import { __assign } from "tslib";
import React from 'react';
import isEqual from 'lodash/isEqual';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import Avatar from '@/components/atoms/avatar';
import { ClearableSearchField } from '@/components/clearable_search_field';
export var MultiTeamsSelectList = function (props) {
    var allTeams = props.allTeams, defaultSelectedTeams = props.selectedTeams, workedTeamIdSet = props.workedTeamIdSet, onApply = props.onApply;
    var _a = React.useState(defaultSelectedTeams), selectedTeams = _a[0], setSelectedTeams = _a[1];
    var _b = React.useState(''), searchText = _b[0], setSearchText = _b[1];
    React.useEffect(function () {
        setSelectedTeams(defaultSelectedTeams);
    }, [defaultSelectedTeams]);
    var handleSelect = function (team) {
        var _a;
        var isSelectedById = __assign(__assign({}, selectedTeams.reduce(function (acc, t) {
            acc[t.id] = true;
            return acc;
        }, {})), (_a = {}, _a[team.id] = !isCheckedByTeamId[team.id], _a));
        var newSelectedTeams = allTeams.filter(function (t) { return isSelectedById[t.id]; });
        setSelectedTeams(newSelectedTeams);
    };
    var handleSelectAll = function () {
        setSelectedTeams(allTeams);
    };
    var handleDeselectAll = function () {
        setSelectedTeams([]);
    };
    var handleApply = function () {
        onApply(selectedTeams);
    };
    var isCheckedByTeamId = selectedTeams.reduce(function (acc, team) {
        acc[team.id] = true;
        return acc;
    }, {});
    var _c = React.useMemo(function () {
        var _a;
        var arrangedTeams = allTeams
            .filter(function (team) { return team.name.includes(searchText); })
            .sort(function (a, b) {
            if (workedTeamIdSet.has(a.id) && !workedTeamIdSet.has(b.id)) {
                return -1;
            }
            if (!workedTeamIdSet.has(a.id) && workedTeamIdSet.has(b.id)) {
                return 1;
            }
            return 0;
        });
        var firstInactiveId = (_a = arrangedTeams.find(function (team) { return !workedTeamIdSet.has(team.id); })) === null || _a === void 0 ? void 0 : _a.id;
        return [arrangedTeams, firstInactiveId];
    }, [allTeams, searchText, workedTeamIdSet]), arrangedTeams = _c[0], firstInactiveId = _c[1];
    return (<>
      <ClearableSearchField className="mt-2 px-2" placeholderKey="searchByTeamName" searchText={searchText} size="md" onChangeSearchText={setSearchText}/>
      <hr className="w-100 mb-0"/>
      <div className="overflow-y-auto">
        {arrangedTeams.map(function (team) { return (<div key={team.id} className={"d-flex align-items-center !tw-cursor-pointer px-2 py-1 my-1\n                  ".concat(workedTeamIdSet.has(team.id) ? '' : 'text-muted', " \n                  ").concat(firstInactiveId === team.id ? 'border-top-1' : '')} onClick={function () { return handleSelect(team); }}>
            <CustomCheck checked={isCheckedByTeamId[team.id]} className="mr-1" onClick={function (e) { return e.preventDefault(); }}/>
            <Avatar className="mr-1 border-0" image={team.avatar_url}/>
            <span className="text-overflow">{team.name}</span>
            {!workedTeamIdSet.has(team.id) && (<span className="small text-nowrap ml-auto">
                {I18n.t('shared.inactive')}
              </span>)}
          </div>); })}
      </div>
      <hr className="w-100 mt-0"/>
      <div className="d-flex justify-content-between aling-items-center px-2 pb-2">
        <div className="d-flex align-items-center">
          <button className="btn btn-link p-0" onClick={handleSelectAll}>
            {I18n.t('shared.selectAll')}
          </button>
          <button className="btn btn-link p-0 ml-2" onClick={handleDeselectAll}>
            {I18n.t('shared.clear')}
          </button>
        </div>
        <button className="btn btn-primary" disabled={isEqual(selectedTeams, defaultSelectedTeams)} onClick={handleApply}>
          {I18n.t('shared.apply')}
        </button>
      </div>
    </>);
};
