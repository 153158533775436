import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import api2 from '@/api2';
export var FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_REQUEST = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_REQUEST';
export var FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_SUCCESS = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_SUCCESS';
export var FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_FAILURE = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_FAILURE';
export var CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST';
export var CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS';
export var CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE';
export var UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST';
export var UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS';
export var UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE';
export var UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_PRIORITY_UP_SUCCESS = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_PRIORITY_UP_SUCCESS';
export var UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_PRIORITY_DOWN_SUCCESS = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_PRIORITY_DOWN_SUCCESS';
export var DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST';
export var DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS';
export var DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE = 'timecrowd/WORKSPACES_CALENDAR_INTEGRATION_FILTERS/DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE';
var initialState = {
    status: 'pending',
    items: [],
};
export default function workspacesCalendarIntegrationFilters(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_REQUEST:
        case CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST:
        case UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST:
        case DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST:
            return __assign(__assign({}, state), { status: 'loading' });
        case FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, status: 'loaded' });
        case FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_FAILURE:
        case CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE:
        case UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE:
        case DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE:
            return __assign(__assign({}, state), { status: 'pending' });
        case CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS:
            return __assign(__assign({}, state), { items: __spreadArray(__spreadArray([], state.items, true), [action.payload], false), status: 'pending' });
        case UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.map(function (item) {
                    return item.id === action.payload.id ? action.payload : item;
                }), status: 'pending' });
        case UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_PRIORITY_UP_SUCCESS: {
            var items = state.items.map(function (item) {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                else {
                    if (item.priority === action.payload.priority) {
                        return __assign(__assign({}, item), { priority: item.priority + 1 });
                    }
                    else {
                        return item;
                    }
                }
            });
            return __assign(__assign({}, state), { items: items, status: 'pending' });
        }
        case UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_PRIORITY_DOWN_SUCCESS: {
            var items = state.items.map(function (item) {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                else {
                    if (item.priority === action.payload.priority) {
                        return __assign(__assign({}, item), { priority: item.priority - 1 });
                    }
                    else {
                        return item;
                    }
                }
            });
            return __assign(__assign({}, state), { items: items, status: 'pending' });
        }
        case DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS: {
            var items = state.items
                .filter(function (item) { return item.id !== action.payload.id; })
                .map(function (item, index) {
                return __assign(__assign({}, item), { priority: index + 1 });
            });
            return __assign(__assign({}, state), { items: items, status: 'pending' });
        }
        default:
            return state;
    }
}
export var fetchWorkspacesCalendarIntegrationFiltersRequest = function () { return ({
    type: FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_REQUEST,
}); };
export var fetchWorkspacesCalendarIntegrationFiltersSuccess = function (items) { return ({
    type: FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_SUCCESS,
    payload: items,
}); };
export var fetchWorkspacesCalendarIntegrationFiltersFailure = function () { return ({
    type: FETCH_WORKSPACES_CALENDAR_INTEGRATION_FILTERS_FAILURE,
}); };
export var fetchWorkspacesCalendarIntegrationFilters = function () {
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesCalendarIntegrationFiltersRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/calendar_integration/filters", 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchWorkspacesCalendarIntegrationFiltersSuccess(items));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchWorkspacesCalendarIntegrationFiltersFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var fetchUserWorkspacesCalendarIntegrationFilters = function (settingId) {
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesCalendarIntegrationFiltersRequest());
        return api2(dispatch, getState, "/api/v2/users/workspaces_calendar_integration_settings/".concat(settingId, "/workspaces_calendar_integration_filters"), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchWorkspacesCalendarIntegrationFiltersSuccess(items));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchWorkspacesCalendarIntegrationFiltersFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var createWorkspacesCalendarIntegrationFilterRequest = function () { return ({
    type: CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST,
}); };
export var createWorkspacesCalendarIntegrationFilterSuccess = function (item) { return ({
    type: CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS,
    payload: item,
}); };
export var createWorkspacesCalendarIntegrationFilterFailure = function () { return ({
    type: CREATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE,
}); };
export var createWorkspacesCalendarIntegrationFilter = function (params) {
    return function (dispatch, getState) {
        dispatch(createWorkspacesCalendarIntegrationFilterRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/calendar_integration/filters", 'POST', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(createWorkspacesCalendarIntegrationFilterSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(createWorkspacesCalendarIntegrationFilterFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateWorkspacesCalendarIntegrationFilterRequest = function () { return ({
    type: UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST,
}); };
export var updateWorkspacesCalendarIntegrationFilterSuccess = function (item) { return ({
    type: UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS,
    payload: item,
}); };
export var updateWorkspacesCalendarIntegrationFilterFailure = function () { return ({
    type: UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE,
}); };
export var updateWorkspacesCalendarIntegrationFilterPriorityUpSuccess = function (item) { return ({
    type: UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_PRIORITY_UP_SUCCESS,
    payload: item,
}); };
export var updateWorkspacesCalendarIntegrationFilterPriorityDownSuccess = function (item) { return ({
    type: UPDATE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_PRIORITY_DOWN_SUCCESS,
    payload: item,
}); };
export var updateWorkspacesCalendarIntegrationFilter = function (id, params) {
    return function (dispatch, getState) {
        dispatch(updateWorkspacesCalendarIntegrationFilterRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/calendar_integration/filters/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(updateWorkspacesCalendarIntegrationFilterSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(updateWorkspacesCalendarIntegrationFilterFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateWorkspacesCalendarIntegrationFilterPriorityUp = function (id, params) {
    return function (dispatch, getState) {
        dispatch(updateWorkspacesCalendarIntegrationFilterRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/calendar_integration/filters/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(updateWorkspacesCalendarIntegrationFilterPriorityUpSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(updateWorkspacesCalendarIntegrationFilterFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateWorkspacesCalendarIntegrationFilterPriorityDown = function (id, params) {
    return function (dispatch, getState) {
        dispatch(updateWorkspacesCalendarIntegrationFilterRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/calendar_integration/filters/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(updateWorkspacesCalendarIntegrationFilterPriorityDownSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(updateWorkspacesCalendarIntegrationFilterFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var deleteWorkspacesCalendarIntegrationFilterRequest = function () { return ({
    type: DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_REQUEST,
}); };
export var deleteWorkspacesCalendarIntegrationFilterSuccess = function (item) { return ({
    type: DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_SUCCESS,
    payload: item,
}); };
export var deleteWorkspacesCalendarIntegrationFilterFailure = function () { return ({
    type: DELETE_WORKSPACES_CALENDAR_INTEGRATION_FILTER_FAILURE,
}); };
export var deleteWorkspacesCalendarIntegrationFilter = function (id) {
    return function (dispatch, getState) {
        dispatch(deleteWorkspacesCalendarIntegrationFilterRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/calendar_integration/filters/".concat(id), 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(deleteWorkspacesCalendarIntegrationFilterSuccess({ id: id }));
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(deleteWorkspacesCalendarIntegrationFilterFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
