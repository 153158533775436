import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HardDeleteTaskModal } from '@/components/hard_delete_task_modal';
import { destroyTask } from '@/actions/task';
import { removeDangerNotificationMessage } from '@/modules/notification';
var HardDeleteTaskModalContainer = function (_a) {
    var team = _a.team, task = _a.task, isOpen = _a.isOpen, toggle = _a.toggle;
    var openURL = useSelector(function (state) { return state.openURL; });
    var handleClickHelp = function (e) {
        e.preventDefault();
        openURL(e.target.href);
    };
    var dispatch = useDispatch();
    var handleDeleteTask = function () {
        dispatch(destroyTask(task.team_id, task.id)).then(function (response) {
            if (response.error) {
                return;
            }
            handleCloseModal();
        });
    };
    var handleCloseModal = function () {
        dispatch(removeDangerNotificationMessage());
        toggle();
    };
    if (!team || !task) {
        return null;
    }
    return (<HardDeleteTaskModal isOpen={isOpen} taskTitle={task.title} teamAvatarUrl={team.avatar_url} teamName={team.name} onClickDelete={handleDeleteTask} onClickHelp={handleClickHelp} onClose={handleCloseModal}/>);
};
export default HardDeleteTaskModalContainer;
