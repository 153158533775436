import I18n from '@/constants/i18n';
export var unsetParentCategory = function (team) {
    return ({
        id: null,
        title: I18n.t('shared.unset'),
        color: 0,
        ancestry_depth: 0,
        archived: false,
        team: team,
        team_id: team.id,
    });
};
export var unsetCategory = function () {
    return ({
        id: null,
        title: '',
        color: 1,
    });
};
