import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import api2 from '@/api2';
export var FETCH_CUSTOM_FIELDS_MASTERS_REQUEST = 'timecrowd/CUSTOM_FIELDS_MASTERS/FETCH_CUSTOM_FIELDS_MASTERS_REQUEST';
export var FETCH_CUSTOM_FIELDS_MASTERS_SUCCESS = 'timecrowd/CUSTOM_FIELDS_MASTERS/FETCH_CUSTOM_FIELDS_MASTERS_SUCCESS';
export var FETCH_CUSTOM_FIELDS_MASTERS_FAILURE = 'timecrowd/CUSTOM_FIELDS_MASTERS/FETCH_CUSTOM_FIELDS_MASTERS_FAILURE';
export var CREATE_CUSTOM_FIELDS_MASTER_REQUEST = 'timecrowd/CUSTOM_FIELDS_MASTERS/CREATE_CUSTOM_FIELDS_MASTER_REQUEST';
export var CREATE_CUSTOM_FIELDS_MASTER_SUCCESS = 'timecrowd/CUSTOM_FIELDS_MASTERS/CREATE_CUSTOM_FIELDS_MASTER_SUCCESS';
export var CREATE_CUSTOM_FIELDS_MASTER_FAILURE = 'timecrowd/CUSTOM_FIELDS_MASTERS/CREATE_CUSTOM_FIELDS_MASTER_FAILURE';
export var UPDATE_CUSTOM_FIELDS_MASTER_REQUEST = 'timecrowd/CUSTOM_FIELDS_MASTERS/UPDATE_CUSTOM_FIELDS_MASTER_REQUEST';
export var UPDATE_CUSTOM_FIELDS_MASTER_SUCCESS = 'timecrowd/CUSTOM_FIELDS_MASTERS/UPDATE_CUSTOM_FIELDS_MASTER_SUCCESS';
export var UPDATE_CUSTOM_FIELDS_MASTER_FAILURE = 'timecrowd/CUSTOM_FIELDS_MASTERS/UPDATE_CUSTOM_FIELDS_MASTER_FAILURE';
export var DELETE_CUSTOM_FIELDS_MASTER_REQUEST = 'timecrowd/CUSTOM_FIELDS_MASTERS/DELETE_CUSTOM_FIELDS_MASTER_REQUEST';
export var DELETE_CUSTOM_FIELDS_MASTER_SUCCESS = 'timecrowd/CUSTOM_FIELDS_MASTERS/DELETE_CUSTOM_FIELDS_MASTER_SUCCESS';
export var DELETE_CUSTOM_FIELDS_MASTER_FAILURE = 'timecrowd/CUSTOM_FIELDS_MASTERS/DELETE_CUSTOM_FIELDS_MASTER_FAILURE';
export var initialState = {
    isLoading: false,
    items: [],
    statusByTeamId: {},
};
export default function customFieldsMasters(state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CUSTOM_FIELDS_MASTERS_REQUEST:
            return __assign(__assign({}, state), { statusByTeamId: __assign(__assign({}, state.statusByTeamId), (_a = {}, _a[action.payload] = 'loading', _a)) });
        case CREATE_CUSTOM_FIELDS_MASTER_REQUEST:
        case UPDATE_CUSTOM_FIELDS_MASTER_REQUEST:
        case DELETE_CUSTOM_FIELDS_MASTER_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_CUSTOM_FIELDS_MASTERS_SUCCESS:
            return __assign(__assign({}, state), { items: state.items
                    .filter(function (_a) {
                    var team_id = _a.team_id;
                    return team_id !== action.payload.teamId;
                })
                    .concat(action.payload.items), statusByTeamId: __assign(__assign({}, state.statusByTeamId), (_b = {}, _b[action.payload.teamId] = 'loaded', _b)) });
        case FETCH_CUSTOM_FIELDS_MASTERS_FAILURE:
            return __assign(__assign({}, state), { statusByTeamId: __assign(__assign({}, state.statusByTeamId), (_c = {}, _c[action.payload] = 'pending', _c)) });
        case CREATE_CUSTOM_FIELDS_MASTER_FAILURE:
        case UPDATE_CUSTOM_FIELDS_MASTER_FAILURE:
        case DELETE_CUSTOM_FIELDS_MASTER_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case CREATE_CUSTOM_FIELDS_MASTER_SUCCESS:
            return __assign(__assign({}, state), { items: __spreadArray(__spreadArray([], state.items, true), [action.payload], false), isLoading: false });
        case UPDATE_CUSTOM_FIELDS_MASTER_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.map(function (item) {
                    return item.id === action.payload.id ? action.payload : item;
                }), isLoading: false });
        case DELETE_CUSTOM_FIELDS_MASTER_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.filter(function (item) { return item.id !== action.payload.id; }), isLoading: false });
        default:
            return state;
    }
}
export var fetchCustomFieldsMastersRequest = function (teamId) { return ({
    type: FETCH_CUSTOM_FIELDS_MASTERS_REQUEST,
    payload: teamId,
}); };
export var fetchCustomFieldsMastersSuccess = function (items, teamId) { return ({
    type: FETCH_CUSTOM_FIELDS_MASTERS_SUCCESS,
    payload: {
        items: items,
        teamId: teamId,
    },
}); };
export var fetchCustomFieldsMastersFailure = function (teamId) { return ({
    type: FETCH_CUSTOM_FIELDS_MASTERS_FAILURE,
    payload: teamId,
}); };
export var fetchCustomFieldsMasters = function (teamId) {
    return function (dispatch, getState) {
        dispatch(fetchCustomFieldsMastersRequest(teamId));
        return api2(dispatch, getState, "/api/v2/custom_fields/masters?team_id=".concat(teamId), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchCustomFieldsMastersSuccess(items, teamId));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchCustomFieldsMastersFailure(teamId));
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var createCustomFieldsMasterRequest = function () { return ({
    type: CREATE_CUSTOM_FIELDS_MASTER_REQUEST,
}); };
export var createCustomFieldsMasterSuccess = function (item) { return ({
    type: CREATE_CUSTOM_FIELDS_MASTER_SUCCESS,
    payload: item,
}); };
export var createCustomFieldsMasterFailure = function () { return ({
    type: CREATE_CUSTOM_FIELDS_MASTER_FAILURE,
}); };
export var createCustomFieldsMaster = function (params) {
    return function (dispatch, getState) {
        dispatch(createCustomFieldsMasterRequest());
        return api2(dispatch, getState, '/api/v2/custom_fields/masters', 'POST', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(createCustomFieldsMasterSuccess(item));
                        return [2 /*return*/, { error: false, item: item }];
                    case 2:
                        dispatch(createCustomFieldsMasterFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateCustomFieldsMasterRequest = function () { return ({
    type: UPDATE_CUSTOM_FIELDS_MASTER_REQUEST,
}); };
export var updateCustomFieldsMasterSuccess = function (item) { return ({
    type: UPDATE_CUSTOM_FIELDS_MASTER_SUCCESS,
    payload: item,
}); };
export var updateCustomFieldsMasterFailure = function () { return ({
    type: UPDATE_CUSTOM_FIELDS_MASTER_FAILURE,
}); };
export var updateCustomFieldsMaster = function (id, params) {
    return function (dispatch, getState) {
        dispatch(updateCustomFieldsMasterRequest());
        return api2(dispatch, getState, "/api/v2/custom_fields/masters/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        if (response.ok) {
                            dispatch(updateCustomFieldsMasterSuccess(item));
                            return [2 /*return*/, { error: false }];
                        }
                        else {
                            dispatch(updateCustomFieldsMasterFailure());
                            return [2 /*return*/, { error: true, errors: item.errors }];
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
};
export var deleteCustomFieldsMasterRequest = function () { return ({
    type: DELETE_CUSTOM_FIELDS_MASTER_REQUEST,
}); };
export var deleteCustomFieldsMasterSuccess = function (item) { return ({
    type: DELETE_CUSTOM_FIELDS_MASTER_SUCCESS,
    payload: item,
}); };
export var deleteCustomFieldsMasterFailure = function () { return ({
    type: DELETE_CUSTOM_FIELDS_MASTER_FAILURE,
}); };
export var deleteCustomFieldsMaster = function (id) {
    return function (dispatch, getState) {
        dispatch(deleteCustomFieldsMasterRequest());
        return api2(dispatch, getState, "/api/v2/custom_fields/masters/".concat(id), 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(deleteCustomFieldsMasterSuccess({ id: id }));
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(deleteCustomFieldsMasterFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
