export var OPEN_MODAL = 'timecrowd/modal/OPEN_MODAL';
export var CLOSE_MODAL = 'timecrowd/modal/CLOSE_MODAL';
var initialState = {
    modalType: null,
    modalProps: {},
};
export default function modal(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case OPEN_MODAL:
            return { modalType: action.modalType, modalProps: action.modalProps };
        case CLOSE_MODAL:
            return initialState;
        default:
            return state;
    }
}
export var openModal = function (modalType, modalProps) {
    if (modalProps === void 0) { modalProps = {}; }
    return ({
        type: OPEN_MODAL,
        modalType: modalType,
        modalProps: modalProps,
    });
};
export var closeModal = function () { return ({
    type: CLOSE_MODAL,
}); };
