import { __awaiter, __generator } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { CrossTeamReportBookmarkItem } from './cross_team_report_bookmark_item';
export var CrossTeamReportBookmarkList = function (props) {
    var bookmarks = props.bookmarks, isLoaded = props.isLoaded, onSelect = props.onSelect, onUpdate = props.onUpdate;
    var notFound = isLoaded && bookmarks.length < 1;
    var handleFavorite = React.useCallback(function (id) {
        onUpdate({ id: id, pinned: true });
    }, [onUpdate]);
    var handleUnfavorite = React.useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onUpdate({ id: id, pinned: false })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [onUpdate]);
    return (<div className="p-2">
      <div className="d-flex align-items-center mt-1">
        <Icon className="mr-1" name="pin"/>
        <div className="font-weight-bold">
          {I18n.t('shared.reportBookmarks')}
        </div>
      </div>
      {notFound ? (<span className="text-muted">
          {I18n.t('shared.noReportBookmarkFound')}
        </span>) : (bookmarks.map(function (bookmark) { return (<CrossTeamReportBookmarkItem key={bookmark.id} bookmark={bookmark} onFavorite={handleFavorite} onSelect={onSelect} onUnfavorite={handleUnfavorite}/>); }))}
    </div>);
};
