import React from 'react';
import { Link } from 'react-router-dom';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
export var TeamReportMyReportNotice = function (props) {
    var isSelectedMyReport = props.isSelectedMyReport, isShowOnlyMyData = props.isShowOnlyMyData, team = props.team;
    if (!isShowOnlyMyData) {
        return null;
    }
    var isShowAllUsersLink = !isSelectedMyReport && isShowOnlyMyData && team.can_manage;
    return (<p className="text-muted mb-1">
      {I18n.t('shared.myReportNotice')}
      {isShowAllUsersLink && (<Link className="" to={"/app/workspaces/".concat(team.workspaces_workspace_id, "/report?team_id=").concat(team.id, "&all_users=true")}>
          <Icon className="ml-3 mr-0.5 align-text-bottom" name="user-group"/>
          {I18n.t('shared.showAllUsersReport')}
        </Link>)}
    </p>);
};
