import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { CrossTeamReportConditionsInfo } from '../cross_team_report_conditions_info';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var CrossTeamReportBookmarkCreateModal = function (props) {
    var conditions = props.conditions, isLoading = props.isLoading, isOpen = props.isOpen, onOpen = props.onOpen, onClose = props.onClose, onCreate = props.onCreate;
    var _a = React.useState(''), name = _a[0], setName = _a[1];
    React.useEffect(function () {
        setName('');
    }, [isOpen]);
    var handleCreate = React.useCallback(function () {
        onCreate({ name: name, conditions: conditions });
    }, [name, conditions, onCreate]);
    return (<>
      <button className="btn btn-link hidden-md-down" onClick={onOpen}>
        <Icon className="mr-1 align-baseline" name="pin"/>
        {I18n.t('shared.saveThisCondition')}
      </button>

      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeaderMod toggle={onClose}>
          {I18n.t('shared.createReportBookmarkHeader')}
        </ModalHeaderMod>

        <ModalBody>
          <p>{I18n.t('shared.createReportBookmarkDescription')}</p>
          <div className="bg-gray-lighter rounded px-2 pt-2 pb-1 mb-2 text-secondary">
            <CrossTeamReportConditionsInfo withDetails conditions={conditions} withDate={conditions.period_type === 'custom' ||
            conditions.term === 'long_term'}/>
          </div>
          <p className="mb-1">
            {I18n.t('shared.createReportBookmarkTitleLabel')}
          </p>
          <input className="form-control" maxLength={30} placeholder={I18n.t('shared.createReportBookmarkTitlePlaceholder')} type="text" value={name} onChange={function (e) { return setName(e.target.value); }}/>
          <p className="text-right">{"".concat(name.length, " / 30")}</p>
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-secondary" disabled={isLoading} onClick={onClose}>
            {I18n.t('shared.cancel')}
          </button>
          <button className="btn btn-primary" disabled={isLoading} onClick={handleCreate}>
            {I18n.t('shared.save')}
          </button>
        </ModalFooter>
      </Modal>
    </>);
};
