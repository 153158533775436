import { categoryWithAncestors } from '@/utils/category_helper';
import { buildPattern } from '@/utils/search_helper';
export var filterWorkingTasks = function (searchText, tasks, timeEntries) {
    var timeEntriesByTaskId = timeEntries.reduce(function (acc, timeEntry) {
        if (!acc[timeEntry.task.id]) {
            acc[timeEntry.task.id] = [];
        }
        acc[timeEntry.task.id].push(timeEntry);
        return acc;
    }, {});
    if (!searchText) {
        return {
            filteredTasks: tasks,
            timeEntriesByTaskId: timeEntriesByTaskId,
        };
    }
    var teamMatchedTasks = [];
    var categoryMatchedTasks = [];
    var taskMatchedTasks = [];
    var userMatchedTasks = [];
    var pattern = buildPattern(searchText);
    tasks.map(function (task) {
        if (pattern.test(task.team.name)) {
            teamMatchedTasks.push(task);
        }
        else if (pattern.test(categoryWithAncestors(task.category))) {
            categoryMatchedTasks.push(task);
        }
        else if (pattern.test(task.title)) {
            taskMatchedTasks.push(task);
        }
        else if (timeEntriesByTaskId[task.id].some(function (timeEntry) {
            return pattern.test(timeEntry.user.nickname);
        })) {
            userMatchedTasks.push(task);
        }
    });
    var filteredTasks = teamMatchedTasks.concat(categoryMatchedTasks, taskMatchedTasks, userMatchedTasks);
    return {
        filteredTasks: filteredTasks,
        timeEntriesByTaskId: timeEntriesByTaskId,
    };
};
