import { __assign } from "tslib";
// Actions
export var FETCH_SUGGEST_TASKS_REQUEST = 'timecrowd/task_search/FETCH_SUGGEST_TASKS_REQUEST';
export var EXPAND_SUGGEST_TASKS_REQUEST = 'timecrowd/task_search/EXPAND_SUGGEST_TASKS_REQUEST';
export var FETCH_USER_TASK_SUGGESTIONS_REQUEST = 'timecrowd/task_search/FETCH_USER_TASK_SUGGESTIONS_REQUEST';
export var EXPAND_USER_TASK_SUGGESTIONS_REQUEST = 'timecrowd/task_search/EXPAND_USER_TASK_SUGGESTIONS_REQUEST';
export var SET_MATCHED_TASKS = 'timecrowd/task_search/SET_MATCHED_TASKS';
export var APPEND_MATCHED_TASKS = 'timecrowd/task_search/APPEND_MATCHED_TASKS';
export var RESET_MATCHED_TASKS = 'timecrowd/task_search/RESET_MATCHED_TASKS';
export var ignoreArchivedTasks = function (tasks) {
    return tasks.filter(function (_a) {
        var _b = _a.category, _c = _b === void 0 ? { archived: false } : _b, archived = _c.archived;
        return !archived;
    });
};
export var initialState = {
    isLoading: false,
    isFetched: false,
    items: [],
    next: null,
    params: {},
};
export default function taskSearch(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_SUGGEST_TASKS_REQUEST:
        case FETCH_USER_TASK_SUGGESTIONS_REQUEST:
            return __assign(__assign({}, state), { items: [], next: null, isLoading: true, isFetched: false, params: {} });
        case EXPAND_SUGGEST_TASKS_REQUEST:
        case EXPAND_USER_TASK_SUGGESTIONS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case SET_MATCHED_TASKS:
            return __assign(__assign({}, state), { items: ignoreArchivedTasks(action.tasks), next: action.next, isLoading: false, isFetched: true, params: action.params });
        case APPEND_MATCHED_TASKS:
            return __assign(__assign({}, state), { items: ignoreArchivedTasks(state.items.concat(action.tasks)), next: action.next, isLoading: false });
        case RESET_MATCHED_TASKS:
            return initialState;
        default:
            return state;
    }
}
export var fetchSuggestTasksRequest = function () { return ({
    type: FETCH_SUGGEST_TASKS_REQUEST,
}); };
export var expandSuggestTasksRequest = function () { return ({
    type: EXPAND_SUGGEST_TASKS_REQUEST,
}); };
export var fetchUserTaskSuggestionsRequest = function () { return ({
    type: FETCH_USER_TASK_SUGGESTIONS_REQUEST,
}); };
export var expandUserTaskSuggestionsRequest = function () { return ({
    type: EXPAND_USER_TASK_SUGGESTIONS_REQUEST,
}); };
export var setMatchedTasks = function (tasks, next, params) { return ({
    type: SET_MATCHED_TASKS,
    tasks: tasks,
    next: next,
    params: params,
}); };
export var appendMatchedTasks = function (tasks, next) { return ({
    type: APPEND_MATCHED_TASKS,
    tasks: tasks,
    next: next,
}); };
export var resetMatchedTasks = function () { return ({
    type: RESET_MATCHED_TASKS,
}); };
