import React from 'react';
import Icon from '@/components/atoms/icon';
var ModalHeaderMod = function (props) {
    var _a = props.className, className = _a === void 0 ? '' : _a, children = props.children, toggle = props.toggle;
    var closeButton = (<button aria-label="Close" className="close" type="button" onClick={toggle}>
      <Icon className="align-baseline" name="cross"/>
    </button>);
    return (<div className={"modal-header ".concat(className)}>
      <h4 className="modal-title">{children}</h4>
      {closeButton}
    </div>);
};
export default ModalHeaderMod;
