import React from 'react';
import range from 'lodash/range';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import { ReportRecipientForm } from './report_recipient_form';
import { PersonalSettingsLayout } from '@/components/personal_settings_layout';
export var ReportMailForm = function (_a) {
    var defaultDaily = _a.daily, defaultDailyTime = _a.daily_time, isOpenSubpane = _a.isOpenSubpane, isPC = _a.isPC, signOut = _a.signOut, user = _a.user, defaultWeekly = _a.weekly, onToggleSubpane = _a.onToggleSubpane, onUpdate = _a.onUpdate;
    var _b = React.useState(defaultDaily), daily = _b[0], setDaily = _b[1];
    var _c = React.useState(defaultDailyTime), dailyTime = _c[0], setDailyTime = _c[1];
    var _d = React.useState(defaultWeekly), weekly = _d[0], setWeekly = _d[1];
    var _e = React.useState('save'), saveStatus = _e[0], setSaveStatus = _e[1];
    var handleClickSave = function () {
        var params = {
            user: {
                daily: daily,
                daily_time: dailyTime,
                weekly: weekly,
            },
        };
        setSaveStatus('saving');
        onUpdate(params).then(function (user) {
            if (user.error) {
                setSaveStatus('save');
                return;
            }
            setSaveStatus('saved');
            setTimeout(function () {
                setSaveStatus('save');
            }, 2000);
        });
    };
    var onChangeDaily = function (e) {
        setDaily(e.target.checked);
    };
    var handleChangeDailyTime = function (e) {
        setDailyTime(e.target.value);
    };
    var handleChangeWeekly = function (e) {
        setWeekly(e.target.checked);
    };
    var hours = range(24).map(function (hour) {
        return hour < 10 ? '0' + hour : hour.toString();
    });
    var isDisabled = saveStatus === 'saving' || saveStatus === 'saved';
    return (<PersonalSettingsLayout active="report_mail" isOpenSubpane={isOpenSubpane} isPC={isPC} saveButtonForPC={<button className="btn-major-action btn btn-primary" disabled={isDisabled} onClick={handleClickSave}>
          {I18n.t("shared.".concat(saveStatus))}
        </button>} saveButtonForSP={<button className="btn-mobile-header-action btn btn-link" disabled={isDisabled} onClick={handleClickSave}>
          {I18n.t("shared.".concat(saveStatus))}
        </button>} setIsOpenSubpane={onToggleSubpane} signOut={signOut} title={I18n.t('personalSetting.reportMail')} user={user}>
      <div className="col-12">
        <div className="form-group row mb-4">
          <label className="col-sm-3 col-12 col-form-label">
            {I18n.t('personalSetting.sendSettings')}
          </label>
          <div className="col-xl-7 col-sm-9 col-12">
            <div className="form-group">
              <CustomCheck checked={daily} onChange={onChangeDaily}>
                {I18n.t('personalSetting.receiveDailyReport')}
              </CustomCheck>
              {daily && (<div className="mt-2 ml-3">
                  <select className="form-control" id="daily-time" name="select" value={dailyTime} onChange={handleChangeDailyTime}>
                    {hours.map(function (hour) {
                return (<option key={hour} value={"".concat(hour, ":00")}>
                          {hour}:00
                        </option>);
            })}
                  </select>
                  <div className="form-text text-muted mt-1">
                    {I18n.t('personalSetting.timeOfReceivingDailyReportDesc')}
                  </div>
                </div>)}
            </div>
            <CustomCheck checked={weekly} onChange={handleChangeWeekly}>
              {I18n.t('personalSetting.receiveWeeklyReport')}
            </CustomCheck>
            {weekly && (<div className="form-text text-muted mt-1 ml-3">
                {I18n.t('personalSetting.receiveWeeklyReportDesc')}
              </div>)}
          </div>
        </div>
      </div>

      <ReportRecipientForm />
    </PersonalSettingsLayout>);
};
