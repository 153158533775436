import api2 from '@/api2';
import { fetchIdentitiesRequest, fetchIdentitiesSuccess, fetchIdentitiesFailure, deleteIdentitySuccess, deleteIdentityFailure, } from '@/modules/identities';
export var fetchIdentities = function () {
    return function (dispatch, getState) {
        dispatch(fetchIdentitiesRequest());
        return api2(dispatch, getState, '/api/v2/identities', 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (identities) {
                    dispatch(fetchIdentitiesSuccess(identities));
                    return identities;
                });
            }
            else {
                dispatch(fetchIdentitiesFailure());
                return { error: true };
            }
        });
    };
};
export var destroyIdentity = function (id) {
    return function (dispatch, getState) {
        return api2(dispatch, getState, "/api/v2/identities/".concat(id), 'DELETE').then(function (response) {
            if (response.ok) {
                dispatch(deleteIdentitySuccess(id));
                return { id: id, error: false };
            }
            else {
                dispatch(deleteIdentityFailure());
                return { error: true };
            }
        });
    };
};
