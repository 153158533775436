import { __awaiter, __generator } from "tslib";
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import Avatar from '@/components/atoms/avatar';
import variants from '@/constants/variants';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import { removeDangerNotificationMessage } from '@/modules/notification';
export var DeleteWorkspaceModal = function (_a) {
    var workspace = _a.workspace, teams = _a.teams, deleteWorkspace = _a.deleteWorkspace, setCurrentWorkspace = _a.setCurrentWorkspace, payment = _a.payment, _b = _a.reloadPage, reloadPage = _b === void 0 ? variants.reloadPage : _b, _c = _a.openURL, openURL = _c === void 0 ? variants.openURL : _c, setNotificationMessage = _a.setNotificationMessage;
    var _d = React.useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var _e = React.useState(false), isConfirmed = _e[0], setIsConfirmed = _e[1];
    var _f = React.useState(false), isDeleting = _f[0], setIsDeleting = _f[1];
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var onCancel = function () {
        dispatch(removeDangerNotificationMessage());
        setIsConfirmed(false);
        setIsOpen(false);
    };
    var onConfirm = function () {
        setIsConfirmed(!isConfirmed);
    };
    var onDelete = function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsDeleting(true);
                        return [4 /*yield*/, deleteWorkspace(workspace.id)];
                    case 1:
                        response = _a.sent();
                        setIsDeleting(false);
                        if (response.error) {
                            return [2 /*return*/];
                        }
                        setCurrentWorkspace(null);
                        setNotificationMessage('success', I18n.t('shared.targetDeleted', { target: workspace.name }));
                        navigate('/app');
                        reloadPage();
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    return (<>
      <hr className="mt-3"/>

      <div className="form-group row mt-3">
        <div className="col-xl-10 col-12">
          <button className="btn btn-link text-danger pr-0 pl-0 mt-1" type="button" onClick={function () { return setIsOpen(true); }}>
            {I18n.t('shared.deleteWorkspace')}
          </button>
        </div>
      </div>

      <Modal isOpen={isOpen} toggle={onCancel}>
        <ModalHeaderMod toggle={onCancel}>
          {I18n.t('shared.deleteWorkspace')}
        </ModalHeaderMod>
        <ModalBody className="text-break">
          {payment.subscribing && (<div className="text-danger mb-4">
              <p>
                {I18n.t('teamManagement.destroyTeam.noticePaidTeam1', {
                name: workspace.name,
            })}
              </p>
              <p>
                {I18n.t('teamManagement.destroyTeam.noticePaidTeam2', {
                target: I18n.t('shared.team'),
            })}
              </p>
              <p>
                {I18n.t('teamManagement.destroyTeam.attention_payment_prefix')}
                <a href="https://help.timecrowd.net/cancel-paid-plan" onClick={function (e) {
                e.preventDefault();
                openURL(e.target.href);
            }}>
                  {I18n.t('teamManagement.destroyTeam.attention_payment_page')}
                </a>
                {I18n.t('teamManagement.destroyTeam.attention_payment_suffix')}
              </p>
            </div>)}

          {I18n.t('shared.deleteWorkspaceDescription')}

          <p className="mt-3 small">{I18n.t('shared.workspace')}</p>
          <div className="h3">
            <Avatar className="mr-1" image={workspace.avatar_url} size="lg"/>
            {workspace.name}
          </div>

          <p className="mt-3 small">{I18n.t('shared.willDeleteTeams')}</p>
          <ul className="list-unstyled">
            {teams.map(function (team) { return (<li key={team.id}>
                <Avatar className="mr-1" image={team.avatar_url}/>
                {team.name}
              </li>); })}
          </ul>

          <CustomCheck checked={isConfirmed} className="mt-3" disabled={payment.subscribing} onChange={onConfirm}>
            <span className="text-danger font-weight-bold">
              {I18n.t('shared.deleteWorkspaceConfirm')}
            </span>
          </CustomCheck>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <button className="btn btn-danger" disabled={!isConfirmed || isDeleting || payment.subscribing} onClick={onDelete}>
            {I18n.t('shared.delete')}
          </button>

          <button className="btn btn-secondary" disabled={isDeleting} onClick={onCancel}>
            {I18n.t('shared.cancel')}
          </button>
        </ModalFooter>
      </Modal>
    </>);
};
