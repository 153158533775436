import api from '../api';
import { setWebHooks, addWebHook, removeWebHook } from '@/modules/web_hooks';
export var fetchWebHooks = function (teamId) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(teamId, "/web_hooks"), 'GET').then(function (webHooks) {
            dispatch(setWebHooks(webHooks));
        });
    };
};
export var createWebHook = function (teamId, params) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(teamId, "/web_hooks"), 'POST', params).then(function (webHook) {
            if (webHook.id) {
                dispatch(addWebHook(webHook));
            }
            return webHook;
        });
    };
};
export var destroyWebHook = function (teamId, id) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(teamId, "/web_hooks/").concat(id), 'DELETE').then(function () {
            dispatch(removeWebHook(id));
        });
    };
};
