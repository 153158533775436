import { __assign } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import { SortableCategoryListWithSearch } from '@/components/sortable_category_list/sortable_category_list_with_search';
import DeleteCategoryModalContainer from '@/containers/delete_category_modal_container';
import ArchiveCategoryModalContainer from '@/containers/archive_category_modal_container';
import CustomCheck from '@/components/atoms/custom_check';
import { unsetCategory } from '@/components/categories/helpers';
var CategoryList = function (props) {
    var moveLowerCategoryPosition = props.moveLowerCategoryPosition, moveHigherCategoryPosition = props.moveHigherCategoryPosition, team = props.team, categories = props.categories, onUpdateCategoryPosition = props.onUpdateCategoryPosition, onUpdateCategory = props.onUpdateCategory, customFieldsMasters = props.customFieldsMasters, customFieldsValues = props.customFieldsValues, categoryTaskTree = props.categoryTaskTree, setNotificationMessage = props.setNotificationMessage, copyTeamCategory = props.copyTeamCategory, createTeamCategory = props.createTeamCategory, updateTeamCategory = props.updateTeamCategory, fetchCustomFieldsValues = props.fetchCustomFieldsValues, fetchCategoryTaskTree = props.fetchCategoryTaskTree;
    var _a = React.useState(unsetCategory()), selectedCategory = _a[0], setSelectedCategory = _a[1];
    var _b = React.useState(false), isRemoveModalOpened = _b[0], setIsRemoveModalOpened = _b[1];
    var _c = React.useState(false), isArchiveModalOpened = _c[0], setIsArchiveModalOpened = _c[1];
    var _d = React.useState(null), draggedId = _d[0], setDraggedId = _d[1];
    var _e = React.useState(false), isShowArchived = _e[0], setIsShowArchived = _e[1];
    var handleArchiveCategory = function (selectedCategory) {
        setSelectedCategory(selectedCategory);
        setIsArchiveModalOpened(true);
    };
    var handleToggleArchiveModal = function () {
        setIsArchiveModalOpened(!isArchiveModalOpened);
    };
    var handleToggleRemoveModal = function (selectedCategory) {
        if (selectedCategory) {
            setSelectedCategory(selectedCategory);
            setIsRemoveModalOpened(!isRemoveModalOpened);
        }
        else {
            setSelectedCategory(unsetCategory());
            setIsRemoveModalOpened(!isRemoveModalOpened);
        }
    };
    var handleMoveLowerCategoryPosition = function (id) {
        moveLowerCategoryPosition(id, isShowArchived);
    };
    var handleMoveHigherCategoryPosition = function (id) {
        moveHigherCategoryPosition(id, isShowArchived);
    };
    var handleDragStart = function (e) {
        setDraggedId(e.active.id);
    };
    var handleDragEnd = function (e) {
        setDraggedId(null);
        var params = {
            position: e.over.data.current.sortable.index + 1,
            team_id: team.id, // NOTE: ポジションの更新には必要ないがエラー処理で必要なため
        };
        // NOTE: 描画をスムーズにするため、フロントで計算して並び替える
        var category = categories.find(function (c) { return c.id === Number(e.active.id); });
        onUpdateCategoryPosition(__assign(__assign({}, category), params), e.active.data.current.sortable.index + 1);
        onUpdateCategory(e.active.id, params);
    };
    return (<div className="row no-gutters w-100">
      <div className="col-12">
        <div className="text-right mb-2">
          <CustomCheck checked={isShowArchived} onChange={function () { return setIsShowArchived(!isShowArchived); }}>
            {I18n.t('shared.includeArchived')}
          </CustomCheck>
        </div>
        <SortableCategoryListWithSearch cancelDrag={function () { return setDraggedId(null); }} categories={categories} categoryTaskTree={categoryTaskTree} copyTeamCategory={copyTeamCategory} createTeamCategory={createTeamCategory} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues} draggedId={draggedId} fetchCategoryTaskTree={fetchCategoryTaskTree} fetchCustomFieldsValues={fetchCustomFieldsValues} handleArchiveCategory={handleArchiveCategory} handleDragEnd={handleDragEnd} handleDragStart={handleDragStart} isShowArchived={isShowArchived} setNotificationMessage={setNotificationMessage} team={team} toggleRemoveModal={handleToggleRemoveModal} updateTeamCategory={updateTeamCategory} onMoveHigherCategoryPosition={handleMoveHigherCategoryPosition} onMoveLowerCategoryPosition={handleMoveLowerCategoryPosition}/>
      </div>

      <ArchiveCategoryModalContainer category={selectedCategory} isOpen={isArchiveModalOpened} onToggle={handleToggleArchiveModal}/>
      <DeleteCategoryModalContainer categories={categories} category={selectedCategory} isOpen={isRemoveModalOpened} toggle={handleToggleRemoveModal}/>
    </div>);
};
export default CategoryList;
