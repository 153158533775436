import { __assign } from "tslib";
export default function user(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case 'SET_USER':
            return action.user;
        case 'SET_CALENDAR_ZOOM_RATE':
            return __assign(__assign({}, state), { calendar_zoom_rate: action.zoomRate });
        default:
            return state;
    }
}
