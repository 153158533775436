import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parentId } from '@/utils/ancestry_helper';
import { updateTeam } from '@/modules/teams';
import { closeModal } from '@/modules/modal';
import { DeleteChildTeamModal } from '@/components/team_children/delete_child_team_modal';
var DeleteChildTeamModalContainer = function () {
    var modalProps = useSelector(function (state) { return state.modal.modalProps; });
    var isOpen = useSelector(function (state) { return state.modal.modalType === 'DELETE_CHILD_TEAM_MODAL'; });
    var teams = useSelector(function (state) { return state.teams.items; });
    var team = useSelector(function (state) { return state.modal.modalProps.team; });
    var parentTeam = teams.find(function (_a) {
        var id = _a.id;
        return id === parentId(team);
    });
    var dispatch = useDispatch();
    var onSubmit = React.useCallback(function (id) {
        return dispatch(updateTeam(id, { team: { parent_id: null } }));
    }, [dispatch]);
    var onClose = React.useCallback(function () {
        dispatch(closeModal());
    }, [dispatch]);
    return (<DeleteChildTeamModal isOpen={isOpen} modalProps={modalProps} parentTeam={parentTeam} onClose={onClose} onSubmit={onSubmit}/>);
};
export default DeleteChildTeamModalContainer;
