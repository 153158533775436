import { __assign } from "tslib";
import React from 'react';
import { useSelector } from 'react-redux';
import { CategoryInfoTooltip } from '@/components/category_info_tooltip';
import { buildAncestorTitles } from '@/modules/categories/helpers';
import { workspaceTeams } from '@/utils/workspace_helper';
export var CategoryInfoContainer = function (_a) {
    var _b, _c;
    var propsCategories = _a.categories, category = _a.category, className = _a.className, _d = _a.requiresCustomFields, requiresCustomFields = _d === void 0 ? false : _d;
    var customFieldsMasters = useSelector(function (state) { return state.customFieldsMasters; });
    var customFieldsValues = useSelector(function (state) { return state.customFieldsValues; });
    var allTeams = useSelector(function (state) { return state.teams.items; });
    var allCategories = useSelector(function (state) { return state.categories; });
    var categories = propsCategories !== null && propsCategories !== void 0 ? propsCategories : allCategories;
    var teams = React.useMemo(function () {
        return workspaceTeams(allTeams);
    }, [allTeams]);
    var normalizedCategory = React.useMemo(function () {
        var _a;
        if (category.ancestor_titles) {
            return category;
        }
        var categoryById = {};
        categories.forEach(function (category) {
            categoryById[category.id] = category;
        });
        var matchedCategory = (_a = categoryById[category.id]) !== null && _a !== void 0 ? _a : category;
        return __assign(__assign(__assign({}, category), matchedCategory), { ancestor_titles: buildAncestorTitles(matchedCategory, categoryById) });
    }, [categories, category]);
    var teamId = (_b = normalizedCategory.team_id) !== null && _b !== void 0 ? _b : (_c = normalizedCategory.team) === null || _c === void 0 ? void 0 : _c.id;
    var team = teams.find(function (_a) {
        var id = _a.id;
        return id === teamId;
    });
    if (!team || !category.id) {
        return null;
    }
    var teamCustomFieldsMasters = customFieldsMasters.items.filter(function (_a) {
        var team_id = _a.team_id;
        return team_id === team.id;
    });
    return (<CategoryInfoTooltip categories={categories} category={__assign(__assign({}, normalizedCategory), { team: team })} className={className} customFieldsMasters={teamCustomFieldsMasters} customFieldsValues={customFieldsValues} requiresCustomFields={requiresCustomFields} team={team}/>);
};
