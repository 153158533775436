import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UndoAlert } from '@/components/undo_alert';
import { clearUndo } from '@/modules/undo';
export var UndoAlertContainer = function () {
    var dispatch = useDispatch();
    var undo = useSelector(function (state) { return state.undo; });
    var handleUndo = React.useCallback(function () {
        undo.action();
        dispatch(clearUndo());
    }, [dispatch, undo]);
    var handleCancel = React.useCallback(function () {
        dispatch(clearUndo());
    }, [dispatch]);
    if (!undo.action) {
        return null;
    }
    return (<UndoAlert autoClose={undo.messageType !== 'danger'} message={undo.message} messageType={undo.messageType} onCancel={handleCancel} onUndo={handleUndo}/>);
};
