import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryTaskStructuresImports, createCategoryTaskStructuresImport, } from '@/modules/category_task_structures_imports';
import { CategoryTaskStructuresImportForm } from '@/components/category_task_structures_import_form';
export var CategoryTaskStructuresImportContainer = function (_a) {
    var canImport = _a.canImport, team = _a.team;
    var categoryTaskStructuresImports = useSelector(function (state) { return state.categoryTaskStructuresImports; });
    var dispatch = useDispatch();
    var isOwner = team && team.can_manage;
    var handleCreateCategoryTaskStructuresImport = function (params) {
        dispatch(createCategoryTaskStructuresImport(params));
    };
    var handleFetchCategoryTaskStructuresImports = React.useCallback(function (teamId, nextPage) {
        dispatch(fetchCategoryTaskStructuresImports(teamId, nextPage));
    }, [dispatch]);
    React.useEffect(function () {
        if (!team.payment_required && isOwner) {
            handleFetchCategoryTaskStructuresImports(team.id, 1);
        }
    }, [
        handleFetchCategoryTaskStructuresImports,
        team.id,
        team.payment_required,
        isOwner,
    ]);
    return (<CategoryTaskStructuresImportForm canImport={canImport} createCategoryTaskStructuresImport={handleCreateCategoryTaskStructuresImport} fetchCategoryTaskStructuresImports={handleFetchCategoryTaskStructuresImports} hasNext={categoryTaskStructuresImports.hasNext} imports={categoryTaskStructuresImports.items} isLoading={categoryTaskStructuresImports.isLoading} team={team}/>);
};
export default CategoryTaskStructuresImportContainer;
