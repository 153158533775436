import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { workspaceTeams } from '@/utils/workspace_helper';
import { TeamChildren } from '@/components/team_children';
var TeamChildrenContainer = function () {
    var teams = useSelector(function (state) { return workspaceTeams(state.teams.items); });
    var team_id = useParams().team_id;
    var team = teams.find(function (team) { return team.id === Number(team_id); });
    return <TeamChildren team={team}/>;
};
export default TeamChildrenContainer;
