import { __spreadArray } from "tslib";
import React from 'react';
import { Link } from 'react-router-dom';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { SortButton } from './sort_button';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import CompareBar from '@/components/atoms/compare_bar';
import { colors } from './helpers';
import { CategoryInfoContainer } from '@/containers/category_info_container';
import { buildCategoriesWithAncestorTitles } from '@/modules/categories/helpers';
import { workspacePath } from '@/utils/workspace_helper';
import { formatDuration } from '@/utils/time_helper';
var BASE_LIMIT = 5;
var PER_PAGE = 20;
export var TeamReportTasks = function (_a) {
    var categories = _a.categories, tasks = _a.tasks, taskTotals = _a.taskTotals, currentSortBy = _a.currentSortBy, currentSortOrder = _a.currentSortOrder, setOrder = _a.setOrder, setTarget = _a.setTarget;
    var _b = React.useState(0), page = _b[0], setPage = _b[1];
    var maxTaskDuration = React.useMemo(function () {
        return tasks.reduce(function (acc, task) { return Math.max(acc, taskTotals[task.id].duration); }, 0);
    }, [tasks, taskTotals]);
    var _c = React.useMemo(function () { return buildCategoriesWithAncestorTitles(categories); }, [categories]), categoriesWithAncestorTitles = _c.categoriesWithAncestorTitles, categoryById = _c.categoryById;
    var sortedTasks = React.useMemo(function () {
        var sortValue = function (task) {
            switch (currentSortBy) {
                case 'category_title':
                    return categoryById[Number(task.ancestry.split('/').pop())].title;
                case 'title':
                    return task.title;
                case 'total_time':
                    return taskTotals[task.id].duration;
            }
        };
        return __spreadArray([], tasks, true).sort(function (a, b) {
            if (currentSortOrder === 'asc') {
                return sortValue(a) < sortValue(b) ? 1 : -1;
            }
            else {
                return sortValue(a) > sortValue(b) ? 1 : -1;
            }
        });
    }, [tasks, currentSortBy, currentSortOrder, taskTotals, categoryById]);
    var tasksShown = React.useMemo(function () {
        return sortedTasks.slice(0, BASE_LIMIT + page * PER_PAGE);
    }, [page, sortedTasks]);
    var handlePrevPage = function () {
        setPage(page - 1);
    };
    var handleNextPage = function () {
        setPage(page + 1);
    };
    return (<div className="report-padding p-[0.8rem]">
      <h3 className="h3 mb-3 should-remove-ensure-compatibility-design">
        <Icon className="mr-1" color="info" name="to-do"/>
        {I18n.t('shared.task')}
      </h3>
      <div className="table-responsive pb-4">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-nowrap">
                {I18n.t('shared.category')}
                <SortButton currentSortBy={currentSortBy} currentSortOrder={currentSortOrder} setOrder={setOrder} setTarget={setTarget} sortBy="category_title"/>
              </th>
              <th>
                {I18n.t('shared.title')}
                <SortButton currentSortBy={currentSortBy} currentSortOrder={currentSortOrder} setOrder={setOrder} setTarget={setTarget} sortBy="title"/>
              </th>
              <th>
                {I18n.t('report.duration')}
                <SortButton currentSortBy={currentSortBy} currentSortOrder={currentSortOrder} setOrder={setOrder} setTarget={setTarget} sortBy="total_time"/>
              </th>
            </tr>
          </thead>
          <tbody>
            {tasksShown.map(function (v, i) {
            var category = categoryById[v.ancestry.split('/').pop()];
            return (<tr key={i}>
                  <td className="text-nowrap align-top">
                    <CategoryCircleIcon color={category.color}/>
                    {category.title}
                    <CategoryInfoContainer categories={categoriesWithAncestorTitles} category={category} className="mx-1"/>
                  </td>
                  <td className="align-top">
                    <Link to={workspacePath("/tasks/".concat(v.id, "/edit"))}>
                      {v.title}
                    </Link>
                  </td>
                  <td className="align-top">
                    <div className="d-flex align-items-center flex-nowrap">
                      <span className="d-inline-block mr-1" style={{ minWidth: '108px' }}>
                        <Icon className="mr-1" color="secondary" name="clock" size="sm" style={{ position: 'relative', top: '-2px' }}/>
                        {formatDuration(taskTotals[v.id].duration)}
                      </span>
                      <CompareBar color={colors[category.color - 1]} percent={(taskTotals[v.id].duration / maxTaskDuration) * 100}/>
                    </div>
                  </td>
                </tr>);
        })}
          </tbody>
        </table>
        {tasks && tasks.length > BASE_LIMIT && (<div className="text-center mt-1">
            {page > 0 && (<button className="btn btn-link btn-sm p-0 mr-2" onClick={function () { return handlePrevPage(); }}>
                {"- ".concat(I18n.t('shared.showLess'))}
              </button>)}
            {tasks.length > BASE_LIMIT + page * PER_PAGE && (<button className="btn btn-link btn-sm p-0 ml-2" onClick={function () { return handleNextPage(); }}>
                {"+ ".concat(I18n.t('shared.showMore'))}
              </button>)}
          </div>)}
      </div>
    </div>);
};
