import I18n from '@/constants/i18n';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
export function zenToHanTime(str) {
    var value = str
        .replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
        .replace(/：/, ':');
    return value;
}
export function addColon(str) {
    if (/^[0-9]{4}$/.test(str)) {
        return [str.slice(0, 2), str.slice(2, str.length)].join(':');
    }
    else if (/^[0-9]{2}:[0-9]{4}$/.test(str)) {
        return [str.slice(0, 2), str.slice(3, 5), str.slice(5, str.length)].join(':');
    }
    else {
        return str;
    }
}
export function removeNonTimeCharacters(str) {
    return str.replace(/[^0-9:]/g, '');
}
export function isValidTimeFormat(str) {
    return /^\d{2}:\d{2}/.test(str);
}
export function isValidTimeRange(str) {
    var _a = str.split(':'), hour = _a[0], minute = _a[1];
    return Number(hour) < 24 && Number(minute) < 60;
}
export function zeroPadTime(str) {
    if (/^\d:\d{2}/.test(str)) {
        var _a = str.split(':'), hour = _a[0], minute = _a[1];
        return hour.padStart(2, '0') + ':' + minute;
    }
    else {
        return str;
    }
}
export function formatDateTime(date) {
    return "".concat(formatDate(date), " ").concat(formatTime(date));
}
export function formatShortDateTime(date, defaultText) {
    if (defaultText === void 0) { defaultText = ''; }
    var m = moment(date);
    if (!m.isValid()) {
        return defaultText;
    }
    return "".concat(m.format('MM/DD'), " ").concat(formatTime(date, false));
}
export function formatDate(date, delimiter) {
    if (delimiter === void 0) { delimiter = '-'; }
    return moment(date).format("YYYY".concat(delimiter, "MM").concat(delimiter, "DD"));
}
export function formatTime(date, withSecond) {
    if (withSecond === void 0) { withSecond = true; }
    return moment(date).format("HH:mm".concat(withSecond ? ':ss' : ''));
}
export function formatDuration(duration) {
    var h = Math.floor(duration / 3600);
    var m = Math.floor((duration - h * 3600) / 60);
    return "".concat(h).concat(I18n.t('shared.hours')).concat(m).concat(I18n.t('shared.minutes'));
}
export function remainingDays(time) {
    return Math.max(0, Math.ceil(moment(time).diff(moment(), 'hours') / 24));
}
export var isSameDay = function (date, anotherDate) {
    if (!date || !anotherDate) {
        return false;
    }
    var mDate = moment(date);
    var mAnotherDate = moment(anotherDate);
    return mDate.isSame(mAnotherDate, 'day');
};
export var isSameOrBefore = function (time, anotherTime) {
    return moment(time).isSameOrBefore(anotherTime);
};
export var formatDateWithLocale = function (time) {
    return moment(time).locale(I18n.locale).format('l');
};
export var beforeDay = function (time, days) {
    return moment(time).subtract(days, 'days').toDate();
};
export var weekOfMonth = function (time) {
    var current = moment(time);
    var startOfMonth = current.clone().startOf('month');
    // NOTE: 12月最終週のisoWeekは1になるので「前週+1」することで12月内の週を返す
    if (current.isoWeekYear() !== startOfMonth.isoWeekYear()) {
        return weekOfMonth(current.subtract(1, 'week')) + 1;
    }
    return moment(time).isoWeek() - moment(time).startOf('month').isoWeek() + 1;
};
export var quarterOfYear = function (time, startMonth) {
    var month = moment(time).month() + 1;
    var year = moment(time).year();
    return {
        year: month < startMonth ? year - 1 : year,
        quarter: (Math.floor((month + 12 - startMonth) / 3) % 4) + 1,
    };
};
