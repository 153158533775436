import React from 'react';
import { useSelector } from 'react-redux';
import TimeInput from '@/components/timespan/time_input';
var TimeInputContainer = function (_a) {
    var autoFocus = _a.autoFocus, className = _a.className, disabled = _a.disabled, initTime = _a.initTime, onBlur = _a.onBlur, onChange = _a.onChange, onFocus = _a.onFocus;
    var isChromeExtension = useSelector(function (state) { return state.isChromeExtension; });
    var isPC = useSelector(function (state) { return state.isPC; });
    return (<TimeInput autoFocus={autoFocus} className={className} disabled={disabled} initTime={initTime} isChromeExtension={isChromeExtension} isPC={isPC} onBlur={onBlur} onChange={onChange} onFocus={onFocus}/>);
};
export default TimeInputContainer;
