import moment from 'moment'; // eslint-disable-line no-restricted-imports
export var isOverlap = function (target, partner) {
    var targetStartedAt = moment(target.started_at).startOf('minute');
    var targetStoppedAt = target.stopped_at
        ? moment(target.stopped_at).startOf('minute')
        : moment().startOf('minute');
    var partnerStartedAt = moment(partner.started_at).startOf('minute');
    var partnerStoppedAt = partner.stopped_at
        ? moment(partner.stopped_at).startOf('minute')
        : moment().startOf('minute');
    var isStartedAtBetweenAnotherTimeEntry = moment(targetStartedAt).isBetween(partnerStartedAt, partnerStoppedAt, null, '[)');
    var isStoppedAtBetweenAnotherTimeEntry = targetStoppedAt.isBetween(partnerStartedAt, partnerStoppedAt, null, '(]');
    var isIncludedAnotherTimeEntry = targetStartedAt.isBefore(partnerStoppedAt) &&
        targetStoppedAt.isAfter(partnerStoppedAt);
    return (target.id !== partner.id &&
        (isStartedAtBetweenAnotherTimeEntry ||
            isStoppedAtBetweenAnotherTimeEntry ||
            isIncludedAnotherTimeEntry));
};
