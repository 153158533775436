import 'whatwg-fetch';
import moment from 'moment-timezone'; // eslint-disable-line no-restricted-imports
import { thunk } from 'redux-thunk';
import 'moment/locale/ja';
import App from './containers/app';
import actions from './actions';
import variants from './constants/variants';
import initialState from './initial_state';
import I18n from '@/constants/i18n';
var useHashRouter = variants.useHashRouter, isChromeExtension = variants.isChromeExtension, isApp = variants.isApp;
var app = App({ initialState: initialState, useHashRouter: useHashRouter, middleware: [thunk] });
if (window.success) {
    app.store.dispatch(actions.setNotificationMessage('success', window.success));
}
if (window.error) {
    app.store.dispatch(actions.setNotificationMessage('danger', window.error));
}
app.store.subscribe(function () {
    var user = app.store.getState().user;
    if (!user) {
        return;
    }
    var oldLocal = I18n.locale;
    I18n.locale = user.locale || oldLocal;
    moment.tz.setDefault(user.time_zone_mapping);
    if (!isChromeExtension && !isApp) {
        return;
    }
});
app.render();
