import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
export var WorkspacesCalendarIntegrationDeleteFilterModal = function (_a) {
    var filter = _a.filter, isOpen = _a.isOpen, onClose = _a.onClose, onSubmit = _a.onSubmit;
    var handleSubmit = React.useCallback(function () {
        onSubmit(filter.id);
    }, [filter.id, onSubmit]);
    return (<Modal isOpen={isOpen} toggle={onClose} zIndex={1050}>
      <ModalHeaderMod toggle={onClose}>
        {I18n.t('shared.deleteFiltering')}
      </ModalHeaderMod>
      <ModalBody>
        <div className="mb-3">
          <p>{I18n.t('shared.deleteFilteringDescription')}</p>
        </div>
        <div className="row">
          <div className="col-4">{I18n.t('shared.priority')}</div>
          <div className="col-4">{filter.priority}</div>
        </div>
        <div className="row mt-2">
          <div className="col-4">{I18n.t('shared.teamCategory')}</div>
          <div className="col">
            {filter.category && (<span>
                <CategoryCircleIcon color={filter.category.color}/>
                {filter.category.title} - {filter.category.team.name}
              </span>)}
            {!filter.category && (<span className="font-weight-bold text-danger">
                {I18n.t('shared.notSelected')}
              </span>)}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4">{I18n.t('shared.keywords')}</div>
          <div className="col">
            <p dangerouslySetInnerHTML={{
            __html: filter.keywords_text.split('\n').join('<br>'),
        }}/>
          </div>
        </div>
      </ModalBody>

      <ModalFooter className="justify-start">
        <button className="btn btn-danger" onClick={handleSubmit}>
          {I18n.t('shared.delete')}
        </button>
        <button className="btn btn-secondary" onClick={onClose}>
          {I18n.t('shared.cancel')}
        </button>
      </ModalFooter>
    </Modal>);
};
