import React from 'react';
import I18n from '@/constants/i18n';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import { TaskSuggestionList } from './task_suggestion_list';
var TaskSuggestion = function (props) {
    var recentTasks = props.recentTasks, expandRecentTasks = props.expandRecentTasks, isInitial = props.isInitial, keyword = props.keyword, taskSearch = props.taskSearch, category = props.category, isCategorySelected = props.isCategorySelected, onPaginateSuggestTasks = props.onPaginateSuggestTasks, onSelectTaskFromSuggestions = props.onSelectTaskFromSuggestions, calendarUser = props.calendarUser, user = props.user;
    var _a = React.useState(isInitial), isShowRecentTasks = _a[0], setIsShowRecentTasks = _a[1];
    var _b = React.useState(false), isShowAllSearchedTasks = _b[0], setIsShowAllSearchedTasks = _b[1];
    var _c = React.useState(false), isShowCategoryMatchedTasks = _c[0], setIsShowCategoryMatchedTasks = _c[1];
    var _d = React.useState(false), isShowRelatedTasks = _d[0], setIsShowRelatedTasks = _d[1];
    var _e = React.useState(false), isShowRecentTasksButton = _e[0], setIsShowRecentTasksButton = _e[1];
    var hasKeyword = keyword.length > 0;
    var isNotFound = (isShowAllSearchedTasks || isShowCategoryMatchedTasks) &&
        taskSearch.isFetched &&
        taskSearch.items.length < 1;
    React.useEffect(function () {
        if (isCategorySelected) {
            setIsShowRecentTasks(false);
            setIsShowAllSearchedTasks(false);
            setIsShowCategoryMatchedTasks(true);
            setIsShowRelatedTasks(hasKeyword);
            setIsShowRecentTasksButton(!hasKeyword);
        }
        else {
            setIsShowRecentTasks(!hasKeyword);
            setIsShowAllSearchedTasks(hasKeyword);
            setIsShowCategoryMatchedTasks(false);
            setIsShowRelatedTasks(false);
            setIsShowRecentTasksButton(false);
        }
    }, [isCategorySelected, hasKeyword]);
    var handleClickReadMoreTaskSuggest = function () {
        return onPaginateSuggestTasks();
    };
    var handleClickReadMoreRecentTasks = function () {
        var items = recentTasks.items, next = recentTasks.next;
        if (!items.length || !next) {
            return Promise.resolve();
        }
        return expandRecentTasks();
    };
    var recentTaskLabel = calendarUser && calendarUser.id !== user.id
        ? I18n.t('shared.recentTasksProxy', { nickname: calendarUser.nickname })
        : I18n.t('shared.recentTasks');
    var _f = React.useMemo(function () {
        var categoryMatchedTasks = [];
        var relatedTasks = [];
        taskSearch.items.forEach(function (task) {
            if (task.category.id === category.id) {
                categoryMatchedTasks.push(task);
            }
            else {
                relatedTasks.push(task);
            }
        });
        return [categoryMatchedTasks, relatedTasks];
    }, [taskSearch.items, category]), categoryMatchedTasks = _f[0], relatedTasks = _f[1];
    return (<div className="tab-pane active rounded-bottom overflow-hidden">
      <div className="list-group list-group-flush list-group-suggest">
        {!isShowRecentTasks && !taskSearch.isFetched && (<div className="py-2 text-center">
            <LoadingSpinner size="md"/>
          </div>)}
        {isNotFound && (<div className="py-3 text-info text-center">
            {I18n.t('shared.noTasksForKeyword')}
          </div>)}
        {isShowAllSearchedTasks &&
            taskSearch.isFetched &&
            taskSearch.items.length > 0 && (<TaskSuggestionList hasMore={!taskSearch.isLoading && !!taskSearch.next} icon="" keyword={keyword} tasks={taskSearch.items} onLoadMore={handleClickReadMoreTaskSuggest} onSelectTaskFromSuggestions={onSelectTaskFromSuggestions}/>)}
        {isShowCategoryMatchedTasks && (<>
            {categoryMatchedTasks.length > 0 && (<TaskSuggestionList hideTeam hasMore={!taskSearch.isLoading &&
                    !!taskSearch.next &&
                    relatedTasks.length < 1} icon="" keyword={keyword} tasks={categoryMatchedTasks} onLoadMore={handleClickReadMoreTaskSuggest} onSelectTaskFromSuggestions={onSelectTaskFromSuggestions}/>)}
            {categoryMatchedTasks.length > 0 && relatedTasks.length > 0 && (<div className="py-2 text-info text-center">
                {I18n.t('shared.noMoreTasksForCategory')}
              </div>)}
            {taskSearch.isFetched &&
                categoryMatchedTasks.length < 1 &&
                !isNotFound && (<div className="py-2 text-info text-center">
                  {I18n.t('shared.noTasksForCategory')}
                </div>)}
          </>)}
        {isShowRelatedTasks && relatedTasks.length > 0 && (<>
            <div className="px-2 pt-2 font-weight-bold small text-info bg-gray-lighter">
              {I18n.t('shared.relatedTasks')}
            </div>
            <TaskSuggestionList className="bg-gray-lighter" hasMore={!taskSearch.isLoading && !!taskSearch.next} icon="" keyword={keyword} tasks={relatedTasks} onLoadMore={handleClickReadMoreTaskSuggest} onSelectTaskFromSuggestions={onSelectTaskFromSuggestions}/>
          </>)}
        {isShowRecentTasks && recentTasks.items.length > 0 && (<>
            <div className="px-2 pt-2 font-weight-bold small text-info bg-gray-lighter">
              {recentTaskLabel}
            </div>
            <TaskSuggestionList className="bg-gray-lighter" hasMore={!recentTasks.isLoading && !!recentTasks.next} icon="clock" keyword={keyword} tasks={recentTasks.items} onLoadMore={handleClickReadMoreRecentTasks} onSelectTaskFromSuggestions={onSelectTaskFromSuggestions}/>
          </>)}
        {isShowRecentTasksButton &&
            taskSearch.isFetched &&
            !taskSearch.isLoading && (<div className="py-2 text-center">
              <button className="btn btn-link" onClick={function () {
                setIsShowRecentTasksButton(false);
                setIsShowRecentTasks(true);
            }}>
                {I18n.t('shared.showRecentTasks')}
              </button>
            </div>)}
      </div>
    </div>);
};
export default TaskSuggestion;
