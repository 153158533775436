import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import LoadingSpinner from '@/components/atoms/loading_spinner';
export var TimeZoneSelect = function (_a) {
    var timeZones = _a.timeZones, selected = _a.selected, onClick = _a.onClick;
    if (!timeZones.length) {
        return <LoadingSpinner position="normal" size="md"/>;
    }
    var selectedTimeZone = timeZones.find(function (zone) { return zone[1] === selected; }) || timeZones[0];
    return (<UncontrolledDropdown>
      <DropdownToggle caret>{selectedTimeZone[0]}</DropdownToggle>
      <DropdownMenu>
        {timeZones.map(function (zone) {
            return (<DropdownItem key={zone[1]} value={zone[1]} onClick={onClick}>
              {zone[0]}
            </DropdownItem>);
        })}
      </DropdownMenu>
    </UncontrolledDropdown>);
};
