import moment from 'moment'; // eslint-disable-line no-restricted-imports
export var fitTimesWithinDate = function (date, timeEntry) {
    var startedAt = moment(timeEntry.started_at);
    var stoppedAt = moment();
    if (timeEntry.stopped_at) {
        if (startedAt.isSame(timeEntry.stopped_at, 'day')) {
            stoppedAt = moment(timeEntry.stopped_at);
        }
        else if (startedAt.isSame(date, 'day')) {
            stoppedAt = moment(startedAt).endOf('day');
        }
        else if (startedAt.isBefore(date, 'day') &&
            moment(timeEntry.stopped_at).isAfter(date, 'day')) {
            startedAt = moment(date).startOf('day');
            stoppedAt = moment(date).endOf('day');
        }
        else {
            stoppedAt = moment(timeEntry.stopped_at);
            startedAt = moment(stoppedAt).startOf('day');
        }
    }
    else {
        if (startedAt.isBefore(date, 'day')) {
            startedAt = moment(date).startOf('day');
        }
        if (moment().isAfter(date, 'day')) {
            stoppedAt = moment(date).endOf('day');
        }
    }
    return { startedAt: startedAt, stoppedAt: stoppedAt };
};
