import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import api2 from '@/api2';
import queryString from 'query-string';
export var FETCH_WORKSPACES_REPORT_BOOKMARKS_REQUEST = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_REPORT_BOOKMARKS_REQUEST';
export var FETCH_WORKSPACES_REPORT_BOOKMARKS_SUCCESS = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_REPORT_BOOKMARKS_SUCCESS';
export var FETCH_WORKSPACES_REPORT_BOOKMARKS_FAILURE = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_REPORT_BOOKMARKS_FAILURE';
export var CREATE_WORKSPACES_REPORT_BOOKMARK_REQUEST = 'timecrowd/WORKSPACES/CREATE_WORKSPACES_REPORT_BOOKMARK_REQUEST';
export var CREATE_WORKSPACES_REPORT_BOOKMARK_SUCCESS = 'timecrowd/WORKSPACES/CREATE_WORKSPACES_REPORT_BOOKMARK_SUCCESS';
export var CREATE_WORKSPACES_REPORT_BOOKMARK_FAILURE = 'timecrowd/WORKSPACES/CREATE_WORKSPACES_REPORT_BOOKMARK_FAILURE';
export var UPDATE_WORKSPACES_REPORT_BOOKMARK_REQUEST = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_REPORT_BOOKMARK_REQUEST';
export var UPDATE_WORKSPACES_REPORT_BOOKMARK_SUCCESS = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_REPORT_BOOKMARK_SUCCESS';
export var UPDATE_WORKSPACES_REPORT_BOOKMARK_FAILURE = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_REPORT_BOOKMARK_FAILURE';
export var DELETE_WORKSPACES_REPORT_BOOKMARK_REQUEST = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_REPORT_BOOKMARK_REQUEST';
export var DELETE_WORKSPACES_REPORT_BOOKMARK_SUCCESS = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_REPORT_BOOKMARK_SUCCESS';
export var DELETE_WORKSPACES_REPORT_BOOKMARK_FAILURE = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_REPORT_BOOKMARK_FAILURE';
export var RESET_WORKSPACES_REPORT_BOOKMARKS = 'timecrowd/WORKSPACES/RESET_WORKSPACES_REPORT_BOOKMARKS';
export var initialState = {
    status: 'pending',
    items: [],
};
export default function workspaces(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_WORKSPACES_REPORT_BOOKMARKS_REQUEST:
        case CREATE_WORKSPACES_REPORT_BOOKMARK_REQUEST:
        case UPDATE_WORKSPACES_REPORT_BOOKMARK_REQUEST:
        case DELETE_WORKSPACES_REPORT_BOOKMARK_REQUEST:
            return __assign(__assign({}, state), { status: 'loading' });
        case FETCH_WORKSPACES_REPORT_BOOKMARKS_SUCCESS:
            return __assign(__assign({}, state), { status: 'success', items: action.payload });
        case CREATE_WORKSPACES_REPORT_BOOKMARK_SUCCESS:
            return __assign(__assign({}, state), { status: 'success', items: __spreadArray(__spreadArray([], state.items, true), [action.payload], false) });
        case UPDATE_WORKSPACES_REPORT_BOOKMARK_SUCCESS:
            return __assign(__assign({}, state), { status: 'success', items: state.items.map(function (item) {
                    return item.id === action.payload.id ? action.payload : item;
                }) });
        case DELETE_WORKSPACES_REPORT_BOOKMARK_SUCCESS:
            return __assign(__assign({}, state), { status: 'success', items: state.items.filter(function (item) { return item.id !== action.payload; }) });
        case FETCH_WORKSPACES_REPORT_BOOKMARKS_FAILURE:
        case CREATE_WORKSPACES_REPORT_BOOKMARK_FAILURE:
        case UPDATE_WORKSPACES_REPORT_BOOKMARK_FAILURE:
        case DELETE_WORKSPACES_REPORT_BOOKMARK_FAILURE:
            return __assign(__assign({}, state), { status: 'failure' });
        case RESET_WORKSPACES_REPORT_BOOKMARKS:
            return initialState;
        default:
            return state;
    }
}
export var fetchWorkspacesReportBookmarksRequest = function () { return ({
    type: FETCH_WORKSPACES_REPORT_BOOKMARKS_REQUEST,
}); };
export var fetchWorkspacesReportBookmarksSuccess = function (items) { return ({
    type: FETCH_WORKSPACES_REPORT_BOOKMARKS_SUCCESS,
    payload: items,
}); };
export var fetchWorkspacesReportBookmarksFailure = function () { return ({
    type: FETCH_WORKSPACES_REPORT_BOOKMARKS_FAILURE,
}); };
export var fetchWorkspacesReportBookmarks = function (params) {
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesReportBookmarksRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/report_bookmarks?".concat(queryString.stringify(params)), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(fetchWorkspacesReportBookmarksSuccess(item));
                        return [2 /*return*/, { error: false, data: item }];
                    case 2:
                        dispatch(fetchWorkspacesReportBookmarksFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var createWorkspacesReportBookmarkRequest = function () { return ({
    type: CREATE_WORKSPACES_REPORT_BOOKMARK_REQUEST,
}); };
export var createWorkspacesReportBookmarkSuccess = function (item) { return ({
    type: CREATE_WORKSPACES_REPORT_BOOKMARK_SUCCESS,
    payload: item,
}); };
export var createWorkspacesReportBookmarkFailure = function () { return ({
    type: CREATE_WORKSPACES_REPORT_BOOKMARK_FAILURE,
}); };
export var createWorkspacesReportBookmark = function (params) {
    return function (dispatch, getState) {
        dispatch(createWorkspacesReportBookmarkRequest());
        return api2(dispatch, getState, '/api/v2/workspaces/report_bookmarks', 'POST', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(createWorkspacesReportBookmarkSuccess(item));
                        return [2 /*return*/, { error: false, data: item }];
                    case 2:
                        dispatch(createWorkspacesReportBookmarkFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateWorkspacesReportBookmarkRequest = function () { return ({
    type: UPDATE_WORKSPACES_REPORT_BOOKMARK_REQUEST,
}); };
export var updateWorkspacesReportBookmarkSuccess = function (item) { return ({
    type: UPDATE_WORKSPACES_REPORT_BOOKMARK_SUCCESS,
    payload: item,
}); };
export var updateWorkspacesReportBookmarkFailure = function () { return ({
    type: UPDATE_WORKSPACES_REPORT_BOOKMARK_FAILURE,
}); };
export var updateWorkspacesReportBookmark = function (params) {
    return function (dispatch, getState) {
        dispatch(updateWorkspacesReportBookmarkRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/report_bookmarks/".concat(params.id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(updateWorkspacesReportBookmarkSuccess(item));
                        return [2 /*return*/, { error: false, data: item }];
                    case 2:
                        dispatch(updateWorkspacesReportBookmarkFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var deleteWorkspacesReportBookmarkRequest = function () { return ({
    type: DELETE_WORKSPACES_REPORT_BOOKMARK_REQUEST,
}); };
export var deleteWorkspacesReportBookmarkSuccess = function (id) { return ({
    type: DELETE_WORKSPACES_REPORT_BOOKMARK_SUCCESS,
    payload: id,
}); };
export var deleteWorkspacesReportBookmarkFailure = function () { return ({
    type: DELETE_WORKSPACES_REPORT_BOOKMARK_FAILURE,
}); };
export var deleteWorkspacesReportBookmark = function (params) {
    return function (dispatch, getState) {
        dispatch(deleteWorkspacesReportBookmarkRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/report_bookmarks/".concat(params.id), 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(deleteWorkspacesReportBookmarkSuccess(params.id));
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(deleteWorkspacesReportBookmarkFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
export var resetWorkspacesReportBookmarks = function () { return ({
    type: RESET_WORKSPACES_REPORT_BOOKMARKS,
}); };
