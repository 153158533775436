import { __spreadArray } from "tslib";
import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import InfiniteCalendar, { withRange, Calendar } from 'react-infinite-calendar';
import I18n from '@/constants/i18n';
import { days } from '@/types';
import CustomCheck from '@/components/atoms/custom_check';
import { formatDate } from '@/utils/time_helper';
import Icon from '@/components/atoms/icon';
export var ReminderSendTimeField = function (_a) {
    var index = _a.index, reminderEmailsSetting = _a.reminderEmailsSetting, activeHolidayCalendarIndex = _a.activeHolidayCalendarIndex, onChangeReminderEmailsSetting = _a.onChangeReminderEmailsSetting, setActiveHolidayCalendarIndex = _a.setActiveHolidayCalendarIndex;
    return (<div className="row mt-3">
      <div className="col-3">
        {I18n.t('shared.reminderSendTime')}
        <br />({I18n.t('shared.businessDay')})
      </div>
      <div className="col">
        <p className="small">{I18n.t('shared.reminderDay')}</p>
        {days.map(function (day) { return (<CustomCheck key={day} checked={reminderEmailsSetting.days_of_week.includes(day)} onChange={function () {
                onChangeReminderEmailsSetting(index, {
                    days_of_week: reminderEmailsSetting.days_of_week.includes(day)
                        ? reminderEmailsSetting.days_of_week.filter(function (d) { return d !== day; })
                        : __spreadArray(__spreadArray([], reminderEmailsSetting.days_of_week, true), [day], false),
                });
            }}>
            {I18n.t("shared.reminderDays.".concat(day))}
          </CustomCheck>); })}
        <CustomCheck checked={reminderEmailsSetting.include_holidays} className="d-block mt-1" onChange={function (e) {
            onChangeReminderEmailsSetting(index, {
                include_holidays: e.target.checked,
            });
        }}>
          {I18n.t('shared.includeHolidays')}
        </CustomCheck>

        <p className="small mt-3">{I18n.t('shared.reminderTime')}</p>
        <input className="form-control w-auto" type="time" value={reminderEmailsSetting.send_time_text} onChange={function (e) {
            onChangeReminderEmailsSetting(index, {
                send_time_text: e.target.value,
            });
        }}/>

        <p className="small mt-3">{I18n.t('shared.reminderIgnore')}</p>
        {__spreadArray(__spreadArray([], reminderEmailsSetting.ignored_periods, true), [{}], false).map(function (_a, j) {
            var start = _a.start, end = _a.end;
            var isNew = !start;
            var calendarIndex = "".concat(index, "-").concat(j);
            var isOpen = activeHolidayCalendarIndex === calendarIndex;
            return (<div key={j} className="mb-2">
                <ButtonDropdown key={j} isOpen={isOpen} toggle={function () {
                    return setActiveHolidayCalendarIndex(isOpen ? '' : calendarIndex);
                }}>
                  <DropdownToggle caret>
                    {isNew
                    ? I18n.t('shared.addReminderIgnore')
                    : "".concat(formatDate(new Date(start)), " - ").concat(formatDate(new Date(end)))}
                  </DropdownToggle>
                  <DropdownMenu>
                    <InfiniteCalendar className="calendar-sm" Component={withRange(Calendar)} displayOptions={{
                    showHeader: true,
                    showWeekdays: false,
                    shouldHeaderAnimate: true,
                }} height={276} locale={I18n.t('infiniteCalendar')} rowHeight={48} selected={isNew
                    ? { start: new Date(), end: new Date() }
                    : { start: start, end: end }} theme={{
                    floatingNav: {
                        chevron: '#4abaa4',
                    },
                }} width="100%" onSelect={function (_a) {
                    var start = _a.start, end = _a.end, eventType = _a.eventType;
                    // NOTE: 終了日を選ぶと3になる
                    if (eventType !== 3) {
                        return;
                    }
                    var ignored_periods = isNew
                        ? __spreadArray(__spreadArray([], reminderEmailsSetting.ignored_periods, true), [
                            { start: start, end: end },
                        ], false) : reminderEmailsSetting.ignored_periods.map(function (holiday, k) {
                        return k === j ? { start: start, end: end } : holiday;
                    });
                    onChangeReminderEmailsSetting(index, {
                        ignored_periods: ignored_periods,
                    });
                    setActiveHolidayCalendarIndex('');
                }}/>
                  </DropdownMenu>
                </ButtonDropdown>
                {!isNew && (<button className="btn btn-link text-secondary" onClick={function () {
                        onChangeReminderEmailsSetting(index, {
                            ignored_periods: reminderEmailsSetting.ignored_periods.filter(function (ignore, k) { return k !== j; }),
                        });
                    }}>
                    <Icon name="cross"/>
                  </button>)}
              </div>);
        })}
      </div>
    </div>);
};
