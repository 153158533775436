import { __assign } from "tslib";
import sortBy from 'lodash/sortBy';
import { incrementCategoryPosition, decrementCategoryPosition, } from './helpers';
// Actions
export var SET_CATEGORIES = 'timecrowd/categories/SET_CATEGORIES';
export var CHANGE_CATEGORY = 'timecrowd/categories/CHANGE_CATEGORY';
export var MOVE_CATEGORY_LOWER_SUCCESS = 'timecrowd/categories/MOVE_CATEGORY_LOWER_SUCCESS';
export var MOVE_CATEGORY_HIGHER_SUCCESS = 'timecrowd/categories/MOVE_CATEGORY_HIGHER_SUCCESS';
export var CHANGE_CATEGORY_POSITION_LOWER_SUCCESS = 'timecrowd/categories/CHANGE_CATEGORY_POSITION_LOWER_SUCCESS';
export var CHANGE_CATEGORY_POSITION_HIGHER_SUCCESS = 'timecrowd/categories/CHANGE_CATEGORY_POSITION_HIGHER_SUCCESS';
// Reducer
var initialState = [];
export var ignoreArchivedCategories = function (categories) {
    return categories.filter(function (_a) {
        var archived = _a.archived;
        return !archived;
    });
};
export var ignoreReadonlyTeamCategories = function (categories) {
    return categories.filter(function (_a) {
        var tasks_readonly = _a.team.tasks_readonly;
        return !tasks_readonly;
    });
};
function isSamePosition(category, newCategory) {
    return (category.team_id === newCategory.team_id &&
        category.id !== newCategory.id &&
        category.ancestry === newCategory.ancestry &&
        category.position === newCategory.position);
}
function decrement(position) {
    return position - 1;
}
function increment(position) {
    return position + 1;
}
function moveCategory(category, newCategory, func) {
    if (isSamePosition(category, newCategory)) {
        var position = func(category.position);
        return __assign(__assign({}, category), { position: position });
    }
    else {
        if (category.id === newCategory.id) {
            return newCategory;
        }
        else {
            return category;
        }
    }
}
function moveCategoryLower(categories, newCategory) {
    return categories.map(function (category) {
        return moveCategory(category, newCategory, decrement);
    });
}
function moveCategoryHigher(categories, newCategory) {
    return categories.map(function (category) {
        return moveCategory(category, newCategory, increment);
    });
}
export function isChild(parentCategory, category) {
    return (parentCategory.id === category.parent_id &&
        parentCategory.team_id === category.team_id &&
        parentCategory.ancestry_depth + 1 === category.ancestry_depth);
}
export function sortByEachRoots(categories) {
    var sortedCategories = [];
    var roots = sortBy(categories.filter(function (category) { return category.ancestry_depth === 0; }), 'position');
    var sortByParentCategory = function (parentCategory) {
        sortedCategories.push(parentCategory);
        var children = categories.filter(function (category) {
            return isChild(parentCategory, category);
        });
        if (children.length) {
            sortBy(children, 'position').forEach(sortByParentCategory);
        }
    };
    roots.forEach(sortByParentCategory);
    return sortedCategories;
}
export default function categories(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_CATEGORIES:
            return sortByEachRoots(action.categories);
        case CHANGE_CATEGORY:
            return sortByEachRoots(state.map(function (category) {
                if (category.id === action.category.id) {
                    return __assign(__assign({}, category), action.category);
                }
                else {
                    return category;
                }
            }));
        case MOVE_CATEGORY_LOWER_SUCCESS:
            return sortByEachRoots(moveCategoryLower(state, action.category));
        case MOVE_CATEGORY_HIGHER_SUCCESS:
            return sortByEachRoots(moveCategoryHigher(state, action.category));
        case CHANGE_CATEGORY_POSITION_LOWER_SUCCESS:
            return sortByEachRoots(state.map(function (category) {
                if (category.id === action.category.id) {
                    return action.category;
                }
                else {
                    return incrementCategoryPosition(category, action.category, action.beforePosition);
                }
            }));
        case CHANGE_CATEGORY_POSITION_HIGHER_SUCCESS:
            return sortByEachRoots(state.map(function (category) {
                if (category.id === action.category.id) {
                    return action.category;
                }
                else {
                    return decrementCategoryPosition(category, action.category, action.beforePosition);
                }
            }));
        default:
            return state;
    }
}
export var setCategories = function (categories) { return ({
    type: SET_CATEGORIES,
    categories: categories,
}); };
export var changeCategory = function (category) { return ({
    type: CHANGE_CATEGORY,
    category: category,
}); };
export var moveCategoryLowerSuccess = function (category) { return ({
    type: MOVE_CATEGORY_LOWER_SUCCESS,
    category: category,
}); };
export var moveCategoryHigherSuccess = function (category) { return ({
    type: MOVE_CATEGORY_HIGHER_SUCCESS,
    category: category,
}); };
export var changeCategoryPositionLowerSuccess = function (category, beforePosition) { return ({
    type: CHANGE_CATEGORY_POSITION_LOWER_SUCCESS,
    category: category,
    beforePosition: beforePosition,
}); };
export var changeCategoryPositionHigherSuccess = function (category, beforePosition) { return ({
    type: CHANGE_CATEGORY_POSITION_HIGHER_SUCCESS,
    category: category,
    beforePosition: beforePosition,
}); };
