import React from 'react';
import { TeamReportDetailsTooltip } from './tooltip';
export var WithTooltip = function (_a) {
    var categories = _a.categories, targetId = _a.targetId, users = _a.users, category = _a.category, task = _a.task, tasksByCategoryId = _a.tasksByCategoryId, timeEntries = _a.timeEntries, showsTooltip = _a.showsTooltip, children = _a.children;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var onMouseEnter = React.useCallback(function () {
        setIsOpen(true);
    }, []);
    var onMouseLeave = React.useCallback(function () {
        setIsOpen(false);
    }, []);
    return (<div className="d-flex" id={targetId} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
      {showsTooltip && (<TeamReportDetailsTooltip categories={categories} category={category} isOpen={isOpen} targetId={targetId} task={task} tasksByCategoryId={tasksByCategoryId} timeEntries={timeEntries} users={users}/>)}
    </div>);
};
