import React from 'react';
import I18n from '@/constants/i18n';
import Blank from '@/components/molecules/blank';
import { workspacePath } from '@/utils/workspace_helper';
export var BlankReport = function (_a) {
    var paymentTeam = _a.paymentTeam, onNavigateToPayment = _a.onNavigateToPayment;
    return (<Blank description={paymentTeam && onNavigateToPayment ? (paymentTeam.can_manage ? (<span>
              {I18n.t('report.blankDescriptionForPaymentPrefix')}
              <a href={workspacePath("/teams/".concat(paymentTeam.id, "/payment"))} onClick={function (e) {
                e.preventDefault();
                onNavigateToPayment(paymentTeam.id);
            }}>
                {I18n.t('shared.here')}
              </a>
              {I18n.t('report.blankDescriptionForPaymentSuffix')}
            </span>) : (I18n.t('report.blankDescriptionForPaymentMember'))) : (<span dangerouslySetInnerHTML={{
                __html: I18n.t('report.blankDescription'),
            }}/>)} title={I18n.t("report.blankTitle".concat(paymentTeam ? 'ForPayment' : ''))}/>);
};
