import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LongTermReportTab } from '@/components/cross_team_report_header/long_term_report_tab';
import { listWorkspacesLongTermReports, createWorkspacesLongTermReport, cancelWorkspacesLongTermReport, setCurrentWorkspacesLongTermReport, } from '@/modules/workspaces_long_term_reports';
import { useWorkspaceId } from '@/hooks/use_workspace_id';
export var LongTermReportTabContainer = function (props) {
    var isOpen = props.isOpen, startMonth = props.startMonth, onChangeStartMonth = props.onChangeStartMonth, onClose = props.onClose;
    var workspaceId = useWorkspaceId();
    var dispatch = useDispatch();
    var isLoading = useSelector(function (state) { return state.workspacesLongTermReports.status === 'loading'; });
    var generatingReport = useSelector(function (state) {
        return state.workspacesLongTermReports.items.find(function (report) {
            return ['pending', 'processing'].includes(report.status);
        });
    });
    var handleSearch = React.useCallback(function (params) {
        return dispatch(listWorkspacesLongTermReports(workspaceId, params));
    }, [dispatch, workspaceId]);
    var handleUseCache = React.useCallback(function (report) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(setCurrentWorkspacesLongTermReport(report));
            onClose();
            return [2 /*return*/];
        });
    }); }, [dispatch, onClose]);
    var handleGenerate = React.useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(createWorkspacesLongTermReport(params))];
                case 1:
                    res = _a.sent();
                    if (!res.error) {
                        onClose();
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch, onClose]);
    var handleCancelGenerate = React.useCallback(function () {
        dispatch(cancelWorkspacesLongTermReport(generatingReport.id));
    }, [dispatch, generatingReport]);
    return (<LongTermReportTab generatingReport={generatingReport} isLoading={isLoading} isOpen={isOpen} startMonth={startMonth} onCancelGenerate={handleCancelGenerate} onChangeStartMonth={onChangeStartMonth} onClose={onClose} onGenerate={handleGenerate} onSearch={handleSearch} onUseCache={handleUseCache}/>);
};
