import React from 'react';
import { useNavigate } from 'react-router-dom';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import { workspacePath } from '@/utils/workspace_helper';
export var WorkspacesTeamListItem = function (_a) {
    var team = _a.team, memberships = _a.memberships, isPersonal = _a.isPersonal, toggleTeamMenu = _a.toggleTeamMenu;
    var navigate = useNavigate();
    var onClickLink = function (e) {
        e.preventDefault();
        toggleTeamMenu(false);
        navigate(e.currentTarget.getAttribute('href'));
    };
    return (<div className="category-list-item__container d-flex mt-2 py-3 pl-2 pr-3 align-items-center">
      <Avatar image={team.avatar_url}/>
      <div className="ml-1 d-flex align-items-center">
        <div className="text-break font-weight-bold">{team.name}</div>
        <div className="mx-4 text-nowrap">
          {I18n.t('shared.nMembers', { num: memberships.length })}
        </div>
      </div>
      <div className="ml-auto text-nowrap">
        {team.can_manage && !isPersonal && (<a className="btn btn-link p-0 ml-0" href={workspacePath("/teams/".concat(team.id, "/members?tab=invitation"))} onClick={onClickLink}>
            {I18n.t('shared.inviteToTeam')}
          </a>)}
        <a className="btn btn-link p-0 ml-3" href={workspacePath("/teams/".concat(team.id, "/members"))} onClick={onClickLink}>
          {I18n.t('shared.settings')}
        </a>
      </div>
    </div>);
};
