import { __assign } from "tslib";
export var SET_NOTIFICATION_MESSAGE = 'timecrowd/notification/SET_NOTIFICATION_MESSAGE';
export var REMOVE_NOTIFICATION_MESSAGE = 'timecrowd/notification/REMOVE_NOTIFICATION_MESSAGE';
export var REMOVE_DANGER_NOTIFICATION_MESSAGE = 'timecrowd/notification/REMOVE_DANGER_NOTIFICATION_MESSAGE';
export var REMOVE_OUTDATED_NOTIFICATION_MESSAGE = 'timecrowd/notification/REMOVE_OUTDATED_NOTIFICATION_MESSAGE';
export var initialState = {
    messageType: 'info',
    message: '',
    isShown: false,
    isHtml: false,
    isPermanent: false,
    timestamp: null,
};
var OUTDATE_DURATION = 5000;
export default function notification(state, action) {
    if (state === void 0) { state = initialState; }
    var options = action.options || { isHtml: false, isPermanent: false };
    switch (action.type) {
        case SET_NOTIFICATION_MESSAGE:
            return __assign(__assign({}, state), { messageType: action.messageType, message: action.message, isShown: true, isHtml: options.isHtml, isPermanent: options.isPermanent, timestamp: new Date().getTime() });
        case REMOVE_DANGER_NOTIFICATION_MESSAGE:
            if (state.messageType === 'danger') {
                return initialState;
            }
            else {
                return state;
            }
        case REMOVE_OUTDATED_NOTIFICATION_MESSAGE:
            if (state.timestamp &&
                new Date().getTime() - state.timestamp > OUTDATE_DURATION) {
                return initialState;
            }
            else {
                return state;
            }
        case REMOVE_NOTIFICATION_MESSAGE:
            return initialState;
        default:
            return state;
    }
}
export var setNotificationMessage = function (messageType, message, options) { return ({
    type: SET_NOTIFICATION_MESSAGE,
    messageType: messageType,
    message: message,
    options: options,
}); };
export var removeNotificationMessage = function () {
    return {
        type: REMOVE_NOTIFICATION_MESSAGE,
    };
};
export var removeDangerNotificationMessage = function () {
    return {
        type: REMOVE_DANGER_NOTIFICATION_MESSAGE,
    };
};
export var removeOutdatedNotificationMessage = function () {
    return {
        type: REMOVE_OUTDATED_NOTIFICATION_MESSAGE,
    };
};
