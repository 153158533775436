import { __assign } from "tslib";
import React from 'react';
import { UncontrolledDropdown, UncontrolledTooltip, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '@/constants/i18n';
import { FiltersForm } from './filters_form';
import { CategoryDropdown } from './category_dropdown';
import { DeleteModal } from './delete_modal';
import Helplink from '@/components/molecules/helplink';
import { RAILS_ENV } from '@/constants/rails_env';
import { ignoreArchivedCategories, ignoreReadonlyTeamCategories, } from '@/modules/categories';
import { imageSource } from '@/utils/image_helper';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import { CalendarIntegrationAutoStartSetting } from '@/components/calendar_integration_auto_start_setting';
import { CalendarIntegrationIgnoreWordsSetting } from '@/components/calendar_integration_ignore_words_setting';
import { CalendarIntegrationIgnoreMinutesSetting } from '@/components/calendar_integration_ignore_minutes_setting';
import CustomRadio from '@/components/atoms/custom_radio';
import Icon from '@/components/atoms/icon';
import WorkspacesCalendarIntegrationWorkspaceSelectContainer from '@/containers/workspaces_calendar_integration_workspace_select_container';
import CalendarIntegrationSettingWorkspacePartsContainer from '@/containers/calendar_integration_setting_workspace_parts_container';
var isDemo = RAILS_ENV === 'demo';
var calendarIntegrationListLabel = function (calendarIntegrationList) {
    if (!calendarIntegrationList) {
        return;
    }
    return "".concat(calendarIntegrationList.summary, " - ").concat(calendarIntegrationList.resource_identifier);
};
export var EditForm = function (_a) {
    var _b;
    var calendarIntegrationSetting = _a.calendarIntegrationSetting, calendarIntegrationLists = _a.calendarIntegrationLists, calendarIntegrationFilters = _a.calendarIntegrationFilters, identities = _a.identities, categories = _a.categories, canIntegrate = _a.canIntegrate, user = _a.user, isCalendarIntegrationListsIsLoading = _a.isCalendarIntegrationListsIsLoading, isUnrecoverable = _a.isUnrecoverable, onDeleteIntegration = _a.onDeleteIntegration, setCalendarIntegrationSetting = _a.setCalendarIntegrationSetting, setCalendarIntegrationFilters = _a.setCalendarIntegrationFilters, setCalendarIntegrationLists = _a.setCalendarIntegrationLists, openURL = _a.openURL, activateDefaultList = _a.activateDefaultList, setNotificationMessage = _a.setNotificationMessage;
    var _c = React.useState(calendarIntegrationSetting.ignore_minutes > 0), isIgnoreMinutes = _c[0], setIsIgnoreMinutes = _c[1];
    var _d = React.useState(calendarIntegrationSetting.ignore_minutes), ignoreMinutes = _d[0], setIgnoreMinutes = _d[1];
    var _e = React.useState(calendarIntegrationSetting.base_type), baseType = _e[0], setBaseType = _e[1];
    var _f = React.useState(calendarIntegrationSetting.workspaces_workspace_id), workspaceId = _f[0], setWorkspaceId = _f[1];
    var tooltipTarget = React.useRef(null);
    var onClickLink = React.useCallback(function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    }, [openURL]);
    var identity = identities.find(function (_a) {
        var id = _a.id;
        return id === calendarIntegrationSetting.identity_id;
    });
    var isAutoStart = calendarIntegrationSetting.start_mode !== 'none';
    var filteredCategories = ignoreArchivedCategories(ignoreReadonlyTeamCategories(categories));
    var handleChangeBaseType = React.useCallback(function (e) {
        setBaseType(e.target.value);
        setWorkspaceId(calendarIntegrationSetting.workspaces_workspace_id);
        setCalendarIntegrationSetting(__assign(__assign({}, calendarIntegrationSetting), { base_type: e.target.value }));
    }, [calendarIntegrationSetting, setCalendarIntegrationSetting]);
    var handleSelectWorkspace = React.useCallback(function (workspaceId) {
        setWorkspaceId(workspaceId);
        setCalendarIntegrationSetting(__assign(__assign({}, calendarIntegrationSetting), { workspaces_workspace_id: workspaceId }));
    }, [calendarIntegrationSetting, setCalendarIntegrationSetting]);
    var handleChangeAutoStart = React.useCallback(function (_a) {
        var checked = _a.target.checked;
        return setCalendarIntegrationSetting(__assign(__assign({}, calendarIntegrationSetting), { start_mode: checked ? 'auto' : 'none', stop_mode: checked ? 'auto' : 'none' }));
    }, [calendarIntegrationSetting, setCalendarIntegrationSetting]);
    var handleChangeStartMode = function (start_mode) {
        setCalendarIntegrationSetting(__assign(__assign({}, calendarIntegrationSetting), { start_mode: start_mode }));
    };
    var handleChangeStopMode = function (stop_mode) {
        setCalendarIntegrationSetting(__assign(__assign({}, calendarIntegrationSetting), { stop_mode: stop_mode }));
    };
    var handleChangeIgnoreWords = function (e) {
        setCalendarIntegrationSetting(__assign(__assign({}, calendarIntegrationSetting), { ignore_words_text: e.target.value }));
    };
    var handleIsIgnoreMinutes = function () {
        setIsIgnoreMinutes(!isIgnoreMinutes);
    };
    var handleChangeIgnoreMinutes = function (e) {
        setIgnoreMinutes(e.target.value);
        setCalendarIntegrationSetting(__assign(__assign({}, calendarIntegrationSetting), { ignore_minutes: parseInt(e.target.value) || 0 }));
    };
    // NOTE: UncontrolledTooltipのトリガーがラベル全体になってしまうのを防ぐ
    var handlePreventClick = function (e) {
        e.preventDefault();
    };
    React.useEffect(function () {
        if (!isIgnoreMinutes && calendarIntegrationSetting.ignore_minutes !== 0) {
            setIgnoreMinutes(0);
            setCalendarIntegrationSetting(__assign(__assign({}, calendarIntegrationSetting), { ignore_minutes: 0 }));
        }
    }, [
        calendarIntegrationSetting,
        isIgnoreMinutes,
        setCalendarIntegrationSetting,
    ]);
    React.useEffect(function () {
        if ((identity === null || identity === void 0 ? void 0 : identity.provider) !== 'outlook' ||
            calendarIntegrationLists.length < 1 ||
            calendarIntegrationLists[0].is_active) {
            return;
        }
        activateDefaultList();
    }, [identity === null || identity === void 0 ? void 0 : identity.provider, calendarIntegrationLists, activateDefaultList]);
    if (!identity) {
        return null;
    }
    var selectedCalendarIntegrationList = calendarIntegrationLists.find(function (_a) {
        var is_active = _a.is_active;
        return is_active;
    });
    var provider = identity.provider;
    var isLoadingCategories = categories.length < 1;
    return (<div>
      <div className="form-group">
        <label className="font-weight-bold mb-0">
          {I18n.t('shared.linkedAccount')}
        </label>
        <p className="text-muted mb-0">
          <img className="avatar avatar-md rounded-0 mr-1" src={imageSource("sign_in_with_".concat(identity.provider, "_logo.png"))}/>
          {identity.info_email}
        </p>
        {!isDemo && (<div>
            {isUnrecoverable ? (<div className="my-3" dangerouslySetInnerHTML={{
                    __html: I18n.t('shared.unrecoverableError'),
                }}/>) : (<p className="mt-1 mb-0">
                {I18n.t('shared.changeLinkedAccount')}
              </p>)}
            {canIntegrate ? (<p>
                <a href="/oauth_scopes/new?provider=google&scope_name=calendar" onClick={function (e) {
                    var _a;
                    if (((_a = user.allowed_sign_in_methods) === null || _a === void 0 ? void 0 : _a.length) &&
                        !user.allowed_sign_in_methods.includes('google')) {
                        setNotificationMessage('danger', I18n.t('shared.operationRestrictedByAdministrator'));
                        e.preventDefault();
                    }
                }}>
                  {I18n.t('shared.integrateWithGoogleCalendar')}
                </a>
                <a className="ml-3" href="/oauth_scopes/new?provider=outlook&scope_name=calendar" onClick={function (e) {
                    var _a;
                    if (((_a = user.allowed_sign_in_methods) === null || _a === void 0 ? void 0 : _a.length) &&
                        !user.allowed_sign_in_methods.includes('outlook')) {
                        setNotificationMessage('danger', I18n.t('shared.operationRestrictedByAdministrator'));
                        e.preventDefault();
                    }
                }}>
                  {I18n.t('shared.integrateWithOutlookCalendar')}
                </a>
              </p>) : (<p className="mt-1">
                {I18n.t('shared.pleaseChangeAccountWithBrowser')}
                <br />
                <a href="https://timecrowd.net/app/personal_settings/calendar_integration" onClick={onClickLink}>
                  {I18n.t('shared.openTimeCrowdOnBrowser')}
                </a>
              </p>)}
          </div>)}
      </div>

      {!isUnrecoverable && (<>
          <div className="form-group mt-3">
            <label className="font-weight-bold">
              {I18n.t('shared.linkedCalendar')}
            </label>
            <div>
              {provider === 'outlook' ? (<span className="text-muted">
                  {I18n.t('shared.outlookCalendar')}
                </span>) : (<>
                  <UncontrolledDropdown>
                    <DropdownToggle caret className="max-w-lg" disabled={calendarIntegrationLists.length < 1 || isDemo}>
                      <span className="text-truncate">
                        {(_b = calendarIntegrationListLabel(selectedCalendarIntegrationList)) !== null && _b !== void 0 ? _b : I18n.t('shared.pleaseSelect')}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu>
                      {calendarIntegrationLists.map(function (calendarIntegrationList) { return (<DropdownItem key={calendarIntegrationList.id} onClick={function () {
                        return setCalendarIntegrationLists(calendarIntegrationLists.map(function (list) { return (__assign(__assign({}, list), { is_active: list.id === calendarIntegrationList.id })); }));
                    }}>
                            {calendarIntegrationListLabel(calendarIntegrationList)}
                          </DropdownItem>); })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {!isCalendarIntegrationListsIsLoading &&
                    selectedCalendarIntegrationList === undefined && (<p className="mt-1 text-danger">
                        {I18n.t('shared.pleaseSelectList')}
                      </p>)}
                </>)}
            </div>
          </div>

          {(isLoadingCategories || filteredCategories.length > 0) && (<div className="form-group mt-3">
              <label className="font-weight-bold">
                {I18n.t('shared.defaultTeamCategory')}
                <Helplink className="ml-1" href={"https://help.timecrowd.net/".concat(provider, "-calendar#team-and-category")} onClick={onClickLink}/>
              </label>
              <div className="d-flex align-items-center">
                <CategoryDropdown categories={filteredCategories} disabled={isLoadingCategories} selectedId={calendarIntegrationSetting.category_id} onSelect={function (category_id) {
                    return setCalendarIntegrationSetting(__assign(__assign({}, calendarIntegrationSetting), { category_id: category_id }));
                }}/>
                {isLoadingCategories && (<div className="position-relative ml-1">
                    <LoadingSpinner size="md"/>
                  </div>)}
              </div>
            </div>)}

          <>
            <hr className="mt-4"/>

            {user.enabled_features.includes('workspace_calendar_integration') && (<>
                <label className="font-weight-bold d-flex align-items-center" onClick={handlePreventClick}>
                  {I18n.t('shared.selectCalendarIntegrationSettingBaseType')}
                  <button ref={tooltipTarget} className="btn btn-link p-0 ml-1">
                    <Icon name="question" size="md"/>
                  </button>
                </label>

                <UncontrolledTooltip fade={false} innerClassName="max-w-lg text-left mb-1" placement="top" target={tooltipTarget} trigger="legacy">
                  <p dangerouslySetInnerHTML={{
                    __html: I18n.t('shared.calendarIntegrationSettingsBaseTypeDescriptionHtml'),
                }}/>
                  <p className="text-right mb-0">
                    <u>
                      <a className="text-reset text-underline" href="https://support.timecrowd.net/calendar-auto-track-setting" onClick={onClickLink}>
                        {I18n.t('shared.clickHereForDetails')}
                      </a>
                    </u>
                  </p>
                </UncontrolledTooltip>

                <div className="d-flex">
                  <CustomRadio checked={baseType === 'personal'} value={'personal'} onChange={handleChangeBaseType}>
                    {I18n.t('shared.calendarIntegrationSettingsBaseTypePersonal')}
                  </CustomRadio>
                  <CustomRadio checked={baseType === 'workspace'} value={'workspace'} onChange={handleChangeBaseType}>
                    {I18n.t('shared.calendarIntegrationSettingsBaseTypeWorkspace')}
                  </CustomRadio>
                </div>

                {baseType === 'workspace' && (<div className="mt-3">
                    <label className="font-weight-bold">
                      {I18n.t('shared.calendarIntegrationSelectWorkspace')}
                    </label>
                    <div className="d-flex">
                      <div className="d-inline-block">
                        <WorkspacesCalendarIntegrationWorkspaceSelectContainer workspaceId={workspaceId} onSelect={handleSelectWorkspace}/>
                      </div>
                    </div>
                  </div>)}
              </>)}

            {baseType === 'personal' && (<>
                <CalendarIntegrationAutoStartSetting helplink="https://help.timecrowd.net/various-calendar-options#auto-start" isAutoStart={isAutoStart} startMode={calendarIntegrationSetting.start_mode} stopMode={calendarIntegrationSetting.stop_mode} onChangeAutoStart={handleChangeAutoStart} onChangeStartMode={handleChangeStartMode} onChangeStopMode={handleChangeStopMode} onClickLink={onClickLink}/>

                <div className="form-group mt-3">
                  <label className="font-weight-bold">
                    {I18n.t('shared.filtering')}
                    <Helplink className="ml-1" href="https://support.timecrowd.net/1428ec2d2afa8015ab10d96ca21b9686" onClick={onClickLink}/>
                  </label>
                  <p className="text-muted small">
                    {I18n.t('shared.filteringNote')}
                  </p>
                  <div>
                    <FiltersForm calendarIntegrationFilters={calendarIntegrationFilters} categories={filteredCategories} setCalendarIntegrationFilters={setCalendarIntegrationFilters}/>
                  </div>
                </div>

                <CalendarIntegrationIgnoreWordsSetting ignoreWordsText={calendarIntegrationSetting.ignore_words_text} onChangeIgnoreWords={handleChangeIgnoreWords} onClickLink={onClickLink}/>

                <CalendarIntegrationIgnoreMinutesSetting ignoreMinutes={ignoreMinutes} isIgnoreMinutes={isIgnoreMinutes} onChangeIgnoreMinutes={handleChangeIgnoreMinutes} onChangeIsIgnoreMinutes={handleIsIgnoreMinutes} onClickLink={onClickLink}/>
              </>)}
            {baseType === 'workspace' && (<CalendarIntegrationSettingWorkspacePartsContainer workspaceId={workspaceId}/>)}
          </>

          <div className="form-group">
            {!isDemo && (<>
                <hr className="mt-4"/>

                <div className="mt-3">
                  <DeleteModal onDelete={onDeleteIntegration}/>
                </div>
              </>)}
          </div>
        </>)}
    </div>);
};
