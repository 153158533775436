import React from 'react';
import I18n from '@/constants/i18n';
import Subpane from '@/components/molecules/subpane';
import { SubpaneUserList } from './subpane_user_list';
import { SubpaneCategoryList } from './subpane_category_list';
export var ReportSubpane = function (_a) {
    var selectedSubPaneTab = _a.selectedSubPaneTab, reportableTeams = _a.reportableTeams, reportableUsers = _a.reportableUsers, selectedUsers = _a.selectedUsers, isOpenSubpane = _a.isOpenSubpane, reportedTeam = _a.reportedTeam, reportedCategory = _a.reportedCategory, isShowArchived = _a.isShowArchived, isNotWorkedUsersOpened = _a.isNotWorkedUsersOpened, loading = _a.loading, withAllUsers = _a.withAllUsers, workedMemberIds = _a.workedMemberIds, isShowOnlyMyData = _a.isShowOnlyMyData, isSelectedMyReport = _a.isSelectedMyReport, selectSubpaneTab = _a.selectSubpaneTab, toggleOpenSubpane = _a.toggleOpenSubpane, onSelectCategory = _a.onSelectCategory, toggleIsShowArchived = _a.toggleIsShowArchived, setIsNotWorkedUsersOpened = _a.setIsNotWorkedUsersOpened, onSelectUser = _a.onSelectUser, onSelectAllUsers = _a.onSelectAllUsers, onDeselectAllUsers = _a.onDeselectAllUsers;
    var handleSelectCategory = function (e) {
        e.preventDefault();
        selectSubpaneTab('category');
    };
    var handleSelectMember = function (e) {
        e.preventDefault();
        selectSubpaneTab('member');
    };
    var reportableWorkedUsers = reportableUsers.items.filter(function (_a) {
        var id = _a.id;
        return workedMemberIds.includes(id);
    });
    var selectedWorkedUsers = withAllUsers
        ? reportableWorkedUsers
        : selectedUsers.filter(function (_a) {
            var id = _a.id;
            return workedMemberIds.includes(id);
        });
    return (<Subpane className="col-md-3 col-12" isOpen={isOpenSubpane} toggle={toggleOpenSubpane}>
      <ul className="nav nav-tabs">
        <li className={"nav-item ".concat(isShowOnlyMyData ? 'w-100' : '')}>
          <a className={"nav-link ".concat(selectedSubPaneTab === 'category' ? 'active' : null)} href="#" onClick={handleSelectCategory}>
            {I18n.t('shared.category')}
          </a>
        </li>
        {!isShowOnlyMyData && (<li className="nav-item">
            <a className={"nav-link ".concat(selectedSubPaneTab === 'member' ? 'active' : null)} href="#" onClick={handleSelectMember}>
              {I18n.t('shared.member')}
              <span className={"ml-1 ".concat(reportableWorkedUsers.length && !selectedWorkedUsers.length
                ? 'text-danger font-weight-bold'
                : 'font-weight-normal')}>
                {selectedWorkedUsers.length} / {reportableWorkedUsers.length}
              </span>
            </a>
          </li>)}
      </ul>
      {selectedSubPaneTab === 'category' ? (<div className="list-group flex-column pt-0 list-group-flush">
          {reportedTeam && (<SubpaneCategoryList isSelectedMyReport={isSelectedMyReport} isShowArchived={isShowArchived} reportableTeams={reportableTeams} reportedCategory={reportedCategory} reportedTeam={reportedTeam} onSelectCategory={onSelectCategory} onToggleIsShowArchived={toggleIsShowArchived} onToggleOpenSubpane={toggleOpenSubpane}/>)}
        </div>) : null}
      {selectedSubPaneTab === 'member' ? (<div className="list-group flex-column pt-0 list-group-flush">
          {reportableUsers.isLoaded && (<SubpaneUserList isLoading={loading} isNotWorkedUsersOpened={isNotWorkedUsersOpened} reportableUsers={reportableUsers.items} selectedUsers={selectedUsers} withAllUsers={withAllUsers} workedMemberIds={workedMemberIds} onDeselectAllUsers={onDeselectAllUsers} onSelectAllUsers={onSelectAllUsers} onSelectUser={onSelectUser} onToggle={setIsNotWorkedUsersOpened}/>)}
        </div>) : null}
    </Subpane>);
};
