import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import CustomRadio from '@/components/atoms/custom_radio';
import { DataTypeField, } from '@/components/molecules/data_type_field';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import { CharsetSelect } from '@/components/charset_select';
export var timeFormats = ['seconds', 'hms', 'hms_colon', '6min'];
export var ExportFormatModal = function (_a) {
    var isOpen = _a.isOpen, toggle = _a.toggle, backdrop = _a.backdrop, titleLabel = _a.titleLabel, actionLabel = _a.actionLabel, onSubmit = _a.onSubmit, dataType = _a.dataType, timeFormat = _a.timeFormat, charset = _a.charset, onChangeDataType = _a.onChangeDataType, onChangeTimeFormat = _a.onChangeTimeFormat, onChangeCharset = _a.onChangeCharset, disableDataType = _a.disableDataType;
    return (<Modal backdrop={backdrop} isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeaderMod toggle={toggle}>{titleLabel}</ModalHeaderMod>
      <ModalBody>
        <div className="col-12">
          {disableDataType || (<DataTypeField dataType={dataType} onChangeDataType={onChangeDataType}/>)}
          <div className="form-group row">
            <label className="col-form-label col-sm-3 col-12">
              {I18n.t("export.timeFormat")}
            </label>
            <div className="col-xl-7 col-sm-9 col-12">
              {timeFormats.map(function (tf) { return (<div key={tf}>
                  <CustomRadio checked={timeFormat === tf} value={tf} onChange={function () { return onChangeTimeFormat(tf); }}>
                    {I18n.t("export.format.".concat(tf))}
                    <br />
                    {I18n.t('export.example')}
                    01:02:01 → {I18n.t("export.examples.".concat(tf))}
                    {!!I18n.t("export.notice.".concat(tf)) && (<p className="text-danger">
                        {I18n.t("export.notice.".concat(tf))}
                      </p>)}
                  </CustomRadio>
                </div>); })}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-3 col-12">
              {I18n.t("export.charset.label")}
            </label>
            <div className="col-xl-7 col-sm-9 col-12">
              <CharsetSelect charset={charset} onChangeCharset={onChangeCharset}/>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={toggle}>
          {I18n.t('shared.cancel')}
        </button>
        <button className="btn btn-primary" onClick={onSubmit}>
          {actionLabel}
        </button>
      </ModalFooter>
    </Modal>);
};
