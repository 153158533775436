import React from 'react';
import I18n from '@/constants/i18n';
import CustomCheck from '../atoms/custom_check';
export var CustomFieldsValidationField = function (_a) {
    var customFieldsMaster = _a.customFieldsMaster, onChangeCustomFieldsMaster = _a.onChangeCustomFieldsMaster, className = _a.className;
    var _b = React.useState(customFieldsMaster.requirement !== 'none'), isCheckedRequired = _b[0], setIsCheckedRequired = _b[1];
    var _c = React.useState(customFieldsMaster.requirement === 'leaf'), isCheckedLeaf = _c[0], setIsCheckedLeaf = _c[1];
    var onChangeRequirement = React.useCallback(function (required, leaf) {
        onChangeCustomFieldsMaster('requirement', required ? (leaf ? 'leaf' : 'all') : 'none');
    }, [onChangeCustomFieldsMaster]);
    if (customFieldsMaster.field_type === 'expression') {
        return null;
    }
    return (<div className={className}>
      <div className="mt-2">
        <CustomCheck checked={isCheckedRequired} onChange={function () {
            var required = !isCheckedRequired;
            if (!required) {
                setIsCheckedLeaf(false);
            }
            setIsCheckedRequired(required);
            onChangeRequirement(required, isCheckedLeaf);
        }}>
          {I18n.t('shared.customFieldsRequirementAll')}
        </CustomCheck>
      </div>
      <div className="mt-1 ml-3">
        <CustomCheck checked={isCheckedLeaf} onChange={function () {
            var leaf = !isCheckedLeaf;
            setIsCheckedLeaf(leaf);
            if (leaf) {
                setIsCheckedRequired(true);
            }
            onChangeRequirement(true, leaf);
        }}>
          {I18n.t('shared.customFieldsRequirementLeaf')}
        </CustomCheck>
      </div>
      <div className="mt-3">
        <CustomCheck checked={customFieldsMaster.unique} onChange={function (e) {
            return onChangeCustomFieldsMaster('unique', e.target.checked);
        }}>
          {I18n.t('shared.customFieldsUnique')}
        </CustomCheck>
      </div>
    </div>);
};
