import React from 'react';
import NewPaymentFormContainer from '@/containers/new_payment_form_container';
import EditPaymentFormContainer from '@/containers/edit_payment_form_container';
import ChromeExtension from './chrome_extension';
import PremiumPage from './premium_page';
import variants from '@/constants/variants';
import ConfirmModalContainer from '@/containers/confirm_modal_container';
export var PaymentForm = function (props) {
    var payment = props.payment, isChromeExtension = props.isChromeExtension, teamId = props.teamId, user = props.user, openModal = props.openModal, _a = props.openURL, openURL = _a === void 0 ? variants.openURL : _a;
    var form;
    if (payment.premium) {
        form = <PremiumPage />;
    }
    else if (isChromeExtension) {
        form = <ChromeExtension openURL={openURL} teamId={teamId}/>;
    }
    else if (payment.subscribing) {
        form = <EditPaymentFormContainer openModal={openModal} user={user}/>;
    }
    else {
        form = <NewPaymentFormContainer openModal={openModal}/>;
    }
    return (<>
      {form}
      <ConfirmModalContainer />
    </>);
};
