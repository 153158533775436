import { __assign } from "tslib";
export var FETCH_IDENTITIES_REQUEST = 'timecrowd/teams/FETCH_IDENTITIES_REQUEST';
export var FETCH_IDENTITIES_SUCCESS = 'timecrowd/teams/FETCH_IDENTITIES_SUCCESS';
export var FETCH_IDENTITIES_FAILURE = 'timecrowd/teams/FETCH_IDENTITIES_FAILURE';
export var DELETE_IDENTITY_SUCCESS = 'timecrowd/teams/DELETE_IDENTITY_SUCCESS';
export var DELETE_IDENTITY_FAILURE = 'timecrowd/teams/DELETE_IDENTITY_FAILURE';
export var initialState = {
    isLoading: false,
    items: [],
};
function removeIdentity(state, id) {
    var identities = state.items;
    return identities.filter(function (identity) { return identity.id !== id; });
}
export default function identities(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_IDENTITIES_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_IDENTITIES_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: action.identities });
        case FETCH_IDENTITIES_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case DELETE_IDENTITY_SUCCESS:
            return __assign(__assign({}, state), { items: removeIdentity(state, action.id) });
        case DELETE_IDENTITY_FAILURE:
            return state;
        default:
            return state;
    }
}
export var fetchIdentitiesRequest = function () { return ({
    type: FETCH_IDENTITIES_REQUEST,
}); };
export var fetchIdentitiesSuccess = function (identities) { return ({
    type: FETCH_IDENTITIES_SUCCESS,
    identities: identities,
}); };
export var fetchIdentitiesFailure = function () { return ({
    type: FETCH_IDENTITIES_FAILURE,
}); };
export var deleteIdentitySuccess = function (id) { return ({
    type: DELETE_IDENTITY_SUCCESS,
    id: id,
}); };
export var deleteIdentityFailure = function () { return ({
    type: DELETE_IDENTITY_FAILURE,
}); };
