import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import orderBy from 'lodash/orderBy';
import { useNavigate } from 'react-router-dom';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import Icon from '@/components/atoms/icon';
import { trashTask } from '@/api2/tasks';
import { closeModal } from '@/modules/modal';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import { workspacePath } from '@/utils/workspace_helper';
var categoryListFrom = function (categories, list, baseCategory) {
    if (!baseCategory.parent_id) {
        return list;
    }
    var found = categories.find(function (category) { return category.id === baseCategory.parent_id; });
    if (found) {
        return categoryListFrom(categories, list.concat(found), found);
    }
    else {
        return list;
    }
};
export var DeleteTaskModal = function (_a) {
    var categories = _a.categories, from = _a.from, task = _a.task, team = _a.team;
    var isOpen = useSelector(function (state) { return state.modal.modalType === 'DELETE_TASK_MODAL'; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var onClickDelete = function (id) {
        dispatch(trashTask(id)).then(function (response) {
            if (!response.error) {
                toggle();
                if (from === 'mytasks') {
                    navigate('/app');
                }
                else {
                    navigate(workspacePath("/".concat(from)));
                }
            }
        });
    };
    var toggle = function () {
        dispatch(closeModal());
    };
    if (!team || !task || !categories.length) {
        return null;
    }
    var baseCategory = categories.find(function (category) { return category.id === task.category.id; });
    if (!baseCategory) {
        return null;
    }
    var categoryList = orderBy(categoryListFrom(categories, [baseCategory], baseCategory), ['ancestry_depth'], ['asc']);
    return (<Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeaderMod toggle={toggle}>
        {I18n.t('deleteTaskModal.title')}
      </ModalHeaderMod>

      <ModalBody>
        <p>
          {I18n.t('deleteTaskModal.confirmation')}
          {I18n.t('deleteTaskModal.additional')}
        </p>
        <div className="ml-2">
          <label className="col-form-label" style={{ lineHeight: '1.2rem', width: '100%' }}>
            <Icon className="mr-1" name="document"/>
            <span style={{
            fontSize: '10px',
        }}>
              {I18n.t('shared.title')}
            </span>
          </label>
          {task.title}
        </div>
        <div className="ml-2">
          <label className="col-form-label" style={{ lineHeight: '1.2rem', width: '100%' }}>
            <Icon className="mr-1" name="user-group"/>
            <span style={{
            fontSize: '10px',
        }}>
              {I18n.t('shared.team')}
            </span>
          </label>
          <Avatar className="mr-1" image={team.avatar_url} size="sm"/>
          {team.name}
        </div>
        <div className="ml-2">
          <label className="col-form-label" style={{ lineHeight: '1.2rem', width: '100%' }}>
            <Icon className="mr-1" name="box"/>
            <span style={{
            fontSize: '10px',
        }}>
              {I18n.t('shared.category')}
            </span>
          </label>
          <ul className="tree clearfix">
            {categoryList.map(function (category) {
            return (<li key={"delete-task-modal-category-".concat(category.id)} className="branch">
                  {category.title}
                </li>);
        })}
          </ul>
        </div>
        {team.can_manage && (<p className="text-danger mt-3">
            {I18n.t('deleteTaskModal.attention')}
          </p>)}
      </ModalBody>

      <ModalFooter style={{ justifyContent: 'flex-start' }}>
        <button className="btn btn-danger" onClick={function () { return onClickDelete(task.id); }}>
          {I18n.t('shared.delete')}
        </button>
        <button className="btn btn-secondary" onClick={toggle}>
          {I18n.t('shared.cancel')}
        </button>
        <a className="btn btn-link ml-auto" href="https://help.timecrowd.net/task-edit-delete-0" onClick={function (e) {
            e.preventDefault();
            openURL(e.target.href);
        }}>
          {I18n.t('shared.help')}
        </a>
      </ModalFooter>
    </Modal>);
};
