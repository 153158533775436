import keyBy from 'lodash/keyBy';
import escapeRegExp from 'lodash/escapeRegExp';
import { zenToHan } from '@/utils/text_helper';
// NOTE: フロントの calendar_integration/filter#match? も合わせて修正すること
export function matchedCategory(title, calendarIntegrationSetting, calendarIntegrationFilters, categories) {
    var categoriesDict = keyBy(categories, 'id');
    var normalized = zenToHan(title);
    for (var _i = 0, calendarIntegrationFilters_1 = calendarIntegrationFilters; _i < calendarIntegrationFilters_1.length; _i++) {
        var filter = calendarIntegrationFilters_1[_i];
        if (categoriesDict[filter.category_id] &&
            new RegExp(filter.keywords
                .map(function (keyword) { return escapeRegExp(zenToHan(keyword)); })
                .join('|'), 'i').test(normalized)) {
            return categoriesDict[filter.category_id];
        }
    }
    return categoriesDict[calendarIntegrationSetting === null || calendarIntegrationSetting === void 0 ? void 0 : calendarIntegrationSetting.category_id];
}
