import { __awaiter, __generator } from "tslib";
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import { TaskSuggestionListItem } from './task_suggesion_list_item';
export var TaskSuggestionList = function (props) {
    var hasMore = props.hasMore, keyword = props.keyword, tasks = props.tasks, icon = props.icon, _a = props.className, className = _a === void 0 ? '' : _a, _b = props.hideTeam, hideTeam = _b === void 0 ? false : _b, onSelectTaskFromSuggestions = props.onSelectTaskFromSuggestions, onLoadMore = props.onLoadMore;
    var isLoadingMore = React.useRef(false);
    var handleLoadMore = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!hasMore) {
                        return [2 /*return*/];
                    }
                    if (isLoadingMore.current) {
                        return [2 /*return*/];
                    }
                    isLoadingMore.current = true;
                    return [4 /*yield*/, onLoadMore()];
                case 1:
                    _a.sent();
                    isLoadingMore.current = false;
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClickSuggestion = function (task) {
        onSelectTaskFromSuggestions(task);
    };
    return (<InfiniteScroll className={className} hasMore={hasMore} loader={<div key={0} className="py-4 text-center">
          <LoadingSpinner size="md"/>
        </div>} loadMore={handleLoadMore} useWindow={false}>
      {tasks.map(function (task) {
            return (<TaskSuggestionListItem key={task.id} handleClickSuggestion={handleClickSuggestion} hideTeam={hideTeam} icon={icon} keyword={keyword} task={task}/>);
        })}
    </InfiniteScroll>);
};
