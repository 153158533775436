import React from 'react';
import classNames from 'classnames';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { Rnd } from 'react-rnd';
import { isTouchDevice } from '@/utils/device_helper';
import { CalendarItemFormModal } from '@/components/calendar_item_form_modal';
import { isSameDay } from '@/utils/time_helper';
var TimelineEntry = function (props) {
    var index = props.index, divisionWidth = props.divisionWidth, divisionHeight = props.divisionHeight, timeEntry = props.timeEntry, top = props.top, className = props.className, children = props.children, _a = props.tag, tag = _a === void 0 ? 'div' : _a, height = props.height, _b = props.isOpen, isOpen = _b === void 0 ? false : _b, selectedDay = props.selectedDay, _c = props.readOnly, readOnly = _c === void 0 ? false : _c, isApp = props.isApp, isMobile = props.isMobile, isMine = props.isMine, taskSearch = props.taskSearch, createTask = props.createTask, toggleTaskForm = props.toggleTaskForm, onUpdate = props.onUpdate, calendarDate = props.calendarDate, calendarUnitMinutes = props.calendarUnitMinutes, left = props.left, width = props.width;
    var resizeRef = React.useRef(null);
    var dragRef = React.useRef(null);
    var _d = React.useState(false), saving = _d[0], setSaving = _d[1];
    var handleToggleTaskForm = function () {
        if (resizeRef.current || dragRef.current || saving) {
            return;
        }
        toggleTaskForm(index);
    };
    var onResizeStop = function (_e, dir, _refToElement, delta) {
        // Prevent onClick event
        // @ts-ignore
        resizeRef.current = true;
        setTimeout(function () {
            // @ts-ignore
            resizeRef.current = false;
        }, 0);
        var diff = Math.round(delta.height / divisionHeight) * calendarUnitMinutes;
        if (diff === 0) {
            return;
        }
        var params = {};
        if (dir === 'top') {
            params.started_at = moment(timeEntry.started_at).add(-diff, 'm').toDate();
        }
        else {
            params.stopped_at = moment(timeEntry.stopped_at).add(diff, 'm').toDate();
        }
        updateTimeEntry(params);
    };
    var onDragStop = function (_e, data) {
        // Prevent onClick event
        setTimeout(function () {
            // @ts-ignore
            dragRef.current = false;
        }, 0);
        var diffMinutes = Math.round((top - data.y) / divisionHeight) * calendarUnitMinutes;
        var diffDays = Math.floor(data.x / divisionWidth);
        if (diffMinutes === 0 && diffDays === 0) {
            return;
        }
        var params = {
            started_at: moment(timeEntry.started_at)
                .add(-diffMinutes, 'm')
                .add(diffDays, 'day')
                .toDate(),
            stopped_at: moment(timeEntry.stopped_at)
                .add(-diffMinutes, 'm')
                .add(diffDays, 'day')
                .toDate(),
        };
        updateTimeEntry(params);
    };
    var onDrag = function () {
        // Prevent onClick event
        // @ts-ignore
        dragRef.current = true;
    };
    var updateTimeEntry = function (params) {
        setSaving(true);
        onUpdate(timeEntry.id, params).then(function () {
            setSaving(false);
        });
    };
    var Tag = readOnly ||
        timeEntry.task.category.archived ||
        isOpen ||
        isApp ||
        isMobile ||
        isTouchDevice()
        ? tag
        : Rnd;
    var enableResizingTop = !saving && isSameDay(calendarDate, timeEntry.started_at);
    var enableResizingBottom = !saving && isSameDay(calendarDate, timeEntry.stopped_at);
    var rndProps = Tag !== Rnd
        ? {}
        : {
            default: {
                // controlledだとdragAxisがバグるため、uncontrolledを使う
                x: left,
                y: top,
                width: width,
                height: height,
            },
            enableResizing: {
                top: enableResizingTop,
                right: false,
                bottom: enableResizingBottom,
                left: false,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
            },
            onResizeStop: onResizeStop,
            onDragStop: onDragStop,
            onDrag: onDrag,
            minHeight: divisionHeight,
            disableDragging: saving,
            // NOTE: Rndをリセットする
            // - top: 移動後のtopを移動させてしまうのを防止するため
            // - height: ドラッグ以外の手段で高さが変わったのを反映するため
            key: "".concat(top, "-").concat(height, "-").concat(width),
            resizeGrid: [1, divisionHeight],
            dragGrid: [divisionWidth, divisionHeight],
            bounds: '.rnd-bounds',
        };
    var classes = classNames('timeline-item timeline-entry', className, {
        open: isOpen,
    });
    var cursor = readOnly ? 'auto' : null;
    var view = (<>
      {isOpen && (<>
          <CalendarItemFormModal isOpen createTask={createTask} isMine={isMine} readOnly={readOnly} selectedDay={selectedDay} taskSearch={taskSearch} timeEntry={timeEntry} toggleTaskForm={toggleTaskForm} onClose={function () { return toggleTaskForm(-1); }}/>
        </>)}
      <div className="h-100">{children}</div>
    </>);
    return (<Tag className={classes} style={{
            top: top,
            height: height,
            cursor: cursor,
            left: left,
            width: width,
        }} onClick={handleToggleTaskForm} {...rndProps} // eslint-disable-line react/jsx-props-no-spreading
    >
      {view}
    </Tag>);
};
export default TimelineEntry;
