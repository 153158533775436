import React from 'react';
import classNames from 'classnames';
export var CustomSwitch = function (props) {
    var className = props.className, _a = props.descriptionClassName, descriptionClassName = _a === void 0 ? '' : _a, children = props.children, _b = props.checked, checked = _b === void 0 ? false : _b, disabled = props.disabled, onChange = props.onChange, _c = props.onClick, onClick = _c === void 0 ? function () { } : _c;
    var conditionals = {
        checked: checked,
        disabled: disabled,
    };
    var classes = classNames('custom-control custom-switch', className, conditionals);
    return (<label className={classes} onClick={onClick}>
      <input checked={checked} className="custom-control-input" disabled={disabled} type="checkbox" onChange={onChange}/>
      <span className="custom-control-indicator">
        <span className="custom-control-knob"/>
      </span>
      <span className={"custom-control-description ".concat(descriptionClassName)}>
        {children}
      </span>
    </label>);
};
