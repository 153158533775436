import React from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
export var WorkspacesCalendarIntegrationFilter = function (_a) {
    var filter = _a.filter, isEnablePriority = _a.isEnablePriority, _b = _a.hightlightKeywords, hightlightKeywords = _b === void 0 ? [] : _b, maxPriority = _a.maxPriority, onClickDelete = _a.onClickDelete, onClickEdit = _a.onClickEdit, onClickDown = _a.onClickDown, onClickUp = _a.onClickUp;
    var openURL = useSelector(function (state) { return state.openURL; });
    var tooltipTarget = React.useRef(null);
    var handleClickDelete = React.useCallback(function () {
        onClickDelete === null || onClickDelete === void 0 ? void 0 : onClickDelete(filter);
    }, [filter, onClickDelete]);
    var handleClickDown = React.useCallback(function () {
        onClickDown === null || onClickDown === void 0 ? void 0 : onClickDown(filter.id, { priority: filter.priority + 1 });
    }, [filter, onClickDown]);
    var handleClickEdit = React.useCallback(function () {
        onClickEdit === null || onClickEdit === void 0 ? void 0 : onClickEdit(filter);
    }, [filter, onClickEdit]);
    var handleClickUp = React.useCallback(function () {
        onClickUp === null || onClickUp === void 0 ? void 0 : onClickUp(filter.id, { priority: filter.priority - 1 });
    }, [filter, onClickUp]);
    var handleClickLink = React.useCallback(function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    }, [openURL]);
    return (<>
      <div className="row">
        <div className="col-4 d-flex align-items-center">
          {I18n.t('shared.priority')}
          <button ref={tooltipTarget} className="btn btn-link p-0 ml-1">
            <Icon name="question" size="md"/>
          </button>
          <UncontrolledTooltip fade={false} innerClassName="max-w-lg text-left mb-1" placement="top" target={tooltipTarget} trigger="legacy">
            <p>
              {I18n.t('shared.calendarIntegrationSettingsPriorityDescription1')}
            </p>
            <div className="d-flex align-items-center">
              <Icon className="text-warning" name="warning"/>
              <span className="ml-1">{I18n.t('shared.caution')}</span>
            </div>
            <p>
              {I18n.t('shared.calendarIntegrationSettingsPriorityDescription2')}
            </p>
            <p className="text-right mb-0">
              <u>
                <a className="text-reset text-underline" href="https://support.timecrowd.net/workspace-calendar-auto-categorization" onClick={handleClickLink}>
                  {I18n.t('shared.clickHereForDetails')}
                </a>
              </u>
            </p>
          </UncontrolledTooltip>
        </div>
        <div className="col-4 d-flex align-items-center">{filter.priority}</div>
        <div className="col-4 d-flex align-items-center justify-end">
          {isEnablePriority && (<div>
              {filter.priority > 1 && (<button className="p-0 btn btn-link text-gray-dark btn-sm" onClick={handleClickUp}>
                  <Icon name="arrow-thin-up" size="lg"/>
                </button>)}
              {filter.priority < maxPriority && (<button className="p-0 btn btn-link text-gray-dark btn-sm" onClick={handleClickDown}>
                  <Icon name="arrow-thin-down" size="lg"/>
                </button>)}
            </div>)}
          {(onClickEdit || onClickDelete) && (<div className="ml-2">
              <div className="btn-group">
                {onClickEdit && (<button className="btn btn-secondary" onClick={handleClickEdit}>
                    <Icon name="pencil" size="md"/>
                  </button>)}
                {onClickDelete && (<button className="btn btn-secondary" onClick={handleClickDelete}>
                    <Icon className="text-danger" name="trash" size="md"/>
                  </button>)}
              </div>
            </div>)}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-4">{I18n.t('shared.teamCategory')}</div>
        <div className="col">
          {filter.category && (<span>
              <CategoryCircleIcon color={filter.category.color}/>
              {filter.category.title} - {filter.category.team.name}
            </span>)}
          {!filter.category && (<span className="font-weight-bold text-danger">
              {I18n.t('shared.notSelected')}
            </span>)}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-4">{I18n.t('shared.keywords')}</div>
        <div className="col">
          <p dangerouslySetInnerHTML={{
            __html: filter.keywords_text
                .split('\n')
                .map(function (keyword) {
                if (hightlightKeywords.includes(keyword)) {
                    return "<span class=\"font-weight-bold text-danger\">".concat(keyword, "</span>");
                }
                return keyword;
            })
                .join('<br>'),
        }}/>
        </div>
      </div>
    </>);
};
