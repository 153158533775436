import React from 'react';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
export var ProxyInputUser = function (_a) {
    var nickname = _a.nickname, avatarUrl = _a.avatarUrl;
    return (<div className="bg-gray-lighter p-1">
      <Avatar className="mr-1" image={avatarUrl} name={nickname} size="md"/>
      <span>{I18n.t('proxyInputUser.message', { nickname: nickname })}</span>
    </div>);
};
