import { __assign, __spreadArray } from "tslib";
import React from 'react';
import { Modal, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import flatten from 'lodash/flatten';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import { TeamSelectDropdown } from '@/components/molecules/team_select_dropdown';
import { CategoryCheckList } from '@/components/molecules/category_check_list';
import LoadingSpinner from '@/components/atoms/loading_spinner';
var isSelectable = function (team) {
    return (team === null || team === void 0 ? void 0 : team.is_bulk_create_tasks_allowed) && !(team === null || team === void 0 ? void 0 : team.tasks_readonly);
};
export var BulkCreateTasksFormModal = function (_a) {
    var isOpen = _a.isOpen, isLoading = _a.isLoading, onToggle = _a.onToggle, onCreate = _a.onCreate, teams = _a.teams, categories = _a.categories, defaultSelectedTeam = _a.defaultSelectedTeam, defaultSelectedCategories = _a.defaultSelectedCategories;
    var _b = React.useState(''), title = _b[0], setTitle = _b[1];
    var _c = React.useState(isSelectable(defaultSelectedTeam) ? defaultSelectedTeam : null), selectedTeam = _c[0], setSelectedTeam = _c[1];
    var _d = React.useState(defaultSelectedCategories), selectedCategories = _d[0], setSelectedCategories = _d[1];
    var titleRef = React.useRef(null);
    React.useEffect(function () {
        if (isOpen) {
            setTimeout(function () {
                var _a;
                (_a = titleRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }, 0);
        }
    }, [isOpen, titleRef]);
    var selectedTeamCategories = React.useMemo(function () {
        return selectedCategories.filter(function (_a) {
            var team_id = _a.team_id;
            return team_id === (selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.id);
        });
    }, [selectedCategories, selectedTeam]);
    var onChangeTitle = React.useCallback(function (e) {
        setTitle(e.target.value);
    }, []);
    var onSelectTeam = React.useCallback(function (team) {
        setSelectedTeam(team);
    }, []);
    var onSubmit = React.useCallback(function () {
        onCreate({
            titles: title.split('\n').filter(function (t) { return t; }),
            category_ids: selectedTeamCategories.map(function (_a) {
                var id = _a.id;
                return id;
            }),
        });
    }, [title, selectedTeamCategories, onCreate]);
    var onChangeCategory = React.useCallback(function (team, category, checked) {
        var prevSelectedIds = selectedCategories.map(function (_a) {
            var id = _a.id;
            return id;
        });
        var nextSelectedCategories = categories.filter(function (_a) {
            var id = _a.id;
            if (checked) {
                return prevSelectedIds.includes(id) && id !== category.id;
            }
            else {
                return prevSelectedIds.includes(id) || id === category.id;
            }
        });
        setSelectedCategories(nextSelectedCategories);
    }, [selectedCategories, categories]);
    var onFilter = React.useCallback(function (filter) {
        var notLeafIds = flatten(categories.map(function (category) {
            return (category.ancestry || '').split('/').map(function (id) { return parseInt(id); });
        }));
        var unselectedTeamCategories = selectedCategories.filter(function (_a) {
            var team_id = _a.team_id;
            return team_id !== (selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.id);
        });
        var teamCategories = categories.filter(function (_a) {
            var team_id = _a.team_id;
            return team_id === (selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.id);
        });
        switch (filter) {
            case 'selectAll':
                setSelectedCategories(__spreadArray(__spreadArray([], unselectedTeamCategories, true), teamCategories, true));
                break;
            case 'selectParents':
                setSelectedCategories(__spreadArray(__spreadArray([], unselectedTeamCategories, true), teamCategories.filter(function (category) { return category.ancestry_depth === 0; }), true));
                break;
            case 'selectLeafs':
                setSelectedCategories(__spreadArray(__spreadArray([], unselectedTeamCategories, true), teamCategories.filter(function (category) { return !notLeafIds.includes(category.id); }), true));
                break;
            case 'deselectAll':
                setSelectedCategories(unselectedTeamCategories);
                break;
        }
    }, [categories, selectedTeam, selectedCategories]);
    return (<Modal backdrop="static" isOpen={isOpen} size="lg" toggle={onToggle}>
      <ModalHeaderMod toggle={onToggle}>
        {I18n.t('shared.addTask')}
      </ModalHeaderMod>
      <ModalBody>
        <div className="col-12">
          <div className="form-group">
            <label>{I18n.t('shared.title')}</label>
            <textarea ref={titleRef} className="form-control" rows={title.split('\n').length} value={title} onChange={onChangeTitle}/>
            <p className="text-muted">
              {I18n.t('shared.bulkCreateWithLikeBreak')}
            </p>
          </div>
          <div className="form-group">
            <label>{I18n.t('shared.team')}</label>
            <TeamSelectDropdown labelForAllTeam={I18n.t('shared.preaseSelectTeam')} selectedTeam={selectedTeam} teams={teams.filter(function (team) { return isSelectable(team); })} onSelectTeam={onSelectTeam}/>
          </div>
          {selectedTeam !== null && (<div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <label>{I18n.t('shared.category')}</label>
                <UncontrolledDropdown>
                  <DropdownToggle caret className="btn-link">
                    {I18n.t('shared.filterAndSelect')}
                  </DropdownToggle>
                  <DropdownMenu>
                    {[
                'selectAll',
                'selectParents',
                'selectLeafs',
                'deselectAll',
            ].map(function (filter) {
                return (<DropdownItem key={filter} value={filter} onClick={function () { return onFilter(filter); }}>
                          {I18n.t("shared.".concat(filter))}
                        </DropdownItem>);
            })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {categories.length > 0 ? (<CategoryCheckList selectedCategories={selectedCategories} team={__assign(__assign({}, selectedTeam), { categories: categories.filter(function (_a) {
                        var team_id = _a.team_id;
                        return team_id === selectedTeam.id;
                    }) })} onChange={onChangeCategory}/>) : (<div className="mt-2 text-center">
                  <LoadingSpinner size="md"/>
                </div>)}
            </div>)}
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={onToggle}>
          {I18n.t('shared.cancel')}
        </button>
        <button className="btn btn-primary" disabled={isLoading ||
            title.length < 1 ||
            !selectedTeam ||
            selectedTeamCategories.length < 1} onClick={onSubmit}>
          {I18n.t('shared.create')}
        </button>
      </ModalFooter>
    </Modal>);
};
