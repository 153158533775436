import React from 'react';
import TimeEntryMergeModal from './time_entry_merge_modal';
import TaskMergeModal from './task_merge_modal';
var MergeModals = function (_a) {
    var task = _a.task, team = _a.team, category = _a.category, from = _a.from, toggle = _a.toggle;
    return (<>
    <TimeEntryMergeModal category={category} from={from} task={task} team={team} toggle={toggle}/>
    <TaskMergeModal category={category} from={from} task={task} team={team} toggle={toggle}/>
  </>);
};
export default MergeModals;
