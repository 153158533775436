import { __assign, __spreadArray } from "tslib";
import orderBy from 'lodash/orderBy';
import api from '../../api';
import api2, { normalizeParams } from '../../api2';
import { stateWithoutWorkspaces } from '@/modules/workspaces';
export var FETCH_TEAMS_REQUEST = 'timecrowd/teams/FETCH_TEAMS_REQUEST';
export var FETCH_TEAMS_SUCCESS = 'timecrowd/teams/FETCH_TEAMS_SUCCESS';
export var FETCH_TEAMS_FAILURE = 'timecrowd/teams/FETCH_TEAMS_FAILURE';
// export const CREATE_TEAM_REQUEST = 'timecrowd/teams/CREATE_TEAM_REQUEST';
export var CREATE_TEAM_SUCCESS = 'timecrowd/teams/CREATE_TEAM_SUCCESS';
// export const CREATE_TEAM_FAILURE = 'timecrowd/teams/CREATE_TEAM_FAILURE';
// export const UPDATE_TEAM_REQUEST = 'timecrowd/teams/UPDATE_TEAM_REQUEST';
export var UPDATE_TEAM_SUCCESS = 'timecrowd/teams/UPDATE_TEAM_SUCCESS';
// export const UPDATE_TEAM_FAILURE = 'timecrowd/teams/UPDATE_TEAM_FAILURE';
// export const DELETE_TEAM_REQUEST = 'timecrowd/teams/DELETE_TEAM_REQUEST';
export var DELETE_TEAM_SUCCESS = 'timecrowd/teams/DELETE_TEAM_SUCCESS';
// export const DELETE_TEAM_FAILURE = 'timecrowd/teams/DELETE_TEAM_FAILURE';
export var RESET_TEAMS = 'timecrowd/teams/RESET_TEAMS';
export var initialState = {
    isLoading: false,
    isFetched: false,
    items: [],
};
export var sortTeams = function (teams) {
    return orderBy(teams.sort(function (a, b) { return a.name.localeCompare(b.name); }), ['payment_required', 'personal'], ['asc', 'asc']);
};
export default function teams(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_TEAMS_REQUEST:
            return __assign(__assign({}, state), { isLoading: !state.isFetched });
        case FETCH_TEAMS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isFetched: true, items: sortTeams(action.teams) });
        case CREATE_TEAM_SUCCESS:
            return __assign(__assign({}, state), { items: sortTeams(__spreadArray(__spreadArray([], state.items, true), [action.team], false)) });
        case UPDATE_TEAM_SUCCESS:
            return __assign(__assign({}, state), { items: sortTeams(state.items.map(function (team) {
                    if (team.id === action.team.id) {
                        return action.team;
                    }
                    else {
                        return team;
                    }
                })) });
        case DELETE_TEAM_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.filter(function (team) { return team.id !== action.id; }) });
        case FETCH_TEAMS_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case RESET_TEAMS:
            return initialState;
        default:
            return state;
    }
}
export var fetchTeamsRequest = function () { return ({
    type: FETCH_TEAMS_REQUEST,
}); };
export var fetchTeamsSuccess = function (teams) { return ({
    type: FETCH_TEAMS_SUCCESS,
    teams: teams,
}); };
export var fetchTeamsFailure = function () { return ({
    type: FETCH_TEAMS_FAILURE,
}); };
export var createTeamSuccess = function (team) { return ({
    type: CREATE_TEAM_SUCCESS,
    team: team,
}); };
export var updateTeamSuccess = function (team) { return ({
    type: UPDATE_TEAM_SUCCESS,
    team: team,
}); };
export var deleteTeamSuccess = function (id) { return ({
    type: DELETE_TEAM_SUCCESS,
    id: id,
}); };
export var fetchTeams = function (params) {
    if (params === void 0) { params = {}; }
    var query = normalizeParams(params);
    return function (dispatch, getState) {
        dispatch(fetchTeamsRequest());
        return api2(dispatch, stateWithoutWorkspaces(getState), "/api/v2/teams".concat(query), 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (teams) {
                    dispatch(fetchTeamsSuccess(teams));
                    return teams;
                });
            }
            else {
                dispatch(fetchTeamsFailure());
                return { error: true };
            }
        });
    };
};
export var createTeam = function (params) {
    return function (dispatch, getState) {
        return api(dispatch, getState, 'teams', 'POST', params).then(function (team) {
            if (!team.error) {
                dispatch(createTeamSuccess(team));
            }
            return team;
        });
    };
};
export var updateTeam = function (id, params) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(id), 'PATCH', params).then(function (team) {
            if (!team.error) {
                dispatch(updateTeamSuccess(team));
            }
            return team;
        });
    };
};
export var destroyTeam = function (id) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(id), 'DELETE').then(function (response) {
            if (!response.error) {
                dispatch(deleteTeamSuccess(id));
            }
            return response;
        });
    };
};
export var resetTeams = function () { return ({
    type: RESET_TEAMS,
}); };
