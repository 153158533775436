import { __extends } from "tslib";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorScreen from './error_screen';
import { notifyToAirbrake } from '@/utils/airbrake_helper';
import { EnvMark } from '@/components/molecules/env_mark';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            error: null,
        };
        _this.onClickLink = _this.onClickLink.bind(_this);
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        var user = this.props.user;
        this.setState({
            error: error,
        });
        notifyToAirbrake(user.id, error, errorInfo);
    };
    ErrorBoundary.prototype.onClickLink = function (e) {
        e.preventDefault();
        var openURL = this.props.openURL;
        openURL(e.currentTarget.href);
    };
    ErrorBoundary.prototype.render = function () {
        var error = this.state.error;
        if (error) {
            return (<>
          <ErrorScreen status={500} onClickLink={this.onClickLink} onReload={this.props.reloadPage}/>
          <EnvMark />
        </>);
        }
        return <div>{this.props.children}</div>;
    };
    return ErrorBoundary;
}(Component));
var mapStateToProps = function (state) { return ({
    openURL: state.openURL,
    appVersion: state.appVersion,
    reloadPage: state.reloadPage,
}); };
export default connect(mapStateToProps)(ErrorBoundary);
