import { __assign, __awaiter, __generator } from "tslib";
import React from 'react';
import { Modal, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import { TeamNameField } from './team_name_field';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
var DEFAULT_TEAM = { name: '' };
export var CreateTeamFormModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, createTeam = _a.createTeam, setNotificationMessage = _a.setNotificationMessage;
    var _b = React.useState(DEFAULT_TEAM), team = _b[0], setTeam = _b[1];
    var _c = React.useState(false), isSaving = _c[0], setIsSaving = _c[1];
    React.useEffect(function () {
        if (isOpen) {
            setTeam(DEFAULT_TEAM);
        }
    }, [isOpen]);
    var onChangeTeam = React.useCallback(function (attribute, value) {
        var _a;
        setTeam(__assign(__assign({}, team), (_a = {}, _a[attribute] = value, _a)));
    }, [team]);
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var params, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSaving(true);
                    params = {
                        team: { name: team.name },
                    };
                    return [4 /*yield*/, createTeam(params)];
                case 1:
                    res = _a.sent();
                    setIsSaving(false);
                    if (!res.error) {
                        setNotificationMessage('success', I18n.t('shared.targetCreated', {
                            target: team.name,
                        }));
                        onClose();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (<Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeaderMod toggle={onClose}>
        {I18n.t('shared.createTeam')}
      </ModalHeaderMod>
      <div className="modal-body">
        <TeamNameField team={team} onChangeTeam={onChangeTeam}/>
      </div>
      <ModalFooter className="justify-content-end">
        <button className="btn btn-secondary" disabled={isSaving} onClick={onClose}>
          {I18n.t('shared.cancel')}
        </button>

        <button className="btn btn-primary" disabled={isSaving} onClick={onSave}>
          {I18n.t('shared.save')}
        </button>
      </ModalFooter>
    </Modal>);
};
