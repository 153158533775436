import { __assign } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import ExpansionPanel from '@/components/atoms/expansion_panel';
import ExpansionPanelBody from '@/components/atoms/expansion_panel_body';
import Icon from '@/components/atoms/icon';
import ExpansionPanelHeader from '@/components/molecules/expansion_panel_header';
import { renderOrderedNestedExpansionList } from '../nested_expansion_list';
import { buildCategoriesWithAncestorTitles } from '@/modules/categories/helpers';
import { formatDuration } from '@/utils/time_helper';
import { numberWithDelimiter } from '@/utils/number_helper';
var BASE_LIMIT = 5;
var PER_PAGE = 20;
export var TeamReportDetails = function (_a) {
    var categories = _a.categories, categoryTotals = _a.categoryTotals, users = _a.users, showsTooltip = _a.showsTooltip, tasks = _a.tasks, taskTotals = _a.taskTotals, teamTotals = _a.teamTotals, teams = _a.teams, timeEntries = _a.timeEntries;
    var _b = React.useState({}), pageByTeamId = _b[0], setPageByTeamId = _b[1];
    var categoriesWithAncestorTitles = React.useMemo(function () { return buildCategoriesWithAncestorTitles(categories); }, [categories]).categoriesWithAncestorTitles;
    var tasksByCategoryId = React.useMemo(function () {
        return tasks.reduce(function (acc, task) {
            var categoryId = Number(task.ancestry.split('/').pop());
            acc[categoryId] = acc[categoryId] || [];
            acc[categoryId].push(task);
            return acc;
        }, {});
    }, [tasks]);
    var sortedCategories = React.useMemo(function () {
        return categories.slice().sort(function (a, b) {
            var totalA = categoryTotals[a.id];
            var totalB = categoryTotals[b.id];
            return ((totalB === null || totalB === void 0 ? void 0 : totalB.duration) || 0) - ((totalA === null || totalA === void 0 ? void 0 : totalA.duration) || 0);
        });
    }, [categories, categoryTotals]);
    var categoriesByTeamId = React.useMemo(function () {
        return sortedCategories.reduce(function (acc, category) {
            acc[category.team_id] = acc[category.team_id] || [];
            acc[category.team_id].push(category);
            return acc;
        }, {});
    }, [sortedCategories]);
    var childCategoriesByParentId = React.useMemo(function () {
        return sortedCategories.reduce(function (acc, category) {
            var _a;
            var parent_id = (_a = category.parent_id) !== null && _a !== void 0 ? _a : 0;
            acc[parent_id] = acc[parent_id] || [];
            acc[parent_id].push(category);
            return acc;
        }, {});
    }, [sortedCategories]);
    var handlePrevPage = function (team) {
        var _a;
        var _b;
        setPageByTeamId(__assign(__assign({}, pageByTeamId), (_a = {}, _a[team.id] = ((_b = pageByTeamId[team.id]) !== null && _b !== void 0 ? _b : 0) - 1, _a)));
    };
    var handleNextPage = function (team) {
        var _a;
        var _b;
        setPageByTeamId(__assign(__assign({}, pageByTeamId), (_a = {}, _a[team.id] = ((_b = pageByTeamId[team.id]) !== null && _b !== void 0 ? _b : 0) + 1, _a)));
    };
    return (<ExpansionPanel expand className="mw-100">
      {function (expand, toggle) { return (<>
          <ExpansionPanelHeader className="p-[0.8rem]" expand={expand} toggle={toggle}>
            <label className="expansion-panel-label">
              <Icon className="mr-1" color="info" name="to-do"/>
              {I18n.t('report.details')}
            </label>
          </ExpansionPanelHeader>
          <ExpansionPanelBody expand={expand}>
            {teams.map(function (team) {
                var _a, _b, _c, _d;
                var categories = categoriesByTeamId[team.id] || [];
                var rootCategories = categories.filter(function (c) {
                    var _a, _b;
                    return c.ancestry_depth === 0 &&
                        (((_a = tasksByCategoryId[c.id]) === null || _a === void 0 ? void 0 : _a.length) ||
                            ((_b = childCategoriesByParentId[c.id]) === null || _b === void 0 ? void 0 : _b.length));
                });
                var totalDuration = ((_a = teamTotals[team.id]) === null || _a === void 0 ? void 0 : _a.duration) || 0;
                var totalAmount = ((_b = teamTotals[team.id]) === null || _b === void 0 ? void 0 : _b.amount) || 0;
                if (totalDuration < 1) {
                    return null;
                }
                return (<div key={team.id} className="mb-4">
                  <div className="report-detailed-header">
                    <span className="report-detailed-title">{team.name}</span>
                    <span className="report-detailed-hours text-right">
                      {formatDuration(totalDuration)}
                    </span>
                    <span className="report-detailed-amount text-right">
                      ¥{numberWithDelimiter(totalAmount)}
                    </span>
                    <span className="report-detailed-bar pl-2 d-none d-sm-block"/>
                  </div>
                  {renderOrderedNestedExpansionList(categories, rootCategories.slice(0, BASE_LIMIT + ((_c = pageByTeamId[team.id]) !== null && _c !== void 0 ? _c : 0) * PER_PAGE), users, showsTooltip, categoriesWithAncestorTitles, categoryTotals, taskTotals, totalDuration, tasksByCategoryId, childCategoriesByParentId, timeEntries)}
                  {rootCategories.length > BASE_LIMIT && (<div className="text-center mt-1">
                      {pageByTeamId[team.id] > 0 && (<button className="btn btn-link btn-sm p-0" onClick={function () { return handlePrevPage(team); }}>
                          {"- ".concat(I18n.t('shared.showLess'))}
                        </button>)}
                      {rootCategories.length >
                            BASE_LIMIT +
                                ((_d = pageByTeamId[team.id]) !== null && _d !== void 0 ? _d : 0) * PER_PAGE && (<button className="btn btn-link btn-sm p-0" onClick={function () { return handleNextPage(team); }}>
                          {"+ ".concat(I18n.t('shared.showMore'))}
                        </button>)}
                    </div>)}
                </div>);
            })}
          </ExpansionPanelBody>
        </>); }}
    </ExpansionPanel>);
};
