import { __spreadArray } from "tslib";
import Home from '@/components/pages/home';
import Member from '@/components/member';
import Report from '@/components/report';
import Other from '@/components/other';
import TaskManagementContainer from '@/containers/task_management_container';
import UserCalendar from '@/components/pages/user_calendar';
import Payment from '@/components/payment';
import NotFound from '@/components/pages/not_found';
import Teams from '@/components/teams';
import TeamEditContainer from '@/containers/team_edit_container';
import TeamMemberSettingContainer from '@/containers/team_member_setting_container';
import CategorySettingContainer from '@/containers/category_setting_container';
import IntegrationSettingContainer from '@/containers/integration_setting_container';
import WebHookSettingContainer from '@/containers/web_hook_setting_container';
import DataExportContainer from '@/containers/data_export_container';
import MyTaskEdit from '@/containers/my_task_edit';
import TaskEdit from '@/containers/task_edit';
import ProfileContainer from '@/containers/profile_container';
import AccountSettingContainer from '@/containers/account_setting_container';
import ReportMailSettingContainer from '@/containers/report_mail_setting_container';
import TwoFactorAuth from '@/containers/two_factor_auth';
import TeamChildrenContainer from '@/containers/team_children_container';
import { WelcomeContainer } from '@/containers/welcome_container';
import CalendarIntegrationContainer from '@/containers/calendar_integration_container';
import CategoryTaskStructuresContainer from '@/containers/category_task_structures_container';
import RemindierEmailsContainer from '@/containers/reminder_emails_container';
import CustomFieldsContainer from '@/containers/custom_fields_container';
import WorkspacesEditContainer from '@/containers/workspaces_edit_container';
import WorkspacesMembersContainer from '@/containers/workspaces_members_container';
import WorkspacesTeamsContainer from '@/containers/workspaces_teams_container';
import WorkspacesPlanContainer from '@/containers/workspaces_plan_container';
import { CrossTeamReportContainer } from '@/containers/cross_team_report_container';
import WorkspacesCalendarIntegrationSettingContainer from '@/containers/workspaces_calendar_integration_setting_container';
var withWorkspace = function (_a) {
    var path = _a.path, title = _a.title, component = _a.component;
    return [
        {
            path: path,
            title: title,
            component: component,
        },
        {
            path: path.replace('/app/', '/app/workspaces/:workspace_id/'),
            title: "Workspace ".concat(title),
            component: component,
        },
    ];
};
export default __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([
    { path: '/app', title: 'Home', component: Home },
    { path: '/app/myreport', title: 'MyReport', component: Report }
], withWorkspace({
    path: '/app/member/:tab?',
    title: 'Member',
    component: Member,
}), true), withWorkspace({ path: '/app/report', title: 'Report', component: Report }), true), [
    { path: '/app/other', title: 'Other', component: Other },
    {
        path: '/app/personal_settings/profile',
        title: 'Profile',
        component: ProfileContainer,
    },
    {
        path: '/app/personal_settings/account',
        title: 'Account Setting',
        component: AccountSettingContainer,
    },
    {
        path: '/app/personal_settings/report_mail',
        title: 'Report Mail',
        component: ReportMailSettingContainer,
    },
    {
        path: '/app/personal_settings/two_factor_auth',
        title: 'Two Factor Auth',
        component: TwoFactorAuth,
    },
    {
        path: '/app/personal_settings/calendar_integration',
        title: 'Google Calendar Itegration',
        component: CalendarIntegrationContainer,
    },
    {
        path: '/app/teams',
        title: 'Teams',
        component: Teams,
    },
    {
        path: '/app/export',
        title: 'Data Export',
        component: DataExportContainer,
    }
], false), withWorkspace({
    path: '/app/teams/:id/edit',
    title: 'Team',
    component: TeamEditContainer,
}), true), withWorkspace({
    path: '/app/teams/:team_id/payment',
    title: 'Payment',
    component: Payment,
}), true), withWorkspace({
    path: '/app/teams/:team_id/members',
    title: 'Team Member Setting',
    component: TeamMemberSettingContainer,
}), true), withWorkspace({
    path: '/app/teams/:team_id/categories',
    title: 'Category Setting',
    component: CategorySettingContainer,
}), true), withWorkspace({
    path: '/app/teams/:team_id/integrations',
    title: 'Integration Setting',
    component: IntegrationSettingContainer,
}), true), withWorkspace({
    path: '/app/teams/:team_id/settings',
    title: 'WebHook Setting',
    component: WebHookSettingContainer,
}), true), withWorkspace({
    path: '/app/teams/:team_id/children',
    title: 'Team Children',
    component: TeamChildrenContainer,
}), true), withWorkspace({
    path: '/app/teams/:team_id/category_task_structures',
    title: 'Category Task Structures',
    component: CategoryTaskStructuresContainer,
}), true), withWorkspace({
    path: '/app/teams/:team_id/reminder_emails',
    title: 'Reminder Emails',
    component: RemindierEmailsContainer,
}), true), withWorkspace({
    path: '/app/teams/:team_id/custom_fields',
    title: 'Custom Fields',
    component: CustomFieldsContainer,
}), true), withWorkspace({
    path: '/app/tasks',
    title: 'Task Management',
    component: TaskManagementContainer,
}), true), withWorkspace({
    path: '/app/tasks/:id/edit',
    title: 'Task Edit',
    component: TaskEdit,
}), true), [
    {
        path: '/app/mytasks/:id/edit',
        title: 'My Task Edit',
        component: MyTaskEdit,
    }
], false), withWorkspace({
    path: '/app/users/:user_id/calendar',
    title: 'User Calendar',
    component: UserCalendar,
}), true), [
    {
        path: '/app/workspaces/:workspace_id/edit',
        title: 'Workspaces Edit',
        component: WorkspacesEditContainer,
    },
    {
        path: '/app/workspaces/:workspace_id/members',
        title: 'Workspaces Members',
        component: WorkspacesMembersContainer,
    },
    {
        path: '/app/workspaces/:workspace_id/teams',
        title: 'Workspaces Teams',
        component: WorkspacesTeamsContainer,
    },
    {
        path: '/app/workspaces/:workspace_id/plan',
        title: 'Workspaces Plan',
        component: WorkspacesPlanContainer,
    },
    {
        path: '/app/workspaces/:workspace_id/cross_team_report',
        title: 'Cross-team Report',
        component: CrossTeamReportContainer,
    },
    {
        path: '/app/walkthrough/welcome',
        title: "Welcome",
        component: WelcomeContainer,
    },
    {
        path: '/app/workspaces/:workspace_id/calendar_integration',
        title: "Team Calendar Integration Setting",
        component: WorkspacesCalendarIntegrationSettingContainer,
    },
    { path: '/app/*', title: 'Not Found', component: NotFound },
], false);
