import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import EditTaskButton from '@/components/edit_task_button';
var TaskMenu = function (props) {
    var task = props.task, openURL = props.openURL, showsLinkToEdit = props.showsLinkToEdit;
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var handleToggle = function () {
        setIsOpen(!isOpen);
    };
    return (<>
      {isOpen && (<div style={{
                position: 'fixed',
                display: 'block',
                top: '0',
                left: '0',
                height: '100vh',
                width: '100vw',
                zIndex: 200,
                backgroundColor: 'rgba(0,0,0,0)',
                cursor: 'default',
            }}/>)}
      <div className="btn-group">
        {showsLinkToEdit && (<div className="btn-group btn-group-sm">
            <EditTaskButton task={task}/>
          </div>)}
        {task.url.length > 1 && (<ButtonDropdown isOpen={isOpen} size="sm" style={{ zIndex: '201' }} toggle={handleToggle}>
            <DropdownToggle onClick={function (e) {
                e.stopPropagation();
                handleToggle();
            }}>
              <Icon name="dots-3" size="sm"/>
            </DropdownToggle>
            <DropdownMenu right>
              {task.url.length > 1 && (<DropdownItem onClick={function (e) {
                    e.stopPropagation();
                    openURL(task.url);
                }}>
                  <Icon className="mr-2" name="link" size="md"/>
                  {I18n.t('tasklist.link')}
                </DropdownItem>)}
            </DropdownMenu>
          </ButtonDropdown>)}
      </div>
    </>);
};
export default TaskMenu;
