import { __assign } from "tslib";
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { useDispatch } from 'react-redux';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import Blank from '@/components/molecules/blank';
import Avatar from '@/components/atoms/avatar';
import Counter from '@/components/atoms/counter';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import TaskMenu from '@/components/molecules/task_menu';
import { categoryWithAncestors } from '@/utils/category_helper';
import { StopMemberEntryModal } from './stop_member_entry_modal';
import { filterWorkingTasks } from './helpers';
import { CategoryInfoContainer } from '@/containers/category_info_container';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
import { useThrottle } from '@/hooks/use_throttle';
import { removeDangerNotificationMessage } from '@/modules/notification';
var WorkingUsers = function (_a) {
    var tasks = _a.tasks, timeEntries = _a.timeEntries, isLoading = _a.isLoading, currentEntry = _a.currentEntry, onStart = _a.onStart, onStop = _a.onStop, openURL = _a.openURL, isPC = _a.isPC, searchText = _a.searchText, teams = _a.teams, user = _a.user;
    var currentTaskId = currentEntry ? currentEntry.task.id : null;
    var _b = React.useState(null), stoppingTimeEntry = _b[0], setStoppingTimeEntry = _b[1];
    var _c = React.useMemo(function () {
        return filterWorkingTasks(searchText, tasks, timeEntries);
    }, [searchText, tasks, timeEntries]), timeEntriesByTaskId = _c.timeEntriesByTaskId, filteredTasks = _c.filteredTasks;
    var handleClickTask = useThrottle(function (task) {
        if (!isPC || task.category.archived) {
            return null;
        }
        if (task.id === currentTaskId) {
            return onStop(currentEntry);
        }
        else {
            return onStart(task);
        }
    });
    var dispatch = useDispatch();
    var handleCloseModal = function () {
        dispatch(removeDangerNotificationMessage());
        setStoppingTimeEntry(null);
    };
    var handleStop = function () {
        onStop(stoppingTimeEntry).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            handleCloseModal();
        });
    };
    if (isLoading) {
        return (<div className="loader">
        <LoadingSpinner position="absolute-center" size="md"/>
      </div>);
    }
    if (timeEntries.length === 0) {
        return (<Blank description={I18n.t('member.blankDescription')} title={I18n.t('member.blankTitle')}/>);
    }
    var ownerTeamIds = teams
        .filter(function (_a) {
        var can_manage = _a.can_manage;
        return can_manage;
    })
        .map(function (_a) {
        var id = _a.id;
        return id;
    });
    return (<div className="tasklist working-users">
      {filteredTasks.map(function (task) {
            var tooltipId = "working-users-archived-tooltip-".concat(task.id);
            return (<div key={task.id} className={"task task-controls-container ".concat(task.category.archived ? 'archived' : '')} onClick={function () { return handleClickTask(task); }}>
            <div className="task-block task-members">
              {timeEntriesByTaskId[task.id].map(function (timeEntry) { return (<div key={"users-".concat(timeEntry.user.id, "-").concat(new Date().getTime())} className="task-member mr-0 mr-md-2">
                  <Avatar className="task-member-avatar mr-1" image={timeEntry.user.avatar_url} name={timeEntry.user.nickname} size="sm"/>
                  {timeEntry.can_edit && !timeEntry.stopped_at && (<div>
                      <span className="task-icon task-icon-stop task-icon-member" id={"working-user-".concat(timeEntry.user.id)} onClick={function (e) {
                            e.stopPropagation();
                            if (timeEntry.id === (currentEntry === null || currentEntry === void 0 ? void 0 : currentEntry.id)) {
                                onStop(timeEntry);
                            }
                            else {
                                setStoppingTimeEntry(timeEntry);
                            }
                        }}>
                        <Icon name="stop-circle"/>
                      </span>
                      <UncontrolledTooltip fade={false} placement="top" target={"working-user-".concat(timeEntry.user.id)}>
                        {I18n.t('workingusers.stop')}
                      </UncontrolledTooltip>
                    </div>)}
                  <span className="task-member-nickname mr-1">
                    {timeEntry.user.nickname}
                  </span>
                  {timeEntry.stopped_at && (<span className="text-info">
                      {moment(timeEntry.stopped_at).fromNow()}
                    </span>)}
                  {!timeEntry.stopped_at && (<div className="task-member-duration">
                      <Icon className="mr-1" color="secondary" name="clock"/>
                      <Counter entry={timeEntry}/>
                    </div>)}
                </div>); })}
            </div>
            <div className="task-header">
              {task.id !== currentTaskId ? (<span className={"task-icon task-icon-start ".concat(task.category.archived ? 'opacity-50' : '')} id={tooltipId}>
                  <Icon name="start-circle"/>
                </span>) : (<span className="task-icon task-icon-stop">
                  <Icon name="stop-circle"/>
                </span>)}
              <span className="task-title">{task.title}</span>
              {task.category.archived && (<UncontrolledTooltip fade={false} target={tooltipId}>
                  {I18n.t('shared.archivedTaskCannotBeStarted')}
                </UncontrolledTooltip>)}
            </div>
            <div className="task-block">
              <div className="task-category d-flex align-items-center">
                <WorkspaceAvatarContainer className="mr-1 ml-n1" workspaceId={task.team.workspaces_workspace_id}/>
                <CategoryCircleIcon className="top-0 mr-1" color={task.category.color}/>
                <span className="task-category-title">
                  {categoryWithAncestors(task.category)} - {task.team.name}
                </span>
                <CategoryInfoContainer requiresCustomFields category={__assign(__assign({}, task.category), { team: task.team })} className="mx-1"/>
                {task.category.archived && (<span className="badge badge-info ml-1">
                    <Icon className="mr-1" name="archive" size="sm"/>
                    {I18n.t('shared.archived')}
                  </span>)}
              </div>
            </div>
            <div className="task-controls">
              <div className="btn-group mr-1">
                {!isPC &&
                    !task.category.archived &&
                    task.id !== currentTaskId && (<button className="btn btn-primary btn-sm" title={I18n.t('workingusers.start')} type="button" onClick={function (e) {
                        e.stopPropagation();
                        onStart(task);
                    }}>
                      <span className="btn-icon">
                        <Icon name="start"/>
                      </span>
                    </button>)}
                {!isPC && task.id === currentTaskId && (<button className="btn btn-danger btn-sm" title={I18n.t('workingusers.stop')} type="button" onClick={function (e) {
                        e.stopPropagation();
                        onStop(currentEntry);
                    }}>
                    <Icon name="stop"/>
                  </button>)}
              </div>
              <TaskMenu openURL={openURL} showsLinkToEdit={ownerTeamIds.includes(task.team_id) ||
                    timeEntriesByTaskId[task.id].some(function (_a) {
                        var time_tracker_id = _a.time_tracker_id;
                        return time_tracker_id === user.id;
                    })} task={task}/>
            </div>
          </div>);
        })}
      {stoppingTimeEntry !== null && (<StopMemberEntryModal isOpen user={stoppingTimeEntry.user} onCancel={handleCloseModal} onStop={handleStop}/>)}
    </div>);
};
export default WorkingUsers;
