import queryString from 'query-string';
import api2 from '@/api2';
import { fetchTaskRequest, fetchTaskSuccess, fetchTaskFailure, refreshTaskRequest, refreshTaskSuccess, refreshTaskFailure, updateTaskRequest, updateTaskSuccess, updateTaskFailure, mergeTaskRequest, mergeTaskSuccess, mergeTaskFailure, trashTaskRequest, trashTaskSuccess, trashTaskFailure, } from '@/modules/tasks';
import { changeTask } from '@/modules/task_management';
export var fetchTask = function (id, params) {
    if (params === void 0) { params = {}; }
    var _query = queryString.stringify(params);
    var query = _query ? "?".concat(_query) : '';
    return function (dispatch, getState) {
        dispatch(fetchTaskRequest());
        return api2(dispatch, getState, "/api/v2/tasks/".concat(id).concat(query), 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (task) {
                    dispatch(fetchTaskSuccess(task));
                    return task;
                });
            }
            else {
                dispatch(fetchTaskFailure());
                return { error: true };
            }
        });
    };
};
export var refreshTask = function (id, params) {
    if (params === void 0) { params = {}; }
    var _query = queryString.stringify(params);
    var query = _query ? "?".concat(_query) : '';
    return function (dispatch, getState) {
        dispatch(refreshTaskRequest());
        return api2(dispatch, getState, "/api/v2/tasks/".concat(id).concat(query), 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (task) {
                    dispatch(refreshTaskSuccess(task));
                    return task;
                });
            }
            else {
                dispatch(refreshTaskFailure());
                return { error: true };
            }
        });
    };
};
export var updateTask = function (id, task) {
    return function (dispatch, getState) {
        dispatch(updateTaskRequest());
        return api2(dispatch, getState, "/api/v2/tasks/".concat(id), 'PATCH', task).then(function (response) {
            if (response.ok) {
                return response.json().then(function (task) {
                    dispatch(updateTaskSuccess(task));
                    return task;
                });
            }
            else {
                dispatch(updateTaskFailure());
                return { error: true };
            }
        });
    };
};
export var mergeTask = function (id, task) {
    return function (dispatch, getState) {
        dispatch(mergeTaskRequest());
        return api2(dispatch, getState, "/api/v2/tasks/".concat(id), 'PATCH', task).then(function (response) {
            if (response.ok) {
                return response.json().then(function (task) {
                    dispatch(mergeTaskSuccess(id, task));
                    return task;
                });
            }
            else {
                dispatch(mergeTaskFailure());
                return { error: true };
            }
        });
    };
};
export var trashTask = function (id) {
    return function (dispatch, getState) {
        dispatch(trashTaskRequest());
        return api2(dispatch, getState, "/api/v2/tasks/".concat(id, "/trash"), 'PATCH').then(function (response) {
            if (response.ok) {
                return response.json().then(function (task) {
                    dispatch(trashTaskSuccess(task));
                    dispatch(changeTask(task));
                    return task;
                });
            }
            else {
                dispatch(trashTaskFailure());
                return { error: true };
            }
        });
    };
};
