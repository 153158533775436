import React from 'react';
import { Tooltip } from 'reactstrap';
import { numberWithDelimiter } from '@/utils/number_helper';
import { formatDuration, calcRatio } from './helpers';
import Icon from '@/components/atoms/icon';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
export var ActivityTooltip = function (_a) {
    var targetId = _a.targetId, summary = _a.summary;
    var user = summary.user, userDuration = summary.userDuration, userAmount = summary.userAmount, categorySummaries = summary.categorySummaries;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var toggle = React.useCallback(function () {
        setIsOpen(!isOpen);
    }, [isOpen]);
    if (!user) {
        return null;
    }
    return (<Tooltip className="report-member-activities-tooltip" fade={false} isOpen={isOpen} placement="right" target={targetId} toggle={toggle}>
      {isOpen && (<table className="table mb-0">
          <thead>
            <tr>
              <th className="text-nowrap">{user.nickname}</th>
              <th className="text-nowrap" colSpan={2}>
                <Icon className="text-muted mr-1" name="clock"/>
                {formatDuration(userDuration)}
              </th>
              <th className="text-nowrap" colSpan={2}>
                <span className="text-muted mr-1">¥</span>
                {numberWithDelimiter(userAmount)}
              </th>
            </tr>
          </thead>
          <tbody>
            {categorySummaries.map(function (_a) {
                var visible = _a.visible, category = _a.category, categoryDuration = _a.categoryDuration, categoryAmount = _a.categoryAmount;
                if (!visible) {
                    return;
                }
                return (<tr key={category.id}>
                    <td className="text-left">
                      <CategoryCircleIcon color={category.color}/>
                      {category.title}
                    </td>
                    <td className="text-nowrap">
                      <Icon className="text-muted mr-1" name="clock"/>
                      {formatDuration(categoryDuration)}
                    </td>
                    <td className="text-nowrap">
                      {calcRatio(categoryDuration, userDuration)} %
                    </td>
                    <td className="text-nowrap">
                      <span className="text-muted mr-1">¥</span>
                      {numberWithDelimiter(categoryAmount)}
                    </td>
                    <td className="text-nowrap">
                      {calcRatio(categoryAmount, userAmount)} %
                    </td>
                  </tr>);
            })}
          </tbody>
        </table>)}
    </Tooltip>);
};
