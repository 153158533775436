import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import I18n from '@/constants/i18n';
import MainMenuContainer from '@/containers/main_menu_container';
import WorkingUsersContainer from '@/containers/member/working_users_container';
import MemberSchedule from './member_schedule';
import { ClearableSearchField } from '@/components/clearable_search_field';
import { sortTeamsByWorkspace, workspacePath, workspaceTeams as filterWorkspaceTeams, } from '@/utils/workspace_helper';
import useCustomFields from '@/hooks/use_custom_fields';
var Member = function (_a) {
    var memberSummaries = _a.memberSummaries, teams = _a.teams, workspaces = _a.workspaces, fetchMemberSummaries = _a.fetchMemberSummaries;
    var _b = React.useState(''), searchText = _b[0], setSearchText = _b[1];
    var params = useParams();
    var navigate = useNavigate();
    var tab = params.tab || 'realtime';
    var workspaceTeams = React.useMemo(function () { return filterWorkspaceTeams(teams.items); }, [teams.items]);
    useCustomFields(workspaceTeams);
    var onSelectTab = function (tab) {
        navigate(workspacePath("/member/".concat(tab)));
    };
    return (<div className="content-wrap">
      <MainMenuContainer />
      <div className="content-main">
        <div className="toolbar d-flex">
          <ClearableSearchField className="flex-grow-1 mr-2 mr-md-5" placeholderKey={"memberSearchPlaceholder.".concat(tab)} searchText={searchText} size="lg" onChangeSearchText={function (searchText) { return setSearchText(searchText); }}/>
          <ul className="nav nav-pills overflow-visible">
            <li className="nav-item">
              <a className={[
            'nav-link',
            tab === 'realtime' ? 'active' : '',
        ].join(' ')} href="#" onClick={function (e) {
            e.preventDefault();
            onSelectTab('realtime');
        }}>
                {I18n.t('member.realtime')}
              </a>
            </li>
            <li className="nav-item">
              <a className={[
            'nav-link',
            tab === 'schedule' ? 'active' : '',
        ].join(' ')} href="#" onClick={function (e) {
            e.preventDefault();
            onSelectTab('schedule');
        }}>
                {I18n.t('member.timeline')}
              </a>
            </li>
          </ul>
        </div>
        <div className="row no-gutters with-toolbar">
          {tab === 'realtime' && (<WorkingUsersContainer searchText={searchText}/>)}
          {tab === 'schedule' && (<MemberSchedule fetchMemberSummaries={fetchMemberSummaries} memberSummaries={memberSummaries} searchText={searchText} teams={sortTeamsByWorkspace(workspaceTeams, workspaces.items)}/>)}
        </div>
      </div>
    </div>);
};
export default Member;
