import { __assign } from "tslib";
import api2 from '@/api2';
import { sortTeams } from '@/modules/teams';
export var FETCH_EXPORTABLE_TEAMS_REQUEST = 'timecrowd/teams/FETCH_EXPORTABLE_TEAMS_REQUEST';
export var FETCH_EXPORTABLE_TEAMS_SUCCESS = 'timecrowd/teams/FETCH_EXPORTABLE_TEAMS_SUCCESS';
export var FETCH_EXPORTABLE_TEAMS_FAILURE = 'timecrowd/teams/FETCH_EXPORTABLE_TEAMS_FAILURE';
export var initialState = {
    isLoading: false,
    isFetched: false,
    items: [],
};
export default function teams(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_EXPORTABLE_TEAMS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isFetched: false });
        case FETCH_EXPORTABLE_TEAMS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isFetched: true, items: sortTeams(action.teams) });
        case FETCH_EXPORTABLE_TEAMS_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
export var fetchExportableTeamsRequest = function () { return ({
    type: FETCH_EXPORTABLE_TEAMS_REQUEST,
}); };
export var fetchExportableTeamsSuccess = function (teams) { return ({
    type: FETCH_EXPORTABLE_TEAMS_SUCCESS,
    teams: teams,
}); };
export var fetchExportableTeamsFailure = function () { return ({
    type: FETCH_EXPORTABLE_TEAMS_FAILURE,
}); };
export var fetchExportableTeams = function () {
    return function (dispatch, getState) {
        dispatch(fetchExportableTeamsRequest());
        return api2(dispatch, getState, '/api/v2/exportable_teams', 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (teams) {
                    dispatch(fetchExportableTeamsSuccess(teams));
                    return teams;
                });
            }
            else {
                dispatch(fetchExportableTeamsFailure());
                return { error: true };
            }
        });
    };
};
