import React from 'react';
import I18n from '@/constants/i18n';
import Helplink from '@/components/molecules/helplink';
import CustomCheck from '@/components/atoms/custom_check';
import CustomRadio from '@/components/atoms/custom_radio';
export var CalendarIntegrationAutoStartSetting = function (_a) {
    var _b = _a.helplink, helplink = _b === void 0 ? '' : _b, isAutoStart = _a.isAutoStart, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, startMode = _a.startMode, stopMode = _a.stopMode, onChangeAutoStart = _a.onChangeAutoStart, onChangeStartMode = _a.onChangeStartMode, onChangeStopMode = _a.onChangeStopMode, onClickLink = _a.onClickLink;
    return (<div className="form-group mt-3">
      <label className="font-weight-bold">
        {I18n.t('shared.autoStart')}
        <Helplink className="ml-1" href={helplink} onClick={onClickLink}/>
      </label>
      <div>
        <CustomCheck checked={isAutoStart} disabled={isDisabled} onChange={onChangeAutoStart}>
          {I18n.t('shared.autoStartLabel')}
        </CustomCheck>
        <div className={"align-items-start mt-1 ml-3 ".concat(isAutoStart ? 'd-block' : 'd-none')}>
          <div>
            <label className="font-weight-bold">
              {I18n.t('shared.startModeLabel')}
            </label>
            {['auto', 'force'].map(function (start_mode) { return (<div key={start_mode}>
                <CustomRadio checked={startMode === start_mode} disabled={isDisabled} value={start_mode} onChange={function () { return onChangeStartMode === null || onChangeStartMode === void 0 ? void 0 : onChangeStartMode(start_mode); }}>
                  {I18n.t("shared.startMode.".concat(start_mode))}
                </CustomRadio>
              </div>); })}
          </div>
          <div className="mt-1">
            <label className="font-weight-bold">
              {I18n.t('shared.stopModeLabel')}
            </label>
            {['auto', 'none'].map(function (stop_mode) { return (<div key={stop_mode}>
                <CustomRadio checked={stopMode === stop_mode} disabled={isDisabled} value={stop_mode} onChange={function () { return onChangeStopMode === null || onChangeStopMode === void 0 ? void 0 : onChangeStopMode(stop_mode); }}>
                  {I18n.t("shared.stopMode.".concat(stop_mode))}
                </CustomRadio>
              </div>); })}
          </div>
        </div>
      </div>
    </div>);
};
