import { __assign, __awaiter, __generator } from "tslib";
import { fetchSearchedTasks } from '@/actions/task';
// Actions
export var SET_TASKS = 'timecrowd/task_management/SET_TASKS';
export var CHANGE_TASK = 'timecrowd/task_management/CHANGE_TASK';
export var REMOVE_TASK = 'timecrowd/task_management/REMOVE_TASK';
export var EXPAND_TASKS = 'timecrowd/task_management/EXPAND_TASKS';
export var SET_SELECTED_TEAM = 'timecrowd/task_management/SET_SELECTED_TEAM';
export var SET_SELECTED_STATE = 'timecrowd/task_management/SET_SELECTED_STATE';
export var SET_SELECTED_CATEGORIES = 'timecrowd/task_management/SET_SELECTED_CATEGOREIS';
export var SET_KEYWORD = 'timecrowd/task_management/SET_KEYWORD';
export var SET_IS_SEARCH = 'timecrowd/task_management/SET_IS_SEARCH';
export var SET_IS_SHOW_ARCHIVED = 'timecrowd/task_management/SET_IS_SHOW_ARCHIVED';
export var RESET_TASKS = 'timecrowd/task_management/RESET_TASKS';
export var SET_IS_HISTORY_LOADED = 'timecrowd/task_management/SET_IS_HISTORY_LOADED';
export var FETCH_SEARCHED_TASKS_REQUEST = 'timecrowd/task_management/FETCH_SEARCHED_TASKS_REQUEST';
export var initialState = {
    items: [],
    next: null,
    params: {},
    selectedTeam: null,
    selectedState: 'all',
    selectedCategories: [],
    keyword: '',
    isSearch: false,
    isShowArchived: false,
    isHistoryLoaded: false,
    status: 'pending',
};
export default function taskManagement(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_TASKS:
            return __assign(__assign({}, state), { items: action.tasks, next: action.next, params: action.params || {}, status: 'loaded' });
        case EXPAND_TASKS:
            return __assign(__assign({}, state), { items: state.items.concat(action.tasks), next: action.next });
        case CHANGE_TASK:
            return __assign(__assign({}, state), { items: state.items.map(function (task) {
                    if (task.id === action.task.id) {
                        return __assign(__assign({}, task), action.task);
                    }
                    else {
                        return task;
                    }
                }) });
        case REMOVE_TASK:
            return __assign(__assign({}, state), { items: state.items.filter(function (task) {
                    return task.id !== action.id;
                }) });
        case SET_SELECTED_TEAM:
            return __assign(__assign({}, state), { selectedTeam: action.selectedTeam || null });
        case SET_SELECTED_STATE:
            return __assign(__assign({}, state), { selectedState: action.selectedState });
        case SET_SELECTED_CATEGORIES:
            return __assign(__assign({}, state), { selectedCategories: action.selectedCategories });
        case SET_KEYWORD:
            return __assign(__assign({}, state), { keyword: action.keyword });
        case SET_IS_SEARCH:
            return __assign(__assign({}, state), { isSearch: action.isSearch });
        case SET_IS_SHOW_ARCHIVED:
            return __assign(__assign({}, state), { isShowArchived: action.isShowArchived });
        case SET_IS_HISTORY_LOADED:
            return __assign(__assign({}, state), { isHistoryLoaded: true });
        case FETCH_SEARCHED_TASKS_REQUEST:
            return __assign(__assign({}, state), { status: 'loading' });
        case RESET_TASKS:
            return initialState;
        default:
            return state;
    }
}
export var setTasks = function (tasks, next, params) { return ({
    type: SET_TASKS,
    tasks: tasks,
    next: next,
    params: params,
}); };
export var expandTasks = function (tasks, next) { return ({
    type: EXPAND_TASKS,
    tasks: tasks,
    next: next,
}); };
export var changeTask = function (task) { return ({
    type: CHANGE_TASK,
    task: task,
}); };
export var removeTask = function (id) { return ({
    type: REMOVE_TASK,
    id: id,
}); };
export var setSelectedTeam = function (selectedTeam) { return ({
    type: SET_SELECTED_TEAM,
    selectedTeam: selectedTeam,
}); };
export var setSelectedState = function (selectedState) { return ({
    type: SET_SELECTED_STATE,
    selectedState: selectedState,
}); };
export var setSelectedCategories = function (selectedCategories) { return ({
    type: SET_SELECTED_CATEGORIES,
    selectedCategories: selectedCategories,
}); };
export var setKeyword = function (keyword) { return ({
    type: SET_KEYWORD,
    keyword: keyword,
}); };
export var setIsSearch = function (isSearch) { return ({
    type: SET_IS_SEARCH,
    isSearch: isSearch,
}); };
export var setIsShowArchived = function (isShowArchived) { return ({
    type: SET_IS_SHOW_ARCHIVED,
    isShowArchived: isShowArchived,
}); };
export var resetTasks = function () { return ({
    type: RESET_TASKS,
}); };
export var setIsHistoryLoaded = function () { return ({
    type: SET_IS_HISTORY_LOADED,
}); };
export var fetchSearchedTasksRequest = function () { return ({
    type: FETCH_SEARCHED_TASKS_REQUEST,
}); };
export var loadHistory = function (teams, fetchCategories, loadTaskManagementHistory) {
    return function (dispatch, getState) {
        var current = getState().workspaces.current;
        var namespace = current ? "_workspace_".concat(current.id) : '';
        return new Promise(function (resolve) {
            loadTaskManagementHistory(namespace, function (history) { return __awaiter(void 0, void 0, void 0, function () {
                var _a, selectedState, isShowArchived, keyword, isSearch, selectedTeamId, selectedCategoryIds, categories, selectedCategories, teamCategoryIds, params;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            dispatch(setIsHistoryLoaded());
                            if (!!history) return [3 /*break*/, 2];
                            return [4 /*yield*/, fetchCategories()];
                        case 1:
                            _b.sent();
                            dispatch(fetchSearchedTasks({}));
                            resolve();
                            return [2 /*return*/];
                        case 2:
                            _a = JSON.parse(history), selectedState = _a.selectedState, isShowArchived = _a.isShowArchived, keyword = _a.keyword, isSearch = _a.isSearch, selectedTeamId = _a.selectedTeamId, selectedCategoryIds = _a.selectedCategoryIds;
                            dispatch(setSelectedState(selectedState));
                            dispatch(setIsShowArchived(isShowArchived));
                            dispatch(setKeyword(keyword));
                            dispatch(setIsSearch(isSearch));
                            dispatch(setSelectedTeam(teams.find(function (_a) {
                                var id = _a.id;
                                return id === selectedTeamId;
                            })));
                            return [4 /*yield*/, fetchCategories()];
                        case 3:
                            categories = _b.sent();
                            selectedCategories = categories.filter(function (_a) {
                                var id = _a.id;
                                return selectedCategoryIds.includes(id);
                            });
                            dispatch(setSelectedCategories(selectedCategories));
                            teamCategoryIds = selectedCategories
                                .filter(function (_a) {
                                var team_id = _a.team_id;
                                return selectedTeamId === null || team_id === selectedTeamId;
                            })
                                .map(function (_a) {
                                var id = _a.id;
                                return id;
                            });
                            params = {
                                state: selectedState,
                                title: keyword,
                                team_id: selectedTeamId,
                                category_ids: teamCategoryIds,
                                with_archived: isShowArchived,
                            };
                            dispatch(fetchSearchedTasks(params));
                            resolve();
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
};
export var saveHistory = function (saveTaskManagementHistory) {
    return function (_dispatch, getState) {
        var _a;
        var state = getState();
        var _b = state.taskManagement, selectedState = _b.selectedState, isShowArchived = _b.isShowArchived, keyword = _b.keyword, isSearch = _b.isSearch, selectedTeam = _b.selectedTeam, selectedCategories = _b.selectedCategories;
        var history = {
            selectedState: selectedState,
            isShowArchived: isShowArchived,
            keyword: keyword,
            isSearch: isSearch,
            selectedTeamId: (_a = selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.id) !== null && _a !== void 0 ? _a : null,
            selectedCategoryIds: selectedCategories.map(function (_a) {
                var id = _a.id;
                return id;
            }),
        };
        var current = state.workspaces.current;
        var namespace = current ? "_workspace_".concat(current.id) : '';
        saveTaskManagementHistory(namespace, JSON.stringify(history));
    };
};
