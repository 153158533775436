import { connect } from 'react-redux';
import DeleteAssetButton from '@/components/export/delete_asset_button';
import { selectAsset, openDeleteAssetModal } from '@/modules/exports';
var mapStateToProps = function (state) { return state; };
var mapDispatchToProps = function (dispatch) { return ({
    onDelete: function (asset) {
        dispatch(selectAsset(asset));
        dispatch(openDeleteAssetModal());
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(DeleteAssetButton);
