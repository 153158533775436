import React from 'react';
import { arrangeCustomFieldsValues, expressionErrors } from './helpers';
import { numberWithDelimiter } from '@/utils/number_helper';
import { CustomFieldsErrorBadge } from '@/components/cutom_fields_error_badge';
import { formatDateTime } from '@/utils/time_helper';
import { SyntaxHighlighting } from '@/components/syntax_highlighting';
import I18n from '@/constants/i18n';
export var CustomFieldsValueList = function (props) {
    var customFieldsMasters = props.customFieldsMasters, customFieldsValues = props.customFieldsValues, categories = props.categories, target = props.target, canManage = props.canManage, labelWidth = props.labelWidth, _a = props.showsDetails, showsDetails = _a === void 0 ? false : _a;
    var _b = arrangeCustomFieldsValues(customFieldsMasters, customFieldsValues, categories, target), customFieldsValuesByMasterId = _b.customFieldsValuesByMasterId, errorsByMasterId = _b.errorsByMasterId;
    return (<div className="d-flex">
      <table className="w-100 bg-transparent">
        <tbody>
          {customFieldsMasters.map(function (_a) {
            var _b;
            var id = _a.id, name = _a.name, field_type = _a.field_type, unit = _a.unit, expression_text = _a.expression_text;
            var value = (_b = customFieldsValuesByMasterId[id]) !== null && _b !== void 0 ? _b : {};
            var value_text = value.value_text;
            return (<tr key={id}>
                  <td className={"align-top w-".concat(labelWidth, " text-break")}>
                    {showsDetails &&
                    canManage &&
                    errorsByMasterId[id].length > 0 ? (<span className="text-danger">
                        {name}
                        <CustomFieldsErrorBadge className="ml-1 mr-1" errors={errorsByMasterId[id]} tooltipId={"value-list-item-".concat(target.id, "-").concat(id, "-tooltip")}/>
                      </span>) : (name)}
                  </td>
                  <td className="align-top pl-2 text-break">
                    {!!value_text && (<>
                        {field_type === 'number' &&
                        "".concat(numberWithDelimiter(value_text), " ").concat(unit)}
                        {field_type === 'duration' &&
                        (function () {
                            var _a = value_text.split(':'), hours = _a[0], minutes = _a[1];
                            return "".concat(hours, ":").concat(String(minutes).padStart(2, '0'));
                        })()}
                        {!['number', 'duration', 'expression'].includes(field_type) && value.value_text}
                      </>)}
                    {field_type === 'expression' && (<>
                        <div>
                          <span className="mr-1">
                            {expressionErrors.includes(value_text) ? (<span className={showsDetails ? 'text-danger' : ''}>
                                {I18n.t("shared.customFieldsExpressionErrors.".concat(value_text))}
                              </span>) : !isNaN(value_text) ? ("".concat(numberWithDelimiter(value_text).replace(/\.0$/, ''), " ").concat(unit)) : (I18n.t('shared.customFieldsExpressionCalculating'))}
                          </span>
                          {showsDetails && !!value.value_text && (<small className="text-muted text-nowrap">
                              {I18n.t('shared.asOf', {
                            time: formatDateTime(value.updated_at),
                        })}
                            </small>)}
                        </div>
                        {showsDetails && (<SyntaxHighlighting className="small my-1" expressionText={expression_text}/>)}
                      </>)}
                  </td>
                </tr>);
        })}
        </tbody>
      </table>
    </div>);
};
