import { __assign } from "tslib";
import api from '@/api';
export var FETCH_FILTERS_REQUEST = 'timecrowd/filters/FETCH_FILTERS_REQUEST';
export var FETCH_FILTERS_SUCCESS = 'timecrowd/filters/FETCH_FILTERS_SUCCESS';
export var UPDATE_FILTER_REQUEST = 'timecrowd/filters/UPDATE_FILTER_REQUEST';
export var UPDATE_FILTER_SUCCESS = 'timecrowd/filters/UPDATE_FILTER_SUCCESS';
export var RESET_FILTERS = 'timecrowd/filters/RESET_FILTERS';
export var fetchFilters = function (team_id, state) {
    if (state === void 0) { state = ''; }
    return function (dispatch, getState) {
        dispatch(fetchFiltersRequest());
        return api(dispatch, getState, "teams/".concat(team_id, "/filters?state=").concat(state), 'GET').then(function (filters) {
            return dispatch(fetchFiltersSuccess(filters));
        });
    };
};
export var updateFilter = function (filter) {
    return function (dispatch, getState) {
        dispatch(updateFilterRequest());
        return api(dispatch, getState, "teams/".concat(filter.team_id, "/filters/").concat(filter.id), 'PATCH', filter).then(function (filter) {
            return dispatch(updateFilterSuccess(filter));
        });
    };
};
var initialState = {
    filters: [],
    isLoading: false,
    isFetched: false,
};
export default function filters(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_FILTERS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_FILTERS_SUCCESS:
            return __assign(__assign({}, state), { filters: action.filters, isLoading: false, isFetched: true });
        case UPDATE_FILTER_SUCCESS:
            return __assign(__assign({}, state), { filters: state.filters.map(function (filter) {
                    return filter.id === action.filter.id ? action.filter : filter;
                }), isLoading: false });
        case RESET_FILTERS:
            return __assign(__assign({}, state), { filters: [] });
        case UPDATE_FILTER_REQUEST:
            return state;
        default:
            return state;
    }
}
export var fetchFiltersRequest = function () { return ({
    type: FETCH_FILTERS_REQUEST,
}); };
export var fetchFiltersSuccess = function (filters) { return ({
    type: FETCH_FILTERS_SUCCESS,
    filters: filters,
}); };
export var updateFilterRequest = function () { return ({
    type: UPDATE_FILTER_REQUEST,
}); };
export var updateFilterSuccess = function (filter) { return ({
    type: UPDATE_FILTER_SUCCESS,
    filter: filter,
}); };
export var resetFilters = function () { return ({
    type: RESET_FILTERS,
}); };
