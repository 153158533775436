import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '@/constants/i18n';
import { conditionTypes } from '@/types';
export var ReminderConditionField = function (_a) {
    var _b, _c;
    var index = _a.index, reminderEmailsSetting = _a.reminderEmailsSetting, onChangeReminderEmailsSetting = _a.onChangeReminderEmailsSetting;
    return reminderEmailsSetting.reminder_type === 'general' ? (<div className="row mt-3">
      <div className="col-3">{I18n.t('shared.reminderCondition')}</div>
      <div className="col">
        <UncontrolledDropdown>
          <DropdownToggle caret>
            {I18n.t("shared.reminderConditions.".concat(reminderEmailsSetting.condition_type))}
          </DropdownToggle>
          <DropdownMenu>
            {conditionTypes.map(function (cond) {
            return (<DropdownItem key={cond} value={cond} onClick={function () {
                    return onChangeReminderEmailsSetting(index, {
                        condition_type: cond,
                    });
                }}>
                  {I18n.t("shared.reminderConditions.".concat(cond))}
                </DropdownItem>);
        })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>) : (<div className="row mt-3">
      <div className="col-3">
        {reminderEmailsSetting.reminder_type === 'forgot_record'
            ? I18n.t('shared.reminderMinDuration')
            : I18n.t('shared.reminderWorkingDuration')}
      </div>
      <div className="col d-flex align-items-center">
        <input className="form-control w-25 mr-2" max="24" min="0" type="number" value={(_b = reminderEmailsSetting.condition_hours) !== null && _b !== void 0 ? _b : ''} onChange={function (e) {
            return onChangeReminderEmailsSetting(index, {
                condition_hours: e.target.value,
            });
        }}/>
        {I18n.t('shared.hours')}
        <input className="form-control w-25 mx-2" max="59" min="0" type="number" value={(_c = reminderEmailsSetting.condition_minutes) !== null && _c !== void 0 ? _c : ''} onChange={function (e) {
            return onChangeReminderEmailsSetting(index, {
                condition_minutes: e.target.value,
            });
        }}/>
        {I18n.t('shared.minutes')}
      </div>
    </div>);
};
