import React from 'react';
import I18n from '@/constants/i18n';
import Counter from '@/components/atoms/counter';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import Icon from '@/components/atoms/icon';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
import LoadingSpinner from '@/components/atoms/loading_spinner';
var QuickTaskCurrent = function (_a) {
    var currentEntry = _a.currentEntry, isLoading = _a.isLoading, onStop = _a.onStop;
    return (<form className="form-inline quicktask-current-wrap" onClick={onStop}>
      <div className="quicktask-current pl-1">
        <span className="current-title font-weight-bold">
          <WorkspaceAvatarContainer className="mr-1 align-middle grayscale" workspaceId={currentEntry.team.workspaces_workspace_id}/>
          <CategoryCircleIcon color={currentEntry.category.color}/>
          {currentEntry.task.title}
        </span>
        <span className="current-elapsed">
          <Icon className="mr-1" name="clock"/>
          <Counter entry={currentEntry}/>
        </span>
        <span className="current-category-name">
          {currentEntry.category.title} - {currentEntry.team.name}
        </span>
      </div>
      {!isLoading && (<button className="btn btn-danger btn-circle" type="button" onClick={onStop}>
          <Icon name="stop"/>
        </button>)}
      {isLoading && (<>
          <div>
            <LoadingSpinner size="md"/>
          </div>
          <div className="ml-1 shrink-0 hidden-sm-down">
            <span className="text-muted">{I18n.t('shared.stopping')}...</span>
          </div>
        </>)}
    </form>);
};
export default QuickTaskCurrent;
