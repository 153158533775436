import { __spreadArray } from "tslib";
import React from 'react';
import { useLocation } from 'react-router-dom';
import I18n from '@/constants/i18n';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import TeamEmployList from '@/components/team_employ_list';
import { TeamEmployListModal } from '@/components/team_employ_list/team_employ_list_modal';
import { isSameEmploy } from '@/components/team_employ_list/helpers';
var UserEmployList = function (props) {
    var team = props.team, membership = props.membership, employs = props.employs, isLoading = props.isLoading, onCreate = props.onCreate, onUpdate = props.onUpdate, onDelete = props.onDelete, workspacesEmploys = props.workspacesEmploys, currentWorkspace = props.currentWorkspace;
    var queryUserId = Number(new URLSearchParams(useLocation().search).get('user_id'));
    var _a = React.useState(membership.user.id === queryUserId), isOpen = _a[0], setIsOpen = _a[1];
    var handleToggle = function () {
        setIsOpen(!isOpen);
    };
    var arrangedEmploys = React.useMemo(function () {
        var allEmploys = __spreadArray(__spreadArray([], workspacesEmploys, true), employs, true).sort(function (a, b) {
            return a.started_at.localeCompare(b.started_at);
        });
        var defaultEmploy = workspacesEmploys[0];
        return defaultEmploy
            ? __spreadArray([
                defaultEmploy
            ], allEmploys.filter(function (employ) { return !isSameEmploy(employ, defaultEmploy); }), true) : allEmploys;
    }, [workspacesEmploys, employs]);
    if (isLoading) {
        return <LoadingSpinner size="md"/>;
    }
    if (membership.team.id !== team.id) {
        return null;
    }
    return (<div>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <TeamEmployList employs={arrangedEmploys}/>
        </div>
        <button className="btn btn-secondary btn-sm float-right" onClick={handleToggle}>
          {I18n.t('shared.change')}
        </button>
      </div>
      <TeamEmployListModal currentWorkspace={currentWorkspace} disabled={false} employs={arrangedEmploys} isOpen={isOpen} membership={membership} toggle={handleToggle} onCreate={onCreate} onDelete={onDelete} onUpdate={onUpdate}/>
    </div>);
};
export default UserEmployList;
