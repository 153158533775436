import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
export var FETCH_CUSTOM_FIELDS_VALUES_REQUEST = 'timecrowd/CUSTOM_FIELDS_VALUES/FETCH_CUSTOM_FIELDS_VALUES_REQUEST';
export var FETCH_CUSTOM_FIELDS_VALUES_SUCCESS = 'timecrowd/CUSTOM_FIELDS_VALUES/FETCH_CUSTOM_FIELDS_VALUES_SUCCESS';
export var FETCH_CUSTOM_FIELDS_VALUES_FAILURE = 'timecrowd/CUSTOM_FIELDS_VALUES/FETCH_CUSTOM_FIELDS_VALUES_FAILURE';
export var initialState = {
    items: [],
    statusByTeamId: {},
};
export default function customFieldsValues(state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CUSTOM_FIELDS_VALUES_REQUEST:
            return __assign(__assign({}, state), { statusByTeamId: __assign(__assign({}, state.statusByTeamId), (_a = {}, _a[action.payload] = 'loading', _a)) });
        case FETCH_CUSTOM_FIELDS_VALUES_SUCCESS: {
            var _d = action.payload, items = _d.items, teamId_1 = _d.teamId;
            return __assign(__assign({}, state), { items: state.items
                    .filter(function (_a) {
                    var team_id = _a.team_id;
                    return team_id !== teamId_1;
                })
                    .concat(items.map(function (item) { return (__assign(__assign({}, item), { team_id: teamId_1 })); })), statusByTeamId: __assign(__assign({}, state.statusByTeamId), (_b = {}, _b[teamId_1] = 'loaded', _b)) });
        }
        case FETCH_CUSTOM_FIELDS_VALUES_FAILURE:
            return __assign(__assign({}, state), { statusByTeamId: __assign(__assign({}, state.statusByTeamId), (_c = {}, _c[action.payload] = 'pending', _c)) });
        default:
            return state;
    }
}
export var fetchCustomFieldsValuesRequest = function (teamId) { return ({
    type: FETCH_CUSTOM_FIELDS_VALUES_REQUEST,
    payload: teamId,
}); };
export var fetchCustomFieldsValuesSuccess = function (items, teamId) { return ({
    type: FETCH_CUSTOM_FIELDS_VALUES_SUCCESS,
    payload: {
        items: items,
        teamId: teamId,
    },
}); };
export var fetchCustomFieldsValuesFailure = function (teamId) { return ({
    type: FETCH_CUSTOM_FIELDS_VALUES_FAILURE,
    payload: teamId,
}); };
export var fetchCustomFieldsValues = function (teamId) {
    return function (dispatch, getState) {
        dispatch(fetchCustomFieldsValuesRequest(teamId));
        return api2(dispatch, getState, "/api/v2/custom_fields/values?team_id=".concat(teamId), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchCustomFieldsValuesSuccess(items, teamId));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchCustomFieldsValuesFailure(teamId));
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
