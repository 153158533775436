import React from 'react';
import I18n from '@/constants/i18n';
import { formatDateWithLocale, beforeDay } from '@/utils/time_helper';
export default function WorkspacesEmployListItem(_a) {
    var employ = _a.employ, index = _a.index, nextEmploy = _a.nextEmploy, isCurrent = _a.isCurrent;
    return (<div>
      <span>{I18n.t('shared.currencyUnit')}</span>
      <span>{employ.rate}</span>
      <span>/{I18n.t('shared.hours')}</span>
      <span className="text-muted ml-1">
        {index < 1 &&
            nextEmploy &&
            employ.workspaces_workspace_id &&
            I18n.t('shared.toDate', {
                date: formatDateWithLocale(beforeDay(nextEmploy.started_at, 1)),
            })}
        {index < 1 &&
            !nextEmploy &&
            employ.workspaces_workspace_id &&
            I18n.t('shared.allPeriods')}
        {(index > 0 || !employ.workspaces_workspace_id) &&
            I18n.t('shared.fromDate', {
                date: formatDateWithLocale(employ.started_at),
            })}
      </span>
      {isCurrent && (<span className="badge badge-success opacity-50 ml-1 rounded-0">
          {I18n.t('shared.currentRate')}
        </span>)}
    </div>);
}
