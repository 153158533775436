import { __awaiter, __generator } from "tslib";
import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import TimeEntryListItem from '@/components/task_edit/time_entry_list_item';
export function TaskItemDetails(_a) {
    var _this = this;
    var isOpened = _a.isOpened, task = _a.task, date = _a.date, timeEntries = _a.timeEntries, currentEntry = _a.currentEntry, totalTimeSec = _a.totalTimeSec, fetchDailyTimeEntries = _a.fetchDailyTimeEntries, destroyTimeEntry = _a.destroyTimeEntry;
    var _b = React.useState(false), isFetched = _b[0], setIsFetched = _b[1];
    React.useEffect(function () {
        setIsFetched(false);
    }, [isOpened, currentEntry, totalTimeSec]);
    React.useEffect(function () {
        if (!isOpened || isFetched) {
            return;
        }
        (function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetchDailyTimeEntries(task.team_id, task.id, moment(date).startOf('day').toDate(), moment(date).endOf('day').toDate())];
                    case 1:
                        _a.sent();
                        setIsFetched(true);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [date, fetchDailyTimeEntries, isFetched, isOpened, task.id, task.team_id]);
    if (!isOpened) {
        return null;
    }
    var filteredTimeEntries = timeEntries.filter(function (timeEntry) {
        return timeEntry.time_trackable_id === task.id &&
            moment(date).isSame(new Date(timeEntry.started_at), 'day');
    });
    return (<div className="border-bottom-1">
      {filteredTimeEntries.map(function (timeEntry) { return (<TimeEntryListItem key={timeEntry.id} withoutAvatar className="border-bottom-0 px-1 position-static" destroyTimeEntry={destroyTimeEntry} query={''} task={task} timeEntry={timeEntry}/>); })}
    </div>);
}
