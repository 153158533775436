import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import Avatar from '@/components/atoms/avatar';
import Helplink from '@/components/molecules/helplink';
import variants from '@/constants/variants';
import WorkspacesEmployList from './index';
import { useNavigate } from 'react-router-dom';
export var TeamEmploysModal = function (_a) {
    var user = _a.user, teams = _a.teams, employsByTeamId = _a.employsByTeamId, membershipByTeamId = _a.membershipByTeamId, _b = _a.openURL, openURL = _b === void 0 ? variants.openURL : _b, toggleTeamMenu = _a.toggleTeamMenu;
    var _c = React.useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var navigate = useNavigate();
    var handleToggle = function () {
        setIsOpen(!isOpen);
    };
    var belongsTeams = teams.filter(function (team) {
        var _a;
        return (_a = membershipByTeamId[team.id]) === null || _a === void 0 ? void 0 : _a.some(function (membership) { return membership.user.id === user.id; });
    });
    var onClickLink = function (e) {
        e.preventDefault();
        toggleTeamMenu(false);
        navigate(e.currentTarget.getAttribute('href'));
    };
    return (<>
      <div className="pt-2">
        <button className="btn btn-link p-0" onClick={handleToggle}>
          {I18n.t('shared.employByTeams')}
        </button>
      </div>

      <Modal isOpen={isOpen} toggle={handleToggle}>
        <ModalHeaderMod toggle={handleToggle}>
          <div className="mt-1">
            {I18n.t('shared.teamEmploysTitle')}
            <Avatar className="ml-2 mr-1" image={user.avatar_url}/>
            {user.nickname}
          </div>
        </ModalHeaderMod>
        <ModalBody className="px-2 mx-1">
          <p>{I18n.t('shared.teamEmploysDescription')}</p>
          <p className="text-right">
            <Helplink className="ml-0" href="https://support.timecrowd.net/1428ec2d2afa804ebaf5f6b4ab0e9f2e" onClick={function (e) {
            e.preventDefault();
            openURL(e.currentTarget.href);
        }}>
              {I18n.t('shared.clickHereForDetails')}
            </Helplink>
          </p>
          <table className="table table-bordered">
            <thead>
              <tr>
                <td>{I18n.t('shared.team')}</td>
                <td>{I18n.t('shared.rate')}</td>
              </tr>
            </thead>
            <tbody>
              {belongsTeams.map(function (team) {
            var _a;
            return (<tr key={team.id}>
                  <td>
                    <Avatar className="mr-1" image={team.avatar_url}/>
                    <a href={"/app/workspaces/".concat(team.workspaces_workspace_id, "/teams/").concat(team.id, "/members?user_id=").concat(user.id)} onClick={onClickLink}>
                      {team.name}
                    </a>
                  </td>
                  <td>
                    <WorkspacesEmployList employs={((_a = employsByTeamId[team.id]) !== null && _a !== void 0 ? _a : []).filter(function (employ) { return employ.user_id === user.id; })}/>
                  </td>
                </tr>);
        })}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    </>);
};
