import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import Avatar from '@/components/atoms/avatar';
import I18n from '@/constants/i18n';
import { PersonalBadge, UnpaidBadge } from '@/components/badges';
export var TeamSelectDropdown = function (_a) {
    var teams = _a.teams, selectedTeam = _a.selectedTeam, onSelectTeam = _a.onSelectTeam, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.labelForAllTeam, labelForAllTeam = _c === void 0 ? I18n.t('taskManagement.allTeams') : _c;
    var _d = React.useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var onToggle = React.useCallback(function () {
        setIsOpen(!isOpen);
    }, [isOpen]);
    var selected = selectedTeam || {
        name: labelForAllTeam,
        avatar_url: null,
        payment_required: false,
        personal: false,
    };
    return (<ButtonDropdown className="ml-auto w-100 pl-1" disabled={disabled} isOpen={isOpen} size="lg" toggle={onToggle}>
      <DropdownToggle block caret>
        {selected.avatar_url && (<Avatar className="mr-1" image={selected.avatar_url} size="sm"/>)}
        {selected.name}
        {selectedTeam && (<UnpaidBadge className="ml-auto" team={selectedTeam} withLink={false}/>)}
        <PersonalBadge className="ml-auto" isPersonal={selected.personal}/>
      </DropdownToggle>
      <DropdownMenu right className="min-w-0">
        <DropdownItem onClick={function () { return onSelectTeam(null); }}>
          {labelForAllTeam}
        </DropdownItem>
        {teams.map(function (team) {
            return (<DropdownItem key={"team-select-".concat(team.id)} className="d-flex align-items-center text-break whitespace-normal" onClick={function () { return onSelectTeam(team); }}>
              <Avatar className="mr-1" image={team.avatar_url} size="sm"/>
              {team.name}
              <UnpaidBadge className="ml-auto" team={team} withLink={false}/>
              <PersonalBadge className="ml-auto" isPersonal={team.personal}/>
            </DropdownItem>);
        })}
      </DropdownMenu>
    </ButtonDropdown>);
};
