import React from 'react';
import I18n from '../../constants/i18n';
export default function UserLabel(_a) {
    var users = _a.users;
    var label;
    if (users.length === 0) {
        label = I18n.t('export.noUsers');
    }
    else if (users.length === 1) {
        label = users[0].nickname;
    }
    else {
        label = I18n.t('export.multipleUsers');
    }
    return <span>{label}</span>;
}
