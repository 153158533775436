import { __assign } from "tslib";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../actions';
var mapStateToProps = function (state) { return state; };
var mapDispatchToProps = function (dispatch) { return (__assign({ dispatch: dispatch }, bindActionCreators(actions, dispatch))); };
var connectedComponents = {};
var connectAll = function (_a) {
    var component = _a.component, title = _a.title;
    var connected = connectedComponents[title] ||
        connect(mapStateToProps, mapDispatchToProps)(component);
    connectedComponents[title] = connected;
    return connected;
};
export default connectAll;
