import { __assign } from "tslib";
import orderBy from 'lodash/orderBy';
var initialState = {
    isLoaded: false,
    items: [],
};
export var FETCH_REPORTABLE_USERS_REQUEST = 'timecrowd/reportable_users/FETCH_REPORTABLE_USERS_REQUEST';
export var FETCH_REPORTABLE_USERS_SUCCESS = 'timecrowd/reportable_users/FETCH_REPORTABLE_USERS_SUCCESS';
export var FETCH_REPORTABLE_USERS_FAILURE = 'timecrowd/reportable_users/FETCH_REPORTABLE_USERS_FAILURE';
export default function reportableUsers(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_REPORTABLE_USERS_REQUEST:
        case FETCH_REPORTABLE_USERS_FAILURE:
            return __assign(__assign({}, state), { isLoaded: false });
        case FETCH_REPORTABLE_USERS_SUCCESS:
            return __assign(__assign({}, state), { isLoaded: true, items: action.payload.users });
        default:
            return state;
    }
}
export var fetchReportableUsersRequest = function () { return ({
    type: FETCH_REPORTABLE_USERS_REQUEST,
}); };
export var fetchReportableUsersSuccess = function (users) { return ({
    type: FETCH_REPORTABLE_USERS_SUCCESS,
    payload: {
        users: users,
    },
}); };
export var fetchReportableUsersFailure = function () { return ({
    type: FETCH_REPORTABLE_USERS_FAILURE,
}); };
// Utility Functions
export var sortReportableUsers = function (users) {
    return orderBy(users, ['deactivated', 'member', 'id'], ['asc', 'desc', 'asc']);
};
