import { __assign, __spreadArray } from "tslib";
import api2, { getNextLink } from '../../api2';
export var FETCH_ASSETS_REQUEST = 'timecrowd/assets/FETCH_ASSETS_REQUEST';
export var FETCH_ASSETS_SUCCESS = 'timecrowd/assets/FETCH_ASSETS_SUCCESS';
export var FETCH_ASSETS_FAILURE = 'timecrowd/assets/FETCH_ASSETS_FAILURE';
export var EXPAND_ASSETS_REQUEST = 'timecrowd/assets/EXPAND_ASSETS_REQUEST';
export var EXPAND_ASSETS_SUCCESS = 'timecrowd/assets/EXPAND_ASSETS_SUCCESS';
export var EXPAND_ASSETS_FAILURE = 'timecrowd/assets/EXPAND_ASSETS_FAILURE';
export var CREATE_ASSET_REQUEST = 'timecrowd/assets/CREATE_ASSET_REQUEST';
export var CREATE_ASSET_SUCCESS = 'timecrowd/assets/CREATE_ASSET_SUCCESS';
export var CREATE_ASSET_FAILURE = 'timecrowd/assets/CREATE_ASSET_FAILURE';
export var FETCH_ASSET_REQUEST = 'timecrowd/assets/FETCH_ASSET_REQUEST';
export var FETCH_ASSET_SUCCESS = 'timecrowd/assets/FETCH_ASSET_SUCCESS';
export var FETCH_ASSET_FAILURE = 'timecrowd/assets/FETCH_ASSET_FAILURE';
export var DELETE_ASSET_REQUEST = 'timecrowd/assets/DELETE_ASSET_REQUEST';
export var DELETE_ASSET_SUCCESS = 'timecrowd/assets/DELETE_ASSET_SUCCESS';
export var DELETE_ASSET_FAILURE = 'timecrowd/assets/DELETE_ASSET_FAILURE';
export var CANCEL_CREATE_ASSET_REQUEST = 'timecrowd/assets/CANCEL_CREATE_ASSET_REQUEST';
export var CANCEL_CREATE_ASSET_SUCCESS = 'timecrowd/assets/CANCEL_CREATE_ASSET_SUCCESS';
export var CANCEL_CREATE_ASSET_FAILURE = 'timecrowd/assets/CANCEL_CREATE_ASSET_FAILURE';
export var initialState = {
    isLoading: false,
    items: [],
    next: null,
};
export function changeAsset(items, asset) {
    return items.map(function (item) {
        if (item.id === asset.id) {
            return asset;
        }
        else {
            return item;
        }
    });
}
export function removeAsset(items, id) {
    return items.filter(function (item) { return item.id !== id; });
}
export default function assets(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_ASSETS_REQUEST:
        case EXPAND_ASSETS_REQUEST:
        case CREATE_ASSET_REQUEST:
        case FETCH_ASSET_REQUEST:
        case DELETE_ASSET_REQUEST:
        case CANCEL_CREATE_ASSET_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_ASSETS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: action.assets, next: action.next });
        case EXPAND_ASSETS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: __spreadArray(__spreadArray([], state.items, true), action.assets, true), next: action.next });
        case FETCH_ASSETS_FAILURE:
        case EXPAND_ASSETS_FAILURE:
        case CREATE_ASSET_FAILURE:
        case FETCH_ASSET_FAILURE:
        case DELETE_ASSET_FAILURE:
        case CANCEL_CREATE_ASSET_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case CREATE_ASSET_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: __spreadArray([action.asset], state.items, true) });
        case FETCH_ASSET_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: changeAsset(state.items, action.asset) });
        case DELETE_ASSET_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: removeAsset(state.items, action.id) });
        case CANCEL_CREATE_ASSET_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: changeAsset(state.items, action.asset) });
        default:
            return state;
    }
}
export var fetchAssetsRequest = function () { return ({
    type: FETCH_ASSETS_REQUEST,
}); };
export var fetchAssetsSuccess = function (assets, next) { return ({
    type: FETCH_ASSETS_SUCCESS,
    assets: assets,
    next: next,
}); };
export var fetchAssetsFailure = function () { return ({
    type: FETCH_ASSETS_FAILURE,
}); };
export var expandAssetsRequest = function () { return ({
    type: EXPAND_ASSETS_REQUEST,
}); };
export var expandAssetsSuccess = function (assets, next) { return ({
    type: EXPAND_ASSETS_SUCCESS,
    assets: assets,
    next: next,
}); };
export var expandAssetsFailure = function () { return ({
    type: EXPAND_ASSETS_FAILURE,
}); };
export var createAssetRequest = function () { return ({
    type: CREATE_ASSET_REQUEST,
}); };
export var createAssetSuccess = function (asset) { return ({
    type: CREATE_ASSET_SUCCESS,
    asset: asset,
}); };
export var createAssetFailure = function () { return ({
    type: CREATE_ASSET_FAILURE,
}); };
export var fetchAssetRequest = function () { return ({
    type: FETCH_ASSET_REQUEST,
}); };
export var fetchAssetSuccess = function (asset) { return ({
    type: FETCH_ASSET_SUCCESS,
    asset: asset,
}); };
export var fetchAssetFailure = function () { return ({
    type: FETCH_ASSET_FAILURE,
}); };
export var deleteAssetRequest = function () { return ({
    type: DELETE_ASSET_REQUEST,
}); };
export var deleteAssetSuccess = function (id) { return ({
    type: DELETE_ASSET_SUCCESS,
    id: id,
}); };
export var deleteAssetFailure = function () { return ({
    type: DELETE_ASSET_FAILURE,
}); };
export var cancelCreateAssetRequest = function () { return ({
    type: CANCEL_CREATE_ASSET_REQUEST,
}); };
export var cancelCreateAssetSuccess = function (asset) { return ({
    type: CANCEL_CREATE_ASSET_SUCCESS,
    asset: asset,
}); };
export var cancelCreateAssetFailure = function () { return ({
    type: CANCEL_CREATE_ASSET_FAILURE,
}); };
export var fetchAssets = function () {
    return function (dispatch, getState) {
        dispatch(fetchAssetsRequest());
        return api2(dispatch, getState, "/api/v2/assets", 'GET').then(function (response) {
            if (response.ok) {
                var next_1 = getNextLink(response);
                return response.json().then(function (assets) {
                    dispatch(fetchAssetsSuccess(assets, next_1));
                    return assets;
                });
            }
            else {
                dispatch(fetchAssetsFailure());
                return { error: true };
            }
        });
    };
};
export var expandAssets = function (next) {
    return function (dispatch, getState) {
        dispatch(expandAssetsRequest());
        return api2(dispatch, getState, next, 'GET').then(function (response) {
            if (response.ok) {
                var next_2 = getNextLink(response);
                return response.json().then(function (assets) {
                    dispatch(expandAssetsSuccess(assets, next_2));
                    return assets;
                });
            }
            else {
                dispatch(expandAssetsFailure());
                return { error: true };
            }
        });
    };
};
export var createAsset = function (params) {
    return function (dispatch, getState) {
        dispatch(createAssetRequest());
        return api2(dispatch, getState, "/api/v2/assets", 'POST', params).then(function (response) {
            if (response.ok) {
                return response.json().then(function (asset) {
                    dispatch(createAssetSuccess(asset));
                    return asset;
                });
            }
            else {
                dispatch(createAssetFailure());
                return { error: true };
            }
        });
    };
};
export var fetchAsset = function (id) {
    return function (dispatch, getState) {
        dispatch(fetchAssetRequest());
        return api2(dispatch, getState, "/api/v2/assets/".concat(id), 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (asset) {
                    dispatch(fetchAssetSuccess(asset));
                    return asset;
                });
            }
            else {
                dispatch(fetchAssetFailure());
                return { error: true };
            }
        });
    };
};
export var deleteAsset = function (id) {
    return function (dispatch, getState) {
        dispatch(deleteAssetRequest());
        return api2(dispatch, getState, "/api/v2/assets/".concat(id), 'DELETE').then(function (response) {
            if (response.ok) {
                dispatch(deleteAssetSuccess(id));
                return id;
            }
            else {
                dispatch(deleteAssetFailure());
                return { error: true };
            }
        });
    };
};
export var cancelCreateAsset = function (id) {
    return function (dispatch, getState) {
        dispatch(cancelCreateAssetRequest());
        return api2(dispatch, getState, "/api/v2/assets/".concat(id, "/cancel"), 'PATCH').then(function (response) {
            if (response.ok) {
                return response.json().then(function (asset) {
                    dispatch(cancelCreateAssetSuccess(asset));
                    return asset;
                });
            }
            else {
                dispatch(cancelCreateAssetFailure());
                return { error: true };
            }
        });
    };
};
