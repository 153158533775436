import { padZero } from '@/utils/number_helper';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import fromPairs from 'lodash/fromPairs';
export var cellId = function (userId, cellName) {
    return "report-member-activity-cell-".concat(userId, "-").concat(cellName);
};
export var calcRatio = function (target, total) {
    var ratio = Math.floor((target / total) * 1000) / 10;
    if (isNaN(ratio) || !isFinite(ratio)) {
        return 0;
    }
    return ratio;
};
export var formatDuration = function (duration) {
    var hours = Math.floor(duration / 3600);
    var minutes = Math.floor((duration - hours * 3600) / 60);
    return "".concat(padZero(hours), ":").concat(padZero(minutes));
};
export var calcTotalDuration = function (entries) {
    return entries.reduce(function (total, timeEntry) { return total + timeEntry.duration; }, 0);
};
export var calcTotalAmount = function (entries) {
    return entries.reduce(function (total, timeEntry) { return total + timeEntry.amount; }, 0);
};
export var calcCategorySummary = function (category, tasks, userEntries) {
    var categoryTaskIds = tasks
        .filter(function (task) { return task.category_id === category.id; })
        .map(function (task) { return task.id; });
    var categoryEntries = userEntries.filter(function (timeEntry) {
        return categoryTaskIds.includes(timeEntry.time_trackable_id);
    });
    if (!categoryEntries.length) {
        return {
            visible: false,
            category: category,
            categoryDuration: 0,
            categoryAmount: 0,
        };
    }
    var categoryDuration = calcTotalDuration(categoryEntries);
    var categoryAmount = calcTotalAmount(categoryEntries);
    return {
        visible: true,
        category: category,
        categoryDuration: categoryDuration,
        categoryAmount: categoryAmount,
    };
};
export var calcUserSummary = function (categories, tasks, timeEntries, users, userId) {
    var user = users.find(function (user) { return user.id === userId; });
    var userEntries = timeEntries.filter(function (timeEntry) { return timeEntry.time_tracker_id === userId; });
    var userDuration = calcTotalDuration(userEntries);
    var userAmount = calcTotalAmount(userEntries);
    var categorySummaries = orderBy(categories.map(function (category) {
        return calcCategorySummary(category, tasks, userEntries);
    }), [function (summary) { return summary.categoryDuration; }], ['desc']);
    return {
        user: user,
        userDuration: userDuration,
        userAmount: userAmount,
        categorySummaries: categorySummaries,
    };
};
export var calcUserSummaries = function (categories, tasks, timeEntries, users) {
    var userIds = uniq(timeEntries.map(function (timeEntry) { return timeEntry.time_tracker_id; }));
    var userSummaries = fromPairs(userIds.map(function (userId) { return [
        userId,
        calcUserSummary(categories, tasks, timeEntries, users, userId),
    ]; }));
    var orderedUserIds = orderBy(Object.keys(userSummaries), [function (userId) { return userSummaries[userId].userDuration; }], ['desc']);
    return {
        userIds: orderedUserIds,
        userSummaries: userSummaries,
    };
};
