import React from 'react';
import { createPortal } from 'react-dom';
import { DndContext, DragOverlay, closestCenter, useSensor, useSensors, KeyboardSensor, MouseSensor, TouchSensor, } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { NestedCategoryListItem } from '@/components/molecules/nested_category_list_item';
import { DragListItemOverlay } from '@/components/sortable_category_list/drag_list_item_overlay';
import { buildPattern } from '@/utils/search_helper';
export var SortableCategoryList = function (_a) {
    var team = _a.team, originCategories = _a.originCategories, categories = _a.categories, draggedId = _a.draggedId, customFieldsMasters = _a.customFieldsMasters, customFieldsValues = _a.customFieldsValues, searchText = _a.searchText, categoryTaskTree = _a.categoryTaskTree, handleDragStart = _a.handleDragStart, handleDragEnd = _a.handleDragEnd, toggleRemoveModal = _a.toggleRemoveModal, handleArchiveCategory = _a.handleArchiveCategory, onMoveLowerCategoryPosition = _a.onMoveLowerCategoryPosition, onMoveHigherCategoryPosition = _a.onMoveHigherCategoryPosition, setNotificationMessage = _a.setNotificationMessage, fetchCustomFieldsValues = _a.fetchCustomFieldsValues, copyTeamCategory = _a.copyTeamCategory, createTeamCategory = _a.createTeamCategory, updateTeamCategory = _a.updateTeamCategory, fetchCategoryTaskTree = _a.fetchCategoryTaskTree;
    var activationConstraint = {
        distance: 10,
    };
    var sensors = useSensors(useSensor(KeyboardSensor), useSensor(MouseSensor, { activationConstraint: activationConstraint }), useSensor(TouchSensor, { activationConstraint: activationConstraint }));
    var items = originCategories.map(function (category) { return category.id.toString(); });
    var filteredCategories = React.useMemo(function () {
        if (!searchText) {
            return originCategories;
        }
        var pattern = buildPattern(searchText);
        return originCategories.filter(function (category) {
            var _a, _b;
            return pattern.test(category.title) ||
                pattern.test(((_a = category.descendant_titles) !== null && _a !== void 0 ? _a : []).join(' ')) ||
                pattern.test(((_b = category.ancestor_titles) !== null && _b !== void 0 ? _b : []).join(' '));
        });
    }, [searchText, originCategories]);
    return (<DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <>
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {filteredCategories.map(function (category) {
            return (<NestedCategoryListItem key={category.id} categories={categories} category={category} categoryTaskTree={categoryTaskTree} copyTeamCategory={copyTeamCategory} createTeamCategory={createTeamCategory} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues} draggedId={draggedId} fetchCategoryTaskTree={fetchCategoryTaskTree} fetchCustomFieldsValues={fetchCustomFieldsValues} handleArchiveCategory={handleArchiveCategory} handleDragEnd={handleDragEnd} handleDragStart={handleDragStart} isOverlay={false} searchText={searchText} setNotificationMessage={setNotificationMessage} team={team} toggleRemoveModal={toggleRemoveModal} updateTeamCategory={updateTeamCategory} onMoveHigherCategoryPosition={onMoveHigherCategoryPosition} onMoveLowerCategoryPosition={onMoveLowerCategoryPosition}/>);
        })}
        </SortableContext>
        {draggedId &&
            createPortal(<DragOverlay>
              <DragListItemOverlay categories={categories} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues} originCategories={originCategories} targetId={draggedId} team={team}/>
            </DragOverlay>, document.body)}
      </>
    </DndContext>);
};
