import { __assign } from "tslib";
import React from 'react';
import isEqual from 'lodash/isEqual';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import Avatar from '@/components/atoms/avatar';
import { ClearableSearchField } from '@/components/clearable_search_field';
export var MultiUsersSelectList = function (props) {
    var allUsers = props.allUsers, leavedUserIds = props.leavedUserIds, defaultSelectedUsers = props.selectedUsers, workedUserIdSet = props.workedUserIdSet, onApply = props.onApply;
    var _a = React.useState(''), searchText = _a[0], setSearchText = _a[1];
    var _b = React.useState(defaultSelectedUsers), selectedUsers = _b[0], setSelectedUsers = _b[1];
    React.useEffect(function () {
        setSelectedUsers(defaultSelectedUsers);
    }, [defaultSelectedUsers]);
    var handleSelect = function (user) {
        var _a;
        var isSelectedById = __assign(__assign({}, selectedUsers.reduce(function (acc, u) {
            acc[u.id] = true;
            return acc;
        }, {})), (_a = {}, _a[user.id] = !isCheckedByUserId[user.id], _a));
        var newSelectedUsers = allUsers.filter(function (u) { return isSelectedById[u.id]; });
        setSelectedUsers(newSelectedUsers);
    };
    var handleSelectAll = function () {
        setSelectedUsers(allUsers);
    };
    var handleDeselectAll = function () {
        setSelectedUsers([]);
    };
    var handleApply = function () {
        onApply(selectedUsers);
    };
    var _c = React.useMemo(function () {
        var _a;
        var arrangedUsers = allUsers
            .filter(function (user) { return user.nickname.includes(searchText); })
            .sort(function (a, b) {
            if (workedUserIdSet.has(a.id) && !workedUserIdSet.has(b.id)) {
                return -1;
            }
            if (!workedUserIdSet.has(a.id) && workedUserIdSet.has(b.id)) {
                return 1;
            }
            return 0;
        });
        var firstInactiveId = (_a = arrangedUsers.find(function (user) { return !workedUserIdSet.has(user.id); })) === null || _a === void 0 ? void 0 : _a.id;
        return [arrangedUsers, firstInactiveId];
    }, [allUsers, searchText, workedUserIdSet]), arrangedUsers = _c[0], firstInactiveId = _c[1];
    var isCheckedByUserId = selectedUsers.reduce(function (acc, user) {
        acc[user.id] = true;
        return acc;
    }, {});
    return (<>
      <ClearableSearchField className="mt-2 px-2" placeholderKey="searchByUserName" searchText={searchText} size="md" onChangeSearchText={setSearchText}/>
      <hr className="w-100 mb-0"/>
      <div className="overflow-y-auto">
        {arrangedUsers.map(function (user) { return (<div key={user.id} className={"d-flex align-items-center !tw-cursor-pointer px-2 py-1 my-1\n                  ".concat(workedUserIdSet.has(user.id) ? '' : 'text-muted', " \n                  ").concat(firstInactiveId === user.id ? 'border-top-1' : '')} onClick={function () { return handleSelect(user); }}>
            <CustomCheck checked={isCheckedByUserId[user.id]} className="mr-1" onClick={function (e) { return e.preventDefault(); }}/>
            <Avatar className="mr-1 border-0" image={user.avatar_url}/>
            <span className="text-overflow">{user.nickname}</span>
            <span className="ml-auto text-nowrap">
              {!workedUserIdSet.has(user.id) && (<span className={"\n                    small ".concat(leavedUserIds.includes(user.id) ? 'mr-1' : '')}>
                  {I18n.t('shared.inactive')}
                </span>)}
              {leavedUserIds.includes(user.id) && (<span className="badge badge-info ml-auto">
                  {I18n.t('shared.leaved')}
                </span>)}
            </span>
          </div>); })}
      </div>
      <hr className="w-100 mt-0"/>
      <div className="d-flex justify-content-between aling-items-center px-2 pb-2">
        <div className="d-flex align-items-center">
          <button className="btn btn-link p-0" onClick={handleSelectAll}>
            {I18n.t('shared.selectAll')}
          </button>
          <button className="btn btn-link p-0 ml-2" onClick={handleDeselectAll}>
            {I18n.t('shared.clear')}
          </button>
        </div>
        <button className="btn btn-primary" disabled={isEqual(selectedUsers, defaultSelectedUsers)} onClick={handleApply}>
          {I18n.t('shared.apply')}
        </button>
      </div>
    </>);
};
