import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ClassNames from 'classnames';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import Avatar from '@/components/atoms/avatar';
import { WorkspacesAccordion } from '@/components/workspaces_accordion';
var MainMenu = function (_a) {
    var user = _a.user, workingUsersCount = _a.workingUsersCount, isPC = _a.isPC, apiBase = _a.apiBase, openURL = _a.openURL, currentEntry = _a.currentEntry, isPlaceholder = _a.isPlaceholder, workspaces = _a.workspaces, teams = _a.teams;
    var location = useLocation();
    var onClickContact = function (e) {
        e.preventDefault();
        openURL(e.target.href);
    };
    var currentRoute = location.pathname;
    var invisible = isPlaceholder || !(user === null || user === void 0 ? void 0 : user.id);
    var reportPath = isPC ? '/app/report' : '/app/myreport';
    var allWorkingUsersCount = 0;
    for (var key in workingUsersCount) {
        allWorkingUsersCount += workingUsersCount[key];
    }
    return (<>
      <nav className="bg-inverse navbar navbar-dark overflow-y-auto scrollbar-thin scrollbar-dark hidden-sm-down">
        <ul className={"navbar-major-group navbar-nav ".concat(invisible ? 'invisible' : '')}>
          <li className="nav-item text-white small my-1">
            {I18n.t('shared.yourTime')}
          </li>
          <li className="nav-item">
            <Link className={ClassNames({
            home: true,
            'nav-link': true,
            active: currentRoute === '/app',
        })} to="/app">
              {currentEntry && (<span className="badge-status zoomIn animated badge-sm badge badge-danger">
                  <Icon name="start"/>
                </span>)}
              <Icon name="home"/>
              {I18n.t('mainmenu.mytask')}
            </Link>
          </li>
          <li className="nav-item">
            <Link className={ClassNames({
            reports: true,
            'nav-link': true,
            active: currentRoute.indexOf('/app/myreport') > -1,
        })} to="/app/myreport">
              <Icon name="graph-pie"/>
              {I18n.t('shared.myReport')}
            </Link>
          </li>
        </ul>
        {!invisible && (<WorkspacesAccordion currentRoute={currentRoute} teams={teams.items} user={user} workingUsersCount={workingUsersCount} workspaces={workspaces.items}/>)}
        <ul className={"hidden-sm-down mt-auto ".concat(invisible ? 'invisible' : '', " navbar-nav")}>
          <li className="navbar-minor-group p-1 navbar-nav mt-1"/>
          <li className="nav-item">
            <Link className={ClassNames({
            'nav-link': true,
            active: currentRoute.indexOf('/app/personal_settings') > -1,
        })} to="/app/personal_settings/profile">
              <Avatar className="mr-2 border-0" image={user.avatar_url} size="icon-md"/>
              {I18n.t('shared.personalSettings')}
            </Link>
          </li>
          {isPC && (<li className="nav-item" id="NavDataExport">
              <Link className={ClassNames({
                'nav-link': true,
                active: currentRoute.indexOf('/app/export') > -1,
            })} to="/app/export">
                <Icon name="download" size="md"/>
                {I18n.t('other.dataExport')}
              </Link>
            </li>)}
          <li className="nav-item">
            <span className="nav-link" onClick={function () { return openURL('https://support.timecrowd.net/'); }}>
              <Icon name="question" size="md"/>
              {I18n.t('shared.help')}
            </span>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={"".concat(apiBase, "/contacts/new")} onClick={onClickContact}>
              <Icon name="message" size="md"/>
              {I18n.t('shared.contactSupport')}
            </a>
          </li>
        </ul>
      </nav>

      {/* モバイルメニュー表示 */}
      <nav className="bg-inverse navbar navbar-dark hidden-md-up">
        <ul className={"navbar-major-group ".concat(invisible ? 'invisible' : '', " navbar-nav")}>
          <li className="nav-item">
            <Link className={ClassNames({
            home: true,
            'nav-link': true,
            active: currentRoute === '/app',
        })} to="/app">
              {currentEntry && (<span className="badge-status zoomIn animated badge-sm badge badge-danger">
                  <Icon name="start"/>
                </span>)}
              <Icon name="home"/>
              {I18n.t('mainmenu.mytask')}
            </Link>
          </li>
          <li className="members nav-item">
            <Link className={ClassNames({
            'nav-link': true,
            active: currentRoute.indexOf('/app/member') > -1,
        })} to="/app/member">
              <Icon name="user"/>
              {allWorkingUsersCount > 0 && (<span className={ClassNames({
                'badge badge-pill badge-default': true,
            })}>
                  {allWorkingUsersCount}
                </span>)}
              {I18n.t('mainmenu.members')}
            </Link>
          </li>
          <li className="nav-item">
            <Link className={ClassNames({
            reports: true,
            'nav-link': true,
            active: currentRoute.indexOf(reportPath) > -1,
        })} to={reportPath}>
              <Icon name="graph-pie"/>
              {I18n.t('mainmenu.report')}
            </Link>
          </li>
          <li className="hidden-md-up nav-item">
            <Link className={ClassNames({
            'nav-link': true,
            active: currentRoute === '/app/other',
        })} to="/app/other">
              <Icon name="dots-3"/>
              {I18n.t('mainmenu.more')}
            </Link>
          </li>
        </ul>
      </nav>
    </>);
};
export default MainMenu;
