import { resetReport } from '@/modules/report';
import { resetMyTask } from '@/modules/my_task';
import { resetCalendar } from '@/modules/calendar';
import { resetDailyActivities } from '@/modules/daily_activites';
import { resetLastTimeEntries } from '@/modules/last_time_entries';
import { resetTasks } from '@/modules/task_management';
import { resetMatchedTasks } from '@/modules/task_search';
import { setCategories } from '@/modules/categories';
import { resetTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
import { resetRecentTasks } from '@/modules/recent_tasks';
import { resetWorkspacesEmploys } from '@/modules/workspaces_employs';
import { resetWorkspacesInvitations } from '@/modules/workspaces_invitations';
import { resetWorkspacesMemberships } from '@/modules/workspaces_memberships';
import { resetPayment } from '@/modules/payment';
import { setMemberSummaries } from '@/actions/setter';
import { resetRecentCategories } from '@/modules/recent_categories';
import { resetWorkspacesRealtimeReport } from '@/modules/workspaces_realtime_reports';
import { resetWorkspacesReportBookmarks } from '@/modules/workspaces_report_bookmarks';
export var resetWorkspaceResources = function () {
    return function (dispatch) {
        [
            resetReport(),
            resetMyTask(),
            resetCalendar(),
            resetDailyActivities(),
            resetLastTimeEntries(),
            resetTasks(),
            resetMatchedTasks(),
            setCategories([]),
            setMemberSummaries([]),
            resetTimeEntrySuggestion(),
            resetRecentTasks(),
            resetWorkspacesEmploys(),
            resetWorkspacesInvitations(),
            resetWorkspacesMemberships(),
            resetPayment(),
            resetRecentCategories(),
            resetWorkspacesRealtimeReport(),
            resetWorkspacesReportBookmarks(),
        ].forEach(dispatch);
    };
};
