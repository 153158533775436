import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import api2 from '@/api2';
export var FETCH_CALENDAR_INTEGRATION_FILTERS_REQUEST = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/FETCH_CALENDAR_INTEGRATION_FILTERS_REQUEST';
export var FETCH_CALENDAR_INTEGRATION_FILTERS_SUCCESS = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/FETCH_CALENDAR_INTEGRATION_FILTERS_SUCCESS';
export var FETCH_CALENDAR_INTEGRATION_FILTERS_FAILURE = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/FETCH_CALENDAR_INTEGRATION_FILTERS_FAILURE';
export var CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST';
export var CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS';
export var CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE';
export var UPDATE_CALENDAR_INTEGRATION_FILTER_REQUEST = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/UPDATE_CALENDAR_INTEGRATION_FILTER_REQUEST';
export var UPDATE_CALENDAR_INTEGRATION_FILTER_SUCCESS = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/UPDATE_CALENDAR_INTEGRATION_FILTER_SUCCESS';
export var UPDATE_CALENDAR_INTEGRATION_FILTER_FAILURE = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/UPDATE_CALENDAR_INTEGRATION_FILTER_FAILURE';
export var DELETE_CALENDAR_INTEGRATION_FILTER_REQUEST = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/DELETE_CALENDAR_INTEGRATION_FILTER_REQUEST';
export var DELETE_CALENDAR_INTEGRATION_FILTER_SUCCESS = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/DELETE_CALENDAR_INTEGRATION_FILTER_SUCCESS';
export var DELETE_CALENDAR_INTEGRATION_FILTER_FAILURE = 'timecrowd/CALENDAR_INTEGRATION_FILTERS/DELETE_CALENDAR_INTEGRATION_FILTER_FAILURE';
var initialState = {
    isLoading: false,
    items: [],
};
export default function calendarIntegrationFilters(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CALENDAR_INTEGRATION_FILTERS_REQUEST:
        case CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST:
        case UPDATE_CALENDAR_INTEGRATION_FILTER_REQUEST:
        case DELETE_CALENDAR_INTEGRATION_FILTER_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_CALENDAR_INTEGRATION_FILTERS_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, isLoading: false });
        case FETCH_CALENDAR_INTEGRATION_FILTERS_FAILURE:
        case CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE:
        case UPDATE_CALENDAR_INTEGRATION_FILTER_FAILURE:
        case DELETE_CALENDAR_INTEGRATION_FILTER_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS:
            return __assign(__assign({}, state), { items: __spreadArray(__spreadArray([], state.items, true), [action.payload], false), isLoading: false });
        case UPDATE_CALENDAR_INTEGRATION_FILTER_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.map(function (item) {
                    return item.id === action.payload.id ? action.payload : item;
                }), isLoading: false });
        case DELETE_CALENDAR_INTEGRATION_FILTER_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.filter(function (item) { return item.id !== action.payload.id; }), isLoading: false });
        default:
            return state;
    }
}
export var fetchCalendarIntegrationFiltersRequest = function () { return ({
    type: FETCH_CALENDAR_INTEGRATION_FILTERS_REQUEST,
}); };
export var fetchCalendarIntegrationFiltersSuccess = function (items) { return ({
    type: FETCH_CALENDAR_INTEGRATION_FILTERS_SUCCESS,
    payload: items,
}); };
export var fetchCalendarIntegrationFiltersFailure = function () { return ({
    type: FETCH_CALENDAR_INTEGRATION_FILTERS_FAILURE,
}); };
export var fetchCalendarIntegrationFilters = function () {
    return function (dispatch, getState) {
        dispatch(fetchCalendarIntegrationFiltersRequest());
        return api2(dispatch, getState, '/api/v2/calendar_integration/filters', 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchCalendarIntegrationFiltersSuccess(items));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchCalendarIntegrationFiltersFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var createCalendarIntegrationFilterRequest = function () { return ({
    type: CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST,
}); };
export var createCalendarIntegrationFilterSuccess = function (item) { return ({
    type: CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS,
    payload: item,
}); };
export var createCalendarIntegrationFilterFailure = function () { return ({
    type: CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE,
}); };
export var createCalendarIntegrationFilter = function (params) {
    return function (dispatch, getState) {
        dispatch(createCalendarIntegrationFilterRequest());
        return api2(dispatch, getState, '/api/v2/calendar_integration/filters', 'POST', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(createCalendarIntegrationFilterSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(createCalendarIntegrationFilterFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateCalendarIntegrationFilterRequest = function () { return ({
    type: UPDATE_CALENDAR_INTEGRATION_FILTER_REQUEST,
}); };
export var updateCalendarIntegrationFilterSuccess = function (item) { return ({
    type: UPDATE_CALENDAR_INTEGRATION_FILTER_SUCCESS,
    payload: item,
}); };
export var updateCalendarIntegrationFilterFailure = function () { return ({
    type: UPDATE_CALENDAR_INTEGRATION_FILTER_FAILURE,
}); };
export var updateCalendarIntegrationFilter = function (id, params) {
    return function (dispatch, getState) {
        dispatch(updateCalendarIntegrationFilterRequest());
        return api2(dispatch, getState, "/api/v2/calendar_integration/filters/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(updateCalendarIntegrationFilterSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(updateCalendarIntegrationFilterFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var deleteCalendarIntegrationFilterRequest = function () { return ({
    type: DELETE_CALENDAR_INTEGRATION_FILTER_REQUEST,
}); };
export var deleteCalendarIntegrationFilterSuccess = function (item) { return ({
    type: DELETE_CALENDAR_INTEGRATION_FILTER_SUCCESS,
    payload: item,
}); };
export var deleteCalendarIntegrationFilterFailure = function () { return ({
    type: DELETE_CALENDAR_INTEGRATION_FILTER_FAILURE,
}); };
export var deleteCalendarIntegrationFilter = function (id) {
    return function (dispatch, getState) {
        dispatch(deleteCalendarIntegrationFilterRequest());
        return api2(dispatch, getState, "/api/v2/calendar_integration/filters/".concat(id), 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(deleteCalendarIntegrationFilterSuccess({ id: id }));
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(deleteCalendarIntegrationFilterFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
