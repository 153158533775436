import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '@/constants/i18n';
import { fieldTypes } from '@/types';
export var CustomFieldsTypeField = function (_a) {
    var customFieldsMaster = _a.customFieldsMaster, onChangeCustomFieldsMaster = _a.onChangeCustomFieldsMaster, className = _a.className;
    return (<div className={className}>
      {I18n.t('shared.customFieldsType')}
      <div className="mt-2">
        <UncontrolledDropdown>
          <DropdownToggle caret>
            {I18n.t("shared.customFieldsTypes.".concat(customFieldsMaster.field_type))}
          </DropdownToggle>
          <DropdownMenu>
            {fieldTypes.map(function (type) {
            return (<DropdownItem key={type} value={type} onClick={function () { return onChangeCustomFieldsMaster('field_type', type); }}>
                  {I18n.t("shared.customFieldsTypes.".concat(type))}
                </DropdownItem>);
        })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>);
};
