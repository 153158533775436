import React from 'react';
import I18n from '@/constants/i18n';
function PersonalBadge(_a) {
    var isPersonal = _a.isPersonal, _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.style, style = _c === void 0 ? {} : _c;
    if (!isPersonal) {
        return null;
    }
    return (<span className={"badge badge-info ".concat(className)} style={style}>
      {I18n.t('shared.personal')}
    </span>);
}
export default PersonalBadge;
