import { __awaiter, __generator, __spreadArray } from "tslib";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkspacesSettingsLayout } from '@/components/workspaces_settings_layout';
import I18n from '@/constants/i18n';
import { InviteWorkspaceModal } from '@/components/invite_workspace_modal';
import MembersInfo from '@/components/members/members_info';
import InvitationInfo from '@/components/members/invitation_info';
import { WorkspacesMemberListItem } from '@/components/workspaces_member_list_item';
import { workspacePath } from '@/utils/workspace_helper';
import { MemberRateHelp } from '@/member_rate_help';
export var WorkspacesMembersForm = function (props) {
    var workspace = props.workspace, workspacesInvitation = props.workspacesInvitation, workspacesMemberships = props.workspacesMemberships, workspacesEmploys = props.workspacesEmploys, teams = props.teams, employsByTeamId = props.employsByTeamId, membershipsByTeamId = props.membershipsByTeamId, renewWorkspacesInvitation = props.renewWorkspacesInvitation, createWorkspacesEmploy = props.createWorkspacesEmploy, updateWorkspacesEmploy = props.updateWorkspacesEmploy, deleteWorkspacesEmploy = props.deleteWorkspacesEmploy, updateWorkspacesMembership = props.updateWorkspacesMembership, deleteWorkspacesMembership = props.deleteWorkspacesMembership, toggleTeamMenu = props.toggleTeamMenu, onRemoveDangerNotificationMessage = props.onRemoveDangerNotificationMessage;
    var navigate = useNavigate();
    var _a = React.useState(false), renewed = _a[0], setRenewed = _a[1];
    var _b = React.useState(false), isOpenInvitationModal = _b[0], setIsOpenInvitationModal = _b[1];
    var onRenew = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, renewWorkspacesInvitation(workspacesInvitation.id)];
                case 1:
                    _a.sent();
                    setRenewed(true);
                    setTimeout(function () { return setRenewed(false); }, 2000);
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickInvite = function () {
        setIsOpenInvitationModal(true);
    };
    var handleCloseModal = function () {
        onRemoveDangerNotificationMessage();
        setIsOpenInvitationModal(false);
    };
    var employsByUserId = workspacesEmploys.reduce(function (acc, employ) {
        var _a;
        acc[employ.user_id] = __spreadArray(__spreadArray([], ((_a = acc[employ.user_id]) !== null && _a !== void 0 ? _a : []), true), [employ], false);
        return acc;
    }, {});
    var canInvite = workspace.plan_type !== 'personal';
    return (<WorkspacesSettingsLayout active="members" saveButtonForPC={canInvite && (<button className="btn-major-action btn btn-primary" onClick={onClickInvite}>
            {I18n.t('shared.inviteMembers')}
          </button>)} saveButtonForSP={canInvite && (<button className="btn-mobile-header-action btn btn-link" onClick={onClickInvite}>
            {I18n.t('shared.invite')}
          </button>)} title={"".concat(I18n.t('shared.workspace'), " ").concat(I18n.t('shared.memberManagement'))}>
      <div className="mb-2 d-flex">
        <MembersInfo memberships={workspacesMemberships}/>
        {workspace.plan_type === 'legacy_light' && (<>
            {workspace.capacity && <span className="mx-1">/</span>}
            <InvitationInfo canChange capacity={workspace.capacity} count={workspacesMemberships.length} paymentPath={workspacePath("/plan")} onClick={function (path) { return navigate(path); }}/>
          </>)}
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <td>{I18n.t('shared.username')}</td>
            <td>
              {I18n.t('shared.rate')}
              <MemberRateHelp messageKey="rateHelpForWorkspace"/>
            </td>
            <td>{I18n.t('shared.workspaceRole')}</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {workspacesMemberships.map(function (membership) {
            var _a;
            return (<WorkspacesMemberListItem key={membership.id} createWorkspacesEmploy={createWorkspacesEmploy} deleteWorkspacesEmploy={deleteWorkspacesEmploy} deleteWorkspacesMembership={deleteWorkspacesMembership} employsByTeamId={employsByTeamId} membership={membership} membershipsByTeamId={membershipsByTeamId} teams={teams} toggleTeamMenu={toggleTeamMenu} updateWorkspacesEmploy={updateWorkspacesEmploy} updateWorkspacesMembership={updateWorkspacesMembership} workspacesEmploys={(_a = employsByUserId[membership.user_id]) !== null && _a !== void 0 ? _a : []} workspacesMemberships={workspacesMemberships} onRemoveDangerNotificationMessage={onRemoveDangerNotificationMessage}/>);
        })}
        </tbody>
      </table>

      <InviteWorkspaceModal isOpen={isOpenInvitationModal} renewed={renewed} workspaceId={workspace.id} workspacesInvitation={workspacesInvitation} onClose={handleCloseModal} onRenew={onRenew}/>
    </WorkspacesSettingsLayout>);
};
