import React from 'react';
import { useSelector } from 'react-redux';
import { CrossTeamReportLongTermProgress } from '@/components/cross_team_report_long_term_progress';
export var CrossTeamReportLongTermProgressContainer = function (props) {
    var workspace = props.workspace;
    var isProgress = useSelector(function (state) {
        return state.workspacesLongTermReports.items.some(function (report) {
            return report.workspaces_workspace_id === workspace.id &&
                ['pending', 'processing'].includes(report.status);
        });
    });
    var isCompleted = useSelector(function (state) {
        return state.workspacesLongTermReports.completedItems.some(function (report) {
            return report.workspaces_workspace_id === workspace.id;
        });
    });
    return (<CrossTeamReportLongTermProgress isCompleted={!isProgress && isCompleted} isProgress={isProgress}/>);
};
