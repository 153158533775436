import React from 'react';
import I18n from '@/constants/i18n';
import Member from './member';
import { ClearableSearchField } from '@/components/clearable_search_field';
import { buildPattern } from '@/utils/search_helper';
import { MemberRateHelp } from '@/member_rate_help';
export var MemberListTable = function (_a) {
    var team = _a.team, user = _a.user, memberships = _a.memberships;
    var _b = React.useState(''), searchText = _b[0], setSearchText = _b[1];
    var filteredMemberships = React.useMemo(function () {
        if (!searchText) {
            return memberships;
        }
        var pattern = buildPattern(searchText);
        return memberships.filter(function (membership) {
            return pattern.test(membership.user.nickname);
        });
    }, [searchText, memberships]);
    if (!team) {
        return null;
    }
    return (<div className="row no-gutters">
      <div className="col-12">
        <ClearableSearchField className="mb-2" placeholderKey="memberListSearchPlaceholder" searchText={searchText} size="md" onChangeSearchText={setSearchText}/>
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th>{I18n.t('shared.user')}</th>
              {(team === null || team === void 0 ? void 0 : team.can_manage_employ) && (<th>
                  {I18n.t('teamManagement.memberTab.rate')}
                  <MemberRateHelp messageKey="rateHelpForTeam"/>
                </th>)}
              {(team === null || team === void 0 ? void 0 : team.can_manage_employ) && <th>{I18n.t('shared.teamRole')}</th>}
            </tr>
          </thead>
          <tbody>
            {filteredMemberships.map(function (membership) {
            return (<Member key={"team-".concat(team === null || team === void 0 ? void 0 : team.id, "-membership-").concat(membership.id)} membership={membership} team={team} user={user}/>);
        })}
          </tbody>
        </table>
      </div>
    </div>);
};
