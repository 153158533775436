import { __awaiter, __generator } from "tslib";
import React from 'react';
import { saveAs } from 'file-saver';
import MainMenuContainer from '@/containers/main_menu_container';
import { CrossTeamReportHeader } from '@/components/cross_team_report_header';
import { CrossTeamReportSummary } from '@/components/cross_team_report_summary';
import { filterTimeEntries, filterWorkedTasks, calcCategoryAndTaskTotals, } from '@/components/report/helpers';
import { TeamReportBarChart } from '@/components/report_chart/team_report_bar_chart';
import { TeamReportDetails } from '@/components/report/team_report_details';
import { buildDefaultPeriod } from '@/components/cross_team_report_header/helpers';
import { CrossTeamReportBookmarkListContainer } from '@/containers/cross_team_report_bookmark_list_container';
import ExportFormatModalContainer from '@/containers/export_format_modal_container';
import I18n from '@/constants/i18n';
import { generateCSV } from './helpers';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import { CrossTeamReportLongTermLoadingContainer } from '@/containers/cross_team_report_long_term_loading_container';
import { ReportMemberActivities } from '@/components/organisms/report_member_activities';
import { TeamReportTasks } from '@/components/report/team_report_tasks';
export var CrossTeamReport = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var bookmarks = props.bookmarks, isLoadingLongTerm = props.isLoadingLongTerm, isLoadingRealtime = props.isLoadingRealtime, report = props.report, sortBy = props.sortBy, sortOrder = props.sortOrder, selectedCategories = props.selectedCategories, selectedTeams = props.selectedTeams, selectedUsers = props.selectedUsers, onFetchLongTermReport = props.onFetchLongTermReport, onFetchRealtimeReport = props.onFetchRealtimeReport, onGenerateLongTermReport = props.onGenerateLongTermReport, onSetReportSortBy = props.onSetReportSortBy, onSetReportSortOrder = props.onSetReportSortOrder, onSelectCategories = props.onSelectCategories, onSelectTeams = props.onSelectTeams, onSelectUsers = props.onSelectUsers;
    var _g = React.useState(null), selectedBookmarkId = _g[0], setSelectedBookmarkId = _g[1];
    var _h = React.useState(false), isOpenCSV = _h[0], setIsOpenCSV = _h[1];
    var _j = React.useState(false), withArchived = _j[0], setWithArchived = _j[1];
    var isLoading = isLoadingRealtime || isLoadingLongTerm;
    var _k = React.useMemo(function () {
        if (!report) {
            return {
                timeEntries: [],
                workedTeamIdSet: new Set(),
                workedUserIdSet: new Set(),
                workedCategoryIdSet: new Set(),
            };
        }
        return filterTimeEntries(report.time_entries, report.categories, report.tasks, selectedCategories.map(function (_a) {
            var id = _a.id;
            return id;
        }), selectedTeams.map(function (_a) {
            var id = _a.id;
            return id;
        }), selectedUsers.map(function (_a) {
            var id = _a.id;
            return id;
        }), withArchived, !selectedUsers.length);
    }, [
        report,
        selectedCategories,
        selectedTeams,
        selectedUsers,
        withArchived,
    ]), timeEntries = _k.timeEntries, workedTeamIdSet = _k.workedTeamIdSet, workedUserIdSet = _k.workedUserIdSet, workedCategoryIdSet = _k.workedCategoryIdSet;
    var handleOpenCSV = React.useCallback(function () {
        setIsOpenCSV(true);
    }, []);
    var handleCloseCSV = React.useCallback(function () {
        setIsOpenCSV(false);
    }, []);
    var handleSubmitCSV = React.useCallback(function (dataType, timeFormat, charset) {
        var _a, _b, _c, _d;
        var blob = generateCSV(timeEntries, (_a = report === null || report === void 0 ? void 0 : report.tasks) !== null && _a !== void 0 ? _a : [], (_b = report === null || report === void 0 ? void 0 : report.teams) !== null && _b !== void 0 ? _b : [], (_c = report === null || report === void 0 ? void 0 : report.categories) !== null && _c !== void 0 ? _c : [], (_d = report === null || report === void 0 ? void 0 : report.users) !== null && _d !== void 0 ? _d : [], dataType, timeFormat, charset);
        saveAs(blob, 'report.csv');
        setIsOpenCSV(false);
    }, [
        timeEntries,
        report === null || report === void 0 ? void 0 : report.tasks,
        report === null || report === void 0 ? void 0 : report.teams,
        report === null || report === void 0 ? void 0 : report.categories,
        report === null || report === void 0 ? void 0 : report.users,
    ]);
    var tasks = React.useMemo(function () { var _a; return filterWorkedTasks((_a = report === null || report === void 0 ? void 0 : report.tasks) !== null && _a !== void 0 ? _a : [], timeEntries); }, [report === null || report === void 0 ? void 0 : report.tasks, timeEntries]);
    var _l = React.useMemo(function () {
        return calcCategoryAndTaskTotals(timeEntries, tasks);
    }, [timeEntries, tasks]), totalDuration = _l.totalDuration, totalAmount = _l.totalAmount, categoryTotals = _l.categoryTotals, taskTotals = _l.taskTotals, teamTotals = _l.teamTotals;
    var defaultStartMonth = (_a = report === null || report === void 0 ? void 0 : report.start_month) !== null && _a !== void 0 ? _a : 1;
    var handleSelectBookmark = React.useCallback(function (bookmark) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (bookmark.long_term_report_id) {
                onFetchLongTermReport(bookmark.long_term_report_id);
            }
            else {
                onFetchRealtimeReport(bookmark.conditions);
            }
            onSelectTeams(bookmark.conditions.selected_teams);
            onSelectUsers(bookmark.conditions.selected_users);
            onSelectCategories(bookmark.conditions.selected_categories);
            setSelectedBookmarkId(bookmark.id);
            return [2 /*return*/];
        });
    }); }, [
        onFetchRealtimeReport,
        onFetchLongTermReport,
        onSelectCategories,
        onSelectTeams,
        onSelectUsers,
    ]);
    var handleRegenerate = React.useCallback(function () {
        var params = {
            period_type: report.period_type,
            from: report.from,
            to: report.to,
            start_month: report.start_month,
        };
        if (report.term === 'realtime') {
            onFetchRealtimeReport(params);
        }
        else {
            onGenerateLongTermReport(params);
        }
    }, [onFetchRealtimeReport, onGenerateLongTermReport, report]);
    var selectedBookmark = React.useMemo(function () {
        if (!selectedBookmarkId) {
            return null;
        }
        return bookmarks.find(function (_a) {
            var id = _a.id;
            return id === selectedBookmarkId;
        }) || null;
    }, [bookmarks, selectedBookmarkId]);
    // NOTE: 期間が変更されたらブックマークの選択を解除する
    React.useEffect(function () {
        if (isLoading) {
            return;
        }
        if ((selectedBookmark === null || selectedBookmark === void 0 ? void 0 : selectedBookmark.conditions.from) !== (report === null || report === void 0 ? void 0 : report.from) ||
            (selectedBookmark === null || selectedBookmark === void 0 ? void 0 : selectedBookmark.conditions.to) !== (report === null || report === void 0 ? void 0 : report.to)) {
            setSelectedBookmarkId(null);
        }
    }, [isLoading, selectedBookmark, report === null || report === void 0 ? void 0 : report.from, report === null || report === void 0 ? void 0 : report.to]);
    return (<div className="content-wrap">
      <MainMenuContainer />
      <div className="content-main pt-44">
        <CrossTeamReportHeader allCategories={(_b = report === null || report === void 0 ? void 0 : report.categories) !== null && _b !== void 0 ? _b : []} allTeams={(_c = report === null || report === void 0 ? void 0 : report.teams) !== null && _c !== void 0 ? _c : []} allUsers={(_d = report === null || report === void 0 ? void 0 : report.users) !== null && _d !== void 0 ? _d : []} defaultPeriod={report !== null && report !== void 0 ? report : buildDefaultPeriod(defaultStartMonth)} defaultStartMonth={defaultStartMonth} memberships={(_e = report === null || report === void 0 ? void 0 : report.memberships) !== null && _e !== void 0 ? _e : []} selectedCategories={selectedCategories} selectedTeams={selectedTeams} selectedUsers={selectedUsers} withArchived={withArchived} workedCategoryIdSet={workedCategoryIdSet} workedTeamIdSet={workedTeamIdSet} workedUserIdSet={workedUserIdSet} workspacesMemberships={(_f = report === null || report === void 0 ? void 0 : report.workspaces_memberships) !== null && _f !== void 0 ? _f : []} onChangeWithArchived={setWithArchived} onOpenCSV={handleOpenCSV} onSelectCategories={onSelectCategories} onSelectTeams={onSelectTeams} onSelectUsers={onSelectUsers}/>
        <div className="row no-gutters min-h-full">
          <div className="col-12 col-md-9">
            {!isLoading && report && (<>
                <CrossTeamReportSummary categoryTotals={categoryTotals} report={report} selectedBookmark={selectedBookmark} teams={selectedTeams.length ? selectedTeams : report.teams} teamTotals={teamTotals} totalAmount={totalAmount} totalDuration={totalDuration} onRegenerate={handleRegenerate}/>
                <TeamReportBarChart categories={report.categories} from={report.from} isSelectedMyReport={false} tasks={tasks} teams={report.teams} timeEntries={timeEntries} to={report.to} withoutNoDataPeriods={report.period_type === 'allPeriods'}/>
                <TeamReportDetails showsTooltip categories={report.categories} categoryTotals={categoryTotals} tasks={tasks} taskTotals={taskTotals} teams={report.teams} teamTotals={teamTotals} timeEntries={timeEntries} users={report.users}/>
                <hr className="my-4"/>
                <ReportMemberActivities showsTooltip categories={report.categories} defaultVisibleRows={5} tasks={tasks} timeEntries={timeEntries} users={report.users} onSelectUser={function (user) { return onSelectUsers([user]); }}/>
                <hr className="my-4"/>
                <TeamReportTasks categories={report.categories} currentSortBy={sortBy} currentSortOrder={sortOrder} setOrder={onSetReportSortOrder} setTarget={onSetReportSortBy} tasks={tasks} taskTotals={taskTotals}/>
              </>)}
            {isLoading && (<div className="h-75 d-flex flex-column align-items-center justify-content-center">
                <div className="text-center">
                  <LoadingSpinner size="lg"/>
                  <h2 className="opacity-80 mt-3">
                    {isLoadingRealtime &&
                I18n.t('shared.crossTeamReportLoadingTitleForRealtime')}
                    {isLoadingLongTerm &&
                I18n.t('shared.crossTeamReportLoadingTitleForLongTerm')}
                  </h2>
                  <p className="text-secondary mt-1">
                    {I18n.t('shared.crossTeamReportLoadingDescription')}
                  </p>
                </div>
              </div>)}
            <CrossTeamReportLongTermLoadingContainer />
          </div>
          <div className="border-left-1 col-3 hidden-sm-down">
            <CrossTeamReportBookmarkListContainer onSelect={handleSelectBookmark}/>
          </div>
        </div>
      </div>
      <ExportFormatModalContainer actionLabel={I18n.t('report.download')} isOpen={isOpenCSV} titleLabel={I18n.t('report.download')} toggle={handleCloseCSV} onSubmit={handleSubmitCSV}/>
    </div>);
};
