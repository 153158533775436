import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
export var RESEND_CONFIRMATION_REQUEST = 'timecrowd/EMAIL_CONFIRMATIONS/RESEND_CONFIRMATION_REQUEST';
export var RESEND_CONFIRMATION_SUCCESS = 'timecrowd/EMAIL_CONFIRMATIONS/RESEND_CONFIRMATION_SUCCESS';
export var RESEND_CONFIRMATION_FAILURE = 'timecrowd/EMAIL_CONFIRMATIONS/RESEND_CONFIRMATION_FAILURE';
export var CANCEL_CONFIRMATION_REQUEST = 'timecrowd/EMAIL_CONFIRMATIONS/CANCEL_CONFIRMATION_REQUEST';
export var CANCEL_CONFIRMATION_SUCCESS = 'timecrowd/EMAIL_CONFIRMATIONS/CANCEL_CONFIRMATION_SUCCESS';
export var CANCEL_CONFIRMATION_FAILURE = 'timecrowd/EMAIL_CONFIRMATIONS/CANCEL_CONFIRMATION_FAILURE';
export var initialState = {
    isLoading: false,
};
export default function emailConfirmations(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case RESEND_CONFIRMATION_REQUEST:
        case CANCEL_CONFIRMATION_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case RESEND_CONFIRMATION_FAILURE:
        case CANCEL_CONFIRMATION_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case RESEND_CONFIRMATION_SUCCESS:
        case CANCEL_CONFIRMATION_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
export var resendEmailConfirmationRequest = function () { return ({
    type: RESEND_CONFIRMATION_REQUEST,
}); };
export var resendEmailConfirmationSuccess = function () { return ({
    type: RESEND_CONFIRMATION_SUCCESS,
}); };
export var resendEmailConfirmationFailure = function () { return ({
    type: RESEND_CONFIRMATION_FAILURE,
}); };
export var resendEmailConfirmation = function () {
    return function (dispatch, getState) {
        dispatch(resendEmailConfirmationRequest());
        return api2(dispatch, getState, '/api/v2/users/email_confirmation', 'POST', {}).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(resendEmailConfirmationSuccess());
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(resendEmailConfirmationFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
export var cancelEmailConfirmationRequest = function () { return ({
    type: CANCEL_CONFIRMATION_REQUEST,
}); };
export var cancelEmailConfirmationSuccess = function () { return ({
    type: CANCEL_CONFIRMATION_SUCCESS,
}); };
export var cancelEmailConfirmationFailure = function () { return ({
    type: CANCEL_CONFIRMATION_FAILURE,
}); };
export var cancelEmailConfirmation = function () {
    return function (dispatch, getState) {
        dispatch(cancelEmailConfirmationRequest());
        return api2(dispatch, getState, "/api/v2/users/email_confirmation", 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(cancelEmailConfirmationSuccess());
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(cancelEmailConfirmationFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
