import React from 'react';
import Alert from '@/components/alert';
import I18n from '@/constants/i18n';
export var UndoAlert = function (props) {
    var autoClose = props.autoClose, messageType = props.messageType, message = props.message, onUndo = props.onUndo, onCancel = props.onCancel;
    var handleUndo = function () {
        onUndo();
    };
    var handleClose = function () {
        onCancel();
    };
    return (<Alert isHtml isOpen withoutCloseButton autoClose={autoClose} color={messageType} message="" position="bottom" onClose={handleClose}>
      <div className="d-flex w-100 align-items-center">
        <div className="mr-2" dangerouslySetInnerHTML={{ __html: message }}/>
        <button className={"btn btn-link ml-auto font-weight-bold text-".concat(messageType)} type="button" onClick={handleUndo}>
          {I18n.t('shared.undo')}
        </button>
      </div>
    </Alert>);
};
