import { __assign } from "tslib";
import React, { useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip, } from 'reactstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import I18n from '@/constants/i18n';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import Icon from '@/components/atoms/icon';
import OmitLabel from '@/components/atoms/omit_label';
import ExportFormatModalContainer from '@/containers/export_format_modal_container';
import { CategoryInfoContainer } from '@/containers/category_info_container';
import { workspacePath } from '@/utils/workspace_helper';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
export default function Task(_a) {
    var task = _a.task, toggle = _a.toggle, onBack = _a.onBack, onOpenDeleteModal = _a.onOpenDeleteModal, openURL = _a.openURL;
    var _b = useState(false), isOpenCSV = _b[0], setIsOpenCSV = _b[1];
    var onToggleCSV = function () {
        setIsOpenCSV(!isOpenCSV);
    };
    var onToggleForm = React.useCallback(function () {
        if (task.category.archived) {
            return;
        }
        toggle();
    }, [task, toggle]);
    var csvURL = function (timeFormat, charset) {
        var query = queryString.stringify({
            'team_ids[]': [task.team.id],
            type: 'unlimited',
            time_format: timeFormat,
            charset: charset,
            task_id: task.id,
        });
        return "/report/time_entries.csv?".concat(query);
    };
    var tooltipId = "task-edit-task-".concat(task.id, "-tooltip");
    var archived = task.category.archived;
    return (<div className="d-flex">
      <div className="mr-auto task-edit-header">
        <div className={"mb-1 cursor-".concat(archived ? 'not-allowed' : 'pointer')} onClick={onToggleForm}>
          <OmitLabel maxWidth={'100%'}>
            <span className="font-weight-bold">{task.title}</span>
          </OmitLabel>
        </div>
        <div className="d-flex align-items-center">
          <div className={"mr-3 d-flex align-items-center cursor-".concat(archived ? 'not-allowed' : 'pointer')} onClick={onToggleForm}>
            <WorkspaceAvatarContainer className="mr-1" workspaceId={task.team.workspaces_workspace_id}/>
            <OmitLabel maxWidth="18rem">
              <CategoryCircleIcon color={task.category.color}/>
              <span className="mr-3">
                {task.category.title} - {task.team.name}
              </span>
            </OmitLabel>
            <CategoryInfoContainer category={__assign(__assign({}, task.category), { team_id: task.team.id })}/>
            {archived && <Icon className="ml-1" name="archive"/>}
          </div>
          {task.url && (<a className="btn btn-link text-secondary d-inline-block mr-3" href={task.url} onClick={function (e) {
                e.preventDefault();
                openURL(task.url);
            }}>
              <OmitLabel maxWidth="18rem">
                <Icon className="mr-1" name="link"/>
                <span>{task.url}</span>
              </OmitLabel>
            </a>)}
          <button className="btn btn-link text-secondary btn-sm d-flex mr-1" disabled={archived} onClick={onToggleForm}>
            <span id={tooltipId}>
              <Icon className="mr-1" name="pencil" size="sm"/>
              {I18n.t('shared.edit')}
            </span>
          </button>
          {archived && (<UncontrolledTooltip fade={false} target={tooltipId}>
              {I18n.t('shared.archivedTaskCannotBeEdited')}
            </UncontrolledTooltip>)}
          <UncontrolledDropdown className="d-inline-block" size="sm">
            <DropdownToggle className="btn btn-link text-secondary">
              <Icon name="dots-3" size="md"/>
            </DropdownToggle>
            <DropdownMenu right>
              {task.team.can_manage && (<Link className="dropdown-item" to={workspacePath("/teams/".concat(task.team.id, "/categories"))}>
                  {I18n.t('shared.categoryManagement')}
                </Link>)}
              <DropdownItem onClick={onToggleCSV}>
                {I18n.t('report.download_csv')}
              </DropdownItem>
              {!archived && (<DropdownItem onClick={onOpenDeleteModal}>
                  <span className="text-danger">{I18n.t('shared.delete')}</span>
                </DropdownItem>)}
            </DropdownMenu>
          </UncontrolledDropdown>
          <ExportFormatModalContainer actionLabel={I18n.t('report.download')} disableDataType={true} isOpen={isOpenCSV} titleLabel={I18n.t('report.download')} toggle={onToggleCSV} onSubmit={function (_dataType, timeFormat, charset) {
            openURL(csvURL(timeFormat, charset));
            onToggleCSV();
        }}/>
        </div>
      </div>
      <div>
        <button className="btn btn-close" onClick={onBack}>
          <Icon name="cross" size="xl"/>
        </button>
      </div>
    </div>);
}
