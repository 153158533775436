import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { ActivityRow } from './row';
import { calcUserSummaries, calcTotalAmount, calcTotalDuration, } from './helpers';
export var ReportMemberActivities = function (_a) {
    var timeEntries = _a.timeEntries, tasks = _a.tasks, categories = _a.categories, users = _a.users, showsTooltip = _a.showsTooltip, defaultVisibleRows = _a.defaultVisibleRows, onSelectUser = _a.onSelectUser;
    var _b = React.useState(false), isExpanded = _b[0], setIsExpanded = _b[1];
    var _c = calcUserSummaries(categories, tasks, timeEntries, users), userIds = _c.userIds, userSummaries = _c.userSummaries;
    var visibleUserIds = isExpanded
        ? userIds
        : userIds.slice(0, defaultVisibleRows);
    return (<div className="report-padding p-[0.8rem]">
      <h3 className="h3 mb-3 should-remove-ensure-compatibility-design">
        <Icon className="mr-1" color="info" name="to-do"/>
        {I18n.t('shared.member')}
      </h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="text-nowrap">{I18n.t('shared.user')}</th>
              <th className="text-nowrap" colSpan={3}>
                {I18n.t('shared.duration')}
              </th>
              <th className="text-nowrap" colSpan={3}>
                {I18n.t('shared.amount')}
              </th>
            </tr>
          </thead>
          <tbody>
            {visibleUserIds.map(function (userId) { return (<ActivityRow key={userId} showsTooltip={showsTooltip} summary={userSummaries[userId]} totalAmount={calcTotalAmount(timeEntries)} totalDuration={calcTotalDuration(timeEntries)} userId={userId} onSelectUser={onSelectUser}/>); })}
          </tbody>
        </table>
      </div>
      {userIds.length > defaultVisibleRows && (<button className="btn btn-link" type="button" onClick={function () { return setIsExpanded(!isExpanded); }}>
          {isExpanded
                ? I18n.t('shared.close')
                : I18n.t('shared.showMoreItems', {
                    count: userIds.length - defaultVisibleRows,
                })}
        </button>)}
    </div>);
};
