import React from 'react';
import I18n from '@/constants/i18n';
import Alert from '@/components/alert';
export var SystemNotificationAlert = function (_a) {
    var notifications = _a.notifications, onInit = _a.onInit, onConfirm = _a.onConfirm, openURL = _a.openURL;
    var notification = notifications[notifications.length - 1];
    React.useEffect(function () {
        onInit();
    }, [onInit]);
    var onCloseAlert = React.useCallback(function () {
        onConfirm(notification.id);
    }, [notification, onConfirm]);
    var onClickAction = React.useCallback(function () {
        openURL(notification.detail_link);
    }, [notification, openURL]);
    if (!notification) {
        return <div />;
    }
    return (<Alert isHtml isOpen color="warning" message={notification.message} position="bottom" onClose={onCloseAlert}>
      {(notification === null || notification === void 0 ? void 0 : notification.detail_link) && (<div className="mt-2">
          <button className="btn btn-secondary" type="button" onClick={onClickAction}>
            {I18n.t('shared.details')}
          </button>
        </div>)}
    </Alert>);
};
