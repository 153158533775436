import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import Avatar from '@/components/atoms/avatar';
import { removeDangerNotificationMessage } from '@/modules/notification';
export var CategoryTaskStructuresImportModal = function (_a) {
    var file = _a.file, team = _a.team, onImport = _a.onImport, onClear = _a.onClear;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = React.useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var dispatch = useDispatch();
    var toggle = React.useCallback(function () {
        dispatch(removeDangerNotificationMessage());
        setIsOpen(!isOpen);
    }, [dispatch, isOpen]);
    var onClickImport = React.useCallback(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, onImport()];
                    case 1:
                        _a.sent();
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [onImport]);
    return (<>
      <div className="text-center">
        <p className="text-break px-2">
          <Icon color="secondary" name="paperclip"/>
          <span className="ml-1">{file.name}</span>
          <button className="btn btn-sm ml-2 bg-secondary font-weight-normal" onClick={onClear}>
            {I18n.t('shared.cancel')}
          </button>
        </p>
        <p>
          <button className="btn btn-primary" onClick={toggle}>
            {I18n.t('shared.importCSVFile')}
          </button>
        </p>
      </div>

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeaderMod toggle={toggle}>
          {I18n.t('shared.importCSVFile')}
        </ModalHeaderMod>

        <ModalBody>
          <p>
            {I18n.t('shared.importIntoTeam')}
            <br />
            <span className="text-danger">
              {I18n.t('shared.cannotBeStopped')}
            </span>
          </p>
          <div>
            <Avatar className="mr-1" image={team.avatar_url} size="sm"/>
            {team.name}
          </div>
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-secondary" disabled={isLoading} onClick={toggle}>
            {I18n.t('shared.cancel')}
          </button>
          <button className="btn btn-primary" disabled={isLoading} onClick={onClickImport}>
            {I18n.t('shared.import')}
          </button>
        </ModalFooter>
      </Modal>
    </>);
};
