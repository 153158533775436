import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
export var CommentField = function (_a) {
    var commentRef = _a.commentRef, _b = _a.defaultValue, defaultValue = _b === void 0 ? '' : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.rows, rows = _d === void 0 ? 1 : _d, onChange = _a.onChange;
    var _e = React.useState(defaultValue), value = _e[0], setValue = _e[1];
    var handleChangeComment = function (e) {
        setValue(e.target.value);
        onChange(e.target.value);
    };
    return (<div className="d-flex align-items-baseline">
      <div>
        <Icon className="align-baseline" name="message"/>
      </div>
      <div className="ml-2 flex-grow-1">
        <textarea ref={commentRef} className="form-control" disabled={disabled} placeholder={I18n.t('shared.enterComments')} rows={rows} value={value} onChange={handleChangeComment}></textarea>
      </div>
    </div>);
};
