import React from 'react';
import classNames from 'classnames';
import Icon from '@/components/atoms/icon';
var Subpane = function (props) {
    var className = props.className, _a = props.tag, Tag = _a === void 0 ? 'div' : _a, children = props.children, _b = props.isOpen, isOpen = _b === void 0 ? false : _b, toggle = props.toggle, _c = props.style, style = _c === void 0 ? {} : _c;
    var onToggle = function () {
        toggle === null || toggle === void 0 ? void 0 : toggle();
    };
    var classes = classNames('subpane animated', className, {
        open: isOpen,
        hide: !isOpen,
    });
    return (<Tag className={classes} style={style}>
      <div className="d-flex py-1 hidden-md-up">
        <button className="btn btn-link text-secondary ml-auto" onClick={onToggle}>
          <Icon name="cross" size="lg"/>
        </button>
      </div>
      <div className="subpane-body">{children}</div>
    </Tag>);
};
export default Subpane;
