import { __assign, __awaiter, __generator } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import { PersonalSettingsLayout, } from '@/components/personal_settings_layout';
import { NewForm } from './new_form';
import { EditForm } from './edit_form';
export var CalendarIntegrationForm = function (_a) {
    var isApp = _a.isApp, isChromeExtension = _a.isChromeExtension, setIsOpenSubpane = _a.setIsOpenSubpane, user = _a.user, isOpenSubpane = _a.isOpenSubpane, isPC = _a.isPC, isCalendarIntegrationListsIsLoading = _a.isCalendarIntegrationListsIsLoading, propsCalendarIntegrationSetting = _a.calendarIntegrationSetting, propsCalendarIntegrationLists = _a.calendarIntegrationLists, propsCalendarIntegrationFilters = _a.calendarIntegrationFilters, identities = _a.identities, categories = _a.categories, isUnrecoverable = _a.isUnrecoverable, openURL = _a.openURL, onDeleteIntegration = _a.onDeleteIntegration, updateCalendarIntegrationSetting = _a.updateCalendarIntegrationSetting, fetchCalendarIntegrationFilters = _a.fetchCalendarIntegrationFilters, createCalendarIntegrationFilter = _a.createCalendarIntegrationFilter, updateCalendarIntegrationFilter = _a.updateCalendarIntegrationFilter, deleteCalendarIntegrationFilter = _a.deleteCalendarIntegrationFilter, updateCalendarIntegrationList = _a.updateCalendarIntegrationList, setNotificationMessage = _a.setNotificationMessage, signOut = _a.signOut;
    var _b = React.useState(false), isSaving = _b[0], setIsSaving = _b[1];
    var _c = React.useState(false), isSaved = _c[0], setIsSaved = _c[1];
    var saveLabel = isSaving ? 'saving' : isSaved ? 'saved' : 'save';
    var _d = React.useState(propsCalendarIntegrationSetting), calendarIntegrationSetting = _d[0], setCalendarIntegrationSetting = _d[1];
    var _e = React.useState(propsCalendarIntegrationLists), calendarIntegrationLists = _e[0], setCalendarIntegrationLists = _e[1];
    var _f = React.useState(propsCalendarIntegrationFilters), calendarIntegrationFilters = _f[0], setCalendarIntegrationFilters = _f[1];
    var isIntegrated = !!calendarIntegrationSetting;
    var hasActiveCalendarIntegrationList = calendarIntegrationLists.some(function (_a) {
        var is_active = _a.is_active;
        return is_active;
    });
    var invalidFilters = calendarIntegrationFilters.filter(function (_a) {
        var category_id = _a.category_id, is_category_soft_destroyed = _a.is_category_soft_destroyed, is_category_archived = _a.is_category_archived;
        return !category_id || is_category_soft_destroyed || is_category_archived;
    });
    var isValidFilters = (calendarIntegrationSetting === null || calendarIntegrationSetting === void 0 ? void 0 : calendarIntegrationSetting.base_type) !== 'personal' ||
        invalidFilters.length === 0;
    var isValidWorkspace = (calendarIntegrationSetting === null || calendarIntegrationSetting === void 0 ? void 0 : calendarIntegrationSetting.base_type) !== 'workspace' ||
        (calendarIntegrationSetting === null || calendarIntegrationSetting === void 0 ? void 0 : calendarIntegrationSetting.workspaces_workspace_id);
    var isValid = hasActiveCalendarIntegrationList && isValidFilters && isValidWorkspace;
    var errorMessages = [];
    if (isIntegrated &&
        !isCalendarIntegrationListsIsLoading &&
        !hasActiveCalendarIntegrationList) {
        errorMessages.push(I18n.t('shared.pleaseSelectList'));
    }
    if (!isValidFilters) {
        errorMessages.push(I18n.t('shared.pleaseSelectCategoryOfCalendarIntegrationFilterHtml', {
            label: I18n.t('shared.filtering'),
            count: invalidFilters.length,
        }));
    }
    if (isIntegrated && !isValidWorkspace) {
        errorMessages.push(I18n.t('shared.pleaseSelectCalendarIntegrationSettingWorkspace'));
    }
    React.useEffect(function () {
        setCalendarIntegrationSetting(propsCalendarIntegrationSetting);
    }, [propsCalendarIntegrationSetting]);
    React.useEffect(function () {
        setCalendarIntegrationLists(propsCalendarIntegrationLists);
    }, [propsCalendarIntegrationLists]);
    React.useEffect(function () {
        setCalendarIntegrationFilters(propsCalendarIntegrationFilters);
    }, [propsCalendarIntegrationFilters]);
    var onSave = React.useCallback(function () {
        setIsSaving(true);
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var filterIds_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, updateCalendarIntegrationList((_a = calendarIntegrationLists.find(function (_a) {
                            var is_active = _a.is_active;
                            return is_active;
                        })) === null || _a === void 0 ? void 0 : _a.id, { is_active: true })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, updateCalendarIntegrationSetting(calendarIntegrationSetting)];
                    case 2:
                        _b.sent();
                        if (!((calendarIntegrationSetting === null || calendarIntegrationSetting === void 0 ? void 0 : calendarIntegrationSetting.base_type) === 'personal')) return [3 /*break*/, 5];
                        filterIds_1 = calendarIntegrationFilters.map(function (_a) {
                            var id = _a.id;
                            return id;
                        });
                        return [4 /*yield*/, Promise.all(propsCalendarIntegrationFilters.map(function (filter) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!!filterIds_1.includes(filter.id)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, deleteCalendarIntegrationFilter(filter.id)];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, Promise.all(calendarIntegrationFilters.map(function (filter) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!filter.id) return [3 /*break*/, 2];
                                            return [4 /*yield*/, updateCalendarIntegrationFilter(filter.id, filter)];
                                        case 1:
                                            _a.sent();
                                            return [3 /*break*/, 4];
                                        case 2: return [4 /*yield*/, createCalendarIntegrationFilter(filter)];
                                        case 3:
                                            _a.sent();
                                            _a.label = 4;
                                        case 4: return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 4:
                        _b.sent();
                        fetchCalendarIntegrationFilters();
                        _b.label = 5;
                    case 5:
                        setIsSaving(false);
                        setIsSaved(true);
                        setTimeout(function () {
                            setNotificationMessage('success', I18n.t('shared.updateCalendarIntegrationSettingSuccess'));
                            setIsSaved(false);
                        }, 2000);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [
        calendarIntegrationFilters,
        calendarIntegrationLists,
        calendarIntegrationSetting,
        createCalendarIntegrationFilter,
        deleteCalendarIntegrationFilter,
        fetchCalendarIntegrationFilters,
        propsCalendarIntegrationFilters,
        setNotificationMessage,
        updateCalendarIntegrationFilter,
        updateCalendarIntegrationList,
        updateCalendarIntegrationSetting,
    ]);
    var activateDefaultList = React.useCallback(function () {
        var list = calendarIntegrationLists[0];
        setCalendarIntegrationLists([__assign(__assign({}, list), { is_active: true })]);
        setIsSaving(true);
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, updateCalendarIntegrationList(list.id, { is_active: true })];
                    case 1:
                        _a.sent();
                        setIsSaving(false);
                        setIsSaved(true);
                        setTimeout(function () {
                            setIsSaved(false);
                        }, 2000);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [calendarIntegrationLists, updateCalendarIntegrationList]);
    var canIntegrate = !isApp && !isChromeExtension;
    return (<PersonalSettingsLayout active="calendar" errorMessages={errorMessages} isOpenSubpane={isOpenSubpane} isPC={isPC} saveButtonForPC={isIntegrated && (<button className="btn-major-action btn btn-primary" disabled={isSaving || isSaved || !isValid} onClick={onSave}>
            {I18n.t("shared.".concat(saveLabel))}
          </button>)} saveButtonForSP={isIntegrated && (<button className="btn-mobile-header-action btn btn-link" disabled={isSaving || isSaved || !isValid} onClick={onSave}>
            {I18n.t("shared.".concat(saveLabel))}
          </button>)} setIsOpenSubpane={setIsOpenSubpane} signOut={signOut} title={I18n.t('shared.calendarIntegrations')} user={user}>
      {isIntegrated ? (<EditForm activateDefaultList={activateDefaultList} calendarIntegrationFilters={calendarIntegrationFilters} calendarIntegrationLists={calendarIntegrationLists} calendarIntegrationSetting={calendarIntegrationSetting} canIntegrate={canIntegrate} categories={categories} identities={identities} isCalendarIntegrationListsIsLoading={isCalendarIntegrationListsIsLoading} isUnrecoverable={isUnrecoverable} openURL={openURL} setCalendarIntegrationFilters={setCalendarIntegrationFilters} setCalendarIntegrationLists={setCalendarIntegrationLists} setCalendarIntegrationSetting={setCalendarIntegrationSetting} setNotificationMessage={setNotificationMessage} user={user} onDeleteIntegration={onDeleteIntegration}/>) : (<NewForm canIntegrate={canIntegrate} openURL={openURL} setNotificationMessage={setNotificationMessage} user={user}/>)}
    </PersonalSettingsLayout>);
};
