import { __assign } from "tslib";
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import { CustomFieldsVisibilityField } from './custom_fields_visibility_field';
import { CustomFieldsTypeField } from './custom_fields_type_field';
import { CustomFieldsNameField } from './custom_fields_name_field';
import { CustomFieldsValidationField } from './custom_fields_validation_field';
import { CustomFieldsUnitField } from './custom_fields_unit_field';
import { CustomFieldsExpressionField } from './custom_fields_expression_field';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var CustomFieldsMasterFormModal = function (_a) {
    var defaultCustomFieldsMaster = _a.customFieldsMaster, customFieldsMasters = _a.customFieldsMasters, isOpen = _a.isOpen, isSaving = _a.isSaving, _b = _a.duplicatedCategories, duplicatedCategories = _b === void 0 ? [] : _b, onCancel = _a.onCancel, onSave = _a.onSave, _c = _a.onNavigateToCategories, onNavigateToCategories = _c === void 0 ? function () { } : _c;
    var _d = React.useState(defaultCustomFieldsMaster), customFieldsMaster = _d[0], setCustomFieldsMaster = _d[1];
    var onChangeCustomFieldsMaster = React.useCallback(function (attribute, value) {
        var _a;
        setCustomFieldsMaster(__assign(__assign({}, customFieldsMaster), (_a = {}, _a[attribute] = value, _a)));
    }, [customFieldsMaster]);
    var handleCancel = React.useCallback(function () {
        setCustomFieldsMaster(defaultCustomFieldsMaster);
        onCancel();
    }, [defaultCustomFieldsMaster, onCancel]);
    return (<Modal isOpen={isOpen} toggle={handleCancel}>
      <ModalHeaderMod toggle={handleCancel}>
        {I18n.t('shared.customFieldDetails')}
      </ModalHeaderMod>
      <ModalBody>
        <CustomFieldsNameField className="mt-3" customFieldsMaster={customFieldsMaster} onChangeCustomFieldsMaster={onChangeCustomFieldsMaster}/>
        <CustomFieldsTypeField className="mt-3" customFieldsMaster={customFieldsMaster} onChangeCustomFieldsMaster={onChangeCustomFieldsMaster}/>
        <CustomFieldsValidationField className="mt-3" customFieldsMaster={customFieldsMaster} onChangeCustomFieldsMaster={onChangeCustomFieldsMaster}/>
        {duplicatedCategories.length > 0 && (<div className="mt-2">
            <div className="text-danger">
              {I18n.t('shared.followingCategoriesDuplicated')}
              <ul className="pl-3 mt-1">
                {duplicatedCategories.map(function (c) { return (<li key={c}>{c}</li>); })}
              </ul>
            </div>
            <button className="btn btn-link p-0 mt-2" onClick={onNavigateToCategories}>
              {I18n.t('shared.goToCategorySettings')}
            </button>
          </div>)}
        <CustomFieldsExpressionField className="mt-3" customFieldsMaster={customFieldsMaster} customFieldsMasters={customFieldsMasters} onChangeCustomFieldsMaster={onChangeCustomFieldsMaster}/>
        <CustomFieldsUnitField className="mt-3" customFieldsMaster={customFieldsMaster} onChangeCustomFieldsMaster={onChangeCustomFieldsMaster}/>
        <CustomFieldsVisibilityField className="mt-3" customFieldsMaster={customFieldsMaster} onChangeCustomFieldsMaster={onChangeCustomFieldsMaster}/>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <button className="btn btn-secondary" disabled={isSaving} onClick={handleCancel}>
          {I18n.t('shared.cancel')}
        </button>

        <button className="btn btn-primary" disabled={isSaving} onClick={function () { return onSave(customFieldsMaster); }}>
          {I18n.t('shared.save')}
        </button>
      </ModalFooter>
    </Modal>);
};
