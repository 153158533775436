import { __assign, __awaiter, __generator } from "tslib";
import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { WorkspacesSettingsLayout } from '@/components/workspaces_settings_layout';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import variants from '@/constants/variants';
import Icon from '@/components/atoms/icon';
import Helplink from '@/components/molecules/helplink';
import { DeleteWorkspaceModal } from '@/components/delete_workspace_modal';
import { TeamOwnersInviteAllowedField } from './team_owners_invite_allowed_field';
export var WorkspacesEditForm = function (props) {
    var defaultWorkspace = props.workspace, _a = props.isPC, isPC = _a === void 0 ? variants.isPC : _a, _b = props.openURL, openURL = _b === void 0 ? variants.openURL : _b, teams = props.teams, updateWorkspace = props.updateWorkspace, deleteWorkspace = props.deleteWorkspace, setCurrentWorkspace = props.setCurrentWorkspace, setNotificationMessage = props.setNotificationMessage, payment = props.payment;
    var _c = React.useState(defaultWorkspace), workspace = _c[0], setWorkspace = _c[1];
    var _d = React.useState({
        avatar: '',
        avatar_url: workspace.avatar_url,
        avatar_filename: '',
        avatar_content_type: '',
    }), avatarData = _d[0], setAvatarData = _d[1];
    var _e = React.useState(false), saving = _e[0], setSaving = _e[1];
    var _f = React.useState(false), saved = _f[0], setSaved = _f[1];
    var avatarRef = React.useRef(null);
    var saveLabel = saving ? 'saving' : saved ? 'saved' : 'save';
    React.useEffect(function () {
        setWorkspace(defaultWorkspace);
        setAvatarData({
            avatar: '',
            avatar_url: defaultWorkspace.avatar_url,
            avatar_filename: '',
            avatar_content_type: '',
        });
    }, [defaultWorkspace]);
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSaving(true);
                    return [4 /*yield*/, updateWorkspace(workspace.id, __assign(__assign(__assign({}, workspace), avatarData), { avatar_url: '' }))];
                case 1:
                    res = _a.sent();
                    setSaving(false);
                    if (!!res.error) return [3 /*break*/, 3];
                    setCurrentWorkspace(res.item);
                    setSaved(true);
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 2000); })];
                case 2:
                    _a.sent();
                    setSaved(false);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onChangeAvatar = function (e) {
        var file = e.target.files[0];
        if (file !== void 0) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                if (file.type.match(/^image\/(png|jpeg|gif)$/)) {
                    setAvatarData({
                        // @ts-ignore
                        avatar: reader_1.result.split(',')[1],
                        avatar_url: reader_1.result,
                        avatar_filename: file.name,
                        avatar_content_type: file.type,
                    });
                }
            };
            reader_1.readAsDataURL(file);
        }
    };
    var onClickLink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    var onChangeWorkspace = function (attribute, value) {
        var _a;
        setWorkspace(__assign(__assign({}, workspace), (_a = {}, _a[attribute] = value, _a)));
    };
    return (<WorkspacesSettingsLayout active="edit" saveButtonForPC={<button className="btn-major-action btn btn-primary" disabled={saving || saved} onClick={onSave}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>} saveButtonForSP={<button className="btn-mobile-header-action btn btn-link" disabled={saving || saved} onClick={onSave}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>} title={"".concat(I18n.t('shared.workspace'), " ").concat(I18n.t('shared.generalSettings'))}>
      <div className="form-group mb-3 row">
        <label className="col-12 col-md-3 col-form-label">
          {I18n.t('shared.icon')}
        </label>
        <div className="col-12 col-md-9 d-flex">
          <Avatar className="mr-2" image={avatarData.avatar_url} size="lg"/>
          {isPC && (<>
              <div>
                <input ref={avatarRef} accept="image/gif, image/jpeg, image/png" className="hidden-file-input" id="avatarFileInput" type="file" onChange={onChangeAvatar}/>
                <button className="btn btn-secondary" onClick={function () {
                var _a;
                (_a = avatarRef.current) === null || _a === void 0 ? void 0 : _a.click();
            }}>
                  <Icon className="mr-1" name="camera"/>
                  {I18n.t('shared.change')}
                </button>
              </div>
              <p className="text-muted mt-1 ml-1">
                {I18n.t('shared.avatarHelp')}
              </p>
            </>)}
        </div>
      </div>

      <div className="form-group mb-3 row">
        <label className="col-12 col-md-3 col-form-label">
          {I18n.t('shared.workspaceName')}
        </label>
        <div className="col-12 col-md-7">
          <input className="mb-2 form-control" value={workspace.name} onChange={function (e) { return onChangeWorkspace('name', e.target.value); }}/>
          <span className="text-muted small mb-2 font-weight-medium">
            {I18n.t('shared.workspaceId')} {workspace.id}
          </span>
        </div>
      </div>

      <div className="form-group mb-3 row">
        <label className="col-12 col-md-3 col-form-label">
          {I18n.t('shared.rounding')}
          <Helplink color="secondary" href="https://support.timecrowd.net/1428ec2d2afa804993dcc7fe5bb1f0b9" onClick={onClickLink}/>
        </label>
        <div className="col-12 col-md-3">
          <UncontrolledDropdown>
            <DropdownToggle block caret>
              {I18n.t("shared.roundings.".concat(workspace.rounding))}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={function () { return onChangeWorkspace('rounding', 'floor'); }}>
                {I18n.t("shared.roundings.floor")}
              </DropdownItem>
              <DropdownItem onClick={function () { return onChangeWorkspace('rounding', 'round'); }}>
                {I18n.t("shared.roundings.round")}
              </DropdownItem>
              <DropdownItem onClick={function () { return onChangeWorkspace('rounding', 'ceil'); }}>
                {I18n.t("shared.roundings.ceil")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>

      <TeamOwnersInviteAllowedField setWorkspace={setWorkspace} workspace={workspace}/>

      {['legacy_light', 'personal'].includes(workspace.plan_type) && (<DeleteWorkspaceModal deleteWorkspace={deleteWorkspace} payment={payment} setCurrentWorkspace={setCurrentWorkspace} setNotificationMessage={setNotificationMessage} teams={teams} workspace={defaultWorkspace}/>)}
    </WorkspacesSettingsLayout>);
};
