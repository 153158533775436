import React from 'react';
import classNames from 'classnames';
import I18n from '@/constants/i18n';
import { sortReportableUsers } from '@/modules/reportable_users';
import Avatar from '@/components/atoms/avatar';
import { UserStatusBadge } from './user_status_badge';
import CustomCheck from '@/components/atoms/custom_check';
export var SubpaneUserList = function (_a) {
    var onToggle = _a.onToggle, onSelectUser = _a.onSelectUser, onSelectAllUsers = _a.onSelectAllUsers, onDeselectAllUsers = _a.onDeselectAllUsers, reportableUsers = _a.reportableUsers, selectedUsers = _a.selectedUsers, isNotWorkedUsersOpened = _a.isNotWorkedUsersOpened, isLoading = _a.isLoading, withAllUsers = _a.withAllUsers, workedMemberIds = _a.workedMemberIds;
    var sortedUsers = sortReportableUsers(reportableUsers);
    var workedUsers = sortedUsers.filter(function (u) { return workedMemberIds.includes(u.id); });
    var notWorkedUsers = sortedUsers.filter(function (u) { return !workedMemberIds.includes(u.id); });
    var UserListItem = function (_a) {
        var user = _a.user, isDisabled = _a.isDisabled;
        return (<div key={user.id} className="list-group-item">
        <CustomCheck checked={withAllUsers || selectedUsers.some(function (_a) {
                var id = _a.id;
                return id === user.id;
            })} disabled={isDisabled} onChange={function () { return onSelectUser(user); }}/>
        <Avatar className="mr-2" image={user.image}/>
        <span className="list-group-contents">{user.nickname}</span>
        <UserStatusBadge user={user}/>
      </div>);
    };
    var ShowNotWorkedUsers = function () {
        return (<a className="list-group-item" href="#" onClick={function (e) {
                e.preventDefault();
                onToggle(!isNotWorkedUsersOpened);
            }}>
        <span className={classNames('mr-2', isNotWorkedUsersOpened ? 'tc-chevron-up' : 'tc-chevron-down')}/>
        <span className="list-group-contents">
          {isNotWorkedUsersOpened
                ? I18n.t('report.hideInactiveMembers')
                : I18n.t('report.showInactiveMembers')}
        </span>
      </a>);
    };
    return (<>
      {reportableUsers.length > 1 && (<div className="px-2 mt-1">
          <button className="p-0 btn btn-link" disabled={isLoading} onClick={function () { return onSelectAllUsers(); }}>
            {I18n.t('shared.selectAll')}
          </button>
          <button className="p-0 ml-2 btn btn-link" disabled={isLoading} onClick={function () { return onDeselectAllUsers(); }}>
            {I18n.t('shared.clear')}
          </button>
        </div>)}
      {workedUsers.map(function (user) { return (<UserListItem key={user.id} isDisabled={isLoading} user={user}/>); })}
      {notWorkedUsers.length > 0 && (<>
          <ShowNotWorkedUsers key="subPanelUsersShowNotWorkedUsersToggle"/>
          {isNotWorkedUsersOpened &&
                notWorkedUsers.map(function (user) { return (<UserListItem key={user.id} isDisabled={isLoading} user={user}/>); })}
        </>)}
    </>);
};
