import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import api2, { getNextLink } from '@/api2';
export var FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_REQUEST = 'timecrowd/CATEGORY_TASK_STRUCTURES_EXPORTS/FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_REQUEST';
export var FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_SUCCESS = 'timecrowd/CATEGORY_TASK_STRUCTURES_EXPORTS/FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_SUCCESS';
export var FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_FAILURE = 'timecrowd/CATEGORY_TASK_STRUCTURES_EXPORTS/FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_FAILURE';
export var CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST = 'timecrowd/CATEGORY_TASK_STRUCTURES_EXPORTS/CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST';
export var CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS = 'timecrowd/CATEGORY_TASK_STRUCTURES_EXPORTS/CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS';
export var CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE = 'timecrowd/CATEGORY_TASK_STRUCTURES_EXPORTS/CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE';
var initialState = {
    isLoading: false,
    items: [],
    page: 0,
    hasNext: false,
};
export default function categoryTaskStructuresExports(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_REQUEST:
        case CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_SUCCESS:
            return __assign(__assign(__assign({}, state), action.payload), { items: __spreadArray(__spreadArray([], (action.payload.page === 1 ? [] : state.items), true), action.payload.items, true), isLoading: false });
        case FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_FAILURE:
        case CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS:
            return __assign(__assign({}, state), { items: __spreadArray(__spreadArray([], state.items, true), [action.payload], false), isLoading: false });
        default:
            return state;
    }
}
export var fetchCategoryTaskStructuresExportsRequest = function () { return ({
    type: FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_REQUEST,
}); };
export var fetchCategoryTaskStructuresExportsSuccess = function (payload) { return ({
    type: FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_SUCCESS,
    payload: payload,
}); };
export var fetchCategoryTaskStructuresExportsFailure = function () { return ({
    type: FETCH_CATEGORY_TASK_STRUCTURES_EXPORTS_FAILURE,
}); };
export var fetchCategoryTaskStructuresExports = function (teamId, nextPage) {
    return function (dispatch, getState) {
        var categoryTaskStructuresExports = getState().categoryTaskStructuresExports;
        var page = nextPage || categoryTaskStructuresExports.page + 1;
        dispatch(fetchCategoryTaskStructuresExportsRequest());
        return api2(dispatch, getState, "/api/v2/category_task_structures/exports?team_id=".concat(teamId, "&page=").concat(page), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items, payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        payload = {
                            items: items,
                            page: page,
                            hasNext: getNextLink(response) !== null,
                        };
                        dispatch(fetchCategoryTaskStructuresExportsSuccess(payload));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchCategoryTaskStructuresExportsFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var createCategoryTaskStructuresExportRequest = function () { return ({
    type: CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST,
}); };
export var createCategoryTaskStructuresExportSuccess = function (item) { return ({
    type: CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS,
    payload: item,
}); };
export var createCategoryTaskStructuresExportFailure = function () { return ({
    type: CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE,
}); };
export var createCategoryTaskStructuresExport = function (params) {
    return function (dispatch, getState) {
        dispatch(createCategoryTaskStructuresExportRequest());
        return api2(dispatch, getState, '/api/v2/category_task_structures/exports', 'POST', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(createCategoryTaskStructuresExportSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(createCategoryTaskStructuresExportFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
