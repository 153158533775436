import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import { workspacePath } from '@/utils/workspace_helper';
import { parentId } from '@/utils/ancestry_helper';
var TeamDeletion = function (props) {
    var id = props.id, name = props.name, _a = props.isOpen, isOpen = _a === void 0 ? false : _a, openURL = props.openURL, categories = props.categories, personal = props.personal, teams = props.teams, setNotificationMessage = props.setNotificationMessage, fetchCategories = props.fetchCategories, onSubmit = props.onSubmit, onFetchUser = props.onFetchUser, onClose = props.onClose;
    var _b = React.useState(false), isConfirm = _b[0], setIsConfirm = _b[1];
    var _c = React.useState(false), forceDisabledDestroy = _c[0], setForceDisabledDestroy = _c[1];
    var navigate = useNavigate();
    var hasChildren = teams.some(function (team) { return parentId(team) === id; });
    var canDestroy = !personal && !hasChildren;
    React.useEffect(function () {
        if (!isOpen) {
            return;
        }
        fetchCategories({ team_id: id });
    }, [fetchCategories, id, isOpen]);
    var onChangeConfirm = function () {
        setIsConfirm(!isConfirm);
    };
    var onClickSubmit = function () {
        setForceDisabledDestroy(true);
        onSubmit(id).then(function (response) {
            if (response.error) {
                setForceDisabledDestroy(false);
                return;
            }
            setNotificationMessage('success', I18n.t('shared.targetDeleted', { target: name }));
            onFetchUser().then(function () {
                navigate(workspacePath('/teams'));
            });
        });
    };
    var onClickCancel = function () {
        setIsConfirm(false);
        onClose();
    };
    var isDisabledDestroy = function () {
        return forceDisabledDestroy || !canDestroy || !isConfirm;
    };
    return (<Modal isOpen={isOpen} toggle={onClickCancel} zIndex={1050}>
      <ModalHeaderMod toggle={onClickCancel}>
        {I18n.t('teamManagement.destroyTeam.delete')}
      </ModalHeaderMod>

      <ModalBody className="text-break">
        {!canDestroy && (<div className="text-danger mb-4">
            <p>
              {personal &&
                I18n.t('teamManagement.destroyTeam.noticePersonal', { name: name })}
              {hasChildren &&
                I18n.t('teamManagement.destroyTeam.noticeParent', { name: name })}
            </p>
          </div>)}

        <p>{I18n.t('teamManagement.destroyTeam.attention')}</p>

        <div className="h4 mt-3">{name}</div>

        <div className="mt-1">
          {categories.map(function (_a) {
            var id = _a.id, ancestry_depth = _a.ancestry_depth, color = _a.color, title = _a.title;
            return (<div key={id} style={{ marginLeft: "".concat(ancestry_depth * 1.2, "rem") }}>
              <CategoryCircleIcon color={color}/>
              {title}
            </div>);
        })}
        </div>

        <div className="form-group mt-3">
          <CustomCheck checked={isConfirm} disabled={!canDestroy} onChange={onChangeConfirm}>
            <span className="text-danger font-weight-bold">
              {I18n.t('teamManagement.destroyTeam.confirmation')}
            </span>
          </CustomCheck>
        </div>
      </ModalBody>

      <ModalFooter style={{ justifyContent: 'flex-start' }}>
        <button className="btn btn-danger" disabled={isDisabledDestroy()} onClick={onClickSubmit}>
          {I18n.t('shared.delete')}
        </button>
        <button className="btn btn-secondary" onClick={onClickCancel}>
          {I18n.t('shared.cancel')}
        </button>
        <a className="btn btn-link ml-auto" href="https://help.timecrowd.net/delete-team" onClick={function (e) {
            e.preventDefault();
            openURL(e.target.href);
        }}>
          {I18n.t('shared.help')}
        </a>
      </ModalFooter>
    </Modal>);
};
export default TeamDeletion;
