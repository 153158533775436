import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { numberWithDelimiter } from '../../utils/number_helper';
import I18n from '@/constants/i18n';
import CreditCardSettingContainer from '@/containers/credit_card_setting_container';
import ChargeListContainer from '@/containers/charge_list_container';
import { DeletePaymentModal } from './delete_payment_modal';
import { CapacityField } from './capacity_field';
export var EditPaymentForm = function (_a) {
    var payment = _a.payment, team = _a.team, user = _a.user, workspace = _a.workspace, onDelete = _a.onDelete, onUpdate = _a.onUpdate;
    var target = workspace || team;
    var normalizeCapacity = function (capacity) {
        if (capacity === null) {
            return '';
        }
        else {
            return capacity;
        }
    };
    var _b = React.useState(normalizeCapacity(target.capacity)), capacity = _b[0], setCapacity = _b[1];
    var _c = React.useState(isNumber(target.capacity)), hasLimit = _c[0], setHasLimit = _c[1];
    var _d = React.useState(false), isOpenDeleteModal = _d[0], setIsOpenDeleteModal = _d[1];
    var initialState = React.useRef({ capacity: capacity, hasLimit: hasLimit, isOpenDeleteModal: isOpenDeleteModal });
    var handleChangeHasLimit = function (e) {
        var capacity;
        if (e.target.checked) {
            if (isNumber(target.capacity)) {
                capacity = String(target.capacity);
            }
            else {
                capacity = String(payment.count);
            }
        }
        else {
            capacity = '';
        }
        setCapacity(capacity);
        setHasLimit(e.target.checked);
    };
    var handleChangeCapacity = function (e) {
        if (e.target.value === '' || Number(e.target.value) > 0) {
            setCapacity(e.target.value);
        }
    };
    var handleClickUpdateCapacity = function () {
        var newCapacity;
        if (hasLimit) {
            newCapacity = capacity;
        }
        else {
            newCapacity = '';
        }
        onUpdate(team === null || team === void 0 ? void 0 : team.id, newCapacity);
    };
    var handleClickDelete = function (e) {
        e.preventDefault();
        setIsOpenDeleteModal(true);
    };
    var handleCancelDeleteModal = function () {
        setIsOpenDeleteModal(false);
    };
    var handleSubmitDeleteModal = function () {
        setIsOpenDeleteModal(false);
        onDelete(team === null || team === void 0 ? void 0 : team.id);
    };
    var canDeletePayment = user.id === payment.user.id;
    var isInvalidCapacity = capacity !== target.capacity && isEmpty(capacity) && hasLimit;
    var form = (<div className="w-100">
      <div className="row no-gutters w-100">
        <div className="col-12">
          <div className="form-group row mb-2">
            <div className="col-md-8 col-12">
              <p className="h3 mb-3">{I18n.t('payment.paymentMethods')}</p>
              <div className="p-2 mt-3" style={{
            background: '#f9f9f9',
            border: '1px solid #ececec',
            borderRadius: '.5rem',
            width: '100%',
            maxWidth: '720px',
        }}>
                <div className="d-flex align-items-center">
                  <span className="mr-1">
                    <span>
                      {I18n.t('payment.currently')}
                      <span className="h3 ml-1">
                        <strong>{payment.count}</strong>
                      </span>
                    </span>{' '}
                    {I18n.t('payment.people')} × {I18n.t('shared.currencyUnit')}
                    {payment.unit_price} =
                  </span>
                  <div className="d-flex align-items-center">
                    <span className="badge badge-success">
                      {I18n.t('payment.totalAmount')}
                    </span>
                    <strong className="ml-1 font-weight-bold h2">
                      {I18n.t('shared.currencyUnit')}
                      {numberWithDelimiter(payment.unit_price * payment.count)}
                    </strong>
                  </div>
                </div>
              </div>
              <CapacityField capacity={capacity} hasLimit={hasLimit} invalid={isInvalidCapacity} unitPrice={payment.unit_price} onChangeCapacity={handleChangeCapacity} onChangeHasLimit={handleChangeHasLimit}/>
            </div>
            <div className="col-md-4 col-12 text-right">
              <button className="btn-major-action btn btn-secondary" disabled={(capacity === initialState.current.capacity &&
            hasLimit === initialState.current.hasLimit) ||
            isInvalidCapacity} onClick={handleClickUpdateCapacity}>
                {I18n.t('payment.changeMaximumNumber')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row no-gutters w-100">
        <div className="col-12">
          <hr className="my-3"/>
          <CreditCardSettingContainer payment={payment}/>
        </div>
      </div>
      <ChargeListContainer />
      <div className="form-group row mt-3">
        <div className="col-xl-10 col-12">
          <hr />
          <button className={"btn btn-link pr-0 pl-0 mt-1 ".concat(canDeletePayment ? 'text-danger' : 'text-muted')} disabled={!canDeletePayment} type="button" onClick={handleClickDelete}>
            {I18n.t('shared.deletePayment')}
          </button>
          {!canDeletePayment && (<div className="text-muted small font-weight-medium mt-1">
              <p>{I18n.t('shared.deletePaymentPermission')}</p>
              <p>
                {I18n.t('shared.paidUser')} {payment.user.nickname}
              </p>
            </div>)}
        </div>
        <DeletePaymentModal isOpen={isOpenDeleteModal} team={team} workspace={workspace} onCancel={handleCancelDeleteModal} onSubmit={handleSubmitDeleteModal}/>
      </div>
    </div>);
    return form;
};
