import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '../../constants/i18n';
var MembershipRoleDropdown = function (props) {
    var team = props.team, membership = props.membership, onUpdate = props.onUpdate;
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var handleChangeRole = function (role) {
        onUpdate(membership, role);
    };
    var handleToggle = function () {
        setIsOpen(!isOpen);
    };
    if (!membership.can_update || membership.team.id !== team.id) {
        return <span>{I18n.t("shared.roles.".concat(membership.ancestral_role))}</span>;
    }
    return (<Dropdown isOpen={isOpen} toggle={handleToggle}>
      <DropdownToggle caret>
        {I18n.t("shared.roles.".concat(membership.ancestral_role))}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={function () { return handleChangeRole('manager'); }}>
          {I18n.t("shared.roles.manager")}
        </DropdownItem>
        <DropdownItem onClick={function () { return handleChangeRole('member'); }}>
          {I18n.t("shared.roles.member")}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>);
};
export default MembershipRoleDropdown;
