import { connect } from 'react-redux';
import Task from '@/components/task_edit/task';
import { openModal } from '@/modules/modal';
var mapStateToProps = function (state) { return ({
    openURL: state.openURL,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onOpenDeleteModal: function () {
        dispatch(openModal('DELETE_TASK_MODAL'));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(Task);
