import React from 'react';
import { cellId } from './helpers';
import { ActivityTooltip } from './tooltip';
export var WithTooltipCell = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, userId = _a.userId, cellName = _a.cellName, children = _a.children, summary = _a.summary, showsTooltip = _a.showsTooltip;
    var targetId = cellId(userId, cellName);
    return (<td className={className}>
      <div id={targetId}>
        {children}
        {showsTooltip && (<ActivityTooltip key={userId} summary={summary} targetId={targetId}/>)}
      </div>
    </td>);
};
