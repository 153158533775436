import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var StopMemberEntryModal = function (_a) {
    var isOpen = _a.isOpen, onCancel = _a.onCancel, onStop = _a.onStop, user = _a.user;
    return (<Modal isOpen={isOpen} toggle={onCancel} zIndex={1050}>
      <ModalHeaderMod toggle={onCancel}>
        {I18n.t('shared.stopMemberEntryModal.title')}
      </ModalHeaderMod>

      <ModalBody>
        <p>
          {I18n.t('shared.stopMemberEntryModal.description', {
            user: user.nickname,
        })}
        </p>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-danger" onClick={onStop}>
          {I18n.t('shared.stopMemberEntryModal.stop')}
        </button>
        <button className="btn btn-secondary" onClick={onCancel}>
          {I18n.t('shared.cancel')}
        </button>
      </ModalFooter>
    </Modal>);
};
