import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import InfiniteCalendar from 'react-infinite-calendar';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
import variants from '@/constants/variants';
export var views = ['daily', 'weekly'];
var relativeDate = function (currentDate, view, direction) {
    var unit = view === 'daily' ? 'days' : 'weeks';
    return moment(currentDate).add(direction, unit).toDate();
};
export var CalendarDateSelectNav = function (_a) {
    var view = _a.view, currentDate = _a.currentDate, onSelectDate = _a.onSelectDate, onSelectView = _a.onSelectView, _b = _a.isPC, isPC = _b === void 0 ? variants.isPC : _b;
    var _c = React.useState(false), isOpenCalendarSelect = _c[0], setIsOpenCalendarSelect = _c[1];
    var _d = React.useState(false), isOpenViewSelect = _d[0], setIsOpenViewSelect = _d[1];
    var onClickToday = React.useCallback(function () {
        onSelectDate(new Date());
    }, [onSelectDate]);
    var onClickPrev = React.useCallback(function () {
        onSelectDate(relativeDate(currentDate, view, -1));
    }, [currentDate, view, onSelectDate]);
    var onClickNext = React.useCallback(function () {
        onSelectDate(relativeDate(currentDate, view, 1));
    }, [currentDate, view, onSelectDate]);
    var onSelectCalendar = React.useCallback(function (date) {
        onSelectDate(date);
        setIsOpenCalendarSelect(false);
    }, [onSelectDate]);
    var onChangeView = React.useCallback(function (view) {
        onSelectView(view);
        setIsOpenViewSelect(false);
    }, [onSelectView]);
    var onToggleCalendarSelect = React.useCallback(function () {
        setIsOpenCalendarSelect(!isOpenCalendarSelect);
    }, [isOpenCalendarSelect, setIsOpenCalendarSelect]);
    return (<div className="calendar-date-select-nav border-bottom-1">
      <button className="mr-2 btn btn-secondary" type="button" onClick={onClickToday}>
        {I18n.t('shared.today')}
      </button>
      <div className="btn-group" role="group">
        <button className="btn btn-secondary" type="button" onClick={onClickPrev}>
          <Icon name="chevron-left"/>
        </button>
        <button className="btn btn-secondary" type="button" onClick={onClickNext}>
          <Icon name="chevron-right"/>
        </button>
      </div>
      <ButtonDropdown className="ml-2" isOpen={isOpenCalendarSelect} toggle={onToggleCalendarSelect}>
        <DropdownToggle caret>
          {moment(currentDate).format('YYYY-MM-DD')}
        </DropdownToggle>
        <DropdownMenu>
          <InfiniteCalendar className="calendar-sm" displayOptions={{
            showHeader: true,
            showWeekdays: false,
            shouldHeaderAnimate: true,
        }} height={276} locale={I18n.t('infiniteCalendar')} rowHeight={48} selected={currentDate} theme={{
            floatingNav: {
                chevron: '#4abaa4',
            },
        }} width="100%" onSelect={onSelectCalendar}/>
        </DropdownMenu>
      </ButtonDropdown>
      {isPC && (<ButtonDropdown className="ml-2" isOpen={isOpenViewSelect} toggle={function () { return setIsOpenViewSelect(!isOpenViewSelect); }}>
          <DropdownToggle caret>
            {I18n.t("shared.calendarViews.".concat(view))}
          </DropdownToggle>
          <DropdownMenu>
            {views.map(function (v) { return (<button key={v} className="dropdown-item btn btn-secondary" type="button" onClick={function () { return onChangeView(v); }}>
                {I18n.t("shared.calendarViews.".concat(v))}
              </button>); })}
          </DropdownMenu>
        </ButtonDropdown>)}
    </div>);
};
