// Actions
export var SET_WEB_HOOKS = 'timecrowd/web_hooks/SET_WEB_HOOKS';
export var ADD_WEB_HOOK = 'timecrowd/web_hooks/ADD_WEB_HOOK';
export var REMOVE_WEB_HOOK = 'timecrowd/web_hooks/REMOVE_WEB_HOOK';
// Reducer
var initialState = [];
export default function webHooks(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_WEB_HOOKS:
            return action.webHooks;
        case ADD_WEB_HOOK:
            return state.concat(action.webHook);
        case REMOVE_WEB_HOOK:
            return state.filter(function (webHook) {
                return webHook.id !== action.id;
            });
        default:
            return state;
    }
}
// Action Creators
export var setWebHooks = function (webHooks) { return ({
    type: SET_WEB_HOOKS,
    webHooks: webHooks,
}); };
export var addWebHook = function (webHook) { return ({
    type: ADD_WEB_HOOK,
    webHook: webHook,
}); };
export var removeWebHook = function (id) { return ({
    type: REMOVE_WEB_HOOK,
    id: id,
}); };
