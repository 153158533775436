import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import intersection from 'lodash/intersection';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import { CategoryDropdown } from '@/components/calendar_integration_form/category_dropdown';
import { WorkspacesCalendarIntegrationFilter } from '@/components/workspaces_calendar_integration_filter';
import Icon from '@/components/atoms/icon';
export var CalendarIntegrationFilterFormModal = function (_a) {
    var categories = _a.categories, _b = _a.defaultCategoryId, defaultCategoryId = _b === void 0 ? undefined : _b, _c = _a.defaultKeywords, defaultKeywords = _c === void 0 ? '' : _c, _d = _a.filterId, filterId = _d === void 0 ? undefined : _d, _e = _a.filters, filters = _e === void 0 ? [] : _e, _f = _a.isExpandAll, isExpandAll = _f === void 0 ? false : _f, isOpen = _a.isOpen, onClose = _a.onClose, onSubmit = _a.onSubmit;
    var _g = React.useState(defaultCategoryId), selectedCategoryId = _g[0], setSelectedCategoryId = _g[1];
    var _h = React.useState(defaultKeywords), keywordsText = _h[0], setKeywordsText = _h[1];
    var _j = React.useState([]), duplicatedFilters = _j[0], setDuplicatedFilters = _j[1];
    var _k = React.useState([]), duplicatedKeywords = _k[0], setDuplicatedKeywords = _k[1];
    var isDisabled = !categories.length;
    var handleSelectCategory = React.useCallback(function (id) {
        setSelectedCategoryId(id);
    }, []);
    var handleKeywordsChange = React.useCallback(function (e) {
        setKeywordsText(e.target.value);
    }, []);
    var handleSubmit = React.useCallback(function () {
        var allDuplicatedWords = [];
        var duplicated = filters.filter(function (filter) {
            if (filter.id === filterId) {
                return false;
            }
            var duplicatedWords = intersection(filter.keywords, keywordsText.split(/\n+/));
            allDuplicatedWords.push.apply(allDuplicatedWords, duplicatedWords);
            return duplicatedWords.length > 0;
        });
        if (duplicated.length > 0) {
            setDuplicatedFilters(duplicated);
            setDuplicatedKeywords(allDuplicatedWords);
            return;
        }
        setDuplicatedFilters([]);
        setDuplicatedKeywords([]);
        onSubmit({
            category_id: selectedCategoryId,
            keywords_text: keywordsText,
        });
    }, [filterId, filters, keywordsText, selectedCategoryId, onSubmit]);
    React.useEffect(function () {
        setSelectedCategoryId(defaultCategoryId);
        setKeywordsText(defaultKeywords);
        setDuplicatedFilters([]);
        setDuplicatedKeywords([]);
    }, [defaultCategoryId, defaultKeywords, isOpen]);
    return (<Modal isOpen={isOpen} toggle={onClose} zIndex={1050}>
      <ModalHeaderMod toggle={onClose}>
        {I18n.t('shared.addFiltering')}
      </ModalHeaderMod>
      <ModalBody>
        <div className="mb-2">
          <div className="row">
            <div className="col-4">{I18n.t('shared.teamCategory')}</div>
            <div className="col-8">
              <CategoryDropdown categories={categories} isExpandAll={isExpandAll} selectedId={selectedCategoryId} onSelect={handleSelectCategory}/>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-4">{I18n.t('shared.keywords')}</div>
            <div className="col-8">
              <textarea className={"form-control ".concat(duplicatedKeywords.length > 0 ? 'border-danger' : '')} rows={2} value={keywordsText} onChange={handleKeywordsChange}></textarea>
              <p className="text-muted small mt-1">
                {I18n.t('shared.multiwordsNote')}
              </p>
            </div>
          </div>
        </div>
        {duplicatedKeywords.length > 0 && (<div className="my-2">
            <div className="alert-danger border-1 border-danger p-2">
              <div className="d-flex align-items-baseline">
                <Icon className="text-danger" name="warning"/>
                <div className="ml-1">
                  <p className="mb-0">
                    {I18n.t('shared.mustBeUniqueKeywords', {
                keywords: duplicatedKeywords
                    .map(function (keyword) { return "\u300C".concat(keyword, "\u300D"); })
                    .join(','),
                count: duplicatedKeywords.length,
            })}
                  </p>
                </div>
              </div>
            </div>
          </div>)}
        <div>
          {duplicatedFilters.map(function (filter) {
            return (<div key={filter.id} className="border-1 p-2 mb-2">
                <WorkspacesCalendarIntegrationFilter filter={filter} hightlightKeywords={duplicatedKeywords} isEnablePriority={false} maxPriority={filters.length}/>
              </div>);
        })}
        </div>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-secondary" onClick={onClose}>
          {I18n.t('shared.cancel')}
        </button>
        <button className="btn btn-primary" disabled={isDisabled} onClick={handleSubmit}>
          {I18n.t('shared.save')}
        </button>
      </ModalFooter>
    </Modal>);
};
