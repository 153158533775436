import { __assign } from "tslib";
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import I18n from '@/constants/i18n';
import MembersInfo from '@/components/members/members_info';
import InvitationInfo from '@/components/members/invitation_info';
import { TeamManagementLayoutContainer } from '@/containers/team_management_layout_container';
import { MemberListTable } from '@/components/team_management/member_list_table';
import { TeamMembershipsFormModal } from '@/components/team_memberships_form_modal';
import { ConfirmLeaveTeamMyselfModal } from '@/components/confirm_leave_team_myself_modal';
import { workspacePath } from '@/utils/workspace_helper';
export var TeamMemberSetting = function (_a) {
    var _b;
    var memberships = _a.memberships, defaultTeam = _a.team, user = _a.user, workspaces = _a.workspaces, workspacesMemberships = _a.workspacesMemberships, onBulkUpsertTeamMemberships = _a.onBulkUpsertTeamMemberships, onDelete = _a.onDelete, onFetchWorkspacesEmploys = _a.onFetchWorkspacesEmploys, onFetchWorkspacesMemberships = _a.onFetchWorkspacesMemberships, onSetNotificationMessage = _a.onSetNotificationMessage;
    var team = React.useMemo(function () { return defaultTeam || {}; }, [defaultTeam]);
    var navigate = useNavigate();
    var _c = React.useState({
        openInvite: false,
        inviteType: 'email',
        isOpenMembersModal: new URLSearchParams(useLocation().search).get('tab') === 'invitation',
        membership: null,
        user: null,
    }), state = _c[0], setState = _c[1];
    var _d = React.useState(false), isOpenConfirmLeaveTeamMyselfModal = _d[0], setIsOpenConfirmLeaveTeamMyselfModal = _d[1];
    var isInvitable = function () {
        return !team.personal && team.can_manage;
    };
    var toggleIsOpenMembersModal = function () {
        if (!state.isOpenMembersModal && workspacesMemberships.length < 1) {
            onFetchWorkspacesMemberships();
        }
        setState(function (prevState) { return (__assign(__assign({}, prevState), { isOpenMembersModal: !state.isOpenMembersModal })); });
    };
    var onClickAddCapacityLink = function (url) {
        navigate(url);
    };
    var handleToggleConfirmLeaveTeamMyselfModal = function () {
        setIsOpenConfirmLeaveTeamMyselfModal(!isOpenConfirmLeaveTeamMyselfModal);
    };
    var handleDeleteMembership = function () {
        var membership = memberships.find(function (m) { return m.user.id === user.id && m.team.id === team.id; });
        if (!membership) {
            return;
        }
        onDelete(team, membership.id);
    };
    var currentWorkspaceId = (_b = workspaces.current) === null || _b === void 0 ? void 0 : _b.id;
    React.useEffect(function () {
        if (team.can_manage &&
            team.workspaces_workspace_id === currentWorkspaceId) {
            onFetchWorkspacesEmploys(team.id);
        }
    }, [user, team, currentWorkspaceId, onFetchWorkspacesEmploys]);
    return (<TeamManagementLayoutContainer currentPage="members" saveButtonForPC={isInvitable() && (<button className="btn-major-action btn btn-primary" onClick={toggleIsOpenMembersModal}>
            {I18n.t('shared.manageMembers')}
          </button>)} saveButtonForSP={<button className="btn-mobile-header-action btn-mobile-header-action-text  btn-mobile-header-action-primary btn btn-link" disabled={!isInvitable()} style={{ minWidth: '60px' }} onClick={toggleIsOpenMembersModal}>
          {I18n.t('shared.manageMembers')}
        </button>} team={defaultTeam} title={I18n.t('teamManagement.memberTab.title')}>
      <div className="mb-2 d-flex">
        <MembersInfo memberships={memberships}/>
        {team.root_capacity && <span className="mx-1">/</span>}
        <InvitationInfo canChange={team.ancestry === null} capacity={team.root_capacity} count={team.root_price_users_count} paymentPath={workspacePath("/teams/".concat(team.id, "/payment"))} onClick={onClickAddCapacityLink}/>
      </div>
      <MemberListTable memberships={memberships} team={team} user={user}/>
      {isInvitable() && (<TeamMembershipsFormModal currentUser={user} isOpen={state.isOpenMembersModal} memberships={memberships} setNotificationMessage={onSetNotificationMessage} team={team} updateTeam={function (id, params) {
                return onBulkUpsertTeamMemberships(id, params);
            }} workspacesMemberships={workspacesMemberships} onCancel={toggleIsOpenMembersModal}/>)}
      <hr />
      <p>
        <button className="btn btn-link text-danger" onClick={handleToggleConfirmLeaveTeamMyselfModal}>
          {I18n.t('confirmLeaveTeamMyselfModal.leaveTeam')}
        </button>
      </p>
      <ConfirmLeaveTeamMyselfModal isDeleting={false} isOpen={isOpenConfirmLeaveTeamMyselfModal} role={team.can_manage ? 'manager' : 'member'} toggle={handleToggleConfirmLeaveTeamMyselfModal} onDelete={handleDeleteMembership}/>
    </TeamManagementLayoutContainer>);
};
