import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { destroyTimeEntry, fetchDailyTimeEntries, } from '@/modules/time_entries';
import { TaskList } from '@/components/task_list';
var TaskListContainer = function (_a) {
    var activities = _a.activities, arrangeRecentCategories = _a.arrangeRecentCategories, currentEntry = _a.currentEntry, dailyActivities = _a.dailyActivities, expandDailyActivity = _a.expandDailyActivity, fetchWorkingUsersCount = _a.fetchWorkingUsersCount, isApp = _a.isApp, isMobile = _a.isMobile, restoreTask = _a.restoreTask, setCurrentCategory = _a.setCurrentCategory, setCurrentEntry = _a.setCurrentEntry, startTask = _a.startTask, stopTimeEntry = _a.stopTimeEntry, updateDailyActivity = _a.updateDailyActivity, updateTask = _a.updateTask, user = _a.user;
    var timeEntries = useSelector(function (state) { return state.timeEntries.items; });
    var dispatch = useDispatch();
    var handleFetchDailyTimeEntries = React.useCallback(function (teamId, taskId, startedSince, startedUntil) {
        return dispatch(fetchDailyTimeEntries(teamId, taskId, startedSince, startedUntil));
    }, [dispatch]);
    var handleDestroyTimeEntry = React.useCallback(function (id) {
        return dispatch(destroyTimeEntry(id));
    }, [dispatch]);
    return (<TaskList activities={activities} arrangeRecentCategories={arrangeRecentCategories} currentEntry={currentEntry} dailyActivities={dailyActivities} destroyTimeEntry={handleDestroyTimeEntry} expandDailyActivity={expandDailyActivity} fetchDailyTimeEntries={handleFetchDailyTimeEntries} fetchWorkingUsersCount={fetchWorkingUsersCount} isApp={isApp} isMobile={isMobile} restoreTask={restoreTask} setCurrentCategory={setCurrentCategory} setCurrentEntry={setCurrentEntry} startTask={startTask} stopTimeEntry={stopTimeEntry} timeEntries={timeEntries} updateDailyActivity={updateDailyActivity} updateTask={updateTask} user={user}/>);
};
export default TaskListContainer;
