import React from 'react';
import classNames from 'classnames';
import CustomCheck from '@/components/atoms/custom_check';
var ListGroupItemCheck = function (props) {
    var _a;
    var className = props.className, children = props.children, _b = props.tag, Tag = _b === void 0 ? 'li' : _b, _c = props.active, active = _c === void 0 ? false : _c, _d = props.disabled, disabled = _d === void 0 ? false : _d, _e = props.color, color = _e === void 0 ? 'normal' : _e, _f = props.action, action = _f === void 0 ? true : _f, _g = props.checked, checked = _g === void 0 ? false : _g, onChange = props.onChange, _h = props.style, style = _h === void 0 ? {} : _h;
    var classes = classNames('list-group-item list-group-item-check', className, (_a = {
            active: active,
            disabled: disabled,
            'list-group-item-action': action
        },
        _a['list-group-item-' + color] = true,
        _a.checked = checked,
        _a));
    return (<Tag className={classes} style={style}>
      <CustomCheck checked={checked} descriptionClassName="flex-grow-1 overflow-hidden" disabled={disabled} onChange={onChange}>
        {children}
      </CustomCheck>
    </Tag>);
};
export default ListGroupItemCheck;
