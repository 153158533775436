import React from 'react';
import some from 'lodash/some';
import Avatar from '@/components/atoms/avatar';
import ListGroupItemCheck from '@/components/atoms/list_group_item_check';
import OmitLabel from '@/components/atoms/omit_label';
var ListUsers = function (props) {
    var team = props.team, selectedTeamUsers = props.selectedTeamUsers, onChange = props.onChange;
    return (<ul className="list-group list-group-flush">
      {team.users.map(function (user) {
            var checked = some(selectedTeamUsers, { id: user.id });
            return (<ListGroupItemCheck key={"team-".concat(team.id, "-user-").concat(user.id)} checked={checked} style={{
                    borderBottom: 0,
                }} onChange={function () { return onChange(team, user, checked); }}>
            <div className="d-flex">
              <Avatar className="mr-1" image={user.avatar_url}/>
              <OmitLabel maxWidth="14rem">{user.nickname}</OmitLabel>
            </div>
          </ListGroupItemCheck>);
        })}
    </ul>);
};
export default ListUsers;
