import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import MainMenuContainer from '@/containers/main_menu_container';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import { WorkspacesAccordion } from '@/components/workspaces_accordion';
var Other = function (props) {
    var user = props.user, openURL = props.openURL, workingUsersCount = props.workingUsersCount, workspaces = props.workspaces, teams = props.teams.items;
    var location = useLocation();
    return (<div className="content-wrap">
      <MainMenuContainer />
      <div className="content-main">
        <WorkspacesAccordion currentRoute={location.pathname} teams={teams} user={user} workingUsersCount={workingUsersCount} workspaces={workspaces.items}/>

        <hr className="mx-2"/>
        <ul className="flex-column nav">
          <li className="nav-item">
            <Link className="nav-link" to="/app/personal_settings/profile">
              <Avatar className="mr-2 border-0" image={user.avatar_url} size="icon-md"/>
              {I18n.t('shared.personalSettings')}
            </Link>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={function (e) {
            e.preventDefault();
            openURL('https://support.timecrowd.net/');
        }}>
              <Icon className="mr-2" name="question" size="md"/>
              {I18n.t('shared.help')}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={function (e) {
            e.preventDefault();
            openURL('https://timecrowd.net/contacts/new');
        }}>
              <Icon className="mr-2" name="message" size="md"/>
              {I18n.t('shared.contactSupport')}
            </a>
          </li>
        </ul>
      </div>
    </div>);
};
export default Other;
