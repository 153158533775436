import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
export var FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST';
export var FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_SUCCESS = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_SUCCESS';
export var FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_FAILURE = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_FAILURE';
export var UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST';
export var UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_SUCCESS = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_SUCCESS';
export var UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_FAILURE = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_FAILURE';
export var DELETE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST';
export var DELETE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_SUCCESS = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_SUCCESS';
export var DELETE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_FAILURE = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_FAILURE';
export var RESET_WORKSPACES_CALENDAR_INTEGRATION_SETTING = 'timecrowd/WORKSPACES/RESET_WORKSPACES_CALENDAR_INTEGRATION_SETTING';
export var FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_REQUEST = 'timecrowd/WORKSPACES/FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_REQUEST';
export var FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_SUCCESS = 'timecrowd/WORKSPACES/FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_SUCCESS';
export var FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_FAILURE = 'timecrowd/WORKSPACES/FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_FAILURE';
export var initialState = {
    status: 'pending',
    item: {},
    items: [],
};
export default function workspacesCalendarIntegrationSettings(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST:
        case FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_REQUEST:
            return __assign(__assign({}, state), { status: 'loading' });
        case UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST:
        case DELETE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST:
            return __assign({}, state);
        case FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_SUCCESS:
            return __assign(__assign({}, state), { item: action.payload, status: 'loaded' });
        case FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_SUCCESS:
            return __assign(__assign({}, state), { status: 'loaded', items: action.payload });
        case UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_SUCCESS:
            return __assign(__assign({}, state), { item: action.payload });
        case FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_FAILURE:
        case FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_FAILURE:
            return __assign(__assign({}, state), { status: 'loaded' });
        case UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_FAILURE:
            return __assign({}, state);
        default:
            return state;
    }
}
export var fetchWorkspacesCalendarIntegrationSettingRequest = function () { return ({
    type: FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST,
}); };
export var fetchWorkspacesCalendarIntegrationSettingSuccess = function (item) { return ({
    type: FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_SUCCESS,
    payload: item,
}); };
export var fetchWorkspacesCalendarIntegrationSettingFailure = function () { return ({
    type: FETCH_WORKSPACES_CALENDAR_INTEGRATION_SETTING_FAILURE,
}); };
export var fetchWorkspacesCalendarIntegrationSetting = function () {
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesCalendarIntegrationSettingRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/calendar_integration/setting", 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(fetchWorkspacesCalendarIntegrationSettingSuccess(item));
                        return [2 /*return*/, { error: false, item: item }];
                    case 2:
                        dispatch(fetchWorkspacesCalendarIntegrationSettingFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateWorkspacesCalendarIntegrationSettingRequest = function () { return ({
    type: UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_REQUEST,
}); };
export var updateWorkspacesCalendarIntegrationSettingSuccess = function (item) { return ({
    type: UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_SUCCESS,
    payload: item,
}); };
export var updateWorkspacesCalendarIntegrationSettingFailure = function () { return ({
    type: UPDATE_WORKSPACES_CALENDAR_INTEGRATION_SETTING_FAILURE,
}); };
export var updateWorkspacesCalendarIntegrationSetting = function (params) {
    return function (dispatch, getState) {
        dispatch(updateWorkspacesCalendarIntegrationSettingRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/calendar_integration/setting", 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        if (response.ok) {
                            dispatch(updateWorkspacesCalendarIntegrationSettingSuccess(item));
                            return [2 /*return*/, { error: false, item: item }];
                        }
                        else {
                            dispatch(updateWorkspacesCalendarIntegrationSettingFailure());
                            return [2 /*return*/, { error: true, errors: item.errors }];
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
};
export var resetWorkspacesCalendarIntegrationSetting = function () { return ({
    type: RESET_WORKSPACES_CALENDAR_INTEGRATION_SETTING,
}); };
export var fetchUserWorkspacesCalendarIntegrationSettingsRequest = function () { return ({
    type: FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_REQUEST,
}); };
export var fetchUserWorkspacesCalendarIntegrationSettingsSuccess = function (items) { return ({
    type: FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_SUCCESS,
    payload: items,
}); };
export var fetchUserWorkspacesCalendarIntegrationSettingsFailure = function () { return ({
    type: FETCH_USER_WORKSPACES_CALENDAR_INTEGRATION_SETTINGS_FAILURE,
}); };
export var fetchUserWorkspacesCalendarIntegrationSettings = function () {
    return function (dispatch, getState) {
        dispatch(fetchUserWorkspacesCalendarIntegrationSettingsRequest());
        return api2(dispatch, getState, "/api/v2/users/workspaces_calendar_integration_settings", 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchUserWorkspacesCalendarIntegrationSettingsSuccess(items));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchUserWorkspacesCalendarIntegrationSettingsFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
