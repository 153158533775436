import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomFieldsMasterList } from '@/components/custom_fields_master_list';
import { fetchCustomFieldsMasters, createCustomFieldsMaster, updateCustomFieldsMaster, deleteCustomFieldsMaster, } from '@/modules/custom_fields_masters';
import { setNotificationMessage } from '@/modules/notification';
import { workspaceTeams as getWorkspaceTeams } from '@/utils/workspace_helper';
var CustomFieldsContainer = function () {
    var customFieldsMasters = useSelector(function (state) { return state.customFieldsMasters; });
    var teams = useSelector(function (state) { return state.teams; });
    var workspaceTeams = getWorkspaceTeams(teams.items);
    var dispatch = useDispatch();
    var handleCreateCustomFieldsMaster = function (params) {
        return dispatch(createCustomFieldsMaster(params));
    };
    var handleUpdateCustomFieldsMaster = function (id, params) {
        return dispatch(updateCustomFieldsMaster(id, params));
    };
    var handleDeleteCustomFieldsMaster = function (id) {
        return dispatch(deleteCustomFieldsMaster(id));
    };
    var handleSetNotificationMessage = function (type, message) {
        dispatch(setNotificationMessage(type, message));
    };
    var params = useParams();
    var team = workspaceTeams.find(function (team) { return team.id === Number(params.team_id); });
    React.useEffect(function () {
        if (team === null || team === void 0 ? void 0 : team.is_custom_fields_allowed) {
            dispatch(fetchCustomFieldsMasters(team.id));
        }
    }, [dispatch, team === null || team === void 0 ? void 0 : team.id, team === null || team === void 0 ? void 0 : team.is_custom_fields_allowed]);
    return (<CustomFieldsMasterList createCustomFieldsMaster={handleCreateCustomFieldsMaster} customFieldsMasters={customFieldsMasters.items.filter(function (_a) {
            var team_id = _a.team_id;
            return team_id === (team === null || team === void 0 ? void 0 : team.id);
        })} deleteCustomFieldsMaster={handleDeleteCustomFieldsMaster} setNotificationMessage={handleSetNotificationMessage} teams={workspaceTeams} updateCustomFieldsMaster={handleUpdateCustomFieldsMaster}/>);
};
export default CustomFieldsContainer;
