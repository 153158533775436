import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteAssetModal from '@/components/export/delete_asset_modal';
import { deleteAsset } from '@/modules/assets';
import { unselectAsset, closeDeleteAssetModal } from '@/modules/exports';
import { removeDangerNotificationMessage } from '@/modules/notification';
var DeleteAssetModalContainer = function () {
    var asset = useSelector(function (state) { return state.exports.selected; });
    var isOpen = useSelector(function (state) { return state.exports.isOpenDeleteModal; });
    var dispatch = useDispatch();
    var handleDelete = React.useCallback(function (asset) {
        dispatch(deleteAsset(asset.id)).then(function (response) {
            if (response.error) {
                return;
            }
            dispatch(unselectAsset());
            dispatch(closeDeleteAssetModal());
        });
    }, [dispatch]);
    var toggle = React.useCallback(function () {
        dispatch(removeDangerNotificationMessage());
        dispatch(unselectAsset());
        dispatch(closeDeleteAssetModal());
    }, [dispatch]);
    return (<DeleteAssetModal asset={asset} isOpen={isOpen} toggle={toggle} onDelete={handleDelete}/>);
};
export default DeleteAssetModalContainer;
