import { connect } from 'react-redux';
import TaskEdit from '@/components/task_edit';
import { refreshTask } from '@/api2/tasks';
var mapStateToProps = function (state) { return ({
    tasks: state.tasks.items,
    isLoading: state.tasks.isLoading,
    isFetched: state.tasks.isFetched,
    from: 'tasks',
    teams: state.teams,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onRefresh: function (id, params) {
        dispatch(refreshTask(id, params));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TaskEdit);
