import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useSelector } from 'react-redux';
import I18n from '@/constants/i18n';
import Alert from '@/components/alert';
import { RAILS_ENV } from '@/constants/rails_env';
import { isRequiredUpdate } from './helpers';
var FETCH_INTERVAL = 1000 * 60 * 5;
var VERSION_URL = "https://".concat(RAILS_ENV, "-assets-timecrowd.s3.ap-northeast-1.amazonaws.com/web/version.txt");
export var MinAppVersionAlertContainer = function () {
    var _a = React.useState('0.0.0'), minAppVersion = _a[0], setMinAppVersion = _a[1];
    var appVersion = useSelector(function (state) { return state.appVersion; });
    var reloadPage = useSelector(function (state) { return state.reloadPage; });
    var isChromeExtension = useSelector(function (state) { return state.isChromeExtension; });
    // NOTE: 定期的に最低バージョンを取得
    React.useEffect(function () {
        if (isChromeExtension) {
            return;
        }
        var fetchMinAppVersion = function () { return __awaiter(void 0, void 0, void 0, function () {
            var res, version, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch("".concat(VERSION_URL, "?").concat(new Date().getTime()))];
                    case 1:
                        res = _b.sent();
                        return [4 /*yield*/, res.text()];
                    case 2:
                        version = _b.sent();
                        setMinAppVersion(version);
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var intervalId = setInterval(fetchMinAppVersion, FETCH_INTERVAL);
        fetchMinAppVersion();
        return function () { return clearInterval(intervalId); };
    }, [isChromeExtension]);
    if (isChromeExtension || !isRequiredUpdate(appVersion, minAppVersion)) {
        return;
    }
    return (<Alert isHtml isOpen withoutCloseButton autoClose={false} color="warning" message="" position="bottom" onClose={function () { }}>
      <div className="d-flex w-100 align-items-center pr-1">
        {I18n.t('shared.minAppVersionAlert')}
        <button className={'btn btn-warning ml-5'} type="button" onClick={reloadPage}>
          {I18n.t('shared.reload')}
        </button>
      </div>
    </Alert>);
};
