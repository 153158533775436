import api from '@/api';
export var FETCH_REPORT_RECIPIENTS_REQUEST = 'timecrowd/report_recipients/FETCH_REPORT_RECIPIENTS_REQUEST';
export var FETCH_REPORT_RECIPIENTS_SUCCESS = 'timecrowd/report_recipients/FETCH_REPORT_RECIPIENTS_SUCCESS';
export var ADD_REPORT_RECIPIENT = 'timecrowd/report_recipients/ADD_REPORT_RECIPIENT';
export var REMOVE_REPORT_RECIPIENT = 'timecrowd/report_recipients/REMOVE_REPORT_RECIPIENT';
export var fetchReportRecipients = function () {
    return function (dispatch, getState) {
        dispatch(fetchReportRecipientsRequest());
        return api(dispatch, getState, "report_recipients", 'GET').then(function (reportRecipients) {
            dispatch(fetchReportRecipientsSuccess(reportRecipients));
        });
    };
};
export var createReportRecipient = function (params) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "report_recipients", 'POST', params).then(function (reportRecipient) {
            if (!reportRecipient.error) {
                dispatch(addReportRecipient(reportRecipient));
            }
            return reportRecipient;
        });
    };
};
export var destroyReportRecipient = function (id) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "report_recipients/".concat(id), 'DELETE').then(function () {
            dispatch(removeReportRecipient(id));
            return id;
        });
    };
};
var initialState = [];
export default function reportRecipients(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_REPORT_RECIPIENTS_REQUEST:
            return initialState;
        case FETCH_REPORT_RECIPIENTS_SUCCESS:
            return action.reportRecipients;
        case ADD_REPORT_RECIPIENT:
            return state.concat(action.reportRecipient);
        case REMOVE_REPORT_RECIPIENT:
            return state.filter(function (reportRecipient) {
                return reportRecipient.id !== action.id;
            });
        default:
            return state;
    }
}
export var fetchReportRecipientsRequest = function () { return ({
    type: FETCH_REPORT_RECIPIENTS_REQUEST,
}); };
export var fetchReportRecipientsSuccess = function (reportRecipients) { return ({
    type: FETCH_REPORT_RECIPIENTS_SUCCESS,
    reportRecipients: reportRecipients,
}); };
export var addReportRecipient = function (reportRecipient) { return ({
    type: ADD_REPORT_RECIPIENT,
    reportRecipient: reportRecipient,
}); };
export var removeReportRecipient = function (id) { return ({
    type: REMOVE_REPORT_RECIPIENT,
    id: id,
}); };
