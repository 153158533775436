import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteCategoryModal from '@/components/categories/delete_category_modal';
import { destroyTeamCategory } from '@/actions/category';
import { rejectRecentCategories } from '@/modules/recent_categories';
import { setCurrentCategory } from '@/modules/my_task';
import { removeDangerNotificationMessage } from '@/modules/notification';
var DeleteCategoryModalContainer = function (_a) {
    var categories = _a.categories, category = _a.category, isOpen = _a.isOpen, toggle = _a.toggle;
    var currentCategory = useSelector(function (state) { return state.myTask.currentCategory; });
    var dispatch = useDispatch();
    var handleDelete = function (category) {
        return dispatch(destroyTeamCategory(category.team_id, category.id));
    };
    var handleRemoveCategoryFromRecentCategories = function (category) {
        dispatch(rejectRecentCategories(category));
    };
    var handleRemoveCurrentCategory = function () {
        dispatch(setCurrentCategory(undefined));
    };
    var handleToggle = function () {
        dispatch(removeDangerNotificationMessage());
        toggle();
    };
    return (<DeleteCategoryModal categories={categories} category={category} currentCategory={currentCategory} isOpen={isOpen} toggle={handleToggle} onDelete={handleDelete} onRemoveCategoryFromRecentCategories={handleRemoveCategoryFromRecentCategories} onRemoveCurrentCategory={handleRemoveCurrentCategory}/>);
};
export default DeleteCategoryModalContainer;
