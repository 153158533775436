import React from 'react';
import I18n from '@/constants/i18n';
import TeamListContainer from '@/containers/team_children/team_list_container';
import NewChildTeamFormContainer from '@/containers/team_children/new_child_team_form_container';
import EditChildTeamFormContainer from '@/containers/team_children/edit_child_team_form_container';
import DeleteChildTeamModalContainer from '@/containers/delete_child_team_modal_container';
var TeamChildrenForm = function (props) {
    var team = props.team;
    return (<>
      <h2 className="mb-2">{team.name}</h2>
      <div className="mb-4">
        <table className="table table-bordered">
          <thead>
            <tr>
              <td>{I18n.t('model.attributes.team.name')}</td>
              <td />
            </tr>
          </thead>
          <TeamListContainer team={team}/>
        </table>
      </div>
      <div className="mb-4">
        <NewChildTeamFormContainer team={team}/>
      </div>
      <div>
        <EditChildTeamFormContainer team={team}/>
      </div>
      <DeleteChildTeamModalContainer />
    </>);
};
export default TeamChildrenForm;
