import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TeamInvitationTabContent } from '@/components/team_memberships_form_modal/team_invitation_tab_content';
import { fetchWorkspacesInvitations, renewWorkspacesInvitation, } from '@/modules/workspaces_invitations';
var WorkspacesTeamInvitationContainer = function (_a) {
    var team = _a.team;
    var workspaces = useSelector(function (state) { return state.workspaces; });
    var workspacesInvitations = useSelector(function (state) { return state.workspacesInvitations; });
    var dispatch = useDispatch();
    var handleFetchWorkspacesInvitations = React.useCallback(function (teamId) {
        return dispatch(fetchWorkspacesInvitations(teamId));
    }, [dispatch]);
    var handleRenewWorkspacesInvitation = function (id, teamId) {
        return dispatch(renewWorkspacesInvitation(id, teamId));
    };
    return (<TeamInvitationTabContent fetchWorkspacesInvitations={handleFetchWorkspacesInvitations} renewWorkspacesInvitation={handleRenewWorkspacesInvitation} team={team} workspaces={workspaces.items} workspacesInvitations={workspacesInvitations}/>);
};
export default WorkspacesTeamInvitationContainer;
