import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import Avatar from '@/components/atoms/avatar';
import { useNavigate } from 'react-router-dom';
import Icon from '../atoms/icon';
var VISIBLE_TEAM_ICON_COUNT = 5;
export var TeamMembershipsModal = function (_a) {
    var user = _a.user, teams = _a.teams, membershipByTeamId = _a.membershipByTeamId, toggleTeamMenu = _a.toggleTeamMenu;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var navigate = useNavigate();
    var handleToggle = function () {
        setIsOpen(!isOpen);
    };
    var belongsTeams = teams.filter(function (team) {
        var _a;
        return (_a = membershipByTeamId[team.id]) === null || _a === void 0 ? void 0 : _a.some(function (membership) { return membership.user.id === user.id; });
    });
    var onClickLink = function (e) {
        e.preventDefault();
        toggleTeamMenu(false);
        navigate(e.currentTarget.getAttribute('href'));
    };
    return (<>
      <div className="pt-2">
        {belongsTeams.slice(0, VISIBLE_TEAM_ICON_COUNT).map(function (team) { return (<a key={team.id} href={"/app/workspaces/".concat(team.workspaces_workspace_id, "/teams/").concat(team.id, "/members")} onClick={onClickLink}>
            <Avatar className="mr-2 border-0" image={team.avatar_url} size="sm"/>
          </a>); })}
        {belongsTeams.length > VISIBLE_TEAM_ICON_COUNT && (<>
            <Icon name="dots-3"/>
            <br />
            <button className="btn btn-link p-0 mt-2" onClick={handleToggle}>
              {"+".concat(I18n.t('shared.nTeams', { num: belongsTeams.length - VISIBLE_TEAM_ICON_COUNT }))}
            </button>
          </>)}
      </div>

      <Modal isOpen={isOpen} toggle={handleToggle}>
        <ModalHeaderMod toggle={handleToggle}>
          <div className="mt-1">
            {I18n.t('shared.allBelongTeams')}
            <Avatar className="ml-2 mr-1" image={user.avatar_url}/>
            {user.nickname}
          </div>
        </ModalHeaderMod>
        <ModalBody className="px-2 pt-0 pb-2 mx-1">
          <ul className="list-unstyled">
            {belongsTeams.map(function (team) { return (<li key={team.id} className="mb-2">
                <a href={"/app/workspaces/".concat(team.workspaces_workspace_id, "/teams/").concat(team.id, "/members")} onClick={onClickLink}>
                  <Avatar className="mr-1 border-0" image={team.avatar_url}/>
                  {team.name}
                </a>
              </li>); })}
          </ul>
        </ModalBody>
      </Modal>
    </>);
};
