import { __assign } from "tslib";
import { connect } from 'react-redux';
import { createTimeEntry } from '@/modules/time_entries';
import NewTimeEntryForm from '@/components/task_edit/new_time_entry_form';
import { fetchTask } from '@/api2/tasks';
import { fetchTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { user: state.user, openURL: state.openURL, timeEntrySuggestions: state.timeEntrySuggestions.items })); };
var mapDispatchToProps = function (dispatch) { return ({
    onCreate: function (params) {
        return dispatch(createTimeEntry(params));
    },
    onFetchTask: function (id, params) {
        dispatch(fetchTask(id, params));
    },
    fetchTimeEntrySuggestion: function (time) {
        dispatch(fetchTimeEntrySuggestion(time));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(NewTimeEntryForm);
