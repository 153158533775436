import { __assign } from "tslib";
// Actions
export var TOGGLE_TEAM_MANAGEMENT_SUCCESS_MESSAGE = 'timecrowd/team_management/TOGGLE_TEAM_MANAGEMENT_SUCCESS_MESSAGE';
export var SET_TEAM_MANAGEMENT_SELECTED_PAGE = 'timecrowd/team_management/SET_TEAM_MANAGEMENT_SELECTED_PAGE';
export var TOGGLE_TEAM_MENU = 'timecrowd/team_management/TOGGLE_TEAM_MENU';
// Reducer
var initialState = {
    isSuccessMessageShown: false,
    successMessage: '',
    selectedPage: null,
    openTeamMenu: true,
};
export default function teamManagement(state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case TOGGLE_TEAM_MANAGEMENT_SUCCESS_MESSAGE:
            return __assign(__assign({}, state), { isSuccessMessageShown: !state.isSuccessMessageShown, successMessage: action.message });
        case SET_TEAM_MANAGEMENT_SELECTED_PAGE:
            return __assign(__assign({}, state), { selectedPage: action.selectedPage });
        case TOGGLE_TEAM_MENU:
            return __assign(__assign({}, state), { openTeamMenu: (_a = action.isOpen) !== null && _a !== void 0 ? _a : !state.openTeamMenu });
        default:
            return state;
    }
}
// Action Creators
export var toggleTeamManagementSuccessMessage = function (message) { return ({
    type: TOGGLE_TEAM_MANAGEMENT_SUCCESS_MESSAGE,
    message: message,
}); };
export var setTeamManagementSelectedPage = function (selectedPage) { return ({
    type: SET_TEAM_MANAGEMENT_SELECTED_PAGE,
    selectedPage: selectedPage,
}); };
export var toggleTeamMenu = function (isOpen) { return ({
    type: TOGGLE_TEAM_MENU,
    isOpen: isOpen,
}); };
