export function numberWithDelimiter(num) {
    if (isNaN(num)) {
        return '0';
    }
    var parts = String(num).split('.');
    parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return parts.join('.');
}
export var padZero = function (num) {
    var s = "0".concat(num);
    var l = s.length > 3 ? s.length - 1 : 2;
    return s.slice(-l);
};
export var fullToHalf = function (text) {
    return text
        .replace(/[０-９]/g, function (s) { return String.fromCharCode(s.charCodeAt(0) - 65248); })
        .replace(/[。．]/g, '.')
        .replace(/[^\d.]/g, '');
};
