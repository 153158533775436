import React, { useState, useEffect } from 'react';
import I18n from '@/constants/i18n';
import Alert from '@/components/alert';
import CustomCheck from '@/components/atoms/custom_check';
var PaymentAlert = function (_a) {
    var team = _a.team, message = _a.message, isOpen = _a.isOpen, onClose = _a.onClose, onPay = _a.onPay, openURL = _a.openURL;
    var _b = useState(false), isChecked = _b[0], setIsChecked = _b[1];
    var handleClose = function () {
        onClose(team, isChecked);
        initializeCheckBox();
    };
    var initializeCheckBox = function () {
        setIsChecked(false);
    };
    useEffect(initializeCheckBox, [team]);
    return (<Alert isHtml color="warning" isOpen={isOpen} message={message} onClose={handleClose}>
      {team.root && team.can_manage && (<div className="mt-2">
          <CustomCheck checked={isChecked} onChange={function () { return setIsChecked(!isChecked); }}>
            {I18n.t('shared.dontShowThisMessage')}
          </CustomCheck>
          <div className="mt-2">
            <button className="mr-1 btn btn-primary" onClick={onPay}>
              {I18n.t('shared.subscribePaidPlan')}
            </button>
            <button className="mr-1 btn btn-secondary" type="button" onClick={function () {
                openURL('https://timecrowd.net/contacts/new');
            }}>
              {I18n.t('shared.contactForExtendTrial')}
            </button>
            <button className="btn btn-secondary" type="button" onClick={handleClose}>
              {I18n.t('shared.close')}
            </button>
          </div>
        </div>)}
    </Alert>);
};
export default PaymentAlert;
