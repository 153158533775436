import React from 'react';
import { WorkspacesSettingsLayout } from '@/components/workspaces_settings_layout';
import I18n from '@/constants/i18n';
import variants from '@/constants/variants';
import { formatDate, remainingDays } from '@/utils/time_helper';
import { PaymentForm } from '@/components/payment/payment_form';
import LoadingSpinner from '@/components/atoms/loading_spinner';
var LEGACY_STANDARD_PRICE = 880;
export var WorkspacesPlanForm = function (props) {
    var workspace = props.workspace, payment = props.payment, user = props.user, _a = props.isChromeExtension, isChromeExtension = _a === void 0 ? variants.isChromeExtension : _a, _b = props.openURL, openURL = _b === void 0 ? variants.openURL : _b, openModal = props.openModal;
    var isTrial = workspace.plan_type !== 'personal' &&
        workspace.is_trial &&
        !workspace.premium;
    var planName = workspace.plan_type === 'legacy_light' &&
        payment.item.unit_price === LEGACY_STANDARD_PRICE
        ? 'legacy_standard'
        : workspace.plan_type;
    return (<WorkspacesSettingsLayout active="plan" title={"".concat(I18n.t('shared.workspace'), " ").concat(I18n.t('shared.plan'))}>
      <p className="mb-1 small">{I18n.t('shared.currentPlan')}</p>
      <p className="h3 font-weight-normal">
        {I18n.t("shared.workspacePlans.".concat(isTrial ? 'enterprise_trial' : planName))}
      </p>

      {workspace.plan_type === 'legacy_light' ? (<>
          {payment.isLoading ? (<div className="py-4 text-center">
              <LoadingSpinner size="md"/>
            </div>) : (<div className="mt-3">
              <PaymentForm isChromeExtension={isChromeExtension} openModal={openModal} openURL={openURL} payment={payment.item} user={user}/>
            </div>)}
        </>) : (<>
          {isTrial && (<>
              <p className="mt-5">
                <span>{I18n.t('shared.trialEndsDate')}</span>
                <span className="text-danger">
                  <span className="h2 font-weight-normal ml-3">
                    {formatDate(workspace.trial_ends_at)}
                  </span>
                  <span className="ml-1">
                    {I18n.t('shared.trialRemainingDays', {
                    days: remainingDays(workspace.trial_ends_at),
                })}
                  </span>
                </span>
              </p>

              <p className="mt-5" dangerouslySetInnerHTML={{
                    __html: I18n.t('shared.workspacePlanNotes.trial'),
                }}/>

              <button className="btn btn-primary mt-2" onClick={function () {
                    openURL('https://timecrowd.net/contacts/new');
                }}>
                {I18n.t('shared.contactSupport')}
              </button>
            </>)}

          {!/^legacy_/.test(workspace.plan_type) && (<p className="mt-5" dangerouslySetInnerHTML={{
                    __html: I18n.t("shared.workspacePlanNotes.".concat(workspace.plan_type === 'personal' ? 'personal' : 'general')),
                }}/>)}
        </>)}
    </WorkspacesSettingsLayout>);
};
