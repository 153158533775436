import { __assign } from "tslib";
// Actions
export var FETCH_CATEGORIES_REQUEST = 'timecrowd/quick_task/FETCH_CATEGORIES_REQUEST';
export var FETCH_CATEGORIES_SUCCESS = 'timecrowd/quick_task/FETCH_CATEGORIES_SUCCESS';
export var FETCH_CATEGORIES_FAIL = 'timecrowd/quick_task/FETCH_CATEGORIES_FAIL';
var initialState = {
    isLoading: false,
};
export default function quickTask(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CATEGORIES_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_CATEGORIES_SUCCESS:
        case FETCH_CATEGORIES_FAIL:
            return __assign(__assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
// Action Creators
export var fetchCategoriesRequest = function () { return ({
    type: FETCH_CATEGORIES_REQUEST,
}); };
export var fetchCategoriesSuccess = function () { return ({
    type: FETCH_CATEGORIES_SUCCESS,
}); };
export var fetchCategoriesFail = function () { return ({
    type: FETCH_CATEGORIES_FAIL,
}); };
