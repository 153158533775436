export var parseWorkspaceId = function (location) {
    var _a;
    return (Number((_a = "".concat(location.pathname).concat(location.hash).match(/\/app\/workspaces\/(\d+)/)) === null || _a === void 0 ? void 0 : _a[1]) || null);
};
export var workspacePath = function (path, location) {
    if (location === void 0) { location = window.location; }
    var workspaceId = parseWorkspaceId(location);
    return "/app".concat(workspaceId ? "/workspaces/".concat(workspaceId) : '').concat(path);
};
export var workspaceTeams = function (teams, location) {
    if (location === void 0) { location = window.location; }
    var workspaceId = parseWorkspaceId(location);
    if (workspaceId) {
        return teams.filter(function (team) { return team.workspaces_workspace_id === workspaceId; });
    }
    else {
        return teams;
    }
};
export var sortWorkspaces = function (workspaces) {
    return workspaces.sort(function (a, b) {
        return a.name.localeCompare(b.name);
    });
};
export var sortTeamsByWorkspace = function (teams, workspaces) {
    if (!teams.length || !workspaces.length) {
        return teams;
    }
    var sortedWorkspaces = sortWorkspaces(workspaces);
    var indexByWorkspaceId = sortedWorkspaces.reduce(function (acc, workspace, index) {
        acc[workspace.id] = index;
        return acc;
    }, {});
    return teams.sort(function (a, b) {
        var _a, _b;
        return ((_a = indexByWorkspaceId[a.workspaces_workspace_id]) !== null && _a !== void 0 ? _a : 0) -
            ((_b = indexByWorkspaceId[b.workspaces_workspace_id]) !== null && _b !== void 0 ? _b : 0);
    });
};
