import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
export var FETCH_WORKSPACES_CHARGES_REQUEST = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_CHARGES_REQUEST';
export var FETCH_WORKSPACES_CHARGES_SUCCESS = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_CHARGES_SUCCESS';
export var FETCH_WORKSPACES_CHARGES_FAILURE = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_CHARGES_FAILURE';
export var initialState = {
    isLoading: false,
    isLoaded: false,
    items: [],
};
export default function workspacesCharges(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_WORKSPACES_CHARGES_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isLoaded: false });
        case FETCH_WORKSPACES_CHARGES_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, isLoading: false, isLoaded: true });
        case FETCH_WORKSPACES_CHARGES_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
export var fetchWorkspacesChargesRequest = function () { return ({
    type: FETCH_WORKSPACES_CHARGES_REQUEST,
}); };
export var fetchWorkspacesChargesSuccess = function (items) { return ({
    type: FETCH_WORKSPACES_CHARGES_SUCCESS,
    payload: items,
}); };
export var fetchWorkspacesChargesFailure = function () { return ({
    type: FETCH_WORKSPACES_CHARGES_FAILURE,
}); };
export var fetchWorkspacesCharges = function () {
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesChargesRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/charges", 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchWorkspacesChargesSuccess(items));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchWorkspacesChargesFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
