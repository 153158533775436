import { __assign } from "tslib";
import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import classNames from 'classnames';
import TimelineEntryContainer from '@/containers/timeline_entry_container';
import TaskInline from '@/components/organisms/task_inline';
import TimelineEntryNew from '@/components/organisms/timeline_entry_new';
import { padZero } from '@/utils/number_helper';
import { TimelineCalendarEvents } from '@/components/timeline_calendar_events';
import { fitTimesWithinDate } from './helpers';
var Timeline = function (props) {
    var toggleTaskForm = props.toggleTaskForm, date = props.date, _a = props.divisionHeight, divisionHeight = _a === void 0 ? 24 : _a, _b = props.subdivisions, subdivisions = _b === void 0 ? 2 : _b, _c = props.initialScrollTo, initialScrollTo = _c === void 0 ? 9 : _c, className = props.className, _d = props.tag, Tag = _d === void 0 ? 'div' : _d, currentCategory = props.currentCategory, _e = props.entries, entries = _e === void 0 ? [] : _e, _f = props.readOnly, readOnly = _f === void 0 ? false : _f, activeTaskForm = props.activeTaskForm, selectedDay = props.selectedDay, isMobile = props.isMobile, isApp = props.isApp, isChromeExtension = props.isChromeExtension, currentUser = props.currentUser, showsCalendarEvents = props.showsCalendarEvents, calendarIntegrationEvents = props.calendarIntegrationEvents, teams = props.teams, view = props.view, taskSearch = props.taskSearch, isShowCategoryOnWeeklyCalendar = props.isShowCategoryOnWeeklyCalendar, calendarUnitMinutes = props.calendarUnitMinutes, overlaps = props.overlaps;
    var _g = React.useState(undefined), draggedOn = _g[0], setDraggedOn = _g[1];
    var _h = React.useState(0), nowDateTop = _h[0], setNowDateTop = _h[1];
    var containerRef = React.useRef(null);
    var timelineRef = React.useRef(null);
    var _j = React.useState(0), timelineWidth = _j[0], setTimelineWidth = _j[1];
    var leftMarginRate = 0.25;
    var overlapCount = 0;
    var handleToggleTaskForm = function (index) {
        toggleTaskForm(index);
    };
    var handleDrag = function (draggedOn) {
        setDraggedOn(draggedOn);
    };
    var handleCancelDrag = function () {
        setDraggedOn(undefined);
    };
    var nowDate = React.useCallback(function () {
        var now = new Date();
        var today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
        var selectDate = date.split('-');
        selectDate[1]--;
        var nowDateTop;
        if (today === new Date(selectDate[0], selectDate[1], selectDate[2]).getTime()) {
            var ms = new Date().getTime() - today;
            nowDateTop = (ms / 1000 / 900) * divisionHeight;
        }
        else {
            nowDateTop = -1;
        }
        setNowDateTop(nowDateTop);
    }, [date, divisionHeight]);
    var initScroll = React.useCallback(function () {
        var scrollSize = divisionHeight * subdivisions * initialScrollTo - 6;
        setTimeout(function () { return window.scrollTo(0, scrollSize); }, 0);
    }, [divisionHeight, subdivisions, initialScrollTo]);
    React.useEffect(function () {
        nowDate();
        initScroll();
        setInterval(nowDate, 60000);
    }, [nowDate, initScroll]);
    React.useEffect(function () {
        if (isMobile || isApp) {
            if (activeTaskForm === -1) {
                initScroll();
            }
            else {
                window.scrollTo(0, 0);
            }
        }
    }, [isMobile, isApp, activeTaskForm, initScroll]);
    React.useEffect(function () {
        var element = timelineRef.current;
        if (element) {
            var resizeObserver_1 = new ResizeObserver(function (entries) {
                setTimelineWidth(entries[0].contentRect.width);
            });
            resizeObserver_1.observe(element);
            return function () { return resizeObserver_1.disconnect(); };
        }
    }, []);
    var classes = classNames('timeline w-100', className, {
        'open-task-form': activeTaskForm !== -1,
    });
    var hours = range(24).map(function (hour) {
        return hour < 10 ? '0' + hour : hour.toString();
    });
    var isOwner = teams
        .filter(function (_a) {
        var can_manage = _a.can_manage;
        return can_manage;
    })
        .reduce(function (hash, team) {
        var _a;
        return (__assign(__assign({}, hash), (_a = {}, _a[team.id] = true, _a)));
    }, {});
    return (<Tag ref={containerRef} className={classes}>
      <div className="timeline-nowdate-line" style={{ top: nowDateTop }}/>
      <div className="d-flex">
        <div ref={timelineRef} className="w-100 position-relative">
          {hours.map(function (hour, index) {
            return (<div key={"timeline-hours-".concat(hour)} className={['timeline-hour', 'timeline-hour-' + index].join(' ')}>
                <div className="timeline-hour-body">
                  {range(subdivisions).map(function (item, subindex) {
                    var _a, _b;
                    var minute = calendarUnitMinutes * subindex;
                    var started_at = moment("".concat(date, " ").concat(hour, ":").concat(padZero(minute)));
                    var stopped_at = started_at
                        .clone()
                        .add((_b = (_a = currentCategory === null || currentCategory === void 0 ? void 0 : currentCategory.team) === null || _a === void 0 ? void 0 : _a.default_duration) !== null && _b !== void 0 ? _b : 15, 'm');
                    return (<TimelineEntryNew key={"timeline-entry-new-".concat(hour, "-").concat(subindex)} calendarUnitMinutes={calendarUnitMinutes} currentCategory={currentCategory} date={date} draggedOn={draggedOn} height={divisionHeight} index={"".concat(index, "-").concat(subindex, "-").concat(date)} isApp={isApp} isMobile={isMobile} isOpen={activeTaskForm === "".concat(index, "-").concat(subindex, "-").concat(date)} readOnly={readOnly} started_at={started_at} stopped_at={stopped_at} taskSearch={taskSearch} toggleTaskForm={handleToggleTaskForm} onCancelDrag={handleCancelDrag} onDrag={handleDrag}/>);
                })}
                </div>
              </div>);
        })}
          {orderBy(entries, ['started_at', 'duration'], ['asc', 'desc']).map(function (entry, i) {
            var _a, _b;
            var _c = fitTimesWithinDate(date, entry), startedAt = _c.startedAt, stoppedAt = _c.stoppedAt;
            var duration = stoppedAt.diff(startedAt, 'seconds');
            var heightPerMinutes = divisionHeight / calendarUnitMinutes;
            var top = startedAt.hours() * subdivisions * divisionHeight +
                startedAt.minutes() * heightPerMinutes;
            var minutes = Math.ceil(duration / 60);
            var height = minutes * heightPerMinutes;
            var index = "".concat(startedAt.hours(), "-").concat(i, "-time-entry-").concat(date);
            var isOverlap = overlaps[entry.id];
            if (isOverlap) {
                overlapCount += 1;
            }
            else {
                overlapCount = 0;
            }
            var isEven = overlapCount > 0 && overlapCount % 2 === 0;
            var left = isEven ? timelineWidth * leftMarginRate : 4;
            var width = isOverlap
                ? "".concat(timelineWidth - timelineWidth * leftMarginRate - 4, "px")
                : "".concat(timelineWidth - 8, "px");
            return (<TimelineEntryContainer key={"timeline-entry-".concat(entry.id)} calendarDate={date} calendarUnitMinutes={calendarUnitMinutes} divisionHeight={divisionHeight} divisionWidth={(_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== null && _b !== void 0 ? _b : 1} height={height > divisionHeight ? height : divisionHeight} index={"".concat(index)} isApp={isApp} isChromeExtension={isChromeExtension} isMine={currentUser && currentUser.id === entry.user.id} isMobile={isMobile} isOpen={activeTaskForm === "".concat(index)} left={left} readOnly={currentUser.id !== entry.user.id && !isOwner[entry.team_id]} selectedDay={selectedDay} timeEntry={entry} toggleTaskForm={handleToggleTaskForm} top={top} width={width}>
                  <TaskInline className={"timeline-task-inline category-".concat(entry.task.category.color)} isOverlap={isOverlap} isShowCategoryOnWeeklyCalendar={isShowCategoryOnWeeklyCalendar} isSingleLineTitle={isShowCategoryOnWeeklyCalendar &&
                    minutes < calendarUnitMinutes * 2} task={entry.task} timeEntry={entry} view={view}/>
                </TimelineEntryContainer>);
        })}
        </div>
        <TimelineCalendarEvents calendarIntegrationEvents={calendarIntegrationEvents} calendarUnitMinutes={calendarUnitMinutes} date={date} divisionHeight={divisionHeight} hours={hours} isShown={showsCalendarEvents} subdivisions={subdivisions} toggleTaskForm={handleToggleTaskForm}/>
      </div>
    </Tag>);
};
export default Timeline;
