import React from 'react';
import I18n from '@/constants/i18n';
export var CustomFieldsUnitField = function (_a) {
    var customFieldsMaster = _a.customFieldsMaster, onChangeCustomFieldsMaster = _a.onChangeCustomFieldsMaster, className = _a.className;
    if (!['number', 'expression'].includes(customFieldsMaster.field_type)) {
        return null;
    }
    return (<div className={className}>
      {I18n.t('shared.customFieldsUnit')}
      <div className="mt-2">
        <input className="form-control" type="text" value={customFieldsMaster.unit} onChange={function (e) { return onChangeCustomFieldsMaster('unit', e.target.value); }}/>
      </div>
    </div>);
};
