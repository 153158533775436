// NOTE: コード内では呼んでいないが、variantsから設定されるので必要
export default function token(state, action) {
    if (state === void 0) { state = null; }
    switch (action.type) {
        case 'SET_TOKEN':
            return action.token;
        default:
            return state;
    }
}
