import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import I18n from '@/constants/i18n';
import { numberWithDelimiter } from '@/utils/number_helper';
import ExpansionPanelBody from '@/components/atoms/expansion_panel_body';
import ExpansionPanel from '@/components/atoms/expansion_panel';
import ExpansionPanelHeader from '@/components/molecules/expansion_panel_header';
import { WithTooltip } from './team_report_details/with_tooltip';
import CompareBar from '@/components/atoms/compare_bar';
import { colors } from './helpers';
import { CategoryInfoContainer } from '@/containers/category_info_container';
import { workspacePath } from '@/utils/workspace_helper';
import { formatDuration } from '@/utils/time_helper';
export function renderOrderedNestedExpansionList(allCategories, currentCategories, users, showsTooltip, categoriesForInfo, categoryTotals, taskTotals, totalDuration, tasksByCategoryId, childCategoriesByParentId, timeEntries) {
    return currentCategories.map(function (category) {
        return renderNestedExpansionList(allCategories, category, users, showsTooltip, categoriesForInfo, categoryTotals, taskTotals, totalDuration, tasksByCategoryId, childCategoriesByParentId, timeEntries);
    });
}
export function renderNestedExpansionList(categories, parentCategory, users, showsTooltip, categoriesForInfo, categoryTotals, taskTotals, totalDuration, tasksByCategoryId, childCategoriesByParentId, timeEntries) {
    var _a;
    var _b = (_a = categoryTotals[parentCategory.id]) !== null && _a !== void 0 ? _a : {
        duration: 0,
        amount: 0,
    }, time = _b.duration, amount = _b.amount;
    var h = Math.floor(time / 3600);
    var m = Math.floor((time - h * 3600) / 60);
    if (time === 0) {
        return <div />;
    }
    return (<NestedExpansionList key={"nested-expansion-list-".concat(parentCategory.id)} amount={amount} categories={categories} categoriesForInfo={categoriesForInfo} categoryTotals={categoryTotals} childCategoriesByParentId={childCategoriesByParentId} h={h} m={m} parentCategory={parentCategory} showsTooltip={showsTooltip} tasksByCategoryId={tasksByCategoryId} taskTotals={taskTotals} time={time} timeEntries={timeEntries} totalDuration={totalDuration} users={users}/>);
}
export function NestedExpansionList(_a) {
    var _b;
    var categories = _a.categories, parentCategory = _a.parentCategory, amount = _a.amount, time = _a.time, h = _a.h, m = _a.m, users = _a.users, showsTooltip = _a.showsTooltip, categoriesForInfo = _a.categoriesForInfo, categoryTotals = _a.categoryTotals, taskTotals = _a.taskTotals, totalDuration = _a.totalDuration, tasksByCategoryId = _a.tasksByCategoryId, childCategoriesByParentId = _a.childCategoriesByParentId, timeEntries = _a.timeEntries;
    if (time === 0) {
        return <div />;
    }
    var tasks = (_b = tasksByCategoryId[parentCategory.id]) !== null && _b !== void 0 ? _b : [];
    var childCategories = childCategoriesByParentId[parentCategory.id] || [];
    return (<ExpansionPanel className={classNames('report-category', 'category-' + parentCategory.color)}>
      {function (expand, toggle) { return (<>
          <ExpansionPanelHeader action expand={expand} toggle={toggle}>
            <label className="expansion-panel-label report-category-header">
              <span className="report-category-title">
                {parentCategory.title}
                <CategoryInfoContainer requiresCustomFields categories={categoriesForInfo} category={parentCategory} className="mx-1"/>
              </span>
              <WithTooltip categories={categories} category={parentCategory} showsTooltip={showsTooltip} targetId={"team-report-detail-tooltip-".concat(parentCategory.id)} tasksByCategoryId={tasksByCategoryId} timeEntries={timeEntries} users={users}>
                <span className="report-category-hours text-right">
                  {h}
                  {I18n.t('shared.hours')}
                  {m}
                  {I18n.t('shared.minutes')}
                </span>
                <span className="report-category-amount text-right">
                  ¥{numberWithDelimiter(amount)}
                </span>
                <span className="report-category-bar pl-2">
                  <CompareBar color={colors[parentCategory.color - 1]} maxWidthPx={100} percent={(time / totalDuration) * 100}/>
                </span>
              </WithTooltip>
            </label>
          </ExpansionPanelHeader>
          <ExpansionPanelBody expand={expand}>
            {!!childCategories.length &&
                renderOrderedNestedExpansionList(categories, childCategories, users, showsTooltip, categoriesForInfo, categoryTotals, taskTotals, totalDuration, tasksByCategoryId, childCategoriesByParentId, timeEntries)}
            {!!tasks.length && (<div className="list-group report-task-list">
                {tasks.map(function (task) {
                    var _a, _b, _c, _d;
                    return (<Link key={"link-".concat(task.id)} to={workspacePath("/tasks/".concat(task.id, "/edit"))}>
                    <div key={task.id} className="list-group-item report-task">
                      <span className="report-task-title" id={"report-task-title-".concat(task.id)}>
                        {task.title}
                      </span>
                      <WithTooltip categories={categories} showsTooltip={showsTooltip} targetId={"team-report-detail-tooltip-".concat(task.id)} task={task} tasksByCategoryId={tasksByCategoryId} timeEntries={timeEntries} users={users}>
                        <span className="report-task-hours text-right">
                          {formatDuration((_b = (_a = taskTotals[task.id]) === null || _a === void 0 ? void 0 : _a.duration) !== null && _b !== void 0 ? _b : 0)}
                        </span>
                        <span className="report-task-amount text-right">
                          ¥
                          {numberWithDelimiter((_d = (_c = taskTotals[task.id]) === null || _c === void 0 ? void 0 : _c.amount) !== null && _d !== void 0 ? _d : 0)}
                        </span>
                        <span className="report-task-bar pl-2"/>
                      </WithTooltip>
                    </div>
                  </Link>);
                })}
              </div>)}
          </ExpansionPanelBody>
        </>); }}
    </ExpansionPanel>);
}
