import React from 'react';
import I18n from '@/constants/i18n';
export var ReminderMessageField = function (_a) {
    var index = _a.index, reminderEmailsSetting = _a.reminderEmailsSetting, onChangeReminderEmailsSetting = _a.onChangeReminderEmailsSetting;
    return (<div className="row mt-3">
      <div className="col-3">{I18n.t('shared.reminderMessage')}</div>
      <div className="col">
        {reminderEmailsSetting.reminder_type === 'general' ? (<textarea className="form-control" rows={3} value={reminderEmailsSetting.message} onChange={function (e) {
                return onChangeReminderEmailsSetting(index, { message: e.target.value });
            }}/>) : (<span dangerouslySetInnerHTML={{
                __html: I18n.t("shared.reminderMessages.".concat(reminderEmailsSetting.reminder_type)),
            }}/>)}
      </div>
    </div>);
};
