import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import I18n from '@/constants/i18n';
import OmitLabel from '@/components/atoms/omit_label';
import { MultiCategoriesSelectList } from './multi_categories_select_list';
export var MultiCategoriesSelectDropdown = function (props) {
    var allCategories = props.allCategories, _a = props.disabled, disabled = _a === void 0 ? false : _a, selectedCategories = props.selectedCategories, teams = props.teams, workedCategoryIdSet = props.workedCategoryIdSet, onApply = props.onApply;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var handleToggle = function () {
        setIsOpen(!isOpen);
    };
    var handleApply = function (categories) {
        onApply(categories);
        setIsOpen(false);
    };
    return (<Dropdown isOpen={isOpen} toggle={handleToggle}>
      <DropdownToggle caret className={disabled ? '' : 'text-secondary'} color="dark" disabled={disabled}>
        {selectedCategories.length < 1 ? (I18n.t('shared.all')) : (<>
            <OmitLabel maxWidth="13rem">
              {selectedCategories[0].title}
            </OmitLabel>
            {selectedCategories.length > 1 &&
                " + ".concat(selectedCategories.length - 1)}
          </>)}
      </DropdownToggle>

      <DropdownMenu className="bg-white max-w-lg overflow-hidden p-0">
        <div className="d-flex flex-column max-h-[80vh]">
          <MultiCategoriesSelectList allCategories={allCategories} selectedCategories={selectedCategories} teams={teams} workedCategoryIdSet={workedCategoryIdSet} onApply={handleApply}/>
        </div>
      </DropdownMenu>
    </Dropdown>);
};
