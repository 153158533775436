import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n from '@/constants/i18n';
import { updateWorkspacePayment, deleteWorkspacePayment, } from '@/actions/workspace_payment';
import { updateWorkspaceSuccess } from '@/modules/workspaces';
import { setNotificationMessage } from '@/modules/notification';
import { EditPaymentForm } from '@/components/payment/edit_payment_form';
var EditPaymentFormContainer = function (_a) {
    var openModal = _a.openModal, user = _a.user;
    var team = useSelector(function (state) { return state.payment.item.team; });
    var workspace = useSelector(function (state) { return state.payment.item.workspace; });
    var payment = useSelector(function (state) { return state.payment.item; });
    var dispatch = useDispatch();
    var handleUpdate = function (_teamId, capacity) {
        var params = { capacity: capacity };
        dispatch(updateWorkspacePayment(params)).then(function (payment) {
            if (payment.error) {
                return;
            }
            dispatch(updateWorkspaceSuccess(payment.workspace));
            openModal(I18n.t('shared.capacityModalTitle'), I18n.t('shared.capacityModalBody'));
        });
    };
    var handleDelete = function () {
        dispatch(deleteWorkspacePayment()).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            dispatch(setNotificationMessage('success', I18n.t('shared.paymentDeleted')));
        });
    };
    return (<EditPaymentForm payment={payment} team={team} user={user} workspace={workspace} onDelete={handleDelete} onUpdate={handleUpdate}/>);
};
export default EditPaymentFormContainer;
