import React from 'react';
import { RAILS_ENV } from '@/constants/rails_env';
export var EnvMark = function () {
    if (RAILS_ENV === 'production' || RAILS_ENV === 'demo') {
        return null;
    }
    return (<div style={{
            position: 'fixed',
            background: 'rgba(255, 0, 0, 0.5)',
            right: 0,
            top: 0,
            zIndex: 9999,
            padding: '0 1rem',
            color: 'white',
        }}>
      {(RAILS_ENV || '').slice(0, 3)}
    </div>);
};
