import React from 'react';
import { numberWithDelimiter } from '@/utils/number_helper';
import CompareBar from '@/components/atoms/compare_bar';
import { formatDuration, calcRatio } from './helpers';
import Icon from '@/components/atoms/icon';
import { WithTooltipCell } from './with_tooltip_cell';
export var ActivityRow = function (_a) {
    var userId = _a.userId, totalDuration = _a.totalDuration, totalAmount = _a.totalAmount, summary = _a.summary, showsTooltip = _a.showsTooltip, onSelectUser = _a.onSelectUser;
    var user = summary.user, userDuration = summary.userDuration, userAmount = summary.userAmount;
    if (!user) {
        return null;
    }
    return (<tr>
      <td className="text-nowrap">
        <button className="p-0 btn btn-link" type="button" onClick={function () { return onSelectUser(user); }}>
          {user.nickname}
        </button>
      </td>
      <WithTooltipCell cellName="duration-value" className="text-nowrap" showsTooltip={showsTooltip} summary={summary} userId={userId}>
        <Icon className="text-muted mr-1" name="clock"/>
        {formatDuration(userDuration)}
      </WithTooltipCell>
      <WithTooltipCell cellName="duration-ratio" className="text-nowrap" showsTooltip={showsTooltip} summary={summary} userId={userId}>
        {calcRatio(userDuration, totalDuration)} %
      </WithTooltipCell>
      <WithTooltipCell cellName="duration-bar" showsTooltip={showsTooltip} summary={summary} userId={userId}>
        <CompareBar color="#4abaa4" percent={calcRatio(userDuration, totalDuration)}/>
      </WithTooltipCell>
      <WithTooltipCell cellName="amount-value" className="text-nowrap" showsTooltip={showsTooltip} summary={summary} userId={userId}>
        <span className="text-muted mr-1">¥</span>
        {numberWithDelimiter(userAmount)}
      </WithTooltipCell>
      <WithTooltipCell cellName="amount-ratio" className="text-nowrap" showsTooltip={showsTooltip} summary={summary} userId={userId}>
        {calcRatio(userAmount, totalAmount)} %
      </WithTooltipCell>
      <WithTooltipCell cellName="amount-bar" showsTooltip={showsTooltip} summary={summary} userId={userId}>
        <CompareBar color="#4abaa4" percent={calcRatio(userAmount, totalAmount)}/>
      </WithTooltipCell>
    </tr>);
};
