import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip, } from 'reactstrap';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
export var StartMonthDropdown = function (props) {
    var helpMessage = props.helpMessage, startMonth = props.startMonth, onChange = props.onChange;
    var helpRef = React.useRef(null);
    return (<div className="d-flex align-items-center">
      <UncontrolledDropdown>
        <DropdownToggle caret className="text-secondary" color="dark">
          <span className="mr-1">{I18n.t('shared.startMonth')}</span>
          {I18n.t('shared.month', { month: startMonth })}
        </DropdownToggle>
        <DropdownMenu>
          {Array.from({ length: 12 }, function (_, i) { return i + 1; }).map(function (month) { return (<DropdownItem key={month} active={startMonth === month} onClick={function () { return onChange(month); }}>
              {month}
            </DropdownItem>); })}
        </DropdownMenu>
      </UncontrolledDropdown>
      <span ref={helpRef} className="ml-1 text-primary">
        <Icon name="question"/>
      </span>
      <UncontrolledTooltip target={helpRef}>{helpMessage}</UncontrolledTooltip>
    </div>);
};
