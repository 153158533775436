import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '@/components/atoms/icon';
import { workspacePath } from '@/utils/workspace_helper';
var EditTaskButton = function (_a) {
    var task = _a.task;
    var navigate = useNavigate();
    var handleClick = function (e) {
        e.stopPropagation();
        navigate(workspacePath("/tasks/".concat(task.id, "/edit")));
    };
    return (<button className="btn btn-secondary btn-sm" onClick={handleClick}>
      <Icon name={task.category.archived ? 'list' : 'pencil'}/>
    </button>);
};
export default EditTaskButton;
