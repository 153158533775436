import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryTaskStructuresExports, createCategoryTaskStructuresExport, } from '@/modules/category_task_structures_exports';
import { CategoryTaskStructuresExportForm } from '@/components/category_task_structures_export_form';
import variants from '@/constants/variants';
var loadExportCharset = variants.loadExportCharset;
export var CategoryTaskStructuresExportContainer = function (_a) {
    var team = _a.team;
    var categoryTaskStructuresExports = useSelector(function (state) { return state.categoryTaskStructuresExports; });
    var dispatch = useDispatch();
    var handleCreateCategoryTaskStructuresExport = function (params) {
        dispatch(createCategoryTaskStructuresExport(params));
    };
    var handleFetchCategoryTaskStructuresExports = React.useCallback(function (teamId, nextPage) {
        dispatch(fetchCategoryTaskStructuresExports(teamId, nextPage));
    }, [dispatch]);
    var _b = React.useState('sjis'), defaultCharset = _b[0], setDefaultCharset = _b[1];
    var isOwner = team && team.can_manage;
    React.useEffect(function () {
        if (!isOwner) {
            return;
        }
        handleFetchCategoryTaskStructuresExports(team.id, 1);
        loadExportCharset(function (charset) {
            if (charset) {
                setDefaultCharset(charset);
            }
        });
    }, [handleFetchCategoryTaskStructuresExports, team.id, isOwner]);
    return (<CategoryTaskStructuresExportForm createCategoryTaskStructuresExport={handleCreateCategoryTaskStructuresExport} defaultCharset={defaultCharset} exports={categoryTaskStructuresExports.items} fetchCategoryTaskStructuresExports={handleFetchCategoryTaskStructuresExports} hasNext={categoryTaskStructuresExports.hasNext} isLoading={categoryTaskStructuresExports.isLoading} team={team}/>);
};
export default CategoryTaskStructuresExportContainer;
