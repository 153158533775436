import { __assign } from "tslib";
import React from 'react';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import I18n from '@/constants/i18n';
import { ClearableSearchField } from '@/components/clearable_search_field';
import ExpansionPanelBody from '@/components/atoms/expansion_panel_body';
import ExpansionPanelHeader from '@/components/molecules/expansion_panel_header';
import ExpansionPanel from '@/components/atoms/expansion_panel';
import { isIndeterminate } from '@/components/molecules/team_category_check_list/helpers';
import Avatar from '@/components/atoms/avatar';
import CustomCheck from '@/components/atoms/custom_check';
import { sortByEachRoots } from '@/modules/categories';
import ListGroupItemCheck from '@/components/atoms/list_group_item_check';
import { CustomSwitch } from '@/components/custom_switch';
export var MultiCategoriesSelectList = function (props) {
    var allCategories = props.allCategories, defaultSelectedCategories = props.selectedCategories, teams = props.teams, workedCategoryIdSet = props.workedCategoryIdSet, onApply = props.onApply;
    var _a = React.useState(defaultSelectedCategories), selectedCategories = _a[0], setSelectedCategories = _a[1];
    var _b = React.useState(''), searchText = _b[0], setSearchText = _b[1];
    var _c = React.useState(false), withArchived = _c[0], setWithArchived = _c[1];
    var _d = React.useState(false), withInactive = _d[0], setWithInactive = _d[1];
    // NOTE: アーカイブ/稼働済みが選択されていたらデフォルトでフラグをONにする
    React.useEffect(function () {
        var isSelectedArchived = false;
        var isSelectedInactive = false;
        for (var _i = 0, defaultSelectedCategories_1 = defaultSelectedCategories; _i < defaultSelectedCategories_1.length; _i++) {
            var category = defaultSelectedCategories_1[_i];
            if (category.archived) {
                isSelectedArchived = true;
            }
            if (!workedCategoryIdSet.has(category.id)) {
                isSelectedInactive = true;
            }
            if (isSelectedArchived && isSelectedInactive) {
                break;
            }
        }
        setWithArchived(isSelectedArchived);
        setWithInactive(isSelectedInactive);
    }, [defaultSelectedCategories, workedCategoryIdSet]);
    React.useEffect(function () {
        setSelectedCategories(defaultSelectedCategories);
    }, [defaultSelectedCategories]);
    var teamById = React.useMemo(function () {
        return teams.reduce(function (acc, team) {
            acc[team.id] = team;
            return acc;
        }, {});
    }, [teams]);
    var categoryById = React.useMemo(function () {
        return allCategories.reduce(function (acc, category) {
            acc[category.id] = category;
            return acc;
        }, {});
    }, [allCategories]);
    var filteredCategories = React.useMemo(function () {
        var matched = new Set();
        allCategories.forEach(function (category) {
            var _a, _b;
            var isMatchedKeyword = [
                teamById[category.team_id].name,
                category.title,
            ].some(function (text) { return text.includes(searchText); });
            var isMatchedArchived = withArchived || !category.archived;
            var isMatchedInactive = withInactive || workedCategoryIdSet.has(category.id);
            var isMatched = isMatchedKeyword && isMatchedArchived && isMatchedInactive;
            if (isMatched) {
                matched.add(category);
                ((_b = (_a = category.ancestry) === null || _a === void 0 ? void 0 : _a.split('/')) !== null && _b !== void 0 ? _b : []).forEach(function (id) {
                    matched.add(categoryById[Number(id)]);
                });
            }
        });
        return Array.from(matched);
    }, [
        allCategories,
        searchText,
        teamById,
        categoryById,
        workedCategoryIdSet,
        withArchived,
        withInactive,
    ]);
    var categoriesByTeamId = React.useMemo(function () {
        return filteredCategories.reduce(function (acc, category) {
            if (!acc[category.team_id]) {
                acc[category.team_id] = [];
            }
            acc[category.team_id].push(category);
            return acc;
        }, {});
    }, [filteredCategories]);
    var teamsWithCategories = React.useMemo(function () {
        return teams
            .filter(function (_a) {
            var id = _a.id;
            return categoriesByTeamId[id];
        })
            .map(function (team) {
            return __assign(__assign({}, team), { categories: categoriesByTeamId[team.id] });
        });
    }, [teams, categoriesByTeamId]);
    var handleSelectTeam = function (team, checked) {
        var isSelectedById = __assign(__assign({}, selectedCategories.reduce(function (acc, c) {
            acc[c.id] = true;
            return acc;
        }, {})), team.categories.reduce(function (acc, c) {
            acc[c.id] = !checked;
            return acc;
        }, {}));
        var newSelectedCategories = filteredCategories.filter(function (c) { return isSelectedById[c.id]; });
        setSelectedCategories(newSelectedCategories);
    };
    var handleSelectCategory = function (_team, category, checked) {
        var _a;
        var isSelectedById = __assign(__assign({}, selectedCategories.reduce(function (acc, c) {
            acc[c.id] = true;
            return acc;
        }, {})), (_a = {}, _a[category.id] = !checked, _a));
        var newSelectedCategories = filteredCategories.filter(function (c) { return isSelectedById[c.id]; });
        setSelectedCategories(newSelectedCategories);
    };
    var handleSelectAll = function () {
        setSelectedCategories(filteredCategories);
    };
    var handleDeselectAll = function () {
        setSelectedCategories([]);
    };
    var handleApply = function () {
        onApply(selectedCategories);
    };
    return (<>
      <ClearableSearchField className="mt-2 px-2" placeholderKey="searchByCategoryName" searchText={searchText} size="md" onChangeSearchText={setSearchText}/>
      <div className="mt-2 overflow-y-auto">
        <div className="text-muted border-top-1 px-2 py-1">
          <div className="small mb-1">{I18n.t('shared.showBelow')}</div>
          <div className="d-flex">
            <CustomSwitch checked={withArchived} onChange={function () { return setWithArchived(!withArchived); }}>
              {I18n.t('shared.archived')}
            </CustomSwitch>
            <CustomSwitch checked={withInactive} className="ml-2" onChange={function () { return setWithInactive(!withInactive); }}>
              {I18n.t('shared.inactive')}
            </CustomSwitch>
          </div>
        </div>

        {teamsWithCategories.map(function (team) {
            var selectedTeamCategories = selectedCategories.filter(function (category) { return category.team_id === team.id; });
            var checked = team.categories.length === selectedTeamCategories.length;
            var indeterminated = isIndeterminate(team.categories, selectedTeamCategories);
            return (<ExpansionPanel key={team.id} expand className="list-group-item-expansion">
              {function (expand, toggle) { return (<>
                  <ExpansionPanelHeader className="tw-cursor-default" expand={expand} toggle={toggle} onClick={function () { }}>
                    <div className="d-flex align-items-center">
                      <CustomCheck checked={checked} className="mr-1" indeterminate={indeterminated} onClick={function () {
                        handleSelectTeam(team, checked);
                    }}/>
                      <div className="!tw-cursor-pointer" onClick={function () {
                        handleSelectTeam(team, checked);
                    }}>
                        <Avatar className="mr-1 border-0" image={team.avatar_url}/>
                        <span className="text-overflow">{team.name}</span>
                      </div>
                    </div>
                  </ExpansionPanelHeader>
                  <ExpansionPanelBody expand={expand}>
                    <ul className="list-group list-group-flush">
                      {sortByEachRoots(team.categories).map(function (category) {
                        var checked = some(selectedCategories, {
                            id: category.id,
                        });
                        return (<ListGroupItemCheck key={category.id} checked={checked} className="border-bottom-0" style={{
                                paddingLeft: "".concat(category.ancestry_depth * 1.2, "rem"),
                            }} onChange={function () {
                                return handleSelectCategory(team, category, checked);
                            }}>
                            <div className={"d-flex align-items-center\n                  ".concat(workedCategoryIdSet.has(category.id) ? '' : 'text-muted')}>
                              <i className={"top-0 category-circle category-".concat(category.color)}/>
                              <div className="text-overflow" title={category.title}>
                                {category.title}
                              </div>
                              <span className="ml-auto text-nowrap">
                                {!workedCategoryIdSet.has(category.id) && (<span className={"\n                    small ".concat(category.archived ? 'mr-1' : '')}>
                                    {I18n.t('shared.inactive')}
                                  </span>)}
                                {category.archived && (<span className="badge badge-info ml-auto">
                                    {I18n.t('shared.archived')}
                                  </span>)}
                              </span>
                            </div>
                          </ListGroupItemCheck>);
                    })}
                    </ul>
                  </ExpansionPanelBody>
                </>); }}
            </ExpansionPanel>);
        })}
      </div>
      <hr className="w-100 mt-0"/>
      <div className="d-flex justify-content-between aling-items-center px-2 pb-2">
        <div className="d-flex align-items-center">
          <button className="btn btn-link p-0" onClick={handleSelectAll}>
            {I18n.t('shared.selectAll')}
          </button>
          <button className="btn btn-link p-0 ml-2" onClick={handleDeselectAll}>
            {I18n.t('shared.clear')}
          </button>
        </div>
        <button className="btn btn-primary" disabled={isEqual(selectedCategories, defaultSelectedCategories)} onClick={handleApply}>
          {I18n.t('shared.apply')}
        </button>
      </div>
    </>);
};
