import { __awaiter, __generator, __spreadArray } from "tslib";
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';
import { sortReportableUsers } from '@/modules/reportable_users';
import I18n from '@/constants/i18n';
export var NOT_FOUND = { notFound: true };
export var parseReportFromStorage = function (teams, periods, selected, fetchReportableUsers, defaults) { return __awaiter(void 0, void 0, Promise, function () {
    var defaultTeam, selectedTeam, defaultPeriod, selectedPeriod, users, selectedUsers, restoredUserIds_1, restoredUsers, selectedCategory, isShowArchived, withAllUsers, isSelectedMyReport;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                defaultTeam = teams.find(function (t) { return !t.payment_required; });
                selectedTeam = (selected &&
                    teams.find(function (t) { return t.id === selected.team.id && !t.payment_required; })) ||
                    defaultTeam;
                if (!selectedTeam) {
                    return [2 /*return*/, NOT_FOUND];
                }
                defaultPeriod = periods[0];
                selectedPeriod = selected && selected.period ? selected.period : defaultPeriod;
                return [4 /*yield*/, fetchReportableUsers({ team_id: selectedTeam.id })];
            case 1:
                users = _c.sent();
                selectedUsers = [];
                if (!users.error) {
                    restoredUserIds_1 = selected
                        ? flatten([selected.user]).map(function (_a) {
                            var id = _a.id;
                            return parseInt(id);
                        })
                        : [];
                    restoredUsers = users.filter(function (user) {
                        return restoredUserIds_1.includes(user.id);
                    });
                    selectedUsers = restoredUsers.length ? restoredUsers : users;
                }
                selectedCategory = (selected &&
                    selected.category &&
                    selectedTeam.categories.find(function (c) { return c.id === selected.category.id; })) ||
                    null;
                isShowArchived = (selected === null || selected === void 0 ? void 0 : selected.isShowArchived) || false;
                withAllUsers = (selected === null || selected === void 0 ? void 0 : selected.withAllUsers) !== false;
                isSelectedMyReport = (_b = (_a = selected === null || selected === void 0 ? void 0 : selected.isSelectedMyReport) !== null && _a !== void 0 ? _a : defaults.isSelectedMyReport) !== null && _b !== void 0 ? _b : false;
                return [2 /*return*/, {
                        selectedTeam: selectedTeam,
                        selectedUsers: selectedUsers,
                        selectedPeriod: selectedPeriod,
                        selectedCategory: selectedCategory,
                        isShowArchived: isShowArchived,
                        withAllUsers: withAllUsers,
                        isSelectedMyReport: isSelectedMyReport,
                        notFound: false,
                    }];
        }
    });
}); };
export var parseReportFromQuery = function (query, teams, periods, fetchReportableUsers) { return __awaiter(void 0, void 0, Promise, function () {
    var selectedTeam, selectedCategory, users, userIds, selectedUsers, selectedPeriod, isShowArchived, withAllUsers, isSelectedMyReport;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                selectedTeam = teams.find(function (_a) {
                    var id = _a.id;
                    return id === parseInt(query.team_id);
                });
                if (!selectedTeam) {
                    return [2 /*return*/, NOT_FOUND];
                }
                selectedCategory = (_a = selectedTeam.categories.find(function (_a) {
                    var id = _a.id;
                    return id === parseInt(query.category_id);
                })) !== null && _a !== void 0 ? _a : null;
                if (query.category_id && !selectedCategory) {
                    return [2 /*return*/, NOT_FOUND];
                }
                return [4 /*yield*/, fetchReportableUsers({
                        team_id: selectedTeam.id,
                    })];
            case 1:
                users = _d.sent();
                userIds = flatten([query.user_id]).map(function (id) { return parseInt(id); });
                selectedUsers = Object.hasOwn(query, 'user_id')
                    ? users.filter(function (_a) {
                        var id = _a.id;
                        return userIds.includes(id);
                    })
                    : users;
                selectedPeriod = (_c = (_b = periods.find(function (period) { return period.type === query.type; })) !== null && _b !== void 0 ? _b : buildPeriodWithQuery(query)) !== null && _c !== void 0 ? _c : periods[0];
                if (!selectedPeriod.from || !selectedPeriod.to) {
                    return [2 /*return*/, NOT_FOUND];
                }
                isShowArchived = query.with_archived === 'true';
                withAllUsers = query.with_all_users === 'true';
                isSelectedMyReport = query.myreport === 'true';
                return [2 /*return*/, {
                        selectedTeam: selectedTeam,
                        selectedUsers: selectedUsers,
                        selectedPeriod: selectedPeriod,
                        selectedCategory: selectedCategory,
                        isShowArchived: isShowArchived,
                        withAllUsers: withAllUsers,
                        isSelectedMyReport: isSelectedMyReport,
                        notFound: false,
                    }];
        }
    });
}); };
export var sortSelectedUsers = function (users, workedIds) {
    var sortedUsers = sortReportableUsers(users);
    return sortedUsers.filter(function (u) { return workedIds.includes(u.id); });
};
export var colors = [
    '#c8eca4',
    '#fcc7dc',
    '#e2cce9',
    '#bad7f5',
    '#fcf6a7',
    '#acd9c1',
    '#f9cfcc',
    '#cecef2',
    '#b0e7ea',
    '#ffd0a6',
    '#badcad',
    '#f3a9a4',
    '#94bae3',
    '#99c4d6',
    '#e2d6b7',
    '#b3c185',
    '#dd9bb3',
    '#829ece',
    '#d3d3d3',
    '#ccb38b',
];
export var saveSelectedUsersToStroge = function (saveReportSelectedUsers, loadReportSelectedUsers, team, selectedUsers, namespace) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        loadReportSelectedUsers(namespace, function (data) {
            data[team.id] = selectedUsers.map(function (_a) {
                var id = _a.id;
                return id;
            });
            saveReportSelectedUsers(namespace, data);
        });
        return [2 /*return*/];
    });
}); };
export var loadSelectedUsersFromStroge = function (loadReportSelectedUsers, team, namespace) { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) {
                loadReportSelectedUsers(namespace, function (data) {
                    resolve(data[team.id] || []);
                });
            })];
    });
}); };
export var convertShownPaymentAlertTeamIds = function (loadShownPaymentAlertTeamIds) { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) {
                loadShownPaymentAlertTeamIds(function (teamIds) {
                    var converted = teamIds.map(function (item) {
                        if (Number.isInteger(item)) {
                            return { id: item, permanent: true };
                        }
                        else {
                            return item;
                        }
                    });
                    resolve(converted);
                });
            })];
    });
}); };
export var getShownPaymentAlertTeamIds = function (loadShownPaymentAlertTeamIds) { return __awaiter(void 0, void 0, void 0, function () {
    var converted, ids;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, convertShownPaymentAlertTeamIds(loadShownPaymentAlertTeamIds)];
            case 1:
                converted = _a.sent();
                ids = converted
                    .filter(function (item) {
                    if (item.permanent) {
                        return true;
                    }
                    else {
                        var closedAt = new Date(item.closedAt);
                        return closedAt.getDate() === new Date().getDate();
                    }
                })
                    .map(function (_a) {
                    var id = _a.id;
                    return id;
                });
                return [2 /*return*/, ids];
        }
    });
}); };
export var updateShownPaymentAlertTeamIds = function (loadShownPaymentAlertTeamIds, saveShownPaymentAlertTeamIds, teamId, permanent) { return __awaiter(void 0, void 0, void 0, function () {
    var converted, updated;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, convertShownPaymentAlertTeamIds(loadShownPaymentAlertTeamIds)];
            case 1:
                converted = _a.sent();
                updated = __spreadArray(__spreadArray([], converted.filter(function (_a) {
                    var id = _a.id;
                    return id !== teamId;
                }), true), [
                    { id: teamId, permanent: permanent, closedAt: new Date().getTime() },
                ], false);
                saveShownPaymentAlertTeamIds(updated);
                return [2 /*return*/, updated];
        }
    });
}); };
export var getPaymentTeamForBlankReport = function (teams) {
    var unpaidTeams = teams.filter(function (_a) {
        var payment_required = _a.payment_required;
        return payment_required;
    });
    if (teams.length - unpaidTeams.length > 0) {
        return;
    }
    unpaidTeams.sort(function (a, b) { return a.id - b.id; });
    return unpaidTeams.find(function (_a) {
        var can_manage = _a.can_manage;
        return can_manage;
    }) || unpaidTeams[0];
};
var buildPeriodWithQuery = function (query) {
    if (!query.from) {
        return;
    }
    var locale = I18n.locale;
    var label = "".concat(moment(query.from).locale(locale).format('l'), " - ").concat(moment(query.to)
        .locale(locale)
        .format('l'));
    return {
        from: query.from,
        to: query.to,
        label: label,
        type: query.type,
    };
};
export var calcCategoryAndTaskTotals = function (timeEntries, tasks) {
    var taskTotals = {};
    var categoryTotals = {};
    var teamTotals = {};
    var totalDuration = 0;
    var totalAmount = 0;
    var categoryIdsByTaskId = tasks.reduce(function (acc, task) {
        acc[task.id] = task.ancestry.split('/').map(function (id) { return parseInt(id); });
        return acc;
    }, {});
    timeEntries.forEach(function (timeEntry) {
        var taskId = timeEntry.time_trackable_id;
        if (!taskTotals[taskId]) {
            taskTotals[taskId] = {
                duration: 0,
                amount: 0,
            };
        }
        taskTotals[taskId].duration += timeEntry.duration;
        taskTotals[taskId].amount += timeEntry.amount;
        categoryIdsByTaskId[taskId].forEach(function (categoryId) {
            if (!categoryTotals[categoryId]) {
                categoryTotals[categoryId] = {
                    duration: 0,
                    amount: 0,
                };
            }
            categoryTotals[categoryId].duration += timeEntry.duration;
            categoryTotals[categoryId].amount += timeEntry.amount;
        });
        if (!teamTotals[timeEntry.team_id]) {
            teamTotals[timeEntry.team_id] = {
                duration: 0,
                amount: 0,
            };
        }
        teamTotals[timeEntry.team_id].duration += timeEntry.duration;
        teamTotals[timeEntry.team_id].amount += timeEntry.amount;
        totalDuration += timeEntry.duration;
        totalAmount += timeEntry.amount;
    });
    return { taskTotals: taskTotals, categoryTotals: categoryTotals, teamTotals: teamTotals, totalDuration: totalDuration, totalAmount: totalAmount };
};
export var filterTimeEntries = function (timeEntries, categories, tasks, selectedCategoryIds, selectedTeamIds, selectedUserIds, isShowArchived, withAllUsers) {
    var workedCategoryIdSet = new Set();
    var workedTeamIdSet = new Set();
    var workedUserIdSet = new Set();
    if (!timeEntries || !categories || !tasks) {
        return {
            timeEntries: [],
            workedMemberIds: [],
            workedTeamIdSet: workedTeamIdSet,
            workedUserIdSet: workedUserIdSet,
            workedCategoryIdSet: workedCategoryIdSet,
        };
    }
    var isArchivedByCategoryId = isShowArchived
        ? []
        : categories.reduce(function (acc, category) {
            acc[category.id] = category.archived;
            return acc;
        }, {});
    var categoryIdsByTaskId = tasks.reduce(function (acc, task) {
        acc[task.id] = task.ancestry.split('/').map(function (id) { return Number(id); });
        return acc;
    }, {});
    var filteredTimeEntries = [];
    var workedMemberIds = [];
    timeEntries.forEach(function (timeEntry) {
        var categoryIds = categoryIdsByTaskId[timeEntry.time_trackable_id];
        var isMatchedArchived = isShowArchived ||
            !isArchivedByCategoryId[categoryIds[categoryIds.length - 1]];
        var isMatchedCategory = isMatchedArchived &&
            (!selectedCategoryIds.length ||
                intersection(categoryIds, selectedCategoryIds).length);
        var isMatchedTeam = !selectedTeamIds.length || selectedTeamIds.includes(timeEntry.team_id);
        var isMatchedUser = withAllUsers || selectedUserIds.includes(timeEntry.time_tracker_id);
        if (isMatchedCategory && isMatchedTeam) {
            if (isMatchedUser) {
                filteredTimeEntries.push(timeEntry);
            }
            workedMemberIds.push(timeEntry.time_tracker_id);
        }
        if (isMatchedUser && isMatchedCategory) {
            workedTeamIdSet.add(timeEntry.team_id);
        }
        if (isMatchedTeam && isMatchedCategory) {
            workedUserIdSet.add(timeEntry.time_tracker_id);
        }
        if (isMatchedTeam && isMatchedUser) {
            categoryIds.forEach(function (categoryId) {
                workedCategoryIdSet.add(categoryId);
            });
        }
    });
    return {
        timeEntries: filteredTimeEntries,
        workedMemberIds: Array.from(new Set(workedMemberIds)),
        workedTeamIdSet: workedTeamIdSet,
        workedUserIdSet: workedUserIdSet,
        workedCategoryIdSet: workedCategoryIdSet,
    };
};
export var filterWorkedTasks = function (tasks, timeEntries) {
    var taskById = tasks.reduce(function (acc, task) {
        acc[task.id] = task;
        return acc;
    }, {});
    var filteredTasksSet = new Set();
    timeEntries.forEach(function (timeEntry) {
        filteredTasksSet.add(taskById[timeEntry.time_trackable_id]);
    });
    return Array.from(filteredTasksSet);
};
