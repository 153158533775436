import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
export var FilterButton = function (_a) {
    var onToggle = _a.onToggle;
    return (<div className="text-right">
      <button className="btn btn-link hidden-md-up" onClick={onToggle}>
        <Icon className="mr-2" name="toggles"/>
        {I18n.t('shared.filter')}
      </button>
    </div>);
};
