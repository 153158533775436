import React from 'react';
import { Modal, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { CrossTeamReportConditionsInfo } from '../cross_team_report_conditions_info';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var CrossTeamReportBookmarkDeleteModal = function (props) {
    var bookmark = props.bookmark, isLoading = props.isLoading, isOpen = props.isOpen, onOpen = props.onOpen, onClose = props.onClose, onDelete = props.onDelete;
    var tooltipRef = React.useRef(null);
    var handleDelete = React.useCallback(function () {
        onDelete({ id: bookmark.id });
    }, [bookmark, onDelete]);
    var handleOpen = React.useCallback(function (e) {
        e.stopPropagation();
        onOpen();
    }, [onOpen]);
    return (<>
      <button ref={tooltipRef} className="btn btn-secondary text-danger" onClick={handleOpen}>
        <Icon name="trash"/>
      </button>
      <UncontrolledTooltip fade={false} target={tooltipRef}>
        {I18n.t("shared.delete")}
      </UncontrolledTooltip>

      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeaderMod toggle={onClose}>
          {I18n.t('shared.deleteReportBookmarkHeader')}
        </ModalHeaderMod>

        <ModalBody>
          <p>{I18n.t('shared.deleteReportBookmarkDescription')}</p>
          <div className="bg-gray-lighter rounded px-2 pt-2 pb-1 mb-2">
            {bookmark.name.length > 0 && (<p className="h3 leading-8 mb-1">{bookmark.name}</p>)}
            <div className="text-secondary">
              <CrossTeamReportConditionsInfo withDetails conditions={bookmark.conditions} withDate={bookmark.conditions.period_type === 'custom' ||
            bookmark.conditions.term === 'long_term'}/>
            </div>
          </div>
          <p className="mb-1">{I18n.t('shared.deleteReportBookmarkNotice')}</p>
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-secondary" disabled={isLoading} onClick={onClose}>
            {I18n.t('shared.cancel')}
          </button>
          <button className="btn btn-danger" disabled={isLoading} onClick={handleDelete}>
            {I18n.t('shared.delete')}
          </button>
        </ModalFooter>
      </Modal>
    </>);
};
