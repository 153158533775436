import { connect } from 'react-redux';
import TeamDeletion from '@/components/team_edit/team_deletion';
import { destroyTeam } from '@/modules/teams';
import { fetchCurrentUser } from '@/modules/current_user';
import { fetchCategories } from '@/actions/category';
import { setNotificationMessage } from '@/modules/notification';
var mapStateToProps = function (state, ownProps) { return ({
    categories: state.categories.filter(function (_a) {
        var team_id = _a.team_id;
        return team_id === ownProps.id;
    }),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onSubmit: function (id) {
        return dispatch(destroyTeam(id));
    },
    onFetchUser: function () {
        // NOTE: チーム削除時に打刻中だったケースに対応するために取得
        //       再度取得するのではなく、reducerを更新して対応したい
        //       https://trello.com/c/DZ2YSU1p
        return dispatch(fetchCurrentUser());
    },
    fetchCategories: function (params) {
        return dispatch(fetchCategories(params));
    },
    setNotificationMessage: function (messageType, message) {
        dispatch(setNotificationMessage(messageType, message));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TeamDeletion);
