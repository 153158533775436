import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import React from 'react';
import queryString from 'query-string';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@/hooks/use_query';
import MainMenuContainer from '@/containers/main_menu_container';
import TeamReport from './team_report';
import Icon from '@/components/atoms/icon';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import { FilterButton } from '@/components/atoms/filter_button';
import { BlankReport } from './blank_report';
import I18n from '../../constants/i18n';
import { ReportTeamSelectDropdown } from './report_team_select_dropdown';
import { PeriodSelectDropdown } from './period_select_dropdown';
import { ReportAlerts } from './report_alerts';
import { ReportSubpane } from './report_subpane';
import { parseReportFromQuery, parseReportFromStorage, saveSelectedUsersToStroge, loadSelectedUsersFromStroge, getShownPaymentAlertTeamIds, updateShownPaymentAlertTeamIds, getPaymentTeamForBlankReport, filterTimeEntries, } from './helpers';
import { notifyToAirbrake } from '@/utils/airbrake_helper';
import { workspacePath, workspaceTeams } from '@/utils/workspace_helper';
import { useWorkspaceId } from '@/hooks/use_workspace_id';
import { resetReport } from '@/modules/report';
var EVENT_TYPE = {
    START: 1,
    HOVER: 2,
    END: 3,
};
var Report = function (props) {
    var teams = props.teams, user = props.user, report = props.report, _a = props.report, reportTeams = _a.teams, reportPeriods = _a.periods, teamReport = _a.teamReport, categories = props.categories, reportableUsers = props.reportableUsers, workspaces = props.workspaces, isPC = props.isPC, fetchCategories = props.fetchCategories, fetchReportTeams = props.fetchReportTeams, fetchReportPeriods = props.fetchReportPeriods, loadShownPaymentAlertTeamIds = props.loadShownPaymentAlertTeamIds, saveShownPaymentAlertTeamIds = props.saveShownPaymentAlertTeamIds, loadReportHistory = props.loadReportHistory, saveReportHistory = props.saveReportHistory, fetchReportableUsers = props.fetchReportableUsers, fetchTeamReport = props.fetchTeamReport, loadReportSelectedUsers = props.loadReportSelectedUsers, saveReportSelectedUsers = props.saveReportSelectedUsers, toggleTeamMenu = props.toggleTeamMenu, openURL = props.openURL, apiBase = props.apiBase, setReportTasksSortOrder = props.setReportTasksSortOrder, setReportTasksSortTarget = props.setReportTasksSortTarget;
    var isMyReportView = useLocation().pathname.startsWith('/app/myreport');
    var _b = React.useState({
        tab: 'team',
        openTeams: false,
        openDateRange: false,
        selectedTeam: null,
        selectedUsers: [],
        selectedPeriod: null,
        loading: false,
        isOpenAlerts: [],
        hideTeamIds: [],
        selectedCategory: null,
        reportedTeam: null,
        selectedSubPaneTab: 'category',
        isNotWorkedUsersOpened: false,
        notFound: false,
        isShowArchived: false,
        isOpenSubpane: false,
        withAllUsers: false,
        reportableTeams: [],
        isSelectedMyReport: isMyReportView,
    }), state = _b[0], setState = _b[1];
    var notFound = state.notFound, selectedTeam = state.selectedTeam, selectedUsers = state.selectedUsers, selectedPeriod = state.selectedPeriod, selectedCategory = state.selectedCategory, reportedTeam = state.reportedTeam, selectedSubPaneTab = state.selectedSubPaneTab, loading = state.loading, withAllUsers = state.withAllUsers, reportableTeams = state.reportableTeams, isShowArchived = state.isShowArchived, isSelectedMyReport = state.isSelectedMyReport, openTeams = state.openTeams, openDateRange = state.openDateRange, isOpenAlerts = state.isOpenAlerts, isOpenSubpane = state.isOpenSubpane, hideTeamIds = state.hideTeamIds, tab = state.tab, isNotWorkedUsersOpened = state.isNotWorkedUsersOpened;
    var navigate = useNavigate();
    var location = useLocation();
    var query = useQuery();
    var workspaceId = useWorkspaceId();
    var dispatch = useDispatch();
    var storageNamespace = workspaceId
        ? "_workspace_".concat(workspaceId)
        : isMyReportView
            ? '_myreport'
            : '';
    var teamItems = React.useMemo(function () { return workspaceTeams(teams.items); }, [teams.items]);
    var myReportTeamIds = React.useMemo(function () { return teamItems.filter(function (t) { return !t.payment_required; }).map(function (t) { return t.id; }); }, [teamItems]);
    var myReportUserIds = React.useMemo(function () { return [user.id]; }, [user.id]);
    var isShowOnlyMyData = isSelectedMyReport || (isMyReportView && isPC);
    var hasQuery = !!Object.keys(query).length;
    var locale = I18n.locale;
    var _c = React.useMemo(function () {
        return filterTimeEntries(teamReport === null || teamReport === void 0 ? void 0 : teamReport.time_entries, teamReport === null || teamReport === void 0 ? void 0 : teamReport.categories, teamReport === null || teamReport === void 0 ? void 0 : teamReport.tasks, selectedCategory ? [selectedCategory.id] : [], [], isShowOnlyMyData ? myReportUserIds : selectedUsers.map(function (_a) {
            var id = _a.id;
            return id;
        }), isShowArchived, isShowOnlyMyData ? false : withAllUsers);
    }, [
        teamReport === null || teamReport === void 0 ? void 0 : teamReport.time_entries,
        teamReport === null || teamReport === void 0 ? void 0 : teamReport.categories,
        teamReport === null || teamReport === void 0 ? void 0 : teamReport.tasks,
        selectedCategory,
        selectedUsers,
        isShowArchived,
        withAllUsers,
        isShowOnlyMyData,
        myReportUserIds,
    ]), timeEntries = _c.timeEntries, workedMemberIds = _c.workedMemberIds;
    var isBlankTeamReport = (teamReport && !timeEntries.length) ||
        (selectedUsers.length < 1 && !withAllUsers);
    var isReportable = React.useMemo(function () { return !loading && selectedTeam && selectedPeriod; }, [loading, selectedTeam, selectedPeriod]);
    var isChangedQueryByMyself = React.useRef(false);
    var handleSelectTeam = function (team) {
        if (team.payment_required || !isReportable) {
            return;
        }
        fetchReportableUsers({ team_id: team.id }).then(function (reportableUsers) { return __awaiter(void 0, void 0, void 0, function () {
            var restoredUserIds, restoredUsers, selectedUsers, params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (reportableUsers.error) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, loadSelectedUsersFromStroge(loadReportSelectedUsers, team, storageNamespace)];
                    case 1:
                        restoredUserIds = _a.sent();
                        restoredUsers = reportableUsers.filter(function (u) {
                            return restoredUserIds.includes(u.id);
                        });
                        selectedUsers = restoredUsers.length
                            ? restoredUsers
                            : reportableUsers;
                        params = {
                            team: team,
                            period: selectedPeriod,
                            category: selectedCategory,
                            users: selectedUsers,
                            isShowArchived: isShowArchived,
                            withAllUsers: withAllUsers,
                            isSelectedMyReport: false,
                        };
                        handleRefreshReport(params, true);
                        navigateWithQuery(params);
                        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedTeam: team, selectedUsers: selectedUsers, selectedCategory: null, isSelectedMyReport: false })); });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    var handleSelectMyReport = function () {
        if (!isReportable) {
            return;
        }
        var params = {
            team: selectedTeam,
            period: selectedPeriod,
            category: selectedCategory,
            users: selectedUsers,
            isShowArchived: isShowArchived,
            withAllUsers: withAllUsers,
            isSelectedMyReport: true,
        };
        handleRefreshReport(params, true);
        navigateWithQuery(params);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { isSelectedMyReport: true })); });
    };
    var handleSelectPeriod = function (period) {
        if (!isReportable) {
            return;
        }
        var params = {
            team: selectedTeam,
            period: period,
            category: selectedCategory,
            users: selectedUsers,
            isShowArchived: isShowArchived,
            withAllUsers: withAllUsers,
            isSelectedMyReport: isSelectedMyReport,
        };
        handleRefreshReport(params, true);
        navigateWithQuery(params);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedPeriod: period })); });
    };
    var handleSelectDate = function (selected) {
        if (!isReportable) {
            return;
        }
        if (selected.eventType !== EVENT_TYPE.END) {
            return;
        }
        var from = moment(selected.start).format('YYYY-MM-DD');
        var to = moment(selected.end).format('YYYY-MM-DD');
        var label = "".concat(moment(selected.start)
            .locale(locale)
            .format('l'), " - ").concat(moment(selected.end).locale(locale).format('l'));
        var period = { from: from, to: to, label: label };
        var params = {
            team: selectedTeam,
            period: period,
            category: selectedCategory,
            users: selectedUsers,
            isShowArchived: isShowArchived,
            withAllUsers: withAllUsers,
            isSelectedMyReport: isSelectedMyReport,
        };
        handleRefreshReport(params, true);
        navigateWithQuery(params);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedPeriod: period })); });
        toggleOpenDateRange();
    };
    var saveReportToStorage = React.useCallback(function (team, user, period, category, isShowArchived, withAllUsers, isSelectedMyReport) {
        try {
            saveReportHistory(storageNamespace, {
                team: team,
                user: user,
                period: period,
                category: category,
                isShowArchived: isShowArchived,
                withAllUsers: withAllUsers,
                isSelectedMyReport: isSelectedMyReport,
            });
        }
        catch (e) {
            notifyToAirbrake(user.id, e, { team_id: team.id });
        }
    }, [saveReportHistory, storageNamespace]);
    var handleFetchTeamReport = React.useCallback(function (team, period, category, users, isShowArchived, withAllUsers, saveToStorage) {
        var teamIds = [team.id];
        var userIds = isShowOnlyMyData
            ? myReportUserIds
            : users.map(function (_a) {
                var id = _a.id;
                return id;
            });
        setState(function (prevState) { return (__assign(__assign({}, prevState), { loading: true, notFound: false })); });
        fetchTeamReport(teamIds, userIds, period.from, period.to, period.type, category === null || category === void 0 ? void 0 : category.id, isShowArchived, withAllUsers).then(function (res) {
            if (res.ok && saveToStorage) {
                saveReportToStorage(team, users, period, category, isShowArchived, withAllUsers, false);
            }
            setState(function (prevState) { return (__assign(__assign({}, prevState), { loading: false, reportedTeam: team, notFound: !res.ok })); });
        });
    }, [fetchTeamReport, isShowOnlyMyData, myReportUserIds, saveReportToStorage]);
    var handleFetchMyReport = React.useCallback(function (team, period, category, users, isShowArchived, saveToStorage) {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { loading: true, notFound: false })); });
        fetchTeamReport(myReportTeamIds, myReportUserIds, period.from, period.to, period.type, category === null || category === void 0 ? void 0 : category.id, isShowArchived, false).then(function (res) {
            if (res.ok && saveToStorage) {
                saveReportToStorage(team, users, period, category, isShowArchived, false, true);
            }
            setState(function (prevState) { return (__assign(__assign({}, prevState), { loading: false, reportedTeam: team, notFound: !res.ok })); });
        });
    }, [fetchTeamReport, myReportTeamIds, myReportUserIds, saveReportToStorage]);
    var handleRefreshReport = React.useCallback(function (_a, saveToStorage) {
        var team = _a.team, period = _a.period, category = _a.category, users = _a.users, isShowArchived = _a.isShowArchived, withAllUsers = _a.withAllUsers, isSelectedMyReport = _a.isSelectedMyReport;
        if (isSelectedMyReport) {
            handleFetchMyReport(team, period, category, users, isShowArchived, saveToStorage);
        }
        else {
            handleFetchTeamReport(team, period, category, users, isShowArchived, withAllUsers, saveToStorage);
        }
    }, [handleFetchMyReport, handleFetchTeamReport]);
    var handleClickRefreshReport = function () {
        var params = {
            team: selectedTeam,
            period: selectedPeriod,
            category: selectedCategory,
            users: selectedUsers,
            isShowArchived: isShowArchived,
            withAllUsers: withAllUsers,
            isSelectedMyReport: isSelectedMyReport,
        };
        handleRefreshReport(params, true);
    };
    var handleIsShowArchived = function () {
        if (!isReportable) {
            return;
        }
        var params = {
            team: selectedTeam,
            period: selectedPeriod,
            category: selectedCategory,
            users: selectedUsers,
            isShowArchived: !isShowArchived,
            withAllUsers: withAllUsers,
            isSelectedMyReport: isSelectedMyReport,
        };
        saveReportToStorage(selectedTeam, selectedUsers, selectedPeriod, selectedCategory, !isShowArchived, withAllUsers, isSelectedMyReport);
        navigateWithQuery(params);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { isShowArchived: !isShowArchived })); });
        if (selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.archived) {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedCategory: null })); });
        }
    };
    var handleSelectCategory = function (category) {
        if (!isReportable) {
            return;
        }
        var team = (category === null || category === void 0 ? void 0 : category.team)
            ? reportableTeams.find(function (_a) {
                var id = _a.id;
                return id === category.team_id;
            })
            : selectedTeam;
        var params = {
            team: team,
            period: selectedPeriod,
            category: category,
            users: selectedUsers,
            isShowArchived: isShowArchived,
            withAllUsers: withAllUsers,
            isSelectedMyReport: isSelectedMyReport,
        };
        saveReportToStorage(team, selectedUsers, selectedPeriod, category, isShowArchived, withAllUsers, isSelectedMyReport);
        navigateWithQuery(params);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedTeam: team, selectedCategory: category })); });
    };
    var handleSelectAllUsers = function () {
        if (!isReportable) {
            return;
        }
        handleSelectUsers([], true);
    };
    var handleDeselectAllUsers = function () {
        if (!isReportable) {
            return;
        }
        handleSelectUsers([], false);
    };
    var handleSelectUser = function (selectedUser) {
        if (!isReportable) {
            return;
        }
        if (withAllUsers) {
            handleSelectUsers(reportableUsers.items.filter(function (_a) {
                var id = _a.id;
                return id !== selectedUser.id;
            }), false);
            return;
        }
        var nextSelectedUsers = selectedUsers.some(function (user) { return user.id === selectedUser.id; })
            ? selectedUsers.filter(function (user) { return user.id !== selectedUser.id; })
            : __spreadArray(__spreadArray([], selectedUsers.filter(function (user) { return user.id; }), true), [selectedUser], false);
        handleSelectUsers(nextSelectedUsers, false);
    };
    var handleSelectUsers = function (users, withAllUsers) {
        if (!isReportable) {
            return;
        }
        if (reportableUsers.items.length === users.length) {
            users = [];
            withAllUsers = true;
        }
        var params = {
            team: selectedTeam,
            period: selectedPeriod,
            category: selectedCategory,
            users: users,
            isShowArchived: isShowArchived,
            withAllUsers: withAllUsers,
            isSelectedMyReport: isSelectedMyReport,
        };
        saveReportToStorage(selectedTeam, users, selectedPeriod, selectedCategory, isShowArchived, withAllUsers, isSelectedMyReport);
        navigateWithQuery(params);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedUsers: users, withAllUsers: withAllUsers })); });
        saveSelectedUsersToStroge(saveReportSelectedUsers, loadReportSelectedUsers, selectedTeam, users, storageNamespace);
    };
    React.useEffect(function () {
        if (!teams.isFetched) {
            return;
        }
        fetchCategories();
    }, [fetchCategories, teams.isFetched]);
    React.useEffect(function () {
        fetchReportTeams();
    }, [fetchReportTeams]);
    React.useEffect(function () {
        fetchReportPeriods();
    }, [fetchReportPeriods]);
    React.useEffect(function () {
        if (teams.isFetched && teamItems.length < 1 && !notFound) {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { notFound: true })); });
        }
    }, [teams, notFound, teamItems.length]);
    React.useEffect(function () {
        if (!reportTeams.length) {
            return;
        }
        var paiableTeams = reportTeams.filter(function (team) { return team.payable; });
        getShownPaymentAlertTeamIds(loadShownPaymentAlertTeamIds).then(function (teamIds) {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { isOpenAlerts: paiableTeams
                    .filter(function (team) { return !teamIds.includes(team.id); })
                    .map(function () { return true; }), hideTeamIds: teamIds })); });
        });
    }, [reportTeams, loadShownPaymentAlertTeamIds]);
    var restoreReport = React.useCallback(function (restoredParams, saveToStorage) {
        if (saveToStorage === void 0) { saveToStorage = false; }
        var selectedTeam = restoredParams.selectedTeam, selectedUsers = restoredParams.selectedUsers, selectedPeriod = restoredParams.selectedPeriod, selectedCategory = restoredParams.selectedCategory, isShowArchived = restoredParams.isShowArchived, withAllUsers = restoredParams.withAllUsers, isSelectedMyReport = restoredParams.isSelectedMyReport;
        if (!selectedTeam) {
            return;
        }
        var params = {
            team: selectedTeam,
            period: selectedPeriod,
            category: selectedCategory,
            users: selectedUsers,
            isShowArchived: isShowArchived,
            withAllUsers: withAllUsers,
            isSelectedMyReport: isSelectedMyReport,
        };
        handleRefreshReport(params, saveToStorage);
    }, [handleRefreshReport]);
    var restoreReportFromQuery = React.useCallback(function (reportableTeams) { return __awaiter(void 0, void 0, void 0, function () {
        var parsedState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isChangedQueryByMyself.current) {
                        isChangedQueryByMyself.current = false;
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, parseReportFromQuery(query, reportableTeams, reportPeriods, fetchReportableUsers)];
                case 1:
                    parsedState = _a.sent();
                    setState(function (prevState) { return (__assign(__assign({}, prevState), parsedState)); });
                    restoreReport(parsedState, false);
                    return [2 /*return*/];
            }
        });
    }); }, [fetchReportableUsers, query, reportPeriods, restoreReport]);
    var restoreReportFromStorage = React.useCallback(function (reportableTeams) {
        loadReportHistory(storageNamespace, function (selected) { return __awaiter(void 0, void 0, void 0, function () {
            var parsedState;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, parseReportFromStorage(reportableTeams, reportPeriods, selected, fetchReportableUsers, { isSelectedMyReport: isMyReportView })];
                    case 1:
                        parsedState = _a.sent();
                        setState(function (prevState) { return (__assign(__assign({}, prevState), parsedState)); });
                        restoreReport(parsedState, true);
                        return [2 /*return*/];
                }
            });
        }); });
    }, [
        fetchReportableUsers,
        isMyReportView,
        loadReportHistory,
        reportPeriods,
        restoreReport,
        storageNamespace,
    ]);
    React.useEffect(function () {
        if (reportTeams.length < 1 ||
            categories.length < 1 ||
            reportPeriods.length < 1) {
            return;
        }
        var categoriesByTeamId = categories.reduce(function (acc, category) {
            if (!acc[category.team_id]) {
                acc[category.team_id] = [];
            }
            acc[category.team_id].push(category);
            return acc;
        }, {});
        var reportableTeams = reportTeams.map(function (team) {
            var _a;
            return (__assign(__assign({}, team), { categories: (_a = categoriesByTeamId[team.id]) !== null && _a !== void 0 ? _a : [] }));
        });
        if (hasQuery) {
            restoreReportFromQuery(reportableTeams);
        }
        else {
            restoreReportFromStorage(reportableTeams);
        }
        setState(function (prevState) { return (__assign(__assign({}, prevState), { reportableTeams: reportableTeams })); });
    }, [
        reportTeams,
        categories,
        reportPeriods,
        hasQuery,
        restoreReportFromQuery,
        restoreReportFromStorage,
    ]);
    var navigateWithQuery = React.useCallback(function (_a) {
        var _b;
        var team = _a.team, users = _a.users, period = _a.period, category = _a.category, isShowArchived = _a.isShowArchived, withAllUsers = _a.withAllUsers, isSelectedMyReport = _a.isSelectedMyReport;
        if (!team || !period) {
            return;
        }
        var userIds = users.map(function (_a) {
            var id = _a.id;
            return id;
        });
        var query = {
            team_id: team.id,
            user_id: userIds.length > 0 ? userIds : '',
            from: period.from,
            to: period.to,
            type: period.type,
            category_id: (_b = category === null || category === void 0 ? void 0 : category.id) !== null && _b !== void 0 ? _b : '',
            with_archived: isShowArchived,
            with_all_users: withAllUsers,
            myreport: isSelectedMyReport,
        };
        var url = location.pathname + '?' + queryString.stringify(query);
        isChangedQueryByMyself.current = true;
        navigate(url);
    }, [location.pathname, navigate]);
    var toggleOpenTeams = function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { openTeams: !openTeams })); });
    };
    var toggleOpenDateRange = function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { openDateRange: !openDateRange })); });
    };
    var onNavigateToPayment = function (teamId) {
        if (!isPC) {
            toggleTeamMenu(false);
        }
        navigate(workspacePath("/teams/".concat(teamId, "/payment")));
    };
    var handleCloseAlert = function (team, targetIndex, permanent) {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { isOpenAlerts: isOpenAlerts.map(function (isOpen, index) {
                if (targetIndex === index) {
                    updateShownPaymentAlertTeamIds(loadShownPaymentAlertTeamIds, saveShownPaymentAlertTeamIds, team.id, permanent);
                    return false;
                }
                else {
                    return isOpen;
                }
            }) })); });
    };
    var toggleOpenSubpane = function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { isOpenSubpane: !isOpenSubpane })); });
    };
    var selectSubpaneTab = function (tabName) {
        if (!['category', 'member'].includes(tabName)) {
            return;
        }
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedSubPaneTab: tabName })); });
    };
    React.useEffect(function () {
        return function () {
            dispatch(resetReport());
        };
    }, [dispatch]);
    return (<div className="content-wrap">
      <ReportAlerts hiddenTeamIds={hideTeamIds} isOpenAlerts={isOpenAlerts} openURL={openURL} teams={reportableTeams} onCloseAlert={handleCloseAlert} onNavigateToPayment={onNavigateToPayment}/>
      <MainMenuContainer />
      <div className="content-main">
        <div className="toolbar d-flex report-toolbar">
          <div className="btn-group btn-block d-flex">
            <ReportTeamSelectDropdown hasError={notFound} isMyReportView={isMyReportView} isOpen={openTeams} isSelectedMyReport={isSelectedMyReport} selectedTeam={selectedTeam} teams={reportableTeams} workspaces={workspaces.items} onNavigateToPayment={onNavigateToPayment} onSelectMyReport={handleSelectMyReport} onSelectTeam={handleSelectTeam} onToggleDropdown={toggleOpenTeams}/>
            <PeriodSelectDropdown hasError={notFound} isOpen={openDateRange} locale={locale} periods={reportPeriods} selectedPeriod={selectedPeriod} onSelectDate={handleSelectDate} onSelectPeriod={handleSelectPeriod} onToggleDropdown={toggleOpenDateRange}/>
            <button className="btn btn-primary btn-group-last btn-lg" disabled={!isReportable} style={{ padding: '.8rem 1.2rem' }} onClick={handleClickRefreshReport}>
              <Icon name="arrow-thin-right" size="md"/>
            </button>
          </div>
        </div>
        {tab === 'team' && teamReport ? (<div className="row no-gutters with-toolbar report-area">
            <div className="col-md-9 col-12">
              <div className={isBlankTeamReport ? 'bg-blank' : ''}>
                <FilterButton onToggle={toggleOpenSubpane}/>
              </div>
              {isBlankTeamReport ? (<BlankReport />) : (<TeamReport apiBase={apiBase} category={selectedCategory} isPC={isPC} isSelectedMyReport={isSelectedMyReport} isShowArchived={isShowArchived} isShowOnlyMyData={isShowOnlyMyData} myReportTeamIds={myReportTeamIds} myReportUserIds={myReportUserIds} openURL={openURL} report={report} reportableUsers={reportableUsers.items} selectedUsers={selectedUsers} setReportTasksSortOrder={setReportTasksSortOrder} setReportTasksSortTarget={setReportTasksSortTarget} team={teamItems.find(function (_a) {
                    var id = _a.id;
                    return id === teamReport.teams[0].id;
                })} teamReport={teamReport} timeEntries={timeEntries} withAllUsers={withAllUsers} workedMemberIds={workedMemberIds} onSelectUser={function (user) { return handleSelectUsers([user], false); }}/>)}
            </div>
            <ReportSubpane isNotWorkedUsersOpened={isNotWorkedUsersOpened} isOpenSubpane={isOpenSubpane} isSelectedMyReport={isSelectedMyReport} isShowArchived={isShowArchived} isShowOnlyMyData={isShowOnlyMyData} loading={loading} reportableTeams={reportableTeams} reportableUsers={reportableUsers} reportedCategory={selectedCategory} reportedTeam={reportedTeam} selectedSubPaneTab={selectedSubPaneTab} selectedUsers={selectedUsers} selectSubpaneTab={selectSubpaneTab} setIsNotWorkedUsersOpened={function (isNotWorkedUsersOpened) {
                return setState(function (prevState) { return (__assign(__assign({}, prevState), { isNotWorkedUsersOpened: isNotWorkedUsersOpened })); });
            }} toggleIsShowArchived={handleIsShowArchived} toggleOpenSubpane={toggleOpenSubpane} withAllUsers={withAllUsers} workedMemberIds={workedMemberIds} onDeselectAllUsers={handleDeselectAllUsers} onSelectAllUsers={handleSelectAllUsers} onSelectCategory={handleSelectCategory} onSelectUser={handleSelectUser}/>
          </div>) : notFound ? (<BlankReport paymentTeam={getPaymentTeamForBlankReport(reportableTeams)} onNavigateToPayment={onNavigateToPayment}/>) : (<LoadingSpinner position="absolute-center" size="md"/>)}
      </div>
    </div>);
};
export default Report;
