import { __assign } from "tslib";
import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import TimeEntryInlineEditContainer from '@/containers/time_entry_inline_edit_container';
import TimeEntryFormContainer from '@/containers/task_edit/time_entry_form_container';
var TimeEntryListItem = function (props) {
    var timeEntry = props.timeEntry, task = props.task, query = props.query, destroyTimeEntry = props.destroyTimeEntry, className = props.className, withoutAvatar = props.withoutAvatar;
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var handleToggleTimeEntryEdit = function () {
        if (!timeEntry.can_update) {
            return false;
        }
        setIsOpen(!isOpen);
    };
    return (<>
      {isOpen && (<TimeEntryFormContainer id={timeEntry.id} query={query} startedAt={moment(timeEntry.started_at)} task={__assign(__assign({}, task), { category: timeEntry.category })} timeEntry={timeEntry} user={timeEntry.user} onCancel={handleToggleTimeEntryEdit}/>)}
      <TimeEntryInlineEditContainer canUpdate={timeEntry.can_update && !task.category.archived} className={className} comment={timeEntry.comment} destroyTimeEntry={destroyTimeEntry} disabled={task.category.archived} id={timeEntry.id} inputType={timeEntry.input_type} startedAt={moment(timeEntry.started_at)} stoppedAt={moment(timeEntry.stopped_at)} toggle={handleToggleTimeEntryEdit} user={timeEntry.user} withoutAvatar={withoutAvatar}/>
    </>);
};
export default TimeEntryListItem;
