import React from 'react';
import { useParams } from 'react-router-dom';
import I18n from '@/constants/i18n';
import { TeamManagementLayoutContainer } from '@/containers/team_management_layout_container';
import CategoryTaskStructuresImportContainer from '@/containers/category_task_structures_import_container';
import CategoryTaskStructuresExportContainer from '@/containers/category_task_structures_export_container';
export var CategoryTaskStructuresForm = function (_a) {
    var teams = _a.teams;
    var params = useParams();
    var team = teams.find(function (team) { return team.id === Number(params.team_id); });
    var canImport = !(team === null || team === void 0 ? void 0 : team.payment_required);
    return (<TeamManagementLayoutContainer currentPage="category_task_structures" team={team} title={I18n.t('shared.bulkCreateCategoriesAndTasks')}>
      <CategoryTaskStructuresImportContainer canImport={canImport} team={team}/>
      {canImport && (<>
          <hr className="mt-3"/>
          <div className="mt-4">
            <CategoryTaskStructuresExportContainer team={team}/>
          </div>
        </>)}
    </TeamManagementLayoutContainer>);
};
