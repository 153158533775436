import { __assign } from "tslib";
import api2 from '../../api2';
export var FETCH_LAST_TIME_ENTRIES_REQUEST = 'timecrowd/last_time_entries/FETCH_LAST_TIME_ENTRIES_REQUEST';
export var FETCH_LAST_TIME_ENTRIES_SUCCESS = 'timecrowd/last_time_entries/FETCH_LAST_TIME_ENTRIES_SUCCESS';
export var FETCH_LAST_TIME_ENTRIES_FAILURE = 'timecrowd/last_time_entries/FETCH_LAST_TIME_ENTRIES_FAILURE';
export var RESET_LAST_TIME_ENTRIES = 'timecrowd/last_time_entries/RESET_LAST_TIME_ENTRIES';
export var initialState = {
    isLoading: false,
    isFetched: false,
    items: [],
};
export default function lastTimeEntries(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_LAST_TIME_ENTRIES_REQUEST:
            return __assign(__assign({}, state), { isLoading: !state.isFetched });
        case FETCH_LAST_TIME_ENTRIES_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isFetched: true, items: action.lastTimeEntries });
        case FETCH_LAST_TIME_ENTRIES_FAILURE:
        case RESET_LAST_TIME_ENTRIES:
            return initialState;
        default:
            return state;
    }
}
export var fetchLastTimeEntriesRequest = function () { return ({
    type: FETCH_LAST_TIME_ENTRIES_REQUEST,
}); };
export var fetchLastTimeEntriesSuccess = function (lastTimeEntries) { return ({
    type: FETCH_LAST_TIME_ENTRIES_SUCCESS,
    lastTimeEntries: lastTimeEntries,
}); };
export var fetchLastTimeEntriesFailure = function () { return ({
    type: FETCH_LAST_TIME_ENTRIES_FAILURE,
}); };
export var resetLastTimeEntries = function () { return ({
    type: RESET_LAST_TIME_ENTRIES,
}); };
export var fetchLastTimeEntries = function () {
    return function (dispatch, getState) {
        dispatch(fetchLastTimeEntriesRequest());
        return api2(dispatch, getState, '/api/v2/last_time_entries', 'GET').then(function (response) {
            if (response.ok) {
                response.json().then(function (lastTimeEntries) {
                    dispatch(fetchLastTimeEntriesSuccess(lastTimeEntries));
                    return lastTimeEntries;
                });
            }
            else {
                dispatch(fetchLastTimeEntriesFailure());
                return { error: true };
            }
        });
    };
};
