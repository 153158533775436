import React from 'react';
import { CategoryCheckList } from '@/components/molecules/category_check_list';
import ExpansionPanelBody from '@/components/atoms/expansion_panel_body';
import ExpansionPanelHeader from '@/components/molecules/expansion_panel_header';
import ExpansionPanel from '@/components/atoms/expansion_panel';
import TeamCheckbox from '@/components/molecules/team_checkbox';
import { isIndeterminate } from './helpers';
export var TeamCategoryCheckList = function (_a) {
    var teams = _a.teams, selectedCategories = _a.selectedCategories, onChangeTeam = _a.onChangeTeam, onChangeCategory = _a.onChangeCategory, _b = _a.shouldDisableUnpaidTeam, shouldDisableUnpaidTeam = _b === void 0 ? false : _b, _c = _a.showsBadge, showsBadge = _c === void 0 ? false : _c, showsWorkspaceAvatar = _a.showsWorkspaceAvatar, _d = _a.isExpandAllByDefault, isExpandAllByDefault = _d === void 0 ? false : _d, _e = _a.disabled, disabled = _e === void 0 ? false : _e;
    return (<>
      {teams.map(function (team) {
            var selectedTeamCategories = selectedCategories.filter(function (category) { return category.team_id === team.id; });
            var checked = team.categories.length === selectedTeamCategories.length;
            var indeterminated = isIndeterminate(team.categories, selectedTeamCategories);
            return (<ExpansionPanel key={"team-".concat(team.id)} className="list-group-item-expansion" expand={isExpandAllByDefault}>
            {function (expand, toggle) { return (<>
                <ExpansionPanelHeader disabled={shouldDisableUnpaidTeam && team.payment_required} expand={expand} toggle={toggle} onClick={function () {
                        onChangeTeam(team, checked);
                    }}>
                  <TeamCheckbox checked={checked} disabled={disabled} indeterminate={indeterminated} shouldDisableUnpaidTeam={shouldDisableUnpaidTeam} showsBadge={showsBadge} showsWorkspaceAvatar={showsWorkspaceAvatar} team={team} onChange={onChangeTeam}/>
                </ExpansionPanelHeader>
                <ExpansionPanelBody expand={expand}>
                  <CategoryCheckList disabled={disabled} selectedCategories={selectedCategories} team={team} onChange={onChangeCategory}/>
                </ExpansionPanelBody>
              </>); }}
          </ExpansionPanel>);
        })}
    </>);
};
