import React from 'react';
import I18n from '@/constants/i18n';
import { calcFromAndToForType } from './helpers';
export var CrossTeamReportPeriodButton = function (props) {
    var isSelected = props.isSelected, startMonth = props.startMonth, type = props.type, onClick = props.onClick;
    return (<button className={"btn rounded-full border-1 font-weight-normal shadow-none mr-1 ".concat(isSelected ? 'bg-light border-black text-black' : 'bg-transparent text-secondary')} onClick={onClick}>
      {I18n.t("shared.".concat(type))}
      {[
            'recent2Months',
            'currentQuarter',
            'lastQuarter',
            'thisFiscalYear',
            'lastFiscalYear',
            'recent2FiscalYears',
            'recent6Months',
            'recent12Months',
        ].includes(type) && (<span className="ml-1 text-secondary">
          {Object.values(calcFromAndToForType(type, startMonth)).join(' - ')}
        </span>)}
    </button>);
};
