import { __assign, __spreadArray } from "tslib";
import api from '@/api';
export var FETCH_TASK_REQUEST = 'timecrowd/tasks/FETCH_TASK_REQUEST';
export var FETCH_TASK_SUCCESS = 'timecrowd/tasks/FETCH_TASK_SUCCESS';
export var FETCH_TASK_FAILURE = 'timecrowd/tasks/FETCH_TASK_FAILURE';
export var REFRESH_TASK_REQUEST = 'timecrowd/tasks/REFRESH_TASK_REQUEST';
export var REFRESH_TASK_SUCCESS = 'timecrowd/tasks/REFRESH_TASK_SUCCESS';
export var REFRESH_TASK_FAILURE = 'timecrowd/tasks/REFRESH_TASK_FAILURE';
export var CREATE_TASK_REQUEST = 'timecrowd/tasks/CREATE_TASK_REQUEST';
export var CREATE_TASK_SUCCESS = 'timecrowd/tasks/CREATE_TASK_SUCCESS';
export var CREATE_TASK_FAILURE = 'timecrowd/tasks/CREATE_TASK_FAILURE';
export var UPDATE_TASK_REQUEST = 'timecrowd/tasks/UPDATE_TASK_REQUEST';
export var UPDATE_TASK_SUCCESS = 'timecrowd/tasks/UPDATE_TASK_SUCCESS';
export var UPDATE_TASK_FAILURE = 'timecrowd/tasks/UPDATE_TASK_FAILURE';
export var MERGE_TASK_REQUEST = 'timecrowd/tasks/MERGE_TASK_REQUEST';
export var MERGE_TASK_SUCCESS = 'timecrowd/tasks/MERGE_TASK_SUCCESS';
export var MERGE_TASK_FAILURE = 'timecrowd/tasks/MERGE_TASK_FAILURE';
export var TRASH_TASK_REQUEST = 'timecrowd/tasks/TRASH_TASK_REQUEST';
export var TRASH_TASK_SUCCESS = 'timecrowd/tasks/TRASH_TASK_SUCCESS';
export var TRASH_TASK_FAILURE = 'timecrowd/tasks/TRASH_TASK_FAILURE';
export var initialState = {
    isLoading: false,
    isFetched: false,
    items: [],
};
function normalizeUpdate(state, action) {
    if (action.task.deleted_at) {
        return __assign(__assign({}, state), { items: state.items.filter(function (task) {
                return task.id !== action.task.id;
            }) });
    }
    else {
        return __assign(__assign({}, state), { items: state.items.map(function (task) {
                if (task.id === action.task.id) {
                    return action.task;
                }
                else {
                    return task;
                }
            }) });
    }
}
export default function tasks(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_TASK_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case REFRESH_TASK_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isFetched: false });
        case CREATE_TASK_REQUEST:
        case UPDATE_TASK_REQUEST:
        case MERGE_TASK_REQUEST:
        case TRASH_TASK_REQUEST:
            return state;
        case FETCH_TASK_SUCCESS:
        case REFRESH_TASK_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isFetched: true, items: [action.task] });
        case CREATE_TASK_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: __spreadArray(__spreadArray([], state.items, true), [action.task], false) });
        case UPDATE_TASK_SUCCESS:
        case TRASH_TASK_SUCCESS:
            return normalizeUpdate(state, action);
        case MERGE_TASK_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.map(function (task) {
                    if (task.id === action.id) {
                        return action.task;
                    }
                    else {
                        return task;
                    }
                }) });
        case FETCH_TASK_FAILURE:
        case REFRESH_TASK_FAILURE:
            return __assign(__assign({}, state), { isLoading: false, isFetched: false });
        case UPDATE_TASK_FAILURE:
        case MERGE_TASK_FAILURE:
        case TRASH_TASK_FAILURE:
            return state;
        default:
            return state;
    }
}
export var fetchTaskRequest = function () { return ({
    type: FETCH_TASK_REQUEST,
}); };
export var fetchTaskSuccess = function (task) { return ({
    type: FETCH_TASK_SUCCESS,
    task: task,
}); };
export var fetchTaskFailure = function () { return ({
    type: FETCH_TASK_FAILURE,
}); };
export var refreshTaskRequest = function () { return ({
    type: REFRESH_TASK_REQUEST,
}); };
export var refreshTaskSuccess = function (task) { return ({
    type: REFRESH_TASK_SUCCESS,
    task: task,
}); };
export var refreshTaskFailure = function () { return ({
    type: REFRESH_TASK_FAILURE,
}); };
export var createTaskRequest = function () { return ({
    type: CREATE_TASK_REQUEST,
}); };
export var createTaskSuccess = function (task) { return ({
    type: CREATE_TASK_SUCCESS,
    task: task,
}); };
export var createTaskFailure = function () { return ({
    type: CREATE_TASK_FAILURE,
}); };
export var updateTaskRequest = function () { return ({
    type: UPDATE_TASK_REQUEST,
}); };
export var updateTaskSuccess = function (task) { return ({
    type: UPDATE_TASK_SUCCESS,
    task: task,
}); };
export var updateTaskFailure = function () { return ({
    type: UPDATE_TASK_FAILURE,
}); };
export var mergeTaskRequest = function () { return ({
    type: MERGE_TASK_REQUEST,
}); };
export var mergeTaskSuccess = function (id, task) { return ({
    type: MERGE_TASK_SUCCESS,
    id: id,
    task: task,
}); };
export var mergeTaskFailure = function () { return ({
    type: MERGE_TASK_FAILURE,
}); };
export var trashTaskRequest = function () { return ({
    type: TRASH_TASK_REQUEST,
}); };
export var trashTaskSuccess = function (task) { return ({
    type: TRASH_TASK_SUCCESS,
    task: task,
}); };
export var trashTaskFailure = function () { return ({
    type: TRASH_TASK_FAILURE,
}); };
export var createTask = function (teamId, params) {
    return function (dispatch, getState) {
        dispatch(createTaskRequest());
        return api(dispatch, getState, "teams/".concat(teamId, "/tasks"), 'POST', params).then(function (response) {
            if (response.error) {
                dispatch(createTaskFailure());
                return { error: true };
            }
            else {
                dispatch(createTaskSuccess(response));
                return response;
            }
        });
    };
};
