import React from 'react';
import I18n from '../../constants/i18n';
export default function TeamLabel(_a) {
    var teams = _a.teams;
    var label;
    if (teams.length === 0) {
        label = I18n.t('export.noTeams');
    }
    else if (1 === teams.length) {
        label = teams[0].name;
    }
    else {
        label = I18n.t('export.multipleTeams');
    }
    return <span>{label}</span>;
}
