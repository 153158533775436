import React from 'react';
import I18n from '@/constants/i18n';
import { isCreateMode } from './helpers';
import CustomCheck from '@/components/atoms/custom_check';
import variants from '@/constants/variants';
export var TaskFormFromPageToggle = function (_a) {
    var mode = _a.mode, fromPageTitle = _a.fromPageTitle, onSetFromPageTitle = _a.onSetFromPageTitle, onChange = _a.onChange, _b = _a.isChromeExtension, isChromeExtension = _b === void 0 ? variants.isChromeExtension : _b, _c = _a.saveFromPageTitle, saveFromPageTitle = _c === void 0 ? variants.saveFromPageTitle : _c, _d = _a.loadFromPageTitle, loadFromPageTitle = _d === void 0 ? variants.loadFromPageTitle : _d, _e = _a.getPage, getPage = _e === void 0 ? variants.getPage : _e, hidden = _a.hidden;
    var disabled = hidden || !isChromeExtension || !isCreateMode(mode);
    React.useEffect(function () {
        if (disabled) {
            return;
        }
        loadFromPageTitle(function (flag) {
            onSetFromPageTitle(flag);
            if (flag) {
                getPage(function (page) {
                    onChange(page.title, page.url, false);
                });
            }
        });
    }, [disabled, getPage, loadFromPageTitle, onChange, onSetFromPageTitle]);
    if (disabled) {
        return null;
    }
    var handleClickFromPageTitle = function () {
        var nextFromPageTitle = !fromPageTitle;
        var getPageOrBlank = nextFromPageTitle
            ? getPage
            : function (cb) { return cb({ title: '', url: '' }); };
        getPageOrBlank(function (page) {
            onChange(page.title, page.url, !nextFromPageTitle);
        });
        onSetFromPageTitle(nextFromPageTitle);
        saveFromPageTitle(nextFromPageTitle);
    };
    return (<CustomCheck checked={fromPageTitle} className="position-absolute -top-12 text-white d-flex align-items-center" descriptionClassName="small" onClick={handleClickFromPageTitle}>
      {I18n.t('shared.getTitleAndURLfromPage')}
    </CustomCheck>);
};
