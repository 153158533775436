import React from 'react';
import I18n from '@/constants/i18n';
import { PayjpCheckoutButton } from '@/components/payjp_checkout_button';
var CreditCardSetting = function (_a) {
    var cardBrand = _a.cardBrand, cardExpMonth = _a.cardExpMonth, cardExpYear = _a.cardExpYear, cardLast4 = _a.cardLast4, cardName = _a.cardName, email = _a.email, isFailed = _a.isFailed, publicKey = _a.publicKey, onCreateToken = _a.onCreateToken;
    return (<div className="form-group row">
      <div className="col-md-8 col-12">
        <p className="h3 mb-3">{I18n.t('payment.creditCardSettings')}</p>
        {isFailed && (<p className="text-danger">{I18n.t('shared.paymentIsFailed')}</p>)}
        <p>
          {cardBrand} XXXX-XXXX-XXXX-
          {cardLast4} {cardExpYear}/{cardExpMonth} {cardName}
        </p>
        <div className="mb-3">
          <PayjpCheckoutButton dataSubmitText={I18n.t('payment.payjp.submitText')} dataText={I18n.t('payment.payjp.text')} email={email} publicKey={publicKey} onCreateToken={onCreateToken}/>
        </div>
      </div>
    </div>);
};
export default CreditCardSetting;
