import { __assign } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import Helplink from '../molecules/helplink';
import variants from '@/constants/variants';
export var TeamOwnersInviteAllowedField = function (props) {
    var workspace = props.workspace, setWorkspace = props.setWorkspace, _a = props.openURL, openURL = _a === void 0 ? variants.openURL : _a;
    if (workspace.plan_type === 'personal') {
        return null;
    }
    return (<>
      <hr className="mt-3"/>

      <p className="mt-3 font-weight-normal">
        {I18n.t('shared.teamOwnersInviteAllowedTitle')}
      </p>

      <CustomCheck checked={workspace.team_owners_invite_allowed} className="d-block mt-1" onChange={function (e) {
            setWorkspace(__assign(__assign({}, workspace), { team_owners_invite_allowed: e.target.checked }));
        }}>
        {I18n.t('shared.teamOwnersInviteAllowedLabel')}
        <Helplink className="ml-2" href="https://support.timecrowd.net/1428ec2d2afa8026884bc374305c33ff" onClick={function (e) {
            e.preventDefault();
            openURL(e.currentTarget.href);
        }}/>
      </CustomCheck>

      <p className="mt-1 ml-3 small" dangerouslySetInnerHTML={{
            __html: I18n.t('shared.teamOwnersInviteAllowedDescription'),
        }}/>
    </>);
};
