import React from 'react';
import I18n from '@/constants/i18n';
export var TeamNameField = function (_a) {
    var team = _a.team, onChangeTeam = _a.onChangeTeam, className = _a.className;
    return (<div className={className}>
      {I18n.t('shared.teamName')}
      <span className="text-danger">*</span>
      <div className="mt-2">
        <input autoFocus className="form-control" type="text" value={team.name} onChange={function (e) { return onChangeTeam('name', e.target.value); }}/>
      </div>
    </div>);
};
