import React from 'react';
import { TimeEntryInlineTimeInput } from './time_entry_inline_time_input';
import { formatTime } from '@/utils/time_helper';
import { findTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
import { useThrottle } from '@/hooks/use_throttle';
export function TimeEntryInlineTimeField(_a) {
    var id = _a.id, startedAt = _a.startedAt, stoppedAt = _a.stoppedAt, canUpdate = _a.canUpdate, updateTimeEntry = _a.updateTimeEntry, timeEntrySuggestions = _a.timeEntrySuggestions, fetchTimeEntrySuggestion = _a.fetchTimeEntrySuggestion, isMoreThan24hour = _a.isMoreThan24hour, isShowSuggestion = _a.isShowSuggestion, isPC = _a.isPC, isChromeExtension = _a.isChromeExtension;
    var _b = React.useState(false), isEditingStartedAt = _b[0], setIsEditingStartedAt = _b[1];
    var _c = React.useState(false), isEditingStoppedAt = _c[0], setIsEditingStoppedAt = _c[1];
    var _d = React.useState(null), timeEntrySuggestion = _d[0], setTimeEntrySuggestion = _d[1];
    var startedAtTimestamp = startedAt.valueOf();
    var throttledFetchTimeEntrySuggestion = useThrottle(fetchTimeEntrySuggestion, 3000);
    React.useEffect(function () {
        if (isShowSuggestion) {
            throttledFetchTimeEntrySuggestion(startedAt.toDate());
        }
        // NOTE: startedAtはMomentオブジェクトで不要なときにも差分検知されるので、timestampを見ている
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [throttledFetchTimeEntrySuggestion, isShowSuggestion, startedAtTimestamp]);
    React.useEffect(function () {
        var found = findTimeEntrySuggestion(timeEntrySuggestions, startedAt.toDate());
        if (found) {
            setTimeEntrySuggestion(found);
        }
        // NOTE: startedAtはMomentオブジェクトで不要なときにも差分検知されるので、timestampを見ている
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowSuggestion, startedAtTimestamp, timeEntrySuggestions]);
    var prevStoppedAtString = (timeEntrySuggestion === null || timeEntrySuggestion === void 0 ? void 0 : timeEntrySuggestion.prevTimeEntry) &&
        formatTime(new Date(timeEntrySuggestion.prevTimeEntry.stopped_at), false);
    var nextStartedAtString = (timeEntrySuggestion === null || timeEntrySuggestion === void 0 ? void 0 : timeEntrySuggestion.nextTimeEntry) &&
        formatTime(new Date(timeEntrySuggestion.nextTimeEntry.started_at), false);
    return (<div className="time-entry-times d-flex">
      <div className="ml-1 pr-3" onMouseDown={function (e) {
            if (isPC || isChromeExtension) {
                e.stopPropagation();
            }
        }}>
        <TimeEntryInlineTimeInput attribute="started_at" baseTime={startedAt} datetime={startedAt} focusNextField={function () { return setIsEditingStoppedAt(true); }} id={id} isChromeExtension={isChromeExtension} isEditing={canUpdate && isEditingStartedAt} isMoreThan24hour={isMoreThan24hour} isPC={isPC} prevTimeEntry={isShowSuggestion ? timeEntrySuggestion === null || timeEntrySuggestion === void 0 ? void 0 : timeEntrySuggestion.prevTimeEntry : null} setIsEditing={setIsEditingStartedAt} suggestionString={prevStoppedAtString} updateTimeEntry={updateTimeEntry}/>
        <span> - </span>
        <TimeEntryInlineTimeInput attribute="stopped_at" baseTime={startedAt} datetime={stoppedAt} focusNextField={function () { return setIsEditingStartedAt(true); }} id={id} isChromeExtension={isChromeExtension} isEditing={canUpdate && isEditingStoppedAt} isMoreThan24hour={isMoreThan24hour} isPC={isPC} nextTimeEntry={isShowSuggestion ? timeEntrySuggestion === null || timeEntrySuggestion === void 0 ? void 0 : timeEntrySuggestion.nextTimeEntry : null} setIsEditing={setIsEditingStoppedAt} suggestionString={nextStartedAtString} updateTimeEntry={updateTimeEntry}/>
      </div>
    </div>);
}
