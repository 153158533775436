import { __assign } from "tslib";
import React from 'react';
import { Tooltip } from 'reactstrap';
import flatten from 'lodash/flatten';
import { numberWithDelimiter } from '@/utils/number_helper';
import { formatDuration, calcUserSummaries, calcTotalDuration, calcTotalAmount, calcRatio, } from '@/components/organisms/report_member_activities/helpers';
import Icon from '@/components/atoms/icon';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
export var TeamReportDetailsTooltip = function (_a) {
    var categories = _a.categories, targetId = _a.targetId, timeEntries = _a.timeEntries, users = _a.users, category = _a.category, task = _a.task, tasksByCategoryId = _a.tasksByCategoryId, isOpen = _a.isOpen;
    var _b = React.useState(null), cache = _b[0], setCache = _b[1];
    React.useEffect(function () {
        if (!isOpen || cache) {
            return;
        }
        var tasks = flatten(categories.map(function (category) {
            return (tasksByCategoryId[category.id] || []).map(function (task) { return (__assign(__assign({}, task), { category_id: category.id })); });
        }));
        var categoryTaskIds = tasks
            .filter(function (task) { return task.category_id === (category === null || category === void 0 ? void 0 : category.id); })
            .map(function (task) { return task.id; });
        var filteredEntries = timeEntries.filter(function (timeEntry) {
            return categoryTaskIds.includes(timeEntry.time_trackable_id) ||
                timeEntry.time_trackable_id === (task === null || task === void 0 ? void 0 : task.id);
        });
        var _a = calcUserSummaries(categories, tasks, filteredEntries, users), userIds = _a.userIds, userSummaries = _a.userSummaries;
        var totalDuration = calcTotalDuration(filteredEntries);
        var totalAmount = calcTotalAmount(filteredEntries);
        setCache({
            userIds: userIds,
            userSummaries: userSummaries,
            totalDuration: totalDuration,
            totalAmount: totalAmount,
        });
    }, [
        isOpen,
        categories,
        users,
        category,
        task,
        cache,
        tasksByCategoryId,
        timeEntries,
    ]);
    React.useEffect(function () {
        setCache(null);
    }, [timeEntries]);
    if (!categories.length || !cache) {
        return null;
    }
    var userIds = cache.userIds, userSummaries = cache.userSummaries, totalDuration = cache.totalDuration, totalAmount = cache.totalAmount;
    return (<Tooltip className="report-details-tooltip" fade={false} isOpen={isOpen} target={targetId}>
      <table className="table mb-0">
        <thead>
          <tr>
            <th className="text-nowrap text-left">
              {category !== undefined ? (<>
                  <CategoryCircleIcon color={category.color}/>
                  {category.title}
                </>) : (task === null || task === void 0 ? void 0 : task.title)}
            </th>
            <th className="text-nowrap text-right">
              <Icon className="text-muted mr-1" name="clock"/>
              {formatDuration(totalDuration)}
            </th>
            <th />
            <th className="text-nowrap text-right">
              <span className="text-muted mr-1">¥</span>
              {numberWithDelimiter(totalAmount)}
            </th>
          </tr>
        </thead>
        <tbody>
          {userIds.map(function (userId) {
            var _a = userSummaries[userId], user = _a.user, userDuration = _a.userDuration, userAmount = _a.userAmount;
            // NOTE: チーム切り替え直後にteamReportとusers(=reportableUsers)が不整合になるので、ユーザーが見つからないタイミングがある
            if (!user) {
                return null;
            }
            return (<tr key={userId}>
                <td className="text-nowrap text-left">{user.nickname}</td>
                <td className="text-nowrap text-right">
                  <Icon className="text-muted mr-1" name="clock"/>
                  {formatDuration(userDuration)}
                </td>
                <td className="text-nowrap text-right">
                  {calcRatio(userDuration, totalDuration)} %
                </td>
                <td className="text-nowrap text-right">
                  <span className="text-muted mr-1">¥</span>
                  {numberWithDelimiter(userAmount)}
                </td>
              </tr>);
        })}
        </tbody>
      </table>
    </Tooltip>);
};
