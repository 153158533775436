import React from 'react';
import I18n from '@/constants/i18n';
import TeamSelect from '@/components/selects/team_select';
var EditChildTeamForm = function (props) {
    var team = props.team, teams = props.teams, onUpdate = props.onUpdate;
    var _a = React.useState({}), selected = _a[0], setSelected = _a[1];
    var _b = React.useState(false), disabled = _b[0], setDisabled = _b[1];
    var handleSelectTeam = function (selected) {
        setSelected(selected);
    };
    var handleClickAdd = function () {
        setDisabled(true);
        onUpdate(selected.id, { team: { parent_id: team.id } }).then(function () {
            setDisabled(false);
        });
    };
    return (<div>
      <h3 className="mb-2">{I18n.t('shared.addChildTeam')}</h3>
      <div className="row">
        <div className="col-md-4 col-12 mb-2">
          <TeamSelect teams={teams} onClick={handleSelectTeam}/>
        </div>
        <div className="col-md-2 col-12">
          <button className="btn-lg px-3 btn btn-primary" disabled={!selected.id || disabled} onClick={handleClickAdd}>
            {I18n.t('shared.add')}
          </button>
        </div>
      </div>
    </div>);
};
export default EditChildTeamForm;
