import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames';
export function CompareBar(_a) {
    var _b;
    var _c = _a.color, color = _c === void 0 ? 'transparent' : _c, _d = _a.size, size = _d === void 0 ? 'md' : _d, _e = _a.percent, percent = _e === void 0 ? 0 : _e, _f = _a.maxWidthPx, maxWidthPx = _f === void 0 ? 240 : _f, className = _a.className, _g = _a.disabled, disabled = _g === void 0 ? false : _g, _h = _a.style, style = _h === void 0 ? {} : _h;
    var classes = classNames('compare-bar', (_b = {},
        _b["compare-bar-".concat(size)] = typeof size !== 'undefined',
        _b[className] = typeof className === 'string',
        _b.disabled = disabled,
        _b));
    var styles = __assign({ backgroundColor: color, width: "".concat(Math.ceil(maxWidthPx * (percent / 100)), "px") }, style);
    return <span className={classes} style={styles}/>;
}
export default CompareBar;
