import { isSameOrBefore } from '@/utils/time_helper';
export var findCurrentEmploy = function (employs) {
    var teamEmploys = employs.filter(function (employ) { return !employ.workspaces_workspace_id; });
    var workspaceEmploys = employs.filter(function (employ) { return employ.workspaces_workspace_id; });
    var currentEmploy = teamEmploys
        .reverse()
        .find(function (_a) {
        var started_at = _a.started_at;
        return isSameOrBefore(started_at, new Date());
    }) ||
        workspaceEmploys
            .reverse()
            .find(function (_a) {
            var started_at = _a.started_at;
            return isSameOrBefore(started_at, new Date());
        }) ||
        workspaceEmploys[0];
    return currentEmploy;
};
export var isShowCurrentEmployLabel = function (employ, currentEmploy, employs) {
    return (employs.length > 1 &&
        employ.id === (currentEmploy === null || currentEmploy === void 0 ? void 0 : currentEmploy.id) &&
        isSameEmploy(employ, currentEmploy));
};
export var isWorkspaceEmploy = function (employ) {
    return employ && !!employ.workspaces_workspace_id;
};
export var isSameEmploy = function (employ, anotherEmploy) {
    return (employ.id === anotherEmploy.id &&
        employ.workspaces_workspace_id === anotherEmploy.workspaces_workspace_id);
};
export var isDisabledWorkspaceEmploy = function (employs, employ) {
    if (!isWorkspaceEmploy(employ)) {
        return false;
    }
    return !!employs.some(function (e) {
        return !isWorkspaceEmploy(e) && isSameOrBefore(e.started_at, employ.started_at);
    });
};
