import React from 'react';
import ExpansionPanelHeader from '@/components/molecules/expansion_panel_header';
import ExpansionPanelBody from '@/components/atoms/expansion_panel_body';
import Avatar from '@/components/atoms/avatar';
import ExpansionPanel from '@/components/atoms/expansion_panel';
import { CategoryInfoContainer } from '@/containers/category_info_container';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
export var TeamCategoriesList = function (_a) {
    var teams = _a.teams, category = _a.category, categories = _a.categories, setCategory = _a.setCategory, isExpandAll = _a.isExpandAll, withoutTeam = _a.withoutTeam;
    return (<>
    {teams.map(function (team) {
            var teamCategories = categories.filter(function (category) { return category.team_id === team.id; });
            if (!teamCategories.length) {
                return null;
            }
            return (<ExpansionPanel key={"".concat((category === null || category === void 0 ? void 0 : category.id) || 0, "-").concat(team.id)} expand={(category && category.team_id === team.id) || isExpandAll}>
          {function (expand, toggle) { return (<>
              {!withoutTeam && (<ExpansionPanelHeader action expand={expand} toggle={toggle}>
                  <div className="expansion-panel-label">
                    <WorkspaceAvatarContainer withName className="small font-weight-bold mt-1" inline={false} workspaceId={team.workspaces_workspace_id}/>
                    <div className="d-flex align-items-center my-1">
                      <Avatar className="mr-1" image={team.avatar_url} size="sm"/>
                      <div className="d-flex text-break whitespace-normal leading-8">
                        {team.name}
                      </div>
                    </div>
                  </div>
                </ExpansionPanelHeader>)}
              <ExpansionPanelBody expand={expand}>
                <div className="list-group list-group-flush">
                  {teamCategories.map(function (category) {
                        return (<div key={"categories-".concat(team.id, "-").concat(category.id)} className="list-group-item list-group-item-action text-break whitespace-normal" style={{
                                paddingLeft: "".concat((category.ancestry_depth + 1) * 1.2, "rem"),
                            }} onClick={function () {
                                setCategory(category);
                            }}>
                        <i className={[
                                'category-circle',
                                'category-' + category.color,
                            ].join(' ')}/>
                        {category.title}
                        <CategoryInfoContainer requiresCustomFields category={category} className="mx-1"/>
                      </div>);
                    })}
                </div>
              </ExpansionPanelBody>
            </>); }}
        </ExpansionPanel>);
        })}
  </>);
};
