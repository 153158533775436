import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import uniqBy from 'lodash/uniqBy';
import I18n from '@/constants/i18n';
import { CategorySelector } from '@/components/molecules/category_selector';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
export var CategoryDropdown = function (_a) {
    var categories = _a.categories, selectedId = _a.selectedId, onSelect = _a.onSelect, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.isExpandAll, isExpandAll = _c === void 0 ? false : _c;
    var _d = React.useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var selectedCategory = categories.find(function (_a) {
        var id = _a.id;
        return id === selectedId;
    });
    var teams = uniqBy(categories.map(function (_a) {
        var team = _a.team;
        return team;
    }), 'id');
    return (<>
      <Dropdown isOpen={isOpen} toggle={function () { return setIsOpen(!isOpen); }}>
        <DropdownToggle caret className="max-w-lg" disabled={disabled}>
          <span className="text-truncate">
            {selectedCategory ? (<>
                <CategoryCircleIcon color={selectedCategory.color}/>
                <span>
                  {selectedCategory.title} - {selectedCategory.team.name}
                </span>
              </>) : (I18n.t('shared.notSelected'))}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <CategorySelector allowsUnselect categories={categories} category={selectedCategory} isExpandAll={isExpandAll} isOpened={isOpen} setCategory={function (category) {
            setIsOpen(false);
            onSelect((category === null || category === void 0 ? void 0 : category.id) || null);
        }} teams={teams}/>
        </DropdownMenu>
      </Dropdown>
    </>);
};
