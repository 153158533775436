export var NETWORK_ERROR_MESSAGES = new RegExp([
    'Failed to fetch',
    'インターネット接続がオフラインのようです。',
    'The Internet connection appears to be offline\\.',
    'The network connection was lost\\.',
    'NetworkError when attempting to fetch resource\\.',
    'The operation couldn’t be completed\\. Software caused connection abort',
    'The request timed out\\.|A server with the specified hostname could not be found\\.',
    'Unexpected token < in JSON at position 0',
].join('|'));
