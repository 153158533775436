import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n from '@/constants/i18n';
import { openModal } from '@/modules/modal';
import { updateWorkspaceCard } from '@/actions/workspace_card';
import CreditCardSetting from '@/components/credit_card_setting';
var CreditCardSettingContainer = function (_a) {
    var payment = _a.payment;
    var email = useSelector(function (state) { return state.user.email; });
    var dispatch = useDispatch();
    var handleCreatePayjpToken = React.useCallback(function (token) {
        var params = { 'payjp-token': token };
        dispatch(updateWorkspaceCard(params)).then(function (payment) {
            if (payment.error) {
                return;
            }
            dispatch(openModal('CONFIRM', {
                header: I18n.t('card.modal.header'),
                body: I18n.t('card.modal.body'),
            }));
        });
    }, [dispatch]);
    var card = payment.card;
    return (<CreditCardSetting cardBrand={card.brand} cardExpMonth={card.exp_month} cardExpYear={card.exp_year} cardLast4={card.last4} cardName={card.name} email={email} isFailed={payment.is_failed} publicKey={payment.public_key} onCreateToken={handleCreatePayjpToken}/>);
};
export default CreditCardSettingContainer;
