import React from 'react';
import { Link } from 'react-router-dom';
import ClassNames from 'classnames';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import { NameWithTooltip } from '@/components/name_with_tooltip';
export var WorkspacesAccordionSettings = function (props) {
    var workspace = props.workspace, currentRoute = props.currentRoute, teamsByWorkspaceId = props.teamsByWorkspaceId, _a = props.forMobile, forMobile = _a === void 0 ? false : _a;
    var pathPrefix = "/app/workspaces/".concat(workspace.id);
    var _b = React.useState(false), isSearching = _b[0], setIsSearching = _b[1];
    var _c = React.useState(''), searchText = _c[0], setSearchText = _c[1];
    var searchRef = React.useRef(null);
    React.useEffect(function () {
        var _a;
        if (isSearching) {
            (_a = searchRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isSearching]);
    var handleBluer = function () {
        if (!searchText) {
            setIsSearching(false);
        }
    };
    var _d = React.useMemo(function () {
        var _a;
        var teams = (_a = teamsByWorkspaceId[workspace.id]) !== null && _a !== void 0 ? _a : [];
        var filteredTeams = !searchText
            ? teams
            : teams.filter(function (team) { return team.name.includes(searchText); });
        return [teams, filteredTeams];
    }, [teamsByWorkspaceId, workspace.id, searchText]), teams = _d[0], filteredTeams = _d[1];
    var isEnabledSearch = teams.length > 4;
    var gradientClass = isEnabledSearch
        ? "scrollable-gradient".concat(forMobile ? '' : '-inverse')
        : '';
    return (<>
      <li className="nav-item">
        <Link className={ClassNames({
            'nav-link': true,
            active: currentRoute.indexOf("".concat(pathPrefix, "/tasks")) > -1,
        })} to={"".concat(pathPrefix, "/tasks")}>
          <Icon className="mr-2" name="inbox" size="md"/>
          {I18n.t('other.taskManagement')}
        </Link>
      </li>
      {isEnabledSearch && (<li className="nav-item mt-1 mb-2 d-flex">
          {isSearching ? (<input ref={searchRef} className={"ml-2 w-100 form-control ".concat(forMobile ? 'mr-2' : 'mr-1 py-0 bg-white/30 text-white')} value={searchText} onBlur={handleBluer} onChange={function (e) { return setSearchText(e.target.value); }}/>) : (<div className={"ml-2 w-100 p-1 rounded !tw-cursor-pointer ".concat(forMobile ? 'mr-2 form-control text-muted' : 'mr-1 bg-white/30 text-white/50')} onClick={function () { return setIsSearching(true); }}>
              <Icon className="mr-1" name="search"/>
              {I18n.t('shared.searchByTeamName')}
            </div>)}
        </li>)}
      <li className={"position-relative ".concat(gradientClass)}>
        <ul className={"navbar-nav flex-column ".concat(isEnabledSearch ? 'h-80 overflow-y-auto scrollbar-thin scrollbar-dark' : '')}>
          {filteredTeams.map(function (team) { return (<li key={team.id} className="nav-item">
              <Link className={ClassNames('nav-link text-left h-auto', {
                active: currentRoute.indexOf("".concat(pathPrefix, "/teams/").concat(team.id, "/")) >
                    -1,
            })} to={"".concat(pathPrefix, "/teams/").concat(team.id, "/members")}>
                <Avatar className="mr-2 border-0" image={team.avatar_url} size="icon-md"/>
                <NameWithTooltip name={team.name} withTooltip={!forMobile}/>
              </Link>
            </li>); })}
        </ul>
      </li>
    </>);
};
