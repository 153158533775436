import { __assign, __awaiter, __generator } from "tslib";
import queryString from 'query-string';
import api from '@/api';
import api2, { getNextLink } from '@/api2';
import { setWorkingUsersCount } from '@/actions/setter';
import { setTasks, expandTasks, changeTask, removeTask, fetchSearchedTasksRequest, } from '@/modules/task_management';
import { fetchSuggestTasksRequest, expandSuggestTasksRequest, fetchUserTaskSuggestionsRequest, expandUserTaskSuggestionsRequest, setMatchedTasks, appendMatchedTasks, } from '@/modules/task_search';
import { setCurrentEntry, stopCurrentEntryRequest, stopCurrentEntrySuccess, stopCurrentEntryFailure, } from '@/modules/current_entry';
export var fetchWorkingUsersCount = function () {
    return function (dispatch, getState) {
        return api2(dispatch, getState, '/api/v2/working_users_counts', 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (json) {
                    dispatch(setWorkingUsersCount(json));
                    return json;
                });
            }
            else {
                return { error: true };
            }
        });
    };
};
export var startTask = function (task) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(task.team_id, "/tasks/").concat(task.id, "/start"), 'POST').then(function (entry) {
            if (entry.id) {
                dispatch(fetchWorkingUsersCount());
                dispatch(setCurrentEntry(entry));
            }
        });
    };
};
export var updateTask = function (teamId, id, params) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(teamId, "/tasks/").concat(id), 'PATCH', {
            task: params,
        }).then(function (task) {
            if (!task.error) {
                dispatch(changeTask(task));
            }
            return task;
        });
    };
};
export var createAndStartTask = function (task, comment) {
    return function (dispatch, getState) {
        var body = {
            task: task,
            comment: comment,
        };
        return api(dispatch, getState, "time_entries", 'POST', body).then(function (entry) {
            if (entry.id) {
                dispatch(fetchWorkingUsersCount());
                dispatch(setCurrentEntry(entry));
            }
        });
    };
};
export var stopTimeEntry = function (entry) {
    return function (dispatch, getState) {
        var state = getState();
        dispatch(stopCurrentEntryRequest());
        return api(dispatch, getState, "time_entries/".concat(entry.id), 'PATCH', {}).then(function (_a) {
            var _b;
            var error = _a.error;
            if (error) {
                dispatch(stopCurrentEntryFailure());
                return { error: error };
            }
            if (((_b = state.currentEntry.current) === null || _b === void 0 ? void 0 : _b.id) === entry.id) {
                dispatch(stopCurrentEntrySuccess());
            }
            return { error: false };
        });
    };
};
export var destroyTask = function (teamId, id) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(teamId, "/tasks/").concat(id), 'DELETE').then(function (response) {
            if (response.error) {
                return { error: true };
            }
            else {
                dispatch(removeTask(id));
                return { error: false };
            }
        });
    };
};
export var fetchSearchedTasks = function (params) {
    return function (dispatch, getState) {
        dispatch(fetchSearchedTasksRequest());
        return api2(dispatch, getState, '/api/v2/tasks/search?new=1', 'POST', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var next, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        next = getNextLink(response);
                        return [4 /*yield*/, response.json()];
                    case 1:
                        json = _a.sent();
                        dispatch(setTasks(json, next, params));
                        return [3 /*break*/, 3];
                    case 2:
                        dispatch(setTasks([], null, params));
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
};
export var expandSearchedTasks = function () {
    return function (dispatch, getState) {
        var _a = getState().taskManagement, next = _a.next, params = _a.params;
        if (!next) {
            return;
        }
        return api2(dispatch, getState, next, 'POST', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var newNext, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        newNext = getNextLink(response);
                        return [4 /*yield*/, response.json()];
                    case 1:
                        json = _a.sent();
                        dispatch(expandTasks(json, newNext));
                        return [3 /*break*/, 3];
                    case 2:
                        dispatch(expandTasks([], null));
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
};
export var fetchSuggestTasks = function (params) {
    var query = buildQuery(params);
    return function (dispatch, getState) {
        dispatch(fetchSuggestTasksRequest());
        return api2(dispatch, getState, "/api/v2/tasks/suggestions".concat(query, "&new=1"), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var next, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        next = getNextLink(response);
                        return [4 /*yield*/, response.json()];
                    case 1:
                        json = _a.sent();
                        dispatch(setMatchedTasks(json, next, params));
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
};
export var expandSuggestTasks = function () {
    return function (dispatch, getState) {
        var next = getState().taskSearch.next;
        dispatch(expandSuggestTasksRequest());
        return api2(dispatch, getState, next, 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var newNext, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        newNext = getNextLink(response);
                        return [4 /*yield*/, response.json()];
                    case 1:
                        json = _a.sent();
                        dispatch(appendMatchedTasks(json, newNext));
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
};
export var fetchUserTaskSuggestions = function (user_id, params) {
    var query = buildQuery(params);
    return function (dispatch, getState) {
        dispatch(fetchUserTaskSuggestionsRequest());
        return api2(dispatch, getState, "/api/v2/users/".concat(user_id, "/task_suggestions").concat(query), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var next, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        next = getNextLink(response);
                        return [4 /*yield*/, response.json()];
                    case 1:
                        json = _a.sent();
                        dispatch(setMatchedTasks(json, next, params));
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
};
export var expandUserTaskSuggestions = function () {
    return function (dispatch, getState) {
        var next = getState().taskSearch.next;
        dispatch(expandUserTaskSuggestionsRequest());
        return api2(dispatch, getState, next, 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var newNext, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        newNext = getNextLink(response);
                        return [4 /*yield*/, response.json()];
                    case 1:
                        json = _a.sent();
                        dispatch(appendMatchedTasks(json, newNext));
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
};
export var restoreTask = function (teamId, taskId) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(teamId, "/tasks/").concat(taskId, "/restore"), 'PATCH').then(function (task) {
            if (task.error) {
                return;
            }
            dispatch(changeTask(task));
        });
    };
};
export var createTaskWithTimeEntry = function (teamId, task, time_entry, comment) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(teamId, "/tasks"), 'POST', {
            task: task,
            time_entry: __assign(__assign({}, time_entry), { input_type: 'manual' }),
            comment: comment,
        });
    };
};
export var fetchSameKeyTask = function (id, params) {
    var query = buildQuery(params);
    return function (dispatch, getState) {
        return api(dispatch, getState, "same_key_tasks/".concat(id).concat(query), 'GET');
    };
};
var buildQuery = function (params) {
    var _query = queryString.stringify(params);
    return _query ? "?".concat(_query) : '';
};
