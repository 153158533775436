import React from 'react';
import classNames from 'classnames';
var LoadingSpinner = function (props) {
    var _a;
    var className = props.className, _b = props.tag, Tag = _b === void 0 ? 'span' : _b, _c = props.size, size = _c === void 0 ? 'textsize' : _c, _d = props.position, position = _d === void 0 ? 'normal' : _d, _e = props.color, color = _e === void 0 ? 'normal' : _e;
    var conditionals = (_a = {},
        _a['loading-spinner-' + size] = true,
        _a['loading-spinner-lowkey'] = color === 'lowkey',
        _a['loading-spinner-inverse'] = color === 'inverse',
        _a['absolute-center'] = position === 'absolute-center',
        _a);
    var classes = classNames('loading-spinner', className, conditionals);
    return <Tag className={classes}/>;
};
export default LoadingSpinner;
