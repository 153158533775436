import { __assign } from "tslib";
export var FETCH_PAYMENT_REQUEST = 'timecrowd/payment/FETCH_PAYMENT_REQUEST';
export var FETCH_PAYMENT_SUCCESS = 'timecrowd/payment/FETCH_PAYMENT_SUCCESS';
export var FETCH_PAYMENT_FAILURE = 'timecrowd/payment/FETCH_PAYMENT_FAILURE';
export var CREATE_PAYMENT_REQUEST = 'timecrowd/payment/CREATE_PAYMENT_REQUEST';
export var CREATE_PAYMENT_SUCCESS = 'timecrowd/payment/CREATE_PAYMENT_SUCCESS';
export var CREATE_PAYMENT_FAILED = 'timecrowd/payment/CREATE_PAYMENT_FAILED';
export var UPDATE_PAYMENT_REQUEST = 'timecrowd/payment/UPDATE_PAYMENT_REQUEST';
export var UPDATE_PAYMENT_SUCCESS = 'timecrowd/payment/UPDATE_PAYMENT_SUCCESS';
export var UPDATE_PAYMENT_FAILED = 'timecrowd/payment/UPDATE_PAYMENT_FAILED';
export var DELETE_PAYMENT_REQUEST = 'timecrowd/payment/DELETE_PAYMENT_REQUEST';
export var DELETE_PAYMENT_SUCCESS = 'timecrowd/payment/DELETE_PAYMENT_SUCCESS';
export var DELETE_PAYMENT_FAILED = 'timecrowd/payment/DELETE_PAYMENT_FAILED';
export var RESET_PAYMENT = 'timecrowd/payment/RESET_PAYMENT';
var initialState = {
    isLoading: false,
    isLoaded: false,
    item: {},
};
export default function payment(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_PAYMENT_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isLoaded: false, item: {} });
        case CREATE_PAYMENT_REQUEST:
        case UPDATE_PAYMENT_REQUEST:
        case DELETE_PAYMENT_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_PAYMENT_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isLoaded: true, item: action.payment });
        case CREATE_PAYMENT_SUCCESS:
        case UPDATE_PAYMENT_SUCCESS:
        case DELETE_PAYMENT_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, item: action.payment });
        case FETCH_PAYMENT_FAILURE:
        case CREATE_PAYMENT_FAILED:
        case UPDATE_PAYMENT_FAILED:
        case DELETE_PAYMENT_FAILED:
            return __assign(__assign({}, state), { isLoading: false });
        case RESET_PAYMENT:
            return initialState;
        default:
            return state;
    }
}
export var fetchPaymentRequest = function () { return ({
    type: FETCH_PAYMENT_REQUEST,
}); };
export var fetchPaymentSuccess = function (payment) { return ({
    type: FETCH_PAYMENT_SUCCESS,
    payment: payment,
}); };
export var fetchPaymentFailure = function () { return ({
    type: FETCH_PAYMENT_FAILURE,
}); };
export var createPaymentRequest = function () { return ({
    type: CREATE_PAYMENT_REQUEST,
}); };
export var createPaymentSuccess = function (payment) { return ({
    type: CREATE_PAYMENT_SUCCESS,
    payment: payment,
}); };
export var createPaymentFailed = function () { return ({
    type: CREATE_PAYMENT_FAILED,
}); };
export var updatePaymentRequest = function () { return ({
    type: UPDATE_PAYMENT_REQUEST,
}); };
export var updatePaymentSuccess = function (payment) { return ({
    type: UPDATE_PAYMENT_SUCCESS,
    payment: payment,
}); };
export var updatePaymentFailed = function () { return ({
    type: UPDATE_PAYMENT_FAILED,
}); };
export var deletePaymentRequest = function () { return ({
    type: DELETE_PAYMENT_REQUEST,
}); };
export var deletePaymentSuccess = function (payment) { return ({
    type: DELETE_PAYMENT_SUCCESS,
    payment: payment,
}); };
export var deletePaymentFailed = function () { return ({
    type: DELETE_PAYMENT_FAILED,
}); };
export var resetPayment = function () { return ({
    type: RESET_PAYMENT,
}); };
