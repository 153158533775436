import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TaskForm from '@/components/task_form';
import { setCurrentCategory } from '@/modules/my_task';
import { setFromPageTitle } from '@/modules/from_page_title';
import { freezeScroll } from '@/modules/display';
import { updateTimeEntry } from '@/modules/time_entries';
import { fetchSuggestTasks } from '@/actions/task';
import { fetchTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
import { setNotificationMessage } from '@/modules/notification';
import I18n from '@/constants/i18n';
import { destroyTimeEntry } from '@/modules/time_entries';
import { createTask } from '@/modules/tasks';
import { updateDailyActivity } from '@/modules/daily_activites';
import { formatDate } from '@/utils/time_helper';
import { fetchTask } from '@/api2/tasks';
var TaskFormTimeEntryListContainer = function (_a) {
    var hidesFromPageToggle = _a.hidesFromPageToggle, requiredSelectedTask = _a.requiredSelectedTask, commentRef = _a.commentRef, isMine = _a.isMine, isSearching = _a.isSearching, mode = _a.mode, query = _a.query, selectedTask = _a.selectedTask, setIsSearching = _a.setIsSearching, setSelectedTask = _a.setSelectedTask, timeEntry = _a.timeEntry, toggleTaskForm = _a.toggleTaskForm, onSelectCategory = _a.onSelectCategory, onUpdate = _a.onUpdate;
    var fromPageTitle = useSelector(function (state) { return state.fromPageTitle; });
    var isApp = useSelector(function (state) { return state.isApp; });
    var isChromeExtension = useSelector(function (state) { return state.isChromeExtension; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var timeEntrySuggestionsItems = useSelector(function (state) { return state.timeEntrySuggestions.items; });
    var dispatch = useDispatch();
    var handleStart = function () { };
    var handleCreate = function () { };
    var handleUpdate = function (id, params) {
        var promise = dispatch(updateTimeEntry(id, params)).then(function (timeEntry) {
            if (timeEntry.error) {
                return;
            }
            dispatch(setNotificationMessage('success', I18n.t('calendar.timeEntryUpdated')));
            var date = formatDate(new Date(timeEntry.started_at));
            dispatch(updateDailyActivity(date));
            dispatch(fetchTask(timeEntry.task.id, query));
            toggleTaskForm(-1);
            onUpdate();
        });
        return promise;
    };
    var handleDestroyTimeEntry = function (id) {
        dispatch(destroyTimeEntry(id)).then(function (response) {
            if (response.error) {
                return;
            }
            var date = formatDate(new Date(timeEntry.started_at));
            dispatch(updateDailyActivity(date));
            dispatch(fetchTask(timeEntry.task.id, query));
            toggleTaskForm(-1);
        });
    };
    var handleCancel = function () {
        toggleTaskForm(-1);
    };
    var handleSelectCategory = function (category) {
        onSelectCategory(category);
        dispatch(setCurrentCategory(category));
    };
    var handleSetFromPageTitle = function (fromPageTitle) {
        dispatch(setFromPageTitle(fromPageTitle));
    };
    var handleSetFreezeScroll = React.useCallback(function (isFreeze) {
        dispatch(freezeScroll(isFreeze));
    }, [dispatch]);
    var handleSuggestTasks = function (category, title) {
        var params = {
            title: title,
            category_id: category.id,
            team_id: category.team_id,
        };
        dispatch(fetchSuggestTasks(params));
    };
    var handleFetchTimeEntrySuggestion = React.useCallback(function (time, ignoreId) {
        dispatch(fetchTimeEntrySuggestion(time, ignoreId));
    }, [dispatch]);
    var handleSetNotificationMessage = function (type, message) {
        dispatch(setNotificationMessage(type, message));
    };
    var handleCreateTask = function (teamId, params) {
        return dispatch(createTask(teamId, params));
    };
    var timeEntrySuggestions = isMine ? timeEntrySuggestionsItems : [];
    return (<TaskForm isShowCancelButton isTimeEntrySpecified category={timeEntry.category} commentRef={commentRef} createTask={handleCreateTask} fetchTimeEntrySuggestion={handleFetchTimeEntrySuggestion} fromPageTitle={fromPageTitle} hidesFromPageToggle={hidesFromPageToggle} isChromeExtension={isChromeExtension} isFocus={!isApp} isMine={isMine} isPC={isPC} isSearching={isSearching} mode={mode} openURL={openURL} requiredSelectedTask={requiredSelectedTask} selectedTask={selectedTask} setFreezeScroll={handleSetFreezeScroll} setIsSearching={setIsSearching} setNotificationMessage={handleSetNotificationMessage} setSelectedTask={setSelectedTask} tab="schedule" task={timeEntry.task} timeEntry={timeEntry} timeEntrySuggestions={timeEntrySuggestions} onCancel={handleCancel} onCreate={handleCreate} onDestroyTimeEntry={handleDestroyTimeEntry} onSelectCategory={handleSelectCategory} onSetFromPageTitle={handleSetFromPageTitle} onStart={handleStart} onSuggestTasks={handleSuggestTasks} onUpdate={handleUpdate}/>);
};
export default TaskFormTimeEntryListContainer;
