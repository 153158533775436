import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import SectionHeader from '@/components/atoms/section_header';
import { numberWithDelimiter } from '@/utils/number_helper';
import { buildPattern } from '@/utils/search_helper';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import { workspacePath } from '@/utils/workspace_helper';
var MemberSchedule = function (_a) {
    var memberSummaries = _a.memberSummaries, searchText = _a.searchText, teams = _a.teams, fetchMemberSummaries = _a.fetchMemberSummaries;
    var navigate = useNavigate();
    var handleMemberClick = function (user) {
        navigate(workspacePath("/users/".concat(user.id, "/calendar")));
    };
    React.useEffect(function () {
        if (teams.length) {
            fetchMemberSummaries();
        }
    }, [teams.length, fetchMemberSummaries]);
    var summaries = React.useMemo(function () {
        var filtered = {};
        memberSummaries.forEach(function (team) {
            if (team.summaries.length < 1) {
                return;
            }
            var pattern = buildPattern(searchText);
            if (searchText.length < 1 || pattern.test(team.name)) {
                filtered[team.id] = team;
                return;
            }
            var summaries = team.summaries.filter(function (_a) {
                var nickname = _a.nickname;
                return pattern.test(nickname);
            });
            if (summaries.length < 1) {
                return;
            }
            filtered[team.id] = __assign(__assign({}, team), { summaries: summaries });
        });
        return filtered;
    }, [memberSummaries, searchText]);
    return (<div className="w-100">
      {teams.length < 1 ||
            (memberSummaries.length < 1 && (<div className="loader">
            <LoadingSpinner position="absolute-center" size="md"/>
          </div>))}
      {teams.map(function (_a) {
            var id = _a.id;
            var team = summaries[id];
            if (!team) {
                return;
            }
            return (<div key={team.id} className="teamlist">
            <SectionHeader>
              <div className="section-header-title">
                <WorkspaceAvatarContainer className="mr-1" workspaceId={team.workspaces_workspace_id}/>
                <Avatar className="mr-1" image={team.avatar_url} size="sm"/>
                {team.name}
              </div>
              <div className="ml-auto">
                <span className="time-value">{team.hours}</span>
                <span className="time-unit">{I18n.t('shared.hours')}</span>
                <span className="time-value">{team.minutes}</span>
                <span className="time-unit">{I18n.t('shared.minutes')}</span>
                &nbsp; ¥{numberWithDelimiter(team.amount)}
              </div>
            </SectionHeader>
            <div className="list-group list-group-flush">
              {team.summaries
                    .sort(function (a, b) { return b.total_time - a.total_time; })
                    .map(function (summary) { return (<div key={summary.id} className="list-group-item list-group-item-action" onClick={function () { return handleMemberClick(summary); }}>
                    <Avatar className="mr-2" disabled={summary.count > 0} image={summary.avatar_url} name={summary.nickname} size="md"/>
                    <div className={classNames('text-overflow mr-2', {
                        'text-muted': summary.count < 1,
                    })}>
                      {summary.nickname}
                    </div>
                    <div className="ml-auto small">
                      {summary.count > 0 && (<span className="text-secondary">
                          <span className="time-value">
                            {summary.total_time_hours}
                          </span>
                          <span className="time-unit">
                            {I18n.t('shared.hours')}
                          </span>
                          <span className="time-value">
                            {summary.total_time_minutes}
                          </span>
                          <span className="time-unit">
                            {I18n.t('shared.minutes')}
                          </span>
                        </span>)}
                      {summary.count === 0 && (<span className="text-muted">
                          {I18n.t('member.nonWorking')}
                        </span>)}
                    </div>
                  </div>); })}
            </div>
          </div>);
        })}
    </div>);
};
export default MemberSchedule;
