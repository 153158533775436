import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import classNames from 'classnames';
import I18n from '@/constants/i18n';
import Duration from '@/components/atoms/duration';
import ComplexDateTimeInput from './complex_date_time_input';
import Icon from '@/components/atoms/icon';
import { formatShortDateTime } from '@/utils/time_helper';
var Timespan = function (props) {
    var className = props.className, _a = props.start, start = _a === void 0 ? moment() : _a, _b = props.stop, stop = _b === void 0 ? moment().add(15, 'm') : _b, _c = props.holdDurationByStart, holdDurationByStart = _c === void 0 ? false : _c, _d = props.isProgress, isProgress = _d === void 0 ? false : _d, _e = props.isMine, isMine = _e === void 0 ? false : _e, _f = props.disabled, disabled = _f === void 0 ? false : _f, prevTimeEntry = props.prevTimeEntry, nextTimeEntry = props.nextTimeEntry, onChange = props.onChange, _g = props.autoFocus, autoFocus = _g === void 0 ? false : _g, _h = props.overlapTimeEntries, overlapTimeEntries = _h === void 0 ? [] : _h, inputType = props.inputType;
    var classes = classNames('timespan', className);
    var handleChangeStart = function (nextStart) {
        var nextStop = stop;
        if (!isProgress && nextStop.diff(nextStart) < 0) {
            nextStop = holdDurationByStart ? nextStart.add(duration, 's') : nextStart;
        }
        onChange(nextStart, nextStop);
    };
    var handleChangeStop = function (nextStop) {
        onChange(start, nextStop);
    };
    var onChangeDuration = React.useCallback(function (timeString) {
        var _a = timeString.split(':').map(function (s) { return parseInt(s); }), h = _a[0], m = _a[1], s = _a[2];
        var seconds = h * 3600 + m * 60 + s;
        var nextStop = moment(start).add(seconds, 's');
        onChange(start, nextStop);
    }, [onChange, start]);
    if (isProgress) {
        return (<div className="timespan">
        <div className="d-flex">
          {isMine && (<ComplexDateTimeInput autoFocus={autoFocus} className="timespan-datetime-input timespan-start" datetime={start} prevTimeEntry={prevTimeEntry} onChange={handleChangeStart}/>)}
          {!isMine && (<div className="align-self-center">{start.format('HH:mm')}</div>)}
          <span className="timespan-duration mb-auto mx-1">
            <Duration seconds={0}/>
          </span>
          <div className="mt-1">{I18n.t('shared.now')}</div>
        </div>
      </div>);
    }
    var duration = Math.floor(stop.diff(start, 'seconds'));
    return (<div className={classes}>
      {overlapTimeEntries.length > 0 && (<div className="mb-2 small">
          <div className="alert-warning border-1 border-warning rounded p-2">
            <div className="d-flex mb-1 align-items-center">
              <Icon className="text-warning tw-mr-0.5" name="warning"/>
              <span>{I18n.t('timespan.hasOverwrapTimeEntry')}</span>
            </div>
            <ul className="mb-0 pl-3">
              {overlapTimeEntries.map(function (timeEntry) { return (<li key={timeEntry.id}>
                  {formatShortDateTime(timeEntry.started_at)} -{' '}
                  {formatShortDateTime(timeEntry.stopped_at, I18n.t('shared.timeEntryInProgress'))}
                </li>); })}
            </ul>
          </div>
        </div>)}
      {inputType === 'proxy' && (<div className="mb-1 text-muted">
          <span>
            <small>{I18n.t('proxyInputUser.label')}</small>
          </span>
        </div>)}
      <div className="d-flex align-items-start">
        <ComplexDateTimeInput autoFocus={autoFocus} className="timespan-datetime-input timespan-start" dateDisabled={disabled} datetime={start} prevTimeEntry={prevTimeEntry} timeDisabled={disabled} onChange={handleChangeStart}/>
        <span className={classNames('timespan-duration d-none d-sm-block', {
            'timespan-duration-danger': duration < 1,
        })}>
          <Duration seconds={duration} onChange={onChangeDuration}/>
        </span>
        <ComplexDateTimeInput className="timespan-datetime-input timespan-stop" dateDisabled={disabled} datetime={stop} nextTimeEntry={nextTimeEntry} timeDisabled={disabled} onChange={handleChangeStop}/>
      </div>
      <span className={classNames('timespan-duration d-block d-sm-none mt-2', {
            'timespan-duration-danger': duration < 1,
        })}>
        <Duration seconds={duration} onChange={onChangeDuration}/>
      </span>
    </div>);
};
export default Timespan;
