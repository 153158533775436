import { __assign } from "tslib";
import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import I18n from '@/constants/i18n';
import CategorySelectorContainer from '@/containers/category_selector_container';
import TaskFormTimespanContainer from '@/containers/task_form_timespan_container';
import TaskSuggestionContainer from '@/containers/task_suggestion_container';
import { isValidTimeFormat } from '@/utils/time_helper';
import { TaskFormCreateTaskButton } from './task_form_create_task_button';
import { TaskFormDeleteTimeEntryButton } from './task_form_delete_time_entry_button';
import { findTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
import Avatar from '@/components/atoms/avatar';
import { isCreateMode } from './helpers';
export var TaskFormTabContent = function (_a) {
    var mode = _a.mode, tab = _a.tab, isOpenTaskSuggestion = _a.isOpenTaskSuggestion, task = _a.task, category = _a.category, selectedTask = _a.selectedTask, isSearching = _a.isSearching, requiredSelectedTask = _a.requiredSelectedTask, timeEntry = _a.timeEntry, isTimeEntrySpecified = _a.isTimeEntrySpecified, canEditTimeEntry = _a.canEditTimeEntry, isMine = _a.isMine, archived = _a.archived, readOnly = _a.readOnly, timeEntrySuggestions = _a.timeEntrySuggestions, timeEntrySuggestionDate = _a.timeEntrySuggestionDate, isTimeEntryValid = _a.isTimeEntryValid, isShowRemoveTimeEntry = _a.isShowRemoveTimeEntry, disabled = _a.disabled, useDefaultDuration = _a.useDefaultDuration, isTimespanChanged = _a.isTimespanChanged, fromPageTitle = _a.fromPageTitle, setSelectedTask = _a.setSelectedTask, createTask = _a.createTask, setNotificationMessage = _a.setNotificationMessage, onFocusTaskTitle = _a.onFocusTaskTitle, setTimeEntry = _a.setTimeEntry, setIsTimeEntryValid = _a.setIsTimeEntryValid, setIsTimespanChanged = _a.setIsTimespanChanged, setIsOpenTaskSuggestion = _a.setIsOpenTaskSuggestion, setTask = _a.setTask, setIsTimeEntrySpecified = _a.setIsTimeEntrySpecified, setTab = _a.setTab, onDestroyTimeEntry = _a.onDestroyTimeEntry, setIsEditingTask = _a.setIsEditingTask, onSelectCategory = _a.onSelectCategory, onSuggestTasks = _a.onSuggestTasks;
    var _b = React.useState(false), isCategorySelected = _b[0], setIsCategorySelected = _b[1];
    var timeEntrySuggestion = findTimeEntrySuggestion(timeEntrySuggestions, timeEntrySuggestionDate);
    var isInitial = !isCategorySelected && task.title === '';
    var isProgress = timeEntry.stopped_at === null;
    var setCategory = function (category) {
        var isOpenTaskSuggestion = !fromPageTitle;
        setTab('unselect');
        setIsOpenTaskSuggestion(isOpenTaskSuggestion);
        setIsCategorySelected(true);
        onSelectCategory(category);
        onSuggestTasks(category, task.title);
        onFocusTaskTitle();
        if (mode === 'update') {
            setIsEditingTask(true);
        }
        if (requiredSelectedTask) {
            setSelectedTask === null || setSelectedTask === void 0 ? void 0 : setSelectedTask(null);
        }
    };
    var onChangeStartedAt = function (start) {
        var _a = start._i.split(' '), startTimeString = _a[1];
        if (start.isValid() && isValidTimeFormat(startTimeString)) {
            setTimeEntry(__assign(__assign({}, timeEntry), { started_at: start.startOf('minute') }));
            setIsTimeEntryValid(true);
        }
        else {
            setIsTimeEntryValid(false);
        }
    };
    var onChangeTimespan = function (start, stop) {
        if (stop === null) {
            onChangeStartedAt(start);
            return;
        }
        var _a = start._i.split(' '), startTimeString = _a[1];
        var _b = stop._i.split(' '), stopTimeString = _b[1];
        if (start.isValid() &&
            stop.isValid() &&
            isValidTimeFormat(startTimeString) &&
            isValidTimeFormat(stopTimeString)) {
            setTimeEntry(__assign(__assign({}, timeEntry), { started_at: start, stopped_at: stop }));
            setIsTimeEntryValid(start.isBefore(stop));
            setIsTimeEntryValid(true);
        }
        else {
            setIsTimeEntryValid(false);
        }
        setIsTimespanChanged(true);
    };
    var onSelectTaskFromSuggestions = function (task) {
        if (!useDefaultDuration) {
            setIsOpenTaskSuggestion(false);
            setTask(task);
            setCategory(__assign(__assign({}, task.category), { team: task.team }));
            setSelectedTask === null || setSelectedTask === void 0 ? void 0 : setSelectedTask(task);
            return;
        }
        var started_at = timeEntry.started_at.clone();
        var stopped_at = timeEntry.started_at
            .clone()
            .add(task.default_duration, 's');
        setCategory(__assign(__assign({}, task.category), { team: task.team }));
        setTask(task);
        if (!isTimespanChanged && mode !== 'update') {
            setTimeEntry({
                started_at: started_at,
                stopped_at: stopped_at,
            });
        }
        setIsOpenTaskSuggestion(false);
        setSelectedTask === null || setSelectedTask === void 0 ? void 0 : setSelectedTask(task);
    };
    return (<TabContent activeTab={tab} className="task-edit-tab-content">
      <TaskSuggestionContainer category={category} isCategorySelected={isCategorySelected} isInitial={isInitial} isOpen={isOpenTaskSuggestion} task={task} onSelectTaskFromSuggestions={onSelectTaskFromSuggestions}/>
      {tab === 'unselect' && (<TaskFormCreateTaskButton category={category} createTask={createTask} isInitial={isInitial} isSearching={isSearching} requiredSelectedTask={requiredSelectedTask} selectedTask={selectedTask} setIsOpenTaskSuggestion={setIsOpenTaskSuggestion} setNotificationMessage={setNotificationMessage} setSelectedTask={setSelectedTask} task={task}/>)}
      <TabPane tabId="category">
        <CategorySelectorContainer category={category} isOpened={tab === 'category'} setCategory={setCategory}/>
      </TabPane>
      {canEditTimeEntry && (<TabPane tabId="schedule">
          <div className="p-2">
            {mode === 'update' && (<div className="d-flex align-items-center mb-2">
                <Avatar className="mr-1" image={timeEntry.user.avatar_url}/>
                {timeEntry.user.nickname}
              </div>)}
            <div className="d-flex timespan-form">
              <div className="mb-2">
                <TaskFormTimespanContainer disabled={archived || readOnly} isMine={isMine} isProgress={isProgress} nextTimeEntry={timeEntrySuggestion === null || timeEntrySuggestion === void 0 ? void 0 : timeEntrySuggestion.nextTimeEntry} prevTimeEntry={timeEntrySuggestion === null || timeEntrySuggestion === void 0 ? void 0 : timeEntrySuggestion.prevTimeEntry} timeEntry={timeEntry} onChange={onChangeTimespan}/>
              </div>
              <div className="ml-auto timespan-form-controls">
                {isCreateMode(mode) && !isTimeEntrySpecified && (<button className="btn-major-action btn btn-secondary" disabled={!isTimeEntryValid ||
                    timeEntry.stopped_at.diff(timeEntry.started_at) < 1} type="button" onClick={function () {
                    setIsTimeEntrySpecified(true);
                    setTab('unselect');
                    setIsTimespanChanged(true);
                    onFocusTaskTitle();
                }}>
                    {I18n.t('shared.add')}
                  </button>)}
                {isCreateMode(mode) &&
                isTimeEntrySpecified &&
                isShowRemoveTimeEntry && (<button className="text-danger btn btn-link" onClick={function () {
                    setIsTimeEntrySpecified(false);
                    setTab('unselect');
                }}>
                      <span>{I18n.t('shared.remove')}</span>
                    </button>)}
              </div>
            </div>
            <TaskFormDeleteTimeEntryButton archived={archived} disabled={disabled} mode={mode} readOnly={readOnly} timeEntry={timeEntry} onDestroyTimeEntry={onDestroyTimeEntry}/>
          </div>
        </TabPane>)}
    </TabContent>);
};
