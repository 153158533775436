import React from 'react';
import I18n from '@/constants/i18n';
import CustomRadio from '@/components/atoms/custom_radio';
export var charsets = ['sjis', 'utf8', 'utf8_bom'];
export var CharsetSelect = function (_a) {
    var charset = _a.charset, onChangeCharset = _a.onChangeCharset;
    return (<div>
      {charsets.map(function (c) { return (<div key={c}>
          <CustomRadio checked={charset === c} value={c} onChange={function () { return onChangeCharset(c); }}>
            {I18n.t("export.charset.".concat(c))}
          </CustomRadio>
        </div>); })}
    </div>);
};
