import React from 'react';
import TaskContainer from '@/containers/task_edit/task_container';
import Backdrop from '@/components/atoms/backdrop';
import EditTaskFormContainer from '@/containers/task_edit/edit_task_form_container';
var TaskEditor = function (props) {
    var task = props.task, from = props.from, onClickBack = props.onClickBack;
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var handleToggle = function () {
        setIsOpen(!isOpen);
    };
    if (isOpen) {
        return (<div>
        <Backdrop className="hidden-sm-down" onClick={handleToggle}/>
        <EditTaskFormContainer category={task.category} from={from} task={task} team={task.team} toggle={handleToggle}/>
      </div>);
    }
    else {
        return (<TaskContainer task={task} toggle={handleToggle} onBack={onClickBack}/>);
    }
};
export default TaskEditor;
