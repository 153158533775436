import React from 'react';
import classNames from 'classnames';
var CustomRadio = function (props) {
    var className = props.className, children = props.children, _a = props.checked, checked = _a === void 0 ? false : _a, disabled = props.disabled, _b = props.value, value = _b === void 0 ? 'on' : _b, onChange = props.onChange;
    var classes = classNames('custom-control custom-radio', className, {
        checked: checked,
        disabled: disabled,
    });
    return (<label className={classes}>
      <input checked={checked} className="custom-control-input" disabled={disabled} name="radio" type="radio" value={value} onChange={onChange}/>
      <span className="custom-control-indicator"/>
      <span className="custom-control-description">{children}</span>
    </label>);
};
export default CustomRadio;
