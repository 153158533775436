import { __assign } from "tslib";
import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { useNavigate, useLocation } from 'react-router-dom';
import Backdrop from '@/components/atoms/backdrop';
import TaskFormTimeEntryListContainer from '@/containers/task_form_time_entry_list_container';
import { workspacePath } from '@/utils/workspace_helper';
function normalizeTime(time) {
    return moment(time.format('YYYY-MM-DD HH:mm')).add(time.seconds(), 's');
}
var TimeEntryForm = function (props) {
    var taskSearch = props.taskSearch, query = props.query, fetchTimeEntrySuggestion = props.fetchTimeEntrySuggestion, defaultStartedAt = props.startedAt, timeEntry = props.timeEntry, defaultTask = props.task, onCancel = props.onCancel, onSetCurrentEntry = props.onSetCurrentEntry, isMine = props.isMine, timeEntrySuggestionsStatus = props.timeEntrySuggestionsStatus;
    var navigate = useNavigate();
    var location = useLocation();
    var startedAt = normalizeTime(defaultStartedAt);
    var _a = React.useState(defaultTask), task = _a[0], setTask = _a[1];
    var _b = React.useState(__assign(__assign({}, timeEntry.category), { team: timeEntry.team })), category = _b[0], setCategory = _b[1];
    var _c = React.useState(false), isSearching = _c[0], setIsSearching = _c[1];
    var commentRef = React.useRef(null);
    React.useEffect(function () {
        if (timeEntrySuggestionsStatus === 'loading' ||
            timeEntrySuggestionsStatus === 'loaded') {
            return;
        }
        fetchTimeEntrySuggestion(startedAt.toDate());
    }, [fetchTimeEntrySuggestion, startedAt, timeEntrySuggestionsStatus]);
    // NOTE: reduxとの相性でReactstrapのModalがエラーになるため自前で管理
    React.useEffect(function () {
        document.body.classList.add('modal-open');
        return function () {
            document.body.classList.remove('modal-open');
        };
    }, []);
    var exactMatchedTask = React.useMemo(function () {
        return taskSearch.items.find(function (t) {
            return t.title === taskSearch.params.title && t.category.id === category.id;
        });
    }, [category.id, taskSearch.items, taskSearch.params.title]);
    React.useEffect(function () {
        if (exactMatchedTask && !task) {
            setTask(exactMatchedTask);
        }
    }, [exactMatchedTask, task]);
    React.useEffect(function () {
        if (taskSearch.isFetched) {
            setIsSearching(false);
        }
    }, [taskSearch.isFetched]);
    var handleUpdate = function () {
        var isTaskChanged = defaultTask.id !== task.id;
        if (isTaskChanged) {
            if (/app\/?$/.test(location.pathname)) {
                onCancel();
                return;
            }
            var tasksPath = /mytasks/.test(location.pathname) ? 'mytasks' : 'tasks';
            navigate(workspacePath("/".concat(tasksPath, "/").concat(task.id, "/edit")));
            return;
        }
        if (!timeEntry.stopped_at) {
            onSetCurrentEntry(timeEntry);
        }
        onCancel();
    };
    return (<>
      <Backdrop />
      <div className="modal fade show d-block" onClick={onCancel}>
        <div className="modal-dialog modal-lg" onClick={function (e) { return e.stopPropagation(); }}>
          <div className="modal-content">
            <div className="modal-body p-0 inline-edit-backdrop-container">
              <TaskFormTimeEntryListContainer hidesFromPageToggle requiredSelectedTask commentRef={commentRef} isMine={isMine} isSearching={isSearching} mode="update" query={query} selectedTask={task} setIsSearching={setIsSearching} setSelectedTask={setTask} timeEntry={__assign(__assign({}, timeEntry), { category: category })} toggleTaskForm={onCancel} onSelectCategory={setCategory} onUpdate={handleUpdate}/>
            </div>
          </div>
        </div>
      </div>
    </>);
};
export default TimeEntryForm;
