import { __awaiter, __generator } from "tslib";
import api from '../api';
import api2 from '../api2';
import { setReportPeriods, setTeamReport, setReportSortOrder, setReportSortTarget, } from '@/modules/report';
import { setNotificationMessage } from '@/modules/notification';
import I18n from '@/constants/i18n';
export var fetchReportPeriods = function () {
    return function (dispatch, getState) {
        return api(dispatch, getState, 'reports/periods', 'GET').then(function (periods) {
            if (periods.error) {
                dispatch(setNotificationMessage('danger', I18n.t('errors.failedFetchPeriods')));
            }
            else {
                dispatch(setReportPeriods(periods));
            }
        });
    };
};
export var fetchTeamReport = function (teamIds, userIds, from, to, type, categoryId, withArchived, withAllUsers) {
    var body = {
        team_ids: teamIds,
        user_ids: userIds,
        category_id: categoryId || '',
        from: from,
        to: to,
        type: type,
        with_archived: withArchived,
        with_all_users: withAllUsers,
        version: 4,
    };
    return function (dispatch, getState) {
        return api2(dispatch, getState, "/api/v2/report/teams", 'POST', body).then(function (response) {
            if (response.ok) {
                response.json().then(function (report) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        dispatch(setTeamReport(report));
                        return [2 /*return*/];
                    });
                }); });
            }
            else {
                dispatch(setTeamReport(null));
            }
            return response;
        });
    };
};
export var setReportTasksSortOrder = function (sortOrder) {
    return function (dispatch) {
        dispatch(setReportSortOrder(sortOrder));
    };
};
export var setReportTasksSortTarget = function (sortTarget) {
    return function (dispatch) {
        dispatch(setReportSortTarget(sortTarget));
    };
};
