import React from 'react';
import I18n from '@/constants/i18n';
import Alert from '@/components/alert';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
import { periodLabel } from '@/components/cross_team_report_header/helpers';
export var CrossTeamReportLongTermNotification = function (props) {
    var reports = props.reports, onSelect = props.onSelect;
    return reports.map(function (report, i) { return (<Alert key={report.id} isHtml isOpen withoutCloseButton autoClose={false} color="success" message="" position="bottom" style={{ bottom: "".concat(2 + i * 7, "rem") }} onClose={function () { }}>
      <div className="d-flex w-100 align-items-center pr-1">
        {I18n.t('shared.longTermReportGenerated')}
        <div className="ml-3 d-flex align-items-center text-muted small w-96">
          <WorkspaceAvatarContainer force workspaceId={report.workspaces_workspace_id}/>
          <span className="ml-1">
            {report.period_type !== 'custom' && (<span className="mr-1">
                {I18n.t("shared.".concat(report.period_type))}
              </span>)}
          </span>
          {periodLabel(report)}
        </div>
        <button className={'btn btn-primary ml-5'} type="button" onClick={function () { return onSelect(report); }}>
          {I18n.t('shared.longTermReportShow')}
        </button>
      </div>
    </Alert>); });
};
