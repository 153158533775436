import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CrossTeamReport } from '@/components/cross_team_report';
import { fetchWorkspacesRealtimeReport, setWorkspacesRealtimeReport, } from '@/modules/workspaces_realtime_reports';
import { createWorkspacesLongTermReport } from '@/modules/workspaces_long_term_reports';
import { buildDefaultPeriod } from '@/components/cross_team_report_header/helpers';
import { useWorkspaceId } from '@/hooks/use_workspace_id';
import { fetchWorkspacesLongTermReport, setCurrentWorkspacesLongTermReport, } from '@/modules/workspaces_long_term_reports';
import { setReportSortOrder, setReportSortTarget } from '@/modules/report';
import { useQuery } from '@/hooks/use_query';
export var CrossTeamReportContainer = function () {
    var workspaceId = useWorkspaceId();
    var dispatch = useDispatch();
    var workspacesRealtimeReports = useSelector(function (state) { return state.workspacesRealtimeReports; });
    var currentLongTermReport = useSelector(function (state) { return state.workspacesLongTermReports.current; });
    var workspacesReportBookmarks = useSelector(function (state) { return state.workspacesReportBookmarks; });
    var report = useSelector(function (state) { return state.report; });
    var query = useQuery();
    var _a = React.useState(false), isLoadingLongTerm = _a[0], setIsLoadingLongTerm = _a[1];
    var _b = React.useState(false), isInitialLoaded = _b[0], setIsInitialLoaded = _b[1];
    var _c = React.useState([]), selectedCategories = _c[0], setSelectedCategories = _c[1];
    var _d = React.useState([]), selectedTeams = _d[0], setSelectedTeams = _d[1];
    var _e = React.useState([]), selectedUsers = _e[0], setSelectedUsers = _e[1];
    var showLongTermReport = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsLoadingLongTerm(true);
                    return [4 /*yield*/, fetch(currentLongTermReport.file_url)];
                case 1:
                    res = _c.sent();
                    _a = dispatch;
                    _b = setWorkspacesRealtimeReport;
                    return [4 /*yield*/, res.json()];
                case 2:
                    _a.apply(void 0, [_b.apply(void 0, [_c.sent()])]);
                    setIsLoadingLongTerm(false);
                    setIsInitialLoaded(true);
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch, currentLongTermReport]);
    // NOTE: 初回表示のレポート読み込み
    React.useEffect(function () {
        if (isInitialLoaded ||
            (currentLongTermReport === null || currentLongTermReport === void 0 ? void 0 : currentLongTermReport.workspaces_workspace_id) === workspaceId) {
            return;
        }
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, dispatch(fetchWorkspacesRealtimeReport(buildDefaultPeriod()))];
                    case 1:
                        res = _a.sent();
                        if (query.team_id && !res.error) {
                            setSelectedTeams(res.data.teams.filter(function (_a) {
                                var id = _a.id;
                                return id === Number(query.team_id);
                            }));
                        }
                        setIsInitialLoaded(true);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [dispatch, currentLongTermReport, workspaceId, isInitialLoaded, query]);
    // NOTE: 選択中の長期レポート変更時の読み込み
    React.useEffect(function () {
        if ((currentLongTermReport === null || currentLongTermReport === void 0 ? void 0 : currentLongTermReport.workspaces_workspace_id) === workspaceId) {
            showLongTermReport();
        }
    }, [dispatch, currentLongTermReport, workspaceId, showLongTermReport]);
    var handleFetchRealtimeReport = React.useCallback(function (params) {
        dispatch(fetchWorkspacesRealtimeReport(params));
    }, [dispatch]);
    var handleFetchLongTermReport = React.useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(fetchWorkspacesLongTermReport(workspaceId, id))];
                case 1:
                    res = _a.sent();
                    if (!res.error) {
                        dispatch(setCurrentWorkspacesLongTermReport(res.data));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch, workspaceId]);
    var handleGenerateLongTermReport = React.useCallback(function (params) {
        dispatch(createWorkspacesLongTermReport(params));
    }, [dispatch]);
    var handleSetSortOrder = React.useCallback(function (sortOrder) {
        dispatch(setReportSortOrder(sortOrder));
    }, [dispatch]);
    var handleSetSortBy = React.useCallback(function (sortBy) {
        dispatch(setReportSortTarget(sortBy));
    }, [dispatch]);
    return (<CrossTeamReport bookmarks={workspacesReportBookmarks.items} isLoadingLongTerm={isLoadingLongTerm} isLoadingRealtime={workspacesRealtimeReports.status === 'loading'} report={workspacesRealtimeReports.item} selectedCategories={selectedCategories} selectedTeams={selectedTeams} selectedUsers={selectedUsers} sortBy={report.sortBy} sortOrder={report.sortOrder} onFetchLongTermReport={handleFetchLongTermReport} onFetchRealtimeReport={handleFetchRealtimeReport} onGenerateLongTermReport={handleGenerateLongTermReport} onSelectCategories={setSelectedCategories} onSelectTeams={setSelectedTeams} onSelectUsers={setSelectedUsers} onSetReportSortBy={handleSetSortBy} onSetReportSortOrder={handleSetSortOrder}/>);
};
