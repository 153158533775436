import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import { SortableCategoryList } from '@/components/sortable_category_list';
import { CategoryListItem } from '@/components/molecules/category_list_item';
import { findParentCategory } from './helpers';
export var NestedCategoryListItem = function (_a) {
    var _b;
    var category = _a.category, categories = _a.categories, team = _a.team, customFieldsMasters = _a.customFieldsMasters, customFieldsValues = _a.customFieldsValues, searchText = _a.searchText, categoryTaskTree = _a.categoryTaskTree, toggleRemoveModal = _a.toggleRemoveModal, handleArchiveCategory = _a.handleArchiveCategory, onMoveLowerCategoryPosition = _a.onMoveLowerCategoryPosition, onMoveHigherCategoryPosition = _a.onMoveHigherCategoryPosition, handleDragStart = _a.handleDragStart, handleDragEnd = _a.handleDragEnd, setNotificationMessage = _a.setNotificationMessage, fetchCustomFieldsValues = _a.fetchCustomFieldsValues, copyTeamCategory = _a.copyTeamCategory, createTeamCategory = _a.createTeamCategory, updateTeamCategory = _a.updateTeamCategory, fetchCategoryTaskTree = _a.fetchCategoryTaskTree, draggedId = _a.draggedId, isOverlay = _a.isOverlay;
    var _c = useSortable({
        id: category.id.toString(),
    }), setNodeRef = _c.setNodeRef, transform = _c.transform, transition = _c.transition;
    var children = categories.filter(function (c) { return c.parent_id === category.id; });
    var collapsed = draggedId && category.id.toString() === draggedId;
    var className = classNames('nested-category-list-item__container', {
        'nested-category-list-item--overlay': collapsed && isOverlay,
        'nested-category-list-item--dragged': collapsed && !isOverlay,
    });
    var style = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: collapsed && !isOverlay ? 0.5 : 1,
    };
    var canUnarchive = !category.ancestry || !((_b = findParentCategory(category, categories)) === null || _b === void 0 ? void 0 : _b.archived);
    return (<div ref={setNodeRef} className={className} style={style}>
      <CategoryListItem canManage={team.can_manage} canUnarchive={canUnarchive} categories={categories} category={category} categoryTaskTree={categoryTaskTree} copyTeamCategory={copyTeamCategory} createTeamCategory={createTeamCategory} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues} fetchCategoryTaskTree={fetchCategoryTaskTree} fetchCustomFieldsValues={fetchCustomFieldsValues} handleArchiveCategory={handleArchiveCategory} setNotificationMessage={setNotificationMessage} team={team} toggleRemoveModal={toggleRemoveModal} updateTeamCategory={updateTeamCategory} onMoveHigherCategoryPosition={onMoveHigherCategoryPosition} onMoveLowerCategoryPosition={onMoveLowerCategoryPosition}/>
      {!collapsed && children.length > 0 && (<SortableCategoryList categories={categories} categoryTaskTree={categoryTaskTree} copyTeamCategory={copyTeamCategory} createTeamCategory={createTeamCategory} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues} draggedId={draggedId} fetchCategoryTaskTree={fetchCategoryTaskTree} fetchCustomFieldsValues={fetchCustomFieldsValues} handleArchiveCategory={handleArchiveCategory} handleDragEnd={handleDragEnd} handleDragStart={handleDragStart} originCategories={children} searchText={searchText} setNotificationMessage={setNotificationMessage} team={team} toggleRemoveModal={toggleRemoveModal} updateTeamCategory={updateTeamCategory} onMoveHigherCategoryPosition={onMoveHigherCategoryPosition} onMoveLowerCategoryPosition={onMoveLowerCategoryPosition}/>)}
    </div>);
};
