import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@/components/alert';
import { removeNotificationMessage } from '@/modules/notification';
var NotificationContainer = function () {
    var _a = useSelector(function (state) { return state.notification; }), isHtml = _a.isHtml, isPermanent = _a.isPermanent, isOpen = _a.isShown, message = _a.message, color = _a.messageType;
    var autoClose = !isPermanent && color !== 'danger';
    var dispatch = useDispatch();
    var onClose = function () {
        dispatch(removeNotificationMessage());
    };
    return (<Alert autoClose={autoClose} color={color} isHtml={isHtml} isOpen={isOpen} message={message} position="bottom" onClose={onClose}/>);
};
export default NotificationContainer;
