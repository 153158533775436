import React from 'react';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
export var CategoryTaskTreeList = function (_a) {
    var tree = _a.tree;
    if (!tree.length) {
        return <div />;
    }
    return (<div>
      {tree.map(function (item) { return (<div key={"".concat(item.is_category, "-").concat(item.id)} style={{ marginLeft: "".concat(item.depth * 1.2, "rem") }}>
          {item.is_category && <CategoryCircleIcon color={item.color}/>}
          {item.title}
        </div>); })}
    </div>);
};
