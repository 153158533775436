import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { mergeTask } from '@/api2/tasks';
import { closeModal } from '@/modules/modal';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import { workspacePath } from '@/utils/workspace_helper';
export var MergeModal = function (_a) {
    var category = _a.category, from = _a.from, i18nScope = _a.i18nScope, modalTypeName = _a.modalTypeName, task = _a.task, team = _a.team, toggle = _a.toggle;
    var isOpen = useSelector(function (state) { return state.modal.modalType === modalTypeName; });
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var onClose = function () {
        dispatch(closeModal());
    };
    var onClickSubmit = function () {
        var params = {
            task: {
                title: task.title,
                url: task.url,
                team_id: team.id,
                parent_id: category.id,
                moving: true,
            },
        };
        dispatch(mergeTask(task.id, params)).then(function (newTask) {
            if (newTask.error) {
                return false;
            }
            onClose();
            toggle();
            navigate(workspacePath("/".concat(from, "/").concat(newTask.id, "/edit")), { replace: true });
        });
    };
    var onClickCancel = function () {
        onClose();
    };
    return (<Modal isOpen={isOpen} toggle={onClickCancel}>
      <ModalHeaderMod toggle={onClickCancel}>
        {I18n.t("".concat(i18nScope, ".title"))}
      </ModalHeaderMod>

      <ModalBody>
        <p>{I18n.t("".concat(i18nScope, ".desc"))}</p>
        <p className="text-danger">{I18n.t("".concat(i18nScope, ".attention"))}</p>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-danger" onClick={onClickSubmit}>
          {I18n.t("".concat(i18nScope, ".submit"))}
        </button>
        <button className="btn btn-secondary" onClick={onClickCancel}>
          {I18n.t('shared.cancel')}
        </button>
      </ModalFooter>
    </Modal>);
};
