import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTimeEntry, upsertTimeEntryComment, } from '@/modules/time_entries';
import { fetchTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
import { TimeEntryInlineEditForm } from '@/components/time_entry_inline_edit_form';
import { updateDailyActivity } from '@/modules/daily_activites';
import { setCurrentEntry } from '@/modules/current_entry';
import { formatDate } from '@/utils/time_helper';
import { setUndo } from '@/modules/undo';
var TimeEntryInlineEditContainer = function (_a) {
    var canUpdate = _a.canUpdate, className = _a.className, comment = _a.comment, destroyTimeEntry = _a.destroyTimeEntry, disabled = _a.disabled, id = _a.id, startedAt = _a.startedAt, stoppedAt = _a.stoppedAt, toggle = _a.toggle, user = _a.user, withoutAvatar = _a.withoutAvatar, inputType = _a.inputType;
    var currentUser = useSelector(function (state) { return state.currentUser.item; });
    var timeEntrySuggestionsItems = useSelector(function (state) { return state.timeEntrySuggestions.items; });
    var timeEntrySuggestions = currentUser.id === user.id ? timeEntrySuggestionsItems : [];
    var isMine = currentUser.id === user.id;
    var dispatch = useDispatch();
    var handleUpsertTimeEntryComment = function (id, params) {
        return dispatch(upsertTimeEntryComment(id, params));
    };
    var handleFetchTimeEntrySuggestion = function (time) {
        dispatch(fetchTimeEntrySuggestion(time));
    };
    var handleSetUndo = function (messageType, message, action) {
        dispatch(setUndo(messageType, message, action));
    };
    var handleUpdateTimeEntry = function (id, params) {
        return dispatch(updateTimeEntry(id, params)).then(function (timeEntry) {
            if (!timeEntry.error) {
                var date = formatDate(new Date(timeEntry.started_at));
                dispatch(updateDailyActivity(date));
                if (!timeEntry.stopped_at &&
                    timeEntry.time_tracker_id === currentUser.id) {
                    dispatch(setCurrentEntry(timeEntry));
                }
            }
            return timeEntry;
        });
    };
    return (<TimeEntryInlineEditForm canUpdate={canUpdate} className={className} comment={comment} destroyTimeEntry={destroyTimeEntry} disabled={disabled} fetchTimeEntrySuggestion={handleFetchTimeEntrySuggestion} id={id} inputType={inputType} isMine={isMine} setUndo={handleSetUndo} startedAt={startedAt} stoppedAt={stoppedAt} timeEntrySuggestions={timeEntrySuggestions} toggle={toggle} updateTimeEntry={handleUpdateTimeEntry} upsertTimeEntryComment={handleUpsertTimeEntryComment} user={user} withoutAvatar={withoutAvatar}/>);
};
export default TimeEntryInlineEditContainer;
