import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
export var NameWithTooltip = function (props) {
    var className = props.className, name = props.name, withTooltip = props.withTooltip;
    var nameRef = React.useRef(null);
    return (<>
      <span ref={nameRef} className={className}>
        {name}
      </span>
      {withTooltip && (<UncontrolledTooltip autohide delay={{
                show: 850,
                hide: 0,
            }} fade={false} target={nameRef}>
          {name}
        </UncontrolledTooltip>)}
    </>);
};
