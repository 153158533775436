import { __assign } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
var TaskTitle = function (props) {
    var taskTitleRef = props.taskTitleRef, onKeyPressEnter = props.onKeyPressEnter, category = props.category, onSuggestTasks = props.onSuggestTasks, onChangeTask = props.onChangeTask, onChangeTab = props.onChangeTab, onChangeIsOpenTaskSuggestion = props.onChangeIsOpenTaskSuggestion, task = props.task, value = props.value, disabled = props.disabled, rows = props.rows, _a = props.onBlur, onBlur = _a === void 0 ? function () { } : _a;
    var isOpenIME = React.useRef(false);
    var suggestTimer = React.useRef(null);
    React.useEffect(function () {
        setTimeout(function () {
            var textarea = taskTitleRef.current;
            if (textarea) {
                textarea.focus();
                textarea.setSelectionRange(textarea.value.length, textarea.value.length);
            }
        }, 0);
    }, [taskTitleRef]);
    var onKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            onKeyPressEnter();
        }
    };
    var onChange = function (e) {
        if (e.type === 'compositionstart') {
            isOpenIME.current = true;
        }
        else if (e.type === 'compositionend') {
            isOpenIME.current = false;
        }
        var newTask = __assign(__assign({}, task), { title: e.target.value, url: undefined });
        if (isOpenIME.current) {
            onChangeTask(newTask);
        }
        else {
            if (suggestTimer.current) {
                clearTimeout(suggestTimer.current);
            }
            // @ts-ignore
            suggestTimer.current = setTimeout(function () {
                onSuggestTasks(category, newTask.title);
                onChangeIsOpenTaskSuggestion(true);
            }, 500);
            onChangeTask(newTask);
            onChangeTab('unselect');
        }
    };
    return (<textarea ref={taskTitleRef} className="form-control task-edit-title" disabled={disabled} maxLength={700} name="task_title" placeholder={I18n.t('quicktask.insertTaskTitle')} rows={rows} title={value} value={value} onBlur={onBlur} onChange={onChange} onCompositionEnd={onChange} onCompositionStart={onChange} onKeyPress={onKeyPress}/>);
};
var areEqual = function (prevProps, nextProps) {
    return (prevProps.value === nextProps.value &&
        prevProps.disabled === nextProps.disabled);
};
export default React.memo(TaskTitle, areEqual);
