import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReminderEmailsForm } from '@/components/reminder_emails_form';
import { fetchReminderEmailsSettings, createReminderEmailsSetting, updateReminderEmailsSetting, deleteReminderEmailsSetting, } from '@/modules/reminder_emails_settings';
import { workspaceTeams as getWorkspaceTeams } from '@/utils/workspace_helper';
var RemindierEmailsContainer = function () {
    var reminderEmailsSettings = useSelector(function (state) { return state.reminderEmailsSettings; });
    var teams = useSelector(function (state) { return state.teams; });
    var workspaceTeams = getWorkspaceTeams(teams.items);
    var dispatch = useDispatch();
    var handleCreateReminderEmailsSetting = function (params) {
        return dispatch(createReminderEmailsSetting(params));
    };
    var handleUpdateReminderEmailsSetting = function (teamId, params) {
        return dispatch(updateReminderEmailsSetting(teamId, params));
    };
    var handleDeleteReminderEmailsSetting = function (teamId, id) {
        return dispatch(deleteReminderEmailsSetting(teamId, id));
    };
    var params = useParams();
    var team = workspaceTeams.find(function (team) { return team.id === Number(params.team_id); });
    React.useEffect(function () {
        if (team === null || team === void 0 ? void 0 : team.can_manage) {
            dispatch(fetchReminderEmailsSettings(team.id));
        }
    }, [dispatch, team]);
    return (<ReminderEmailsForm createReminderEmailsSetting={handleCreateReminderEmailsSetting} deleteReminderEmailsSetting={handleDeleteReminderEmailsSetting} reminderEmailsSettings={reminderEmailsSettings.items} teams={workspaceTeams} updateReminderEmailsSetting={handleUpdateReminderEmailsSetting}/>);
};
export default RemindierEmailsContainer;
