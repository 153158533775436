import { __assign, __awaiter, __generator } from "tslib";
import { setNotificationMessage } from '../modules/notification';
import I18n from '../constants/i18n';
import parseLinkHeader from 'parse-link-header';
import URL from 'url-parse';
import queryString from 'query-string';
import { NETWORK_ERROR_MESSAGES } from '@/constants/network_error_messages';
export var normalizeParams = function (params) {
    if (Object.keys(params).length === 0) {
        return '';
    }
    else {
        return '?' + queryString.stringify(params);
    }
};
export var getNextLink = function (response) {
    var linkHeader = parseLinkHeader(response.headers.get('Link'));
    if (linkHeader && linkHeader.next) {
        var uri = URL(linkHeader.next.url);
        return uri.pathname + uri.query;
    }
    else {
        return null;
    }
};
export var buildHeaders = function (token, multipart, workspaces) {
    var _a, _b;
    var headers = __assign(__assign({ Accept: 'application/json' }, (multipart ? {} : { 'Content-Type': 'application/json' })), { Origin: window.location.protocol + '//' + window.location.host, 'X-Origin': window.location.protocol + '//' + window.location.host, 'Access-Control-Expose-Headers': 'Link, X-ToTal, X-Per-Page, X-Page', 'TimeCrowd-Workspace-Id': (_b = (_a = workspaces === null || workspaces === void 0 ? void 0 : workspaces.current) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '' });
    if (token) {
        headers['Authorization'] = "Bearer ".concat(token.access_token);
    }
    return headers;
};
export var request = function (getState, path, method, body, multipart) {
    var _a = getState(), token = _a.token, apiBase = _a.apiBase, workspaces = _a.workspaces;
    return fetch("".concat(apiBase).concat(path), {
        method: method,
        headers: buildHeaders(token, multipart, workspaces),
        body: body ? (multipart ? body : JSON.stringify(body)) : null,
        credentials: 'same-origin',
    });
};
var api = function (dispatch, getState, path, method, body, multipart) {
    if (body === void 0) { body = null; }
    if (multipart === void 0) { multipart = false; }
    return request(getState, path, method, body, multipart)
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = response.status;
                    switch (_a) {
                        case 200: return [3 /*break*/, 1];
                        case 204: return [3 /*break*/, 1];
                        case 403: return [3 /*break*/, 2];
                        case 404: return [3 /*break*/, 3];
                        case 413: return [3 /*break*/, 4];
                        case 414: return [3 /*break*/, 5];
                        case 422: return [3 /*break*/, 6];
                        case 500: return [3 /*break*/, 8];
                        case 503: return [3 /*break*/, 8];
                    }
                    return [3 /*break*/, 10];
                case 1:
                    {
                        return [2 /*return*/, response];
                    }
                    _b.label = 2;
                case 2:
                    dispatch(setNotificationMessage('danger', I18n.t('errors.forbidden')));
                    return [2 /*return*/, response];
                case 3:
                    dispatch(setNotificationMessage('danger', I18n.t('errors.notFound')));
                    return [2 /*return*/, response];
                case 4:
                    dispatch(setNotificationMessage('danger', I18n.t('errors.tooLarge')));
                    return [2 /*return*/, response];
                case 5:
                    dispatch(setNotificationMessage('danger', I18n.t('errors.internalServerError'), { isHtml: true }));
                    return [2 /*return*/, response];
                case 6: return [4 /*yield*/, response
                        .clone()
                        .json()
                        .then(function (result) {
                        if (!useRawErrors(result) && result.messages) {
                            dispatch(setNotificationMessage('danger', parseUnprocessableEntityMessages(result.messages), { isHtml: true }));
                        }
                    })];
                case 7:
                    _b.sent();
                    return [2 /*return*/, response];
                case 8: return [4 /*yield*/, response.json().then(function (result) {
                        if (result.messages) {
                            dispatch(setNotificationMessage('danger', result.messages));
                        }
                    })];
                case 9:
                    _b.sent();
                    return [2 /*return*/, response];
                case 10: return [2 /*return*/, response];
            }
        });
    }); })
        .catch(function (e) {
        if (NETWORK_ERROR_MESSAGES.test(e.message)) {
            dispatch(setNotificationMessage('danger', I18n.t('errors.network')));
        }
        else {
            throw e;
        }
        return { ok: false };
    });
};
export var parseUnprocessableEntityMessages = function (messages) {
    return buildErrorList(messages[0], messages.slice(1));
};
export var buildErrorList = function (header, messages) {
    return "\n    <p class=\"mb-1\">".concat(header, "</p>\n    <ul class=\"mb-1 pl-3\">\n      ").concat(messages.map(function (message) { return "<li>".concat(message, "</li>"); }).join(''), "\n    </ul>\n  ");
};
export var useRawErrors = function (_a) {
    var errors = _a.errors;
    return errors === null || errors === void 0 ? void 0 : errors.find(function (_a) {
        var attribute = _a.attribute, type = _a.type;
        return (attribute === 'unique' && type === 'values_duplicated') ||
            attribute === 'custom_fields_values';
    });
};
export default api;
