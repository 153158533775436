// Actions
export var SET_FROM_PAGE_TITLE = 'timecrowd/from_page_title/SET_FROM_PAGE_TITLE';
// Reducer
var initialState = false;
export default function fromPageTitle(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_FROM_PAGE_TITLE:
            return action.fromPageTitle;
        default:
            return state;
    }
}
// Action Creators
export var setFromPageTitle = function (fromPageTitle) { return ({
    type: SET_FROM_PAGE_TITLE,
    fromPageTitle: fromPageTitle,
}); };
