import { __awaiter, __generator } from "tslib";
import React from 'react';
import { Modal, ModalFooter } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import I18n from '@/constants/i18n';
import { TeamMembershipsField } from './team_memberships_field';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import Icon from '@/components/atoms/icon';
import WorkspacesTeamInvitationContainer from '@/containers/workspaces_team_invitation_container';
var EMPTY_MEMBERSHIPS = [];
export var TeamMembershipsFormModal = function (_a) {
    var _b, _c;
    var currentUser = _a.currentUser, team = _a.team, defaultMemberships = _a.memberships, workspacesMemberships = _a.workspacesMemberships, isOpen = _a.isOpen, onCancel = _a.onCancel, updateTeam = _a.updateTeam, setNotificationMessage = _a.setNotificationMessage, fetchTeamMemberships = _a.fetchTeamMemberships;
    var _d = React.useState(defaultMemberships !== null && defaultMemberships !== void 0 ? defaultMemberships : EMPTY_MEMBERSHIPS), memberships = _d[0], setMemberships = _d[1];
    var _e = React.useState(false), isSaving = _e[0], setIsSaving = _e[1];
    var _f = React.useState(false), isShowScrollInfo = _f[0], setIsShowScrollInfo = _f[1];
    var _g = React.useState(new URLSearchParams(useLocation().search).get('tab') === 'invitation'
        ? 'invitation'
        : 'memberships'), currentTab = _g[0], setCurrentTab = _g[1];
    var bodyRef = React.useRef(null);
    var tableRef = React.useRef(null);
    React.useEffect(function () {
        if (!isOpen || isSaving) {
            return;
        }
        setMemberships(defaultMemberships !== null && defaultMemberships !== void 0 ? defaultMemberships : EMPTY_MEMBERSHIPS);
    }, [defaultMemberships, isOpen, isSaving]);
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var params, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (memberships.filter(function (_a) {
                        var ancestral_role = _a.ancestral_role;
                        return ancestral_role === 'manager';
                    })
                        .length < 1) {
                        setNotificationMessage('danger', I18n.t('shared.pleaseSelectOneOwner'));
                        return [2 /*return*/];
                    }
                    setIsSaving(true);
                    params = {
                        memberships: memberships
                            .filter(function (membership) { return membership.team.id === team.id; })
                            .map(function (membership) { return ({
                            user_id: membership.user.id,
                            role: membership.ancestral_role,
                        }); }),
                    };
                    return [4 /*yield*/, updateTeam(team.id, params)];
                case 1:
                    res = _a.sent();
                    setIsSaving(false);
                    if (!res.error) {
                        fetchTeamMemberships === null || fetchTeamMemberships === void 0 ? void 0 : fetchTeamMemberships(team.id);
                        setNotificationMessage('success', I18n.t('shared.targetUpdated', {
                            target: team.name,
                        }));
                        onCancel();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onSelectTab = function (e) {
        e.preventDefault();
        setCurrentTab(e.currentTarget.href.split('#')[1]);
    };
    var tableTop = (_c = (_b = tableRef.current) === null || _b === void 0 ? void 0 : _b.offsetTop) !== null && _c !== void 0 ? _c : 0;
    return (<Modal className="scrollable" isOpen={isOpen} toggle={onCancel}>
      <ModalHeaderMod toggle={onCancel}>
        {I18n.t("shared.editTeam")}
      </ModalHeaderMod>
      <div ref={bodyRef} className="modal-body pt-0 px-2 mx-1" onScroll={function (e) {
            return setIsShowScrollInfo(e.target.scrollTop > tableTop);
        }}>
        <ul className="nav nav-tabs nav-justified mb-3">
          <li className="nav-item">
            <a className={"nav-link ".concat(currentTab === 'memberships' ? 'active' : '')} href="#memberships" onClick={onSelectTab}>
              <Icon className="mr-1" name="pencil"/>
              {I18n.t('shared.manageMembers')}
            </a>
          </li>
          <li className="nav-item">
            <a className={"nav-link ".concat(currentTab === 'invitation' ? 'active' : '')} href="#invitation" onClick={onSelectTab}>
              <Icon className="mr-1" name="plus"/>
              {I18n.t('shared.inviteNewMember')}
            </a>
          </li>
        </ul>
        {currentTab === 'memberships' && (<TeamMembershipsField className="mt-1" currentUser={currentUser} memberships={memberships} tableRef={tableRef} team={team} workspacesMemberships={workspacesMemberships} onChangeMemberships={setMemberships}/>)}
        {currentTab === 'invitation' && (<WorkspacesTeamInvitationContainer team={team}/>)}
        {isShowScrollInfo && (<button className="bg-inverse text-white btn btn-link font-weight-normal position-fixed mx-auto scroll-info" onClick={function () {
                if (bodyRef.current) {
                    bodyRef.current.scrollTop = tableTop;
                }
            }}>
            {I18n.t('shared.selectingNUsers', { count: memberships.length })}
          </button>)}
      </div>
      {currentTab === 'memberships' && (<ModalFooter className="justify-content-end">
          <button className="btn btn-secondary mr-2" disabled={isSaving} onClick={onCancel}>
            {I18n.t('shared.cancel')}
          </button>

          <button className="btn btn-primary" disabled={isSaving} onClick={onSave}>
            {I18n.t('shared.save')}
          </button>
        </ModalFooter>)}
    </Modal>);
};
