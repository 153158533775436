import React from 'react';
import classNames from 'classnames';
var Icon = function (props) {
    var _a;
    var className = props.className, _b = props.tag, Tag = _b === void 0 ? 'i' : _b, name = props.name, _c = props.size, size = _c === void 0 ? 'textsize' : _c, _d = props.color, color = _d === void 0 ? 'normal' : _d, inverse = props.inverse, _e = props.style, style = _e === void 0 ? {} : _e, title = props.title;
    var classes = classNames('icon', className, (_a = {},
        _a['tc-' + name] = true,
        _a['icon-' + size] = true,
        _a['icon-' + color] = true,
        _a['icon-inverse'] = inverse,
        _a));
    return <Tag className={classes} style={style} title={title}/>;
};
export default Icon;
