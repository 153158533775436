import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TaskItem } from '@/components/task_item';
import { workspacePath } from '@/utils/workspace_helper';
import { useThrottle } from '@/hooks/use_throttle';
var TaskManagementListItem = function (props) {
    var navigate = useNavigate();
    var handleStart = useThrottle(function (task) {
        return props.startTask(task).then(function () {
            props.fetchWorkingUsersCount();
        });
    });
    var onStop = function () {
        var currentEntry = props.currentEntry;
        props.stopTimeEntry(currentEntry).then(function () {
            props.setCurrentEntry(null);
            props.fetchWorkingUsersCount();
        });
    };
    var onClose = function (task, params) {
        props.updateTask(task.team_id, task.id, params);
    };
    var onReopen = function (task, params) {
        props.updateTask(task.team_id, task.id, params);
    };
    var onRestore = function (task) {
        props.restoreTask(task.team_id, task.id);
    };
    var toggleTaskEdit = function (e) {
        e.stopPropagation();
        var task = props.task;
        navigate(workspacePath("/tasks/".concat(task.id, "/edit")));
    };
    var task = props.task, currentEntry = props.currentEntry, isMobile = props.isMobile, isApp = props.isApp;
    var isLogging = currentEntry && currentEntry.task.id === task.id ? true : false;
    var isPC = !isMobile && !isApp;
    return (<div key={"task-".concat(task.id)}>
      {/* @ts-ignore TaskListItemとインターフェースが揃っていないため型エラーになるので解消したい */}
      <TaskItem close={onClose} currentEntry={currentEntry} isLogging={isLogging} isPC={isPC} reopen={onReopen} restore={onRestore} start={handleStart} stop={onStop} task={task} toggle={toggleTaskEdit}/>
    </div>);
};
export default TaskManagementListItem;
