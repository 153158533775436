import { __assign } from "tslib";
import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
import classNames from 'classnames';
import { TaskFormButtons } from './task_form_buttons';
import { TaskFormFromPageToggle } from './task_form_from_page_toggle';
import { TaskFormTitleField } from './task_form_title_field';
import { TaskFormStaticCategoryField } from './task_form_static_category_field';
import { TaskFormTabContent } from './task_form_tab_content';
import { TaskFormTabMenu } from './task_form_tab_menu';
import { normalizeTimeEntry, newTimeEntry, isCreateMode } from './helpers';
import variants from '@/constants/variants';
import { TaskFormProxyInputUserContainer } from '@/containers/task_form_proxy_input_user_container';
import { CommentField } from '@/components/comment_field';
var TaskForm = function (props) {
    var _a, _b, _c, _d;
    var _e = props.tab, defaultTab = _e === void 0 ? 'unselect' : _e, _f = props.task, defaultTask = _f === void 0 ? { title: '' } : _f, _g = props.timeEntry, defaultTimeEntry = _g === void 0 ? null : _g, _h = props.category, category = _h === void 0 ? {
        title: I18n.t('quicktask.category'),
        color: 0,
        team: {
            name: I18n.t('quicktask.team'),
        },
    } : _h, _j = props.mode, mode = _j === void 0 ? 'update' : _j, _k = props.isFocus, isFocus = _k === void 0 ? true : _k, isPC = props.isPC, isChromeExtension = props.isChromeExtension, setFreezeScroll = props.setFreezeScroll, fetchTimeEntrySuggestion = props.fetchTimeEntrySuggestion, selectedTask = props.selectedTask, onCreate = props.onCreate, onUpdate = props.onUpdate, onStart = props.onStart, setSelectedTask = props.setSelectedTask, setIsSearching = props.setIsSearching, className = props.className, _l = props.tag, tag = _l === void 0 ? 'div' : _l, _m = props.onCancel, onCancel = _m === void 0 ? function () { } : _m, onDestroyTimeEntry = props.onDestroyTimeEntry, _o = props.openURL, openURL = _o === void 0 ? variants.openURL : _o, isShowCancelButton = props.isShowCancelButton, _p = props.isShowRemoveTimeEntry, isShowRemoveTimeEntry = _p === void 0 ? false : _p, _q = props.isMine, isMine = _q === void 0 ? false : _q, _r = props.linkUrl, linkUrl = _r === void 0 ? '' : _r, _s = props.readOnly, readOnly = _s === void 0 ? false : _s, timeEntrySuggestions = props.timeEntrySuggestions, createTask = props.createTask, setNotificationMessage = props.setNotificationMessage, isSearching = props.isSearching, _t = props.hidesFromPageToggle, hidesFromPageToggle = _t === void 0 ? false : _t, _u = props.requiredSelectedTask, requiredSelectedTask = _u === void 0 ? false : _u, fromPageTitle = props.fromPageTitle, _v = props.useDefaultDuration, useDefaultDuration = _v === void 0 ? true : _v, onSetFromPageTitle = props.onSetFromPageTitle, commentRef = props.commentRef, onSelectCategory = props.onSelectCategory, onSuggestTasks = props.onSuggestTasks, _w = props.isTimespanChanged, defaultIsTimespanChanged = _w === void 0 ? false : _w;
    var taskTitleRef = React.useRef();
    var _x = React.useState(defaultTab), tab = _x[0], setTab = _x[1];
    var _y = React.useState(defaultTask), task = _y[0], setTask = _y[1];
    var _z = React.useState(defaultTimeEntry
        ? normalizeTimeEntry(defaultTimeEntry)
        : newTimeEntry(category)), timeEntry = _z[0], setTimeEntry = _z[1];
    var _0 = React.useState(props.isTimeEntrySpecified), isTimeEntrySpecified = _0[0], setIsTimeEntrySpecified = _0[1];
    var _1 = React.useState(false), isOpenTaskSuggestion = _1[0], setIsOpenTaskSuggestion = _1[1];
    var _2 = React.useState(true), isTimeEntryValid = _2[0], setIsTimeEntryValid = _2[1];
    var _3 = React.useState(false), saving = _3[0], setSaving = _3[1];
    var _4 = React.useState(defaultIsTimespanChanged), isTimespanChanged = _4[0], setIsTimespanChanged = _4[1];
    var _5 = React.useState(false), isEditingTask = _5[0], setIsEditingTask = _5[1];
    var _6 = React.useState(((_a = timeEntry === null || timeEntry === void 0 ? void 0 : timeEntry.comment) === null || _a === void 0 ? void 0 : _a.content) || ''), commentContent = _6[0], setCommentContent = _6[1];
    var timeEntrySuggestionDate = React.useMemo(function () {
        return timeEntry ? new Date(timeEntry.started_at) : new Date();
    }, [timeEntry]);
    var rendered = React.useRef(false);
    var handleFocusTaskTitle = React.useCallback(function () {
        if (isCreateMode(mode) && taskTitleRef && taskTitleRef.current) {
            taskTitleRef.current.focus();
        }
    }, [mode]);
    React.useEffect(function () {
        if (mode === 'update' || mode === 'calendar_event' || tab !== 'unselect') {
            return;
        }
        if (!isPC && !isChromeExtension) {
            setFreezeScroll(true);
        }
        if (isFocus || isPC || isChromeExtension) {
            handleFocusTaskTitle();
        }
        setIsOpenTaskSuggestion(true);
    }, [
        handleFocusTaskTitle,
        isChromeExtension,
        isFocus,
        isPC,
        mode,
        setFreezeScroll,
        tab,
    ]);
    React.useEffect(function () {
        fetchTimeEntrySuggestion(timeEntrySuggestionDate, timeEntry.id);
    }, [fetchTimeEntrySuggestion, timeEntrySuggestionDate, timeEntry.id]);
    // NOTE: チーム変更によるデフォルト作業時間の更新
    React.useEffect(function () {
        var _a;
        // NOTE: 初回レンダリング時の処理を回避するため
        if (!rendered.current) {
            rendered.current = true;
            return;
        }
        if (isTimespanChanged || !((_a = category === null || category === void 0 ? void 0 : category.team) === null || _a === void 0 ? void 0 : _a.id) || (defaultTimeEntry === null || defaultTimeEntry === void 0 ? void 0 : defaultTimeEntry.id)) {
            return;
        }
        if (defaultTimeEntry) {
            var stopped_at = moment(moment(defaultTimeEntry.started_at)
                .add(category.team.default_duration, 'm')
                .clone()
                .format('YYYY-MM-DD HH:mm'));
            var newTimeEntry_1 = __assign(__assign({}, defaultTimeEntry), { stopped_at: stopped_at });
            setTimeEntry(normalizeTimeEntry(newTimeEntry_1));
        }
        else {
            setTimeEntry(newTimeEntry(category));
        }
    }, 
    // NOTE: オブジェクトで比較すると初期表示時に想定外のレンダリングが発生するため中身を比較
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        defaultTimeEntry === null || defaultTimeEntry === void 0 ? void 0 : defaultTimeEntry.started_at,
        defaultTimeEntry === null || defaultTimeEntry === void 0 ? void 0 : defaultTimeEntry.stopped_at,
        (_b = category === null || category === void 0 ? void 0 : category.team) === null || _b === void 0 ? void 0 : _b.id,
        isTimespanChanged,
    ]);
    React.useEffect(function () {
        return function () {
            setFreezeScroll(false);
        };
    }, [setFreezeScroll]);
    var toggleTab = function (toggleTo) {
        setIsOpenTaskSuggestion(false);
        if (tab !== toggleTo) {
            setTab(toggleTo);
        }
        else {
            setTab('unselect');
        }
    };
    var handleClickCreate = function () {
        var newTask = {
            title: task.title,
            parent_id: category.id,
            url: task.url,
            id: selectedTask === null || selectedTask === void 0 ? void 0 : selectedTask.id,
        };
        var comment;
        if (commentContent !== '') {
            comment = {
                content: commentContent,
            };
        }
        handleSaving(onCreate(category.team.id, newTask, timeEntry, comment));
    };
    var handleClickUpdate = function () {
        var params = {
            time_entry: {
                started_at: timeEntry.started_at,
                stopped_at: timeEntry.stopped_at,
                time_trackable_id: selectedTask === null || selectedTask === void 0 ? void 0 : selectedTask.id,
            },
            comment: {
                content: commentContent,
            },
        };
        handleSaving(onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate(timeEntry.id, params));
    };
    var handleClickStart = function () {
        var newTask = {
            key: task.url || task.title,
            title: task.title,
            url: task.url,
            parent_id: category.id,
            team_id: category.team.id,
            category: category,
        };
        var comment;
        if (commentContent !== '') {
            comment = {
                content: commentContent,
            };
        }
        handleSaving(onStart(newTask, comment));
    };
    var handleSaving = function (promise) {
        setSaving(true);
        promise.then(function () {
            setSaving(false);
        });
    };
    var handleChangeTask = function (newTask) {
        setTask(newTask);
        setSelectedTask === null || setSelectedTask === void 0 ? void 0 : setSelectedTask(null);
        setIsSearching === null || setIsSearching === void 0 ? void 0 : setIsSearching(true);
    };
    var isValidForUpdate = function () {
        return isTimeEntryValid && !!selectedTask;
    };
    var handleChangeFromPageTitle = function (title, url, isOpenTaskSuggestion) {
        if (title === task.title && url === task.url) {
            return;
        }
        var newTask = __assign(__assign({}, task), { title: title, url: url });
        setTask(newTask);
        onSuggestTasks(category, newTask.title);
        setIsOpenTaskSuggestion(isOpenTaskSuggestion);
    };
    var disabled = task.title === '' ||
        category === null ||
        (category && !category.id) ||
        saving;
    var Tag = tag;
    var canEditTimeEntry = (timeEntry && timeEntry.started_at) || !timeEntry;
    var classes = classNames(className, {
        'open-suggestion': isOpenTaskSuggestion,
        'unselect-tabs': tab === 'unselect',
        disable: !canEditTimeEntry,
    });
    var formInlineClassName = classNames('form-inline p-1 pl-0 w-100 align-items-start task-edit-form', {
        'flex-column': !isPC && isShowCancelButton && mode !== 'calendar_event',
    });
    var archived = (_d = (_c = task.category) === null || _c === void 0 ? void 0 : _c.archived) !== null && _d !== void 0 ? _d : false;
    return (<Tag className={classes} onClick={function (e) {
            // NOTE: タイムラインの場合はクリックで閉じないようにするため
            if (e.target.type !== 'time') {
                e.preventDefault();
            }
            e.stopPropagation();
        }} onKeyUp={function () { }} onTouchMove={function (e) {
            e.stopPropagation();
        }}>
      <div className="composer-header d-block">
        {isCreateMode(mode) && <TaskFormProxyInputUserContainer />}
        <div className={formInlineClassName}>
          <TaskFormFromPageToggle fromPageTitle={fromPageTitle} hidden={hidesFromPageToggle} mode={mode} onChange={handleChangeFromPageTitle} onSetFromPageTitle={onSetFromPageTitle}/>
          <TaskFormTitleField archived={archived} category={category} fromPageTitle={fromPageTitle} handleClickCreate={handleClickCreate} handleClickStart={handleClickStart} hidesFromPageToggle={hidesFromPageToggle} isChromeExtension={isChromeExtension} isEditingTask={isEditingTask} isPC={isPC} isTimeEntrySpecified={isTimeEntrySpecified} isValidForUpdate={isValidForUpdate} mode={mode} readOnly={readOnly} setIsEditingTask={function (isEditingTask) {
            return setIsEditingTask(isEditingTask);
        }} setIsOpenTaskSuggestion={function (isOpenTaskSuggestion) {
            return setIsOpenTaskSuggestion(isOpenTaskSuggestion);
        }} setTab={function (tab) { return setTab(tab); }} task={task} taskTitleRef={taskTitleRef} onChangeTask={handleChangeTask}/>
          <TaskFormButtons archived={archived} canUpdate={canEditTimeEntry && onUpdate && !readOnly} disabled={disabled} isShowCancelButton={isShowCancelButton} isTimeEntrySpecified={isTimeEntrySpecified} isTimeEntryValid={isTimeEntryValid} isValidForUpdate={isValidForUpdate} linkUrl={linkUrl} mode={mode} openURL={openURL} requiredSelectedTask={requiredSelectedTask} selectedTask={selectedTask} task={task} onCancel={onCancel} onCreate={handleClickCreate} onStart={handleClickStart} onUpdate={handleClickUpdate}/>
        </div>
        <TaskFormStaticCategoryField archived={archived} category={category} mode={mode} readOnly={readOnly}/>
      </div>
      <TaskFormTabMenu archived={archived} canEditTimeEntry={canEditTimeEntry} category={category} isOpenTaskSuggestion={isOpenTaskSuggestion} isTimeEntrySpecified={isTimeEntrySpecified} mode={mode} readOnly={readOnly} tab={tab} taskId={task.id} taskTitle={task.title} timeEntry={timeEntry} toggleTab={toggleTab}/>
      <TaskFormTabContent archived={archived} canEditTimeEntry={canEditTimeEntry} category={category} createTask={createTask} disabled={disabled} fromPageTitle={fromPageTitle} isMine={isMine} isOpenTaskSuggestion={isOpenTaskSuggestion} isSearching={isSearching} isShowRemoveTimeEntry={isShowRemoveTimeEntry} isTimeEntrySpecified={isTimeEntrySpecified} isTimeEntryValid={isTimeEntryValid} isTimespanChanged={isTimespanChanged} mode={mode} readOnly={readOnly} requiredSelectedTask={requiredSelectedTask} selectedTask={selectedTask} setIsEditingTask={function (isEditingTask) { return setIsEditingTask(isEditingTask); }} setIsOpenTaskSuggestion={function (isOpenTaskSuggestion) {
            return setIsOpenTaskSuggestion(isOpenTaskSuggestion);
        }} setIsTimeEntrySpecified={function (isTimeEntrySpecified) {
            return setIsTimeEntrySpecified(isTimeEntrySpecified);
        }} setIsTimeEntryValid={function (isTimeEntryValid) {
            return setIsTimeEntryValid(isTimeEntryValid);
        }} setIsTimespanChanged={function (isTimespanChanged) {
            return setIsTimespanChanged(isTimespanChanged);
        }} setNotificationMessage={setNotificationMessage} setSelectedTask={setSelectedTask} setTab={function (tab) { return setTab(tab); }} setTask={function (task) { return setTask(task); }} setTimeEntry={function (timeEntry) { return setTimeEntry(timeEntry); }} tab={tab} task={task} timeEntry={timeEntry} timeEntrySuggestionDate={timeEntrySuggestionDate} timeEntrySuggestions={timeEntrySuggestions} useDefaultDuration={useDefaultDuration} onDestroyTimeEntry={onDestroyTimeEntry} onFocusTaskTitle={handleFocusTaskTitle} onSelectCategory={onSelectCategory} onSuggestTasks={onSuggestTasks}/>
      <div className="border-top-1 px-2 py-1">
        <CommentField commentRef={commentRef} defaultValue={commentContent} disabled={readOnly || archived} rows={isPC ? 2 : 1} onChange={setCommentContent}/>
      </div>
    </Tag>);
};
export default TaskForm;
