import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
export var ClearableSearchField = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, searchText = _a.searchText, size = _a.size, placeholderKey = _a.placeholderKey, onChangeSearchText = _a.onChangeSearchText;
    var inputRef = React.createRef();
    var handleClick = function (e) {
        var _a;
        e.preventDefault();
        if (searchText) {
            onChangeSearchText('');
        }
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    return (<div className={"position-relative ".concat(className)}>
      <div className="input-group">
        <div className="input-group-btn">
          <button className={"btn btn-secondary btn-".concat(size, " px-2")} onClick={handleClick}>
            <Icon className="text-muted" name={searchText ? 'cross' : 'search'} size={size}/>
          </button>
        </div>
        <input ref={inputRef} className="form-control" placeholder={I18n.t("shared.".concat(placeholderKey))} type="text" value={searchText} onChange={function (e) { return onChangeSearchText(e.target.value); }}/>
      </div>
    </div>);
};
