import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var ConfirmLeaveTeamMyselfModal = function (_a) {
    var isDeleting = _a.isDeleting, isOpen = _a.isOpen, role = _a.role, toggle = _a.toggle, onDelete = _a.onDelete;
    return (<Modal isOpen={isOpen} toggle={toggle} zIndex={1050}>
      <ModalHeaderMod toggle={toggle}>
        {I18n.t('confirmLeaveTeamMyselfModal.leaveTeam')}
      </ModalHeaderMod>

      <ModalBody>
        <p>{I18n.t('confirmLeaveTeamMyselfModal.confirmMessage')}</p>
        <p>{I18n.t('confirmLeaveTeamMyselfModal.descriptionHeader')}</p>
        <ul>
          <li>{I18n.t("confirmLeaveTeamMyselfModal.".concat(role, "Description1"))}</li>
          <li>{I18n.t("confirmLeaveTeamMyselfModal.".concat(role, "Description2"))}</li>
          <li>{I18n.t("confirmLeaveTeamMyselfModal.".concat(role, "Description3"))}</li>
        </ul>
        <p>{I18n.t('confirmLeaveTeamMyselfModal.descriptionFooter')}</p>
      </ModalBody>

      <ModalFooter className="justify-content-start">
        <button className="btn btn-danger" disabled={isDeleting} onClick={onDelete}>
          {I18n.t('teamManagement.deleteMembership')}
        </button>
        <button className="btn btn-secondary" onClick={toggle}>
          {I18n.t('shared.cancel')}
        </button>
      </ModalFooter>
    </Modal>);
};
