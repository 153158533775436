import React from 'react';
import { useNavigate } from 'react-router-dom';
import I18n from '@/constants/i18n';
import MainMenuContainer from '@/containers/main_menu_container';
import Blank from '@/components/molecules/blank';
function NotFound() {
    var navigate = useNavigate();
    var canBack = window && window.history && window.history.length > 1;
    return (<div className="content-wrap">
      <MainMenuContainer />
      <div className="content-main">
        <Blank className="blank-not-found" description={I18n.t('shared.notFound.description')} title={I18n.t('shared.notFound.title')}>
          {canBack && (<button className="mt-3 btn-major-action btn btn-secondary" onClick={function () { return navigate(-1); }}>
              {I18n.t('shared.notFound.back')}
            </button>)}
        </Blank>
      </div>
    </div>);
}
export default NotFound;
