import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
import queryString from 'query-string';
export var FETCH_WORKSPACES_REALTIME_REPORT_REQUEST = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_REALTIME_REPORT_REQUEST';
export var FETCH_WORKSPACES_REALTIME_REPORT_SUCCESS = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_REALTIME_REPORT_SUCCESS';
export var FETCH_WORKSPACES_REALTIME_REPORT_FAILURE = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_REALTIME_REPORT_FAILURE';
export var SET_WORKSPACES_REALTIME_REPORT = 'timecrowd/WORKSPACES/SET_WORKSPACES_REALTIME_REPORT';
export var RESET_WORKSPACES_REALTIME_REPORT = 'timecrowd/WORKSPACES/RESET_WORKSPACES_REALTIME_REPORT';
export var initialState = {
    status: 'pending',
    item: null,
};
var version = 1;
export default function workspaces(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_WORKSPACES_REALTIME_REPORT_REQUEST:
            return __assign(__assign({}, state), { status: 'loading' });
        case FETCH_WORKSPACES_REALTIME_REPORT_SUCCESS:
            return __assign(__assign({}, state), { status: 'success', item: action.payload });
        case FETCH_WORKSPACES_REALTIME_REPORT_FAILURE:
            return __assign(__assign({}, state), { status: 'failure' });
        case SET_WORKSPACES_REALTIME_REPORT:
            return __assign(__assign({}, state), { item: action.payload });
        case RESET_WORKSPACES_REALTIME_REPORT:
            return initialState;
        default:
            return state;
    }
}
export var fetchWorkspacesRealtimeReportRequest = function () { return ({
    type: FETCH_WORKSPACES_REALTIME_REPORT_REQUEST,
}); };
export var fetchWorkspacesRealtimeReportSuccess = function (item) { return ({
    type: FETCH_WORKSPACES_REALTIME_REPORT_SUCCESS,
    payload: item,
}); };
export var fetchWorkspacesRealtimeReportFailure = function () { return ({
    type: FETCH_WORKSPACES_REALTIME_REPORT_FAILURE,
}); };
export var fetchWorkspacesRealtimeReport = function (params) {
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesRealtimeReportRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/realtime_report?".concat(queryString.stringify(__assign(__assign({}, params), { version: version }))), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(fetchWorkspacesRealtimeReportSuccess(item));
                        return [2 /*return*/, { error: false, data: item }];
                    case 2:
                        dispatch(fetchWorkspacesRealtimeReportFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var setWorkspacesRealtimeReport = function (item) { return ({
    type: SET_WORKSPACES_REALTIME_REPORT,
    payload: item,
}); };
export var resetWorkspacesRealtimeReport = function () { return ({
    type: RESET_WORKSPACES_REALTIME_REPORT,
}); };
