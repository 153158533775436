import { __awaiter, __generator } from "tslib";
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import { removeDangerNotificationMessage } from '@/modules/notification';
export var ChangeUserEmailForm = function (_a) {
    var user = _a.user, updateUser = _a.updateUser, fetchCurrentUser = _a.fetchCurrentUser, resendEmailConfirmation = _a.resendEmailConfirmation, cancelEmailConfirmation = _a.cancelEmailConfirmation, setNotificationMessage = _a.setNotificationMessage;
    var _b = React.useState(''), email = _b[0], setEmail = _b[1];
    var _c = React.useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var _d = React.useState(false), isLoading = _d[0], setIsLoading = _d[1];
    React.useEffect(function () {
        setEmail('');
    }, [isOpen]);
    var onChangeEmail = React.useCallback(function (e) {
        setEmail(e.target.value);
    }, []);
    var handleOpenModal = React.useCallback(function () {
        var _a;
        if (((_a = user.allowed_sign_in_methods) === null || _a === void 0 ? void 0 : _a.length) &&
            !user.allowed_sign_in_methods.includes('email')) {
            setNotificationMessage('danger', I18n.t('shared.operationRestrictedByAdministrator'));
            return;
        }
        setIsOpen(true);
    }, [user.allowed_sign_in_methods, setNotificationMessage]);
    var dispatch = useDispatch();
    var handleCloseModal = React.useCallback(function () {
        dispatch(removeDangerNotificationMessage());
        setIsOpen(false);
    }, [dispatch]);
    var onSubmit = React.useCallback(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, updateUser({ email: email })];
                    case 1:
                        res = _a.sent();
                        setIsLoading(false);
                        if (!!res.error) return [3 /*break*/, 3];
                        return [4 /*yield*/, fetchCurrentUser()];
                    case 2:
                        _a.sent();
                        setNotificationMessage('success', I18n.t('shared.emailConfirmationSent'));
                        handleCloseModal();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    }, [
        email,
        fetchCurrentUser,
        setNotificationMessage,
        handleCloseModal,
        updateUser,
    ]);
    var onCancel = React.useCallback(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, cancelEmailConfirmation()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, fetchCurrentUser()];
                    case 2:
                        _a.sent();
                        setIsLoading(false);
                        setNotificationMessage('success', I18n.t('shared.emailConfirmationCanceled'));
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [cancelEmailConfirmation, fetchCurrentUser, setNotificationMessage]);
    var onResend = React.useCallback(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, resendEmailConfirmation()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, fetchCurrentUser()];
                    case 2:
                        _a.sent();
                        setIsLoading(false);
                        setNotificationMessage('success', I18n.t('shared.emailConfirmationResent'));
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [fetchCurrentUser, resendEmailConfirmation, setNotificationMessage]);
    var isReconfirming = user.unconfirmed_email !== null;
    var canSubmit = email && email !== user.email;
    return (<>
      <div className="d-flex">
        <input disabled className="form-control" id="email" type="email" value={user.email}/>
        <button className="btn btn-secondary ml-1" disabled={isReconfirming} type="button" onClick={handleOpenModal}>
          {I18n.t('shared.change')}
        </button>
      </div>

      {isReconfirming && (<div className="mb-3">
          <div className="mt-1 mb-1" dangerouslySetInnerHTML={{
                __html: I18n.t('shared.notesForUnconfirmedEmailHtml', {
                    email: user.unconfirmed_email,
                }),
            }}/>
          <button className="btn btn-link p-0" disabled={isLoading} onClick={onCancel}>
            {I18n.t('shared.cancel')}
          </button>
          <button className="btn btn-link p-0 ml-3" disabled={isLoading} onClick={onResend}>
            {I18n.t('shared.resend')}
          </button>
        </div>)}

      <Modal isOpen={isOpen} toggle={handleCloseModal}>
        <ModalHeaderMod toggle={handleCloseModal}>
          {I18n.t('shared.changeEmail')}
        </ModalHeaderMod>
        <ModalBody>
          <div className="form-group">
            <label>{I18n.t('shared.currentEmail')}</label>
            <div className="form-control-static">{user.email}</div>
          </div>

          <div className="form-group mt-3">
            <label htmlFor="new-email">{I18n.t('shared.newEmail')}</label>
            <div>
              <input autoFocus className="form-control" id="new-email" type="email" value={email} onChange={onChangeEmail}/>
            </div>
          </div>

          <p className="mt-3">{I18n.t('shared.changeEmailDescription')}</p>
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <button className="btn btn-secondary" disabled={isLoading} onClick={handleCloseModal}>
            {I18n.t('shared.cancel')}
          </button>

          <button className="btn btn-primary" disabled={isLoading || !canSubmit} onClick={onSubmit}>
            {I18n.t('shared.sendConfirmation')}
          </button>
        </ModalFooter>
      </Modal>
    </>);
};
