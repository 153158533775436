import { __assign } from "tslib";
import { isLeaf } from '@/utils/category_helper';
export var expressionErrors = [
    'master_not_found',
    'value_not_found',
    'zero_division_error',
    'unexpected_error',
];
export var arrangeCustomFieldsValues = function (customFieldsMasters, customFieldsValues, categories, target) {
    var filteredCustomFieldsValues = customFieldsValues
        .filter(function (_a) {
        var target_id = _a.target_id;
        return target_id === target.id;
    })
        .sort(function (a, b) { return a.custom_fields_master_id - b.custom_fields_master_id; });
    var customFieldsValuesByMasterId = filteredCustomFieldsValues.reduce(function (acc, value) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[value.custom_fields_master_id] = value, _a)));
    }, {});
    var errorsByMasterId = {};
    customFieldsMasters.forEach(function (_a) {
        var id = _a.id, requirement = _a.requirement, field_type = _a.field_type;
        errorsByMasterId[id] = [];
        var value = customFieldsValuesByMasterId[id] || { value_text: '' };
        var isLeafRequirement = requirement === 'leaf' && isLeaf(categories, target);
        if ((requirement === 'all' || isLeafRequirement) && !value.value_text) {
            errorsByMasterId[id] = ['requirement'];
        }
        if (field_type === 'expression' &&
            expressionErrors.includes(value.value_text)) {
            errorsByMasterId[id].push(value.value_text);
        }
    });
    return {
        customFieldsValuesByMasterId: customFieldsValuesByMasterId,
        errorsByMasterId: errorsByMasterId,
    };
};
