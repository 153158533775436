import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import { MultiTeamsSelectList } from './multi_teams_select_list';
export var MultiTeamsSelectDropdown = function (props) {
    var allTeams = props.allTeams, _a = props.disabled, disabled = _a === void 0 ? false : _a, selectedTeams = props.selectedTeams, workedTeamIdSet = props.workedTeamIdSet, onApply = props.onApply;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var handleToggle = function () {
        setIsOpen(!isOpen);
    };
    var handleApply = function (teams) {
        onApply(teams);
        setIsOpen(false);
    };
    return (<Dropdown isOpen={isOpen} toggle={handleToggle}>
      <DropdownToggle caret className={disabled ? '' : 'text-secondary'} color="dark" disabled={disabled}>
        {selectedTeams.length < 1 ? (I18n.t('shared.all')) : (<>
            {selectedTeams.slice(0, 5).map(function (team) { return (<Avatar key={team.id} className="mr-1 border-0" image={team.avatar_url} name={team.name} size="sm"/>); })}
            {selectedTeams.length > 5 && " + ".concat(selectedTeams.length - 5)}
          </>)}
      </DropdownToggle>
      <DropdownMenu className="bg-white max-w-lg overflow-hidden p-0">
        <div className="d-flex flex-column max-h-[80vh]">
          <MultiTeamsSelectList allTeams={allTeams} selectedTeams={selectedTeams} workedTeamIdSet={workedTeamIdSet} onApply={handleApply}/>
        </div>
      </DropdownMenu>
    </Dropdown>);
};
