import React from 'react';
import classNames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import { categoryWithAncestors } from '@/utils/category_helper';
import { formatTime } from '@/utils/time_helper';
import { formatDuration } from '@/utils/time_helper';
import I18n from '@/constants/i18n';
import variants from '@/constants/variants';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import Icon from '@/components/atoms/icon';
var TaskInline = function (props) {
    var className = props.className, task = props.task, _a = props.tag, Tag = _a === void 0 ? 'div' : _a, timeEntry = props.timeEntry, view = props.view, isShowCategoryOnWeeklyCalendar = props.isShowCategoryOnWeeklyCalendar, _b = props.isPC, isPC = _b === void 0 ? variants.isPC : _b, isSingleLineTitle = props.isSingleLineTitle, isOverlap = props.isOverlap;
    var isShowDetails = view === 'daily';
    var isShowCategory = isPC && view === 'weekly' && isShowCategoryOnWeeklyCalendar;
    var containerClassNames = classNames('task task-inline', className, {
        archived: task.category.archived,
        'd-flex flex-column justify-content-between': isShowCategory,
    });
    var titleClassNames = classNames('task-title', {
        'max-h-8': isSingleLineTitle,
    });
    var tooltipRef = React.useRef(null);
    return (<Tag className={containerClassNames}>
      <div className="task-block">
        {isOverlap && (<Icon className="text-warning mt-1 mr-0.5" name="warning"/>)}
        <span className={titleClassNames}>{task.title}</span>
        <div className={isShowDetails ? 'd-flex' : 'd-none'}>
          <div className="ml-3">
            <CategoryCircleIcon className="ml-n1 top-0" color={task.category.color}/>
            <span ref={tooltipRef} className="task-category-title ml-n1">
              {task.category.title} - {task.team.name}
            </span>
          </div>
          <div className="ml-3">
            {!!timeEntry.stopped_at && formatDuration(timeEntry.duration)}
            <span className="ml-1">
              {formatTime(new Date(timeEntry.started_at), false)}
              {' - '}
              {!timeEntry.stopped_at
            ? I18n.t('shared.now')
            : formatTime(new Date(timeEntry.stopped_at), false)}
            </span>
          </div>
        </div>
      </div>
      {isShowCategory && (<div className="task-block align-items-center">
          <CategoryCircleIcon className="ml-n1 top-0" color={task.category.color}/>
          <span className="task-category-title ml-n1">
            {categoryWithAncestors(task.category)}
          </span>
        </div>)}
      <UncontrolledTooltip fade={false} placement="top" target={tooltipRef}>
        {categoryWithAncestors(task.category)} - {task.team.name}
      </UncontrolledTooltip>
    </Tag>);
};
export default TaskInline;
