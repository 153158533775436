import { __assign } from "tslib";
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import I18n from '@/constants/i18n';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import Icon from '@/components/atoms/icon';
import HardDeleteTaskModalContainer from '@/containers/hard_delete_task_modal_container';
import Counter from '@/components/atoms/counter';
import { categoryWithAncestors } from '@/utils/category_helper';
import { TaskItemDetails } from './task_item_details';
import { TaskItemMenu } from './task_item_menu';
import { CategoryInfoContainer } from '@/containers/category_info_container';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
export var TaskItem = function (_a) {
    var _b;
    var task = _a.task, start = _a.start, stop = _a.stop, close = _a.close, reopen = _a.reopen, restore = _a.restore, isPC = _a.isPC, isLogging = _a.isLogging, currentEntry = _a.currentEntry, toggle = _a.toggle, date = _a.date, withDetails = _a.withDetails, _c = _a.timeEntries, timeEntries = _c === void 0 ? [] : _c, fetchDailyTimeEntries = _a.fetchDailyTimeEntries, destroyTimeEntry = _a.destroyTimeEntry;
    var _d = React.useState(false), isOpenOtherMenu = _d[0], setIsOpenOtherMenu = _d[1];
    var _e = React.useState(false), isOpenDeleteTaskModal = _e[0], setIsOpenDeleteTaskModal = _e[1];
    var _f = React.useState(false), isOpenDetails = _f[0], setIsOpenDetails = _f[1];
    var handleStart = function (e) {
        e.stopPropagation();
        start(task);
    };
    var handleStop = function (e) {
        e.stopPropagation();
        stop();
    };
    var handleClose = function (e) {
        e.stopPropagation();
        var params = {
            state: 'closed',
        };
        close(task, params);
    };
    var handleReopen = function (e) {
        e.stopPropagation();
        var params = {
            state: 'open',
        };
        reopen(task, params);
    };
    var handleClickDestroy = function (e) {
        e.stopPropagation();
        setIsOpenDeleteTaskModal(true);
    };
    var handleClickRestore = function (e) {
        e.stopPropagation();
        restore(task);
    };
    var handleClickOtherMenu = function () {
        setIsOpenOtherMenu(!isOpenOtherMenu);
    };
    var handleCloseDeleteTaskModal = function () {
        setIsOpenDeleteTaskModal(false);
    };
    var handleClickContainer = function (e) {
        if (task.category.archived) {
            return;
        }
        if (isPC && !task.deleted_at) {
            if (isLogging) {
                handleStop(e);
            }
            else {
                handleStart(e);
            }
        }
    };
    var isShowMenu = (task.url && task.url.length > 1) || task.deleted_at ? true : false;
    var tooltipId = "task-archived-tooltip-".concat(task.id, "-").concat(date);
    return (<div className="d-flex position-relative">
      {withDetails && (<div className="border-bottom-1">
          <button className="btn btn-link text-muted px-1 h-100 rounded-0 d-flex align-items-start pt-3" onClick={function (e) {
                e.stopPropagation();
                setIsOpenDetails(!isOpenDetails);
            }}>
            <Icon className="mt-1" name={"chevron-".concat(isOpenDetails ? 'up' : 'down')}/>
          </button>
        </div>)}
      <div className="flex-grow-1">
        <div className={[
            'task task-controls-container',
            isLogging ? 'logging' : '',
            task.state !== 'open' ? 'complete' : '',
            task.category.archived ? 'archived' : '',
            withDetails ? 'pl-0' : '',
        ].join(' ')}>
          <div onClick={handleClickContainer}>
            <div className="task-header">
              {isLogging ? (<span className="task-icon task-icon-stop">
                  <Icon name="stop-circle"/>
                </span>) : (<span className={"task-icon task-icon-start ".concat(task.category.archived ? 'opacity-50' : '')} id={tooltipId}>
                  <Icon name="start-circle"/>
                </span>)}
              <span className="task-title">{task.title}</span>
              {task.category.archived && (<UncontrolledTooltip fade={false} target={tooltipId}>
                  {I18n.t('shared.archivedTaskCannotBeStarted')}
                </UncontrolledTooltip>)}
            </div>
            <div className="task-block align-items-center">
              <div className="task-category">
                <WorkspaceAvatarContainer className="mr-1 ml-n1 grayscale" workspaceId={task.team.workspaces_workspace_id}/>
                <CategoryCircleIcon className="mr-1" color={task.category.color} style={{ top: '1px' }}/>
                <span className="task-category-title">
                  <span className="mr-1 d-flex align-items-center">
                    {categoryWithAncestors(task.category)} - {task.team.name}
                    <CategoryInfoContainer requiresCustomFields category={__assign(__assign({}, task.category), { team: task.team })} className="mx-1"/>
                  </span>
                  {task.category.archived && (<span className="badge badge-info">
                      <Icon className="mr-1" name="archive" size="sm"/>
                      {I18n.t('shared.archived')}
                    </span>)}
                </span>
              </div>
              <div className="task-duration">
                {isLogging ? (<span>
                    <Icon name="clock"/>
                    <Counter entry={currentEntry} totalTimeSec={task.total_time_seconds}/>
                  </span>) : (<span>
                    <Icon name="clock"/>
                    {task.total_time}
                  </span>)}
                {!!task.time_entries_count && (<span className="ml-2">({task.time_entries_count})</span>)}
              </div>
            </div>
          </div>
          <TaskItemMenu handleClickDestroy={handleClickDestroy} handleClickOtherMenu={handleClickOtherMenu} handleClickRestore={handleClickRestore} handleClose={handleClose} handleReopen={handleReopen} handleStart={handleStart} handleStop={handleStop} isLogging={isLogging} isOpenOtherMenu={isOpenOtherMenu} isShowMenu={isShowMenu} task={task} toggle={toggle}/>
          <HardDeleteTaskModalContainer isOpen={isOpenDeleteTaskModal} task={task} team={task.team} toggle={handleCloseDeleteTaskModal}/>
        </div>
        <TaskItemDetails currentEntry={currentEntry} date={date} destroyTimeEntry={destroyTimeEntry} fetchDailyTimeEntries={fetchDailyTimeEntries} isOpened={isOpenDetails} task={task} timeEntries={timeEntries} totalTimeSec={task.total_time_seconds + ((_b = currentEntry === null || currentEntry === void 0 ? void 0 : currentEntry.duration) !== null && _b !== void 0 ? _b : 0)}/>
      </div>
    </div>);
};
