import React from 'react';
import isEmpty from 'lodash/isEmpty';
import I18n from '@/constants/i18n';
import ChargeListContainer from '@/containers/charge_list_container';
import { numberWithDelimiter } from '@/utils/number_helper';
import { CreatePaymentModal } from './create_payment_modal';
import { CapacityField } from './capacity_field';
import { remainingDays } from '@/utils/time_helper';
import { PayjpCheckoutButton } from '@/components/payjp_checkout_button';
export var NewPaymentForm = function (_a) {
    var email = _a.email, openURL = _a.openURL, payment = _a.payment, team = _a.team, workspace = _a.workspace, onCreate = _a.onCreate;
    var target = workspace || team;
    var _b = React.useState(''), payjpToken = _b[0], setPayjpToken = _b[1];
    var _c = React.useState(''), capacity = _c[0], setCapacity = _c[1];
    var _d = React.useState(false), hasLimit = _d[0], setHasLimit = _d[1];
    var _e = React.useState(false), isOpenCreateModal = _e[0], setIsOpenCreateModal = _e[1];
    var handleCreatePayjpToken = React.useCallback(function (token) {
        setPayjpToken(token);
        setIsOpenCreateModal(true);
    }, [setPayjpToken, setIsOpenCreateModal]);
    var handleChangeHasLimit = function (e) {
        var capacity = String(payment.count);
        setCapacity(capacity);
        setHasLimit(e.target.checked);
    };
    var handleChangeCapacity = function (e) {
        if (e.target.value === '' ||
            (Number(e.target.value) > 0 && Number(e.target.value) < 10000000)) {
            setCapacity(e.target.value);
        }
    };
    var handleClickApplyPayment = function () {
        if (!payjpToken) {
            return;
        }
        onCreate(team === null || team === void 0 ? void 0 : team.id, payjpToken, capacity);
    };
    var handleClickLink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    var isInvalidCapacity = isEmpty(capacity) && hasLimit;
    var form = (<div className="row no-gutters w-100">
      <div className="col-12">
        <div className="form-group mb-3">
          {target.payable && (<p className="text-danger">
              {I18n.t('payment.trialRemaining', {
                days: remainingDays(target.free_ends_at),
            })}
            </p>)}
          <div className="p-2 mt-3" style={{
            background: '#f9f9f9',
            border: '1px solid #ececec',
            borderRadius: '.5rem',
            width: '100%',
            maxWidth: '480px',
        }}>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center mr-1">
                <span className="badge badge-success">
                  {I18n.t('payment.totalAmount')}
                </span>
                <strong className="ml-1 font-weight-bold h2">
                  {I18n.t('shared.currencyUnit')}
                  {numberWithDelimiter(payment.unit_price * payment.count)}
                </strong>
              </div>
              <span>
                ({I18n.t('shared.currencyUnit')}
                {payment.unit_price} ×{' '}
                <span>
                  {I18n.t('payment.currently')}
                  <span className="h3 ml-1">
                    <strong>{payment.count}</strong>
                  </span>
                </span>
                {I18n.t('payment.people')})
              </span>
            </div>
          </div>
          <CapacityField capacity={capacity} hasLimit={hasLimit} invalid={isInvalidCapacity} unitPrice={payment.unit_price} onChangeCapacity={handleChangeCapacity} onChangeHasLimit={handleChangeHasLimit}/>
        </div>
        <hr className="my-4"/>
        <div className="form-group my-3">
          <p className="h3 mb-3">{I18n.t('payment.applyCreditCard')}</p>
          <div className={"mb-3 ".concat(isInvalidCapacity ? 'd-none' : '')}>
            <PayjpCheckoutButton email={email} publicKey={payment.public_key} onCreateToken={handleCreatePayjpToken}/>
          </div>
        </div>
      </div>
      <div className="col-12 mb-3">
        <hr />
        <button className="btn-major-action btn btn-primary" disabled={!payjpToken || isInvalidCapacity} onClick={function () { return setIsOpenCreateModal(true); }}>
          {I18n.t('teamManagement.billingForm.complete')}
        </button>
        <div>
          <br />
          <p className="text-secondary mb-1">
            <span>
              {I18n.t('teamManagement.billingForm.annotationForPlanApplication')}
            </span>
          </p>
          <p className="text-secondary">
            <span>
              {I18n.t('teamManagement.billingForm.annotationForInvoicePayment.beforeText')}
            </span>
            <a href="https://timecrowd.net/contacts/new" onClick={handleClickLink}>
              {I18n.t('teamManagement.billingForm.annotationForInvoicePayment.helpText')}
            </a>
            <span>
              {I18n.t('teamManagement.billingForm.annotationForInvoicePayment.afterText')}
            </span>
          </p>
        </div>
      </div>
      <ChargeListContainer isShowUnpaid/>
    </div>);
    return (<>
      {form}
      <CreatePaymentModal disabled={isInvalidCapacity} isOpen={isOpenCreateModal} onCancel={function () { return setIsOpenCreateModal(false); }} onSubmit={handleClickApplyPayment}/>
    </>);
};
