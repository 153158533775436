import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import CustomCheck from '@/components/atoms/custom_check';
import Icon from '@/components/atoms/icon';
import Avatar from '@/components/atoms/avatar';
export var HardDeleteTaskModal = function (_a) {
    var isOpen = _a.isOpen, taskTitle = _a.taskTitle, teamAvatarUrl = _a.teamAvatarUrl, teamName = _a.teamName, onClickDelete = _a.onClickDelete, onClickHelp = _a.onClickHelp, onClose = _a.onClose;
    var _b = React.useState(false), isConfirm = _b[0], setIsConfirm = _b[1];
    var handleChangeConfirm = function () {
        setIsConfirm(!isConfirm);
    };
    var handleClose = function () {
        setIsConfirm(false);
        onClose();
    };
    return (<Modal isOpen={isOpen} toggle={handleClose}>
      <ModalHeaderMod toggle={handleClose}>
        {I18n.t('hardDeleteTaskModal.title')}
      </ModalHeaderMod>

      <ModalBody>
        <p>{I18n.t('hardDeleteTaskModal.description')}</p>
        <div className="ml-2">
          <label className="col-form-label" style={{ lineHeight: '1.2rem', width: '100%' }}>
            <Icon className="mr-1" name="document"/>
            <span style={{
            fontSize: '10px',
        }}>
              {I18n.t('shared.title')}
            </span>
          </label>
          {taskTitle}
        </div>
        <div className="ml-2">
          <label className="col-form-label" style={{ lineHeight: '1.2rem', width: '100%' }}>
            <Icon className="mr-1" name="user-group"/>
            <span style={{
            fontSize: '10px',
        }}>
              {I18n.t('shared.team')}
            </span>
          </label>
          <Avatar className="mr-1" image={teamAvatarUrl} size="sm"/>
          {teamName}
        </div>
        <div className="form-group mt-2">
          <CustomCheck checked={isConfirm} onChange={handleChangeConfirm}>
            <span className="text-danger">
              {I18n.t('hardDeleteTaskModal.confirmation')}
            </span>
          </CustomCheck>
        </div>
      </ModalBody>

      <ModalFooter style={{ justifyContent: 'flex-start' }}>
        <button className="btn btn-danger" disabled={!isConfirm} onClick={onClickDelete}>
          {I18n.t('shared.delete')}
        </button>
        <button className="btn btn-secondary" onClick={handleClose}>
          {I18n.t('shared.cancel')}
        </button>
        <a className="btn btn-link ml-auto" href="https://help.timecrowd.net/task-restoration-delete" onClick={onClickHelp}>
          {I18n.t('shared.help')}
        </a>
      </ModalFooter>
    </Modal>);
};
