import React from 'react';
import uniqBy from 'lodash/uniqBy';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import I18n from '@/constants/i18n';
import { workspaceTeams } from '@/utils/workspace_helper';
import { fetchTeamMemberships } from '@/modules/memberships';
import { fetchTeamEmploys } from '@/modules/employs';
import { fetchWorkspacesMemberships } from '@/modules/workspaces_memberships';
import { fetchWorkspacesEmploys } from '@/modules/workspaces_employs';
import { bulkUpsertTeamMemberships, deleteMembership, } from '@/modules/memberships';
import { setNotificationMessage } from '@/modules/notification';
import { TeamMemberSetting } from '@/components/team_member_setting';
import NotFound from '@/components/pages/not_found';
var TeamMemberSettingContainer = function () {
    var _a;
    var team_id = useParams().team_id;
    var memberships = useSelector(function (state) { return state.memberships; });
    var teams = workspaceTeams(useSelector(function (state) { return state.teams.items; }));
    var user = useSelector(function (state) { return state.user; });
    var workspaces = useSelector(function (state) { return state.workspaces; });
    var workspacesMemberships = useSelector(function (state) { return state.workspacesMemberships.items; });
    var dispatch = useDispatch();
    var handleFetchWorkspacesMemberships = React.useCallback(function () {
        dispatch(fetchWorkspacesMemberships());
    }, [dispatch]);
    var handleFetchWorkspacesEmploys = React.useCallback(function (teamId) {
        dispatch(fetchWorkspacesEmploys(teamId));
    }, [dispatch]);
    var handleBulkUpsertTeamMemberships = React.useCallback(function (teamId, memberships) {
        return dispatch(bulkUpsertTeamMemberships(teamId, memberships));
    }, [dispatch]);
    var handleSetNotificationMessage = React.useCallback(function (messageType, message) {
        dispatch(setNotificationMessage(messageType, message));
    }, [dispatch]);
    var navigate = useNavigate();
    var handleDeleteMembership = React.useCallback(function (team, membershipId) {
        dispatch(deleteMembership(team.id, membershipId)).then(function (response) {
            if (!response.error) {
                handleSetNotificationMessage('success', I18n.t('confirmLeaveTeamMyselfModal.leaveTeamSuccess', {
                    teamName: team.name,
                }));
                navigate("/app");
            }
        });
    }, [dispatch, handleSetNotificationMessage, navigate]);
    React.useEffect(function () {
        dispatch(fetchTeamMemberships(team_id));
        dispatch(fetchTeamEmploys(team_id));
    }, [dispatch, team_id]);
    var teamId = Number(team_id);
    var team = teams.find(function (team) { return team.id === teamId; });
    var teamMemberships = (_a = uniqBy(memberships.itemsByTeamId[teamId], 'id')) !== null && _a !== void 0 ? _a : [];
    if (memberships.isLoaded &&
        teamMemberships.length > 0 &&
        user.role !== 'bot' &&
        !teamMemberships.some(function (m) { return m.user.id === user.id; })) {
        return <NotFound />;
    }
    return (<TeamMemberSetting memberships={teamMemberships} team={team} user={user} workspaces={workspaces} workspacesMemberships={workspacesMemberships} onBulkUpsertTeamMemberships={handleBulkUpsertTeamMemberships} onDelete={handleDeleteMembership} onFetchWorkspacesEmploys={handleFetchWorkspacesEmploys} onFetchWorkspacesMemberships={handleFetchWorkspacesMemberships} onSetNotificationMessage={handleSetNotificationMessage}/>);
};
export default TeamMemberSettingContainer;
