import { __assign, __spreadArray } from "tslib";
import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '@/constants/i18n';
import { ClearableSearchField } from '@/components/clearable_search_field';
import { buildPattern } from '@/utils/search_helper';
import Avatar from '@/components/atoms/avatar';
import CustomCheck from '@/components/atoms/custom_check';
export var TeamMembershipsField = function (_a) {
    var currentUser = _a.currentUser, team = _a.team, memberships = _a.memberships, workspacesMemberships = _a.workspacesMemberships, onChangeMemberships = _a.onChangeMemberships, className = _a.className, tableRef = _a.tableRef;
    var _b = React.useState(''), searchText = _b[0], setSearchText = _b[1];
    var filteredWorkspacesMemberships = React.useMemo(function () {
        var usedMembershipUserIds = memberships.map(function (_a) {
            var id = _a.user.id;
            return id;
        });
        var selectableWorkspacesMemberships = workspacesMemberships.filter(function (_a) {
            var user_id = _a.user_id;
            return !usedMembershipUserIds.includes(user_id);
        });
        if (!searchText) {
            return selectableWorkspacesMemberships;
        }
        var pattern = buildPattern(searchText);
        return selectableWorkspacesMemberships.filter(function (membership) {
            return pattern.test(membership.user_nickname);
        });
    }, [searchText, workspacesMemberships, memberships]);
    var filteredMemberships = React.useMemo(function () {
        if (!searchText) {
            return memberships;
        }
        var pattern = buildPattern(searchText);
        return memberships.filter(function (membership) {
            return pattern.test(membership.user.nickname);
        });
    }, [searchText, memberships]);
    var updatableMembershipsIds = memberships
        .filter(function (membership) { return membership.can_update && membership.team.id === team.id; })
        .map(function (_a) {
        var id = _a.id;
        return id;
    });
    return (<div className={className}>
      {I18n.t('shared.member')}
      <span className="mx-1">{memberships.length}</span>/
      <span className="ml-1">{workspacesMemberships.length}</span>
      <ClearableSearchField className="mt-1" placeholderKey="memberListSearchPlaceholder" searchText={searchText} size="md" onChangeSearchText={setSearchText}/>
      <table ref={tableRef} className="mt-2 w-100">
        <thead>
          <tr className="bg-faded">
            <td className="px-2 py-1">{I18n.t('shared.username')}</td>
            <td className="px-2 py-1">{I18n.t('shared.teamRole')}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="p-1" colSpan={2}>
              {I18n.t('shared.selected')}
            </th>
          </tr>
          {filteredMemberships.map(function (membership) {
            var isDeletable = membership.can_destroy &&
                membership.team.id === team.id &&
                membership.user.id !== currentUser.id;
            return (<tr key={membership.user.id}>
                <td className="d-flex align-items-center p-1 pt-2">
                  <CustomCheck checked className="mr-1" disabled={!isDeletable} onClick={function (e) {
                    e.preventDefault();
                    if (!isDeletable) {
                        return;
                    }
                    var newMemberships = memberships.filter(function (m) { return m.user.id !== membership.user.id; });
                    onChangeMemberships(newMemberships);
                }}/>
                  <Avatar className="mr-1" image={membership.user.avatar_url} size="md"/>
                  {membership.user.nickname}
                </td>
                <td className="pt-1">
                  <UncontrolledDropdown>
                    <DropdownToggle caret disabled={!updatableMembershipsIds.includes(membership.id)}>
                      {I18n.t("shared.roles.".concat(membership.ancestral_role))}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {['manager', 'member'].map(function (role) { return (<DropdownItem key={role} onClick={function () {
                        var newMemberships = memberships.map(function (m) {
                            if (m.user.id === membership.user.id) {
                                return __assign(__assign({}, m), { ancestral_role: role });
                            }
                            return m;
                        });
                        onChangeMemberships(newMemberships);
                    }}>
                          {I18n.t("shared.roles.".concat(role))}
                        </DropdownItem>); })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>);
        })}
          <tr>
            <th className="p-1 pt-3" colSpan={2}>
              {I18n.t('shared.notSelected')}
            </th>
          </tr>
          {filteredWorkspacesMemberships.map(function (_a) {
            var id = _a.id, user_id = _a.user_id, user_nickname = _a.user_nickname, user_avatar_url = _a.user_avatar_url;
            return (<tr key={id}>
                <td className="d-flex align-items-center p-1" colSpan={2}>
                  <CustomCheck checked={false} className="mr-1" onClick={function (e) {
                    e.preventDefault();
                    var newMemberships = __spreadArray(__spreadArray([], memberships, true), [
                        {
                            user: {
                                id: user_id,
                                nickname: user_nickname,
                                avatar_url: user_avatar_url,
                            },
                            ancestral_role: 'member',
                            can_update: true,
                            can_destroy: true,
                            team: team,
                        },
                    ], false);
                    onChangeMemberships(newMemberships);
                }}/>
                  <Avatar className="mr-1" image={user_avatar_url} size="md"/>
                  {user_nickname}
                </td>
              </tr>);
        })}
        </tbody>
      </table>
    </div>);
};
