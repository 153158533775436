import { __assign } from "tslib";
import api from '@/api';
import api2 from '@/api2';
export var FETCH_TEAM_MEMBERSHIPS_REQUEST = 'timecrowd/memberships/FETCH_TEAM_MEMBERSHIPS_REQUEST';
export var FETCH_TEAM_MEMBERSHIPS_SUCCESS = 'timecrowd/memberships/FETCH_TEAM_MEMBERSHIPS_SUCCESS';
export var FETCH_TEAM_MEMBERSHIPS_FAILURE = 'timecrowd/memberships/FETCH_TEAM_MEMBERSHIPS_FAILURE';
export var UPDATE_MEMBERSHIP_REQUEST = 'timecrowd/memberships/UPDATE_MEMBERSHIP_REQUEST';
export var UPDATE_MEMBERSHIP_SUCCESS = 'timecrowd/memberships/UPDATE_MEMBERSHIP_SUCCESS';
export var UPDATE_MEMBERSHIP_FAILURE = 'timecrowd/memberships/UPDATE_MEMBERSHIP_FAILURE';
export var DELETE_MEMBERSHIP_REQUEST = 'timecrowd/memberships/DELETE_MEMBERSHIP_REQUEST';
export var DELETE_MEMBERSHIP_SUCCESS = 'timecrowd/memberships/DELETE_MEMBERSHIP_SUCCESS';
export var DELETE_MEMBERSHIP_FAILURE = 'timecrowd/memberships/DELETE_MEMBERSHIP_FAILURE';
export var BULK_UPSERT_MEMBERSHIPS_REQUEST = 'timecrowd/memberships/BULK_UPSERT_MEMBERSHIPS_REQUEST';
export var BULK_UPSERT_MEMBERSHIPS_SUCCESS = 'timecrowd/memberships/BULK_UPSERT_MEMBERSHIPS_SUCCESS';
export var BULK_UPSERT_MEMBERSHIPS_FAILURE = 'timecrowd/memberships/BULK_UPSERT_MEMBERSHIPS_FAILURE';
export var initialState = {
    isLoaded: false,
    isLoading: false,
    isSubmitting: false,
    itemsByTeamId: {},
};
function changeMembership(memberships, newMembership) {
    return memberships.map(function (membership) {
        if (membership.id === newMembership.id) {
            return newMembership;
        }
        else {
            return membership;
        }
    });
}
export default function memberships(state, action) {
    var _a, _b, _c, _d;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_TEAM_MEMBERSHIPS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isLoaded: false });
        case UPDATE_MEMBERSHIP_REQUEST:
        case DELETE_MEMBERSHIP_REQUEST:
        case BULK_UPSERT_MEMBERSHIPS_REQUEST:
            return __assign(__assign({}, state), { isSubmitting: true });
        case FETCH_TEAM_MEMBERSHIPS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isLoaded: true, itemsByTeamId: __assign(__assign({}, state.itemsByTeamId), (_a = {}, _a[action.teamId] = action.memberships, _a)) });
        case UPDATE_MEMBERSHIP_SUCCESS:
            return __assign(__assign({}, state), { isSubmitting: false, itemsByTeamId: __assign(__assign({}, state.itemsByTeamId), (_b = {}, _b[action.teamId] = changeMembership(state.itemsByTeamId[action.teamId], action.membership), _b)) });
        case DELETE_MEMBERSHIP_SUCCESS:
            return __assign(__assign({}, state), { isSubmitting: false, itemsByTeamId: __assign(__assign({}, state.itemsByTeamId), (_c = {}, _c[action.teamId] = state.itemsByTeamId[action.teamId].filter(function (membership) { return membership.id !== action.id; }), _c)) });
        case BULK_UPSERT_MEMBERSHIPS_SUCCESS:
            return __assign(__assign({}, state), { isSubmitting: false, itemsByTeamId: __assign(__assign({}, state.itemsByTeamId), (_d = {}, _d[action.teamId] = action.memberships, _d)) });
        case FETCH_TEAM_MEMBERSHIPS_FAILURE:
            return __assign(__assign({}, state), { isLoading: false, isLoaded: false });
        case UPDATE_MEMBERSHIP_FAILURE:
        case DELETE_MEMBERSHIP_FAILURE:
        case BULK_UPSERT_MEMBERSHIPS_FAILURE:
            return __assign(__assign({}, state), { isSubmitting: false });
        default:
            return state;
    }
}
export var fetchTeamMembershipsRequest = function () { return ({
    type: FETCH_TEAM_MEMBERSHIPS_REQUEST,
}); };
export var fetchTeamMembershipsSuccess = function (teamId, memberships) { return ({
    type: FETCH_TEAM_MEMBERSHIPS_SUCCESS,
    teamId: teamId,
    memberships: memberships,
}); };
export var fetchTeamMembershipsFailure = function () { return ({
    type: FETCH_TEAM_MEMBERSHIPS_FAILURE,
}); };
export var updateMembershipRequest = function () { return ({
    type: UPDATE_MEMBERSHIP_REQUEST,
}); };
export var updateMembershipSuccess = function (teamId, membership) { return ({
    type: UPDATE_MEMBERSHIP_SUCCESS,
    teamId: teamId,
    membership: membership,
}); };
export var updateMembershipFailure = function () { return ({
    type: UPDATE_MEMBERSHIP_FAILURE,
}); };
export var deleteMembershipRequest = function () { return ({
    type: DELETE_MEMBERSHIP_REQUEST,
}); };
export var deleteMembershipSuccess = function (teamId, id) { return ({
    type: DELETE_MEMBERSHIP_SUCCESS,
    teamId: teamId,
    id: id,
}); };
export var deleteMembershipFailure = function () { return ({
    type: DELETE_MEMBERSHIP_FAILURE,
}); };
export var fetchTeamMemberships = function (teamId) {
    return function (dispatch, getState) {
        dispatch(fetchTeamMembershipsRequest());
        return api2(dispatch, getState, "/api/v2/teams/".concat(teamId, "/memberships"), 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (memberships) {
                    dispatch(fetchTeamMembershipsSuccess(teamId, memberships));
                    return memberships;
                });
            }
            else {
                dispatch(fetchTeamMembershipsFailure());
                return { error: true };
            }
        });
    };
};
export var updateMembership = function (teamId, id, params) {
    return function (dispatch, getState) {
        dispatch(updateMembershipRequest());
        return api2(dispatch, getState, "/api/v2/teams/".concat(teamId, "/memberships/").concat(id), 'PATCH', params).then(function (response) {
            if (response.ok) {
                return response.json().then(function (membership) {
                    dispatch(updateMembershipSuccess(teamId, membership));
                    return membership;
                });
            }
            else {
                dispatch(updateMembershipFailure());
                return { error: true };
            }
        });
    };
};
export var deleteMembership = function (teamId, id) {
    return function (dispatch, getState) {
        dispatch(deleteMembershipRequest());
        return api(dispatch, getState, "teams/".concat(teamId, "/memberships/").concat(id), 'DELETE').then(function (response) {
            if (response.error) {
                dispatch(deleteMembershipFailure());
                return { error: true };
            }
            else {
                dispatch(deleteMembershipSuccess(teamId, id));
                return { id: id, error: false };
            }
        });
    };
};
export var bulkUpsertTeamMembershipsRequest = function () { return ({
    type: BULK_UPSERT_MEMBERSHIPS_REQUEST,
}); };
export var bulkUpsertTeamMembershipsSuccess = function (teamId, memberships) { return ({
    type: BULK_UPSERT_MEMBERSHIPS_SUCCESS,
    teamId: teamId,
    memberships: memberships,
}); };
export var bulkUpsertTeamMembershipsFailure = function () { return ({
    type: BULK_UPSERT_MEMBERSHIPS_FAILURE,
}); };
export var bulkUpsertTeamMemberships = function (teamId, params) {
    return function (dispatch, getState) {
        dispatch(bulkUpsertTeamMembershipsRequest());
        return api2(dispatch, getState, "/api/v2/teams/".concat(teamId, "/memberships/bulk_upsert"), 'PATCH', params).then(function (response) {
            if (response.ok) {
                return response.json().then(function (memberships) {
                    dispatch(bulkUpsertTeamMembershipsSuccess(teamId, memberships));
                    return memberships;
                });
            }
            else {
                dispatch(bulkUpsertTeamMembershipsFailure());
                return { error: true };
            }
        });
    };
};
