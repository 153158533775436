import { __assign } from "tslib";
import React from 'react';
import { useParams } from 'react-router-dom';
import Backdrop from '@/components/atoms/backdrop';
import TaskFormCalendarItemContainer from '@/containers/task_form_calendar_item_container';
export var CalendarItemFormModal = function (_a) {
    var _b;
    var isOpen = _a.isOpen, timeEntry = _a.timeEntry, _c = _a.readOnly, readOnly = _c === void 0 ? false : _c, taskSearch = _a.taskSearch, selectedDay = _a.selectedDay, isMine = _a.isMine, onClose = _a.onClose, createTask = _a.createTask, toggleTaskForm = _a.toggleTaskForm;
    var user_id = useParams().user_id;
    var _d = React.useState(timeEntry.task), task = _d[0], setTask = _d[1];
    var _e = React.useState(timeEntry.task.category), category = _e[0], setCategory = _e[1];
    var _f = React.useState(false), isSearching = _f[0], setIsSearching = _f[1];
    var commentRef = React.useRef(null);
    React.useEffect(function () {
        document.body.classList.add('modal-open');
        return function () {
            document.body.classList.remove('modal-open');
        };
    }, []);
    React.useEffect(function () {
        var textarea = commentRef.current;
        if (textarea) {
            textarea.focus();
            textarea.setSelectionRange(textarea.value.length, textarea.value.length);
        }
    }, [commentRef]);
    var exactMatchedTask = React.useMemo(function () {
        return taskSearch.items.find(function (t) {
            return t.title === taskSearch.params.title && t.category.id === category.id;
        });
    }, [category.id, taskSearch.items, taskSearch.params.title]);
    React.useEffect(function () {
        if (exactMatchedTask && !task) {
            setTask(exactMatchedTask);
        }
    }, [task, exactMatchedTask]);
    React.useEffect(function () {
        if (taskSearch.isFetched) {
            setIsSearching(false);
        }
    }, [taskSearch.isFetched]);
    if (!isOpen) {
        return null;
    }
    return (<>
      <Backdrop />
      <div className="modal fade show d-block" onClick={function (e) {
            e.stopPropagation();
            onClose();
        }}>
        <div className="modal-dialog modal-lg" onClick={function (e) { return e.stopPropagation(); }}>
          <div className="modal-content">
            <TaskFormCalendarItemContainer hidesFromPageToggle requiredSelectedTask commentRef={commentRef} createTask={createTask} isMine={isMine} isSearching={isSearching} mode="update" readOnly={readOnly} selectedDay={selectedDay} selectedTask={task} setIsSearching={setIsSearching} setSelectedTask={setTask} timeEntry={__assign(__assign({}, timeEntry), { task: __assign(__assign({}, timeEntry.task), { category: category, team: (_b = category.team) !== null && _b !== void 0 ? _b : timeEntry.task.team }) })} toggleTaskForm={toggleTaskForm} user_id={user_id} onSelectCategory={function (category) { return setCategory(category); }}/>
          </div>
        </div>
      </div>
    </>);
};
