import React from 'react';
import I18n from '@/constants/i18n';
import Blank from '@/components/molecules/blank';
import AssetList from './asset_list';
var ASSET_MONITOR_INTERVAL = 1000 * 30;
export default function AssetTable(_a) {
    var assets = _a.assets, next = _a.next, onExpand = _a.onExpand, fetchAsset = _a.fetchAsset;
    React.useEffect(function () {
        var monitorAssets = function () {
            assets.forEach(function (asset) {
                if (['initial', 'in_progress'].includes(asset.status)) {
                    fetchAsset(asset.id);
                }
            });
        };
        var intervalId = setInterval(monitorAssets, ASSET_MONITOR_INTERVAL);
        return function () { return clearInterval(intervalId); };
    }, [assets, fetchAsset]);
    if (assets.length === 0) {
        return (<Blank description={I18n.t('export.blankDescription')} title={I18n.t('export.blankTitle')}/>);
    }
    return (<table className="table mt-2">
      <thead>
        <tr>
          <th className="table-status"/>
          <th>{I18n.t('export.target')}</th>
          <th>{I18n.t('export.range')}</th>
          <th>{I18n.t('export.fileType')}</th>
          <th>{I18n.t('export.dataType')}</th>
          <th>{I18n.t('export.timeFormat')}</th>
          <th>{I18n.t('export.createdAt')}</th>
          <th />
          <th />
        </tr>
      </thead>
      <AssetList assets={assets}/>
      {next && (<tfoot>
          <tr>
            <td colSpan={9}>
              <button className="btn btn-secondary btn-block" onClick={function () { return onExpand(next); }}>
                {I18n.t('shared.readMore')}
              </button>
            </td>
          </tr>
        </tfoot>)}
    </table>);
}
