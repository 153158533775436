import React from 'react';
import classNames from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
var MobilePage = function (props) {
    var className = props.className, _a = props.tag, Tag = _a === void 0 ? 'div' : _a, children = props.children, _b = props.style, style = _b === void 0 ? {} : _b;
    var classes = classNames('mobile-page animated', className);
    return (<ReactCSSTransitionGroup transitionAppear={true} transitionAppearTimeout={300} transitionEnter={false} transitionLeave={true} transitionLeaveTimeout={1000} transitionName={{
            leave: 'fadeOut',
            leaveActive: 'fadeOut',
            appear: 'fadeIn',
            appearActive: 'fadeIn',
        }}>
      <Tag className={classes} style={style}>
        {children}
      </Tag>
    </ReactCSSTransitionGroup>);
};
export default MobilePage;
