import React from 'react';
import I18n from '@/constants/i18n';
import { formatDateWithLocale, beforeDay } from '@/utils/time_helper';
export default function TeamEmployListItem(_a) {
    var employ = _a.employ, index = _a.index, nextEmploy = _a.nextEmploy, isCurrent = _a.isCurrent, disabled = _a.disabled;
    var fromWorkspace = !!employ.workspaces_workspace_id;
    return (<div className={"".concat(nextEmploy ? 'mb-2' : '', " ").concat(disabled ? 'opacity-30' : '')}>
      <div>
        <span>{I18n.t('shared.currencyUnit')}</span>
        <span>{employ.rate}</span>
        <span>/{I18n.t('shared.hours')}</span>
        <span className="text-muted ml-1">
          {fromWorkspace &&
            index < 1 &&
            (nextEmploy
                ? I18n.t('shared.toDate', {
                    date: formatDateWithLocale(beforeDay(nextEmploy.started_at, 1)),
                })
                : I18n.t('shared.allPeriods'))}
          {(!fromWorkspace || index > 0) &&
            I18n.t('shared.fromDate', {
                date: formatDateWithLocale(employ.started_at),
            })}
        </span>
        {isCurrent && (<span className="badge badge-success opacity-50 ml-1 rounded-0">
            {I18n.t('shared.currentRate')}
          </span>)}
      </div>
      {fromWorkspace && (<span className="badge badge-info opacity-50 align-top">
          {I18n.t('shared.fromWorkspace')}
        </span>)}
      {!fromWorkspace && (<span className="badge badge-primary opacity-50 align-top">
          {I18n.t('shared.inTeam')}
        </span>)}
    </div>);
}
