import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '@/constants/i18n';
import { reminderTypes } from '@/types';
export var ReminderTypeField = function (_a) {
    var index = _a.index, reminderEmailsSetting = _a.reminderEmailsSetting, onChangeReminderEmailsSetting = _a.onChangeReminderEmailsSetting;
    return (<div className="row">
      <div className="col-3">{I18n.t('shared.reminderType')}</div>
      <div className="col d-flex justify-content-between">
        <UncontrolledDropdown>
          <DropdownToggle caret>
            {I18n.t("shared.reminderTypes.".concat(reminderEmailsSetting.reminder_type))}
          </DropdownToggle>
          <DropdownMenu>
            {reminderTypes.map(function (type) {
            return (<DropdownItem key={type} value={type} onClick={function () {
                    var condition_hours = {
                        general: 0,
                        forgot_record: 8,
                        forgot_stop: 4,
                    }[type];
                    onChangeReminderEmailsSetting(index, {
                        reminder_type: type,
                        condition_hours: condition_hours,
                        condition_minutes: 0,
                    });
                }}>
                  {I18n.t("shared.reminderTypes.".concat(type))}
                </DropdownItem>);
        })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>);
};
