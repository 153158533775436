import variants from '@/constants/variants';
var STORAGE_KEY = 'lastOpenedWorkspaceId';
export var saveLastOpenedWorkspaceId = function (workspaceId, saveStorage) {
    if (saveStorage === void 0) { saveStorage = variants.saveStorage; }
    saveStorage(STORAGE_KEY, workspaceId);
};
export var loadLastOpenedWorkspaceId = function (loadStorage) {
    if (loadStorage === void 0) { loadStorage = variants.loadStorage; }
    return new Promise(function (resolve) {
        loadStorage(STORAGE_KEY, function (lastOpenedWorkspaceId) {
            resolve(lastOpenedWorkspaceId);
        });
    });
};
