import React from 'react';
import I18n from '../../constants/i18n';
import Icon from '@/components/atoms/icon';
var icons = {
    teams: 'user-group',
    users: 'user',
    date: 'calendar',
};
export default function FormSectionHeader(_a) {
    var scope = _a.scope, children = _a.children;
    return (<div className="form-section-header">
      <Icon className="mr-2" name={icons[scope]}/>
      <span>{I18n.t("shared.".concat(scope))}</span>
      {children}
    </div>);
}
