import { __assign } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@/hooks/use_query';
import DateHeader from '@/components/molecules/date_header';
import MainMenuContainer from '@/containers/main_menu_container';
import Avatar from '@/components/atoms/avatar';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import NotFound from '@/components/pages/not_found';
import { CalendarDateSelectNav } from '@/components/calendar_date_select_nav';
import { Schedule } from '@/components/schedule';
import { fetchUserRecentCategories } from '@/modules/recent_categories';
import variants from '@/constants/variants';
import { workspacePath, workspaceTeams } from '@/utils/workspace_helper';
import { CalendarOptionsDropdownContainer } from '@/containers/calendar_options_dropdown_container';
import { CalendarZoomRateControlContainer } from '@/containers/calendar_zoom_rate_control_container';
import { setCurrentCategory } from '@/modules/my_task';
var UserCalendar = function (props) {
    var _a;
    var fetchUserCategories = props.fetchUserCategories, setCalendarView = props.setCalendarView, _b = props.loadStorage, loadStorage = _b === void 0 ? variants.loadStorage : _b, fetchCalendar = props.fetchCalendar, resetCalendar = props.resetCalendar, _c = props.saveStorage, saveStorage = _c === void 0 ? variants.saveStorage : _c, setActiveTaskForm = props.setActiveTaskForm;
    var activeTaskForm = useSelector(function (state) { return state.myTask.activeTaskForm; });
    var calendar = useSelector(function (state) { return state.calendar; });
    var currentCategory = useSelector(function (state) { return state.myTask.currentCategory; });
    var isApp = useSelector(function (state) { return state.isApp; });
    var isChromeExtension = useSelector(function (state) { return state.isChromeExtension; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var isMobile = useSelector(function (state) { return state.isMobile; });
    var teams = useSelector(function (state) { return state.teams; });
    var user = useSelector(function (state) { return state.user; });
    var categories = useSelector(function (state) { return state.categories; });
    var taskSearch = useSelector(function (state) { return state.taskSearch; });
    var myTask = useSelector(function (state) { return state.myTask; });
    var query = useQuery();
    var queryDate = query.date;
    var defaultDate = queryDate ? new Date(queryDate) : new Date();
    var _d = React.useState({
        selectedDay: defaultDate,
        divisionHeightRate: user.calendar_zoom_rate,
        initialScrollTo: 9,
    }), state = _d[0], setState = _d[1];
    var user_id = useParams().user_id;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    React.useEffect(function () {
        dispatch(fetchUserRecentCategories(user_id));
    }, [dispatch, user_id]);
    React.useEffect(function () {
        fetchUserCategories(user_id);
    }, [fetchUserCategories, user_id]);
    React.useEffect(function () {
        loadStorage('calendarView', function (view) {
            if (view) {
                setCalendarView(view);
            }
            fetchCalendar({ date: queryDate, user_id: user_id });
        });
        return function () {
            resetCalendar();
        };
    }, [
        user_id,
        fetchCalendar,
        loadStorage,
        queryDate,
        resetCalendar,
        setCalendarView,
    ]);
    React.useEffect(function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { divisionHeightRate: user.calendar_zoom_rate })); });
    }, [user.calendar_zoom_rate]);
    React.useEffect(function () {
        if (myTask.activeTaskForm === -1) {
            dispatch(setCurrentCategory(undefined));
        }
    }, [dispatch, myTask.activeTaskForm]);
    var onSelectCalendar = function (selected) {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedDay: selected })); });
        var date = moment(selected).format('YYYY-MM-DD');
        navigate(workspacePath("/users/".concat(user_id, "/calendar?date=").concat(date)));
    };
    var onSelectView = function (view) {
        setCalendarView(view);
        fetchCalendar({ user_id: user_id, date: queryDate });
        saveStorage('calendarView', view);
    };
    var toggleTaskForm = function (index) {
        if (!isPC) {
            var initialScrollTo_1 = index === -1 ? state.initialScrollTo : Number(index.split('-')[0]);
            setState(function (prevState) { return (__assign(__assign({}, prevState), { initialScrollTo: initialScrollTo_1 })); });
        }
        setActiveTaskForm(index);
    };
    var selectedDay = state.selectedDay, divisionHeightRate = state.divisionHeightRate;
    if (!calendar.loading && !calendar.items.length) {
        return <NotFound />;
    }
    var calendarUser = (_a = calendar.items[0]) === null || _a === void 0 ? void 0 : _a.user;
    var isOpenTimelineTaskForm = activeTaskForm !== -1;
    var readOnly = !categories.length;
    return (<div className={isOpenTimelineTaskForm
            ? 'open-timeline-task-form'
            : 'close-timeline-task-form'}>
      <div className="content-wrap">
        <MainMenuContainer />
        <div className={['content-main'].join(' ')}>
          <div className="toolbar hidden-sm-down">
            <div className="row no-gutter">
              <div className="col-xl-8 col-sm-10 col-xs-8">
                <div className="hidden-sm-down" style={{
            padding: '.9rem .6rem',
        }}>
                  <Avatar className="mr-2" image={calendarUser === null || calendarUser === void 0 ? void 0 : calendarUser.avatar_url} name={calendarUser === null || calendarUser === void 0 ? void 0 : calendarUser.nickname} size="md"/>
                  <span style={{
            fontSize: '17px',
            fontWeight: 500,
            verticalAlign: 'middle',
        }}>
                    {calendarUser === null || calendarUser === void 0 ? void 0 : calendarUser.nickname}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="with-toolbar with-toolbar-only-sm-up">
            {calendar.loading && !calendar.items.length ? (<div className="py-4 text-center">
                <LoadingSpinner size="md"/>
              </div>) : (<div className="date">
                <div className="date-header-container w-100">
                  <CalendarDateSelectNav currentDate={selectedDay} view={calendar.view} onSelectDate={onSelectCalendar} onSelectView={onSelectView}/>
                  <DateHeader calendar={calendar} selectedDate={selectedDay}>
                    <Avatar className="temp__member-avatar ml-auto hidden-md-up" image={calendarUser === null || calendarUser === void 0 ? void 0 : calendarUser.avatar_url} name={calendarUser === null || calendarUser === void 0 ? void 0 : calendarUser.nickname} size="md"/>
                  </DateHeader>
                  <CalendarOptionsDropdownContainer />
                </div>
                <Schedule activeTaskForm={activeTaskForm} calendarIntegrationEvents={[]} calendarUnitMinutes={calendar.unitMinutes} currentCategory={currentCategory} divisionHeightRate={divisionHeightRate} initialScrollTo={state.initialScrollTo} isApp={isApp} isChromeExtension={isChromeExtension} isMobile={isMobile} isShowCategoryOnWeeklyCalendar={calendar.isShowCategoryOnWeeklyCalendar} items={calendar.items} overlaps={calendar.overlaps} readOnly={readOnly} selectedDay={selectedDay} taskSearch={taskSearch} teams={workspaceTeams(teams.items)} toggleTaskForm={toggleTaskForm} user={user} view={calendar.view}/>
                <CalendarZoomRateControlContainer />
              </div>)}
          </div>
        </div>
      </div>
    </div>);
};
export default UserCalendar;
