import React from 'react';
import WorkspacesEmployListItem from './workspaces_employ_list_item';
import { findCurrentEmploy, isShowCurrentEmployLabel, } from '@/components/team_employ_list/helpers';
import I18n from '@/constants/i18n';
export default function WorkspacesEmployList(_a) {
    var employs = _a.employs;
    if (employs.length < 1) {
        return I18n.t('shared.noSetting');
    }
    var currentEmploy = findCurrentEmploy(employs);
    return employs.map(function (employ, i) { return (<WorkspacesEmployListItem key={employ.id} employ={employ} index={i} isCurrent={isShowCurrentEmployLabel(employ, currentEmploy, employs)} nextEmploy={employs[i + 1]}/>); });
}
