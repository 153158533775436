import React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import classNames from 'classnames';
import I18n from '@/constants/i18n';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import InfiniteCalendar from 'react-infinite-calendar';
import TimeInputContainer from '@/containers/time_input_container';
import { isValidTimeFormat, isValidTimeRange } from '@/utils/time_helper';
import { TimeEntrySuggestionButton } from '@/components/time_entry_suggestion_button';
import { TimeSelectionPopup } from '@/components/time_selection_popup';
import variants from '@/constants/variants';
var ComplexDateTimeInput = function (props) {
    var _a = props.datetime, datetime = _a === void 0 ? moment().startOf('minute') : _a, onChange = props.onChange, prevTimeEntry = props.prevTimeEntry, nextTimeEntry = props.nextTimeEntry, className = props.className, _b = props.tag, Tag = _b === void 0 ? 'div' : _b, min = props.min, max = props.max, _c = props.dateDisabled, dateDisabled = _c === void 0 ? false : _c, _d = props.timeDisabled, timeDisabled = _d === void 0 ? false : _d, _e = props.autoFocus, autoFocus = _e === void 0 ? false : _e, _f = props.isPC, isPC = _f === void 0 ? variants.isPC : _f, _g = props.isChromeExtension, isChromeExtension = _g === void 0 ? variants.isChromeExtension : _g;
    var _h = React.useState(false), openCalendar = _h[0], setOpenCalendar = _h[1];
    var _j = React.useState(undefined), message = _j[0], setMessage = _j[1];
    var _k = React.useState(true), isValid = _k[0], setIsValid = _k[1];
    var _l = React.useState(false), isChangedTime = _l[0], setIsChangedTime = _l[1];
    var _m = React.useState(false), isSelectedSuggestion = _m[0], setIsSelectedSuggestion = _m[1];
    var _o = React.useState(false), isShowTimeSelector = _o[0], setIsShowTimeSelector = _o[1];
    var handleToggleOpenCalendar = function () {
        setOpenCalendar(!openCalendar);
    };
    var handleSelectCalendarDate = function (date) {
        handleChangeDate(moment(date).format('YYYY-MM-DD'));
        setOpenCalendar(false);
    };
    var handleChangeDate = function (dateString) {
        var timeString = datetime.format('HH:mm');
        var next = moment(dateString + ' ' + timeString);
        onChange(next);
    };
    var handleChangeTime = function (value) {
        setIsChangedTime(true);
        // NOTE: 入力があった場合にはバリデーションをリセットする
        if (!isValid) {
            setIsValid(true);
            setMessage(undefined);
        }
        callOnChangeWithTime(value);
        setIsSelectedSuggestion(false);
    };
    var handleFocusTime = function () {
        setIsShowTimeSelector(true);
    };
    var handleBlurTime = function (value) {
        setIsShowTimeSelector(false);
        if (isValidTimeFormat(value)) {
            if (isValidTimeRange(value)) {
                setIsValid(true);
                setMessage(undefined);
            }
            else {
                setIsValid(false);
                // @ts-ignore
                setMessage(I18n.t('timeInput.error.inclusion'));
            }
        }
        else {
            setIsValid(false);
            // @ts-ignore
            setMessage(I18n.t('timeInput.error.format'));
        }
        if (isChangedTime) {
            callOnChangeWithTime(value);
        }
    };
    var handleClickSuggestion = function (e) {
        e.preventDefault();
        var time = moment((prevTimeEntry === null || prevTimeEntry === void 0 ? void 0 : prevTimeEntry.stopped_at) || (nextTimeEntry === null || nextTimeEntry === void 0 ? void 0 : nextTimeEntry.started_at));
        // @ts-ignore
        time._i = time.format('YYYY-MM-DD HH:mm');
        onChange(time);
        setIsSelectedSuggestion(true);
        setIsShowTimeSelector(false);
    };
    var handleSelectDateTime = function (datetime) {
        onChange(datetime);
        setIsShowTimeSelector(false);
    };
    var callOnChangeWithTime = function (value) {
        var dateString = datetime.format('YYYY-MM-DD');
        var next = moment(dateString + ' ' + value);
        onChange(next);
    };
    var classes = classNames('datetime-input position-relative', {
        'has-danger': !isValid && message !== void 0,
    }, className);
    var time = datetime.format('HH:mm');
    return (<Tag className={classes}>
      <div className="input-group">
        <div className="input-group-btn">
          <Dropdown className="date-dropdown" disabled={dateDisabled} isOpen={openCalendar} toggle={handleToggleOpenCalendar}>
            <DropdownToggle className={classNames('date-input form-control', {
            'form-control-danger': !isValid && message !== void 0,
        })} disabled={dateDisabled}>
              {datetime.format('YYYY-MM-DD')}
            </DropdownToggle>
            <DropdownMenu flip={false}>
              {openCalendar && (<InfiniteCalendar className="calendar-sm" displayOptions={{
                showHeader: true,
                showTodayHelper: false,
                showWeekdays: false,
                shouldHeaderAnimate: true,
            }} height={276} locale={I18n.t('infiniteCalendar')} maxDate={max} minDate={min} rowHeight={48} selected={datetime.toDate()} theme={{
                floatingNav: {
                    chevron: '#4abaa4',
                },
            }} width="100%" onSelect={handleSelectCalendarDate}/>)}
            </DropdownMenu>
          </Dropdown>
        </div>
        <TimeInputContainer autoFocus={autoFocus} className={classNames({
            'form-control-danger': !isValid && message !== null,
        })} disabled={timeDisabled} initTime={time} onBlur={handleBlurTime} onChange={handleChangeTime} onFocus={handleFocusTime}/>
      </div>
      {(isPC || isChromeExtension) && (<TimeSelectionPopup className="left-33" isShown={isShowTimeSelector} selectedDateTime={datetime} onSelectDateTime={handleSelectDateTime}/>)}
      {!isSelectedSuggestion && (<TimeEntrySuggestionButton datetime={datetime} nextTimeEntry={nextTimeEntry} prevTimeEntry={prevTimeEntry} onClick={handleClickSuggestion}/>)}
      {!isValid && message !== void 0 && (<div className="form-control-feedback">{message}</div>)}
    </Tag>);
};
export default ComplexDateTimeInput;
