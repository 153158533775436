import React from 'react';
import classNames from 'classnames';
var ExpansionPanelBody = function (props) {
    var className = props.className, children = props.children, _a = props.tag, Tag = _a === void 0 ? 'div' : _a, expand = props.expand;
    var classes = classNames('expansion-panel-body', className, {
        expand: expand,
        shrink: !expand,
    });
    return (<Tag aria-hidden={!expand} className={classes}>
      {children}
    </Tag>);
};
export default ExpansionPanelBody;
