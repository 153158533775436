import { __awaiter, __generator } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import { InvitationUrlField } from '@/components/invite_workspace_modal/invitation_url_field';
import { RenewWorkspaceInvitation } from '@/components/invite_workspace_modal/renew_workspace_invitation';
import Avatar from '@/components/atoms/avatar';
import variants from '@/constants/variants';
export var TeamInvitationTabContent = function (_a) {
    var workspaces = _a.workspaces, team = _a.team, workspacesInvitations = _a.workspacesInvitations, fetchWorkspacesInvitations = _a.fetchWorkspacesInvitations, renewWorkspacesInvitation = _a.renewWorkspacesInvitation, _b = _a.openURL, openURL = _b === void 0 ? variants.openURL : _b;
    var _c = React.useState(false), renewed = _c[0], setRenewed = _c[1];
    var workspace = workspaces.find(function (w) { return w.id === team.workspaces_workspace_id; });
    var canInvite = (workspace === null || workspace === void 0 ? void 0 : workspace.team_owners_invite_allowed) || (workspace === null || workspace === void 0 ? void 0 : workspace.role) === 'owner';
    var workspacesInvitation = workspacesInvitations.items[0];
    React.useEffect(function () {
        if (canInvite) {
            fetchWorkspacesInvitations(team.id);
        }
    }, [canInvite, team, fetchWorkspacesInvitations]);
    var onRenew = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, renewWorkspacesInvitation(workspacesInvitation.id, team.id)];
                case 1:
                    _a.sent();
                    setRenewed(true);
                    setTimeout(function () { return setRenewed(false); }, 2000);
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickLink = function (e) {
        e.preventDefault();
        openURL(e.target.href);
    };
    if (!canInvite) {
        return (<div className="text-center">
        <h3 className="font-weight-bold">
          {I18n.t('shared.invitationDisallowed')}
        </h3>
        <p className="mt-3">
          {I18n.t('shared.invitationDisallowedDescription')}
        </p>
        <p className="mt-3">
          <a className="btn btn-primary" href="https://help.timecrowd.net/workspace-team-owner-invite" onClick={onClickLink}>
            {I18n.t('shared.clickHereForDetails')}
          </a>
        </p>
      </div>);
    }
    if (!workspace || !workspacesInvitation) {
        return null;
    }
    return (<>
      <h4 className="mb-2">
        <Avatar className="mr-1" image={team.avatar_url}/>
        {I18n.t('shared.invitationUrlFor', { name: team.name })}
      </h4>

      <InvitationUrlField workspacesInvitation={workspacesInvitation}/>

      <RenewWorkspaceInvitation isExpired={workspacesInvitation.is_expired} renewed={renewed} onRenew={onRenew}/>
    </>);
};
