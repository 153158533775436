import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var DeleteChildTeamModal = function (props) {
    var isOpen = props.isOpen, team = props.modalProps.team, parentTeam = props.parentTeam, onSubmit = props.onSubmit, onClose = props.onClose;
    var _a = React.useState(false), disabled = _a[0], setDisabled = _a[1];
    var handleClickDelete = function () {
        setDisabled(true);
        onSubmit(team.id).then(function (team) {
            setDisabled(false);
            if (!team.error) {
                onClose();
            }
        });
    };
    if (!team || !parentTeam) {
        return null;
    }
    return (<Modal isOpen={isOpen} toggle={onClose} zIndex={1050}>
      <ModalHeaderMod toggle={onClose}>
        {I18n.t('shared.leaveParentTeam')}
      </ModalHeaderMod>

      <ModalBody>
        <p className="text-break">
          {I18n.t('teamChildren.attention', {
            childTeamName: team.name,
            parentTeamName: parentTeam.name,
        })}
        </p>
      </ModalBody>

      <ModalFooter>
        <button className="mr-auto btn btn-danger" disabled={disabled} onClick={handleClickDelete}>
          {I18n.t('shared.leaveParentTeam')}
        </button>
        <button className="btn btn-secondary" onClick={onClose}>
          {I18n.t('shared.cancel')}
        </button>
      </ModalFooter>
    </Modal>);
};
