import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspacesPlanForm } from '@/components/workspaces_plan_form';
import { fetchWorkspacePayment } from '@/actions/workspace_payment';
import { fetchWorkspacesCharges } from '@/modules/workspaces_charges';
import LoadingPage from '@/components/pages/loading_page';
import NotFound from '@/components/pages/not_found';
import { openModal } from '@/modules/modal';
var WorkspacesPlanContainer = function () {
    var workspaces = useSelector(function (state) { return state.workspaces; });
    var payment = useSelector(function (state) { return state.payment; });
    var user = useSelector(function (state) { return state.user; });
    var paymentIsLoaded = payment.isLoaded &&
        workspaces.current &&
        payment.item.workspace.id === workspaces.current.id;
    var currentWorkspace = workspaces.current;
    var dispatch = useDispatch();
    var handleOpenModal = function (header, body) {
        dispatch(openModal('CONFIRM', {
            header: header,
            body: body,
        }));
    };
    React.useEffect(function () {
        if (!currentWorkspace || paymentIsLoaded) {
            return;
        }
        dispatch(fetchWorkspacePayment());
        dispatch(fetchWorkspacesCharges());
    }, [dispatch, currentWorkspace, paymentIsLoaded]);
    var workspace = workspaces.current;
    if (workspaces.isLoaded) {
        if (!workspace || workspace.role !== 'owner') {
            return <NotFound />;
        }
        if (!payment.isLoaded) {
            return <LoadingPage />;
        }
    }
    else {
        return <LoadingPage />;
    }
    return (<WorkspacesPlanForm openModal={handleOpenModal} payment={payment} user={user} workspace={workspace}/>);
};
export default WorkspacesPlanContainer;
