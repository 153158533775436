import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import MainMenuContainer from '@/containers/main_menu_container';
import MyTask from '@/components/pages/my_task';
import CalendarContainer from '@/containers/calendar_container';
import SystemNotifications from '@/containers/system_notifications';
import { ignoreArchivedCategories } from '@/modules/categories';
import { arrangeRecentCategories, fetchRecentCategories, } from '@/modules/recent_categories';
import useCustomFields from '@/hooks/use_custom_fields';
var Home = function (props) {
    var currentCategory = props.myTask.currentCategory, categories = props.categories, loadMyTaskViewMode = props.loadMyTaskViewMode, setCurrentCategory = props.setCurrentCategory, fetchCategories = props.fetchCategories, fetchCategoriesRequest = props.fetchCategoriesRequest, fetchCategoriesSuccess = props.fetchCategoriesSuccess, saveMyTaskViewMode = props.saveMyTaskViewMode, user = props.user, fetchCurrentUser = props.fetchCurrentUser, fetchDailyActivity = props.fetchDailyActivity, resetDailyActivities = props.resetDailyActivities, dailyActivities = props.dailyActivities, currentEntry = props.currentEntry, stopTimeEntry = props.stopTimeEntry, setCurrentEntry = props.setCurrentEntry, fetchWorkingUsersCount = props.fetchWorkingUsersCount, updateDailyActivity = props.updateDailyActivity, isPC = props.isPC, isChromeExtension = props.isChromeExtension, myTask = props.myTask, startTask = props.startTask, updateTask = props.updateTask, restoreTask = props.restoreTask, isMobile = props.isMobile, isApp = props.isApp, expandDailyActivity = props.expandDailyActivity, fetchCalendarIntegrationSettings = props.fetchCalendarIntegrationSettings, fetchCalendar = props.fetchCalendar, setCalendarView = props.setCalendarView, calendar = props.calendar, calendarIntegrationEvents = props.calendarIntegrationEvents, calendarIntegrationSettings = props.calendarIntegrationSettings, calendarIntegrationFilters = props.calendarIntegrationFilters, taskSearch = props.taskSearch, onToggle = props.onToggle, updateCalendarZoomRate = props.updateCalendarZoomRate, teams = props.teams, setActiveTaskForm = props.setActiveTaskForm;
    var recentCategories = useSelector(function (state) { return state.recentCategories; });
    var _a = React.useState('myTask'), activeView = _a[0], setActiveView = _a[1];
    var dispatch = useDispatch();
    var setDefaultCategory = React.useCallback(function () {
        var category = ignoreArchivedCategories(categories)[0];
        setCurrentCategory(category);
    }, [categories, setCurrentCategory]);
    var handleArrangeRecentCategories = React.useCallback(function (category) {
        dispatch(arrangeRecentCategories(category));
    }, [dispatch]);
    React.useEffect(function () {
        dispatch(fetchRecentCategories());
    }, [dispatch]);
    React.useEffect(function () {
        if (teams.length < 1) {
            return;
        }
        if (recentCategories.status === 'loaded') {
            fetchCategoriesRequest();
            fetchCategories().then(function () {
                fetchCategoriesSuccess();
            });
        }
    }, [
        fetchCategories,
        fetchCategoriesRequest,
        fetchCategoriesSuccess,
        recentCategories,
        teams.length,
    ]);
    React.useEffect(function () {
        loadMyTaskViewMode(function (activeView) {
            if (activeView) {
                setActiveView(activeView);
            }
        });
    }, [loadMyTaskViewMode]);
    React.useEffect(function () {
        if (currentCategory || user.is_disable_default_category) {
            return;
        }
        if (recentCategories.items.length) {
            setCurrentCategory(recentCategories.items[0]);
        }
        else {
            setDefaultCategory();
        }
    }, [
        recentCategories,
        currentCategory,
        setDefaultCategory,
        setCurrentCategory,
        user.is_disable_default_category,
    ]);
    React.useEffect(function () {
        return function () {
            setCurrentCategory(undefined);
        };
    }, [setCurrentCategory]);
    React.useEffect(function () {
        if (myTask.activeTaskForm === -1 && user.is_disable_default_category) {
            setCurrentCategory(undefined);
        }
    }, [
        myTask.activeTaskForm,
        user.is_disable_default_category,
        setCurrentCategory,
    ]);
    var handleSwitchViewMode = function (activeView) {
        saveMyTaskViewMode(activeView);
        setActiveView(activeView);
    };
    useCustomFields(teams);
    return (<div>
      <SystemNotifications />
      <div className="content-wrap">
        <MainMenuContainer />
        {activeView === 'myTask' && (<MyTask arrangeRecentCategories={handleArrangeRecentCategories} currentEntry={currentEntry.current} dailyActivities={dailyActivities} expandDailyActivity={expandDailyActivity} fetchCurrentUser={fetchCurrentUser} fetchDailyActivity={fetchDailyActivity} fetchWorkingUsersCount={fetchWorkingUsersCount} isApp={isApp} isChromeExtension={isChromeExtension} isMobile={isMobile} isPC={isPC} myTask={myTask} resetDailyActivities={resetDailyActivities} restoreTask={restoreTask} setActiveTaskForm={setActiveTaskForm} setCurrentCategory={setCurrentCategory} setCurrentEntry={setCurrentEntry} startTask={startTask} stopTimeEntry={stopTimeEntry} switchViewMode={handleSwitchViewMode} updateDailyActivity={updateDailyActivity} updateTask={updateTask} user={user}/>)}
        {activeView === 'calendar' && (<CalendarContainer calendar={calendar} calendarIntegrationEvents={calendarIntegrationEvents} calendarIntegrationFilters={calendarIntegrationFilters} calendarIntegrationSettings={calendarIntegrationSettings} categories={categories} currentEntry={currentEntry.current} fetchCalendar={fetchCalendar} fetchCalendarIntegrationSettings={fetchCalendarIntegrationSettings} isApp={isApp} isChromeExtension={isChromeExtension} isMobile={isMobile} isPC={isPC} myTask={myTask} setActiveTaskForm={setActiveTaskForm} setCalendarView={setCalendarView} setCurrentEntry={setCurrentEntry} stopTimeEntry={stopTimeEntry} switchViewMode={handleSwitchViewMode} taskSearch={taskSearch} teams={teams} updateCalendarZoomRate={updateCalendarZoomRate} user={user} onToggle={onToggle}/>)}
      </div>
    </div>);
};
var mapStateToProps = function (state) { return ({
    isLoading: state.teams.isLoading,
    teams: state.teams.items,
}); };
export default connect(mapStateToProps)(Home);
