import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArchiveCategoryModal } from '@/components/archive_category_modal';
import { updateTeamCategory } from '@/actions/category';
import { removeDangerNotificationMessage } from '@/modules/notification';
var ArchiveCategoryModalContainer = function (_a) {
    var category = _a.category, isOpen = _a.isOpen, onToggle = _a.onToggle;
    var categories = useSelector(function (state) { return state.categories; });
    var dispatch = useDispatch();
    var handleToggle = function () {
        onToggle();
        dispatch(removeDangerNotificationMessage());
    };
    var onArchive = function (category) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(updateTeamCategory(category.id, {
                        archived: !category.archived,
                    }))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    return (<ArchiveCategoryModal categories={categories} category={category} isOpen={isOpen} onArchive={onArchive} onToggle={handleToggle}/>);
};
export default ArchiveCategoryModalContainer;
