import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, signOut } from '@/actions/user';
import { setIsOpenPersonalSettingsSubpane } from '@/modules/personal_settings';
import { Profile } from '@/components/profile';
var ProfileContainer = function () {
    var user = useSelector(function (state) { return state.user; });
    var nickname = useSelector(function (state) { return state.user.nickname; });
    var avatarUrl = useSelector(function (state) { return state.user.avatar_url; });
    var isApp = useSelector(function (state) { return state.isApp; });
    var isMobile = useSelector(function (state) { return state.isMobile; });
    var isOpenSubpane = useSelector(function (state) { return state.personalSettings.isOpenSubpane; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var dispatch = useDispatch();
    var handleUpdate = React.useCallback(function (params) {
        return dispatch(updateUser(params));
    }, [dispatch]);
    var handleSignOut = React.useCallback(function () {
        dispatch(signOut());
    }, [dispatch]);
    var handleIsOpenSubpane = React.useCallback(function (isOpen) {
        dispatch(setIsOpenPersonalSettingsSubpane(isOpen));
    }, [dispatch]);
    var isShowAvatar = !isApp && !isMobile;
    return (<Profile avatarUrl={avatarUrl} isOpenSubpane={isOpenSubpane} isPC={isPC} isShowAvatar={isShowAvatar} nickname={nickname} signOut={handleSignOut} user={user} onToggleSubpane={handleIsOpenSubpane} onUpdate={handleUpdate}/>);
};
export default ProfileContainer;
