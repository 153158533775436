import React from 'react';
import I18n from '@/constants/i18n';
function InvitationInfo(_a) {
    var capacity = _a.capacity, count = _a.count, canChange = _a.canChange, paymentPath = _a.paymentPath, onClick = _a.onClick;
    if (!capacity) {
        return null;
    }
    var rest = Number(capacity) - count;
    return (<div>
      <span className="text-info mr-1" style={{ fontSize: '14px' }}>
        {I18n.t('members.seatLeft', { count: rest })}
      </span>
      {canChange && (<>
          -
          <span className="ml-1">
            <a href={paymentPath} style={{
                fontSize: '14px',
            }} onClick={function (e) {
                e.preventDefault();
                onClick(e.target.pathname);
            }}>
              {I18n.t('members.addCapacity')}
            </a>
          </span>
        </>)}
    </div>);
}
export default InvitationInfo;
