import { updateStateIfChanged } from '@/modules/helpers';
export var SET_IS_OPEN_PERSONAL_SETTINGS_SUBPANE = 'timecrowd/personal_settings/SET_IS_OPEN_PERSONAL_SETTINGS_SUBPANE';
export var initialState = {
    isOpenSubpane: true,
};
export default function personalSettings(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_IS_OPEN_PERSONAL_SETTINGS_SUBPANE:
            return updateStateIfChanged(state, { isOpenSubpane: action.isOpen });
        default:
            return state;
    }
}
export var setIsOpenPersonalSettingsSubpane = function (isOpen) { return ({
    type: SET_IS_OPEN_PERSONAL_SETTINGS_SUBPANE,
    isOpen: isOpen,
}); };
