import React from 'react';
import classNames from 'classnames';
var CustomCheck = function (props) {
    var className = props.className, _a = props.descriptionClassName, descriptionClassName = _a === void 0 ? '' : _a, children = props.children, _b = props.tag, Tag = _b === void 0 ? 'label' : _b, _c = props.checked, checked = _c === void 0 ? false : _c, disabled = props.disabled, _d = props.indeterminate, indeterminate = _d === void 0 ? false : _d, onChange = props.onChange, _e = props.onClick, onClick = _e === void 0 ? function () { } : _e;
    var substanceInput = React.useRef(null);
    React.useEffect(function () {
        if (substanceInput.current) {
            substanceInput.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);
    var conditionals = {
        checked: checked,
        disabled: disabled,
        indeterminate: indeterminate,
    };
    var classes = classNames('custom-control custom-checkbox', className, conditionals);
    return (<Tag className={classes} onChange={onChange} onClick={onClick}>
      <input ref={substanceInput} className="custom-control-input" defaultChecked={checked} disabled={disabled} type="checkbox"/>
      <span className="custom-control-indicator"/>
      <span className={"custom-control-description ".concat(descriptionClassName)}>
        {children}
      </span>
    </Tag>);
};
export default CustomCheck;
