import { __assign, __awaiter, __generator } from "tslib";
import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { TaskItem } from '@/components/task_item';
import { useThrottle } from '@/hooks/use_throttle';
var TaskListItem = function (_a) {
    var task = _a.task, toggleTaskEdit = _a.toggleTaskEdit, startTask = _a.startTask, fetchWorkingUsersCount = _a.fetchWorkingUsersCount, updateDailyActivity = _a.updateDailyActivity, setCurrentCategory = _a.setCurrentCategory, arrangeRecentCategories = _a.arrangeRecentCategories, currentEntry = _a.currentEntry, stopTimeEntry = _a.stopTimeEntry, setCurrentEntry = _a.setCurrentEntry, date = _a.date, updateTask = _a.updateTask, restoreTask = _a.restoreTask, destroyTimeEntry = _a.destroyTimeEntry, isMobile = _a.isMobile, isApp = _a.isApp, timeEntries = _a.timeEntries, fetchDailyTimeEntries = _a.fetchDailyTimeEntries, user = _a.user;
    var handleToggleTaskEdit = function (e) {
        e.stopPropagation();
        toggleTaskEdit(task);
    };
    var handleStart = useThrottle(function (task) {
        var today = moment().format('YYYY-MM-DD');
        return startTask(task).then(function () {
            var category = __assign(__assign({}, task.category), { team: task.team });
            fetchWorkingUsersCount();
            updateDailyActivity(today);
            if (!user.is_disable_default_category) {
                setCurrentCategory(category);
            }
            arrangeRecentCategories(category);
        });
    });
    var handleStop = function () {
        var today = moment().format('YYYY-MM-DD');
        stopTimeEntry(currentEntry).then(function () {
            setCurrentEntry(null);
            fetchWorkingUsersCount();
            updateDailyActivity(today);
        });
    };
    var handleClose = function (task, params) {
        updateTask(task.team_id, task.id, params).then(function () {
            updateDailyActivity(date);
        });
    };
    var handleReopen = function (task, params) {
        updateTask(task.team_id, task.id, params).then(function () {
            updateDailyActivity(date);
        });
    };
    var handleRestore = function (task) {
        restoreTask(task.team_id, task.id);
    };
    var handleDestroyTimeEntry = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, destroyTimeEntry(id)];
                case 1:
                    _a.sent();
                    updateDailyActivity(date);
                    return [2 /*return*/];
            }
        });
    }); };
    var isToday = moment(date).diff(moment(), 'days') === 0;
    var isLogging = currentEntry && currentEntry.task.id === task.id && isToday ? true : false;
    var isPC = !isMobile && !isApp;
    return (<div>
      <TaskItem withDetails close={handleClose} currentEntry={currentEntry} date={date} destroyTimeEntry={handleDestroyTimeEntry} fetchDailyTimeEntries={fetchDailyTimeEntries} isLogging={isLogging} isPC={isPC} reopen={handleReopen} restore={handleRestore} start={handleStart} stop={handleStop} task={task} timeEntries={timeEntries.filter(function (_a) {
            var time_tracker_id = _a.time_tracker_id;
            return time_tracker_id === user.id;
        })} toggle={handleToggleTaskEdit}/>
    </div>);
};
export default TaskListItem;
