import { __spreadArray } from "tslib";
import I18n from '@/constants/i18n';
import { formatTime } from '@/utils/time_helper';
export var buildUndo = function (params, id, startedAt, stoppedAt) {
    var undoParams = {
        time_entry: {
            id: id,
            started_at: startedAt,
            stopped_at: stoppedAt,
        },
    };
    var key = Object.keys(params.time_entry)[0];
    var diffs = [
        '<ul class="p-0 mb-0 ml-3 mt-1">',
        '<li>',
        formatTime(undoParams.time_entry[key]),
        ' -> ',
        formatTime(params.time_entry[key]),
        '</ul>',
    ];
    var message = __spreadArray([
        I18n.t('shared.targetUpdated', { target: I18n.t("shared.".concat(key)) })
    ], diffs, true).join('');
    return {
        undoParams: undoParams,
        message: message,
    };
};
