import React from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import I18n from '@/constants/i18n';
import { BREAKPOINT_MD } from '@/constants/styles';
import Icon from '@/components/atoms/icon';
import MobileHeader from '@/components/atoms/mobile_header';
import MobilePage from '@/components/molecules/mobile_page';
import Subpane from '@/components/molecules/subpane';
import MainMenuContainer from '@/containers/main_menu_container';
import FormNav from '@/components/atoms/form_nav';
import PersonalSettingsSubpane from '@/components/personal_settings_subpane';
import { InlineAlert } from '@/components/inline_alert';
export var PersonalSettingsLayout = function (_a) {
    var _b = _a.errorMessages, errorMessages = _b === void 0 ? [] : _b, user = _a.user, isOpenSubpane = _a.isOpenSubpane, isPC = _a.isPC, setIsOpenSubpane = _a.setIsOpenSubpane, children = _a.children, title = _a.title, active = _a.active, _c = _a.saveButtonForPC, saveButtonForPC = _c === void 0 ? null : _c, _d = _a.saveButtonForSP, saveButtonForSP = _d === void 0 ? null : _d, signOut = _a.signOut;
    var handleOpenSubpane = function () { return setIsOpenSubpane(true); };
    return (<div className="content-wrap">
      <MediaQuery className="d-flex w-100" minWidth={BREAKPOINT_MD}>
        <MainMenuContainer />
        <div className="content-main">
          <div className="row no-gutters">
            <div className="col-md-9 col-12 setting-container px-5">
              <FormNav>
                <div className="d-flex w-100">
                  <div className="form-title">{title}</div>
                  <div className="ml-auto">
                    {saveButtonForPC !== null && saveButtonForPC}
                  </div>
                </div>
              </FormNav>
              <InlineAlert className="mb-3" errorMessages={errorMessages}/>
              {children}
            </div>
            <Subpane className="col-md-3 col-12">
              <PersonalSettingsSubpane active={active} hasPassword={user.has_password} otpAllowed={user.otp_allowed && isPC} setIsOpenSubpane={setIsOpenSubpane} signOut={signOut}/>
            </Subpane>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={BREAKPOINT_MD}>
        <MobilePage>
          {isOpenSubpane && (<MobileHeader>
              <Link className="btn btn-link btn-mobile-header-action" to="/app/other">
                <Icon name="chevron-left"/>
              </Link>
              <span className="mobile-header-title">
                {I18n.t('shared.personalSettings')}
              </span>
              <div style={{ minWidth: '60px' }}/>
            </MobileHeader>)}
          {isOpenSubpane && (<PersonalSettingsSubpane active={active} hasPassword={user.has_password} otpAllowed={user.otp_allowed && isPC} setIsOpenSubpane={setIsOpenSubpane} signOut={signOut}/>)}
          {!isOpenSubpane && (<div className="px-2 py-3">
              <MobileHeader>
                <button className="btn-mobile-header-action btn btn-link" onClick={handleOpenSubpane}>
                  <Icon name="chevron-left"/>
                </button>
                <span className="mobile-header-title">{title}</span>
                <span>{saveButtonForSP !== null && saveButtonForSP}</span>
              </MobileHeader>
              <InlineAlert className="mb-3" errorMessages={errorMessages}/>
              {children}
            </div>)}
        </MobilePage>
      </MediaQuery>
    </div>);
};
