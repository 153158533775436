import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportRecipients } from '@/modules/report_recipients';
import { setIsOpenPersonalSettingsSubpane } from '@/modules/personal_settings';
import { updateUser, signOut } from '@/actions/user';
import { ReportMailForm } from '@/components/report_mail';
var ReportMailSettingContainer = function () {
    var daily = useSelector(function (state) { return state.user.daily; });
    var daily_time = useSelector(function (state) { return state.user.daily_time; });
    var weekly = useSelector(function (state) { return state.user.weekly; });
    var isOpenSubpane = useSelector(function (state) { return state.personalSettings.isOpenSubpane; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var user = useSelector(function (state) { return state.user; });
    var dispatch = useDispatch();
    var handleToggleSubpane = function (isOpen) {
        dispatch(setIsOpenPersonalSettingsSubpane(isOpen));
    };
    var handleUpdate = React.useCallback(function (params) {
        return dispatch(updateUser(params));
    }, [dispatch]);
    var handleSignOut = React.useCallback(function () {
        dispatch(signOut());
    }, [dispatch]);
    React.useEffect(function () {
        dispatch(fetchReportRecipients());
    }, [dispatch]);
    return (<ReportMailForm daily={daily} daily_time={daily_time} isOpenSubpane={isOpenSubpane} isPC={isPC} signOut={handleSignOut} user={user} weekly={weekly} onToggleSubpane={handleToggleSubpane} onUpdate={handleUpdate}/>);
};
export default ReportMailSettingContainer;
