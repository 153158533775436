import { connect } from 'react-redux';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import TimelineEntry from '@/components/molecules/timeline_entry';
import { updateTimeEntry } from '@/modules/time_entries';
import { fetchCalendar } from '@/modules/calendar';
import { setNotificationMessage } from '@/modules/notification';
import { createTask } from '@/modules/tasks';
import I18n from '@/constants/i18n';
var mapStateToProps = function (state) { return ({
    taskSearch: state.taskSearch,
}); };
var mapDispatchToProps = function (dispatch, ownProps) { return ({
    onUpdate: function (id, params) {
        var promise = dispatch(updateTimeEntry(id, params)).then(function (timeEntry) {
            if (timeEntry.error) {
                return;
            }
            dispatch(setNotificationMessage('success', I18n.t('calendar.timeEntryUpdated')));
            var date = moment(ownProps.selectedDay).format('YYYY-MM-DD');
            dispatch(fetchCalendar({ date: date, user_id: timeEntry.user.id }));
        });
        return promise;
    },
    createTask: function (teamId, params) {
        return dispatch(createTask(teamId, params));
    },
    setNotificationMessage: function (type, message) {
        dispatch(setNotificationMessage(type, message));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TimelineEntry);
