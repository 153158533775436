import { __assign } from "tslib";
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import uniq from 'lodash/uniq';
import { useDispatch } from 'react-redux';
import { removeDangerNotificationMessage } from '@/modules/notification';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import CategoryMenu from '@/components/team_management/category_menu';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
import { arrangeCustomFieldsValues } from '@/components/custom_fields_value_list/helpers';
import { CustomFieldsValueList } from '@/components/custom_fields_value_list';
import { CustomFieldsErrorBadge } from '@/components/cutom_fields_error_badge';
import { EditCategoryFormModal } from './edit_category_form_modal';
import { unsetCategory, unsetParentCategory, } from '@/components/categories/helpers';
export var CategoryListItem = function (_a) {
    var category = _a.category, canManage = _a.canManage, canUnarchive = _a.canUnarchive, customFieldsMasters = _a.customFieldsMasters, customFieldsValues = _a.customFieldsValues, categories = _a.categories, team = _a.team, categoryTaskTree = _a.categoryTaskTree, toggleRemoveModal = _a.toggleRemoveModal, handleArchiveCategory = _a.handleArchiveCategory, onMoveLowerCategoryPosition = _a.onMoveLowerCategoryPosition, onMoveHigherCategoryPosition = _a.onMoveHigherCategoryPosition, setNotificationMessage = _a.setNotificationMessage, fetchCustomFieldsValues = _a.fetchCustomFieldsValues, copyTeamCategory = _a.copyTeamCategory, createTeamCategory = _a.createTeamCategory, updateTeamCategory = _a.updateTeamCategory, fetchCategoryTaskTree = _a.fetchCategoryTaskTree;
    var _b = React.useState(false), isOpenDetails = _b[0], setIsOpenDetails = _b[1];
    var _c = React.useState(false), isOpenCategoryModal = _c[0], setIsOpenCategoryModal = _c[1];
    var _d = React.useState(unsetParentCategory(team)), parentCategory = _d[0], setParentCategory = _d[1];
    var _e = React.useState(unsetCategory()), selectedCategory = _e[0], setSelectedCategory = _e[1];
    var _f = React.useState(null), copySourceCategory = _f[0], setCopySourceCategory = _f[1];
    var _g = useSortable({
        id: category.id.toString(),
    }), attributes = _g.attributes, listeners = _g.listeners;
    var style = {
        marginLeft: "".concat(category.ancestry_depth * 1.2, "rem"),
    };
    var errorsByMasterId = arrangeCustomFieldsValues(customFieldsMasters, customFieldsValues, categories, category).errorsByMasterId;
    var toggleIsOpenDetails = React.useCallback(function () {
        setIsOpenDetails(!isOpenDetails);
    }, [isOpenDetails]);
    var handleTitle = function (e) {
        var category = __assign(__assign({}, selectedCategory), { title: e.target.value });
        setSelectedCategory(category);
    };
    var onSelectParentCategory = function (parentCategory) {
        var category = __assign(__assign({}, selectedCategory), { parent_id: parentCategory.id });
        setParentCategory(parentCategory);
        setSelectedCategory(category);
    };
    var selectColor = function (colorIndex) {
        var category = __assign(__assign({}, selectedCategory), { color: colorIndex });
        setSelectedCategory(category);
    };
    var dispatch = useDispatch();
    var handleToggleEditCategoryModal = function (isOpen) {
        setIsOpenCategoryModal(isOpen);
        dispatch(removeDangerNotificationMessage());
    };
    var toggleCategoryModal = function (selectedCategory) {
        if (selectedCategory === void 0) { selectedCategory = null; }
        if (selectedCategory) {
            // NOTE: 編集のケース以外
            var parentCategory_1 = categories.find(function (parentCategory) { return parentCategory.id === selectedCategory.parent_id; }) || unsetParentCategory(team);
            setParentCategory(parentCategory_1);
            setSelectedCategory(selectedCategory);
            handleToggleEditCategoryModal(!isOpenCategoryModal);
        }
        else {
            // NOTE: 新規登録やモーダルを閉じるケース
            handleToggleEditCategoryModal(!isOpenCategoryModal);
            setTimeout(function () {
                setParentCategory(unsetParentCategory(team));
                setSelectedCategory(unsetCategory());
                setCopySourceCategory(null);
            }, 300);
        }
    };
    var handleNewChildCategory = function (parentCategory) {
        setParentCategory(parentCategory);
        setSelectedCategory(__assign(__assign({}, unsetCategory()), { color: parentCategory.color }));
        handleToggleEditCategoryModal(!isOpenCategoryModal);
    };
    var handleCopyCategory = function (copySourceCategory) {
        var parentCategory = categories.find(function (category) { return category.id === copySourceCategory.parent_id; }) || unsetParentCategory(team);
        fetchCategoryTaskTree(copySourceCategory.id, false);
        setParentCategory(parentCategory);
        setSelectedCategory(__assign(__assign({}, unsetCategory()), { title: I18n.t('shared.copyOf', { title: copySourceCategory.title }), color: copySourceCategory.color }));
        handleToggleEditCategoryModal(!isOpenCategoryModal);
        setCopySourceCategory(copySourceCategory);
    };
    return (<>
      <div className="category-list-item__container category-list-item__handle d-block" style={style} {...listeners} // eslint-disable-line react/jsx-props-no-spreading
     {...attributes} // eslint-disable-line react/jsx-props-no-spreading
    >
        <div className="d-flex align-items-center justify-content-between">
          <div className="category-name-group__container">
            <CategoryCircleIcon color={category.color}/>
            <span className={"text-break ".concat(category.archived ? 'text-muted' : '')}>
              {category.title}
            </span>
            {customFieldsMasters.length > 0 && (<>
                {canManage &&
                Object.values(errorsByMasterId).flat().length > 0 && (<CustomFieldsErrorBadge className="ml-2" errors={uniq(Object.values(errorsByMasterId).flat())} tooltipId={"category-list-item-".concat(category.id, "-tooltip")} onClick={toggleIsOpenDetails}/>)}
                <button className="btn btn-link text-muted ml-1 px-1 rounded-circle" onClick={toggleIsOpenDetails}>
                  <Icon name={"chevron-".concat(isOpenDetails ? 'up' : 'down')} size="lg"/>
                </button>
              </>)}
          </div>
          {canManage && (<div>
              {category.archived && (<span className="badge badge-info mr-1">
                  <Icon className="mr-1" name="archive" size="sm"/>
                  {I18n.t('shared.archived')}
                </span>)}
              <div className="btn-group">
                {!category.archived && (<button className="btn btn-secondary btn-sm rounded-right-0" onClick={function () { return toggleCategoryModal(category); }}>
                    <Icon name="pencil" size="sm"/>
                  </button>)}
                <CategoryMenu canUnarchive={canUnarchive} category={category} handleArchiveCategory={handleArchiveCategory} handleCopyCategory={handleCopyCategory} handleNewChildCategory={handleNewChildCategory} toggleRemoveModal={toggleRemoveModal} onMoveHigherCategoryPosition={onMoveHigherCategoryPosition} onMoveLowerCategoryPosition={onMoveLowerCategoryPosition}/>
              </div>
            </div>)}
        </div>
        {isOpenDetails && (<CustomFieldsValueList showsDetails canManage={canManage} categories={categories} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues} labelWidth="40" target={category}/>)}
      </div>
      {isOpenCategoryModal && (<EditCategoryFormModal isOpen categories={categories} categoryTaskTree={categoryTaskTree} copySourceCategory={copySourceCategory} copyTeamCategory={copyTeamCategory} createTeamCategory={createTeamCategory} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues} fetchCustomFieldsValues={fetchCustomFieldsValues} handleTitle={handleTitle} parentCategory={parentCategory} selectColor={selectColor} selectedCategory={selectedCategory} setNotificationMessage={setNotificationMessage} team={team} toggleCategoryModal={toggleCategoryModal} updateTeamCategory={updateTeamCategory} onSelectParentCategory={onSelectParentCategory}/>)}
    </>);
};
