import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
import { workspacePath } from '@/utils/workspace_helper';
export var WorkspacesSettingsSubpane = function (props) {
    var active = props.active, setting = props.setting, onNavigate = props.onNavigate;
    var currentUser = useSelector(function (state) { return state.currentUser.item; });
    var navigate = useNavigate();
    var onClickNavLink = function (e) {
        e.preventDefault();
        navigate(e.target.pathname);
        onNavigate === null || onNavigate === void 0 ? void 0 : onNavigate();
    };
    return (<ul className="flex-column nav">
      <li className="nav-item">
        <a className={"".concat(active === 'members' ? 'active ' : '', "nav-link")} href={workspacePath('/members')} onClick={onClickNavLink}>
          <Icon className="mr-2" color="info" name="user"/>
          {I18n.t('shared.member')}
        </a>
      </li>

      <li className="nav-item">
        <a className={"".concat(active === 'teams' ? 'active ' : '', "nav-link")} href={workspacePath('/teams')} onClick={onClickNavLink}>
          <Icon className="mr-2" color="info" name="user-group"/>
          {I18n.t('shared.team')}
        </a>
      </li>

      <li className="nav-item">
        <a className={"".concat(active === 'edit' ? 'active ' : '', "nav-link")} href={workspacePath('/edit')} onClick={onClickNavLink}>
          <Icon className="mr-2" color="info" name="box"/>
          {I18n.t('shared.generalSettings')}
        </a>
      </li>

      <li className="nav-item">
        <a className={"".concat(active === 'plan' ? 'active ' : '', "nav-link")} href={workspacePath('/plan')} onClick={onClickNavLink}>
          <Icon className="mr-2" color="info" name="jewel"/>
          {I18n.t('shared.plan')}
        </a>
      </li>

      {currentUser.enabled_features.includes('workspace_calendar_integration') &&
            setting.can_update && (<li className="nav-item">
            <a className={"".concat(active === 'calendar_integration' ? 'active ' : '', "nav-link")} href={workspacePath('/calendar_integration')} onClick={onClickNavLink}>
              <Icon className="mr-2" color="info" name="network-3"/>
              {I18n.t('shared.calendarIntegrations')}
              <span className="badge badge-info ml-2">
                {I18n.t('shared.beta')}
              </span>
            </a>
          </li>)}
    </ul>);
};
