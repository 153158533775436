import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
export var FETCH_CALENDAR_INTEGRATION_LISTS_REQUEST = 'timecrowd/CALENDAR_INTEGRATION_LISTS/FETCH_CALENDAR_INTEGRATION_LISTS_REQUEST';
export var FETCH_CALENDAR_INTEGRATION_LISTS_SUCCESS = 'timecrowd/CALENDAR_INTEGRATION_LISTS/FETCH_CALENDAR_INTEGRATION_LISTS_SUCCESS';
export var FETCH_CALENDAR_INTEGRATION_LISTS_FAILURE = 'timecrowd/CALENDAR_INTEGRATION_LISTS/FETCH_CALENDAR_INTEGRATION_LISTS_FAILURE';
export var UPDATE_CALENDAR_INTEGRATION_LIST_REQUEST = 'timecrowd/CALENDAR_INTEGRATION_LISTS/UPDATE_CALENDAR_INTEGRATION_LIST_REQUEST';
export var UPDATE_CALENDAR_INTEGRATION_LIST_SUCCESS = 'timecrowd/CALENDAR_INTEGRATION_LISTS/UPDATE_CALENDAR_INTEGRATION_LIST_SUCCESS';
export var UPDATE_CALENDAR_INTEGRATION_LIST_FAILURE = 'timecrowd/CALENDAR_INTEGRATION_LISTS/UPDATE_CALENDAR_INTEGRATION_LIST_FAILURE';
export var initialState = {
    isLoading: false,
    isUnrecoverable: false,
    items: [],
};
export default function calendarIntegrationLists(state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CALENDAR_INTEGRATION_LISTS_REQUEST:
        case UPDATE_CALENDAR_INTEGRATION_LIST_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isUnrecoverable: false });
        case FETCH_CALENDAR_INTEGRATION_LISTS_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, isLoading: false });
        case FETCH_CALENDAR_INTEGRATION_LISTS_FAILURE:
        case UPDATE_CALENDAR_INTEGRATION_LIST_FAILURE:
            return __assign(__assign({}, state), { isLoading: false, isUnrecoverable: (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.isUnrecoverable) !== null && _b !== void 0 ? _b : false });
        case UPDATE_CALENDAR_INTEGRATION_LIST_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.map(function (item) {
                    return item.id === action.payload.id
                        ? action.payload
                        : __assign(__assign({}, item), { is_active: false });
                }), isLoading: false });
        default:
            return state;
    }
}
export var fetchCalendarIntegrationListsRequest = function () { return ({
    type: FETCH_CALENDAR_INTEGRATION_LISTS_REQUEST,
}); };
export var fetchCalendarIntegrationListsSuccess = function (items) { return ({
    type: FETCH_CALENDAR_INTEGRATION_LISTS_SUCCESS,
    payload: items,
}); };
export var fetchCalendarIntegrationListsFailure = function (isUnrecoverable) { return ({
    type: FETCH_CALENDAR_INTEGRATION_LISTS_FAILURE,
    payload: { isUnrecoverable: isUnrecoverable },
}); };
export var fetchCalendarIntegrationLists = function () {
    return function (dispatch, getState) {
        dispatch(fetchCalendarIntegrationListsRequest());
        return api2(dispatch, getState, '/api/v2/calendar_integration/lists', 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchCalendarIntegrationListsSuccess(items));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(fetchCalendarIntegrationListsFailure(response.status === 400));
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateCalendarIntegrationListRequest = function () { return ({
    type: UPDATE_CALENDAR_INTEGRATION_LIST_REQUEST,
}); };
export var updateCalendarIntegrationListSuccess = function (item) { return ({
    type: UPDATE_CALENDAR_INTEGRATION_LIST_SUCCESS,
    payload: item,
}); };
export var updateCalendarIntegrationListFailure = function () { return ({
    type: UPDATE_CALENDAR_INTEGRATION_LIST_FAILURE,
}); };
export var updateCalendarIntegrationList = function (id, params) {
    return function (dispatch, getState) {
        dispatch(updateCalendarIntegrationListRequest());
        return api2(dispatch, getState, "/api/v2/calendar_integration/lists/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(updateCalendarIntegrationListSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(updateCalendarIntegrationListFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
