import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import Backdrop from '@/components/atoms/backdrop';
import Avatar from '@/components/atoms/avatar';
import MediaQuery from 'react-responsive';
import { BREAKPOINT_MD } from '../../constants/styles';
import { PersonalBadge, UnpaidBadge } from '@/components/badges';
var TeamSelect = function (props) {
    var team = props.team, teams = props.teams, onClick = props.onClick, toggle = props.toggle, _a = props.className, className = _a === void 0 ? '' : _a;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var handleToggle = function () {
        if (typeof toggle === 'function') {
            toggle();
        }
        setIsOpen(!isOpen);
    };
    return (<>
      <Dropdown className={className} isOpen={isOpen} size="lg" toggle={handleToggle}>
        <DropdownToggle block caret className="floating-dropdown-toggle btn-flat" size="lg">
          <MediaQuery minWidth={BREAKPOINT_MD}>
            <Avatar className="mr-2" image={team.avatar_url} size="md"/>
          </MediaQuery>
          <MediaQuery maxWidth={BREAKPOINT_MD}>
            <Avatar className="mr-2" image={team.avatar_url} size="lg"/>
          </MediaQuery>
          <span className="text-overflow mr-2">{team.name}</span>
          <UnpaidBadge className="ml-auto mr-1" team={team} withLink={false}/>
          <PersonalBadge className="ml-auto mr-1" isPersonal={team.personal}/>
        </DropdownToggle>
        <DropdownMenu right className="floating-dropdown-menu floating-dropdown-menu-compability">
          {teams.map(function (team) {
            return (<DropdownItem key={"team-select-".concat(team.id)} className="d-flex align-items-center" onClick={function () { return onClick(team.id); }}>
                <Avatar className="mr-1" image={team.avatar_url} size="md"/>
                <span className="text-overflow mr-2">{team.name}</span>
                <UnpaidBadge className="ml-auto" team={team} withLink={false}/>
                <PersonalBadge className="ml-auto" isPersonal={team.personal}/>
              </DropdownItem>);
        })}
        </DropdownMenu>
      </Dropdown>
      <MediaQuery maxWidth={BREAKPOINT_MD}>
        {isOpen && <Backdrop onClick={handleToggle}/>}
      </MediaQuery>
    </>);
};
export default TeamSelect;
