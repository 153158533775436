import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import MainMenuContainer from '@/containers/main_menu_container';
import MediaQuery from 'react-responsive';
import { BREAKPOINT_MD } from '../../constants/styles';
import NotFound from '@/components/pages/not_found';
import { workspacePath, workspaceTeams } from '@/utils/workspace_helper';
var Teams = function (props) {
    var loadTeamId = props.loadTeamId, saveTeamId = props.saveTeamId, teams = props.teams;
    var navigate = useNavigate();
    React.useEffect(function () {
        if (!teams.isFetched) {
            return;
        }
        loadTeamId(function (id) {
            var team = teams.items.find(function (team) { return team.id === Number(id); }) || teams.items[0];
            if (!team) {
                return;
            }
            saveTeamId(team.id);
            navigate(workspacePath("/teams/".concat(team.id, "/members")));
        });
    }, [loadTeamId, navigate, saveTeamId, teams.isFetched, teams.items]);
    if (teams.isFetched && workspaceTeams(teams.items).length < 1) {
        return <NotFound />;
    }
    return (<div className="content-wrap">
      <MediaQuery minWidth={BREAKPOINT_MD}>
        <MainMenuContainer />
      </MediaQuery>
      <div className="content-main mt-0">
        <LoadingSpinner position="absolute-center" size="md"/>
      </div>
    </div>);
};
export default Teams;
