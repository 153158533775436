import { __assign } from "tslib";
import React from 'react';
import { useSelector } from 'react-redux';
import I18n from '@/constants/i18n';
import { CalendarIntegrationAutoStartSetting } from '@/components/calendar_integration_auto_start_setting';
import { WorkspacesCalendarIntegrationFilter } from '@/components/workspaces_calendar_integration_filter';
import { CalendarIntegrationIgnoreWordsSetting } from '@/components/calendar_integration_ignore_words_setting';
import { CalendarIntegrationIgnoreMinutesSetting } from '@/components/calendar_integration_ignore_minutes_setting';
import Helplink from '@/components/molecules/helplink';
var CalendarIntegrationSettingWorkspacePartsContainer = function (_a) {
    var workspaceId = _a.workspaceId;
    var workspacesCalendarIntegrationSettings = useSelector(function (state) { return state.workspacesCalendarIntegrationSettings; });
    var setting = workspacesCalendarIntegrationSettings.items.find(function (setting) {
        return setting.workspaces_workspace_id === workspaceId;
    });
    var filters = useSelector(function (state) { return state.workspacesCalendarIntegrationFilters; });
    var categories = useSelector(function (state) { return state.categories; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var handleClickLink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    if (!setting || filters.status !== 'loaded' || !categories.length) {
        return null;
    }
    var filtersWithCategory = filters.items.map(function (filter) {
        var category = categories.find(function (c) { return c.id === filter.category_id; });
        return __assign(__assign({}, filter), { category: category });
    });
    var maxPriority = filters.items.length;
    return (<div className="mt-3">
      <CalendarIntegrationAutoStartSetting helplink="https://support.timecrowd.net/workspace-calendar-auto-track-setting" isAutoStart={setting.start_mode !== 'none'} isDisabled={true} startMode={setting.start_mode} stopMode={setting.stop_mode} onClickLink={handleClickLink}/>

      <div className="mt-3">
        <label className="font-weight-bold">
          {I18n.t('calendarIntegrationWorkspaceSetting.label')}
          <Helplink className="ml-1" href="https://support.timecrowd.net/workspace-calendar-auto-categorization" onClick={handleClickLink}/>
        </label>
        <p className="text-muted small" dangerouslySetInnerHTML={{
            __html: I18n.t('calendarIntegrationWorkspaceSetting.descriptionHtml'),
        }}></p>
        <ul className="list-none p-0">
          {filtersWithCategory
            .sort(function (a, b) { return a.priority - b.priority; })
            .map(function (filter, i) { return (<li key={i} className="border-1 border-bottom-0 last:border-bottom-1 px-3 py-2">
                <WorkspacesCalendarIntegrationFilter filter={filter} isEnablePriority={false} maxPriority={maxPriority}/>
              </li>); })}
        </ul>
      </div>

      <CalendarIntegrationIgnoreWordsSetting ignoreWordsText={setting.ignore_words_text} isDisabled={true} onClickLink={handleClickLink}/>

      <CalendarIntegrationIgnoreMinutesSetting ignoreMinutes={setting.ignore_minutes} isDisabled={true} isIgnoreMinutes={setting.ignore_minutes > 0} onClickLink={handleClickLink}/>
    </div>);
};
export default CalendarIntegrationSettingWorkspacePartsContainer;
