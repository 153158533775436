import { __assign } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import { TeamManagementLayoutContainer } from '@/containers/team_management_layout_container';
import { IntegrationForm } from '@/components/integration_form';
export var IntegrationSetting = function (_a) {
    var filters = _a.filters, isFetched = _a.isFetched, isLoading = _a.isLoading, openURL = _a.openURL, team = _a.team, onSubmit = _a.onSubmit;
    var _b = React.useState(filters[0] || null), filter = _b[0], setFilter = _b[1];
    var _c = React.useState(false), saving = _c[0], setSaving = _c[1];
    var _d = React.useState(false), saved = _d[0], setSaved = _d[1];
    React.useEffect(function () {
        setFilter(filters[0]);
    }, [isFetched, filters]);
    var handleActive = function (e) {
        filter.active = e.target.checked;
        setFilter(__assign({}, filter));
    };
    var handleSubmit = function () {
        setSaving(true);
        onSubmit(filter).then(function () {
            setSaving(false);
            setSaved(true);
            setTimeout(function () {
                setSaved(false);
            }, 2000);
        });
    };
    var handleClickHelplink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    var saveLabel = saving ? 'saving' : saved ? 'saved' : 'save';
    var isDisabledSubmit = !isFetched || (isFetched && !filter) || saveLabel !== 'save';
    return (<TeamManagementLayoutContainer currentPage="integrations" saveButtonForPC={<button className="btn-major-action btn btn-primary" disabled={isDisabledSubmit} onClick={handleSubmit}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>} saveButtonForSP={<button className="btn-mobile-header-action btn-mobile-header-action-text btn-mobile-header-action-primary btn btn-link" disabled={isDisabledSubmit} onClick={handleSubmit}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>} team={team} title={I18n.t('teamManagement.integration')}>
      <IntegrationForm filter={filter} isFetched={isFetched} isLoading={isLoading} onChangeActive={handleActive} onClickHelplink={handleClickHelplink}/>
    </TeamManagementLayoutContainer>);
};
