import React, { useState } from 'react';
export var FilterableInput = function (_a) {
    var onChange = _a.onChange, isOpened = _a.isOpened, isPC = _a.isPC, placeholder = _a.placeholder;
    var _b = useState(''), inputValue = _b[0], setInputValue = _b[1];
    var inputRef = React.useRef(null);
    React.useEffect(function () {
        var _a;
        if (!isOpened || !isPC) {
            return;
        }
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [inputRef, isOpened, isPC]);
    var changeValue = function (value) {
        setInputValue(value);
        onChange(value);
    };
    var handleChange = function (e) {
        changeValue(e.target.value);
    };
    var handleClear = function () {
        changeValue('');
    };
    return (<div className="input-group p-2">
      <input ref={inputRef} className="form-control filterable-input" placeholder={placeholder} value={inputValue} onChange={handleChange}/>
      <span className="input-group-append">
        <button className="close" type="button" onClick={handleClear}>
          <span aria-hidden="true">&times;</span>
        </button>
      </span>
    </div>);
};
