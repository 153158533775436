import { __assign } from "tslib";
import React from 'react';
import { connect } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import WorkingUsers from '@/components/organisms/working_users';
import { startTask, stopTimeEntry, fetchWorkingUsersCount, } from '@/actions/task';
import { fetchLastTimeEntries } from '@/modules/last_time_entries';
import { workspaceTeams } from '@/utils/workspace_helper';
var WorkingUsersContainer = function (_a) {
    var currentEntry = _a.currentEntry, tasks = _a.tasks, timeEntries = _a.timeEntries, isLoading = _a.isLoading, onStart = _a.onStart, onStop = _a.onStop, openURL = _a.openURL, isPC = _a.isPC, teams = _a.teams, user = _a.user, searchText = _a.searchText, fetchLastTimeEntries = _a.fetchLastTimeEntries;
    React.useEffect(function () {
        fetchLastTimeEntries();
        var intervalId = setInterval(function () {
            fetchLastTimeEntries();
        }, 60 * 1000);
        return function () { return clearInterval(intervalId); };
    }, [fetchLastTimeEntries]);
    return (<WorkingUsers currentEntry={currentEntry.current} isLoading={isLoading} isPC={isPC} openURL={openURL} searchText={searchText} tasks={tasks} teams={teams} timeEntries={timeEntries} user={user} onStart={onStart} onStop={onStop}/>);
};
function normalizeTasks(timeEntries) {
    var tasks = timeEntries.map(function (timeEntry) { return (__assign(__assign({}, timeEntry.task), { category: timeEntry.category, team: timeEntry.team })); });
    return uniqBy(tasks, 'id');
}
var mapStateToProps = function (state) { return ({
    currentEntry: state.currentEntry,
    tasks: normalizeTasks(state.lastTimeEntries.items),
    timeEntries: state.lastTimeEntries.items,
    isLoading: state.lastTimeEntries.isLoading,
    openURL: state.openURL,
    isPC: state.isPC,
    teams: workspaceTeams(state.teams.items),
    user: state.user,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onStart: function (task) {
        return dispatch(startTask(task)).then(function () {
            dispatch(fetchWorkingUsersCount());
            dispatch(fetchLastTimeEntries());
        });
    },
    onStop: function (entry) {
        return dispatch(stopTimeEntry(entry)).then(function (_a) {
            var error = _a.error;
            if (error) {
                return { error: error };
            }
            dispatch(fetchWorkingUsersCount());
            dispatch(fetchLastTimeEntries());
            return { error: false };
        });
    },
    fetchLastTimeEntries: function () {
        dispatch(fetchLastTimeEntries());
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(WorkingUsersContainer);
