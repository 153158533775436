import React from 'react';
import TeamEmployListItem from './team_employ_list_item';
import { findCurrentEmploy, isDisabledWorkspaceEmploy, isShowCurrentEmployLabel, } from './helpers';
import I18n from '@/constants/i18n';
export default function TeamEmployList(_a) {
    var employs = _a.employs;
    if (employs.length < 1) {
        return I18n.t('shared.noSetting');
    }
    var currentEmploy = findCurrentEmploy(employs);
    var isDisabledFlags = employs.map(function (employ) {
        return isDisabledWorkspaceEmploy(employs, employ);
    });
    return (<div>
      {employs.map(function (employ, i) { return (<TeamEmployListItem key={"".concat(employ.id, "-").concat(employ.workspaces_workspace_id)} disabled={isDisabledFlags[i]} employ={employ} index={i} isCurrent={isShowCurrentEmployLabel(employ, currentEmploy, employs)} nextEmploy={employs[i + 1]}/>); })}

      {isDisabledFlags.some(function (isDisabled) { return isDisabled; }) && (<div className="text-muted mt-3">
          {I18n.t('shared.disabledEmployNotice')}
        </div>)}
    </div>);
}
