import { __awaiter, __generator } from "tslib";
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Avatar from '@/components/atoms/avatar';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var EditTaskConfirmModal = function (_a) {
    var currentTask = _a.currentTask, currentCategory = _a.currentCategory, newTask = _a.newTask, newCategory = _a.newCategory, isOpen = _a.isOpen, onClose = _a.onClose, onSave = _a.onSave;
    var _b = React.useState(false), isSaving = _b[0], setIsSaving = _b[1];
    var onClickSave = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            (function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setIsSaving(true);
                            return [4 /*yield*/, onSave()];
                        case 1:
                            _a.sent();
                            setIsSaving(false);
                            onClose();
                            return [2 /*return*/];
                    }
                });
            }); })();
            return [2 /*return*/];
        });
    }); }, [onSave, onClose]);
    return (<Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeaderMod toggle={onClose}>
        {I18n.t('shared.editTaskConfirmModalTitle')}
      </ModalHeaderMod>
      <ModalBody>
        <p>{I18n.t('shared.editTaskConfirmModalDescription1')}</p>
        <ul className="list-unstyled ml-2">
          <li>
            {currentTask.title}
            {currentTask.title !== newTask.title && (<>
                <span className="mx-2">→</span>
                {newTask.title}
              </>)}
          </li>
          <li>
            <i className={"category-circle category-".concat(currentCategory.color)}/>
            {currentCategory.title}
            {currentCategory.id !== newCategory.id && (<>
                <span className="mx-2">→</span>
                <i className={"category-circle category-".concat(newCategory.color)}/>
                {newCategory.title}
              </>)}
          </li>
        </ul>

        <p className="mt-3">
          {I18n.t('shared.editTaskConfirmModalDescription2')}
        </p>
        <ul className="list-unstyled ml-2">
          {Object.entries(currentTask.time_entry_counts).map(function (_a) {
            var userId = _a[0], count = _a[1];
            var user = currentTask.users.find(function (u) { return u.id === parseInt(userId); }) ||
                {};
            return (<li key={userId} className="mt-1">
                  <Avatar className="mr-2" image={user.avatar_url}/>
                  <span className="list-group-contents mr-1">
                    {user.nickname}
                  </span>
                  ({I18n.t('shared.nEntries', { num: count })})
                </li>);
        })}
        </ul>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <button className="btn btn-secondary" disabled={isSaving} onClick={onClose}>
          {I18n.t('shared.cancel')}
        </button>

        <button className="btn btn-primary" disabled={isSaving} onClick={onClickSave}>
          {I18n.t('shared.save')}
        </button>
      </ModalFooter>
    </Modal>);
};
