import React from 'react';
import { Tooltip } from 'reactstrap';
import { categoryWithAncestors } from '@/utils/category_helper';
import { CustomFieldsValueList } from '@/components/custom_fields_value_list';
import Icon from '@/components/atoms/icon';
export var CategoryInfoTooltip = function (_a) {
    var categories = _a.categories, category = _a.category, _b = _a.className, className = _b === void 0 ? '' : _b, customFieldsMasters = _a.customFieldsMasters, customFieldsValues = _a.customFieldsValues, team = _a.team, _c = _a.requiresCustomFields, requiresCustomFields = _c === void 0 ? false : _c;
    var _d = React.useState(false), isOpenTooltip = _d[0], setIsOpenTooltip = _d[1];
    var buttonRef = React.useRef(null);
    React.useEffect(function () {
        setIsOpenTooltip(false);
    }, [category.id]);
    React.useEffect(function () {
        var closeTooltip = function (e) {
            var _a;
            if ([e.target, (_a = e.target) === null || _a === void 0 ? void 0 : _a.parentElement].includes(buttonRef.current)) {
                return;
            }
            setIsOpenTooltip(false);
        };
        document.addEventListener('mousedown', closeTooltip);
        return function () { return document.removeEventListener('mousedown', closeTooltip); };
    }, []);
    if (requiresCustomFields && customFieldsMasters.length < 1) {
        return null;
    }
    return (<>
      <button ref={buttonRef} className={"text-muted btn btn-link p-0 d-inline-flex justify-content-center align-items-center flex-shrink-0 opacity-80 ".concat(className)} onClick={function (e) {
            e.stopPropagation();
            setIsOpenTooltip(!isOpenTooltip);
        }}>
        <Icon name="information" size="md"/>
      </button>
      <Tooltip fade={false} innerClassName="max-w-none mt-1 text-left" isOpen={isOpenTooltip} placement="bottom" target={buttonRef}>
        <div className="text-nowrap">
          {categoryWithAncestors(category)} - {category.team.name}
        </div>
        {customFieldsMasters.length > 0 && (<div className="mt-2">
            <CustomFieldsValueList canManage={team.can_manage} categories={categories} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues.items} labelWidth="auto" target={category}/>
          </div>)}
      </Tooltip>
    </>);
};
