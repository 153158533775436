import { __assign } from "tslib";
export var SELECT_ASSET = 'timecrowd/exports/SELECT_ASSET';
export var UNSELECT_ASSET = 'timecrowd/exports/UNSELECT_ASSET';
export var OPEN_DELETE_ASSET_MODAL = 'timecrowd/exports/OPEN_DELETE_ASSET_MODAL';
export var CLOSE_DELETE_ASSET_MODAL = 'timecrowd/exports/CLOSE_DELETE_ASSET_MODAL';
export var initialState = {
    selected: undefined,
    isOpenDeleteModal: false,
};
export default function assets(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SELECT_ASSET:
            return __assign(__assign({}, state), { selected: action.asset });
        case UNSELECT_ASSET:
            return __assign(__assign({}, state), { selected: undefined });
        case OPEN_DELETE_ASSET_MODAL:
            return __assign(__assign({}, state), { isOpenDeleteModal: true });
        case CLOSE_DELETE_ASSET_MODAL:
            return __assign(__assign({}, state), { isOpenDeleteModal: false });
        default:
            return state;
    }
}
export var selectAsset = function (asset) { return ({
    type: SELECT_ASSET,
    asset: asset,
}); };
export var unselectAsset = function () { return ({
    type: UNSELECT_ASSET,
}); };
export var openDeleteAssetModal = function () { return ({
    type: OPEN_DELETE_ASSET_MODAL,
}); };
export var closeDeleteAssetModal = function () { return ({
    type: CLOSE_DELETE_ASSET_MODAL,
}); };
