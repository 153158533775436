import React from 'react';
import { Bar } from 'react-chartjs-2';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { calcCategoryTotalsForInterval, buildBarChartDatasetForCategory, buildBarChartDatasetForTeam, buildBarChartTooltipLabel, buildIntervalsForPeriods, } from '@/components/report_chart/helpers';
import I18n from '@/constants/i18n';
import ExpansionPanel from '@/components/atoms/expansion_panel';
import ExpansionPanelBody from '@/components/atoms/expansion_panel_body';
import ExpansionPanelHeader from '@/components/molecules/expansion_panel_header';
import Icon from '@/components/atoms/icon';
import { TeamReportBarChartLegend } from './team_report_bar_chart_legend';
export var TeamReportBarChart = function (_a) {
    var categories = _a.categories, from = _a.from, to = _a.to, tasks = _a.tasks, teams = _a.teams, timeEntries = _a.timeEntries, isSelectedMyReport = _a.isSelectedMyReport, _b = _a.withoutNoDataPeriods, withoutNoDataPeriods = _b === void 0 ? false : _b;
    var _c = buildIntervalsForPeriods(from, to), defaultInterval = _c.defaultInterval, intervals = _c.intervals;
    var _d = React.useState('category'), unit = _d[0], setUnit = _d[1];
    var _e = React.useState(defaultInterval), interval = _e[0], setInterval = _e[1];
    var _f = React.useMemo(function () {
        return calcCategoryTotalsForInterval(from, to, tasks, timeEntries, interval, 1, withoutNoDataPeriods);
    }, [from, to, tasks, timeEntries, interval, withoutNoDataPeriods]), labels = _f.labels, categoryTotalsForInterval = _f.categoryTotalsForInterval, teamTotalsForInterval = _f.teamTotalsForInterval, allTotalDurations = _f.allTotalDurations;
    React.useEffect(function () {
        setInterval(defaultInterval);
    }, [defaultInterval]);
    var sortedCategories = React.useMemo(function () {
        var usedCategoryIds = Object.keys(categoryTotalsForInterval);
        var categoryById = categories.reduce(function (acc, category) {
            acc[category.id] = category;
            return acc;
        }, {});
        var categoryTotalDurations = usedCategoryIds.reduce(function (acc, categoryId) {
            acc[categoryId] = categoryTotalsForInterval[categoryId].duration.reduce(function (sum, duration) { return sum + duration; }, 0);
            return acc;
        }, {});
        return usedCategoryIds
            .sort(function (a, b) { return categoryTotalDurations[a] - categoryTotalDurations[b]; })
            .map(function (id) { return categoryById[id]; });
    }, [categories, categoryTotalsForInterval]);
    var sortedTeams = React.useMemo(function () {
        var usedTeamIds = Object.keys(teamTotalsForInterval);
        var teamById = teams.reduce(function (acc, team) {
            acc[team.id] = team;
            return acc;
        }, {});
        var teamTotalDurations = usedTeamIds.reduce(function (acc, teamId) {
            acc[teamId] = teamTotalsForInterval[teamId].duration.reduce(function (sum, duration) { return sum + duration; }, 0);
            return acc;
        }, {});
        return usedTeamIds
            .sort(function (a, b) { return teamTotalDurations[a] - teamTotalDurations[b]; })
            .map(function (id) { return teamById[id]; });
    }, [teams, teamTotalsForInterval]);
    var handleOnChangeInterval = function (interval) {
        setInterval(interval);
    };
    var barData = {
        labels: labels,
        datasets: unit === 'category'
            ? buildBarChartDatasetForCategory(sortedCategories, categoryTotalsForInterval)
            : buildBarChartDatasetForTeam(sortedTeams, teamTotalsForInterval),
    };
    var barOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                mode: 'nearest',
                intersect: true,
                callbacks: {
                    label: function (tooltipItem) {
                        return buildBarChartTooltipLabel(tooltipItem, allTotalDurations);
                    },
                },
            },
        },
        scales: {
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: I18n.t('report.hours'),
                },
                beginAtZero: true,
            },
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
        responsive: true,
        animation: false,
    };
    return (<ExpansionPanel expand>
      {function (expand, toggle) { return (<>
          <ExpansionPanelHeader className="p-[0.8rem]" expand={expand} toggle={toggle}>
            <label className="expansion-panel-label">
              <Icon className="mr-1" color="info" name="graph-bar"/>
              {I18n.t('report.series')}
            </label>
          </ExpansionPanelHeader>
          <ExpansionPanelBody className="px-2" expand={expand}>
            <div className="d-flex justify-content-end mb-1 mr-1">
              {!isSelectedMyReport && (<UncontrolledDropdown className="ml-2">
                  <DropdownToggle caret>
                    {I18n.t("shared.".concat(unit))}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {['category', 'team'].map(function (unit) { return (<DropdownItem key={unit} onClick={function () { return setUnit(unit); }}>
                        {I18n.t("shared.".concat(unit))}
                      </DropdownItem>); })}
                  </DropdownMenu>
                </UncontrolledDropdown>)}
              {intervals.length > 1 && (<UncontrolledDropdown className="ml-2">
                  <DropdownToggle caret>
                    {I18n.t("shared.".concat(interval))}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {intervals.map(function (interval) { return (<DropdownItem key={interval} onClick={function () { return handleOnChangeInterval(interval); }}>
                        {I18n.t("shared.".concat(interval))}
                      </DropdownItem>); })}
                  </DropdownMenu>
                </UncontrolledDropdown>)}
            </div>
            <Bar data={barData} options={barOptions}/>
            <TeamReportBarChartLegend categories={sortedCategories} teams={sortedTeams}/>
          </ExpansionPanelBody>
        </>); }}
    </ExpansionPanel>);
};
