import { __assign } from "tslib";
import moment from 'moment'; // eslint-disable-line no-restricted-imports
export function normalizeTimeEntry(timeEntry) {
    var mStartedAt = moment(timeEntry.started_at);
    var mStoppedAt = moment(timeEntry.stopped_at);
    var secStartedAt = mStartedAt.seconds();
    var secStoppedAt = mStoppedAt.seconds();
    var normalized = {
        started_at: moment(mStartedAt.format('YYYY-MM-DD HH:mm')).add(secStartedAt, 's'),
    };
    if (timeEntry.stopped_at) {
        normalized.stopped_at = moment(mStoppedAt.format('YYYY-MM-DD HH:mm')).add(secStoppedAt, 's');
    }
    return __assign(__assign({}, timeEntry), normalized);
}
export function newTimeEntry(category) {
    var dateTimeString = moment().format('YYYY-MM-DD HH:mm');
    var started_at = moment(dateTimeString);
    var stopped_at = moment(moment(dateTimeString)
        .add(category.team.default_duration, 'm')
        .clone()
        .format('YYYY-MM-DD HH:mm'));
    return {
        started_at: started_at,
        stopped_at: stopped_at,
    };
}
export var isCreateMode = function (mode) {
    return ['create', 'calendar_event'].includes(mode);
};
