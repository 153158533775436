import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '@/constants/i18n';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import Avatar from '@/components/atoms/avatar';
import { sortTeamsByWorkspace } from '@/utils/workspace_helper';
import { PersonalBadge } from '@/components/badges';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
import variants from '@/constants/variants';
export var ReportTeamSelectDropdown = function (_a) {
    var isOpen = _a.isOpen, hasError = _a.hasError, onToggleDropdown = _a.onToggleDropdown, onSelectTeam = _a.onSelectTeam, onNavigateToPayment = _a.onNavigateToPayment, selectedTeam = _a.selectedTeam, teams = _a.teams, isMyReportView = _a.isMyReportView, isSelectedMyReport = _a.isSelectedMyReport, onSelectMyReport = _a.onSelectMyReport, _b = _a.isPC, isPC = _b === void 0 ? variants.isPC : _b, workspaces = _a.workspaces;
    return (<ButtonDropdown className="d-flex" isOpen={isOpen} size="lg" toggle={onToggleDropdown}>
      <DropdownToggle caret className="">
        {isSelectedMyReport ? (I18n.t("shared.".concat(isPC ? 'allTeams' : 'myReport'))) : selectedTeam ? (<>
            <WorkspaceAvatarContainer className="mr-1" workspaceId={selectedTeam.workspaces_workspace_id}/>
            <Avatar className="mr-1" image={selectedTeam.avatar_url} size="sm"/>
            {selectedTeam.name}
          </>) : hasError ? ('') : (<LoadingSpinner />)}
      </DropdownToggle>
      <DropdownMenu>
        {isMyReportView && (<DropdownItem className="d-flex justify-content-between align-items-center" tag="div" onClick={onSelectMyReport}>
            {I18n.t("shared.".concat(isPC ? 'allTeams' : 'myReport'))}
          </DropdownItem>)}
        {sortTeamsByWorkspace(teams, workspaces).map(function (team) { return (<DropdownItem key={team.id} className="d-flex justify-content-between align-items-center" disabled={team.payment_required} tag="div" onClick={function () { return onSelectTeam(team); }}>
            <div className="d-flex align-items-center">
              <WorkspaceAvatarContainer className="mr-1" workspaceId={team.workspaces_workspace_id}/>
              <Avatar className="mr-1" image={team.avatar_url} size="sm"/>
              <span className="text-overflow mr-2">{team.name}</span>
            </div>
            {team.payment_required &&
                (team.can_manage ? (<a className="badge badge-danger ml-auto" href="#" onClick={function (e) {
                        e.preventDefault();
                        onNavigateToPayment(team.id);
                    }}>
                  {I18n.t('shared.unpaid')}
                </a>) : (<span className="badge badge-danger ml-auto">
                  {I18n.t('shared.unpaid')}
                </span>))}
            <PersonalBadge isPersonal={team.personal}/>
          </DropdownItem>); })}
        {isMyReportView && isPC && (<DropdownItem header className="text-muted small">
            {I18n.t('shared.myReportNotice')}
          </DropdownItem>)}
      </DropdownMenu>
    </ButtonDropdown>);
};
