import { __assign, __spreadArray } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import { CategoryDropdown } from './category_dropdown';
import Icon from '@/components/atoms/icon';
export var FiltersForm = function (_a) {
    var calendarIntegrationFilters = _a.calendarIntegrationFilters, categories = _a.categories, setCalendarIntegrationFilters = _a.setCalendarIntegrationFilters;
    var maxPriority = calendarIntegrationFilters.length;
    var canAdd = calendarIntegrationFilters.length < 60;
    var onChange = React.useCallback(function (index, attributes) {
        setCalendarIntegrationFilters(calendarIntegrationFilters.map(function (filter, i) {
            if (i === index) {
                return __assign(__assign({}, filter), attributes);
            }
            else {
                return filter;
            }
        }));
    }, [calendarIntegrationFilters, setCalendarIntegrationFilters]);
    var onAdd = React.useCallback(function () {
        setCalendarIntegrationFilters(__spreadArray(__spreadArray([], calendarIntegrationFilters, true), [
            { priority: maxPriority + 1, keywords_text: '' },
        ], false));
    }, [calendarIntegrationFilters, maxPriority, setCalendarIntegrationFilters]);
    var onUp = React.useCallback(function (index) {
        setCalendarIntegrationFilters(calendarIntegrationFilters.map(function (filter, i) {
            if (i === index) {
                return __assign(__assign({}, filter), { priority: filter.priority - 1 });
            }
            else if (i === index - 1) {
                return __assign(__assign({}, filter), { priority: filter.priority + 1 });
            }
            else {
                return filter;
            }
        }));
    }, [calendarIntegrationFilters, setCalendarIntegrationFilters]);
    var onDown = React.useCallback(function (index) {
        setCalendarIntegrationFilters(calendarIntegrationFilters.map(function (filter, i) {
            if (i === index) {
                return __assign(__assign({}, filter), { priority: filter.priority + 1 });
            }
            else if (i === index + 1) {
                return __assign(__assign({}, filter), { priority: filter.priority - 1 });
            }
            else {
                return filter;
            }
        }));
    }, [calendarIntegrationFilters, setCalendarIntegrationFilters]);
    var onDelete = React.useCallback(function (index) {
        var nextFilters = calendarIntegrationFilters.filter(function (_, i) { return i !== index; });
        setCalendarIntegrationFilters(nextFilters.map(function (filter, i) {
            if (i >= index) {
                return __assign(__assign({}, filter), { priority: filter.priority - 1 });
            }
            else {
                return filter;
            }
        }));
    }, [calendarIntegrationFilters, setCalendarIntegrationFilters]);
    return (<>
      {calendarIntegrationFilters
            .sort(function (a, b) { return a.priority - b.priority; })
            .map(function (filter, i) { return (<div key={i} className="border-1 border-bottom-0 px-3 py-2">
            <div className="row">
              <div className="col-4">{I18n.t('shared.priority')}</div>
              <div className="col-4">{filter.priority}</div>
              <div className="col-4 text-right">
                {filter.priority > 1 && (<button className="p-0 btn btn-link text-gray-dark btn-sm" onClick={function () { return onUp(i); }}>
                    <Icon name="arrow-thin-up" size="lg"/>
                  </button>)}
                {filter.priority < maxPriority && (<button className="p-0 btn btn-link text-gray-dark btn-sm" onClick={function () { return onDown(i); }}>
                    <Icon name="arrow-thin-down" size="lg"/>
                  </button>)}
                <button className="p-0 btn btn-link text-danger btn-sm" onClick={function () { return onDelete(i); }}>
                  <Icon name="trash" size="lg"/>
                </button>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-4">{I18n.t('shared.teamCategory')}</div>
              <div className="col">
                <CategoryDropdown categories={categories} selectedId={filter.category_id} onSelect={function (id) {
                return onChange(i, {
                    category_id: id,
                    is_category_archived: false,
                    is_category_soft_destroyed: false,
                });
            }}/>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-4">{I18n.t('shared.keywords')}</div>
              <div className="col">
                <textarea className="form-control w-75" rows={Math.max(filter.keywords_text.split('\n').length, 2)} value={filter.keywords_text} onChange={function (e) {
                return onChange(i, { keywords_text: e.target.value });
            }}></textarea>
                <p className="text-muted small mt-1">
                  {I18n.t('shared.multiwordsNote')}
                </p>
              </div>
            </div>
          </div>); })}
      <div className="border-1">
        {canAdd && (<button className="d-flex py-2 btn btn-link btn-block" onClick={onAdd}>
            <span className="bg-primary text-white p-1 rounded-circle d-flex justify-content-center align-items-center mr-1">
              <Icon name="plus"/>
            </span>
            <span>{I18n.t('shared.addFiltering')}</span>
          </button>)}
      </div>
    </>);
};
