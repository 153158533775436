import { __assign } from "tslib";
import { connect } from 'react-redux';
import { setIsOpenPersonalSettingsSubpane } from '@/modules/personal_settings';
import { TwoFactorAuthForm } from '@/components/two_factor_auth_form';
import { fetchTwoFactorAuth, disableTwoFactorAuth, prepareTwoFactorAuth, enableTwoFactorAuth, backupTwoFactorAuth, } from '@/modules/two_factor_auth';
import { signOut } from '@/actions/user';
import { workspaceTeams } from '@/utils/workspace_helper';
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { openURL: state.openURL, twoFactorAuth: state.twoFactorAuth, isOpenSubpane: state.personalSettings.isOpenSubpane, teams: workspaceTeams(state.teams.items), user: state.user, isPC: state.isPC, notification: state.notification })); };
var mapDispatchToProps = function (dispatch) { return ({
    setIsOpenSubpane: function (isOpen) {
        dispatch(setIsOpenPersonalSettingsSubpane(isOpen));
    },
    fetchTwoFactorAuth: function () {
        return dispatch(fetchTwoFactorAuth());
    },
    disableTwoFactorAuth: function () {
        return dispatch(disableTwoFactorAuth());
    },
    prepareTwoFactorAuth: function () {
        return dispatch(prepareTwoFactorAuth());
    },
    enableTwoFactorAuth: function (params) {
        return dispatch(enableTwoFactorAuth(params));
    },
    backupTwoFactorAuth: function () {
        return dispatch(backupTwoFactorAuth());
    },
    signOut: function () {
        dispatch(signOut());
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthForm);
