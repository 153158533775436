import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import I18n from '@/constants/i18n';
import Backdrop from '@/components/atoms/backdrop';
import Blank from '@/components/molecules/blank';
import { CalendarDateSelectNav } from '@/components/calendar_date_select_nav';
import { CalendarOptionsDropdownContainer } from '@/containers/calendar_options_dropdown_container';
import { CalendarZoomRateControlContainer } from '@/containers/calendar_zoom_rate_control_container';
import DateHeader from '@/components/molecules/date_header';
import Icon from '@/components/atoms/icon';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import ModeSwitch from '@/components/molecules/mode_switch';
import QuickTaskCurrentContainer from '@/containers/quick_task_current_container';
import { Schedule } from '@/components/schedule';
import TaskFormTimelineQuickTaskContainer from '@/containers/task_form_timeline_quick_task_container';
import { TimeZoneAlert } from '@/components/time_zone_alert';
export var Calendar = function (_a) {
    var activeTaskForm = _a.activeTaskForm, calendar = _a.calendar, calendarIntegrationEvents = _a.calendarIntegrationEvents, currentCategory = _a.currentCategory, currentEntry = _a.currentEntry, divisionHeightRate = _a.divisionHeightRate, handleClickOverlay = _a.handleClickOverlay, handleTouchMoveContainer = _a.handleTouchMoveContainer, initialScrollTo = _a.initialScrollTo, isApp = _a.isApp, isChromeExtension = _a.isChromeExtension, isClosedTimeZoneAlert = _a.isClosedTimeZoneAlert, isMobile = _a.isMobile, isNoTeam = _a.isNoTeam, isPC = _a.isPC, isOpenQuickTask = _a.isOpenQuickTask, isOpenTimelineTaskForm = _a.isOpenTimelineTaskForm, isStarted = _a.isStarted, switchViewMode = _a.switchViewMode, taskSearch = _a.taskSearch, toggleTaskForm = _a.toggleTaskForm, user = _a.user, osUTCOffset = _a.osUTCOffset, selectedDate = _a.selectedDate, onClickChangeTimeZone = _a.onClickChangeTimeZone, onCloseTimeZoneAlert = _a.onCloseTimeZoneAlert, onOpenQuickTask = _a.onOpenQuickTask, onSelectCalendar = _a.onSelectCalendar, onSelectView = _a.onSelectView, onStop = _a.onStop;
    return (<div className={[
            'content-main',
            isOpenTimelineTaskForm
                ? 'open-timeline-task-form'
                : 'close-timeline-task-form',
            isChromeExtension && isOpenQuickTask ? 'from-page-title' : '',
        ].join(' ')} onTouchMove={handleTouchMoveContainer}>
      <div className={['toolbar', isStarted ? 'started' : 'stopped'].join(' ')}>
        <div className="row no-gutters">
          <div className="col-xl-8 col-md-10 col-12">
            {!isOpenQuickTask && (<div className="quicktask" onClick={onOpenQuickTask}>
                <button className={"text-left btn-new-task btn btn-secondary btn-lg ".concat(currentEntry ? '' : 'btn-block')}>
                  <Icon className={currentEntry ? '' : 'mr-1'} color={currentEntry ? 'normal' : 'primary'} name="plus" size="lg"/>
                  {!isStarted && <span>{I18n.t('quicktask.newtask')}</span>}
                </button>
                <QuickTaskCurrentContainer onStop={onStop}/>
              </div>)}
            {isOpenQuickTask && (<ReactCSSTransitionGroup transitionEnter={isMobile || isApp} transitionEnterTimeout={210} transitionLeave={isMobile || isApp} transitionLeaveTimeout={210} transitionName={{
                enter: 'animated',
                enterActive: 'slideInUpFast',
                leave: 'animated',
                leaveActive: 'slideOutDownFast',
            }}>
                <TaskFormTimelineQuickTaskContainer className="composer task-edit" isShowRemoveTimeEntry={true} mode="create" selectedDay={selectedDate} toggleTaskForm={toggleTaskForm}/>
              </ReactCSSTransitionGroup>)}
            {isOpenQuickTask && <Backdrop onClick={handleClickOverlay}/>}
          </div>
          <ModeSwitch isChromeExtension={isChromeExtension} isPC={isPC} mode="calendar" switchViewMode={switchViewMode}/>
        </div>
      </div>
      <div className="with-toolbar with-bottom-bar-only-mobile-app">
        {isNoTeam ? (<Blank description={I18n.t('mytask.blankDescriptipn')} title={I18n.t('mytask.blankTitle')}/>) : calendar.loading && !calendar.items.length ? (<div className="py-4 text-center">
            <LoadingSpinner size="md"/>
          </div>) : (<>
            <TimeZoneAlert isOpen={user.utc_offset !== osUTCOffset && !isClosedTimeZoneAlert} osUTCOffset={osUTCOffset} utcOffset={user.utc_offset} onClickChangeTimeZone={onClickChangeTimeZone} onClose={onCloseTimeZoneAlert}/>
            <div className="date">
              <div className="date-header-container date-header-with-toolbar w-100">
                <CalendarDateSelectNav currentDate={selectedDate} view={calendar.view} onSelectDate={onSelectCalendar} onSelectView={onSelectView}/>
                <DateHeader calendar={calendar} selectedDate={selectedDate}/>
                <CalendarOptionsDropdownContainer isShowCalendarIntegrationMenu={!user.otp_allowed}/>
              </div>
              <Schedule activeTaskForm={activeTaskForm} calendarIntegrationEvents={calendarIntegrationEvents.items} calendarUnitMinutes={calendar.unitMinutes} currentCategory={currentCategory} divisionHeightRate={divisionHeightRate} initialScrollTo={initialScrollTo} isApp={isApp} isChromeExtension={isChromeExtension} isMobile={isMobile} isShowCategoryOnWeeklyCalendar={calendar.isShowCategoryOnWeeklyCalendar} items={calendar.items} overlaps={calendar.overlaps} selectedDay={selectedDate} showsCalendarEvents={calendarIntegrationEvents.isIntegrated} taskSearch={taskSearch} teams={[]} toggleTaskForm={toggleTaskForm} user={user} view={calendar.view}/>
              <CalendarZoomRateControlContainer />
            </div>
          </>)}
      </div>
    </div>);
};
