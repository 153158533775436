import { __awaiter, __generator } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import Duration from '@/components/atoms/duration';
import Icon from '@/components/atoms/icon';
import { TimeEntryInlineTimeField } from './time_entry_inline_time_field';
import { TimeEntryInlineCommendField } from './time_entry_inline_comment_field';
import DeleteTimeEntryModal from '@/components/organisms/delete_time_entry_modal';
import variants from '@/constants/variants';
import { buildUndo } from './helpers';
import { removeDangerNotificationMessage } from '@/modules/notification';
export function TimeEntryInlineEditForm(_a) {
    var _this = this;
    var id = _a.id, startedAt = _a.startedAt, stoppedAt = _a.stoppedAt, comment = _a.comment, user = _a.user, canUpdate = _a.canUpdate, toggle = _a.toggle, disabled = _a.disabled, updateTimeEntry = _a.updateTimeEntry, upsertTimeEntryComment = _a.upsertTimeEntryComment, timeEntrySuggestions = _a.timeEntrySuggestions, fetchTimeEntrySuggestion = _a.fetchTimeEntrySuggestion, destroyTimeEntry = _a.destroyTimeEntry, _b = _a.openURL, openURL = _b === void 0 ? variants.openURL : _b, _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.withoutAvatar, withoutAvatar = _d === void 0 ? false : _d, isMine = _a.isMine, _e = _a.isPC, isPC = _e === void 0 ? variants.isPC : _e, _f = _a.isChromeExtension, isChromeExtension = _f === void 0 ? variants.isChromeExtension : _f, setUndo = _a.setUndo, inputType = _a.inputType;
    var _g = React.useState(false), isOpenDeleteModal = _g[0], setIsOpenDeleteModal = _g[1];
    var _h = React.useState(false), isShowSuggestion = _h[0], setIsShowSuggestion = _h[1];
    var isShowSuggestionWithHover = (isPC || isChromeExtension) && isMine;
    var dispatch = useDispatch();
    var onToggle = React.useCallback(function () {
        if (disabled) {
            return;
        }
        toggle();
    }, [disabled, toggle]);
    var handleMouseEnterContainer = function () {
        if (isShowSuggestionWithHover) {
            setIsShowSuggestion(true);
        }
    };
    var handleMouseLeaveContainer = function () {
        if (isShowSuggestionWithHover) {
            setIsShowSuggestion(false);
        }
    };
    var handleUpdateTimeEntry = React.useCallback(function (id, params) { return __awaiter(_this, void 0, void 0, function () {
        var res, undo_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateTimeEntry(id, params)];
                case 1:
                    res = _a.sent();
                    if (!res.error) {
                        undo_1 = buildUndo(params, id, startedAt, stoppedAt);
                        setUndo('success', undo_1.message, function () {
                            return updateTimeEntry(id, undo_1.undoParams);
                        });
                    }
                    return [2 /*return*/, res];
            }
        });
    }); }, [updateTimeEntry, setUndo, startedAt, stoppedAt]);
    var handleCloseDeleteModal = function () {
        dispatch(removeDangerNotificationMessage());
        setIsOpenDeleteModal(false);
    };
    var durationSeconds = Math.floor(stoppedAt.diff(startedAt, 'seconds'));
    var isMoreThan24hour = durationSeconds >= 86400;
    var canUpdateTime = canUpdate && (stoppedAt.isValid() || isMine);
    return (<>
      {inputType === 'proxy' && (<div className="px-2 -mb-6 text-muted">
          <span>
            <small>{I18n.t('proxyInputUser.label')}</small>
          </span>
        </div>)}
      <div className={classNames('list-group-item list-group-item-action time-entry align-items-start overflow-visible', className, {
            'opacity-50': disabled,
            'tw-cursor-not-allowed': disabled,
        })} onMouseDown={onToggle} onMouseEnter={handleMouseEnterContainer} onMouseLeave={handleMouseLeaveContainer}>
        {!withoutAvatar && (<Avatar className="mr-1 mt-1" image={user.avatar_url} name={user.nickname} size="md"/>)}
        <TimeEntryInlineTimeField canUpdate={canUpdateTime} fetchTimeEntrySuggestion={fetchTimeEntrySuggestion} id={id} isChromeExtension={isChromeExtension} isMoreThan24hour={isMoreThan24hour} isPC={isPC} isShowSuggestion={isShowSuggestion} startedAt={startedAt} stoppedAt={stoppedAt} timeEntrySuggestions={timeEntrySuggestions} updateTimeEntry={handleUpdateTimeEntry}/>
        <div className="time-entry-duration text-muted small mt-2">
          {stoppedAt.isValid() && <Duration seconds={durationSeconds}/>}
        </div>
        {isPC && (<span className={"edit-icon text-muted ml-3 mt-1 ".concat(canUpdate ? '' : 'invisible')}>
            <Icon name="pencil" size="sm"/>
          </span>)}
        {(isPC || isChromeExtension) && (<>
            <div className="list-group-item-subgroup flex-grow-1 ml-5 overflow-hidden">
              <TimeEntryInlineCommendField canUpdate={canUpdate} comment={comment} id={id} upsertTimeEntryComment={upsertTimeEntryComment}/>
            </div>
            <button className={"btn btn-link text-danger rounded-circle ml-1 ".concat(canUpdate ? '' : 'invisible')} onMouseDown={function (e) {
                e.stopPropagation();
                setIsOpenDeleteModal(true);
            }}>
              <Icon name="trash" size="sm"/>
            </button>
          </>)}
      </div>
      <DeleteTimeEntryModal isOpen={isOpenDeleteModal} openURL={openURL} startedAt={startedAt} stoppedAt={stoppedAt} toggle={handleCloseDeleteModal} user={user} onDelete={function () { return destroyTimeEntry(id); }}/>
    </>);
}
