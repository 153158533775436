import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CrossTeamReportBookmarkCreateModal } from '@/components/cross_team_report_bookmark_create_modal';
import { createWorkspacesReportBookmark } from '@/modules/workspaces_report_bookmarks';
import { setNotificationMessage } from '@/modules/notification';
import I18n from '@/constants/i18n';
export var CrossTeamReportBookmarkCreateModalContainer = function (props) {
    var conditions = props.conditions;
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var dispatch = useDispatch();
    var workspacesReportBookmarks = useSelector(function (state) { return state.workspacesReportBookmarks; });
    var handleCreate = React.useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(createWorkspacesReportBookmark(params))];
                case 1:
                    res = _a.sent();
                    if (!res.error) {
                        setIsOpen(false);
                        dispatch(setNotificationMessage('success', I18n.t('shared.reportBookmarkCreated')));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch]);
    var handleClose = React.useCallback(function () {
        setIsOpen(false);
    }, []);
    var handleOpen = React.useCallback(function () {
        if (workspacesReportBookmarks.items.length > 49) {
            dispatch(setNotificationMessage('danger', I18n.t('shared.reportBookmarkReachedLimit')));
            return;
        }
        setIsOpen(true);
    }, [dispatch, workspacesReportBookmarks.items]);
    return (<CrossTeamReportBookmarkCreateModal conditions={conditions} isLoading={workspacesReportBookmarks.status === 'loading'} isOpen={isOpen} onClose={handleClose} onCreate={handleCreate} onOpen={handleOpen}/>);
};
