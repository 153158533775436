import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeUserEmailForm } from '@/components/change_user_email_form';
import { updateUser } from '@/actions/user';
import { resendEmailConfirmation, cancelEmailConfirmation, } from '@/modules/email_confirmations';
import { setNotificationMessage } from '@/modules/notification';
import { unthrottledFetchCurrentUser } from '@/modules/current_user';
export var ChangeUserEmailContainer = function () {
    var user = useSelector(function (state) { return state.user; });
    var dispatch = useDispatch();
    var handleCancelEmailConfirmation = function () {
        return dispatch(cancelEmailConfirmation());
    };
    var handleFetchCurrentUser = function () {
        return dispatch(unthrottledFetchCurrentUser());
    };
    var handleResendEmailConfirmation = function () {
        return dispatch(resendEmailConfirmation());
    };
    var handleSetNotificationMessage = function (type, message) {
        dispatch(setNotificationMessage(type, message));
    };
    var handleUpdateUser = function (params) {
        return dispatch(updateUser(params));
    };
    return (<ChangeUserEmailForm cancelEmailConfirmation={handleCancelEmailConfirmation} fetchCurrentUser={handleFetchCurrentUser} resendEmailConfirmation={handleResendEmailConfirmation} setNotificationMessage={handleSetNotificationMessage} updateUser={handleUpdateUser} user={user}/>);
};
export default ChangeUserEmailContainer;
