import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
export var WorkspaceSelect = function (_a) {
    var _b = _a.blankValue, blankValue = _b === void 0 ? I18n.t('shared.workspaceDefaultBlankValue') : _b, _c = _a.defaultWorkspace, defaultWorkspace = _c === void 0 ? null : _c, isOpen = _a.isOpen, _d = _a.right, right = _d === void 0 ? false : _d, workspaces = _a.workspaces, onSelect = _a.onSelect, onToggle = _a.onToggle;
    var _e = React.useState(defaultWorkspace), selected = _e[0], setSelected = _e[1];
    var handleSelectWorkspace = function (workspace) {
        setSelected(workspace);
        onSelect(workspace);
        onToggle();
    };
    return (<Dropdown isOpen={isOpen} size="lg" toggle={onToggle}>
      <DropdownToggle block caret>
        {selected ? (<>
            <Avatar className="mr-1" image={selected.avatar_url} size="sm"/>
            <span>{selected.name}</span>
          </>) : (blankValue)}
      </DropdownToggle>
      <DropdownMenu right={right}>
        {workspaces.map(function (workspace) { return (<DropdownItem key={"workspace-select-".concat(workspace.id)} className="d-flex align-items-center" onClick={function () { return handleSelectWorkspace(workspace); }}>
            <Avatar className="mr-1" image={workspace.avatar_url} size="sm"/>
            <span>{workspace.name}</span>
          </DropdownItem>); })}
      </DropdownMenu>
    </Dropdown>);
};
