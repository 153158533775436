import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n from '@/constants/i18n';
import { createReportRecipient, destroyReportRecipient, } from '@/modules/report_recipients';
import { setNotificationMessage } from '@/modules/notification';
import Helplink from '@/components/molecules/helplink';
export var ReportRecipientForm = function () {
    var openURL = useSelector(function (state) { return state.openURL; });
    var reportRecipients = useSelector(function (state) { return state.reportRecipients; });
    var _a = React.useState(''), email = _a[0], setEmail = _a[1];
    var onChangeEmail = function (e) {
        setEmail(e.target.value);
    };
    var onKeyPressEmail = function (e) {
        if (e.key === 'Enter') {
            onClickAdd();
        }
    };
    var dispatch = useDispatch();
    var onClickAdd = function () {
        var params = {
            report_recipient: {
                email: email,
            },
        };
        dispatch(setNotificationMessage('success', I18n.t('shared.confirmationEmailSent')));
        dispatch(createReportRecipient(params)).then(function (reportRecipient) {
            if (!reportRecipient.error) {
                setEmail('');
            }
        });
    };
    var onClickRemove = function (id) {
        dispatch(destroyReportRecipient(id));
    };
    var onClickHelplink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    return (<div className="col-12">
      <div className="form-group row">
        <label className="col-sm-3 col-12 col-form-label">
          {I18n.t('personalSetting.destination')}
          <Helplink color="secondary" href="https://help.timecrowd.net/report-recipients" onClick={onClickHelplink}/>
        </label>
        <div className="col-sm-9 col-12">
          <div className="input-group mb-3">
            <input className="form-control" value={email} onChange={onChangeEmail} onKeyPress={onKeyPressEmail}/>
            <div className="input-group-btn">
              <button className="btn btn-secondary" onClick={onClickAdd}>
                {I18n.t('shared.add')}
              </button>
            </div>
          </div>
          <table className="table">
            <tbody>
              {reportRecipients.map(function (reportRecipient) {
            return (<tr key={"reportRecipient-".concat(reportRecipient.id)}>
                    <td>
                      <p className="d-flex flex-wrap align-items-center mb-0">
                        {reportRecipient.email}
                        <button className="ml-auto btn btn-link" onClick={function () { return onClickRemove(reportRecipient.id); }}>
                          {I18n.t('shared.remove')}
                        </button>
                      </p>
                      {reportRecipient.bounced && (<p className="mt-1 text-danger mb-0">
                          {I18n.t('shared.bouncedRecipient')}
                        </p>)}
                      {reportRecipient.unconfirmed && (<p className="mt-1 text-danger mb-0">
                          {I18n.t('shared.unconfirmedRecipient')}
                        </p>)}
                    </td>
                  </tr>);
        })}
            </tbody>
          </table>
        </div>
      </div>
    </div>);
};
