import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import Avatar from '@/components/atoms/avatar';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export default function DeleteTimeEntryModal(_a) {
    var startedAt = _a.startedAt, stoppedAt = _a.stoppedAt, user = _a.user, isOpen = _a.isOpen, toggle = _a.toggle, onDelete = _a.onDelete, openURL = _a.openURL;
    var DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
    return (<Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeaderMod toggle={toggle}>
        {I18n.t('deleteTimeEntryModal.title')}
      </ModalHeaderMod>

      <ModalBody>
        <p>{I18n.t('deleteTimeEntryModal.confirmation')}</p>
        <div className="ml-2">
          <label className="col-form-label" style={{ lineHeight: '1.2rem', width: '100%' }}>
            <Icon className="mr-1" name="document"/>
            <span style={{
            fontSize: '10px',
        }}>
              {I18n.t('shared.timeEntry')}
            </span>
          </label>
          {startedAt.format(DATETIME_FORMAT)} -{' '}
          {stoppedAt.isValid()
            ? stoppedAt.format(DATETIME_FORMAT)
            : I18n.t('shared.now')}
        </div>
        <div className="ml-2">
          <label className="col-form-label" style={{ lineHeight: '1.2rem', width: '100%' }}>
            <Icon className="mr-1" name="document"/>
            <span style={{
            fontSize: '10px',
        }}>
              {I18n.t('shared.user')}
            </span>
          </label>
          <Avatar className="mr-1" image={user.avatar_url} size="sm"/>
          {user.nickname}
        </div>
        <p className="text-danger mt-2">
          {I18n.t('deleteTimeEntryModal.attention')}
        </p>
      </ModalBody>

      <ModalFooter style={{ justifyContent: 'flex-start' }}>
        <button className="btn btn-danger" onClick={onDelete}>
          {I18n.t('shared.delete')}
        </button>
        <button className="btn btn-secondary" onClick={toggle}>
          {I18n.t('shared.cancel')}
        </button>
        <a className="btn btn-link ml-auto" href="https://help.timecrowd.net/delete-log" onClick={function (e) {
            e.preventDefault();
            openURL(e.target.href);
        }}>
          {I18n.t('shared.help')}
        </a>
      </ModalFooter>
    </Modal>);
}
