import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import classNames from 'classnames';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { filterCalendarItems } from '@/utils/calendar_helper';
var DateHeader = function (props) {
    var className = props.className, _a = props.tag, Tag = _a === void 0 ? 'div' : _a, children = props.children, calendar = props.calendar, selectedDate = props.selectedDate;
    var filteredCalendarItems = filterCalendarItems(calendar.items, calendar.view, selectedDate);
    // FIXME: 画面のちらつきを抑えるために、表示するデータが適切ではない場合は描画しない
    var canRender = calendar.view === 'daily'
        ? filteredCalendarItems.length > 0
        : filteredCalendarItems.length > 6;
    var classes = classNames('date-header d-flex', className);
    var today = moment().format('YYYY-MM-DD');
    return (<Tag className={classes}>
      {canRender &&
            filteredCalendarItems.map(function (_a) {
                var date = _a.date, _b = _a.total, hours = _b.hours, minutes = _b.minutes, time_entries = _a.time_entries;
                var overlapCount = time_entries.filter(function (t) {
                    return calendar.overlaps[t.id];
                }).length;
                return (<div key={date} className="w-100">
                <div className="w-100 d-flex flex-wrap">
                  <span className={classNames('date-header-title ml-1', {
                        today: today === date,
                        'text-muted': today < date,
                    })}>
                    {moment(date).locale(I18n.locale).format('MM-DD ddd')}
                  </span>
                  <div className="date-header-subtitle ml-1">
                    <span className="time-value">{hours}</span>
                    <span className="time-unit">
                      {I18n.t('shared.hours_short')}
                    </span>
                    <span className="time-value">{minutes}</span>
                    <span className="time-unit">
                      {I18n.t('shared.minutes_short')}
                    </span>
                  </div>
                </div>
                {overlapCount > 0 && (<div className="text-base d-flex align-items-center position-absolute ml-1 bottom-1 leading-5">
                    <Icon className="text-warning mr-0.5" name="warning"/>
                    <span>
                      {I18n.t('shared.hasOverlapTimeEntry', {
                            count: overlapCount,
                        })}
                    </span>
                  </div>)}
              </div>);
            })}
      {calendar.items.length < 2 && children}
    </Tag>);
};
export default DateHeader;
