import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { UncontrolledTooltip } from 'reactstrap';
import I18n from '@/constants/i18n';
import Duration from '@/components/atoms/duration';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import Icon from '@/components/atoms/icon';
import NewTimeEntryFormContainer from '@/containers/task_edit/new_time_entry_form_container';
import TimeEntryList from './time_entry_list';
var TaskTimeEntryList = function (props) {
    var task = props.task, query = props.query, user_id = props.query.user_id, params = props.params, onFetch = props.onFetch, next = props.next, onExpand = props.onExpand, isLoading = props.isLoading, currentUser = props.currentUser, timeEntries = props.timeEntries, isFetched = props.isFetched, openDeleteModal = props.openDeleteModal, destroyTimeEntry = props.destroyTimeEntry;
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    React.useEffect(function () {
        if (task.id !== Number(params.id)) {
            return;
        }
        onFetch(task.team.id, { task_id: task.id, user_id: user_id });
    }, [task, params, onFetch, user_id]);
    var loadMore = function () {
        if (!isLoading && next) {
            onExpand(next);
        }
    };
    var toggleNewTimeEntryForm = function () {
        setIsOpen(!isOpen);
    };
    var getUser = function () {
        return user_id
            ? task.users.find(function (user) { return user.id === Number(user_id); })
            : undefined;
    };
    var getNickName = function (user) {
        if (user) {
            return user.nickname;
        }
        else {
            return I18n.t('shared.allUsers');
        }
    };
    var user = getUser();
    var showAddButton = !user || (user && currentUser.id === user.id);
    var nickName = getNickName(user);
    var tooltipId = "task-edit-task-timeentry-list-".concat(task.id, "-tooltip");
    return (<div>
      <div className="d-flex hidden-sm-down" style={{ padding: '1.8rem 1.5rem .6rem 1.5rem' }}>
        <div className="mr-auto">
          <span className="font-weight-lighter">{nickName}</span>
        </div>
        <div className="text-muted">
          <Duration seconds={task.total_time}/>
        </div>
      </div>
      <div className="mt-2 add-time-entry-wrapper">
        {showAddButton && !isOpen && (<>
            <button className="btn btn-link btn-add" disabled={task.category.archived} onClick={toggleNewTimeEntryForm}>
              <span className="d-flex align-items-center" id={tooltipId}>
                <span className={"".concat(task.category.archived ? '' : 'btn-add-plus', " mr-2")}>
                  <Icon name="plus" size="lg"/>
                </span>
                {I18n.t('shared.addTimeEntry')}
              </span>
            </button>
            {task.category.archived && (<UncontrolledTooltip fade={false} target={tooltipId}>
                {I18n.t('shared.archivedTaskCannotBeEdited')}
              </UncontrolledTooltip>)}
          </>)}
        {isOpen && (<NewTimeEntryFormContainer query={query} task={task} onCancel={toggleNewTimeEntryForm}/>)}
      </div>
      {isFetched && (<InfiniteScroll hasMore={!!next} loader={<div key={0} className="py-2 text-center">
              <LoadingSpinner size="md"/>
            </div>} loadMore={loadMore}>
          <TimeEntryList destroyTimeEntry={destroyTimeEntry} query={query} task={task} timeEntries={timeEntries}/>
        </InfiniteScroll>)}
      {!isFetched && (<div className="loading-wrapper">
          <LoadingSpinner position="absolute-center" size="md"/>
        </div>)}
      {isFetched && !user && timeEntries.length < 1 && (<div className="border-top-1 pt-4 text-center">
          {I18n.t('shared.deleteEmptyTask')}
          <p className="mt-2">
            <button className="btn btn-link text-danger" onClick={openDeleteModal}>
              {I18n.t('shared.delete')}
            </button>
          </p>
        </div>)}
    </div>);
};
export default TaskTimeEntryList;
