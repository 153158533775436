import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import I18n from '@/constants/i18n';
import { RenewWorkspaceInvitation } from './renew_workspace_invitation';
import { InvitationUrlField } from './invitation_url_field';
export var InviteWorkspaceModal = function (_a) {
    var workspaceId = _a.workspaceId, workspacesInvitation = _a.workspacesInvitation, isOpen = _a.isOpen, renewed = _a.renewed, onClose = _a.onClose, onRenew = _a.onRenew;
    return (<Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeaderMod toggle={onClose}>
        {I18n.t('shared.inviteMembers')}
      </ModalHeaderMod>
      <ModalBody className="px-2 mx-1">
        <h4>{I18n.t('shared.workspaceInvitationUrl')}</h4>
        <p className="mt-1">
          {I18n.t('shared.workspaceInvitationDescription1')}
        </p>

        <InvitationUrlField workspacesInvitation={workspacesInvitation}/>

        <RenewWorkspaceInvitation isExpired={workspacesInvitation.is_expired} renewed={renewed} onRenew={onRenew}/>

        <hr className="mt-3"/>

        <div className="mt-3 text-center">
          <h4>{I18n.t('shared.workspaceInvitationDescription4')}</h4>
          <p className="mt-2">
            <Link className="btn btn-primary" to={"/app/workspaces/".concat(workspaceId, "/teams")}>
              {I18n.t('shared.manageTeams')}
            </Link>
          </p>
        </div>
      </ModalBody>
    </Modal>);
};
