import { __spreadArray } from "tslib";
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import some from 'lodash/some';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import WorkspacesEmployForm from './workspaces_employ_form';
import Avatar from '@/components/atoms/avatar';
import { findCurrentEmploy, isShowCurrentEmployLabel, } from '@/components/team_employ_list/helpers';
var WorkspacesEmployListModal = function (props) {
    var membership = props.membership, isOpen = props.isOpen, onCreate = props.onCreate, onUpdate = props.onUpdate, onDelete = props.onDelete, toggle = props.toggle;
    var _a = React.useState(props.employs), employs = _a[0], setEmploys = _a[1];
    var _b = React.useState(false), saving = _b[0], setSaving = _b[1];
    var _c = React.useState(false), saved = _c[0], setSaved = _c[1];
    var onChangeEmploy = function (newEmploy) {
        var newEmploys = employs.map(function (employ) {
            if (newEmploy.id === employ.id) {
                return newEmploy;
            }
            else {
                return employ;
            }
        });
        setEmploys(newEmploys);
    };
    var handleClickAdd = function (e) {
        e.preventDefault();
        addEmploy();
    };
    var addEmploy = React.useCallback(function () {
        var size = employs.length;
        var newDate = null;
        if (size === 0) {
            newDate = moment(new Date(0)).format();
        }
        else if (size === 1) {
            newDate = moment().startOf('day').format();
        }
        else {
            var lastEmploy = employs[employs.length - 1];
            newDate = moment(lastEmploy.started_at)
                .add(1, 'days')
                .startOf('day')
                .format();
        }
        var newEmploy = {
            id: moment().unix(),
            rate: '',
            started_at: newDate,
            team_id: membership.team.id,
            user_id: membership.user.id,
            isNew: true,
        };
        var newEmploys = __spreadArray(__spreadArray([], employs, true), [newEmploy], false);
        setEmploys(newEmploys);
    }, [employs, membership]);
    var onSubmit = function () {
        setSaving(true);
        var results = employs.map(function (employ) {
            var params = {
                rate: employ.rate,
                started_at: employ.started_at,
                user_id: employ.user_id,
            };
            if (employ.isNew) {
                return onCreate(employ, params);
            }
            else {
                return onUpdate(employ, params);
            }
        });
        // This logic is expected to succeed all of processing
        // But actually it should need to correspond to error
        Promise.all(results).then(function (newEmploys) {
            setEmploys(newEmploys);
            setSaving(false);
            setSaved(true);
            setTimeout(function () {
                setSaved(false);
            }, 2000);
        });
    };
    var onClickDeleteEmploy = function (employ) {
        var newEmploys = employs.filter(function (e) {
            return e.id !== employ.id;
        });
        if (!employ.isNew) {
            onDelete(employ);
        }
        setEmploys(newEmploys);
    };
    var isInvalid = function (employs) {
        return some(employs, { rate: '' });
    };
    var isDisabled = function () {
        var disabled = employs.length === 0 || isInvalid(employs) || saving || saved;
        return disabled;
    };
    var handleToggle = function () {
        if (saving || saved) {
            return;
        }
        toggle();
    };
    var saveLabel = saving ? 'saving' : saved ? 'saved' : 'save';
    var employsSize = employs.length;
    var currentEmploy = findCurrentEmploy(employs);
    return (<Modal isOpen={isOpen} toggle={handleToggle} zIndex={1050}>
      <ModalHeaderMod toggle={handleToggle}>
        <div className="mt-1">
          {I18n.t('shared.employSettingsForWorkspace')}
          <Avatar className="ml-2 mr-1" image={membership.user.avatar_url}/>
          {membership.user.nickname}
        </div>
      </ModalHeaderMod>
      <ModalBody className="px-2 mx-1">
        <div className="list-group">
          {employs.length < 1 && (<div className="py-1 mt-1 mb-2"> {I18n.t('shared.noSetting')}</div>)}
          {employs.map(function (employ, index) {
            var isMany = 1 < employsSize;
            var isNotLast = employsSize !== index + 1;
            var minMoment = index === 0
                ? null
                : moment(employs[index - 1].started_at).add(1, 'days');
            var maxMoment = isMany && isNotLast
                ? moment(employs[employs.length - 1].started_at).subtract(1, 'days')
                : moment('2050-11-31');
            return (<div key={employ.id} className={"list-group-item px-0 border-0 overflow-visible"}>
                <WorkspacesEmployForm employ={employ} index={index} isCurrent={isShowCurrentEmployLabel(employ, currentEmploy, employs)} maxDate={maxMoment.toDate()} minDate={minMoment === null || minMoment === void 0 ? void 0 : minMoment.toDate()} nextEmploy={employs[index + 1]} onChangeEmploy={onChangeEmploy} onDelete={onClickDeleteEmploy}/>
              </div>);
        })}
          <a className="list-group-item text-primary px-0 border-top-1" href="#" onClick={handleClickAdd}>
            <Icon className="mr-1" name="plus"/>
            {I18n.t('shared.addWorkspaceEmploy')}
          </a>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" disabled={saving || saved} onClick={handleToggle}>
          {I18n.t('shared.cancel')}
        </button>
        <button className="btn-major-action btn btn-primary" disabled={isDisabled()} onClick={onSubmit}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>
      </ModalFooter>
    </Modal>);
};
export default WorkspacesEmployListModal;
