import React from 'react';
import I18n from '@/constants/i18n';
import Helplink from '@/components/molecules/helplink';
export var CalendarIntegrationIgnoreWordsSetting = function (_a) {
    var ignoreWordsText = _a.ignoreWordsText, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, onChangeIgnoreWords = _a.onChangeIgnoreWords, onClickLink = _a.onClickLink;
    var ignoreWordsRows = Math.max(ignoreWordsText.split('\n').length, 2);
    return (<div className="form-group mt-3">
      <label className="font-weight-bold">
        {I18n.t('shared.ignoreWords')}
        <Helplink className="ml-1" href="https://help.timecrowd.net/various-calendar-options#exclusion-settings" onClick={onClickLink}/>
      </label>
      <p className="text-muted small">{I18n.t('shared.ignoreCalendarNote')}</p>
      <div className="mt-1">
        <textarea className="form-control w-50" disabled={isDisabled} rows={ignoreWordsRows} value={ignoreWordsText} onChange={onChangeIgnoreWords}></textarea>
        <p className="text-muted small mt-1">
          {I18n.t('shared.multiwordsNote')}
        </p>
      </div>
    </div>);
};
