import React from 'react';
import I18n from '../../constants/i18n';
function MembersInfo(_a) {
    var memberships = _a.memberships;
    return (<div>
      {I18n.t('members.memberCountLabel')} {memberships.length}{' '}
      {I18n.t('members.memberCountUnit')}
    </div>);
}
export default MembersInfo;
