import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import TaskTitle from '@/components/task_form/task_title';
import { fetchSuggestTasks, fetchUserTaskSuggestions } from '@/actions/task';
var TaskTitleContainer = function (_a) {
    var category = _a.category, disabled = _a.disabled, rows = _a.rows, task = _a.task, taskTitleRef = _a.taskTitleRef, onBlur = _a.onBlur, onChangeIsOpenTaskSuggestion = _a.onChangeIsOpenTaskSuggestion, onChangeTab = _a.onChangeTab, onChangeTask = _a.onChangeTask, onKeyPressEnter = _a.onKeyPressEnter;
    var user_id = useParams().user_id;
    var dispatch = useDispatch();
    var onSuggestTasks = function (category, title) {
        var params = {
            title: title,
            category_id: category.id,
            team_id: category.team_id,
        };
        if (user_id) {
            dispatch(fetchUserTaskSuggestions(user_id, params));
        }
        else {
            dispatch(fetchSuggestTasks(params));
        }
    };
    return (<TaskTitle category={category} disabled={disabled} rows={rows} task={task} taskTitleRef={taskTitleRef} value={task.title} onBlur={onBlur} onChangeIsOpenTaskSuggestion={onChangeIsOpenTaskSuggestion} onChangeTab={onChangeTab} onChangeTask={onChangeTask} onKeyPressEnter={onKeyPressEnter} onSuggestTasks={onSuggestTasks}/>);
};
export default TaskTitleContainer;
