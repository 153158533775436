import { __awaiter, __generator } from "tslib";
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import I18n from '@/constants/i18n';
import { ArchiveCategoryModalCategoryItem } from './archive_category_modal_category_item';
export var ArchiveCategoryModal = function (_a) {
    var isOpen = _a.isOpen, category = _a.category, categories = _a.categories, onToggle = _a.onToggle, onArchive = _a.onArchive;
    var _b = React.useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var mode = category.archived ? 'unarchive' : 'archive';
    var onClickArchive = React.useCallback(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, onArchive(category)];
                    case 1:
                        res = _a.sent();
                        setIsLoading(false);
                        if (!res.error) {
                            onToggle();
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [category, onArchive, onToggle]);
    return (<Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeaderMod toggle={onToggle}>
        {I18n.t("shared.archiveCategoryModalTitle.".concat(mode))}
      </ModalHeaderMod>

      <ModalBody>
        <p>{I18n.t("shared.archiveCategoryModalDescription.".concat(mode))}</p>
        <div className="mb-2">
          <ArchiveCategoryModalCategoryItem categories={categories} parent={category}/>
        </div>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-secondary" onClick={onToggle}>
          {I18n.t('shared.cancel')}
        </button>
        <button className="btn btn-danger" disabled={isLoading} onClick={onClickArchive}>
          {I18n.t("shared.".concat(mode))}
        </button>
      </ModalFooter>
    </Modal>);
};
