import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import I18n from '@/constants/i18n';
import { formatTime } from '@/utils/time_helper';
import { imageSource } from '@/utils/image_helper';
import variants from '@/constants/variants';
export var TimeEntrySuggestionButton = function (_a) {
    var _b, _c;
    var datetime = _a.datetime, prevTimeEntry = _a.prevTimeEntry, nextTimeEntry = _a.nextTimeEntry, _d = _a.isInline, isInline = _d === void 0 ? false : _d, _e = _a.isPC, isPC = _e === void 0 ? variants.isPC : _e, onClick = _a.onClick;
    var invisible = (!prevTimeEntry && !nextTimeEntry) ||
        (prevTimeEntry && datetime.isSame(prevTimeEntry.stopped_at, 'minute')) ||
        (nextTimeEntry && datetime.isSame(nextTimeEntry.started_at, 'minute'));
    var isPrev = !!prevTimeEntry;
    var isNext = !!nextTimeEntry;
    var labelKey = isPrev ? 'inputPrevStopTime' : 'inputNextStartTime';
    var imageName = isPrev ? 'join-prev' : 'join-next';
    var buttonRef = React.useRef(null);
    var iconImage = (<img className={"align-text-bottom ".concat(isPrev ? 'mr-1' : 'ml-1')} src={imageSource("".concat(imageName, ".svg"))}/>);
    return (<>
      <button ref={buttonRef} className={"btn btn-gray-darker text-left font-weight-normal mt-1 btn-sm px-1 whitespace-normal d-flex align-items-center transition-none ".concat(invisible ? 'invisible' : '')} onClick={onClick}>
        {isPrev && iconImage}
        {!isInline && (<span className="mr-1 text-nowarp">
            {I18n.t("shared.".concat(labelKey))}
          </span>)}
        <span className="font-weight-bold">
          {formatTime(new Date((_c = (_b = prevTimeEntry === null || prevTimeEntry === void 0 ? void 0 : prevTimeEntry.stopped_at) !== null && _b !== void 0 ? _b : nextTimeEntry === null || nextTimeEntry === void 0 ? void 0 : nextTimeEntry.started_at) !== null && _c !== void 0 ? _c : new Date()), false)}
        </span>
        {isNext && iconImage}
      </button>
      {isInline && isPC && !invisible && (<UncontrolledTooltip autohide={false} fade={false} placement="bottom" target={buttonRef}>
          {I18n.t("shared.".concat(labelKey))}
        </UncontrolledTooltip>)}
    </>);
};
