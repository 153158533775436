import { __assign } from "tslib";
import { connect } from 'react-redux';
import { setCurrentEntry } from '@/modules/current_entry';
import TimeEntryForm from '@/components/task_edit/time_entry_form';
import { fetchTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { isMine: state.user.id === ownProps.user.id, taskSearch: state.taskSearch, timeEntrySuggestionsStatus: state.timeEntrySuggestions.status })); };
var mapDispatchToProps = function (dispatch) { return ({
    onSetCurrentEntry: function (timeEntry) {
        dispatch(setCurrentEntry(timeEntry));
    },
    fetchTimeEntrySuggestion: function (time) {
        dispatch(fetchTimeEntrySuggestion(time));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TimeEntryForm);
