import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
import PaymentAlert from '@/components/payment_alert';
var ExportPaymentAlert = function (_a) {
    var team = _a.team, isOpen = _a.isOpen, onClose = _a.onClose, onPay = _a.onPay, openURL = _a.openURL;
    if (!team) {
        return null;
    }
    if (!team.payable) {
        return null;
    }
    var message;
    if (team.payment_required) {
        if (team.can_manage) {
            message = I18n.t('export.trialEndedToOwner', { name: team.name });
        }
        else {
            message = I18n.t('export.trialEndedToMember', { name: team.name });
        }
    }
    else {
        var trialEndDays = moment(team.trial_ends_at).diff(moment(), 'd');
        message = I18n.t('export.trialDuring', {
            period: trialEndDays,
            name: team.name,
        });
    }
    return (<PaymentAlert isOpen={isOpen} message={message} openURL={openURL} team={team} onClose={onClose} onPay={onPay}/>);
};
export default ExportPaymentAlert;
