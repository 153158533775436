import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CrossTeamReportPeriodSelector } from '@/components/cross_team_report_header/cross_team_report_period_selector';
import { fetchWorkspacesRealtimeReport } from '@/modules/workspaces_realtime_reports';
export var CrossTeamReportPeriodSelectorContainer = function (props) {
    var defaultPeriod = props.defaultPeriod, defaultStartMonth = props.defaultStartMonth;
    var dispatch = useDispatch();
    var workspacesRealtimeReports = useSelector(function (state) { return state.workspacesRealtimeReports; });
    var handleRefreshRealtime = React.useCallback(function (params) {
        return dispatch(fetchWorkspacesRealtimeReport(params));
    }, [dispatch]);
    return (<CrossTeamReportPeriodSelector defaultPeriod={defaultPeriod} defaultStartMonth={defaultStartMonth} isLoadingRealtime={workspacesRealtimeReports.status === 'loading'} onRefreshRealtime={handleRefreshRealtime}/>);
};
