import { __awaiter, __generator } from "tslib";
import React from 'react';
import Icon from '@/components/atoms/icon';
export function TimeEntryInlineCommendField(_a) {
    var _this = this;
    var id = _a.id, comment = _a.comment, canUpdate = _a.canUpdate, upsertTimeEntryComment = _a.upsertTimeEntryComment;
    var _b = React.useState(false), isEditingComment = _b[0], setIsEditingComment = _b[1];
    var textareRef = React.useRef(null);
    var defaultCommentContent = comment ? comment.content : '';
    var _c = React.useState(defaultCommentContent), commentContent = _c[0], setCommentContent = _c[1];
    React.useEffect(function () {
        setIsEditingComment(false);
        setCommentContent(defaultCommentContent);
    }, [defaultCommentContent]);
    var updateComment = React.useCallback(function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, upsertTimeEntryComment(id, {
                            comment: {
                                content: commentContent,
                            },
                        })];
                    case 1:
                        res = _a.sent();
                        if (!res.error) {
                            setIsEditingComment(false);
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [commentContent, id, upsertTimeEntryComment]);
    var icon = (<Icon className="align-baseline" color={defaultCommentContent !== '' ? 'text' : 'disable'} name="message" title={defaultCommentContent}/>);
    var startEditing = function (e) {
        e.stopPropagation();
        setIsEditingComment(canUpdate);
        setTimeout(function () { var _a; return (_a = textareRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 0);
    };
    return (<>
      <div className="py-2 hidden-sm-down" onMouseDown={startEditing}>
        {icon}
      </div>
      <span className="hidden-md-up mt-2">{icon}</span>
      {isEditingComment ? (<div className="align-items-center flex-grow-1 d-flex" onMouseDown={function (e) { return e.stopPropagation(); }}>
          <textarea ref={textareRef} className="form-control form-control-sm ml-1 my-2 py-0" rows={Math.max(commentContent.split('\n').length, 1)} value={commentContent} onChange={function (e) { return setCommentContent(e.target.value); }} onKeyDown={function (e) {
                if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
                    e.preventDefault();
                    updateComment();
                }
            }}></textarea>
          <button className="btn btn-outline-primary rounded-circle px-1 py-0 ml-1" onClick={function () { return updateComment(); }}>
            <Icon name="arrow-thin-up" size="sm"/>
          </button>
          <button className="btn btn-outline-secondary text-muted rounded-circle px-1 py-0 ml-1" onClick={function () {
                setIsEditingComment(false);
                setCommentContent(defaultCommentContent);
            }}>
            <Icon name="cross" size="sm"/>
          </button>
        </div>) : (<span className="time-entry-comment hidden-sm-down flex-grow-1 ml-1 pr-5 pt-2 pb-1 mb-1" onMouseDown={startEditing}>
          {defaultCommentContent || <>&nbsp;</>}
        </span>)}
    </>);
}
