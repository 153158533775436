import { __assign } from "tslib";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import debaunce from 'lodash/debounce';
import I18n from '@/constants/i18n';
import { WorkspacesSettingsLayout } from '@/components/workspaces_settings_layout';
import Helplink from '@/components/molecules/helplink';
import { WorkspacesCalendarIntegrationFilters } from '@/components/workspaces_calendar_integration_filters';
import { CalendarIntegrationAutoStartSetting } from '@/components/calendar_integration_auto_start_setting';
import { CalendarIntegrationIgnoreWordsSetting } from '@/components/calendar_integration_ignore_words_setting';
import { CalendarIntegrationIgnoreMinutesSetting } from '@/components/calendar_integration_ignore_minutes_setting';
import TeamSelect from '@/components/selects/team_select';
import Icon from '@/components/atoms/icon';
import { CalendarIntegrationFilterFormModal } from '@/components/calendar_integration_filter_form_modal';
export var WorkspacesCalendarIntegrationSetting = function (_a) {
    var categories = _a.categories, currentUser = _a.currentUser, filters = _a.filters, setting = _a.setting, teams = _a.teams, onClickLink = _a.onClickLink, onCloseModal = _a.onCloseModal, onCreateFilter = _a.onCreateFilter, onDeleteFilter = _a.onDeleteFilter, onDownFilterPriority = _a.onDownFilterPriority, onSubmitSetting = _a.onSubmitSetting, onUpdateFilter = _a.onUpdateFilter, onUpFilterPriority = _a.onUpFilterPriority;
    var _b = React.useState(false), isAutoStart = _b[0], setIsAutoStart = _b[1];
    var _c = React.useState('none'), startMode = _c[0], setStartMode = _c[1];
    var _d = React.useState('none'), stopMode = _d[0], setStopMode = _d[1];
    var _e = React.useState(''), ignoreWords = _e[0], setIgnoreWords = _e[1];
    var _f = React.useState(0), ignoreMinutes = _f[0], setIgnoreMinutes = _f[1];
    var _g = React.useState(false), isIgnoreMinutes = _g[0], setIsIgnoreMinutes = _g[1];
    var _h = React.useState(null), selectedTeam = _h[0], setSelectedTeam = _h[1];
    var _j = React.useState(false), isOpenFilterFormModal = _j[0], setIsOpenFilterFormModal = _j[1];
    var navigate = useNavigate();
    var selectedTeamCategories = selectedTeam
        ? categories.filter(function (_a) {
            var team_id = _a.team_id;
            return team_id === (selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.id);
        })
        : categories;
    var selectedTeamCategoryIds = selectedTeamCategories.map(function (_a) {
        var id = _a.id;
        return id;
    });
    var selectedTeamCategoryCount = selectedTeam
        ? filters.filter(function (_a) {
            var category_id = _a.category_id;
            return selectedTeamCategoryIds.includes(category_id);
        }).length
        : filters.length;
    var filteredCalendarIntegrationFilters = filters.filter(function (_a) {
        var category_id = _a.category_id;
        return !selectedTeam || selectedTeamCategoryIds.includes(category_id);
    });
    var invalidFilters = filteredCalendarIntegrationFilters.filter(function (_a) {
        var category_id = _a.category_id, is_category_soft_destroyed = _a.is_category_soft_destroyed, is_category_archived = _a.is_category_archived;
        return !category_id || is_category_soft_destroyed || is_category_archived;
    });
    var errorMessages = [];
    if (invalidFilters.length > 0) {
        errorMessages.push(I18n.t('shared.pleaseSelectCategoryOfCalendarIntegrationFilterHtml', {
            label: I18n.t('calendarIntegrationWorkspaceSetting.label'),
            count: invalidFilters.length,
        }));
    }
    var filtersWithCategory = React.useMemo(function () {
        return filters.map(function (filter) {
            var category = categories.find(function (c) { return c.id === filter.category_id; });
            return __assign(__assign({}, filter), { category: category });
        });
    }, [filters, categories]);
    var handleChangeAutoStart = React.useCallback(function () {
        var newIsAutoStart = !isAutoStart;
        var params = {
            start_mode: newIsAutoStart ? 'auto' : 'none',
            stop_mode: newIsAutoStart ? 'auto' : 'none',
        };
        onSubmitSetting(params).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            setStartMode(params.start_mode);
            setStopMode(params.stop_mode);
            setIsAutoStart(newIsAutoStart);
        });
    }, [isAutoStart, onSubmitSetting]);
    var handleChangeStartMode = React.useCallback(function (mode) {
        onSubmitSetting({ start_mode: mode }).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            setStartMode(mode);
        });
    }, [onSubmitSetting]);
    var handleChangeStopMode = React.useCallback(function (mode) {
        onSubmitSetting({ stop_mode: mode }).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            setStopMode(mode);
        });
    }, [onSubmitSetting]);
    var debauncedOnSubmitSetting = React.useMemo(function () { return debaunce(onSubmitSetting, 2000); }, [onSubmitSetting]);
    var handleChangeIgnoreWords = React.useCallback(function (e) {
        debauncedOnSubmitSetting({ ignore_words_text: e.target.value });
        setIgnoreWords(e.target.value);
    }, [debauncedOnSubmitSetting]);
    var handleChangeIsIgnoreMinutes = React.useCallback(function () {
        if (isIgnoreMinutes) {
            onSubmitSetting({ ignore_minutes: 0 });
            setIgnoreMinutes(0);
        }
        setIsIgnoreMinutes(!isIgnoreMinutes);
    }, [isIgnoreMinutes, onSubmitSetting]);
    var handleChangeIgnoreMinutes = React.useCallback(function (e) {
        debauncedOnSubmitSetting({ ignore_minutes: e.target.value });
        setIgnoreMinutes(e.target.value);
    }, [debauncedOnSubmitSetting]);
    var handleClickPersonalSettings = React.useCallback(function () {
        navigate('/app/personal_settings/calendar_integration');
    }, [navigate]);
    var handleSelectTeam = React.useCallback(function (team) {
        if (team.id) {
            setSelectedTeam(team);
        }
        else {
            setSelectedTeam(null);
        }
    }, []);
    var handleToggleFilterFormModal = React.useCallback(function () {
        setIsOpenFilterFormModal(!isOpenFilterFormModal);
        onCloseModal();
    }, [isOpenFilterFormModal, onCloseModal]);
    var handleCreateFilter = React.useCallback(function (params) {
        onCreateFilter(params).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            setIsOpenFilterFormModal(false);
        });
    }, [onCreateFilter]);
    var handleClickLink = React.useCallback(function (e) {
        onClickLink(e);
    }, [onClickLink]);
    React.useEffect(function () {
        setIsAutoStart(setting.start_mode !== 'none');
        setStartMode(setting.start_mode);
    }, [setting.start_mode]);
    React.useEffect(function () {
        setStopMode(setting.stop_mode);
    }, [setting.stop_mode]);
    React.useEffect(function () {
        setIgnoreWords(setting.ignore_words_text);
    }, [setting.ignore_words_text]);
    React.useEffect(function () {
        setIsIgnoreMinutes(setting.ignore_minutes > 0);
        setIgnoreMinutes(setting.ignore_minutes);
    }, [setting.ignore_minutes]);
    var nicknameWithMe = function (manager) {
        if (manager.id === currentUser.id) {
            return "".concat(manager.nickname, " (").concat(I18n.t('shared.you'), ")");
        }
        return manager.nickname;
    };
    return (<WorkspacesSettingsLayout active="calendar_integration" errorMessages={errorMessages} title={"".concat(I18n.t('shared.workspace'), " ").concat(I18n.t('shared.calendarIntegrations'))}>
      <p>
        {I18n.t('shared.workspaceCalendarIntegrationSettingDescription1')}
        <br />
        <a className="btn btn-link p-0" href="https://support.timecrowd.net/workspace-calendar-auto-track-overview" onClick={handleClickLink}>
          {I18n.t('shared.seeDetailedFeatureDescription')}
        </a>
      </p>
      <p>
        {I18n.t('shared.workspaceCalendarIntegrationSettingDescription2')}
        <br />
        <button className="btn btn-link p-0" onClick={handleClickPersonalSettings}>
          {I18n.t('shared.moveToPersonalSettings')}
        </button>
      </p>

      <div className="mt-3">
        <div className="alert-warning border-1 border-warning rounded p-2">
          <div className="d-flex">
            <div>
              <Icon className="mr-2 text-warning" name="warning"/>
            </div>

            <div>
              <p>
                {I18n.t('calendarIntegrationWorkspaceSetting.authorizationDescription')}
              </p>

              <ul className="pl-3">
                <li>
                  {I18n.t('calendarIntegrationWorkspaceSetting.workspaceAuthorization')}
                </li>
                <li>
                  {I18n.t('calendarIntegrationWorkspaceSetting.allTeamsOwnerAuthorization')}
                </li>
              </ul>

              <p>
                {I18n.t('calendarIntegrationWorkspaceSetting.currentPrivilegedUsers')}
                : {setting.managers.map(nicknameWithMe).join(', ')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <CalendarIntegrationAutoStartSetting helplink="https://support.timecrowd.net/workspace-calendar-auto-track-setting" isAutoStart={isAutoStart} isDisabled={!setting.can_update} startMode={startMode} stopMode={stopMode} onChangeAutoStart={handleChangeAutoStart} onChangeStartMode={handleChangeStartMode} onChangeStopMode={handleChangeStopMode} onClickLink={onClickLink}/>
      <div className="form-group mt-3">
        <label className="font-weight-bold">
          {I18n.t('calendarIntegrationWorkspaceSetting.label')}
          <Helplink className="ml-1" href="https://support.timecrowd.net/workspace-calendar-auto-categorization" onClick={onClickLink}/>
        </label>
        <p className="text-muted small" dangerouslySetInnerHTML={{
            __html: I18n.t('calendarIntegrationWorkspaceSetting.descriptionHtml'),
        }}></p>
        <div className="mb-3">
          <label className="font-weight-bold">
            {I18n.t('calendarIntegrationWorkspaceSetting.teamFilterLabel')}
          </label>
          <div className="d-flex align-items-center">
            <TeamSelect block={false} defaultTeamName={I18n.t('shared.all')} right={false} selected={selectedTeam} teams={teams} toggle={function () { }} onClick={handleSelectTeam}/>
            <div className="ml-2">
              <span>
                {I18n.t('shared.countOfFilters', {
            count: selectedTeamCategoryCount,
        })}
              </span>
            </div>
            <div className="ml-auto">
              <button className="btn btn-primary" onClick={handleToggleFilterFormModal}>
                <Icon name="plus"/>
                {I18n.t('shared.addFiltering')}
              </button>
            </div>
          </div>
        </div>
        {!!categories.length && (<div>
            <WorkspacesCalendarIntegrationFilters allFilters={filtersWithCategory} calendarIntegrationFilters={filteredCalendarIntegrationFilters} categories={selectedTeamCategories} isEnablePriority={!selectedTeam} onCloseModal={onCloseModal} onDownFilterPriority={onDownFilterPriority} onSubmitDelete={onDeleteFilter} onSubmitUpdate={onUpdateFilter} onUpFilterPriority={onUpFilterPriority}/>
          </div>)}
      </div>

      <CalendarIntegrationIgnoreWordsSetting ignoreWordsText={ignoreWords} isDisabled={!setting.can_update} onChangeIgnoreWords={handleChangeIgnoreWords} onClickLink={onClickLink}/>

      <CalendarIntegrationIgnoreMinutesSetting ignoreMinutes={ignoreMinutes} isDisabled={!setting.can_update} isIgnoreMinutes={isIgnoreMinutes} onChangeIgnoreMinutes={handleChangeIgnoreMinutes} onChangeIsIgnoreMinutes={handleChangeIsIgnoreMinutes} onClickLink={onClickLink}/>

      <CalendarIntegrationFilterFormModal categories={selectedTeamCategories} filters={filtersWithCategory} isExpandAll={!!selectedTeam} isOpen={isOpenFilterFormModal} onClose={handleToggleFilterFormModal} onSubmit={handleCreateFilter}/>
    </WorkspacesSettingsLayout>);
};
