import { __assign } from "tslib";
import api2 from '@/api2';
export var FETCH_SYSTEM_NOTIFICATIONS_REQUEST = 'timecrowd/SYSTEM_NOTIFICATIONS/FETCH_SYSTEM_NOTIFICATIONS_REQUEST';
export var FETCH_SYSTEM_NOTIFICATIONS_SUCCESS = 'timecrowd/SYSTEM_NOTIFICATIONS/FETCH_SYSTEM_NOTIFICATIONS_SUCCESS';
export var FETCH_SYSTEM_NOTIFICATIONS_FAILURE = 'timecrowd/SYSTEM_NOTIFICATIONS/FETCH_SYSTEM_NOTIFICATIONS_FAILURE';
export var CONFIRM_SYSTEM_NOTIFICATION_REQUEST = 'timecrowd/SYSTEM_NOTIFICATIONS/CONFIRM_SYSTEM_NOTIFICATION_REQUEST';
export var CONFIRM_SYSTEM_NOTIFICATION_SUCCESS = 'timecrowd/SYSTEM_NOTIFICATIONS/CONFIRM_SYSTEM_NOTIFICATION_SUCCESS';
export var CONFIRM_SYSTEM_NOTIFICATION_FAILURE = 'timecrowd/SYSTEM_NOTIFICATIONS/CONFIRM_SYSTEM_NOTIFICATION_FAILURE';
var initialState = {
    isLoading: false,
    isFetched: false,
    notifications: [],
};
export default function SystemNotifications(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_SYSTEM_NOTIFICATIONS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_SYSTEM_NOTIFICATIONS_SUCCESS:
            return __assign(__assign(__assign({}, state), { isLoading: false, isFetched: true }), action.payload);
        case FETCH_SYSTEM_NOTIFICATIONS_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case CONFIRM_SYSTEM_NOTIFICATION_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case CONFIRM_SYSTEM_NOTIFICATION_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, notifications: state.notifications.filter(function (_a) {
                    var id = _a.id;
                    return id !== action.payload.id;
                }) });
        case CONFIRM_SYSTEM_NOTIFICATION_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
export var fetchSystemNotificationsRequest = function () { return ({
    type: FETCH_SYSTEM_NOTIFICATIONS_REQUEST,
}); };
export var fetchSystemNotificationsSuccess = function (payload) { return ({
    type: FETCH_SYSTEM_NOTIFICATIONS_SUCCESS,
    payload: payload,
}); };
export var fetchSystemNotificationsFailure = function () { return ({
    type: FETCH_SYSTEM_NOTIFICATIONS_FAILURE,
}); };
export var fetchSystemNotifications = function () {
    return function (dispatch, getState) {
        dispatch(fetchSystemNotificationsRequest());
        return api2(dispatch, getState, '/api/v2/system_notifications', 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (json) {
                    var payload = { notifications: json };
                    dispatch(fetchSystemNotificationsSuccess(payload));
                    return payload;
                });
            }
            else {
                dispatch(fetchSystemNotificationsFailure());
                return { error: true };
            }
        });
    };
};
export var confirmSystemNotificationRequest = function () { return ({
    type: CONFIRM_SYSTEM_NOTIFICATION_REQUEST,
}); };
export var confirmSystemNotificationSuccess = function (payload) { return ({
    type: CONFIRM_SYSTEM_NOTIFICATION_SUCCESS,
    payload: payload,
}); };
export var confirmSystemNotificationFailure = function () { return ({
    type: CONFIRM_SYSTEM_NOTIFICATION_FAILURE,
}); };
export var confirmSystemNotification = function (id) {
    return function (dispatch, getState) {
        dispatch(confirmSystemNotificationRequest());
        return api2(dispatch, getState, "/api/v2/system_notifications/".concat(id, "/confirm"), 'POST').then(function (response) {
            if (response.ok) {
                var payload = { id: id };
                dispatch(confirmSystemNotificationSuccess(payload));
            }
            else {
                dispatch(confirmSystemNotificationFailure());
                return { error: true };
            }
        });
    };
};
