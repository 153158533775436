import { __assign } from "tslib";
export var SET_CURRENT_ENTRY = 'timecrowd/current_entry/SET_CURRENT_ENTRY';
export var STOP_CURRENT_ENTRY_REQUEST = 'timecrowd/current_entry/STOP_CURRENT_ENTRY_REQUEST';
export var STOP_CURRENT_ENTRY_SUCCESS = 'timecrowd/current_entry/STOP_CURRENT_ENTRY_SUCCESS';
export var STOP_CURRENT_ENTRY_FAILURE = 'timecrowd/current_entry/STOP_CURRENT_ENTRY_FAILURE';
var initialState = {
    current: null,
    status: 'idle',
};
export default function currentEntry(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_CURRENT_ENTRY:
            return __assign(__assign({}, state), { current: action.currentEntry });
        case STOP_CURRENT_ENTRY_REQUEST:
            return __assign(__assign({}, state), { status: 'updating' });
        case STOP_CURRENT_ENTRY_SUCCESS:
            return {
                current: null,
                status: 'idle',
            };
        case STOP_CURRENT_ENTRY_FAILURE:
            return __assign(__assign({}, state), { status: 'idle' });
        default:
            return state;
    }
}
export var setCurrentEntry = function (currentEntry) { return ({
    type: SET_CURRENT_ENTRY,
    currentEntry: currentEntry,
}); };
export var stopCurrentEntryRequest = function () { return ({
    type: STOP_CURRENT_ENTRY_REQUEST,
}); };
export var stopCurrentEntrySuccess = function () { return ({
    type: STOP_CURRENT_ENTRY_SUCCESS,
}); };
export var stopCurrentEntryFailure = function () { return ({
    type: STOP_CURRENT_ENTRY_FAILURE,
}); };
