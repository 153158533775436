import React from 'react';
import I18n from '@/constants/i18n';
export var UserStatusBadge = function (_a) {
    var user = _a.user;
    if (user.deactivated) {
        return (<span className="badge badge-danger ml-auto">
        {I18n.t('shared.deactivated')}
      </span>);
    }
    if (user.member) {
        return null;
    }
    else {
        return (<span className="badge badge-info ml-auto">
        {I18n.t('shared.leaved')}
      </span>);
    }
};
