import React from 'react';
import { useSelector } from 'react-redux';
import QuickTaskCurrent from '@/components/organisms/quick_task_current';
var QuickTaskCurrentContainer = function (_a) {
    var onStop = _a.onStop;
    var currentEntry = useSelector(function (state) { return state.currentEntry; });
    var isLoading = currentEntry.status === 'updating';
    if (!currentEntry.current) {
        return null;
    }
    return (<QuickTaskCurrent currentEntry={currentEntry.current} isLoading={isLoading} onStop={onStop}/>);
};
export default QuickTaskCurrentContainer;
