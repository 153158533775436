import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationMessage } from '@/modules/notification';
import { WorkspacesTeamsForm } from '@/components/workspaces_teams_form';
import { fetchTeamMemberships, } from '@/modules/memberships';
import { createTeam } from '@/modules/teams';
import LoadingPage from '@/components/pages/loading_page';
import NotFound from '@/components/pages/not_found';
import { workspaceTeams } from '@/utils/workspace_helper';
import { toggleTeamMenu } from '@/modules/team_management';
var WorkspacesTeamsContainer = function () {
    var workspaces = useSelector(function (state) { return state.workspaces; });
    var teams = useSelector(function (state) { return state.teams; });
    var memberships = useSelector(function (state) { return state.memberships; });
    var dispatch = useDispatch();
    var handleCreateTeam = function (params) {
        return dispatch(createTeam(params));
    };
    var handleSetNotificationMessage = function (messageType, message) {
        dispatch(setNotificationMessage(messageType, message));
    };
    var handleToggleTeamMenu = function (isOpen) {
        dispatch(toggleTeamMenu(isOpen));
    };
    var filteredTeams = React.useMemo(function () { return workspaceTeams(teams.items); }, [teams.items]);
    React.useEffect(function () {
        if (teams.isLoading || !teams.isFetched) {
            return;
        }
        filteredTeams.forEach(function (team) { return dispatch(fetchTeamMemberships(team.id)); });
    }, [dispatch, filteredTeams, teams.isLoading, teams.isFetched]);
    if (!workspaces.isLoaded) {
        return <LoadingPage />;
    }
    var workspace = workspaces.current;
    if (!workspace || workspace.role !== 'owner') {
        return <NotFound />;
    }
    if (!teams.isFetched) {
        return <LoadingPage />;
    }
    return (<WorkspacesTeamsForm createTeam={handleCreateTeam} membershipsByTeamId={memberships.itemsByTeamId} setNotificationMessage={handleSetNotificationMessage} teams={filteredTeams} toggleTeamMenu={handleToggleTeamMenu} workspace={workspace}/>);
};
export default WorkspacesTeamsContainer;
