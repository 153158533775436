import React from 'react';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import MainMenuContainer from '@/containers/main_menu_container';
export default function LoadingPage() {
    return (<div className="content-wrap">
      <MainMenuContainer />
      <div className="content-main">
        <LoadingSpinner position="absolute-center" size="md"/>
      </div>
    </div>);
}
