import React from 'react';
import classNames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
var Avatar = function (props) {
    var _a;
    var className = props.className, children = props.children, _b = props.tag, Tag = _b === void 0 ? 'div' : _b, _c = props.image, image = _c === void 0 ? '' : _c, _d = props.name, name = _d === void 0 ? '' : _d, _e = props.size, size = _e === void 0 ? 'md' : _e, _f = props.color, color = _f === void 0 ? 'normal' : _f, _g = props.active, active = _g === void 0 ? false : _g, _h = props.disabled, disabled = _h === void 0 ? false : _h, onClick = props.onClick;
    var hasImage = image !== '';
    var conditionals = (_a = {
            avatar: hasImage
        },
        _a['avatar-' + color] = true,
        _a['avatar-' + size] = true,
        _a.active = active,
        _a.disabled = disabled,
        _a['default-image'] = image === '',
        _a);
    var classes = classNames(className, conditionals);
    var imageRef = React.useRef(null);
    return (<Tag className={classes} onClick={onClick}>
      {hasImage && (<div>
          <img ref={imageRef} alt={name} className="avatar-image" src={image} title={name}/>
          {name !== '' && (<UncontrolledTooltip fade={false} placement="top" target={imageRef}>
              {name}
            </UncontrolledTooltip>)}
        </div>)}
      {children}
    </Tag>);
};
export default Avatar;
