import { __assign } from "tslib";
import { connect } from 'react-redux';
import EditTaskForm from '@/components/task_edit/edit_task_form';
import { updateTask } from '@/api2/tasks';
import { fetchSameKeyTask } from '@/actions/task';
import { openModal } from '@/modules/modal';
import { workspaceTeams } from '@/utils/workspace_helper';
function filterTeams(task, teams) {
    if (task.team.can_manage) {
        return teams;
    }
    else {
        return teams.filter(function (team) { return team.id === task.team.id; });
    }
}
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { teams: filterTeams(ownProps.task, workspaceTeams(state.teams.items)), categories: state.categories })); };
var mapDispatchToProps = function (dispatch) { return ({
    onCheck: function (id, params) {
        return dispatch(fetchSameKeyTask(id, params));
    },
    onSubmit: function (id, params) {
        return dispatch(updateTask(id, params));
    },
    onOpenModal: function (modalTypeName) {
        dispatch(openModal(modalTypeName));
    },
    onOpenDeleteModal: function () {
        dispatch(openModal('DELETE_TASK_MODAL'));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(EditTaskForm);
