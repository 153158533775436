import { __assign } from "tslib";
import { connect } from 'react-redux';
import MembershipRoleDropdown from '@/components/members/membership_role_dropdown';
import { updateMembership } from '@/modules/memberships';
import { setNotificationMessage } from '@/modules/notification';
import I18n from '@/constants/i18n';
var mapStateToProps = function (state, ownProps) { return (__assign({}, ownProps)); };
var mapDispatchToProps = function (dispatch) { return ({
    onUpdate: function (membership, role) {
        var params = {
            membership: {
                user_id: membership.user.id,
                role: role,
            },
        };
        dispatch(updateMembership(membership.team.id, membership.id, params)).then(function (membership) {
            if (!membership.error) {
                var user = membership.user;
                var roleText = I18n.t("shared.roles.".concat(membership.role));
                var message = I18n.t('teamManagement.membershipRoleInput.updateSuccess', { nickname: user.nickname, role: roleText });
                dispatch(setNotificationMessage('success', message));
            }
        });
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MembershipRoleDropdown);
