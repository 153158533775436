import { __assign } from "tslib";
import React from 'react';
import { WorkspacesCalendarIntegrationFilter } from '@/components/workspaces_calendar_integration_filter';
import { CalendarIntegrationFilterFormModal } from '@/components/calendar_integration_filter_form_modal';
import { WorkspacesCalendarIntegrationDeleteFilterModal } from '@/components/workspaces_calendar_integration_delete_filter_modal';
export var WorkspacesCalendarIntegrationFilters = function (_a) {
    var _b = _a.allFilters, allFilters = _b === void 0 ? [] : _b, calendarIntegrationFilters = _a.calendarIntegrationFilters, categories = _a.categories, isEnablePriority = _a.isEnablePriority, onCloseModal = _a.onCloseModal, onDownFilterPriority = _a.onDownFilterPriority, onSubmitDelete = _a.onSubmitDelete, onSubmitUpdate = _a.onSubmitUpdate, onUpFilterPriority = _a.onUpFilterPriority;
    var maxPriority = calendarIntegrationFilters.length;
    var filtersWithCategory = calendarIntegrationFilters.map(function (filter) {
        var category = categories.find(function (c) { return c.id === filter.category_id; });
        return __assign(__assign({}, filter), { category: category });
    });
    var _c = React.useState(false), isOpenFilterFormModal = _c[0], setIsOpenFilterFormModal = _c[1];
    var _d = React.useState(false), isOpenDeleteModal = _d[0], setIsOpenDeleteModal = _d[1];
    var _e = React.useState(null), selectedFilter = _e[0], setSelectedFilter = _e[1];
    var handleClickDelete = React.useCallback(function (filter) {
        setSelectedFilter(filter);
        setIsOpenDeleteModal(true);
    }, []);
    var handleClickCloseDeleteModal = React.useCallback(function () {
        setSelectedFilter(null);
        setIsOpenDeleteModal(false);
        onCloseModal();
    }, [onCloseModal]);
    var handleSubmit = React.useCallback(function (id) {
        onSubmitDelete(id).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            handleClickCloseDeleteModal();
        });
    }, [onSubmitDelete, handleClickCloseDeleteModal]);
    var handleCloseFilterFormModal = React.useCallback(function () {
        setSelectedFilter(null);
        setIsOpenFilterFormModal(false);
        onCloseModal();
    }, [onCloseModal]);
    var handleClickEdit = React.useCallback(function (filter) {
        setSelectedFilter(filter);
        setIsOpenFilterFormModal(true);
    }, []);
    var handleUpdateFilter = React.useCallback(function (params) {
        onSubmitUpdate(selectedFilter.id, params).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            handleCloseFilterFormModal();
        });
    }, [handleCloseFilterFormModal, onSubmitUpdate, selectedFilter]);
    return (<>
      <ul className="list-none p-0">
        {filtersWithCategory
            .sort(function (a, b) { return a.priority - b.priority; })
            .map(function (filter, i) { return (<li key={i} className="border-1 border-bottom-0 last:border-bottom-1 px-3 py-2">
              <WorkspacesCalendarIntegrationFilter filter={filter} isEnablePriority={isEnablePriority} maxPriority={maxPriority} onClickDelete={handleClickDelete} onClickDown={onDownFilterPriority} onClickEdit={handleClickEdit} onClickUp={onUpFilterPriority}/>
            </li>); })}
      </ul>

      {selectedFilter && (<CalendarIntegrationFilterFormModal categories={categories} defaultCategoryId={selectedFilter.category_id} defaultKeywords={selectedFilter.keywords_text} filterId={selectedFilter.id} filters={allFilters} isOpen={isOpenFilterFormModal} onClose={handleCloseFilterFormModal} onSubmit={handleUpdateFilter}/>)}

      {selectedFilter && (<WorkspacesCalendarIntegrationDeleteFilterModal filter={selectedFilter} isOpen={isOpenDeleteModal} onClose={handleClickCloseDeleteModal} onSubmit={handleSubmit}/>)}
    </>);
};
