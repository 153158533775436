import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
import { numberWithDelimiter } from '@/utils/number_helper';
export var chargeStatusLabel = function (_a) {
    var paid = _a.paid, refunded = _a.refunded;
    if (refunded) {
        return I18n.t('shared.refunded');
    }
    else {
        if (paid) {
            return I18n.t('teamManagement.billingForm.paid');
        }
        else {
            return I18n.t('teamManagement.billingForm.unpaid');
        }
    }
};
export var ChargeList = function (_a) {
    var apiBase = _a.apiBase, charges = _a.charges, onClickLink = _a.onClickLink;
    return (<div className="row no-gutters w-100">
      <div className="col-12">
        <hr className="my-3"/>
        <p className="h3 my-3">{I18n.t('payment.history')}</p>
        <p className="text-muted mb-3">{I18n.t('shared.notesForCharges')}</p>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{I18n.t('teamManagement.billingForm.date')}</th>
              <th>{I18n.t('teamManagement.billingForm.status')}</th>
              <th>{I18n.t('teamManagement.billingForm.amount')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {charges.map(function (charge) {
            return (<tr key={"charge-".concat(charge.id)}>
                  <td>
                    {moment(charge.period_started_at).format('YYYY-MM-DD (ddd)')}
                    &nbsp;-&nbsp;
                    {moment(charge.period_stopped_at).format('YYYY-MM-DD (ddd)')}
                  </td>
                  <td>
                    <span>{chargeStatusLabel(charge)}</span>
                  </td>
                  <td>
                    {I18n.t('shared.currencyUnit')}
                    {numberWithDelimiter(charge.amount)}
                    {charge.refunded && (<span className="ml-1 text-danger">
                        (-{I18n.t('shared.currencyUnit')}
                        {numberWithDelimiter(charge.amount_refunded)})
                      </span>)}
                  </td>
                  <td className="text-right">
                    <a href={"".concat(apiBase, "/charges/").concat(charge.id, "/receipt")} onClick={onClickLink}>
                      {I18n.t('teamManagement.billingForm.receipt')}
                    </a>
                  </td>
                </tr>);
        })}
          </tbody>
        </table>
      </div>
    </div>);
};
