import { __assign } from "tslib";
import React from 'react';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import { UncontrolledTooltip } from 'reactstrap';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import Icon from '@/components/atoms/icon';
var INITIAL_EVENTS = [
    { index: 0, name: 'entry_start', selected: false },
    { index: 1, name: 'entry_stop', selected: false },
    { index: 2, name: 'entry_create', selected: false },
    { index: 3, name: 'entry_edit', selected: false },
    { index: 4, name: 'entry_delete', selected: false },
];
export var WebHookForm = function (_a) {
    var team = _a.team, webHooks = _a.webHooks, onCreate = _a.onCreate, onDelete = _a.onDelete;
    var _b = React.useState(''), url = _b[0], setUrl = _b[1];
    var _c = React.useState(INITIAL_EVENTS), events = _c[0], setEvents = _c[1];
    var tooltipRef = React.useRef(null);
    var handleUrl = function (e) {
        setUrl(e.target.value);
    };
    var handleEvents = function (newEvent) {
        var newEvents = events.map(function (event) {
            if (event.index === newEvent.index) {
                return __assign(__assign({}, event), { selected: !event.selected });
            }
            else {
                return event;
            }
        });
        setEvents(newEvents);
    };
    var handleSubmit = function () {
        var params = {
            web_hook: {
                url: url,
                events: events
                    .filter(function (event) {
                    return event.selected;
                })
                    .map(function (event) {
                    return event.name;
                }),
            },
        };
        onCreate(team.id, params).then(function (webHook) {
            if (webHook.id) {
                setUrl('');
                setEvents(INITIAL_EVENTS);
            }
        });
    };
    var handleRemoveWebHook = function (id) {
        onDelete(team.id, id);
    };
    var disabled = isEmpty(url) || isEmpty(events.filter(function (event) { return event.selected; }));
    return (<div className="w-100 row no-gutters">
      <div className="col-12">
        <div className="form-group row">
          <label className="col-3 col-form-label" htmlFor="teamWebHook">
            {I18n.t('webHook.url')}
          </label>
          <div className="col-9 col-xl-7">
            <div className="input-group mb-2">
              <input className="form-control" type="text" value={url} onChange={handleUrl}/>
              <span className="input-group-btn">
                <button className="btn btn-primary" disabled={disabled} onClick={handleSubmit}>
                  {I18n.t('shared.create')}
                </button>
              </span>
            </div>
            {events.map(function (event) {
            var translationKey = camelCase("event_".concat(event.name));
            return (<CustomCheck key={event.index} checked={event.selected} className="d-block mb-1" onChange={function () { return handleEvents(event); }}>
                  {I18n.t("webHook.".concat(translationKey))}
                </CustomCheck>);
        })}
          </div>
        </div>
      </div>
      <div className="col-12">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th>{I18n.t('webHook.url')}</th>
              <th>{I18n.t('webHook.events')}</th>
              <th>
                {I18n.t('webHook.secret')}
                <span ref={tooltipRef} className="helplink">
                  <Icon name="question"/>
                </span>
                <UncontrolledTooltip fade={false} target={tooltipRef}>
                  {I18n.t('webHook.secretHelp')}
                </UncontrolledTooltip>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {webHooks.map(function (webHook) {
            return (<tr key={"webHook-".concat(webHook.id)}>
                  <td>{webHook.url}</td>
                  <td>
                    {webHook.events
                    .map(function (event) {
                    var translationKey = camelCase("event_".concat(event));
                    return I18n.t("webHook.".concat(translationKey));
                })
                    .join(', ')}
                  </td>
                  <td>
                    <code>{webHook.secret}</code>
                  </td>
                  <td className="text-right">
                    <button className="btn btn-secondary btn-sm" onClick={function () { return handleRemoveWebHook(webHook.id); }}>
                      {I18n.t('shared.remove')}
                    </button>
                  </td>
                </tr>);
        })}
          </tbody>
        </table>
      </div>
    </div>);
};
