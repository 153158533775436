import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryTaskTree } from '@/modules/category_task_trees';
import { changeCategoryPositionLowerSuccess, changeCategoryPositionHigherSuccess, } from '@/modules/categories';
import { updateCategory } from '@/api2/categories';
import { fetchCategories } from '@/actions/category';
import CategoryList from '@/components/team_management/category_list';
var CategoryListContainer = function (_a) {
    var categories = _a.categories, copyTeamCategory = _a.copyTeamCategory, createTeamCategory = _a.createTeamCategory, fetchCustomFieldsValues = _a.fetchCustomFieldsValues, moveHigherCategoryPosition = _a.moveHigherCategoryPosition, moveLowerCategoryPosition = _a.moveLowerCategoryPosition, setNotificationMessage = _a.setNotificationMessage, team = _a.team, updateTeamCategory = _a.updateTeamCategory;
    var categoryTaskTree = useSelector(function (state) { return state.categoryTaskTrees.tree; });
    var customFieldsValues = useSelector(function (state) { return state.customFieldsValues; });
    var customFieldsMasters = useSelector(function (state) { return state.customFieldsMasters; });
    var customFieldsMastersItems = React.useMemo(function () {
        return customFieldsValues.statusByTeamId[team.id] === 'loaded'
            ? customFieldsMasters.items.filter(function (_a) {
                var team_id = _a.team_id;
                return team_id === team.id;
            })
            : [];
    }, [customFieldsMasters.items, customFieldsValues.statusByTeamId, team.id]);
    var dispatch = useDispatch();
    var handleFetchCategoryTaskTree = React.useCallback(function (categoryId, withArchived) {
        dispatch(fetchCategoryTaskTree(categoryId, withArchived));
    }, [dispatch]);
    var handleUpdateCategoryPosition = React.useCallback(function (category, beforePosition) {
        if (beforePosition > category.position) {
            dispatch(changeCategoryPositionLowerSuccess(category, beforePosition));
        }
        else if (beforePosition < category.position) {
            dispatch(changeCategoryPositionHigherSuccess(category, beforePosition));
        }
    }, [dispatch]);
    var handleUpdateCategory = React.useCallback(function (id, params) {
        dispatch(updateCategory(id, params)).then(function (response) {
            if (response.error) {
                dispatch(fetchCategories({ team_id: params.team_id }));
            }
        });
    }, [dispatch]);
    return (<CategoryList categories={categories} categoryTaskTree={categoryTaskTree} copyTeamCategory={copyTeamCategory} createTeamCategory={createTeamCategory} customFieldsMasters={customFieldsMastersItems} customFieldsValues={customFieldsValues.items} fetchCategoryTaskTree={handleFetchCategoryTaskTree} fetchCustomFieldsValues={fetchCustomFieldsValues} moveHigherCategoryPosition={moveHigherCategoryPosition} moveLowerCategoryPosition={moveLowerCategoryPosition} setNotificationMessage={setNotificationMessage} team={team} updateTeamCategory={updateTeamCategory} onUpdateCategory={handleUpdateCategory} onUpdateCategoryPosition={handleUpdateCategoryPosition}/>);
};
export default CategoryListContainer;
