import React from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import I18n from '@/constants/i18n';
import { BREAKPOINT_MD } from '@/constants/styles';
import Icon from '@/components/atoms/icon';
import MobileHeader from '@/components/atoms/mobile_header';
import MobilePage from '@/components/molecules/mobile_page';
import Subpane from '@/components/molecules/subpane';
import MainMenuContainer from '@/containers/main_menu_container';
import FormNav from '@/components/atoms/form_nav';
import WorkspacesSettingsSubpaneContainer from '@/containers/workspaces_settings_subpane_container';
import { InlineAlert } from '@/components/inline_alert';
export var WorkspacesSettingsLayout = function (_a) {
    var children = _a.children, _b = _a.errorMessages, errorMessages = _b === void 0 ? [] : _b, title = _a.title, active = _a.active, _c = _a.saveButtonForPC, saveButtonForPC = _c === void 0 ? null : _c, _d = _a.saveButtonForSP, saveButtonForSP = _d === void 0 ? null : _d;
    var _e = React.useState(false), isOpenSubpane = _e[0], setIsOpenSubpane = _e[1];
    return (<div className="content-wrap">
      <MediaQuery className="d-flex w-100" minWidth={BREAKPOINT_MD}>
        <MainMenuContainer />
        <div className="content-main">
          <div className="row no-gutters">
            <div className="col-md-9 col-12 setting-container px-5">
              <FormNav>
                <div className="d-flex w-100">
                  <div className="form-title">{title}</div>
                  <div className="ml-auto">
                    {saveButtonForPC !== null && saveButtonForPC}
                  </div>
                </div>
              </FormNav>
              <InlineAlert className="mb-3" errorMessages={errorMessages}/>
              {children}
            </div>
            <Subpane className="col-md-3 col-12">
              <WorkspacesSettingsSubpaneContainer active={active}/>
            </Subpane>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={BREAKPOINT_MD}>
        <MobilePage>
          {isOpenSubpane && (<MobileHeader>
              <Link className="btn btn-link btn-mobile-header-action btn-mobile-header-action-left" to="/app">
                <Icon name="chevron-left"/>
              </Link>
              <span className="mobile-header-title">
                {I18n.t('shared.generalSettings')}
              </span>
              <div className="w-28"/>
            </MobileHeader>)}
          {isOpenSubpane && (<WorkspacesSettingsSubpaneContainer active={active} onNavigate={function () { return setIsOpenSubpane(false); }}/>)}
          {!isOpenSubpane && (<div className="px-2 py-3">
              <MobileHeader>
                <button className="btn-mobile-header-action btn btn-link" onClick={function () { return setIsOpenSubpane(true); }}>
                  <Icon name="chevron-left"/>
                </button>
                <span className="mobile-header-title">{title}</span>
                <span>{saveButtonForSP !== null && saveButtonForSP}</span>
              </MobileHeader>
              <InlineAlert className="mb-3" errorMessages={errorMessages}/>
              {children}
            </div>)}
        </MobilePage>
      </MediaQuery>
    </div>);
};
