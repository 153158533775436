import React from 'react';
import { useSelector } from 'react-redux';
import I18n from '@/constants/i18n';
import { RecentCategories } from '@/components/molecules/recent_categories';
export var RecentCategoriesContainer = function (_a) {
    var recentCategories = _a.recentCategories, setCategory = _a.setCategory;
    var user = useSelector(function (state) { return state.user; });
    var calendarUser = useSelector(function (state) { return state.calendar.user; });
    if (!recentCategories.length) {
        return null;
    }
    var label = calendarUser && calendarUser.id !== user.id
        ? I18n.t('shared.recentCategoriesProxy', {
            nickname: calendarUser.nickname,
        })
        : I18n.t('shared.recentCategories');
    return (<RecentCategories label={label} recentCategories={recentCategories} setCategory={setCategory}/>);
};
