import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOverlapTimeEntries, resetOverlapTimeEntries, } from '@/modules/overlap_time_entries';
import { isOverlap } from '@/utils/time_entry_helper';
import Timespan from '@/components/timespan';
var TaskFormTimespanContainer = function (_a) {
    var disabled = _a.disabled, isMine = _a.isMine, isProgress = _a.isProgress, nextTimeEntry = _a.nextTimeEntry, prevTimeEntry = _a.prevTimeEntry, timeEntry = _a.timeEntry, onChange = _a.onChange;
    var timeEntryId = timeEntry.id, start = timeEntry.started_at, stop = timeEntry.stopped_at, inputType = timeEntry.input_type;
    var overlapTimeEntries = useSelector(function (state) { return state.overlapTimeEntries; });
    var normalizedSecOverlapTimeEntries = React.useMemo(function () {
        return overlapTimeEntries.items.filter(function (item) {
            return isOverlap(timeEntry, item);
        });
    }, [overlapTimeEntries.items, timeEntry]);
    var dispatch = useDispatch();
    React.useEffect(function () {
        if (!timeEntryId) {
            return;
        }
        var params = {
            time_entry_id: timeEntryId,
            started_at: start.unix(),
            stopped_at: stop === null || stop === void 0 ? void 0 : stop.unix(),
        };
        dispatch(fetchOverlapTimeEntries(params));
        return function () {
            dispatch(resetOverlapTimeEntries());
        };
    }, [dispatch, start, stop, timeEntryId]);
    return (<Timespan disabled={disabled} inputType={inputType} isMine={isMine} isProgress={isProgress} nextTimeEntry={nextTimeEntry} overlapTimeEntries={normalizedSecOverlapTimeEntries} prevTimeEntry={prevTimeEntry} start={start} stop={stop} onChange={onChange}/>);
};
export default TaskFormTimespanContainer;
