import { connect } from 'react-redux';
import AssetTable from '@/components/export/asset_table';
import { expandAssets, fetchAsset } from '@/modules/assets';
var mapStateToProps = function (state) { return ({
    assets: state.assets.items,
    next: state.assets.next,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onExpand: function (next) {
        dispatch(expandAssets(next));
    },
    fetchAsset: function (id) {
        dispatch(fetchAsset(id));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AssetTable);
