import { __assign } from "tslib";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter, Navigate, Route, Routes, } from 'react-router-dom';
import { applyMiddleware, legacy_createStore, combineReducers, compose, } from 'redux';
import reducers from '../reducers';
import routes from '../constants/routes';
import Layout from './layout';
import connect from './connect';
import { redirectsForChromeExtension } from '@/constants/redirects';
var isDev = process.env.NODE_ENV === 'development';
var composeEnhancers = 
// @ts-ignore
isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
export default (function (_a) {
    var initialState = _a.initialState, useHashRouter = _a.useHashRouter, _b = _a.middleware, middleware = _b === void 0 ? [] : _b;
    var store = legacy_createStore(combineReducers(__assign({}, reducers)), initialState, composeEnhancers(applyMiddleware.apply(void 0, middleware)));
    var Router = useHashRouter ? HashRouter : BrowserRouter;
    return {
        store: store,
        render: function () {
            var container = createRoot(document.getElementById('root'));
            container.render(<Provider store={store}>
          <Router>
            <Routes>
              {/* NOTE: 古いRouteをリダイレクト */}
              <Route element={<Navigate replace to="/app/personal_settings/calendar_integration"/>} path="/app/personal_settings/google_calendar"/>

              {/* NOTE: モバイルアプリ */}
              <Route element={<Navigate replace to="/app"/>} path="/"/>
              <Route element={<>Healthcheck OK</>} path="/healthcheck/app"/>
              <Route path="/app">
                {routes.map(function (route) {
                    var Connected = connect(route);
                    return (<Route key={route.path} element={
                        // @ts-ignore Layoutのjsで型エラーを回避できないため
                        <Layout>
                          <Connected />
                        </Layout>} path={route.path}/>);
                })}
              </Route>

              {redirectsForChromeExtension.map(function (_a) {
                    var from = _a.from, to = _a.to;
                    return (<Route key={from} element={<Navigate replace to={to}/>} path={from}/>);
                })}
            </Routes>
          </Router>
        </Provider>);
        },
    };
});
