import React from 'react';
import I18n from '@/constants/i18n';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import Icon from '@/components/atoms/icon';
import { categoryWithAncestors } from '@/utils/category_helper';
export var TaskFormStaticCategoryField = function (_a) {
    var category = _a.category, mode = _a.mode, readOnly = _a.readOnly, archived = _a.archived;
    if (mode !== 'update' || (!readOnly && !archived)) {
        return null;
    }
    return (<div className="text-muted small my-1 pl-2">
      <CategoryCircleIcon color={category.color}/>
      <span>
        {categoryWithAncestors(category)} - {category.team.name}
      </span>
      {archived && (<span className="ml-1 badge badge-info">
          <Icon className="mr-1" name="archive" size="sm"/>
          {I18n.t('shared.archived')}
        </span>)}
    </div>);
};
