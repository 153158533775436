import { __assign } from "tslib";
import { connect } from 'react-redux';
import TeamChildrenForm from '@/components/team_children/team_children_form';
import { updateTeam } from '../../modules/teams';
var mapStateToProps = function (state, ownProps) { return (__assign({}, ownProps)); };
var mapDispatchToProps = function (dispatch) { return ({
    onUpdate: function (id, params) {
        dispatch(updateTeam(id, params));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TeamChildrenForm);
