import api from '@/api';
import { fetchCurrentUser } from '@/modules/current_user';
export var updateUser = function (params) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "user", 'PATCH', params).then(function (user) {
            if (!user.error) {
                dispatch(fetchCurrentUser());
            }
            return user;
        });
    };
};
export var signOut = function () {
    return function (dispatch, getState) {
        var clearUser = getState().clearUser;
        return clearUser(dispatch);
    };
};
export var destroyUser = function () {
    return function (dispatch, getState) {
        return api(dispatch, getState, "user", 'DELETE');
    };
};
