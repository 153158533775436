import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { padZero } from '@/utils/number_helper';
export var formatTime = function (entry, totalTimeSec) {
    var started = moment(entry.started_at);
    var stopped = moment();
    if (stopped.isBefore(started)) {
        stopped = started;
    }
    else if (totalTimeSec) {
        stopped = stopped.add(totalTimeSec, 'seconds');
    }
    var duration = stopped.diff(started, 'seconds');
    var h = Math.floor(duration / 3600);
    var m = Math.floor((duration - h * 3600) / 60);
    var s = duration - h * 3600 - m * 60;
    return "".concat(padZero(h), ":").concat(padZero(m), ":").concat(padZero(s));
};
