import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
function OmitLabel(_a) {
    var _b = _a.maxWidth, maxWidth = _b === void 0 ? '1rem' : _b, _c = _a.withTooltip, withTooltip = _c === void 0 ? false : _c, children = _a.children;
    var tooltipRef = React.useRef(null);
    return (<>
      <div ref={tooltipRef} style={{
            maxWidth: maxWidth,
            overflowX: 'hidden',
        }}>
        <div className="text-overflow">{children}</div>
      </div>
      {withTooltip && (<UncontrolledTooltip fade={false} target={tooltipRef}>
          {children}
        </UncontrolledTooltip>)}
    </>);
}
export default OmitLabel;
