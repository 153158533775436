import { __assign } from "tslib";
import api2 from '@/api2';
import some from 'lodash/some';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
export var FETCH_DAILY_ACTIVITY_REQUEST = 'timecrowd/daily_activities/FETCH_DAILY_ACTIVITY_REQUEST';
export var FETCH_DAILY_ACTIVITY_SUCCESS = 'timecrowd/daily_activities/FETCH_DAILY_ACTIVITY_SUCCESS';
export var EXPAND_DAILY_ACTIVITY_REQUEST = 'timecrowd/daily_activities/EXPAND_DAILY_ACTIVITY_REQUEST';
export var EXPAND_DAILY_ACTIVITY_SUCCESS = 'timecrowd/daily_activities/EXPAND_DAILY_ACTIVITY_SUCCESS';
export var UPDATE_DAILY_ACTIVITY_REQUEST = 'timecrowd/daily_activities/UPDATE_DAILY_ACTIVITY_REQUEST';
export var UPDATE_DAILY_ACTIVITY_SUCCESS = 'timecrowd/daily_activities/UPDATE_DAILY_ACTIVITY_SUCCESS';
export var RESET_DAILY_ACTIVITIES = 'timecrowd/daily_activities/RESET_DAILY_ACTIVITIES';
export var fetchDailyActivity = function (date) {
    if (date === void 0) { date = ''; }
    return function (dispatch, getState) {
        dispatch(fetchDailyActivityRequest());
        return api2(dispatch, getState, "/api/v2/daily_activity?date=".concat(date), 'GET').then(function (response) {
            if (response.ok) {
                response.json().then(function (activity) {
                    dispatch(fetchDailyActivitySuccess(activity));
                });
            }
        });
    };
};
export var expandDailyActivity = function (date) {
    if (date === void 0) { date = ''; }
    return function (dispatch, getState) {
        dispatch(expandDailyActivityRequest());
        return api2(dispatch, getState, "/api/v2/daily_activity?date=".concat(date), 'GET').then(function (response) {
            if (response.ok) {
                response.json().then(function (activity) {
                    dispatch(expandDailyActivitySuccess(activity));
                });
            }
        });
    };
};
export var updateDailyActivity = function (date) {
    if (date === void 0) { date = ''; }
    return function (dispatch, getState) {
        dispatch(updateDailyActivityRequest());
        return api2(dispatch, getState, "/api/v2/daily_activity?date=".concat(date), 'GET').then(function (response) {
            if (response.ok) {
                response.json().then(function (activity) {
                    dispatch(updateDailyActivitySuccess(activity));
                });
            }
        });
    };
};
function updateActivtyList(activities, action) {
    var hasActivity = some(activities, { date: action.activity.date });
    if (hasActivity) {
        return activities.map(function (activity) {
            return activity.date === action.activity.date
                ? action.activity
                : activity;
        });
    }
    else {
        return orderBy(activities.concat(action.activity), ['date'], ['desc']);
    }
}
var initialState = {
    activities: [],
    isLoading: false,
    tasks: [],
};
export default function dailyActivities(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_DAILY_ACTIVITY_REQUEST:
        case EXPAND_DAILY_ACTIVITY_REQUEST:
        case UPDATE_DAILY_ACTIVITY_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_DAILY_ACTIVITY_SUCCESS:
            return __assign(__assign({}, state), { activities: [action.activity], isLoading: false, tasks: action.activity.tasks });
        case EXPAND_DAILY_ACTIVITY_SUCCESS:
            return __assign(__assign({}, state), { activities: orderBy(uniqBy(state.activities.concat(action.activity), 'date'), ['date'], ['desc']), isLoading: false, tasks: state.tasks.concat(action.activity.tasks) });
        case UPDATE_DAILY_ACTIVITY_SUCCESS:
            return __assign(__assign({}, state), { activities: updateActivtyList(state.activities, action), isLoading: false, tasks: updateActivtyList(state.activities, action).reduce(function (total, activity) {
                    return total.concat(activity.tasks);
                }, []) });
        case RESET_DAILY_ACTIVITIES:
            return __assign(__assign({}, state), { activities: [] });
        default:
            return state;
    }
}
export var fetchDailyActivityRequest = function () { return ({
    type: FETCH_DAILY_ACTIVITY_REQUEST,
}); };
export var fetchDailyActivitySuccess = function (activity) { return ({
    type: FETCH_DAILY_ACTIVITY_SUCCESS,
    activity: activity,
}); };
export var expandDailyActivityRequest = function () { return ({
    type: EXPAND_DAILY_ACTIVITY_REQUEST,
}); };
export var expandDailyActivitySuccess = function (activity) { return ({
    type: EXPAND_DAILY_ACTIVITY_SUCCESS,
    activity: activity,
}); };
export var updateDailyActivityRequest = function () { return ({
    type: UPDATE_DAILY_ACTIVITY_REQUEST,
}); };
export var updateDailyActivitySuccess = function (activity) { return ({
    type: UPDATE_DAILY_ACTIVITY_SUCCESS,
    activity: activity,
}); };
export var resetDailyActivities = function () { return ({
    type: RESET_DAILY_ACTIVITIES,
}); };
