import { __assign } from "tslib";
import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import InfiniteCalendar from 'react-infinite-calendar';
import I18n from '@/constants/i18n';
import { formatDateWithLocale, beforeDay } from '@/utils/time_helper';
import { Link } from 'react-router-dom';
var TeamEmployForm = function (props) {
    var employ = props.employ, minDate = props.minDate, maxDate = props.maxDate, onChangeEmploy = props.onChangeEmploy, onDelete = props.onDelete, index = props.index, nextEmploy = props.nextEmploy, isCurrent = props.isCurrent, currentWorkspace = props.currentWorkspace, disabled = props.disabled;
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var toggleCalendar = function () {
        setIsOpen(!isOpen);
    };
    var onChangeRate = function (e) {
        if (Number(e.target.value) < 0 || 9999999 < Number(e.target.value)) {
            return;
        }
        var newEmploy = __assign(__assign({}, employ), { rate: e.target.value });
        onChangeEmploy(newEmploy);
    };
    var onSelectDate = function (date) {
        var started_at = date.toString();
        var newEmploy = __assign(__assign({}, employ), { started_at: started_at });
        onChangeEmploy(newEmploy);
        toggleCalendar();
    };
    var onClickDelete = function () {
        onDelete(employ);
    };
    var fromWorkspace = !!employ.workspaces_workspace_id;
    return (<div className={"form-group w-100 ".concat(nextEmploy ? 'mb-1' : 'mb-0', " ").concat(disabled ? 'opacity-30' : '')}>
      <div className="form-inline">
        <div className="d-flex w-40">
          <label className="mr-1 font-weight-bold">
            {I18n.t('shared.currencyUnit')}
          </label>
          {fromWorkspace ? (<span className="font-weight-normal">{employ.rate}</span>) : (<input className="form-control" min="0" type="number" value={employ.rate} onChange={onChangeRate}/>)}
        </div>
        <span className="mr-1 ml-2 text-muted">/ {I18n.t('shared.hours')}</span>
        {fromWorkspace ? (<span className="ml-2">
            {index < 1 && nextEmploy
                ? I18n.t('shared.toDate', {
                    date: formatDateWithLocale(beforeDay(nextEmploy.started_at, 1)),
                })
                : I18n.t('shared.fromDate', {
                    date: formatDateWithLocale(employ.started_at),
                })}
          </span>) : (<ButtonDropdown className="ml-2" isOpen={isOpen} toggle={toggleCalendar}>
            <DropdownToggle caret>
              {I18n.t('shared.fromDate', {
                date: formatDateWithLocale(employ.started_at),
            })}
            </DropdownToggle>
            <DropdownMenu>
              <InfiniteCalendar className="calendar-sm" displayOptions={{
                showHeader: true,
                showTodayHelper: false,
                showWeekdays: false,
                shouldHeaderAnimate: true,
            }} height={276} locale={I18n.t('infiniteCalendar')} maxDate={maxDate} minDate={minDate} rowHeight={48} selected={new Date(employ.started_at)} theme={{
                floatingNav: {
                    chevron: '#4abaa4',
                },
            }} width="100%" onSelect={onSelectDate}/>
            </DropdownMenu>
          </ButtonDropdown>)}
        {isCurrent && (<span className="badge badge-success opacity-50 ml-2 rounded-0">
            {I18n.t('shared.currentRate')}
          </span>)}
        {!fromWorkspace && (<div className="ml-auto">
            <button className="btn btn-link text-danger" onClick={onClickDelete}>
              {I18n.t('shared.remove')}
            </button>
          </div>)}
        {!disabled && fromWorkspace && currentWorkspace.role === 'owner' && (<div className="ml-auto">
            <Link className="btn btn-link" to={"/app/workspaces/".concat(currentWorkspace.id, "/members?user_id=").concat(employ.user_id)}>
              {I18n.t('shared.change')}
            </Link>
          </div>)}
      </div>
      {fromWorkspace && (<div className="mt-1">
          <span className="badge badge-info opacity-50 align-top">
            {I18n.t('shared.fromWorkspace')}
          </span>
          <small className="text-muted ml-2">
            {I18n.t('shared.fromWorkspaceDescription')}
          </small>
        </div>)}
      {!fromWorkspace && (<div className="mt-1">
          <span className="badge badge-primary opacity-50 align-top">
            {I18n.t('shared.inTeam')}
          </span>
        </div>)}
    </div>);
};
export default TeamEmployForm;
