import React from 'react';
import some from 'lodash/some';
import ListGroupItemCheck from '@/components/atoms/list_group_item_check';
import { sortByEachRoots } from '@/modules/categories';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
var Circle = function (_a) {
    var color = _a.color;
    return (<i className={"category-circle category-".concat(color)} style={{ top: 0 }}/>);
};
export var CategoryCheckList = function (props) {
    var team = props.team, selectedCategories = props.selectedCategories, onChange = props.onChange, _a = props.disabled, disabled = _a === void 0 ? false : _a;
    return (<ul className="list-group list-group-flush">
      {sortByEachRoots(team.categories).map(function (category) {
            var checked = some(selectedCategories, {
                id: category.id,
            });
            return (<ListGroupItemCheck key={"category-".concat(category.id)} checked={checked} disabled={disabled} style={{
                    borderBottom: 0,
                    paddingLeft: "".concat(category.ancestry_depth * 1.2, "rem"),
                }} onChange={function () { return onChange(team, category, checked); }}>
            <div className="d-flex align-items-center">
              <Circle color={category.color}/>
              <div className="text-overflow" title={category.title}>
                {category.title}
              </div>
              {category.archived && (<span className="badge badge-info ml-auto">
                  <Icon className="mr-1" name="archive" size="sm"/>
                  {I18n.t('shared.archived')}
                </span>)}
            </div>
          </ListGroupItemCheck>);
        })}
    </ul>);
};
