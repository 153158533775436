import { __assign, __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@/hooks/use_query';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
import { fetchCalendar } from '@/modules/calendar';
import { fetchCalendarIntegrationEvents } from '@/modules/calendar_integration_events';
import { fetchCalendarIntegrationSetting } from '@/modules/calendar_integration_settings';
import { fetchCalendarIntegrationFilters } from '@/modules/calendar_integration_filters';
import { setNotificationMessage } from '@/modules/notification';
import { fetchUserWorkspacesCalendarIntegrationSettings } from '@/modules/workspaces_calendar_integration_settings';
import { fetchUserWorkspacesCalendarIntegrationFilters } from '@/modules/workspaces_calendar_integration_filters';
import variants from '@/constants/variants';
import { workspaceTeams } from '@/utils/workspace_helper';
import { Calendar } from '@/components/calendar';
var CalendarContainer = function (props) {
    var setCalendarView = props.setCalendarView, _a = props.loadStorage, loadStorage = _a === void 0 ? variants.loadStorage : _a, calendar = props.calendar, myTask = props.myTask, currentEntry = props.currentEntry, isMobile = props.isMobile, isApp = props.isApp, isPC = props.isPC, switchViewMode = props.switchViewMode, isChromeExtension = props.isChromeExtension, user = props.user, calendarIntegrationEvents = props.calendarIntegrationEvents, taskSearch = props.taskSearch, stopTimeEntry = props.stopTimeEntry, setCurrentEntry = props.setCurrentEntry, _b = props.saveStorage, saveStorage = _b === void 0 ? variants.saveStorage : _b, teams = props.teams, setActiveTaskForm = props.setActiveTaskForm;
    var currentCategory = myTask.currentCategory;
    var query = useQuery();
    var queryDate = query.date;
    var _c = React.useState(moment(queryDate).startOf('day').toDate()), selectedDate = _c[0], setSelectedDate = _c[1];
    var _d = React.useState({
        page: 0,
        initialScrollTo: 9,
        divisionHeightRate: user.calendar_zoom_rate,
        isClosedTimeZoneAlert: false,
    }), state = _d[0], setState = _d[1];
    var navigate = useNavigate();
    var activeTaskForm = myTask.activeTaskForm;
    var initialScrollTo = state.initialScrollTo, divisionHeightRate = state.divisionHeightRate;
    var isOpenQuickTask = activeTaskForm === 'quicktask';
    var isOpenTimelineTaskForm = activeTaskForm !== -1 && !isOpenQuickTask;
    var isStarted = !!currentEntry;
    var osUTCOffset = moment.tz(moment.tz.guess()).format('Z');
    var isNoTeam = teams.isFetched && workspaceTeams(teams.items).length < 1;
    var dispatch = useDispatch();
    var fetchCalendarIntegrationSettings = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var integration, settings, setting, filters_1, filters;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(fetchCalendarIntegrationSetting())];
                case 1:
                    integration = _a.sent();
                    if (!(integration.item && integration.item.base_type === 'workspace')) return [3 /*break*/, 4];
                    // 個人設定の外部カレンダー同期に設定していたワークスペースを離脱したケース
                    if (!integration.item.workspaces_workspace_id) {
                        dispatch(setNotificationMessage('danger', I18n.t('shared.pleaseConfigureWorkspace') +
                            "<a class=\"alert-link\" href=\"/app/personal_settings/calendar_integration\">".concat(I18n.t('shared.goToSettings'), "</a>"), { isHtml: true }));
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, dispatch(fetchUserWorkspacesCalendarIntegrationSettings())];
                case 2:
                    settings = _a.sent();
                    if (settings.error) {
                        return [2 /*return*/];
                    }
                    setting = settings.items.find(function (item) {
                        return item.workspaces_workspace_id ===
                            integration.item.workspaces_workspace_id;
                    });
                    return [4 /*yield*/, dispatch(fetchUserWorkspacesCalendarIntegrationFilters(setting.id))];
                case 3:
                    filters_1 = _a.sent();
                    if (isPC &&
                        setting.can_update &&
                        filters_1.items.find(function (_a) {
                            var category_id = _a.category_id, is_category_soft_destroyed = _a.is_category_soft_destroyed, is_category_archived = _a.is_category_archived;
                            return !category_id || is_category_soft_destroyed || is_category_archived;
                        })) {
                        dispatch(setNotificationMessage('danger', I18n.t('shared.pleaseConfigureWorkspacesCalendarIntegrationFiltersCategory') +
                            "<a class=\"alert-link\" href=\"/app/workspaces/".concat(setting.workspaces_workspace_id, "/calendar_integration\">").concat(I18n.t('shared.goToSettings'), "</a>"), { isHtml: true }));
                    }
                    return [2 /*return*/];
                case 4: return [4 /*yield*/, dispatch(fetchCalendarIntegrationFilters())];
                case 5:
                    filters = _a.sent();
                    if (isPC &&
                        integration.item &&
                        (integration.item.is_category_soft_destroyed ||
                            integration.item.is_category_archived ||
                            filters.items.find(function (_a) {
                                var category_id = _a.category_id, is_category_soft_destroyed = _a.is_category_soft_destroyed, is_category_archived = _a.is_category_archived;
                                return !category_id || is_category_soft_destroyed || is_category_archived;
                            }))) {
                        dispatch(setNotificationMessage('danger', I18n.t('shared.pleaseConfigureCategory') +
                            "<a class=\"alert-link\" href=\"/app/personal_settings/calendar_integration\">".concat(I18n.t('shared.goToSettings'), "</a>"), { isHtml: true }));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch, isPC]);
    React.useEffect(function () {
        fetchCalendarIntegrationSettings();
    }, [fetchCalendarIntegrationSettings]);
    React.useEffect(function () {
        loadStorage('calendarView', function (view) {
            if (view) {
                setCalendarView(view);
            }
        });
    }, [loadStorage, setCalendarView]);
    React.useEffect(function () {
        dispatch(fetchCalendar({ date: queryDate }));
        dispatch(fetchCalendarIntegrationEvents({ date: queryDate }));
    }, [dispatch, queryDate]);
    React.useEffect(function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { divisionHeightRate: user.calendar_zoom_rate })); });
    }, [user.calendar_zoom_rate]);
    var onStop = function (e) {
        e.stopPropagation();
        stopTimeEntry(currentEntry).then(function () {
            var date = moment(currentEntry.started_at);
            var selected = moment(selectedDate);
            if (date.isoWeek() === selected.isoWeek()) {
                dispatch(fetchCalendar({ date: date.format('YYYY-MM-DD') }));
            }
            setCurrentEntry(null);
            toggleTaskForm(-1);
        });
    };
    var onSelectCalendar = function (selected) {
        setActiveTaskForm(-1);
        setSelectedDate(selected);
        var date = moment(selected).format('YYYY-MM-DD');
        navigate("/app?date=".concat(date));
    };
    var onSelectView = function (view) {
        setCalendarView(view);
        dispatch(fetchCalendar({ date: queryDate }));
        saveStorage('calendarView', view);
    };
    var onOpenQuickTask = function () {
        setActiveTaskForm('quicktask');
    };
    var handleClickOverlay = function () {
        setActiveTaskForm(-1);
    };
    var toggleTaskForm = function (index) {
        if (!isPC) {
            var initialScrollTo_1 = index === -1 ? state.initialScrollTo : Number(index.split('-')[0]);
            setState(function (prevState) { return (__assign(__assign({}, prevState), { initialScrollTo: initialScrollTo_1 })); });
        }
        setActiveTaskForm(index);
    };
    var handleTouchMoveContainer = function (e) {
        if (isOpenQuickTask && (isMobile || isApp)) {
            e.preventDefault();
        }
    };
    var onClickChangeTimeZone = function (e) {
        e.preventDefault();
        navigate('/app/personal_settings/account');
    };
    var onCloseTimeZoneAlert = function () {
        return setState(function (prevState) { return (__assign(__assign({}, prevState), { isClosedTimeZoneAlert: true })); });
    };
    return (<Calendar activeTaskForm={activeTaskForm} calendar={calendar} calendarIntegrationEvents={calendarIntegrationEvents} currentCategory={currentCategory} currentEntry={currentEntry} divisionHeightRate={divisionHeightRate} handleClickOverlay={handleClickOverlay} handleTouchMoveContainer={handleTouchMoveContainer} initialScrollTo={initialScrollTo} isApp={isApp} isChromeExtension={isChromeExtension} isClosedTimeZoneAlert={state.isClosedTimeZoneAlert} isMobile={isMobile} isNoTeam={isNoTeam} isOpenQuickTask={isOpenQuickTask} isOpenTimelineTaskForm={isOpenTimelineTaskForm} isPC={isPC} isStarted={isStarted} osUTCOffset={osUTCOffset} selectedDate={selectedDate} switchViewMode={switchViewMode} taskSearch={taskSearch} toggleTaskForm={toggleTaskForm} user={user} onClickChangeTimeZone={onClickChangeTimeZone} onCloseTimeZoneAlert={onCloseTimeZoneAlert} onOpenQuickTask={onOpenQuickTask} onSelectCalendar={onSelectCalendar} onSelectView={onSelectView} onStop={onStop}/>);
};
export default CalendarContainer;
