import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BulkCreateTasksFormModal } from '@/components/organisms/bulk_create_tasks_form_modal';
import { bulkCreateTasks } from '@/modules/bulk_create_tasks';
import { setNotificationMessage, removeDangerNotificationMessage, } from '@/modules/notification';
import I18n from '@/constants/i18n';
var BulkCreateTasksContainer = function (_a) {
    var isOpen = _a.isOpen, categories = _a.categories, defaultSelectedCategories = _a.defaultSelectedCategories, defaultSelectedTeam = _a.defaultSelectedTeam, teams = _a.teams, onCreate = _a.onCreate, onToggle = _a.onToggle;
    var isLoading = useSelector(function (state) { return state.bulkCreateTasks.isLoading; });
    var dispatch = useDispatch();
    var handleBulkCreateTasks = function (params) {
        return dispatch(bulkCreateTasks(params)).then(function (_a) {
            var error = _a.error;
            if (!error) {
                onCreate();
                onToggle();
                return dispatch(setNotificationMessage('success', I18n.t('shared.tasksBulkCreated')));
            }
        });
    };
    var handleCloseModal = function () {
        dispatch(removeDangerNotificationMessage());
        onToggle();
    };
    return (<BulkCreateTasksFormModal categories={categories} defaultSelectedCategories={defaultSelectedCategories} defaultSelectedTeam={defaultSelectedTeam} isLoading={isLoading} isOpen={isOpen} teams={teams} onCreate={handleBulkCreateTasks} onToggle={handleCloseModal}/>);
};
export default BulkCreateTasksContainer;
