import { __assign } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { useParams } from 'react-router-dom';
import I18n from '@/constants/i18n';
import TaskForm from '@/components/task_form';
import { fetchCalendar } from '@/modules/calendar';
import { arrangeRecentCategories } from '@/modules/recent_categories';
import { setCurrentCategory } from '@/modules/my_task';
import { setFromPageTitle } from '@/modules/from_page_title';
import { freezeScroll } from '@/modules/display';
import { fetchSuggestTasks } from '@/actions/task';
import { fetchTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
import { createTask } from '@/modules/tasks';
import { createTimeEntry, createUserTimeEntry } from '@/modules/time_entries';
import { setNotificationMessage } from '@/modules/notification';
var dateFormat = 'YYYY-MM-DD';
var TaskFormTimelineEntryNewContainer = function (_a) {
    var requiredSelectedTask = _a.requiredSelectedTask, commentRef = _a.commentRef, date = _a.date, isFocus = _a.isFocus, isSearching = _a.isSearching, isTimespanChanged = _a.isTimespanChanged, mode = _a.mode, selectedTask = _a.selectedTask, setIsSearching = _a.setIsSearching, setSelectedTask = _a.setSelectedTask, timeEntry = _a.timeEntry, toggleTaskForm = _a.toggleTaskForm, onSelectCategory = _a.onSelectCategory;
    var category = useSelector(function (state) { return state.myTask.currentCategory; });
    var fromPageTitle = useSelector(function (state) { return state.fromPageTitle; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var isChromeExtension = useSelector(function (state) { return state.isChromeExtension; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var timeEntrySuggestions = useSelector(function (state) { return state.timeEntrySuggestions.items; });
    var user = useSelector(function (state) { return state.user; });
    var calendarUser = useSelector(function (state) { return state.calendar.user; });
    var user_id = useParams().user_id;
    var dispatch = useDispatch();
    var onStart = function () { };
    var handleCreateTimeEntry = function (task, timeEntry, comment) {
        var params = {
            time_entry: {
                started_at: timeEntry.started_at,
                stopped_at: timeEntry.stopped_at,
                time_trackable_id: task.id,
                input_type: user_id ? 'proxy' : 'manual',
            },
            comment: comment,
        };
        if (user_id) {
            return dispatch(createUserTimeEntry(user_id, params));
        }
        else {
            return dispatch(createTimeEntry(params));
        }
    };
    var onCreate = function (_teamId, task, timeEntry, comment) {
        var promise = handleCreateTimeEntry(task, timeEntry, comment);
        promise.then(function (newTimeEntry) {
            if (newTimeEntry.error) {
                return;
            }
            if (calendarUser && calendarUser.id !== user.id) {
                handleSetNotificationMessage('success', I18n.t('proxyInputUser.notification', {
                    nickname: calendarUser.nickname,
                }));
            }
            if (timeEntry) {
                var startedDate = moment(timeEntry.started_at).format(dateFormat);
                if (startedDate === date) {
                    dispatch(fetchCalendar({ date: startedDate, user_id: user_id }));
                }
            }
            toggleTaskForm(-1);
            var category = __assign(__assign({}, newTimeEntry.category), { team: newTimeEntry.team });
            dispatch(arrangeRecentCategories(category));
        });
        return promise;
    };
    var onCancel = function () {
        toggleTaskForm(-1);
    };
    var handleSelectCategory = function (category) {
        dispatch(setCurrentCategory(category));
        onSelectCategory(category);
    };
    var onSetFromPageTitle = function (fromPageTitle) {
        dispatch(setFromPageTitle(fromPageTitle));
    };
    var handleSetFreezeScroll = React.useCallback(function (isFreeze) {
        dispatch(freezeScroll(isFreeze));
    }, [dispatch]);
    var handleFetchSuggestTasks = function (category, title) {
        var params = {
            title: title,
            category_id: category.id,
            team_id: category.team_id,
        };
        dispatch(fetchSuggestTasks(params));
    };
    var handleFetchTimeEntrySuggestion = React.useCallback(function (time) {
        dispatch(fetchTimeEntrySuggestion(time));
    }, [dispatch]);
    var handleCreateTask = function (teamId, params) {
        return dispatch(createTask(teamId, params));
    };
    var handleSetNotificationMessage = function (type, message) {
        dispatch(setNotificationMessage(type, message));
    };
    return (<TaskForm isShowCancelButton category={category} commentRef={commentRef} createTask={handleCreateTask} fetchTimeEntrySuggestion={handleFetchTimeEntrySuggestion} fromPageTitle={fromPageTitle} isChromeExtension={isChromeExtension} isFocus={isFocus} isPC={isPC} isSearching={isSearching} isTimeEntrySpecified={true} isTimespanChanged={isTimespanChanged} mode={mode} openURL={openURL} requiredSelectedTask={requiredSelectedTask} selectedTask={selectedTask} setFreezeScroll={handleSetFreezeScroll} setIsSearching={setIsSearching} setNotificationMessage={handleSetNotificationMessage} setSelectedTask={setSelectedTask} tab="unselect" timeEntry={timeEntry} timeEntrySuggestions={timeEntrySuggestions} useDefaultDuration={false} onCancel={onCancel} onCreate={onCreate} onSelectCategory={handleSelectCategory} onSetFromPageTitle={onSetFromPageTitle} onStart={onStart} onSuggestTasks={handleFetchSuggestTasks}/>);
};
export default TaskFormTimelineEntryNewContainer;
