import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames';
import TaskFormTimelineEntryNewContainer from '@/containers/task_form_timeline_entry_new_container';
import { freezeScroll } from '@/modules/display';
import Backdrop from '@/components/atoms/backdrop';
import I18n from '@/constants/i18n';
var TimelineEntryNew = function (_a) {
    var height = _a.height, started_at = _a.started_at, stopped_at = _a.stopped_at, _b = _a.readOnly, readOnly = _b === void 0 ? false : _b, date = _a.date, isOpen = _a.isOpen, index = _a.index, draggedOn = _a.draggedOn, isApp = _a.isApp, isMobile = _a.isMobile, taskSearch = _a.taskSearch, currentCategory = _a.currentCategory, calendarUnitMinutes = _a.calendarUnitMinutes, toggleTaskForm = _a.toggleTaskForm, onDrag = _a.onDrag, onCancelDrag = _a.onCancelDrag;
    var _c = React.useState({
        started_at: started_at,
        stopped_at: stopped_at,
        draggingHeight: height,
        top: 0,
        baseClientY: 0,
    }), state = _c[0], setState = _c[1];
    var draggingHeight = state.draggingHeight, top = state.top, baseClientY = state.baseClientY;
    var _d = React.useState(null), task = _d[0], setTask = _d[1];
    var _e = React.useState(currentCategory), category = _e[0], setCategory = _e[1];
    var _f = React.useState(false), isSearching = _f[0], setIsSearching = _f[1];
    var _g = React.useState(false), isTimespanChanged = _g[0], setIsTimespanChanged = _g[1];
    var commentRef = React.useRef(null);
    // NOTE: reduxとの相性でReactstrapのModalがエラーになるため自前で管理
    React.useEffect(function () {
        if (isOpen) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
    }, [isOpen]);
    React.useEffect(function () {
        if (draggedOn !== index) {
            var ghost = document.querySelector(".ghost-".concat(index));
            if (ghost && ghost.parentNode) {
                ghost.parentNode.removeChild(ghost);
            }
        }
    }, [draggedOn, index]);
    React.useEffect(function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { draggingHeight: height })); });
    }, [height]);
    var exactMatchedTask = React.useMemo(function () {
        return taskSearch.items.find(function (t) {
            return t.title === taskSearch.params.title && t.category.id === (category === null || category === void 0 ? void 0 : category.id);
        });
    }, [category === null || category === void 0 ? void 0 : category.id, taskSearch.items, taskSearch.params.title]);
    React.useEffect(function () {
        if (exactMatchedTask && !task) {
            setTask(exactMatchedTask);
        }
    }, [exactMatchedTask, task]);
    React.useEffect(function () {
        if ((currentCategory === null || currentCategory === void 0 ? void 0 : currentCategory.id) !== (category === null || category === void 0 ? void 0 : category.id)) {
            setCategory(currentCategory);
            setState(function (prevState) { return (__assign(__assign({}, prevState), { started_at: started_at, stopped_at: stopped_at })); });
        }
    }, [category === null || category === void 0 ? void 0 : category.id, currentCategory, started_at, stopped_at]);
    React.useEffect(function () {
        if (taskSearch.isFetched) {
            setIsSearching(false);
        }
    }, [taskSearch.isFetched]);
    var handleClick = function () {
        setIsTimespanChanged(false);
        openTaskForm();
    };
    var handleDragEnd = function () {
        setIsTimespanChanged(true);
        openTaskForm();
    };
    var openTaskForm = function () {
        if (readOnly) {
            return;
        }
        toggleTaskForm(index);
        onDrag(index);
    };
    var closeTaskForm = function (index) {
        toggleTaskForm(index);
        onCancelDrag();
        setState({
            started_at: started_at,
            stopped_at: stopped_at,
            draggingHeight: height,
            top: 0,
            baseClientY: 0,
        });
    };
    var onDragStartTimeline = function (e) {
        // NOTE: アプリやモバイルでは長押しドラッグイベントが発火してしまうので要注意
        // 修正時点でモバイルやアプリではドラッグによる時間追加は考慮されていない
        if (isApp || isMobile || readOnly) {
            return;
        }
        var ghost = e.target.cloneNode(true);
        ghost.style.display = 'none';
        ghost.style.cursor = 'none';
        ghost.classList.add("ghost-".concat(index));
        document.body.appendChild(ghost);
        if (e.dataTransfer &&
            Object.prototype.hasOwnProperty.call(e.dataTransfer, 'setDragImage')) {
            // IE11 does not support `setDragImage`
            e.dataTransfer.setDragImage(ghost, 0, 0);
        }
        setState(__assign(__assign({}, state), { baseClientY: e.clientY }));
        toggleTaskForm(-1);
        onDrag(index);
    };
    var onDragTimeline = function (e) {
        if (e.clientY === 0) {
            return;
        }
        // NOTE: アプリやモバイルでは長押しドラッグイベントが発火してしまうので要注意
        // 修正時点でモバイルやアプリではドラッグによる時間追加は考慮されていない
        if (isApp || isMobile) {
            return;
        }
        var baseHeight = height;
        var volume = Math.ceil((e.clientY - baseClientY) / baseHeight);
        if (volume > 0) {
            setState(__assign(__assign({}, state), { started_at: started_at, stopped_at: state.started_at
                    .clone()
                    .add(calendarUnitMinutes * volume, 'm'), draggingHeight: baseHeight * volume, top: 0 }));
        }
        if (volume < 0) {
            var absVolume = Math.abs(volume);
            setState(__assign(__assign({}, state), { started_at: started_at
                    .clone()
                    .subtract(calendarUnitMinutes * absVolume, 'm'), stopped_at: stopped_at, draggingHeight: baseHeight + baseHeight * absVolume, top: baseHeight * volume }));
        }
    };
    var onCloseBackdrop = function (e) {
        e.stopPropagation();
        closeTaskForm(-1);
        freezeScroll(false);
    };
    var timeEntry = {
        started_at: state.started_at,
        stopped_at: state.stopped_at,
    };
    var cursor = readOnly ? 'auto' : 'pointer';
    var zIndex = 1200 - Number(index.split('-')[0]) - Number(index.split('-')[1]);
    return (<div className={classNames('timeline-division', {
            readonly: readOnly,
            dragged: draggedOn === index,
        })} data-title={I18n.t('calendar.addToTimelineWithTime', {
            time: started_at.format('HH:mm'),
        })} style={{
            height: height,
            cursor: cursor,
            position: 'relative',
        }}>
      <div className={classNames({
            dragged: draggedOn === index,
        })} draggable={!isOpen} style={{
            height: draggingHeight,
            top: top,
            zIndex: zIndex,
        }} onClick={handleClick} onDrag={onDragTimeline} onDragEnd={handleDragEnd} onDragOver={function (e) { return e.preventDefault(); }} // NOTE: onDragEndのアニメーションを無効化するため
     onDragStart={onDragStartTimeline}/>
      {isOpen && (<>
          <Backdrop />
          <div className="modal fade show d-block top-2" onClick={onCloseBackdrop}>
            <div className="modal-dialog modal-lg pt-3" onClick={function (e) { return e.stopPropagation(); }}>
              <div className="modal-content tw-cursor-default">
                <TaskFormTimelineEntryNewContainer requiredSelectedTask commentRef={commentRef} date={date} isFocus={false} isSearching={isSearching} isTimespanChanged={isTimespanChanged} mode="create" selectedTask={task} setIsSearching={setIsSearching} setSelectedTask={setTask} timeEntry={timeEntry} toggleTaskForm={closeTaskForm} onSelectCategory={function (category) { return setCategory(category); }}/>
              </div>
            </div>
          </div>
        </>)}
    </div>);
};
export default TimelineEntryNew;
