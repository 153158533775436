import { __awaiter, __generator } from "tslib";
import React from 'react';
import { saveAs } from 'file-saver';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import Helplink from '@/components/molecules/helplink';
import { PersonalSettingsLayout } from '@/components/personal_settings_layout';
import NotFound from '@/components/pages/not_found';
export var TwoFactorAuthForm = function (props) {
    var fetchTwoFactorAuth = props.fetchTwoFactorAuth, enableTwoFactorAuth = props.enableTwoFactorAuth, disableTwoFactorAuth = props.disableTwoFactorAuth, _a = props.twoFactorAuth, required = _a.required, backedUp = _a.backedUp, otpQrCode = _a.otpQrCode, isFetched = _a.isFetched, prepareTwoFactorAuth = props.prepareTwoFactorAuth, openURL = props.openURL, backupTwoFactorAuth = props.backupTwoFactorAuth, user = props.user, isOpenSubpane = props.isOpenSubpane, isPC = props.isPC, setIsOpenSubpane = props.setIsOpenSubpane, signOut = props.signOut;
    var _b = React.useState(false), saving = _b[0], setSaving = _b[1];
    var _c = React.useState(false), saved = _c[0], setSaved = _c[1];
    var _d = React.useState(required), otpRequired = _d[0], setOtpRequired = _d[1];
    var _e = React.useState(''), otpAttempt = _e[0], setOtpAttempt = _e[1];
    React.useEffect(function () {
        fetchTwoFactorAuth().then(function (_a) {
            var required = _a.required;
            setOtpRequired(required);
        });
    }, [fetchTwoFactorAuth]);
    var handleClickSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSaving(true);
                    if (!otpRequired) return [3 /*break*/, 2];
                    return [4 /*yield*/, enableTwoFactorAuth({
                            otp_attempt: otpAttempt,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, disableTwoFactorAuth()];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    setSaving(false);
                    setSaved(true);
                    setTimeout(function () {
                        setSaved(false);
                    }, 2000);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleChangeOtpRequired = function () {
        var newOtpRequired = !otpRequired;
        setOtpRequired(newOtpRequired);
        if (!required && !otpQrCode && newOtpRequired) {
            prepareTwoFactorAuth();
        }
    };
    var handleChangeOtpAttempt = function (e) {
        setOtpAttempt(e.target.value);
    };
    var handleClickHelplink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    var onClickBackup = function () { return __awaiter(void 0, void 0, void 0, function () {
        var otpBackupCodes, blob;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, backupTwoFactorAuth()];
                case 1:
                    otpBackupCodes = (_a.sent()).otpBackupCodes;
                    blob = new Blob([otpBackupCodes.join('\n')], {
                        type: 'text/plain;charset=utf-8',
                    });
                    saveAs(blob, 'timecrowd-backup-codes.txt');
                    return [2 /*return*/];
            }
        });
    }); };
    var changed = otpRequired !== required;
    var saveLabel = saving ? 'saving' : saved ? 'saved' : 'save';
    if (!user.otp_allowed) {
        return <NotFound />;
    }
    return (<PersonalSettingsLayout active="two_factor_auth" isOpenSubpane={isOpenSubpane} isPC={isPC} saveButtonForPC={<button className="btn-major-action btn btn-primary" disabled={!changed || saving || saved} onClick={handleClickSave}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>} saveButtonForSP={<button className="btn-mobile-header-action btn btn-link" disabled={saving || saved} onClick={handleClickSave}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>} setIsOpenSubpane={setIsOpenSubpane} signOut={signOut} title={I18n.t('shared.twoFactorAuth')} user={user}>
      <div className="form-group row">
        <label className="col-sm-3 col-12 col-form-label">
          {I18n.t('personalSetting.requiredTwoFactorAuth')}
        </label>
        <div className="col-xl-7 col-sm-9 col-12">
          <div className="form-group">
            <CustomCheck checked={otpRequired} disabled={!isFetched} onChange={handleChangeOtpRequired}>
              {I18n.t('personalSetting.requiredTwoFactorAuthLabel')}
            </CustomCheck>
            <div className="form-text text-muted mt-1">
              {I18n.t('personalSetting.requiredTwoFactorAuthDesc')}
            </div>
            <p>
              <Helplink className="ml-0" href="https://help.timecrowd.net/two-factor-auth" onClick={handleClickHelplink}>
                {I18n.t('shared.clickHereForDetails')}
              </Helplink>
            </p>
          </div>
        </div>
      </div>

      {!required && otpQrCode && otpRequired && (<div className="form-group row">
          <label className="col-sm-3 col-12 col-form-label" htmlFor="otpAttempt">
            {I18n.t('shared.otp')}
          </label>
          <div className="col-xl-7 col-sm-9 col-12">
            <input className="form-control" id="otpAttempt" type="text" value={otpAttempt} onChange={handleChangeOtpAttempt}/>
            <div className="form-text text-muted mt-1">
              {I18n.t('personalSetting.otpDesc')}
            </div>
            <div>
              <img src={otpQrCode} style={{ marginLeft: -30 }}/>
            </div>
          </div>
        </div>)}

      {required && otpRequired && (<div className="form-group row">
          <label className="col-sm-3 col-12 col-form-label">
            {I18n.t('shared.backupCode')}
          </label>
          <div className="col-xl-7 col-sm-9 col-12">
            <button className="btn btn-secondary" disabled={backedUp} onClick={onClickBackup}>
              {I18n.t("shared.download")}
            </button>
            {backedUp && (<div className="form-text text-muted mt-1">
                {I18n.t('personalSetting.backupCodeAlreadyDownloaded')}
              </div>)}
            <div className="form-text text-muted mt-1">
              {I18n.t('personalSetting.backupCodeDesc')}
            </div>
          </div>
        </div>)}
    </PersonalSettingsLayout>);
};
