import React from 'react';
import copyToClipboard from 'copy-to-clipboard';
import I18n from '@/constants/i18n';
import { formatDateTime } from '@/utils/time_helper';
import Icon from '@/components/atoms/icon';
export var InvitationUrlField = function (_a) {
    var workspacesInvitation = _a.workspacesInvitation;
    var _b = React.useState(false), isCopied = _b[0], setIsCopied = _b[1];
    var onCopy = function () {
        copyToClipboard(workspacesInvitation.url);
        setIsCopied(true);
        setTimeout(function () { return setIsCopied(false); }, 2000);
    };
    if (workspacesInvitation.is_expired) {
        return <p>{I18n.t('teamManagement.invitation.byUrl.pleaseRenew')}</p>;
    }
    return (<>
      <div className="input-group">
        <input readOnly className="form-control" type="text" value={workspacesInvitation.url} onClick={function (e) { return e.currentTarget.select(); }}/>
        <span className="input-group-btn">
          <button className="btn btn-primary" onClick={onCopy}>
            {isCopied ? (<>
                <Icon className="mr-1" name="checkmark"/>
                {I18n.t('shared.copied')}
              </>) : (I18n.t('shared.copy'))}
          </button>
        </span>
      </div>

      <p className="mt-2 mb-0">
        {I18n.t('teamManagement.invitation.byUrl.expiresAt')}:{' '}
        {formatDateTime(workspacesInvitation.expires_at)}
      </p>

      <p className="mt-0 mb-0">
        {I18n.t('shared.workspaceInvitationDescription2')}
      </p>

      <p className="mt-0 font-weight-bold text-danger">
        {I18n.t('shared.workspaceInvitationDescription3')}
      </p>
    </>);
};
