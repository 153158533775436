import React from 'react';
import I18n from '@/constants/i18n';
import TeamChildrenFormContainer from '@/containers/team_children/team_children_form_container';
import { TeamManagementLayoutContainer } from '@/containers/team_management_layout_container';
export var TeamChildren = function (_a) {
    var team = _a.team;
    return (<TeamManagementLayoutContainer currentPage="descendants" team={team} title={I18n.t('shared.manageChildTeams')}>
      <TeamChildrenFormContainer team={team}/>
    </TeamManagementLayoutContainer>);
};
