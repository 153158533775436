import { __assign } from "tslib";
import * as setter from './setter';
import * as task from './task';
import * as team from './team';
import * as user from './user';
import * as report from './report';
import * as time_zone from './time_zone';
import * as category from './category';
import * as myTask from '../modules/my_task';
import * as teamManagement from '../modules/team_management';
import * as notification from '../modules/notification';
import * as webHook from './web_hook';
import * as timeEntries from '../modules/time_entries';
import * as tasks from '../modules/tasks';
import * as quickTask from '../modules/quick_task';
import * as calendar from '../modules/calendar';
import * as dailyActivities from '../modules/daily_activites';
import * as fromPageTitle from '../modules/from_page_title';
import * as currentEntry from '../modules/current_entry';
import * as reportRecipients from '../modules/report_recipients';
import * as filters from '../modules/filters';
import * as memberships from '../modules/memberships';
import * as teams from '../modules/teams';
import * as employs from '../modules/employs';
import * as assets from '../modules/assets';
import * as exports from '../modules/exports';
import * as lastTimeEntries from '../modules/last_time_entries';
import * as personalSettings from '../modules/personal_settings';
import * as identities from '../modules/identities';
import * as currentUser from '../modules/current_user';
import * as reportableUsers from '@/modules/reportable_users';
import * as reportableUsersApi from '@/api2/reportable_users';
var actions = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, setter), task), team), user), report), time_zone), category), myTask), teamManagement), notification), webHook), timeEntries), tasks), quickTask), calendar), dailyActivities), fromPageTitle), currentEntry), reportRecipients), filters), memberships), teams), employs), assets), exports), lastTimeEntries), personalSettings), identities), currentUser), reportableUsers), reportableUsersApi);
export default actions;
