import React from 'react';
import I18n from '../../constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import { numberWithDelimiter } from '@/utils/number_helper';
export var CapacityField = function (_a) {
    var hasLimit = _a.hasLimit, capacity = _a.capacity, invalid = _a.invalid, unitPrice = _a.unitPrice, onChangeHasLimit = _a.onChangeHasLimit, onChangeCapacity = _a.onChangeCapacity;
    return (<>
      <CustomCheck checked={hasLimit} className="mt-2" onChange={onChangeHasLimit}>
        {I18n.t('payment.setUpperLimit')}
      </CustomCheck>
      {hasLimit && (<div className="form-group mt-2 ml-3">
          <div className="form-inline">
            <input className="form-control mr-1" style={{ width: '72px' }} type="number" value={capacity} onChange={onChangeCapacity}/>
            <span className="mr-2">{I18n.t('payment.upToPeople')}</span>
            <span className="badge badge-info">
              {I18n.t('payment.maximum')}
            </span>
            <span className="h3 font-weight-bold mx-1">
              {I18n.t('shared.currencyUnit')}
              {numberWithDelimiter(unitPrice * (parseInt(capacity !== null && capacity !== void 0 ? capacity : '') || 0))}
            </span>
            {invalid && (<span className="text-danger">
                {I18n.t('shared.invalidCapacity')}
              </span>)}
          </div>
        </div>)}
    </>);
};
