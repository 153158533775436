import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { CategoryTaskStructuresExportModal } from '../category_task_structures_export_modal';
import { formatDateTime } from '@/utils/time_helper';
export var CategoryTaskStructuresExportForm = function (_a) {
    var exports = _a.exports, team = _a.team, defaultCharset = _a.defaultCharset, isLoading = _a.isLoading, hasNext = _a.hasNext, createCategoryTaskStructuresExport = _a.createCategoryTaskStructuresExport, fetchCategoryTaskStructuresExports = _a.fetchCategoryTaskStructuresExports;
    var onExport = React.useCallback(function (charset) {
        createCategoryTaskStructuresExport({ team_id: team.id, charset: charset });
    }, [team, createCategoryTaskStructuresExport]);
    var loadMore = React.useCallback(function () {
        fetchCategoryTaskStructuresExports(team.id);
    }, [team, fetchCategoryTaskStructuresExports]);
    var processingExport = exports.find(function (_a) {
        var status = _a.status;
        return status === 'processing';
    });
    var completedExports = exports.filter(function (_a) {
        var status = _a.status;
        return status !== 'processing';
    });
    return (<div>
      <h4>{I18n.t('shared.exportCategoriesAndTasks')}</h4>
      <p className="mt-2">{I18n.t('shared.categoryTaskStructureExportNote')}</p>
      <p>
        {processingExport ? (<>
            <button disabled className="btn btn-primary">
              {I18n.t('shared.exporting')}
            </button>
            <br />
            {I18n.t('shared.exportingNow')}
            {I18n.t('shared.notifyWhenComplete', {
                email: processingExport.user_email,
            })}
          </>) : (<CategoryTaskStructuresExportModal defaultCharset={defaultCharset} team={team} onExport={onExport}/>)}
      </p>

      <h4 className="mt-4">
        {I18n.t('shared.categoryTaskStructureExportHistory')}
      </h4>
      <div className="mt-2">
        {completedExports.length < 1 ? (<p className="mt-2">{I18n.t('shared.noHistoryFound')}</p>) : (<div>
            <table>
              <thead>
                <tr>
                  <th className="pr-1">{I18n.t('shared.datetime')}</th>
                  <th className="pr-1">{I18n.t('export.charset.label')}</th>
                  <th className="pr-1">{I18n.t('shared.processingResult')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {completedExports.map(function (_a) {
                var id = _a.id, created_at = _a.created_at, charset = _a.charset, status = _a.status;
                return (<tr key={id}>
                    <td className="pr-1">
                      {formatDateTime(new Date(created_at))}
                    </td>
                    <td className="pr-1">
                      {I18n.t("export.charset.".concat(charset))}
                    </td>
                    <td className="pr-1">
                      {status === 'failed' ? (<span>
                          <Icon color="danger" name="warning"/>
                          <span className="ml-1">
                            {I18n.t('shared.exportFailed')}
                          </span>
                        </span>) : (<span>
                          <span className="bg-primary text-white rounded-circle d-inline-flex justify-content-center align-items-center" style={{ width: '1.5rem', height: '1.5rem' }}>
                            <Icon name="checkmark" size="xs"/>
                          </span>
                          <span className="ml-1">
                            {I18n.t('shared.exportComplete')}
                          </span>
                        </span>)}
                    </td>
                    <td className="pl-3">
                      {status === 'completed' && (<a href={"/category_task_structures/exports/".concat(id)}>
                          {I18n.t('shared.download')}
                        </a>)}
                    </td>
                  </tr>);
            })}
              </tbody>
            </table>
            {hasNext && (<p className="mt-1">
                <button className="btn btn-secondary" disabled={isLoading} onClick={loadMore}>
                  {I18n.t('shared.showMore')}
                </button>
              </p>)}
          </div>)}
      </div>
    </div>);
};
