import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import I18n from '@/constants/i18n';
import { BREAKPOINT_MD } from '@/constants/styles';
import Backdrop from '@/components/atoms/backdrop';
import Avatar from '@/components/atoms/avatar';
import { PersonalBadge, UnpaidBadge } from '@/components/badges';
var TeamSelect = function (props) {
    var _a = props.block, block = _a === void 0 ? true : _a, _b = props.defaultTeamName, defaultTeamName = _b === void 0 ? I18n.t('shared.selectTeam') : _b, _c = props.right, right = _c === void 0 ? true : _c, teams = props.teams, toggle = props.toggle, onClick = props.onClick;
    var blankTeam = React.useMemo(function () {
        return {
            name: defaultTeamName,
            avatar_url: '',
        };
    }, [defaultTeamName]);
    var _d = React.useState(props.selected || blankTeam), selected = _d[0], setSelected = _d[1];
    var _e = React.useState(false), isOpen = _e[0], setIsOpen = _e[1];
    React.useEffect(function () {
        setSelected(blankTeam);
    }, [blankTeam, teams]);
    var handleToggle = function () {
        if (typeof toggle === 'function') {
            toggle();
        }
        setIsOpen(!isOpen);
    };
    var handleSelectTeam = function (team) {
        setSelected(team);
        onClick(team);
    };
    var wrapperClassName = classNames({
        'w-100': block,
    });
    return (<div className={wrapperClassName}>
      <Dropdown className="ml-auto w-100 floating-dropdown" isOpen={isOpen} size="lg" toggle={handleToggle}>
        <DropdownToggle block caret className="floating-dropdown-toggle">
          <Avatar className="mr-2" image={selected.avatar_url}/>
          {selected.name}
          <UnpaidBadge className="ml-auto" style={{ marginRight: '3rem' }} team={selected}/>
          <PersonalBadge className="ml-auto" isPersonal={selected.personal} style={{ marginRight: '3rem' }}/>
        </DropdownToggle>
        <DropdownMenu className="floating-dropdown-menu floating-dropdown-menu-compability" right={right}>
          <DropdownItem key={"team-select-unselected"} className="d-flex align-items-center" onClick={function () { return handleSelectTeam(blankTeam); }}>
            <Avatar className="mr-1" image={blankTeam.avatar_url} size="sm"/>
            {blankTeam.name}
          </DropdownItem>
          {teams.map(function (team) {
            return (<DropdownItem key={"team-select-".concat(team.id)} className="d-flex align-items-center" onClick={function () { return handleSelectTeam(team); }}>
                <Avatar className="mr-1" image={team.avatar_url} size="sm"/>
                {team.name}
                <UnpaidBadge className="ml-auto" team={team}/>
                <PersonalBadge className="ml-auto" isPersonal={team.personal}/>
              </DropdownItem>);
        })}
        </DropdownMenu>
      </Dropdown>
      <MediaQuery maxWidth={BREAKPOINT_MD}>
        {isOpen && <Backdrop onClick={handleToggle}/>}
      </MediaQuery>
    </div>);
};
export default TeamSelect;
