import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CalendarOptionsDropdown } from '@/components/calendar_options_dropdown';
import variants from '@/constants/variants';
import { updateCalendarZoomRate, saveIsShowCategoryOnWeeklyCalendar, loadIsShowCategoryOnWeeklyCalendar, saveCalendarUnitMinutes, loadCalendarUnitMinutes, minimumZoomRate, } from '@/modules/calendar';
export var CalendarOptionsDropdownContainer = function (props) {
    var _a = props.isShowCalendarIntegrationMenu, isShowCalendarIntegrationMenu = _a === void 0 ? false : _a, _b = props.isPC, isPC = _b === void 0 ? variants.isPC : _b, _c = props.isApp, isApp = _c === void 0 ? variants.isApp : _c, _d = props.isMobile, isMobile = _d === void 0 ? variants.isMobile : _d;
    var calendar = useSelector(function (state) { return state.calendar; });
    var calendarZoomRate = useSelector(function (state) { return state.user.calendar_zoom_rate; });
    var dispatch = useDispatch();
    var handleSaveIsShowCategoryOnWeeklyCalendar = React.useCallback(function (value) {
        dispatch(saveIsShowCategoryOnWeeklyCalendar(value));
    }, [dispatch]);
    var handleLoadIsShowCategoryOnWeeklyCalendar = React.useCallback(function () {
        dispatch(loadIsShowCategoryOnWeeklyCalendar());
    }, [dispatch]);
    var handleSaveCalendarUnitMinutes = React.useCallback(function (unitMinutes) {
        dispatch(saveCalendarUnitMinutes(unitMinutes));
    }, [dispatch]);
    var handleLoadCalendarUnitMinutes = React.useCallback(function () {
        dispatch(loadCalendarUnitMinutes());
    }, [dispatch]);
    var handleSetZoomRateToMinimum = React.useCallback(function (isShowCategoryOnWeeklyCalendar, unitMinutes) {
        var minZoomRate = minimumZoomRate(isShowCategoryOnWeeklyCalendar, unitMinutes);
        if (calendarZoomRate < minZoomRate) {
            dispatch(updateCalendarZoomRate(minZoomRate));
        }
    }, [calendarZoomRate, dispatch]);
    return (<CalendarOptionsDropdown calendarUnitMinutes={calendar.unitMinutes} isShowCalendarIntegrationMenu={isShowCalendarIntegrationMenu} isShowCategoryOnWeeklyCalendar={calendar.isShowCategoryOnWeeklyCalendar} isShowCategoryOnWeeklyCalendarMenu={isPC && calendar.view === 'weekly'} isShowZoomRateMenu={!isApp && !isMobile} loadCalendarUnitMinutes={handleLoadCalendarUnitMinutes} loadIsShowCategoryOnWeeklyCalendar={handleLoadIsShowCategoryOnWeeklyCalendar} saveCalendarUnitMinutes={handleSaveCalendarUnitMinutes} saveIsShowCategoryOnWeeklyCalendar={handleSaveIsShowCategoryOnWeeklyCalendar} setZoomRateToMinimum={handleSetZoomRateToMinimum}/>);
};
