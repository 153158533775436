import { __assign } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import TaskForm from '@/components/task_form';
import { fetchCalendar, destroyTimelineTimeEntry } from '@/modules/calendar';
import { setCurrentCategory } from '@/modules/my_task';
import { setFromPageTitle } from '@/modules/from_page_title';
import { freezeScroll } from '@/modules/display';
import { updateTimeEntry } from '@/modules/time_entries';
import { fetchSuggestTasks } from '@/actions/task';
import { fetchTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
import { setNotificationMessage } from '@/modules/notification';
import I18n from '@/constants/i18n';
function normalizeCategory(timeEntry) {
    return __assign(__assign({}, timeEntry.task.category), { team: timeEntry.task.team });
}
var dateFormat = 'YYYY-MM-DD';
var CalendarItemContainer = function (_a) {
    var selectedDay = _a.selectedDay, timeEntry = _a.timeEntry, mode = _a.mode, toggleTaskForm = _a.toggleTaskForm, isMine = _a.isMine, readOnly = _a.readOnly, commentRef = _a.commentRef, onSelectCategory = _a.onSelectCategory, selectedTask = _a.selectedTask, setSelectedTask = _a.setSelectedTask, isSearching = _a.isSearching, createTask = _a.createTask, setIsSearching = _a.setIsSearching, hidesFromPageToggle = _a.hidesFromPageToggle, requiredSelectedTask = _a.requiredSelectedTask, user_id = _a.user_id;
    var fromPageTitle = useSelector(function (state) { return state.fromPageTitle; });
    var isChromeExtension = useSelector(function (state) { return state.isChromeExtension; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var isFocus = useSelector(function (state) { return !state.isApp; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var timeEntrySuggestionsItems = useSelector(function (state) { return state.timeEntrySuggestions.items; });
    var category = normalizeCategory(timeEntry);
    var timeEntrySuggestions = isMine ? timeEntrySuggestionsItems : [];
    var dispatch = useDispatch();
    var onStart = function () { };
    var onCreate = function () { };
    var onUpdate = function (id, params) {
        var promise = dispatch(updateTimeEntry(id, params)).then(function (timeEntry) {
            if (timeEntry.error) {
                return;
            }
            dispatch(setNotificationMessage('success', I18n.t('calendar.timeEntryUpdated')));
            var date = moment(selectedDay).format(dateFormat);
            dispatch(fetchCalendar({ date: date, user_id: timeEntry.user.id }));
            toggleTaskForm(-1);
        });
        return promise;
    };
    var onDestroyTimeEntry = function (id) {
        dispatch(destroyTimelineTimeEntry(id)).then(function (response) {
            if (response.error) {
                return;
            }
            var date = moment(selectedDay).format(dateFormat);
            dispatch(fetchCalendar({ date: date, user_id: user_id }));
            toggleTaskForm(-1);
        });
    };
    var onCancel = function () {
        toggleTaskForm(-1);
    };
    var handleSelectCategory = function (category) {
        onSelectCategory(category);
        dispatch(setCurrentCategory(category));
    };
    var onSetFromPageTitle = function (fromPageTitle) {
        dispatch(setFromPageTitle(fromPageTitle));
    };
    var handleSetFreezeScroll = React.useCallback(function (isFreeze) {
        dispatch(freezeScroll(isFreeze));
    }, [dispatch]);
    var onSuggestTasks = function (category, title) {
        var params = {
            title: title,
            category_id: category.id,
            team_id: category.team_id,
        };
        dispatch(fetchSuggestTasks(params));
    };
    var handleFetchTimeEntrySuggestion = React.useCallback(function (time, ignoreId) {
        dispatch(fetchTimeEntrySuggestion(time, ignoreId));
    }, [dispatch]);
    var handleSetNotificationMessage = function (type, message) {
        dispatch(setNotificationMessage(type, message));
    };
    return (<TaskForm isShowCancelButton isTimeEntrySpecified useDefaultDuration category={category} commentRef={commentRef} createTask={createTask} fetchTimeEntrySuggestion={handleFetchTimeEntrySuggestion} fromPageTitle={fromPageTitle} hidesFromPageToggle={hidesFromPageToggle} isChromeExtension={isChromeExtension} isFocus={isFocus} isMine={isMine} isPC={isPC} isSearching={isSearching} mode={mode} openURL={openURL} readOnly={readOnly} requiredSelectedTask={requiredSelectedTask} selectedTask={selectedTask} setFreezeScroll={handleSetFreezeScroll} setIsSearching={setIsSearching} setNotificationMessage={handleSetNotificationMessage} setSelectedTask={setSelectedTask} tab="schedule" task={timeEntry.task} timeEntry={timeEntry} timeEntrySuggestions={timeEntrySuggestions} onCancel={onCancel} onCreate={onCreate} onDestroyTimeEntry={onDestroyTimeEntry} onSelectCategory={handleSelectCategory} onSetFromPageTitle={onSetFromPageTitle} onStart={onStart} onSuggestTasks={onSuggestTasks} onUpdate={onUpdate}/>);
};
export default CalendarItemContainer;
