import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import Avatar from '@/components/atoms/avatar';
import CustomCheck from '@/components/atoms/custom_check';
import Helplink from '@/components/molecules/helplink';
import TeamDeletionContainer from '@/containers/team_edit/team_deletion_container';
import { isPC } from '@/constants/variants';
import { TeamManagementLayoutContainer } from '@/containers/team_management_layout_container';
export var EditTeamForm = function (_a) {
    var onUpdate = _a.onUpdate, openURL = _a.openURL, defaultTeam = _a.team, teams = _a.teams;
    var team = React.useMemo(function () { return defaultTeam || {}; }, [defaultTeam]);
    var _b = React.useState(team.name), name = _b[0], setName = _b[1];
    var _c = React.useState(''), avatar = _c[0], setAvatar = _c[1];
    var _d = React.useState(team.avatar_url), avatarUrl = _d[0], setAvatarUrl = _d[1];
    var _e = React.useState(''), avatarFilename = _e[0], setAvatarFilename = _e[1];
    var _f = React.useState(''), avatarContentType = _f[0], setAvatarContentType = _f[1];
    var _g = React.useState(team.time_limit), timeLimit = _g[0], setTimeLimit = _g[1];
    var _h = React.useState(team.rounding), rounding = _h[0], setRounding = _h[1];
    var _j = React.useState(team.default_duration), defaultDuration = _j[0], setDefaultDuration = _j[1];
    var _k = React.useState(false), isOpenDeletion = _k[0], setIsOpenDeletion = _k[1];
    var _l = React.useState(false), saving = _l[0], setSaving = _l[1];
    var _m = React.useState(false), saved = _m[0], setSaved = _m[1];
    var _o = React.useState(timeLimit !== 0), timeLimitCheck = _o[0], setTimeLimitCheck = _o[1];
    var avatarFileInput = React.useRef(null);
    React.useEffect(function () {
        setName(team.name);
        setAvatarUrl(team.avatar_url);
        setTimeLimit(team.time_limit);
        setRounding(team.rounding);
        setDefaultDuration(team.default_duration);
    }, [team]);
    var handleChangeName = function (e) {
        setName(e.target.value);
    };
    var handleChangeAvatar = function (e) {
        var file = e.target.files[0];
        if (file !== void 0) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                if (file.type.match(/^image\/(png|jpeg|gif)$/)) {
                    // @ts-ignore
                    setAvatar(reader_1.result.split(',')[1]);
                    setAvatarUrl(reader_1.result);
                    setAvatarFilename(file.name);
                    setAvatarContentType(file.type);
                }
            };
            reader_1.readAsDataURL(file);
        }
    };
    var handleClickFakeFileInput = function () {
        // @ts-ignore
        document.querySelector('#avatarFileInput').click();
    };
    var handleToggleTimeLimitCheck = function () {
        var nextTimeLimitCheck = !timeLimitCheck;
        if (!nextTimeLimitCheck) {
            setTimeLimit(0);
        }
        setTimeLimitCheck(nextTimeLimitCheck);
    };
    var handleChangeTimeLimit = function (e) {
        setTimeLimit(e.target.value);
    };
    var handleChangeDefaultDuration = function (e) {
        if (e.target.value === '') {
            setDefaultDuration('');
            return;
        }
        var default_duration = Number(e.target.value);
        if (1 > default_duration || 60 < default_duration) {
            return;
        }
        setDefaultDuration(default_duration);
    };
    var handleClickSave = function () {
        setSaving(true);
        onUpdate(team.id, {
            team: {
                name: name,
                avatar: avatar,
                avatar_filename: avatarFilename,
                avatar_content_type: avatarContentType,
                time_limit: timeLimit,
                rounding: rounding,
                default_duration: defaultDuration,
            },
        }).then(function (team) {
            if (team.error) {
                setSaving(false);
            }
            else {
                setSaving(false);
                setSaved(true);
                setTimeout(function () {
                    setSaved(false);
                }, 2000);
            }
        });
    };
    var handleClickDeletion = function () {
        setIsOpenDeletion(true);
    };
    var handleCloseDeletion = function () {
        setIsOpenDeletion(false);
    };
    var handleClickHelplink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    var form = (<div className="col-12">
      <p className="h4 mb-2 mb-md-4">{I18n.t('shared.profile')}</p>
      <div className="form-group mb-3 row">
        <label className="col-12 col-md-3 col-form-label">
          {I18n.t('shared.icon')}
        </label>
        <div className="col-12 col-md-9 d-flex">
          <Avatar className="mr-2" image={avatarUrl} size="lg"/>
          {isPC && (<div>
              <input ref={avatarFileInput} accept="image/gif, image/jpeg, image/png" className="hidden-file-input form-control-file" id="avatarFileInput" type="file" onChange={handleChangeAvatar}/>
              <button className="btn btn-secondary" onClick={handleClickFakeFileInput}>
                <Icon className="mr-1" name="camera"/>
                {I18n.t('shared.change')}
              </button>
            </div>)}
        </div>
      </div>
      <div className="form-group mb-3 row">
        <label className="col-12 col-md-3 col-form-label">
          {I18n.t('teamManagement.teamName')}
        </label>
        <div className="col-12 col-md-7">
          <input className="mb-2 form-control" value={name} onChange={handleChangeName}/>
          <span className="text-muted small mb-2 font-weight-medium">
            {I18n.t('teamManagement.teamId')} {team.id}
          </span>
        </div>
      </div>
      <hr className="my-4"/>
      <p className="h4 mb-2 mb-md-4">{I18n.t('shared.calculation')}</p>
      <div className="form-group mb-3 row">
        <label className="col-12 col-md-3 col-form-label">
          {I18n.t('shared.autoStopTimeTracking')}
          <Helplink color="secondary" href="https://support.timecrowd.net/1428ec2d2afa80b7bfc3d8f706b9aba3" onClick={handleClickHelplink}/>
        </label>
        <div className="col-12 col-md-6">
          <div className="form-inline">
            <CustomCheck checked={timeLimitCheck} onChange={handleToggleTimeLimitCheck}>
              {I18n.t('shared.setAutoStopTimeTracking')}
            </CustomCheck>
            <input className="mr-2 col-md-3 form-control" disabled={!timeLimitCheck} type="number" value={timeLimit} onChange={handleChangeTimeLimit}/>
            <span>{I18n.t('shared.minutes')}</span>
          </div>
        </div>
      </div>
      <div className="form-group mb-3 row">
        <label className="col-12 col-md-3 col-form-label">
          {I18n.t('model.attributes.team.defaultDuration')}
        </label>
        <div className="col-12 col-md-3">
          <div className="form-inline">
            <input className="mr-2 form-control" type="number" value={defaultDuration} onChange={handleChangeDefaultDuration}/>
            <span>{I18n.t('shared.minutes')}</span>
          </div>
        </div>
      </div>

      <div className="form-group row mt-3">
        <div className="col-xl-10 col-12">
          <hr />
          <button className="btn btn-link text-danger pr-0 pl-0 mt-1" type="button" onClick={handleClickDeletion}>
            {I18n.t('teamManagement.destroyTeam.delete')}
          </button>
        </div>
        <TeamDeletionContainer avatarUrl={team.avatar_url} id={team.id} isOpen={isOpenDeletion} name={name} openURL={openURL} personal={team.personal} teams={teams} onClose={handleCloseDeletion}/>
      </div>
    </div>);
    var saveLabel = saving ? 'saving' : saved ? 'saved' : 'save';
    return (<TeamManagementLayoutContainer currentPage="team" saveButtonForPC={<button className="btn-major-action btn btn-primary" disabled={saveLabel !== 'save'} onClick={handleClickSave}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>} saveButtonForSP={<button className="btn-mobile-header-action btn-mobile-header-action-text  btn-mobile-header-action-primary btn btn-link" disabled={saveLabel !== 'save'} onClick={handleClickSave}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>} team={defaultTeam} title={I18n.t('shared.generalSettings')}>
      {form}
    </TeamManagementLayoutContainer>);
};
