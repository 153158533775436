import React from 'react';
import classNames from 'classnames';
import Icon from '@/components/atoms/icon';
import TaskTitleContainer from '@/containers/task_title_container';
import { isCreateMode } from './helpers';
import { NameWithTooltip } from '@/components/name_with_tooltip';
export var TaskFormTitleField = function (_a) {
    var task = _a.task, category = _a.category, isTimeEntrySpecified = _a.isTimeEntrySpecified, mode = _a.mode, fromPageTitle = _a.fromPageTitle, readOnly = _a.readOnly, hidesFromPageToggle = _a.hidesFromPageToggle, isEditingTask = _a.isEditingTask, taskTitleRef = _a.taskTitleRef, isChromeExtension = _a.isChromeExtension, isPC = _a.isPC, archived = _a.archived, setIsEditingTask = _a.setIsEditingTask, handleClickStart = _a.handleClickStart, handleClickCreate = _a.handleClickCreate, isValidForUpdate = _a.isValidForUpdate, onChangeTask = _a.onChangeTask, setTab = _a.setTab, setIsOpenTaskSuggestion = _a.setIsOpenTaskSuggestion;
    var handleKeyPressEnterTitle = function () {
        if (isCreateMode(mode)) {
            if (!isTimeEntrySpecified) {
                handleClickStart();
            }
            else {
                handleClickCreate();
            }
        }
        else {
            if (isValidForUpdate()) {
                setIsEditingTask(false);
            }
        }
    };
    var titleContainerClassNames = classNames('d-flex align-items-start', {
        'mb-3': isPC,
        'mb-1': !isPC,
    });
    var titleClassNames = classNames('form-control task-edit-title text-break', {
        'text-elipsis': isChromeExtension,
        'line-clamp-2': isChromeExtension,
    });
    return (<>
      {isCreateMode(mode) || isEditingTask ? (<div className="mb-1 w-100">
          <TaskTitleContainer category={category} disabled={isChromeExtension && fromPageTitle && !hidesFromPageToggle} rows={2} task={task} taskTitleRef={taskTitleRef} onBlur={function () { return setIsEditingTask(false); }} onChangeIsOpenTaskSuggestion={function (isOpenTaskSuggestion) {
                return setIsOpenTaskSuggestion(isOpenTaskSuggestion);
            }} onChangeTab={function (tab) { return setTab(tab); }} onChangeTask={onChangeTask} onKeyPressEnter={handleKeyPressEnterTitle}/>
        </div>) : (<div className={titleContainerClassNames}>
          <NameWithTooltip className={titleClassNames} name={task.title} withTooltip={isChromeExtension}/>
          {!readOnly && !archived && (<button className="btn btn-link btn-sm text-muted px-1 py-0 mt-1" onClick={function () { return setIsEditingTask(true); }}>
              <Icon name="pencil" size="sm"/>
            </button>)}
        </div>)}
    </>);
};
