import queryString from 'query-string';
import api2 from '@/api2';
import { fetchReportableUsersRequest, fetchReportableUsersSuccess, fetchReportableUsersFailure, } from '@/modules/reportable_users';
export var fetchReportableUsers = function (params) {
    if (params === void 0) { params = {}; }
    var _query = queryString.stringify(params);
    var query = _query ? "?".concat(_query) : '';
    return function (dispatch, getState) {
        dispatch(fetchReportableUsersRequest());
        return api2(dispatch, getState, "/api/v2/reportable_users".concat(query), 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (users) {
                    dispatch(fetchReportableUsersSuccess(users));
                    return users;
                });
            }
            else {
                dispatch(fetchReportableUsersFailure());
                return { error: true };
            }
        });
    };
};
