import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import Avatar from '@/components/atoms/avatar';
import Icon from '@/components/atoms/icon';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var DeletePaymentModal = function (_a) {
    var isOpen = _a.isOpen, onCancel = _a.onCancel, onSubmit = _a.onSubmit, team = _a.team, workspace = _a.workspace;
    var _b = React.useState(false), isConfirmed = _b[0], setIsConfirmed = _b[1];
    var target = workspace || team;
    var onConfirm = React.useCallback(function () {
        setIsConfirmed(!isConfirmed);
    }, [isConfirmed]);
    return (<Modal isOpen={isOpen} toggle={onCancel} zIndex={1050}>
      <ModalHeaderMod toggle={onCancel}>
        {I18n.t('shared.deletePayment')}
      </ModalHeaderMod>

      <ModalBody>
        <p>{I18n.t('shared.deletePaymentDescription1')}</p>
        <div className="ml-2">
          <label className="col-form-label" style={{ lineHeight: '1.2rem', width: '100%' }}>
            <Icon className="mr-1" name="user-group"/>
            <span style={{
            fontSize: '10px',
        }}>
              {I18n.t('shared.team')}
            </span>
          </label>
          <Avatar className="mr-1" image={target.avatar_url} size="sm"/>
          {target.name}
        </div>

        <div className="form-group mt-3">
          <CustomCheck checked={isConfirmed} onChange={onConfirm}>
            <span className="text-danger font-weight-bold">
              {I18n.t('shared.confirmation')}
            </span>
          </CustomCheck>
        </div>
      </ModalBody>

      <ModalFooter style={{ justifyContent: 'flex-start' }}>
        <button className="btn btn-danger" disabled={!isConfirmed} onClick={onSubmit}>
          {I18n.t('shared.deletePayment')}
        </button>
        <button className="btn btn-secondary" onClick={onCancel}>
          {I18n.t('shared.cancel')}
        </button>
      </ModalFooter>
    </Modal>);
};
