import React from 'react';
import classNames from 'classnames';
import Icon from '@/components/atoms/icon';
var Helplink = function (props) {
    var _a;
    var className = props.className, _b = props.type, type = _b === void 0 ? 'question' : _b, _c = props.size, size = _c === void 0 ? 'textsize' : _c, _d = props.color, color = _d === void 0 ? 'normal' : _d, _e = props.inverse, inverse = _e === void 0 ? false : _e, onClick = props.onClick, children = props.children, href = props.href;
    var classes = classNames('helplink', className, (_a = {},
        _a['helplink-' + size] = true,
        _a['helplink-' + color] = true,
        _a['help-inverse'] = inverse,
        _a));
    return (<a className={classes} href={href} onClick={onClick}>
      <Icon name={type}/>
      {children && <span className="helplink-title ml-1">{children}</span>}
    </a>);
};
export default Helplink;
