import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '@/constants/i18n';
import variants from '@/constants/variants';
import Icon from '@/components/atoms/icon';
export function TaskItemMenu(_a) {
    var task = _a.task, isLogging = _a.isLogging, isShowMenu = _a.isShowMenu, isOpenOtherMenu = _a.isOpenOtherMenu, _b = _a.isPC, isPC = _b === void 0 ? variants.isPC : _b, _c = _a.openURL, openURL = _c === void 0 ? variants.openURL : _c, toggle = _a.toggle, handleStart = _a.handleStart, handleStop = _a.handleStop, handleClose = _a.handleClose, handleReopen = _a.handleReopen, handleClickOtherMenu = _a.handleClickOtherMenu, handleClickRestore = _a.handleClickRestore, handleClickDestroy = _a.handleClickDestroy;
    return (<>
      <div className="task-controls">
        <div className="btn-group mr-1">
          {!isPC &&
            !task.category.archived &&
            (isLogging ? (<button className="btn btn-danger btn-sm" title={I18n.t('tasklist.stop')} type="button" onClick={handleStop}>
                <Icon name="stop"/>
              </button>) : (<button className="btn btn-primary btn-sm" title={I18n.t('tasklist.start')} type="button" onClick={handleStart}>
                <Icon name="start"/>
              </button>))}
        </div>
        <div className="btn-group">
          {!task.deleted_at && (<button className="btn btn-secondary btn-sm" title={I18n.t("tasklist.".concat(task.category.archived ? 'view' : 'edit'))} type="button" onClick={toggle}>
              <Icon name={task.category.archived ? 'list' : 'pencil'}/>
            </button>)}
          {!task.category.archived &&
            !task.deleted_at &&
            task.state === 'open' && (<button className="btn btn-secondary btn-sm" title={I18n.t('tasklist.done')} type="button" onClick={handleClose}>
                <Icon name="checkmark"/>
              </button>)}
          {!task.category.archived &&
            !task.deleted_at &&
            task.state === 'closed' && (<button className="btn btn-secondary btn-sm" style={{ color: '#4EC0AA' }} title={I18n.t('tasklist.yet')} type="button" onClick={handleReopen}>
                <Icon name="checkmark"/>
              </button>)}
          {isShowMenu && (<ButtonDropdown isOpen={isOpenOtherMenu} size="sm" style={{ zIndex: '201' }} toggle={handleClickOtherMenu}>
              <DropdownToggle onClick={function (e) {
                e.stopPropagation();
                handleClickOtherMenu();
            }}>
                <Icon name="dots-3" size="sm"/>
              </DropdownToggle>
              <DropdownMenu right>
                {task.url.length > 1 && (<DropdownItem onClick={function (e) {
                    e.stopPropagation();
                    openURL(task.url);
                }}>
                    <Icon className="mr-2" name="link" size="md"/>
                    {I18n.t('tasklist.link')}
                  </DropdownItem>)}
                {task.deleted_at && (<>
                    <DropdownItem onClick={handleClickRestore}>
                      {I18n.t('shared.restore')}
                    </DropdownItem>
                    <DropdownItem divider/>
                    <DropdownItem className="text-danger" onClick={handleClickDestroy}>
                      {I18n.t('shared.deleteForever')}
                    </DropdownItem>
                  </>)}
              </DropdownMenu>
            </ButtonDropdown>)}
        </div>
      </div>
      {isOpenOtherMenu && (<div style={{
                position: 'fixed',
                display: 'block',
                top: '0',
                left: '0',
                height: '100vh',
                width: '100vw',
                zIndex: 200,
                backgroundColor: 'rgba(0,0,0,0)',
                cursor: 'default',
            }}/>)}
    </>);
}
