import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchFilters, updateFilter } from '@/modules/filters';
import NotFound from '@/components/pages/not_found';
import { IntegrationSetting } from '@/components/integration_setting';
import { workspaceTeams } from '@/utils/workspace_helper';
var IntegrationSettingContainer = function () {
    var filters = useSelector(function (state) { return state.filters; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var teams = workspaceTeams(useSelector(function (state) { return state.teams.items; }));
    var team_id = useParams().team_id;
    var team = teams.find(function (team) { return team.id === Number(team_id); });
    var isOwner = team && team.can_manage;
    var dispatch = useDispatch();
    var handleSubmit = function (filter) {
        return dispatch(updateFilter(filter));
    };
    React.useEffect(function () {
        if (isOwner) {
            dispatch(fetchFilters(team_id));
        }
    }, [dispatch, isOwner, team_id]);
    if (filters.isFetched && filters.filters.length === 0) {
        return <NotFound />;
    }
    return (<IntegrationSetting filters={filters.filters} isFetched={filters.isFetched} isLoading={filters.isLoading} openURL={openURL} team={team} onSubmit={handleSubmit}/>);
};
export default IntegrationSettingContainer;
