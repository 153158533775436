import { __assign, __awaiter, __generator } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import { CrossTeamReportPeriodButton } from './cross_team_report_period_button';
import { calcFromAndToForType } from './helpers';
import { StartMonthDropdown } from '@/components/start_month_dropdown';
import CustomRadio from '@/components/atoms/custom_radio';
import { formatDate } from '@/utils/time_helper';
import { periodLabel } from '@/components/cross_team_report_header/helpers';
var defaultPeriod = {
    term: 'long_term',
    period_type: '',
    from: '',
    to: '',
};
export var LongTermReportTab = function (props) {
    var generatingReport = props.generatingReport, isLoading = props.isLoading, isOpen = props.isOpen, startMonth = props.startMonth, onCancelGenerate = props.onCancelGenerate, onChangeStartMonth = props.onChangeStartMonth, onClose = props.onClose, onGenerate = props.onGenerate, onUseCache = props.onUseCache, onSearch = props.onSearch;
    var _a = React.useState(defaultPeriod), selectedPeriod = _a[0], setSelectedPeriod = _a[1];
    var _b = React.useState(false), isSearched = _b[0], setIsSearched = _b[1];
    var _c = React.useState(null), matchedReport = _c[0], setMatchedReport = _c[1];
    var _d = React.useState('cache'), mode = _d[0], setMode = _d[1];
    React.useEffect(function () {
        setSelectedPeriod(defaultPeriod);
        setIsSearched(false);
        setMatchedReport(null);
    }, [isOpen]);
    var handleSearch = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var res, matched;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSearch(__assign(__assign({}, params), { status: 'completed', limit: 1 }))];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        return [2 /*return*/];
                    }
                    matched = res.data[0] || null;
                    setMode(matched ? 'cache' : 'generate');
                    setMatchedReport(matched);
                    setIsSearched(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleChangeType = function (period_type) {
        var newPeriod = __assign(__assign(__assign({}, selectedPeriod), { period_type: period_type }), calcFromAndToForType(period_type, startMonth));
        setSelectedPeriod(newPeriod);
        handleSearch(__assign(__assign({}, newPeriod), { start_month: startMonth }));
    };
    var handleChangeStartMonth = function (startMonth) {
        onChangeStartMonth(startMonth);
        if (selectedPeriod.period_type) {
            handleSearch(__assign(__assign(__assign({}, selectedPeriod), calcFromAndToForType(selectedPeriod.period_type, startMonth)), { start_month: startMonth }));
        }
    };
    var handleClickGenerate = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (mode === 'cache' && matchedReport) {
                onUseCache(matchedReport);
            }
            else {
                onGenerate(__assign(__assign({}, selectedPeriod), { start_month: startMonth }));
            }
            return [2 /*return*/];
        });
    }); };
    var handleChangeModeToCache = function () {
        if (matchedReport) {
            setMode('cache');
        }
    };
    var handleChangeModeToGenerate = function () {
        setMode('generate');
    };
    if (generatingReport) {
        return (<div className="text-center py-3 px-5">
        <p className="h4">{I18n.t('shared.longTermReportGeneratingTitle')}</p>
        <p className="mt-1">
          {generatingReport.period_type !== 'custom' && (<span className="mr-1">
              {I18n.t("shared.".concat(generatingReport.period_type))}
            </span>)}
          {periodLabel(generatingReport)}
        </p>
        <p className="mt-4 text-nowrap text-muted">
          {I18n.t('shared.longTermReportGeneratingDescription')}
        </p>
        <p className="mt-2 mb-0">
          <button className="btn btn-link text-danger" onClick={onCancelGenerate}>
            {I18n.t('shared.longTermReportGeneratingCancel')}
          </button>
        </p>
      </div>);
    }
    return (<>
      <p className="text-muted mt-2">
        {I18n.t('shared.longTermReportDescription1')}
      </p>
      <p className="text-muted mt-2">
        {I18n.t('shared.longTermReportDescription2')}
      </p>
      <div className="d-flex justify-content-between align-items-center mt-1">
        <span className="font-weight-bold">
          {I18n.t('shared.selectPeriod')}
        </span>
        <StartMonthDropdown helpMessage={I18n.t('shared.startMonthHelpForLongTerm')} startMonth={startMonth} onChange={handleChangeStartMonth}/>
      </div>
      {[
            ['thisFiscalYear', 'lastFiscalYear', 'recent2FiscalYears'],
            ['recent6Months', 'recent12Months'],
            ['allPeriods'],
        ].map(function (types) { return (<div key={String(types)} className="mt-2 text-nowrap">
          {types.map(function (type) { return (<CrossTeamReportPeriodButton key={type} isSelected={selectedPeriod.period_type === type} startMonth={startMonth} type={type} onClick={function () { return handleChangeType(type); }}/>); })}
        </div>); })}

      {isSearched && (<div className="mt-3">
          <p className="font-weight-bold mb-1">
            {matchedReport
                ? I18n.t('shared.longTermReportCacheTitle', {
                    date: formatDate(matchedReport.created_at),
                })
                : I18n.t('shared.longTermReportGenerateTitle')}
          </p>
          <div className={"p-1 border-1 rounded !tw-cursor-pointer d-flex align-items-center ".concat(matchedReport ? '' : 'opacity-50')} onClick={handleChangeModeToCache}>
            <CustomRadio checked={mode === 'cache'} disabled={!matchedReport} onChange={function () { }}/>
            <div>
              <div>
                <span className="font-weight-bold mr-1">
                  {I18n.t("shared.longTermReportCacheLabelMain")}
                </span>
                {I18n.t("shared.longTermReportCacheLabelSub")}
                {matchedReport !== null && (<span className="badge badge-info ml-1">
                    {I18n.t('shared.recommended')}
                  </span>)}
              </div>
              <div className="text-muted">
                {I18n.t("shared.longTermReportCacheDescription")}
              </div>
            </div>
          </div>
          <div className="p-1 border-1 rounded !tw-cursor-pointer d-flex align-items-center mt-2" onClick={handleChangeModeToGenerate}>
            <CustomRadio checked={mode === 'generate'} disabled={!matchedReport} onChange={function () { }}/>
            <div>
              <div>
                <span className="font-weight-bold mr-1">
                  {I18n.t("shared.longTermReportGenerateLabelMain")}
                </span>
                {I18n.t("shared.longTermReportGenerateLabelSub")}
              </div>
              <div className="text-muted" dangerouslySetInnerHTML={{
                __html: I18n.t("shared.longTermReportGenerateDescription"),
            }}/>
            </div>
          </div>
          {!matchedReport && (<p className="text-muted mt-1">
              {I18n.t('shared.longTermReportGenerateNotice')}
            </p>)}
        </div>)}

      <div className="mt-3 text-right">
        <button className="btn btn-dark text-secondary border-1" onClick={onClose}>
          {I18n.t('shared.cancel')}
        </button>
        <button className="btn btn-primary ml-2" disabled={isLoading || !isSearched} onClick={handleClickGenerate}>
          {I18n.t('shared.startGeneration')}
        </button>
      </div>
    </>);
};
