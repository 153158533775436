import React from 'react';
import { useSelector } from 'react-redux';
import { ProxyInputUser } from '@/components/proxy_input_user';
export var TaskFormProxyInputUserContainer = function () {
    var user = useSelector(function (state) { return state.user; });
    var calendarUser = useSelector(function (state) { return state.calendar.user; });
    if (!calendarUser || calendarUser.id === user.id) {
        return null;
    }
    return (<ProxyInputUser avatarUrl={calendarUser.avatar_url} nickname={calendarUser.nickname}/>);
};
