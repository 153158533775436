import React from 'react';
import isEmpty from 'lodash/isEmpty';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
var SearchBar = function (props) {
    var onChangeKeyword = props.onChangeKeyword, onSearch = props.onSearch, onCancelSearch = props.onCancelSearch, keyword = props.keyword, isSearch = props.isSearch, additionalButton = props.additionalButton;
    var _a = React.useState(false), isFocusInput = _a[0], setIsFocusInput = _a[1];
    var keywordInput = React.useRef(null);
    var handleChangeKeyword = function (e) {
        onChangeKeyword(e.target.value);
    };
    var handleKeyPressKeyword = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSearch();
        }
    };
    var handleClickSearch = function () {
        onSearch();
    };
    var handleClickCancelSearch = function () {
        var _a;
        onCancelSearch();
        // @ts-ignore
        (_a = keywordInput === null || keywordInput === void 0 ? void 0 : keywordInput.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    return (<div className="toolbar">
      <div className="row no-gutters">
        <div className="col-12 col-sm-9">
          <div className="input-group">
            <input ref={keywordInput} autoFocus className="form-control" maxLength={700} placeholder={I18n.t('taskManagement.taskTitle')} type="text" value={keyword} onBlur={function () {
            setIsFocusInput(false);
        }} onChange={handleChangeKeyword} onFocus={function () {
            setIsFocusInput(true);
        }} onKeyPress={handleKeyPressKeyword}/>
            <div className="input-group-btn">
              {isSearch && (<button className="btn-toggler btn btn-secondary btn-lg" onClick={handleClickCancelSearch}>
                  <Icon name="cross" size="lg"/>
                </button>)}
            </div>
            <div className="input-group-btn">
              <button className="btn-major-action btn btn-primary btn-lg" disabled={!isFocusInput && isEmpty(keyword)} title={I18n.t('shared.search')} type="button" onClick={handleClickSearch}>
                <Icon name="search" size="lg"/>
              </button>
            </div>
          </div>
        </div>
        <div className="col-3 d-none d-sm-flex justify-content-end">
          {additionalButton}
        </div>
      </div>
    </div>);
};
export default SearchBar;
