import { __assign, __awaiter, __generator } from "tslib";
import throttle from 'lodash/throttle';
import api2 from '@/api2';
import { setCurrentEntry } from '@/modules/current_entry';
import { setUser } from '@/actions/setter';
// Actions
export var FETCH_CURRENT_USER_REQUEST = 'timecrowd/current_user/FETCH_CURRENT_USER_REQUEST';
export var FETCH_CURRENT_USER_SUCCESS = 'timecrowd/current_user/FETCH_CURRENT_USER_SUCCESS';
export var FETCH_CURRENT_USER_FAILURE = 'timecrowd/current_user/FETCH_CURRENT_USER_FAILURE';
// Reducer
var initialState = {
    loaded: false,
    failed: false,
    item: {},
};
export default function currentUser(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CURRENT_USER_REQUEST:
            return state;
        case FETCH_CURRENT_USER_SUCCESS:
            return {
                loaded: true,
                failed: false,
                item: action.payload.user,
            };
        case FETCH_CURRENT_USER_FAILURE:
            return __assign(__assign({}, state), { failed: true });
        default:
            return state;
    }
}
// Action Creators
export var fetchCurrentUserRequest = function () { return ({
    type: FETCH_CURRENT_USER_REQUEST,
}); };
export var fetchCurrentUserSuccess = function (user) { return ({
    type: FETCH_CURRENT_USER_SUCCESS,
    payload: { user: user },
}); };
export var fetchCurrentUserFailure = function () { return ({
    type: FETCH_CURRENT_USER_FAILURE,
}); };
export var unthrottledFetchCurrentUser = function () {
    return function (dispatch, getState) {
        dispatch(fetchCurrentUserRequest());
        return api2(dispatch, getState, '/api/v2/user', 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (user) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        dispatch(setCurrentEntry(user.time_entry));
                        dispatch(setUser(user));
                        dispatch(fetchCurrentUserSuccess(user));
                        return [2 /*return*/, user];
                    });
                }); });
            }
            else {
                dispatch(fetchCurrentUserFailure());
                return { error: true };
            }
        });
    };
};
var throttledFetchCurrentUser = throttle(unthrottledFetchCurrentUser(), 3000, { trailing: false });
export var fetchCurrentUser = function () { return throttledFetchCurrentUser; };
