import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import TimeEntryListItem from './time_entry_list_item';
import I18n from '@/constants/i18n';
import Duration from '@/components/atoms/duration';
var TimeEntryList = function (props) {
    var timeEntries = props.timeEntries, task = props.task, query = props.query, destroyTimeEntry = props.destroyTimeEntry;
    var format = 'YYYY-MM-DD';
    var isHeaderNeeded = function (index, timeEntry) {
        if (index === 0) {
            return true;
        }
        var prev = moment(timeEntries[index - 1].started_at);
        var current = moment(timeEntry.started_at);
        var isDateChanged = prev.format(format) !== current.format(format);
        return isDateChanged;
    };
    var totalTime = function (timeEntry) {
        var baseDate = moment(timeEntry.started_at).format('YYYY-MM-DD');
        var duration = timeEntries.reduce(function (total, t) {
            var date = moment(t.started_at).format('YYYY-MM-DD');
            if (baseDate === date) {
                return total + t.duration;
            }
            else {
                return total;
            }
        }, 0);
        return <Duration seconds={duration}/>;
    };
    return (<div className="list-group time-entry-list">
      {timeEntries.map(function (timeEntry, index) {
            return [
                <div key={"timeEntry-".concat(timeEntry.id)}>
            {isHeaderNeeded(index, timeEntry) && (<div className="list-group-header">
                <span className="font-weight-bold">
                  {moment(timeEntry.started_at)
                            .locale(I18n.locale)
                            .format('YYYY-MM-DD(ddd)')}
                </span>
                <span className="ml-auto">{totalTime(timeEntry)}</span>
              </div>)}
            <TimeEntryListItem destroyTimeEntry={destroyTimeEntry} query={query} task={task} timeEntry={timeEntry}/>
          </div>,
            ];
        })}
    </div>);
};
export default TimeEntryList;
