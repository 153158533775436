import React from 'react';
import { Link } from 'react-router-dom';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { workspacePath } from '@/utils/workspace_helper';
import { buildTeamMenuItems } from './helpers';
export var TeamNavMenu = function (_a) {
    var user = _a.user, team = _a.team, currentPage = _a.currentPage, onClick = _a.onClick;
    var menuItems = buildTeamMenuItems(team, user);
    return (<ul className="flex-column pt-sm-1 nav">
      {!team.payment_required && (<li className="nav-item hidden-sm-down">
          <Link className="nav-link text-primary font-weight-normal" to={workspacePath("/report?team_id=".concat(team.id))}>
            <Icon name="graph-pie mr-2"/>
            {I18n.t('shared.goToTeamReport')}
          </Link>
        </li>)}
      <li className="nav-item small my-1 hidden-sm-down">
        {I18n.t('shared.settings')}
      </li>
      {menuItems.map(function (item) { return (<li key={item.name} className="nav-item">
          <a className={"".concat(currentPage === item.name ? 'active ' : '', "nav-link")} href={workspacePath(item.path)} onClick={onClick}>
            <Icon className="mr-2" color="info" name={item.icon}/>
            {I18n.t(item.label)}
            {item.isBeta && (<span className="badge badge-info ml-2">
                {I18n.t('shared.beta')}
              </span>)}
          </a>
        </li>); })}
    </ul>);
};
export default TeamNavMenu;
