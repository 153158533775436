export var findParentCategory = function (category, categories) {
    if (!category.ancestry) {
        return null;
    }
    var parentId = parseInt(category.ancestry.split('/').pop());
    return categories.find(function (_a) {
        var id = _a.id;
        return id === parentId;
    });
};
