import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchWorkspacesCalendarIntegrationSetting } from '@/modules/workspaces_calendar_integration_settings';
import { WorkspacesSettingsSubpane } from '@/components/workspaces_settings_subpane';
var WorkspacesSettingsSubpaneContainer = function (_a) {
    var active = _a.active, onNavigate = _a.onNavigate;
    var settings = useSelector(function (state) { return state.workspacesCalendarIntegrationSettings; });
    var dispatch = useDispatch();
    var location = useLocation();
    React.useEffect(function () {
        // NOTE: カレンダー同期設定画面の場合は画面側の取得に任せるため取得しない
        if (location.pathname.includes('calendar_integration')) {
            return;
        }
        dispatch(fetchWorkspacesCalendarIntegrationSetting());
    }, [dispatch, location.pathname]);
    if (settings.status !== 'loaded') {
        return null;
    }
    return (<WorkspacesSettingsSubpane active={active} setting={settings.item} onNavigate={onNavigate}/>);
};
export default WorkspacesSettingsSubpaneContainer;
