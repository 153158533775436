import { __assign } from "tslib";
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { buildPieChartDataForCategory, buildPieChartDataForTeam, buildPieChartTooltipLabel, sortByTotalTime, } from '@/components/report_chart/helpers';
import I18n from '@/constants/i18n';
export var TeamReportPieChart = function (_a) {
    var categoryTotals = _a.categoryTotals, teamTotals = _a.teamTotals, categories = _a.categories, teams = _a.teams, _b = _a.withUnitSelector, withUnitSelector = _b === void 0 ? false : _b;
    var _c = React.useState('category'), unit = _c[0], setUnit = _c[1];
    var sortedCategories = sortByTotalTime(categories
        .filter(function (c) { return c.ancestry_depth === 0; })
        .map(function (c) {
        var _a, _b;
        return (__assign(__assign({}, c), { total_time: (_b = (_a = categoryTotals[c.id]) === null || _a === void 0 ? void 0 : _a.duration) !== null && _b !== void 0 ? _b : 0 }));
    })
        .filter(function (c) { return c.total_time > 0; }));
    var sortedTeams = sortByTotalTime(teams
        .map(function (t) {
        var _a, _b;
        return (__assign(__assign({}, t), { total_time: (_b = (_a = teamTotals[t.id]) === null || _a === void 0 ? void 0 : _a.duration) !== null && _b !== void 0 ? _b : 0 }));
    })
        .filter(function (t) { return t.total_time > 0; }));
    var items = unit === 'category' ? sortedCategories : sortedTeams;
    var itemsLimit = 5;
    var topItems = items.slice(0, itemsLimit);
    var otherItems = items.slice(itemsLimit);
    var doughnutData = unit === 'category'
        ? buildPieChartDataForCategory(topItems, otherItems)
        : buildPieChartDataForTeam(topItems, otherItems);
    var doughnutOptions = {
        plugins: {
            legend: {
                position: 'left',
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return buildPieChartTooltipLabel(tooltipItem, otherItems, itemsLimit);
                    },
                },
            },
        },
        cutout: '78%',
        animation: false,
        aspectRatio: 2,
    };
    return (<div className="d-flex justify-content-end">
      <div>
        <Doughnut data={doughnutData} options={doughnutOptions}/>
      </div>
      {withUnitSelector && (<UncontrolledDropdown className="ml-2">
          <DropdownToggle caret>{I18n.t("shared.".concat(unit))}</DropdownToggle>
          <DropdownMenu right>
            {['category', 'team'].map(function (unit) { return (<DropdownItem key={unit} onClick={function () { return setUnit(unit); }}>
                {I18n.t("shared.".concat(unit))}
              </DropdownItem>); })}
          </DropdownMenu>
        </UncontrolledDropdown>)}
    </div>);
};
