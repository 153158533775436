import { __assign, __spreadArray } from "tslib";
import api2, { getNextLink } from '@/api2';
import { ignoreArchivedTasks } from '@/modules/task_search';
export var FETCH_RECENT_TASKS_REQUEST = 'timecrowd/recent_tasks/FETCH_RECENT_TASKS_REQUEST';
export var FETCH_RECENT_TASKS_SUCCESS = 'timecrowd/recent_tasks/FETCH_RECENT_TASKS_SUCCESS';
export var EXPAND_RECENT_TASKS_REQUEST = 'timecrowd/recent_tasks/EXPAND_RECENT_TASKS_REQUEST';
export var EXPAND_RECENT_TASKS_SUCCESS = 'timecrowd/recent_tasks/EXPAND_RECENT_TASKS_SUCCESS';
export var FETCH_USER_RECENT_TASKS_REQUEST = 'timecrowd/recent_tasks/FETCH_USER_RECENT_TASKS_REQUEST';
export var FETCH_USER_RECENT_TASKS_SUCCESS = 'timecrowd/recent_tasks/FETCH_USER_RECENT_TASKS_SUCCESS';
export var EXPAND_USER_RECENT_TASKS_REQUEST = 'timecrowd/recent_tasks/EXPAND_USER_RECENT_TASKS_REQUEST';
export var EXPAND_USER_RECENT_TASKS_SUCCESS = 'timecrowd/recent_tasks/EXPAND_USER_RECENT_TASKS_SUCCESS';
export var RESET_RECENT_TASKS = 'timecrowd/recent_tasks/RESET_RECENT_TASKS';
export var fetchRecentTasks = function () {
    return function (dispatch, getState) {
        dispatch(fetchRecentTasksRequest());
        return api2(dispatch, getState, "/api/v2/recent_tasks?new=1", 'GET').then(function (response) {
            if (response.ok) {
                var next_1 = getNextLink(response);
                response.json().then(function (json) {
                    dispatch(fetchRecentTasksSuccess(json, next_1));
                });
            }
        });
    };
};
export var expandRecentTasks = function () {
    return function (dispatch, getState) {
        var next = getState().recentTasks.next;
        dispatch(expandRecentTasksRequest());
        return api2(dispatch, getState, next, 'GET').then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    var newNext = getNextLink(response);
                    dispatch(expandRecentTasksSuccess(json, newNext));
                });
            }
        });
    };
};
export var fetchUserRecentTasks = function (user_id) {
    return function (dispatch, getState) {
        dispatch(fetchUserRecentTasksRequest());
        return api2(dispatch, getState, "/api/v2/users/".concat(user_id, "/recent_tasks"), 'GET').then(function (response) {
            if (response.ok) {
                var next_2 = getNextLink(response);
                response.json().then(function (json) {
                    dispatch(fetchUserRecentTasksSuccess(json, next_2));
                });
            }
        });
    };
};
export var expandUserRecentTasks = function () {
    return function (dispatch, getState) {
        var next = getState().recentTasks.next;
        dispatch(expandRecentTasksRequest());
        return api2(dispatch, getState, next, 'GET').then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    var newNext = getNextLink(response);
                    dispatch(expandUserRecentTasksSuccess(json, newNext));
                });
            }
        });
    };
};
export var initialState = {
    isLoading: false,
    items: [],
    next: null,
};
export default function recentTasks(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_RECENT_TASKS_REQUEST:
        case EXPAND_RECENT_TASKS_REQUEST:
        case FETCH_USER_RECENT_TASKS_REQUEST:
        case EXPAND_USER_RECENT_TASKS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_RECENT_TASKS_SUCCESS:
        case FETCH_USER_RECENT_TASKS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: ignoreArchivedTasks(action.tasks), next: action.next });
        case EXPAND_RECENT_TASKS_SUCCESS:
        case EXPAND_USER_RECENT_TASKS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: ignoreArchivedTasks(__spreadArray(__spreadArray([], state.items, true), action.tasks, true)), next: action.next });
        case RESET_RECENT_TASKS:
            return initialState;
        default:
            return state;
    }
}
export var fetchRecentTasksRequest = function () { return ({
    type: FETCH_RECENT_TASKS_REQUEST,
}); };
export var fetchRecentTasksSuccess = function (tasks, next) { return ({
    type: FETCH_RECENT_TASKS_SUCCESS,
    tasks: tasks,
    next: next,
}); };
export var expandRecentTasksRequest = function () { return ({
    type: EXPAND_RECENT_TASKS_REQUEST,
}); };
export var expandRecentTasksSuccess = function (tasks, next) { return ({
    type: EXPAND_RECENT_TASKS_SUCCESS,
    tasks: tasks,
    next: next,
}); };
export var fetchUserRecentTasksRequest = function () { return ({
    type: FETCH_USER_RECENT_TASKS_REQUEST,
}); };
export var fetchUserRecentTasksSuccess = function (tasks, next) { return ({
    type: FETCH_USER_RECENT_TASKS_SUCCESS,
    tasks: tasks,
    next: next,
}); };
export var expandUserRecentTasksRequest = function () { return ({
    type: EXPAND_USER_RECENT_TASKS_REQUEST,
}); };
export var expandUserRecentTasksSuccess = function (tasks, next) { return ({
    type: EXPAND_USER_RECENT_TASKS_SUCCESS,
    tasks: tasks,
    next: next,
}); };
export var resetRecentTasks = function () { return ({
    type: RESET_RECENT_TASKS,
}); };
