import React from 'react';
import MediaQuery from 'react-responsive';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import I18n from '@/constants/i18n';
import { BREAKPOINT_MD } from '@/constants/styles';
import Icon from '@/components/atoms/icon';
import FormNav from '@/components/atoms/form_nav';
import MobileHeader from '@/components/atoms/mobile_header';
import { TeamNavMenu, } from '@/components/team_management/team_nav_menu';
import MainMenuContainer from '@/containers/main_menu_container';
import TeamSelect from '@/components/team_management/team_select';
import MobilePage from '@/components/molecules/mobile_page';
import Subpane from '@/components/molecules/subpane';
import LoadingPage from '@/components/pages/loading_page';
import NotFound from '@/components/pages/not_found';
import { workspacePath } from '@/utils/workspace_helper';
import { buildTeamMenuItems } from '@/components/team_management/helpers';
export var TeamManagementLayout = function (_a) {
    var user = _a.user, team = _a.team, teams = _a.teams, isLoading = _a.isLoading, isTeamMenuOpened = _a.isTeamMenuOpened, children = _a.children, currentPage = _a.currentPage, title = _a.title, _b = _a.saveButtonForPC, saveButtonForPC = _b === void 0 ? null : _b, _c = _a.saveButtonForSP, saveButtonForSP = _c === void 0 ? null : _c, setNotificationMessage = _a.setNotificationMessage, toggleTeamMenu = _a.toggleTeamMenu;
    var navigate = useNavigate();
    var location = useLocation();
    var currentPath = location.pathname.split('/').pop();
    var onSelectTeam = React.useCallback(function (id) { return navigate(workspacePath("/teams/".concat(id, "/").concat(currentPath))); }, [navigate, currentPath]);
    var onClickMenu = React.useCallback(function (e) {
        e.preventDefault();
        navigate(e.target.pathname);
        toggleTeamMenu(false);
    }, [navigate, toggleTeamMenu]);
    if (isLoading) {
        return <LoadingPage />;
    }
    if (!team) {
        return <NotFound />;
    }
    var menuItems = buildTeamMenuItems(team, user);
    if (!menuItems.find(function (_a) {
        var name = _a.name;
        return name === currentPage;
    })) {
        setNotificationMessage('danger', I18n.t('shared.cannotShowMenuForTeam', { team: team.name, menu: title }));
        navigate(workspacePath("/teams/".concat(team.id, "/members")));
    }
    return (<div className="content-wrap">
      <MediaQuery className="d-flex w-100" minWidth={BREAKPOINT_MD}>
        <MainMenuContainer />
        <div className="content-main">
          <div className="row no-gutters">
            <div className="col-md-9 col-12 px-5 py-3">
              <div className="row no-gutters">
                <FormNav>
                  <div className="d-flex w-100">
                    <div className="form-title">{title}</div>
                    <TeamSelect className="w-96 ml-3" team={team} teams={teams} onClick={onSelectTeam}/>
                    <div className="ml-auto">
                      {saveButtonForPC !== null && saveButtonForPC}
                    </div>
                  </div>
                </FormNav>
              </div>
              {children}
            </div>
            <Subpane className="col-md-3 col-12">
              <TeamNavMenu currentPage={currentPage} team={team} user={user} onClick={onClickMenu}/>
            </Subpane>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={BREAKPOINT_MD}>
        {isTeamMenuOpened && (<MobilePage>
            <MobileHeader className="justify-content-center">
              <Link className="btn btn-link btn-mobile-header-action btn-mobile-header-action-left position-absolute left-0" to="/app/other">
                <Icon name="chevron-left"/>
              </Link>
              <span className="mobile-header-title">
                {I18n.t('shared.teamSettings')}
              </span>
            </MobileHeader>
            <TeamSelect team={team} teams={teams} onClick={onSelectTeam}/>
            <TeamNavMenu currentPage={currentPage} team={team} user={user} onClick={onClickMenu}/>
          </MobilePage>)}
        {!isTeamMenuOpened && (<MobilePage>
            <MobileHeader>
              <button className="btn-mobile-header-action btn-mobile-header-action-left btn btn-link" onClick={function () { return toggleTeamMenu(true); }}>
                <Icon name="chevron-left"/>
              </button>
              <span className="mobile-header-title">{title}</span>
              <span>{saveButtonForSP !== null && saveButtonForSP}</span>
            </MobileHeader>
            <div className="px-2 py-3">{children}</div>
          </MobilePage>)}
      </MediaQuery>
    </div>);
};
