import React from 'react';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import { formatDateTime, formatDuration } from '@/utils/time_helper';
import { numberWithDelimiter } from '@/utils/number_helper';
import { TeamReportPieChart } from '@/components/report_chart/team_report_pie_chart';
import Icon from '@/components/atoms/icon';
import { periodLabel } from '@/components/cross_team_report_header/helpers';
export var CrossTeamReportSummary = function (props) {
    var categoryTotals = props.categoryTotals, report = props.report, selectedBookmark = props.selectedBookmark, teamTotals = props.teamTotals, teams = props.teams, totalAmount = props.totalAmount, totalDuration = props.totalDuration, onRegenerate = props.onRegenerate;
    return (<>
      <div className="px-3 pt-3 pb-2 border-bottom-1 d-flex">
        <div className="w-100">
          {selectedBookmark && selectedBookmark.name.length > 0 && (<p className="h2 leading-8 mt-1 mb-2">{selectedBookmark.name}</p>)}
          <div className="d-flex flex-wrap">
            {teams.map(function (team) { return (<div key={team.id} className="mr-2 d-flex align-items-center">
                <Avatar className="mr-1" image={team.avatar_url} size="sm"/>
                <span className="font-weight-bold">{team.name}</span>
              </div>); })}
          </div>
          <div className="text-muted small mt-1">
            {report.period_type !== 'custom' && (<span className="font-weight-bold mr-1">
                {I18n.t("shared.".concat(report.period_type))}
              </span>)}
            {periodLabel(report)}
          </div>
        </div>
        <div className="text-right">
          <button className="btn btn-outline-primary" onClick={onRegenerate}>
            {I18n.t('shared.generateLatestReport')}
          </button>
          <p className="text-muted d-flex align-items-center small text-nowrap mt-1">
            <Icon className="mr-1" name="clock"/>
            {"".concat(I18n.t('shared.lastUpdated'), ": ")}
            {formatDateTime(report.generated_at)}
          </p>
        </div>
      </div>

      <div className="border-bottom-1 row no-gutters">
        <div className="my-4 pl-3 d-flex align-items-center col-xl-6">
          <div>
            <p className="mb-0">{I18n.t('shared.totalDuration')}</p>
            <span className="h2">{formatDuration(totalDuration)}</span>
          </div>
          <div className="ml-5">
            <p className="mb-0">{I18n.t('shared.totalAmount')}</p>
            <span className="h2">
              {I18n.t('shared.currencyUnit')}
              {numberWithDelimiter(totalAmount)}
            </span>
          </div>
        </div>
        <div className="my-3 pr-3 col-xl-6">
          <TeamReportPieChart categories={report.categories} categoryTotals={categoryTotals} teams={report.teams} teamTotals={teamTotals} withUnitSelector={true}/>
        </div>
      </div>
    </>);
};
