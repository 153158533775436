import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import Avatar from '@/components/atoms/avatar';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import { periodLabel } from '@/components/cross_team_report_header/helpers';
export var CrossTeamReportConditionsInfo = function (props) {
    var conditions = props.conditions, _a = props.withDetails, withDetails = _a === void 0 ? false : _a, withDate = props.withDate;
    var teamById = conditions.category_teams.reduce(function (acc, team) {
        acc[team.id] = team;
        return acc;
    }, {});
    return (<div>
      <div className={"d-flex align-items-center ".concat(withDetails ? 'mb-2' : '')}>
        <Icon className="mr-1" name="calendar"/>
        {withDetails && <span className="mr-2">{I18n.t('report.period')}</span>}
        {conditions.period_type !== 'custom' && (<span className="mr-1">
            {I18n.t("shared.".concat(conditions.period_type))}
          </span>)}
        {withDate && periodLabel(conditions)}
      </div>
      {conditions.selected_teams.length > 0 && (<div>
          <div className="d-flex align-items-center">
            <Icon className="mr-1" name="user-group"/>
            {withDetails && (<span className="mr-2">{I18n.t('shared.team')}</span>)}
            {!withDetails && (<>
                {conditions.selected_teams[0].name}
                {conditions.selected_teams.length > 1 && (<>
                    <span className="ml-1">
                      {"+".concat(conditions.selected_teams.length - 1)}
                    </span>
                  </>)}
              </>)}
          </div>
          {withDetails && (<div className="bg-white d-flex flex-wrap overflow-y-auto max-h-32 px-2 py-1 gap-y-2 mt-1 mb-3 rounded">
              {conditions.selected_teams.map(function (team) { return (<div key={team.id} className="d-flex align-items-center mr-2">
                  <Avatar className="border-0 mr-1" image={team.avatar_url} size="sm"/>
                  <span className="text-break">{team.name}</span>
                </div>); })}
            </div>)}
        </div>)}
      {conditions.selected_users.length > 0 && (<div>
          <div className="d-flex align-items-center">
            <Icon className="mr-1" name="user"/>
            {withDetails && (<span className="mr-2">{I18n.t('report.period')}</span>)}
            {!withDetails && (<>
                {conditions.selected_users[0].nickname}
                {conditions.selected_users.length > 1 && (<span className="ml-1">
                    {"+".concat(conditions.selected_users.length - 1)}
                  </span>)}
              </>)}
          </div>
          {withDetails && (<div className="bg-white d-flex flex-wrap overflow-y-auto max-h-32 px-2 py-1 gap-y-2 mt-1 mb-3 rounded">
              {conditions.selected_users.map(function (user) { return (<div key={user.id} className="d-flex align-items-center mr-2">
                  <Avatar className="border-0 mr-1" image={user.avatar_url} size="sm"/>
                  <span className="text-break">{user.nickname}</span>
                </div>); })}
            </div>)}
        </div>)}
      {conditions.selected_categories.length > 0 && (<div>
          <div className="d-flex align-items-center">
            <Icon className="mr-1" name="tag"/>
            {withDetails && (<span className="mr-2">{I18n.t('shared.category')}</span>)}
            {!withDetails && (<>
                {conditions.selected_categories[0].title}
                {conditions.selected_categories.length > 1 && (<>
                    <span className="ml-1">
                      {"+".concat(conditions.selected_categories.length - 1)}
                    </span>
                  </>)}
              </>)}
          </div>
          {withDetails && (<div className="bg-white d-flex flex-wrap overflow-y-auto max-h-32 px-2 py-1 gap-y-2 mt-1 mb-3 rounded">
              {conditions.selected_categories.map(function (category) { return (<div key={category.id} className="d-flex align-items-center mr-2">
                  <Avatar className="border-0 mr-1" image={teamById[category.team_id].avatar_url} size="sm"/>
                  <CategoryCircleIcon className="mr-1" color={category.color}/>
                  <span className="text-break">{category.title}</span>
                </div>); })}
            </div>)}
        </div>)}
    </div>);
};
