import React from 'react';
import { formatTime } from './helpers';
var Counter = function (props) {
    var formattedTime = React.useCallback(function () {
        return formatTime(props.entry, props.totalTimeSec);
    }, [props.entry, props.totalTimeSec]);
    var _a = React.useState(null), timer = _a[0], setTimer = _a[1];
    var _b = React.useState(formattedTime()), text = _b[0], setText = _b[1];
    React.useEffect(function () {
        var t = setInterval(function () {
            setText(formattedTime());
        }, 1000);
        setTimer(t);
    }, [formattedTime]);
    React.useEffect(function () {
        return function () {
            clearInterval(timer);
        };
    }, [timer]);
    return <span className="counter">{text}</span>;
};
export default Counter;
