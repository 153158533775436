import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TaskSuggestion from '@/components/task_suggestion';
import { expandSuggestTasks } from '@/actions/task';
import { fetchRecentTasks, expandRecentTasks, fetchUserRecentTasks, expandUserRecentTasks, } from '@/modules/recent_tasks';
var TaskSuggestionContainer = function (_a) {
    var category = _a.category, isCategorySelected = _a.isCategorySelected, isInitial = _a.isInitial, isOpen = _a.isOpen, task = _a.task, onSelectTaskFromSuggestions = _a.onSelectTaskFromSuggestions;
    var taskSearch = useSelector(function (state) { return state.taskSearch; });
    var recentTasks = useSelector(function (state) { return state.recentTasks; });
    var user = useSelector(function (state) { return state.user; });
    var calendarUser = useSelector(function (state) { return state.calendar.user; });
    var user_id = useParams().user_id;
    var dispatch = useDispatch();
    var handlePaginateSuggestTasks = function () {
        return dispatch(expandSuggestTasks());
    };
    var handleExpandRecentTasks = React.useCallback(function () {
        if (user_id) {
            return dispatch(expandUserRecentTasks());
        }
        else {
            return dispatch(expandRecentTasks());
        }
    }, [dispatch, user_id]);
    React.useEffect(function () {
        if (user_id) {
            dispatch(fetchUserRecentTasks(user_id));
        }
        else {
            dispatch(fetchRecentTasks());
        }
    }, [dispatch, user_id]);
    if (!isOpen) {
        return null;
    }
    return (<TaskSuggestion calendarUser={calendarUser} category={category} expandRecentTasks={handleExpandRecentTasks} isCategorySelected={isCategorySelected} isInitial={isInitial} keyword={task.title} recentTasks={recentTasks} taskSearch={taskSearch} user={user} onPaginateSuggestTasks={handlePaginateSuggestTasks} onSelectTaskFromSuggestions={onSelectTaskFromSuggestions}/>);
};
export default TaskSuggestionContainer;
