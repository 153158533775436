import React from 'react';
import I18n from '@/constants/i18n';
import Helplink from '@/components/molecules/helplink';
import CustomCheck from '@/components/atoms/custom_check';
export var CalendarIntegrationIgnoreMinutesSetting = function (_a) {
    var ignoreMinutes = _a.ignoreMinutes, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, isIgnoreMinutes = _a.isIgnoreMinutes, onChangeIgnoreMinutes = _a.onChangeIgnoreMinutes, onChangeIsIgnoreMinutes = _a.onChangeIsIgnoreMinutes, onClickLink = _a.onClickLink;
    return (<div className="form-group mt-3">
      <label className="font-weight-bold">
        {I18n.t('shared.ignoreMinutes')}
        <Helplink className="ml-1" href="https://help.timecrowd.net/various-calendar-options#exclusion-settings" onClick={onClickLink}/>
      </label>
      <div>
        <CustomCheck checked={isIgnoreMinutes} disabled={isDisabled} onChange={onChangeIsIgnoreMinutes}>
          {I18n.t('shared.ignoreMinutesLabel')}
        </CustomCheck>
        <div className={"align-items-start mt-1 ml-3 ".concat(isIgnoreMinutes ? 'd-flex' : 'd-none')}>
          <input className="form-control w-25" disabled={isDisabled} type="number" value={ignoreMinutes} onChange={onChangeIgnoreMinutes}/>
          <span className="mt-1 ml-1">
            {I18n.t('shared.ignoreMinutesNote')}
          </span>
        </div>
      </div>
    </div>);
};
