import { __assign } from "tslib";
import { sortTeams } from '@/modules/teams';
export var SET_REPORT_TEAMS = 'timecrowd/report/SET_TEAMS';
export var SET_REPORT_PERIODS = 'timecrowd/report/SET_PERIODS';
export var SET_TEAM_REPORT = 'timecrowd/report/SET_TEAM_REPORT';
export var SET_REPORT_SORT_ORDER = 'timecrowd/report/SET_REPORT_SORT_ORDER';
export var SET_REPORT_SORT_TARGET = 'timecrowd/report/SET_REPORT_SORT_TARGET';
export var RESET_REPORT = 'timecrowd/report/RESET_REPORT';
export var initialState = {
    teams: [],
    periods: [],
    teamReport: null,
    sortOrder: 'asc',
    sortBy: 'total_time',
};
export default function report(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_REPORT_TEAMS:
            return __assign(__assign({}, state), { teams: sortTeams(action.teams) });
        case SET_REPORT_PERIODS:
            return __assign(__assign({}, state), { periods: action.periods });
        case SET_TEAM_REPORT:
            return __assign(__assign({}, state), { teamReport: action.teamReport });
        case SET_REPORT_SORT_ORDER:
            return __assign(__assign({}, state), { sortOrder: action.payload });
        case SET_REPORT_SORT_TARGET:
            return __assign(__assign({}, state), { sortBy: action.payload });
        case RESET_REPORT:
            return initialState;
        default:
            return state;
    }
}
// Action Creators
export var setReportTeams = function (teams) { return ({
    type: SET_REPORT_TEAMS,
    teams: teams,
}); };
export var setReportPeriods = function (periods) { return ({
    type: SET_REPORT_PERIODS,
    periods: periods,
}); };
export var setTeamReport = function (teamReport) { return ({
    type: SET_TEAM_REPORT,
    teamReport: teamReport,
}); };
export var setReportSortOrder = function (sortOrder) { return ({
    type: SET_REPORT_SORT_ORDER,
    payload: sortOrder,
}); };
export var setReportSortTarget = function (sortTarget) { return ({
    type: SET_REPORT_SORT_TARGET,
    payload: sortTarget,
}); };
export var resetReport = function () { return ({
    type: RESET_REPORT,
}); };
