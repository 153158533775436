import { __assign } from "tslib";
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { formatDate, quarterOfYear } from '@/utils/time_helper';
export var calcFromAndToForType = function (type, startMonth) {
    var from = moment();
    var to = from;
    switch (type) {
        case 'yesterday':
            from = moment().subtract(1, 'days');
            to = from;
            break;
        case 'thisWeek':
            from = moment().startOf('week').add(1, 'days');
            to = from.clone().add(6, 'days');
            break;
        case 'lastWeek':
            from = moment().subtract(1, 'weeks').startOf('week').add(1, 'days');
            to = from.clone().add(6, 'days');
            break;
        case 'recent2Weeks':
            from = moment().subtract(1, 'weeks').startOf('week').add(1, 'days');
            to = moment().endOf('week').add(1, 'days');
            break;
        case 'thisMonth':
            from = moment().startOf('month');
            to = moment().endOf('month');
            break;
        case 'lastMonth':
            from = moment().subtract(1, 'months').startOf('month');
            to = from.clone().endOf('month');
            break;
        case 'monthBeforeLast':
            from = moment().subtract(2, 'months').startOf('month');
            to = from.clone().endOf('month');
            break;
        case 'recent2Months':
            from = moment().subtract(1, 'months').startOf('month');
            to = moment().endOf('month');
            break;
        case 'currentQuarter': {
            var quarter = calcQuarter(moment(), startMonth);
            from = quarter.from;
            to = quarter.to;
            break;
        }
        case 'lastQuarter': {
            var quarter = calcQuarter(moment().subtract(3, 'months'), startMonth);
            from = quarter.from;
            to = quarter.to;
            break;
        }
        case 'thisFiscalYear':
            from = startOfFiscalYear(moment(), startMonth);
            to = from.clone().add(11, 'months').endOf('month');
            break;
        case 'lastFiscalYear':
            from = startOfFiscalYear(moment().subtract(1, 'year'), startMonth);
            to = from.clone().add(11, 'months').endOf('month');
            break;
        case 'recent2FiscalYears':
            from = startOfFiscalYear(moment().subtract(1, 'year'), startMonth);
            to = from.clone().add(23, 'months').endOf('month');
            break;
        case 'recent6Months':
            from = moment().subtract(5, 'months').startOf('month');
            to = moment().endOf('month');
            break;
        case 'recent12Months':
            from = moment().subtract(11, 'months').startOf('month');
            to = moment().endOf('month');
            break;
        // NOTE: react-infinite-calendarのmin/maxに合わせている
        case 'allPeriods':
            from = moment('1980-01-01');
            to = moment('2050-12-31');
            break;
    }
    return {
        from: formatDate(from),
        to: formatDate(to),
    };
};
var calcQuarter = function (target, startMonth) {
    var from, to;
    var taregtQuarter = quarterOfYear(target, startMonth).quarter;
    var isFoundFrom = false;
    for (var i = -2; i < 4; i++) {
        var month = target.clone().add(i, 'months');
        var quarter = quarterOfYear(month, startMonth).quarter;
        if (quarter === taregtQuarter && !isFoundFrom) {
            from = month.startOf('month');
            isFoundFrom = true;
        }
        else if (quarter !== taregtQuarter && isFoundFrom) {
            to = month.add(-1, 'months').endOf('month');
            break;
        }
    }
    return { from: from, to: to };
};
export var startOfFiscalYear = function (date, startMonth) {
    var year = date.month() + 1 < startMonth ? date.year() - 1 : date.year();
    return moment([year, startMonth - 1, 1]);
};
export var buildUsableUsers = function (selectedTeams, memberships, allUsers, selectedUsers) {
    if (selectedTeams.length < 1) {
        return {
            usableAllUsers: allUsers,
            usableSelectedUsers: selectedUsers,
        };
    }
    var teamsIdSet = new Set(selectedTeams.map(function (team) { return team.id; }));
    var userIdSet = new Set(memberships
        .filter(function (membership) { return teamsIdSet.has(membership.team_id); })
        .map(function (membership) { return membership.user_id; }));
    return {
        usableAllUsers: allUsers.filter(function (user) { return userIdSet.has(user.id); }),
        usableSelectedUsers: selectedUsers.filter(function (user) { return userIdSet.has(user.id); }),
    };
};
export var buildLeavedUserIds = function (selectedTeams, memberships, workspacesMemberships) {
    if (selectedTeams.length > 0) {
        var teamsIdSet_1 = new Set(selectedTeams.map(function (team) { return team.id; }));
        var belongsUserIdSet_1 = new Set(memberships
            .filter(function (membership) {
            return teamsIdSet_1.has(membership.team_id) && !membership.deleted_at;
        })
            .map(function (membership) { return membership.user_id; }));
        return Array.from(new Set(memberships
            .filter(function (membership) {
            return !belongsUserIdSet_1.has(membership.user_id) && membership.deleted_at;
        })
            .map(function (membership) { return membership.user_id; })));
    }
    else {
        return workspacesMemberships
            .filter(function (membership) { return membership.deleted_at; })
            .map(function (membership) { return membership.user_id; });
    }
};
export var buildDefaultPeriod = function (startMonth) {
    if (startMonth === void 0) { startMonth = 1; }
    var period_type = 'thisMonth';
    return __assign({ term: 'realtime', period_type: period_type }, calcFromAndToForType(period_type, startMonth));
};
export var periodLabel = function (conditions) {
    var period_type = conditions.period_type, from = conditions.from, to = conditions.to;
    if (period_type !== 'allPeriods') {
        return "".concat(from, " - ").concat(to);
    }
    else {
        return '';
    }
};
