import React from 'react';
import MainMenuContainer from '@/containers/main_menu_container';
import LoadingSpinner from '@/components/atoms/loading_spinner';
export default function Placeholder() {
    return (<div className="content-wrap">
      <MainMenuContainer isPlaceholder/>
      <div className="content-main">
        <div className="toolbar stopped"/>
        <div className="row no-gutters with-toolbar with-bottom-bar-only-mobile-app">
          <div className="tasklist">
            <div className="py-4 text-center">
              <LoadingSpinner size="md"/>
            </div>
          </div>
        </div>
      </div>
    </div>);
}
