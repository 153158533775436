import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import api2, { getNextLink } from '@/api2';
export var FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_REQUEST = 'timecrowd/CATEGORY_TASK_STRUCTURES_IMPORTS/FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_REQUEST';
export var FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_SUCCESS = 'timecrowd/CATEGORY_TASK_STRUCTURES_IMPORTS/FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_SUCCESS';
export var FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_FAILURE = 'timecrowd/CATEGORY_TASK_STRUCTURES_IMPORTS/FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_FAILURE';
export var CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST = 'timecrowd/CATEGORY_TASK_STRUCTURES_IMPORTS/CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST';
export var CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS = 'timecrowd/CATEGORY_TASK_STRUCTURES_IMPORTS/CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS';
export var CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE = 'timecrowd/CATEGORY_TASK_STRUCTURES_IMPORTS/CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE';
var initialState = {
    isLoading: false,
    items: [],
    page: 0,
    hasNext: false,
};
export default function categoryTaskStructuresImports(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_REQUEST:
        case CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_SUCCESS:
            return __assign(__assign(__assign({}, state), action.payload), { items: __spreadArray(__spreadArray([], (action.payload.page === 1 ? [] : state.items), true), action.payload.items, true), isLoading: false });
        case FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_FAILURE:
        case CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS:
            return __assign(__assign({}, state), { items: __spreadArray(__spreadArray([], state.items, true), [action.payload], false), isLoading: false });
        default:
            return state;
    }
}
export var fetchCategoryTaskStructuresImportsRequest = function () { return ({
    type: FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_REQUEST,
}); };
export var fetchCategoryTaskStructuresImportsSuccess = function (payload) { return ({
    type: FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_SUCCESS,
    payload: payload,
}); };
export var fetchCategoryTaskStructuresImportsFailure = function () { return ({
    type: FETCH_CATEGORY_TASK_STRUCTURES_IMPORTS_FAILURE,
}); };
export var fetchCategoryTaskStructuresImports = function (teamId, nextPage) {
    return function (dispatch, getState) {
        var categoryTaskStructuresImports = getState().categoryTaskStructuresImports;
        var page = nextPage || categoryTaskStructuresImports.page + 1;
        dispatch(fetchCategoryTaskStructuresImportsRequest());
        return api2(dispatch, getState, "/api/v2/category_task_structures/imports?team_id=".concat(teamId, "&page=").concat(page), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items, payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        payload = {
                            items: items,
                            page: page,
                            hasNext: getNextLink(response) !== null,
                        };
                        dispatch(fetchCategoryTaskStructuresImportsSuccess(payload));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchCategoryTaskStructuresImportsFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var createCategoryTaskStructuresImportRequest = function () { return ({
    type: CREATE_CALENDAR_INTEGRATION_FILTER_REQUEST,
}); };
export var createCategoryTaskStructuresImportsuccess = function (item) { return ({
    type: CREATE_CALENDAR_INTEGRATION_FILTER_SUCCESS,
    payload: item,
}); };
export var createCategoryTaskStructuresImportFailure = function () { return ({
    type: CREATE_CALENDAR_INTEGRATION_FILTER_FAILURE,
}); };
export var createCategoryTaskStructuresImport = function (params) {
    return function (dispatch, getState) {
        dispatch(createCategoryTaskStructuresImportRequest());
        return api2(dispatch, getState, '/api/v2/category_task_structures/imports', 'POST', params, true).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(createCategoryTaskStructuresImportsuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(createCategoryTaskStructuresImportFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
