import { __assign } from "tslib";
export var SET_UNDO = 'timecrowd/undo/SET_UNDO';
export var CLEAR_UNDO = 'timecrowd/undo/CLEAR_UNDO';
export var initialState = {
    messageType: '',
    message: '',
    action: null,
};
export default function undo(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_UNDO:
            return __assign(__assign({}, state), { messageType: action.messageType, message: action.message, action: action.action });
        case CLEAR_UNDO:
            return initialState;
        default:
            return state;
    }
}
export var setUndo = function (messageType, message, action) { return ({
    type: SET_UNDO,
    messageType: messageType,
    message: message,
    action: action,
}); };
export var clearUndo = function () { return ({
    type: CLEAR_UNDO,
}); };
