var _a, _b, _c;
import { Notifier } from '@airbrake/browser';
import { IGNORE_ERROR_MESSAGES } from '@/constants/ignore_error_messages';
import variants from '@/constants/variants';
import { RAILS_ENV } from '@/constants/rails_env';
var appVersion = variants.appVersion;
var jsVersion = (_c = (_b = (_a = document
    .querySelector('script[src*=application]')) === null || _a === void 0 ? void 0 : _a.src) === null || _b === void 0 ? void 0 : _b.replace(/^.+-|\.js$/g, '')) !== null && _c !== void 0 ? _c : 'none';
export var isIgnoredError = function (error) {
    return IGNORE_ERROR_MESSAGES.test(error.message);
};
var IS_DEV = process.env.NODE_ENV === 'development';
var IS_TEST = process.env.NODE_ENV === 'test';
var airbrake;
/* istanbul ignore else */
if (!IS_DEV) {
    airbrake = new Notifier({
        projectId: 394985,
        projectKey: 'e6785f881b86c2ae72dca969190f33f9',
        environment: RAILS_ENV,
    });
    /* istanbul ignore next */
    airbrake.addFilter(function (notice) {
        var errorsCount = notice.errors.filter(function (error) {
            return !isIgnoredError(error);
        }).length;
        if (errorsCount && !IS_TEST) {
            notice.context.version = appVersion;
            return notice;
        }
        return null;
    });
}
export var notifyToAirbrake = function (userId, error, errorInfo, isDev) {
    if (errorInfo === void 0) { errorInfo = null; }
    if (isDev === void 0) { isDev = IS_DEV; }
    if (isDev) {
        // eslint-disable-next-line no-console
        console.error({ error: error, errorInfo: errorInfo });
        return;
    }
    airbrake.notify({
        error: error,
        params: {
            errorInfo: errorInfo !== null && errorInfo !== void 0 ? errorInfo : {},
            user_id: userId,
            js_version: jsVersion,
        },
    });
};
