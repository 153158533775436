import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCalendarZoomRate, minimumZoomRate, CALENDAR_ZOOM_RATES, } from '@/modules/calendar';
import { CalendarZoomRateControl } from '@/components/calendar_zoom_rate_control';
import variants from '@/constants/variants';
export var CalendarZoomRateControlContainer = function (props) {
    var _a = props.isHorizontal, isHorizontal = _a === void 0 ? false : _a, _b = props.isApp, isApp = _b === void 0 ? variants.isApp : _b, _c = props.isMobile, isMobile = _c === void 0 ? variants.isMobile : _c;
    var dispatch = useDispatch();
    var user = useSelector(function (state) { return state.user; });
    var calendar = useSelector(function (state) { return state.calendar; });
    var calendarZoomRate = user.calendar_zoom_rate;
    var handleChangeZoomRate = React.useCallback(function (zoomMode) {
        var divisionHeightRate = Math.min(Math.max(zoomMode === 'up' ? calendarZoomRate + 1 : calendarZoomRate - 1, CALENDAR_ZOOM_RATES[0]), CALENDAR_ZOOM_RATES[CALENDAR_ZOOM_RATES.length - 1]);
        dispatch(updateCalendarZoomRate(divisionHeightRate));
    }, [calendarZoomRate, dispatch]);
    if (isApp || isMobile) {
        return null;
    }
    var maxZoomRate = CALENDAR_ZOOM_RATES[CALENDAR_ZOOM_RATES.length - 1];
    var minZoomRate = minimumZoomRate(calendar.isShowCategoryOnWeeklyCalendar, calendar.unitMinutes);
    var isDisabledUp = calendarZoomRate >= maxZoomRate;
    var isDisabledDown = calendarZoomRate <= minZoomRate;
    return (<CalendarZoomRateControl calendarZoomRate={calendarZoomRate} isDisabledDown={isDisabledDown} isDisabledUp={isDisabledUp} isHorizontal={isHorizontal} onChangeCalendarZoomRate={handleChangeZoomRate}/>);
};
