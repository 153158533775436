import React from 'react';
import Avatar from '@/components/atoms/avatar';
export var WorkspaceAvatar = function (_a) {
    var workspaceId = _a.workspaceId, _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.size, size = _c === void 0 ? 'sm' : _c, _d = _a.withName, withName = _d === void 0 ? false : _d, _e = _a.inline, inline = _e === void 0 ? true : _e, _f = _a.force, force = _f === void 0 ? false : _f, workspaces = _a.workspaces;
    var workspace = workspaces.items.find(function (_a) {
        var id = _a.id;
        return id === workspaceId;
    });
    if (!workspace ||
        ((workspaces.items.length === 1 || workspaces.current) && !force)) {
        return null;
    }
    return (<div className={"d-".concat(inline ? 'inline-' : '', "flex align-items-center ").concat(className)}>
      <Avatar className="border-0" image={workspace.avatar_url} size={size}></Avatar>
      {withName && <span className="ml-1">{workspace.name}</span>}
    </div>);
};
