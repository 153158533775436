import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createWebHook, destroyWebHook, fetchWebHooks, } from '@/actions/web_hook';
import { workspaceTeams } from '@/utils/workspace_helper';
import { WebHookSetting } from '@/components/web_hook_setting';
var WebHookSettingContainer = function () {
    var teams = workspaceTeams(useSelector(function (state) { return state.teams.items; }));
    var webHooks = useSelector(function (state) { return state.webHooks; });
    var team_id = useParams().team_id;
    var team = teams.find(function (team) { return team.id === Number(team_id); });
    var isOwner = team && team.can_manage;
    var dispatch = useDispatch();
    React.useEffect(function () {
        if (isOwner) {
            dispatch(fetchWebHooks(team_id));
        }
    }, [dispatch, isOwner, team_id]);
    var handleCreateWebHook = function (teamId, params) {
        return dispatch(createWebHook(teamId, params));
    };
    var handleDeleteWebHook = function (teamId, id) {
        dispatch(destroyWebHook(teamId, id));
    };
    return (<WebHookSetting team={team} webHooks={webHooks} onCreate={handleCreateWebHook} onDelete={handleDeleteWebHook}/>);
};
export default WebHookSettingContainer;
