import { __assign } from "tslib";
import api2 from '@/api2';
export var FETCH_CATEGORY_TASK_TREE_REQUEST = 'timecrowd/CATEGORY_TASK_TREES/FETCH_CATEGORY_TASK_TREE_REQUEST';
export var FETCH_CATEGORY_TASK_TREE_SUCCESS = 'timecrowd/CATEGORY_TASK_TREES/FETCH_CATEGORY_TASK_TREE_SUCCESS';
export var FETCH_CATEGORY_TASK_TREE_FAILURE = 'timecrowd/CATEGORY_TASK_TREES/FETCH_CATEGORY_TASK_TREE_FAILURE';
var initialState = {
    isLoading: false,
    isFetched: false,
    tree: [],
};
export default function CategoryTaskTrees(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CATEGORY_TASK_TREE_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isFetched: false, tree: [] });
        case FETCH_CATEGORY_TASK_TREE_SUCCESS:
            return __assign(__assign(__assign({}, state), { isLoading: false, isFetched: true }), action.payload);
        case FETCH_CATEGORY_TASK_TREE_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
export var fetchCategoryTaskTreeRequest = function () { return ({
    type: FETCH_CATEGORY_TASK_TREE_REQUEST,
}); };
export var fetchCategoryTaskTreeSuccess = function (payload) { return ({
    type: FETCH_CATEGORY_TASK_TREE_SUCCESS,
    payload: payload,
}); };
export var fetchCategoryTaskTreeFailure = function () { return ({
    type: FETCH_CATEGORY_TASK_TREE_FAILURE,
}); };
export var fetchCategoryTaskTree = function (id, withArchived) {
    return function (dispatch, getState) {
        dispatch(fetchCategoryTaskTreeRequest());
        return api2(dispatch, getState, "/api/v2/category_task_trees/".concat(id, "?with_archived=").concat(withArchived), 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (json) {
                    var payload = { tree: json };
                    dispatch(fetchCategoryTaskTreeSuccess(payload));
                    return payload;
                });
            }
            else {
                dispatch(fetchCategoryTaskTreeFailure());
                return { error: true };
            }
        });
    };
};
