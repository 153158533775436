import { __awaiter, __generator } from "tslib";
import api2 from '@/api2';
import { fetchPaymentRequest, fetchPaymentSuccess, fetchPaymentFailure, createPaymentRequest, createPaymentSuccess, createPaymentFailed, updatePaymentRequest, updatePaymentSuccess, updatePaymentFailed, deletePaymentRequest, deletePaymentSuccess, deletePaymentFailed, } from '@/modules/payment';
export var fetchWorkspacePayment = function () {
    return function (dispatch, getState) {
        dispatch(fetchPaymentRequest());
        return api2(dispatch, getState, '/api/v2/workspaces/payment', 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(fetchPaymentSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(fetchPaymentFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var createWorkspacePayment = function (params) {
    return function (dispatch, getState) {
        dispatch(createPaymentRequest());
        return api2(dispatch, getState, '/api/v2/workspaces/payment', 'POST', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(createPaymentSuccess(item));
                        return [2 /*return*/, item];
                    case 2:
                        dispatch(createPaymentFailed());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateWorkspacePayment = function (params) {
    return function (dispatch, getState) {
        dispatch(updatePaymentRequest());
        return api2(dispatch, getState, '/api/v2/workspaces/payment', 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(updatePaymentSuccess(item));
                        return [2 /*return*/, item];
                    case 2:
                        dispatch(updatePaymentFailed());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var deleteWorkspacePayment = function () {
    return function (dispatch, getState) {
        dispatch(deletePaymentRequest());
        return api2(dispatch, getState, '/api/v2/workspaces/payment', 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(deletePaymentSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(deletePaymentFailed());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
