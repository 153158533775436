export function isIndeterminate(all, selected) {
    if (selected.length === 0) {
        return false;
    }
    var sortAll = all.map(function (_a) {
        var id = _a.id;
        return id;
    }).sort();
    var sortSelected = selected.map(function (_a) {
        var id = _a.id;
        return id;
    }).sort();
    if (JSON.stringify(sortAll) === JSON.stringify(sortSelected)) {
        return false;
    }
    else {
        return true;
    }
}
