import { __awaiter, __generator } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { categoryWithAncestors } from '@/utils/category_helper';
export var TaskFormCreateTaskButton = function (_a) {
    var selectedTask = _a.selectedTask, isInitial = _a.isInitial, isSearching = _a.isSearching, category = _a.category, task = _a.task, requiredSelectedTask = _a.requiredSelectedTask, createTask = _a.createTask, setSelectedTask = _a.setSelectedTask, setNotificationMessage = _a.setNotificationMessage, setIsOpenTaskSuggestion = _a.setIsOpenTaskSuggestion;
    if (!requiredSelectedTask || selectedTask || isInitial || isSearching) {
        return null;
    }
    return (<div className="list-group-item-action p-2 rounded bg-white d-flex align-items-center text-break" data-testid="task-form-create-task-button" onClick={function () { return __awaiter(void 0, void 0, void 0, function () {
            var created;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (createTask === null || createTask === void 0 ? void 0 : createTask(category.team_id, {
                            task: {
                                title: task.title,
                                parent_id: category.id,
                                url: task.url,
                            },
                        }))];
                    case 1:
                        created = (_a.sent());
                        if (created.id) {
                            setNotificationMessage === null || setNotificationMessage === void 0 ? void 0 : setNotificationMessage('success', I18n.t('shared.taskCreated', { name: task.title }));
                            setSelectedTask === null || setSelectedTask === void 0 ? void 0 : setSelectedTask(created);
                            setIsOpenTaskSuggestion(false);
                        }
                        return [2 /*return*/];
                }
            });
        }); }}>
      <Icon className="mr-1" color="primary" name="plus" size="lg"/>
      {I18n.t('shared.createA', {
            name: "".concat(task.title, ":").concat(categoryWithAncestors(category), " - ").concat(category.team.name),
        })}
    </div>);
};
