import { __assign } from "tslib";
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import I18n from '@/constants/i18n';
import SearchBar from '@/components/search_bar';
import ExpansionPanel from '@/components/atoms/expansion_panel';
import ExpansionPanelBody from '@/components/atoms/expansion_panel_body';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import ExpansionPanelHeader from '@/components/molecules/expansion_panel_header';
import Blank from '@/components/molecules/blank';
import Subpane from '@/components/molecules/subpane';
import MainMenuContainer from '@/containers/main_menu_container';
import CustomCheck from '@/components/atoms/custom_check';
import CustomRadio from '@/components/atoms/custom_radio';
import TaskManagementListItem from './task_management_list_item';
import { FilterButton } from '@/components/atoms/filter_button';
import { TeamCategoryCheckList } from '@/components/molecules/team_category_check_list';
import { CategoryCheckList } from '@/components/molecules/category_check_list';
import { TeamSelectDropdown } from '@/components/molecules/team_select_dropdown';
import Icon from '@/components/atoms/icon';
import BulkCreateTasksContainer from '@/containers/bulk_create_tasks_container';
import { ignoreArchivedCategories } from '@/modules/categories';
import { workspaceTeams } from '@/utils/workspace_helper';
import useCustomFields from '@/hooks/use_custom_fields';
var TaskManagement = function (props) {
    var loadHistory = props.loadHistory, fetchCategories = props.fetchCategories, selectedTeam = props.selectedTeam, selectedState = props.selectedState, selectedCategories = props.selectedCategories, keyword = props.keyword, taskManagement = props.taskManagement, expandSearchedTasks = props.expandSearchedTasks, setSelectedState = props.setSelectedState, setSelectedCategories = props.setSelectedCategories, setSelectedTeam = props.setSelectedTeam, setIsShowArchived = props.setIsShowArchived, setIsSearch = props.setIsSearch, setKeyword = props.setKeyword, isShowArchived = props.isShowArchived, fetchSearchedTasks = props.fetchSearchedTasks, categories = props.categories, prevSelectedCategories = props.selectedCategories, isSearch = props.isSearch, _a = props.teams, teamItems = _a.items, teamIsFetched = _a.isFetched, isHistoryLoaded = props.isHistoryLoaded, startTask = props.startTask, fetchWorkingUsersCount = props.fetchWorkingUsersCount, currentEntry = props.currentEntry, stopTimeEntry = props.stopTimeEntry, setCurrentEntry = props.setCurrentEntry, updateTask = props.updateTask, destroyTask = props.destroyTask, restoreTask = props.restoreTask, isMobile = props.isMobile, isApp = props.isApp, openURL = props.openURL;
    var _b = React.useState(false), isOpenSubpane = _b[0], setIsOpenSubpane = _b[1];
    var _c = React.useState(false), isOpenBulkCreateTasksFormModal = _c[0], setIsOpenBulkCreateTasksFormModal = _c[1];
    var handleSearchTasks = React.useCallback(function (keyword, selectedTeam, selectedState, selectedCategories, isShowArchived) {
        var params = buildSearchParams({
            state: selectedState,
            with_archived: isShowArchived,
        }, keyword, selectedTeam, isShowArchived
            ? selectedCategories
            : ignoreArchivedCategories(selectedCategories));
        return fetchSearchedTasks(params);
    }, [fetchSearchedTasks]);
    var refreshTasks = React.useCallback(function () {
        return handleSearchTasks(keyword, selectedTeam, selectedState, selectedCategories, isShowArchived);
    }, [
        keyword,
        selectedTeam,
        selectedState,
        selectedCategories,
        isShowArchived,
        handleSearchTasks,
    ]);
    React.useEffect(function () {
        if (teamIsFetched && !isHistoryLoaded) {
            loadHistory(teamItems, fetchCategories);
        }
    }, [fetchCategories, isHistoryLoaded, loadHistory, teamIsFetched, teamItems]);
    var teams = React.useMemo(function () { return workspaceTeams(teamItems); }, [teamItems]);
    useCustomFields(teams);
    var handleLoadMore = function () {
        if (taskManagement.status === 'loading') {
            return;
        }
        expandSearchedTasks();
    };
    var buildSearchParams = function (base, keyword, selectedTeam, selectedCategories) {
        return __assign(__assign({}, base), { title: keyword ? keyword : '', team_id: selectedTeam ? selectedTeam.id : '', category_ids: selectedCategories
                .filter(function (_a) {
                var team_id = _a.team_id;
                return selectedTeam === null || team_id === selectedTeam.id;
            })
                .map(function (_a) {
                var id = _a.id;
                return id;
            }) });
    };
    var handleClickNav = function (selectedState) {
        setSelectedState(selectedState);
        handleSearchTasks(keyword, selectedTeam, selectedState, selectedCategories, isShowArchived);
    };
    var onSelectTeam = function (selectedTeam) {
        setSelectedTeam(selectedTeam);
        handleSearchTasks(keyword, selectedTeam, selectedState, selectedCategories, isShowArchived);
    };
    var onChangeIsShowArchived = function (isShowArchived) {
        setIsShowArchived(isShowArchived);
        handleSearchTasks(keyword, selectedTeam, selectedState, selectedCategories, isShowArchived);
    };
    var onSearch = function () {
        if (keyword === '') {
            onCancelSearch();
            return;
        }
        handleSearchTasks(keyword, selectedTeam, selectedState, selectedCategories, isShowArchived);
        setIsSearch(true);
    };
    var onCancelSearch = function () {
        var keyword = '';
        handleSearchTasks(keyword, selectedTeam, selectedState, selectedCategories, isShowArchived);
        setKeyword(keyword);
        setIsSearch(false);
    };
    var toggleOpenSubpane = function () {
        setIsOpenSubpane(!isOpenSubpane);
    };
    var onChangeKeyword = function (keyword) {
        setKeyword(keyword);
    };
    var onChangeTeam = function (team, checked) {
        var prevSelectedIds = prevSelectedCategories.map(function (_a) {
            var id = _a.id;
            return id;
        });
        var nextSelectedCategories = categories.filter(function (_a) {
            var id = _a.id, team_id = _a.team_id;
            if (checked) {
                return prevSelectedIds.includes(id) && team_id !== team.id;
            }
            else {
                return prevSelectedIds.includes(id) || team_id === team.id;
            }
        });
        setSelectedCategories(nextSelectedCategories);
        handleSearchTasks(keyword, selectedTeam, selectedState, nextSelectedCategories, isShowArchived);
    };
    var onChangeCategory = function (team, category, checked) {
        var prevSelectedIds = prevSelectedCategories.map(function (_a) {
            var id = _a.id;
            return id;
        });
        var nextSelectedCategories = categories.filter(function (_a) {
            var id = _a.id;
            if (checked) {
                return prevSelectedIds.includes(id) && id !== category.id;
            }
            else {
                return prevSelectedIds.includes(id) || id === category.id;
            }
        });
        setSelectedCategories(nextSelectedCategories);
        handleSearchTasks(keyword, selectedTeam, selectedState, nextSelectedCategories, isShowArchived);
    };
    var filteredTasks = taskManagement.items.filter(function (task) {
        if (selectedState === 'all') {
            return !task.deleted_at;
        }
        else if (selectedState === 'trashed') {
            return task.deleted_at;
        }
        else {
            return task.state === selectedState;
        }
    });
    var isNothing = filteredTasks.length === 0 && !taskManagement.next;
    var blankTitle = function () {
        switch (selectedState) {
            case 'open':
                return I18n.t('taskManagement.blankTitleOpen');
            case 'closed':
                return I18n.t('taskManagement.blankTitleClosed');
            case 'trashed':
                return I18n.t('taskManagement.blankTitleTrashed');
            default:
                return I18n.t('taskManagement.blankTitle');
        }
    };
    var blankDescription = function () {
        switch (selectedState) {
            case 'open':
                return I18n.t('taskManagement.blankDescription');
            case 'closed':
                return I18n.t('taskManagement.blankDescriptionClosed');
            case 'trashed':
                return '';
            default:
                return I18n.t('taskManagement.blankDescription');
        }
    };
    var unarchivedCategories = ignoreArchivedCategories(categories);
    var filteredCategories = isShowArchived ? categories : unarchivedCategories;
    var filteredCategoryIds = filteredCategories.map(function (_a) {
        var id = _a.id;
        return id;
    });
    var filteredSelectedCategories = isShowArchived
        ? selectedCategories
        : selectedCategories.filter(function (_a) {
            var id = _a.id;
            return filteredCategoryIds.includes(id);
        });
    var noTeams = teamIsFetched && teams.length < 1;
    var disabled = taskManagement.status !== 'loaded';
    var hasMore = taskManagement.status === 'loaded' && !!taskManagement.next;
    return (<div>
      <div className="content-wrap">
        <MainMenuContainer />
        <div className="content-main">
          <SearchBar additionalButton={<div>
                {teams.find(function (team) {
                return team.is_bulk_create_tasks_allowed && !team.tasks_readonly;
            }) !== undefined && (<button className="rounded-circle d-flex justify-content-center align-items-center btn btn-primary" style={{ width: 42, height: 42 }} type="button" onClick={function () { return setIsOpenBulkCreateTasksFormModal(true); }}>
                    <Icon name="plus" size="lg"/>
                  </button>)}
              </div>} isSearch={isSearch} keyword={keyword} onCancelSearch={onCancelSearch} onChangeKeyword={onChangeKeyword} onSearch={onSearch}/>
          <div className="row no-gutters with-toolbar">
            <div className="col-md-9 col-12">
              <div className={isNothing ? 'bg-blank' : ''}>
                <FilterButton onToggle={toggleOpenSubpane}/>
              </div>
              {selectedState === 'trashed' && (<div className="p-2" style={{ borderBottom: '1px solid #ececec' }}>
                  <p className="mb-0 text-info">
                    {I18n.t('taskManagement.deleteTasksAfterTwoWeeks')}
                  </p>
                </div>)}
              {taskManagement.status === 'loading' && (<div className="text-center">
                  <LoadingSpinner position="absolute-center" size="lg"/>
                </div>)}
              {taskManagement.status === 'loaded' && isNothing && (<Blank description={blankDescription()} title={blankTitle()}/>)}
              {taskManagement.status === 'loaded' && !isNothing && (<div className="tasklist pb-5 pb-sm-0">
                  <InfiniteScroll hasMore={hasMore} loader={<div key={0} className="py-4 text-center">
                        <LoadingSpinner size="md"/>
                      </div>} loadMore={handleLoadMore}>
                    {filteredTasks.map(function (task) {
                return (<TaskManagementListItem key={task.id} currentEntry={currentEntry.current} destroyTask={destroyTask} fetchWorkingUsersCount={fetchWorkingUsersCount} isApp={isApp} isMobile={isMobile} openURL={openURL} restoreTask={restoreTask} setCurrentEntry={setCurrentEntry} startTask={startTask} stopTimeEntry={stopTimeEntry} task={task} updateTask={updateTask} onRefresh={refreshTasks}/>);
            })}
                  </InfiniteScroll>
                </div>)}
              <div className="w-100 d-sm-none" style={{ position: 'fixed', bottom: 0 }}>
                <button className="text-left border-left-0 border-right-0 border-bottom-0 rounded-0 rounded-0 btn btn-secondary btn-lg btn-block" type="button" onClick={function () { return setIsOpenBulkCreateTasksFormModal(true); }}>
                  <Icon className="mr-1" color="primary" name="plus" size="lg"/>
                  <span>{I18n.t('shared.addTask')}</span>
                </button>
              </div>
            </div>
            <Subpane className="col-md-3 col-12" isOpen={isOpenSubpane} toggle={toggleOpenSubpane}>
              <ExpansionPanel expand className="border-top-0 mw-100">
                {function (expand, toggle) { return (<>
                    <ExpansionPanelHeader className="pb-0" expand={expand} toggle={toggle}>
                      <label className="expansion-panel-label small">
                        {I18n.t('tasklist.status')}
                      </label>
                    </ExpansionPanelHeader>
                    <ExpansionPanelBody expand={expand}>
                      <div className="pt-0">
                        <div className="p-1 pl-2" role="button" onClick={function (e) {
                e.preventDefault();
                if (disabled) {
                    return;
                }
                handleClickNav('all');
                toggleOpenSubpane();
            }}>
                          <CustomRadio checked={selectedState === 'all'} disabled={disabled}>
                            {I18n.t('tasklist.all')}
                          </CustomRadio>
                        </div>
                        <div className="p-1 pl-2" role="button" onClick={function (e) {
                e.preventDefault();
                if (disabled) {
                    return;
                }
                handleClickNav('open');
                toggleOpenSubpane();
            }}>
                          <CustomRadio checked={selectedState === 'open'} disabled={disabled}>
                            {I18n.t('taskManagement.open')}
                          </CustomRadio>
                        </div>
                        <div className="p-1 pl-2" role="button" onClick={function (e) {
                e.preventDefault();
                if (disabled) {
                    return;
                }
                handleClickNav('closed');
                toggleOpenSubpane();
            }}>
                          <CustomRadio checked={selectedState === 'closed'} disabled={disabled}>
                            {I18n.t('taskManagement.closed')}
                          </CustomRadio>
                        </div>
                        <div className="p-1 pl-2 pb-2" role="button" onClick={function (e) {
                e.preventDefault();
                if (disabled) {
                    return;
                }
                handleClickNav('trashed');
                toggleOpenSubpane();
            }}>
                          <CustomRadio checked={selectedState === 'trashed'} disabled={disabled}>
                            {I18n.t('taskManagement.trashed')}
                          </CustomRadio>
                        </div>
                      </div>
                    </ExpansionPanelBody>
                  </>); }}
              </ExpansionPanel>
              <hr className="my-0"/>
              <ExpansionPanel expand className="border-top-0 mw-100">
                {function (expand, toggle) { return (<>
                    <ExpansionPanelHeader className="pb-0" expand={expand} toggle={toggle}>
                      <label className="expansion-panel-label small">
                        {I18n.t('shared.teamsAndCategories')}
                      </label>
                    </ExpansionPanelHeader>
                    <ExpansionPanelBody expand={expand}>
                      <div className="mr-1 mb-2">
                        <TeamSelectDropdown disabled={disabled} selectedTeam={selectedTeam} teams={teams} onSelectTeam={onSelectTeam}/>
                      </div>
                      <div style={{ overflow: 'auto' }}>
                        <div className="ml-2 mb-1">
                          <CustomCheck checked={isShowArchived} disabled={disabled} onChange={function () {
                return onChangeIsShowArchived(!isShowArchived);
            }}>
                            {I18n.t('shared.includeArchived')}
                          </CustomCheck>
                        </div>
                        {!noTeams && categories.length < 1 ? (<div className="p-2 text-center">
                            <LoadingSpinner size="md"/>
                          </div>) : selectedTeam === null ? (<TeamCategoryCheckList isExpandAllByDefault disabled={disabled} selectedCategories={filteredSelectedCategories} shouldDisableUnpaidTeam={false} showsBadge={false} teams={teams.map(function (team) { return (__assign(__assign({}, team), { categories: filteredCategories.filter(function (_a) {
                        var team_id = _a.team_id;
                        return team_id === team.id;
                    }) })); })} onChangeCategory={onChangeCategory} onChangeTeam={onChangeTeam}/>) : (<CategoryCheckList disabled={disabled} selectedCategories={filteredSelectedCategories} team={__assign(__assign({}, selectedTeam), { categories: filteredCategories.filter(function (_a) {
                        var team_id = _a.team_id;
                        return team_id === selectedTeam.id;
                    }) })} onChange={onChangeCategory}/>)}
                      </div>
                    </ExpansionPanelBody>
                  </>); }}
              </ExpansionPanel>
            </Subpane>
          </div>
        </div>
      </div>
      {isOpenBulkCreateTasksFormModal && (<BulkCreateTasksContainer isOpen categories={unarchivedCategories} defaultSelectedCategories={ignoreArchivedCategories(selectedCategories)} defaultSelectedTeam={selectedTeam} teams={teams} onCreate={refreshTasks} onToggle={function () { return setIsOpenBulkCreateTasksFormModal(false); }}/>)}
    </div>);
};
export default TaskManagement;
