import React from 'react';
import { NestedCategoryListItem } from '@/components/molecules/nested_category_list_item';
export var DragListItemOverlay = function (_a) {
    var team = _a.team, originCategories = _a.originCategories, categories = _a.categories, targetId = _a.targetId, customFieldsMasters = _a.customFieldsMasters, customFieldsValues = _a.customFieldsValues;
    if (targetId === null) {
        return null;
    }
    var dragged = originCategories.find(function (c) { return c.id.toString() === targetId; });
    if (!dragged) {
        return null;
    }
    return (<NestedCategoryListItem categories={categories} category={dragged} categoryTaskTree={null} copyTeamCategory={function () { return Promise.resolve(); }} createTeamCategory={function () { return Promise.resolve(); }} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues} draggedId={targetId} fetchCategoryTaskTree={function () { }} fetchCustomFieldsValues={function () { }} handleArchiveCategory={function () { }} handleDragEnd={function () { }} handleDragStart={function () { }} isOverlay={true} searchText="" setNotificationMessage={function () { }} team={team} toggleRemoveModal={function () { }} updateTeamCategory={function () { return Promise.resolve(); }} onMoveHigherCategoryPosition={function () { }} onMoveLowerCategoryPosition={function () { }}/>);
};
