import React from 'react';
import { useSelector } from 'react-redux';
import MainMenu from '@/components/main_menu';
var MainMenuContainer = function (_a) {
    var _b = _a.isPlaceholder, isPlaceholder = _b === void 0 ? false : _b;
    var apiBase = useSelector(function (state) { return state.apiBase; });
    var currentEntry = useSelector(function (state) { return state.currentEntry; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var teams = useSelector(function (state) { return state.teams; });
    var user = useSelector(function (state) { return state.user; });
    var workingUsersCount = useSelector(function (state) { return state.workingUsersCount; });
    var workspaces = useSelector(function (state) { return state.workspaces; });
    return (<MainMenu apiBase={apiBase} currentEntry={currentEntry.current} isPC={isPC} isPlaceholder={isPlaceholder} openURL={openURL} teams={teams} user={user} workingUsersCount={workingUsersCount} workspaces={workspaces}/>);
};
export default MainMenuContainer;
