import React from 'react';
import Icon from '@/components/atoms/icon';
export var CalendarZoomRateControl = function (props) {
    var isDisabledUp = props.isDisabledUp, isDisabledDown = props.isDisabledDown, isHorizontal = props.isHorizontal, calendarZoomRate = props.calendarZoomRate, onChangeCalendarZoomRate = props.onChangeCalendarZoomRate;
    return (<div className={isHorizontal
            ? 'd-flex flex-row-reverse justify-content-center'
            : 'timeline-zoom-control btn-group-vertical'}>
      <button className="btn btn-secondary" disabled={isDisabledUp} type="button" onClick={function () { return onChangeCalendarZoomRate('up'); }}>
        <Icon name="plus"/>
      </button>
      {isHorizontal && (<span className="font-weight-bold form-control-static px-2">
          x {calendarZoomRate}
        </span>)}
      <button className="btn btn-secondary" disabled={isDisabledDown} type="button" onClick={function () { return onChangeCalendarZoomRate('down'); }}>
        <Icon name="minus"/>
      </button>
    </div>);
};
