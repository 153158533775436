import React from 'react';
import Avatar from '@/components/atoms/avatar';
import I18n from '@/constants/i18n';
var TeamListItem = function (props) {
    var team = props.team, onLeave = props.onLeave;
    var handleClickLeave = function () {
        onLeave({ team: team });
    };
    return (<tr>
      <td className="text-break">
        <Avatar className="mr-1" image={team.avatar_url} size="sm"/>
        {team.name}
      </td>
      <td>
        <button className="text-danger btn btn-link" onClick={handleClickLeave}>
          {I18n.t('shared.leaveParentTeam')}
        </button>
      </td>
    </tr>);
};
export default TeamListItem;
