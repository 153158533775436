import { connect } from 'react-redux';
import ExportActions from '@/components/export/export_actions';
import { cancelCreateAsset } from '@/modules/assets';
var mapStateToProps = function (state, ownProps) { return ({
    asset: ownProps.asset,
    openURL: state.openURL,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onCancel: function (asset) {
        dispatch(cancelCreateAsset(asset.id));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ExportActions);
