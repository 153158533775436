import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { isCreateMode } from './helpers';
export var TaskFormButtons = function (_a) {
    var mode = _a.mode, isShowCancelButton = _a.isShowCancelButton, isTimeEntrySpecified = _a.isTimeEntrySpecified, disabled = _a.disabled, isTimeEntryValid = _a.isTimeEntryValid, linkUrl = _a.linkUrl, archived = _a.archived, canUpdate = _a.canUpdate, task = _a.task, requiredSelectedTask = _a.requiredSelectedTask, selectedTask = _a.selectedTask, onCreate = _a.onCreate, onStart = _a.onStart, onCancel = _a.onCancel, onUpdate = _a.onUpdate, openURL = _a.openURL, isValidForUpdate = _a.isValidForUpdate;
    var tooltipIdForSave = "task-form-".concat(task === null || task === void 0 ? void 0 : task.id, "-save-tooltip");
    return (<div className="task-edit-controls ml-auto">
      <div className="d-flex">
        {isShowCancelButton && mode !== 'calendar_event' && (<button className="btn btn-link" type="button" onClick={onCancel}>
            <span>{I18n.t('shared.cancel')}</span>
          </button>)}
        {linkUrl !== '' && (<a className="btn btn-link d-flex justify-content-center align-items-center" href={linkUrl} style={{ width: 32, height: 32 }} onClick={function (e) {
                e.preventDefault();
                openURL(linkUrl);
            }}>
            <Icon name="link"/>
          </a>)}
        {isCreateMode(mode) && isTimeEntrySpecified && (<button className={"btn-major-action btn btn-".concat(mode === 'create' ? 'primary' : 'secondary')} disabled={!isTimeEntryValid ||
                disabled ||
                (requiredSelectedTask && !selectedTask)} type="button" onClick={onCreate}>
            <span>{I18n.t('shared.add')}</span>
          </button>)}
        {isCreateMode(mode) && !isTimeEntrySpecified && (<button className="btn-major-action btn btn-primary" disabled={disabled} type="button" onClick={onStart}>
            <Icon name="start"/>
            <span className="hidden-lg-down ml-1">
              {I18n.t('tasklist.start')}
            </span>
          </button>)}
        {mode === 'calendar_event' && (<button className="rounded-circle d-flex justify-content-center align-items-center btn btn-primary" disabled={disabled} style={{ width: 32, height: 32 }} type="button" onClick={onStart}>
            <Icon name="start" size="sm"/>
          </button>)}
        {mode === 'update' && canUpdate && (<>
            <button className="btn-major-action btn btn-primary" disabled={disabled || archived || !isValidForUpdate()} type="button" onClick={onUpdate}>
              <span id={tooltipIdForSave}>{I18n.t('shared.save')}</span>
            </button>
            {archived && (<UncontrolledTooltip fade={false} target={tooltipIdForSave}>
                {I18n.t('shared.archivedTaskCannotBeEdited')}
              </UncontrolledTooltip>)}
          </>)}
      </div>
    </div>);
};
