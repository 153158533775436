import { __spreadArray } from "tslib";
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import EditChildTeamForm from '@/components/team_children/edit_child_team_form';
import { updateTeam } from '@/modules/teams';
import { workspaceTeams } from '@/utils/workspace_helper';
import { parentId } from '@/utils/ancestry_helper';
function isPath(selected, team) {
    var path = selected.ancestry
        ? [selected.id].concat(selected.ancestry.split('/').map(function (id) { return Number(id); }))
        : [selected.id];
    return includes(path, team.id);
}
function isSibling(selected, team) {
    return parentId(selected) === parentId(team);
}
function normalizeTeams(selected, teams) {
    var parentTeamIds = teams.reduce(function (ids, team) {
        var _a;
        var ancestorIds = (((_a = team.ancestry) === null || _a === void 0 ? void 0 : _a.split('/')) || []).map(function (id) {
            return parseInt(id);
        });
        return __spreadArray(__spreadArray([], ids, true), ancestorIds, true);
    }, []);
    return teams.filter(function (team) {
        return (!team.ancestry &&
            !parentTeamIds.includes(team.id) &&
            !team.personal &&
            !isPath(selected, team) &&
            !(selected.ancestry && team.ancestry && isSibling(selected, team)) &&
            team.can_manage);
    });
}
var mapStateToProps = function (state, ownProps) { return ({
    teams: normalizeTeams(ownProps.team, workspaceTeams(state.teams.items)),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onUpdate: function (id, params) {
        return dispatch(updateTeam(id, params));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(EditChildTeamForm);
