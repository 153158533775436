import { connect } from 'react-redux';
import { SystemNotificationAlert } from '@/components/organisms/system_notification_alert';
import { fetchSystemNotifications, confirmSystemNotification, } from '@/modules/system_notifications';
var mapStateToProps = function (state) { return ({
    notifications: state.systemNotifications.notifications,
    openURL: state.openURL,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onInit: function () {
        dispatch(fetchSystemNotifications());
    },
    onConfirm: function (id) {
        dispatch(confirmSystemNotification(id));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(SystemNotificationAlert);
