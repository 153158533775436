import React from 'react';
import { useSelector } from 'react-redux';
import { TeamCategoriesList } from '@/components/team_categories_list';
import { sortTeamsByWorkspace } from '@/utils/workspace_helper';
var TeamCategoriesListContainer = function (_a) {
    var teams = _a.teams, category = _a.category, categories = _a.categories, setCategory = _a.setCategory, isExpandAll = _a.isExpandAll, withoutTeam = _a.withoutTeam;
    var workspaces = useSelector(function (state) { return state.workspaces; });
    var sortedTeams = React.useMemo(function () { return sortTeamsByWorkspace(teams, workspaces.items); }, [teams, workspaces.items]);
    return (<TeamCategoriesList categories={categories} category={category} isExpandAll={isExpandAll} setCategory={setCategory} teams={sortedTeams} withoutTeam={withoutTeam}/>);
};
export default TeamCategoriesListContainer;
