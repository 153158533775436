import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
export var RenewWorkspaceInvitation = function (_a) {
    var renewed = _a.renewed, isExpired = _a.isExpired, onRenew = _a.onRenew;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = React.useState(false), isLoading = _c[0], setIsLoading = _c[1];
    React.useEffect(function () {
        setIsLoading(false);
        setIsOpen(false);
    }, [renewed]);
    var toggle = function () {
        setIsOpen(!isOpen);
    };
    var handleRenew = function () {
        setIsLoading(true);
        onRenew();
    };
    return (<>
      <p className="mt-2">
        <button className="btn btn-link p-0" onClick={isExpired ? handleRenew : toggle}>
          {renewed ? (I18n.t('teamManagement.invitation.byUrl.renewed')) : (<>
              <Icon className="mr-1" name="clockwise"/>
              {I18n.t('shared.renewInvitationUrl')}
            </>)}
        </button>
      </p>

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeaderMod toggle={toggle}>
          {I18n.t('shared.renewInvitationUrl')}
        </ModalHeaderMod>
        <ModalBody className="px-2 mx-1">
          <p className="mt-0 mb-0 font-weight-bold text-danger">
            {I18n.t('shared.renewInvitationUrlDescription1')}
          </p>

          <p className="mt-0">
            {I18n.t('shared.renewInvitationUrlDescription2')}
          </p>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary mr-2" disabled={isLoading} onClick={toggle}>
            {I18n.t('shared.cancel')}
          </button>

          <button className="btn btn-primary" disabled={isLoading} onClick={handleRenew}>
            {I18n.t('shared.renewUrl')}
          </button>
        </ModalFooter>
      </Modal>
    </>);
};
