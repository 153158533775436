import { __assign } from "tslib";
import { connect } from 'react-redux';
import TaskManagement from '../components/task_management';
import { setSelectedTeam, setSelectedState, setSelectedCategories, setKeyword, setIsSearch, setIsShowArchived, loadHistory, saveHistory, } from '@/modules/task_management';
import variants from '@/constants/variants';
var loadTaskManagementHistory = variants.loadTaskManagementHistory, saveTaskManagementHistory = variants.saveTaskManagementHistory;
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { selectedTeam: state.taskManagement.selectedTeam, selectedState: state.taskManagement.selectedState, selectedCategories: state.taskManagement.selectedCategories, keyword: state.taskManagement.keyword, isSearch: state.taskManagement.isSearch, isShowArchived: state.taskManagement.isShowArchived, isHistoryLoaded: state.taskManagement.isHistoryLoaded })); };
var mapDispatchToProps = function (dispatch) { return ({
    loadHistory: function (teams, categories) {
        dispatch(loadHistory(teams, categories, loadTaskManagementHistory));
    },
    setSelectedTeam: function (selectedTeam) {
        dispatch(setSelectedTeam(selectedTeam));
        dispatch(saveHistory(saveTaskManagementHistory));
    },
    setSelectedState: function (selectedState) {
        dispatch(setSelectedState(selectedState));
        dispatch(saveHistory(saveTaskManagementHistory));
    },
    setSelectedCategories: function (selectedCategories) {
        dispatch(setSelectedCategories(selectedCategories));
        dispatch(saveHistory(saveTaskManagementHistory));
    },
    setKeyword: function (keyword) {
        dispatch(setKeyword(keyword));
        dispatch(saveHistory(saveTaskManagementHistory));
    },
    setIsSearch: function (isSearch) {
        dispatch(setIsSearch(isSearch));
        dispatch(saveHistory(saveTaskManagementHistory));
    },
    setIsShowArchived: function (isShowArchived) {
        dispatch(setIsShowArchived(isShowArchived));
        dispatch(saveHistory(saveTaskManagementHistory));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TaskManagement);
