import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { listWorkspacesLongTermReports, fetchWorkspacesLongTermReport, removeWorkspacesCompletedLongTermReport, setCurrentWorkspacesLongTermReport, } from '@/modules/workspaces_long_term_reports';
import { CrossTeamReportLongTermNotification } from '@/components/cross_team_report_long_term_notification';
var POLLING_INTERVAL = 30000;
export var CrossTeamReportLongTermNotificationContainer = function () {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var longTermReports = useSelector(function (state) { return state.workspacesLongTermReports.items; });
    var completedReports = useSelector(function (state) { return state.workspacesLongTermReports.completedItems; });
    React.useEffect(function () {
        dispatch(listWorkspacesLongTermReports(null, {
            status: ['pending', 'processing'],
        }));
    }, [dispatch]);
    // NOTE: 未完了のレポートがある場合、30秒ごとに取得
    React.useEffect(function () {
        var pendingReports = longTermReports.filter(function (report) {
            return ['pending', 'processing'].includes(report.status);
        });
        var timer;
        if (pendingReports.length) {
            timer = setTimeout(function () {
                pendingReports.forEach(function (report) {
                    dispatch(fetchWorkspacesLongTermReport(report.workspaces_workspace_id, report.id));
                });
            }, POLLING_INTERVAL);
        }
        return function () {
            clearTimeout(timer);
        };
    }, [dispatch, longTermReports]);
    var handleSelect = React.useCallback(function (report) {
        dispatch(removeWorkspacesCompletedLongTermReport(report.id));
        dispatch(setCurrentWorkspacesLongTermReport(report));
        navigate("/app/workspaces/".concat(report.workspaces_workspace_id, "/cross_team_report"));
    }, [dispatch, navigate]);
    return (<CrossTeamReportLongTermNotification reports={completedReports} onSelect={handleSelect}/>);
};
