import { __assign, __spreadArray } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';
import { useNavigate } from 'react-router-dom';
import I18n from '@/constants/i18n';
import { DATE_FORMAT } from '@/constants/format';
import Icon from '@/components/atoms/icon';
import ExpansionPanelBody from '@/components/atoms/expansion_panel_body';
import ExpansionPanelHeader from '@/components/molecules/expansion_panel_header';
import Backdrop from '@/components/atoms/backdrop';
import CustomCheck from '@/components/atoms/custom_check';
import ExpansionPanel from '@/components/atoms/expansion_panel';
import MainMenuContainer from '@/containers/main_menu_container';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import OmitLabel from '@/components/atoms/omit_label';
import TeamLabel from '@/components/export/team_label';
import UserLabel from '@/components/export/user_label';
import TeamCheckbox from '@/components/molecules/team_checkbox';
import FormSectionHeader from '@/components/export/form_section_header';
import AssetTableContainer from '@/containers/export/asset_table_container';
import DeleteAssetModalContainer from '@/containers/delete_asset_modal_container';
import ExportPaymentAlert from '@/components/export_payment_alert';
import ExportFormatModalContainer from '@/containers/export_format_modal_container';
import ListUsers from '@/components/export/list_users';
import LoadingPage from '@/components/pages/loading_page';
import { TeamCategoryCheckList } from '@/components/molecules/team_category_check_list';
import { isIndeterminate } from '@/components/molecules/team_category_check_list/helpers';
import { fetchExportableTeams } from '@/modules/exportable_teams';
import { descendantCategories } from '@/components/export/helpers';
import { ignoreArchivedCategories } from '@/modules/categories';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import variants from '@/constants/variants';
import { sortTeamsByWorkspace } from '@/utils/workspace_helper';
import { removeDangerNotificationMessage } from '@/modules/notification';
var EVENT_TYPE = {
    START: 1,
    HOVER: 2,
    END: 3,
};
var THIS_LABEL = {
    day: 'today',
    week: 'thisWeek',
    month: 'thisMonth',
    year: 'thisYear',
};
var LAST_LABEL = {
    day: 'yesterday',
    week: 'lastWeek',
    month: 'lastMonth',
    year: 'lastYear',
};
var PERIODS = ['day', 'week', 'month', 'year'];
var FIRST_CHECK_WAIT = 1000 * 1;
function convert(span) {
    if (span === 'week') {
        return 'isoWeek';
    }
    else {
        return span;
    }
}
export var DataExportContainer = function (props) {
    var assets = props.assets, categories = props.categories, defaultExportableTeams = props.exportableTeams, workspaces = props.workspaces, fetchCategories = props.fetchCategories, fetchAssets = props.fetchAssets, fetchAsset = props.fetchAsset, createAsset = props.createAsset;
    var today = moment().format(DATE_FORMAT);
    var _a = React.useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = React.useState(false), openModal = _b[0], setOpenModal = _b[1];
    var _c = React.useState(false), isCheckAllPeriod = _c[0], setIsCheckAllPeriod = _c[1];
    var _d = React.useState(false), isShowArchived = _d[0], setIsShowArchived = _d[1];
    var _e = React.useState(false), isOpenExportFormatModal = _e[0], setIsOpenExportFormatModal = _e[1];
    var _f = React.useState([]), exportableTeams = _f[0], setExportableTeams = _f[1];
    var _g = React.useState({
        startDate: today,
        endDate: today,
        selectedTeams: [],
        selectedCategories: [],
        selectedUsers: [],
        isOpenCalendar: false,
        selectedTeam: undefined,
        isOpenPaymentAlert: false,
        exportableTeams: [],
        shownPaymentAlertTeamIds: [],
    }), state = _g[0], setState = _g[1];
    var navigate = useNavigate();
    var openURL = useSelector(function (state) { return state.openURL; });
    var dispatch = useDispatch();
    React.useEffect(function () {
        setIsLoading(true);
        Promise.all([
            fetchCategories(),
            dispatch(fetchExportableTeams()),
            fetchAssets(),
        ]).then(function () {
            setIsLoading(false);
        });
        variants.loadStorage('shownPaymentAlertTeamIds', function (shownPaymentAlertTeamIds) {
            if (shownPaymentAlertTeamIds) {
                setState(function (prevState) { return (__assign(__assign({}, prevState), { shownPaymentAlertTeamIds: shownPaymentAlertTeamIds })); });
            }
        });
    }, [dispatch, fetchAssets, fetchCategories]);
    React.useEffect(function () {
        var categoriesByTeamId = categories.reduce(function (acc, category) {
            if (!acc[category.team_id]) {
                acc[category.team_id] = [];
            }
            acc[category.team_id].push(category);
            return acc;
        }, {});
        setExportableTeams(defaultExportableTeams.items.map(function (team) {
            var _a;
            return (__assign(__assign({}, team), { categories: (_a = categoriesByTeamId[team.id]) !== null && _a !== void 0 ? _a : [] }));
        }));
    }, [defaultExportableTeams, categories]);
    var toggleExportForm = function () {
        setOpenModal(!openModal);
    };
    var onClickSelectAllTeams = function (e) {
        e.preventDefault();
        var teams = exportableTeams.filter(function (t) { return !t.payment_required; });
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedTeams: teams, selectedCategories: teams.reduce(function (acc, team) { return acc.concat(team.categories); }, []), selectedUsers: teams.reduce(function (acc, team) { return acc.concat(team.users); }, []) })); });
    };
    var onClickDeselectAllTeams = function (e) {
        e.preventDefault();
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedTeams: [], selectedCategories: [], selectedUsers: [] })); });
    };
    var onClickSelectAllUsers = function (e) {
        e.preventDefault();
        var selectedTeams = state.selectedTeams;
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedUsers: selectedTeams.reduce(function (acc, team) { return acc.concat(team.users); }, []) })); });
    };
    var onClickDeselectAllUsers = function (e) {
        e.preventDefault();
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedUsers: [] })); });
    };
    var onChangePeriod = function (teams) {
        var min = minBy(teams, function (team) {
            return moment(team.beginning_of_period).unix();
        });
        var max = maxBy(teams, function (team) { return moment(team.end_of_period).unix(); });
        var startDate = moment(min.beginning_of_period).format(DATE_FORMAT);
        var endDate = moment(max.end_of_period).format(DATE_FORMAT);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { startDate: startDate, endDate: endDate })); });
    };
    var onChangeAllPeriod = function () {
        setIsCheckAllPeriod(!isCheckAllPeriod);
        if (!isCheckAllPeriod) {
            var selectedTeams_1 = state.selectedTeams;
            onChangePeriod(selectedTeams_1);
        }
    };
    var updateTeam = function (team, checked) {
        var selectedTeams = state.selectedTeams;
        var teams = selectedTeams.filter(function (t) { return t.id !== team.id; });
        if (checked) {
            return teams;
        }
        else {
            return teams.concat(team);
        }
    };
    var updateCategories = function (team, checked) {
        var selectedCategories = state.selectedCategories;
        var categories = selectedCategories.filter(function (category) { return category.team.id !== team.id; });
        if (checked) {
            return categories;
        }
        else {
            return categories.concat(team.categories);
        }
    };
    var _removeUncheckedCategories = function (categories) {
        var selectedCategories = state.selectedCategories;
        var ids = categories.map(function (c) { return c.id; });
        return selectedCategories.filter(function (c) { return !ids.includes(c.id); });
    };
    var _insertCheckedCategories = function (categoryIds) {
        var selectedCategories = state.selectedCategories;
        return uniqBy(__spreadArray(__spreadArray([], selectedCategories, true), categoryIds, true), 'id');
    };
    var updateCategory = function (team, category, checked) {
        var targetCategories = __spreadArray([
            category
        ], descendantCategories(team, category), true);
        if (checked) {
            return _removeUncheckedCategories(targetCategories);
        }
        else {
            return _insertCheckedCategories(targetCategories);
        }
    };
    var updateUsers = function (team, checked) {
        var selectedUsers = state.selectedUsers;
        var users = selectedUsers.filter(function (users) { return users.team.id !== team.id; });
        if (checked) {
            return users;
        }
        else {
            return users.concat(team.users);
        }
    };
    var updateUser = function (team, user, checked) {
        var selectedUsers = state.selectedUsers;
        if (checked) {
            return selectedUsers.filter(function (u) {
                return u.team.id !== user.team.id ||
                    (u.team.id === user.team.id && u.id !== user.id);
            });
        }
        else {
            return selectedUsers.concat(user);
        }
    };
    var updatePeriod = function (teams) {
        if (!isCheckAllPeriod || teams.length === 0) {
            return;
        }
        onChangePeriod(teams);
    };
    var onChangeCategoriesTeam = function (team, checked) {
        if ((team.payment_required || (!team.paid && team.trial)) && !checked) {
            onOpenExportPaymentAlert(team);
        }
        if (team.payment_required) {
            return false;
        }
        var selectedTeams = updateTeam(team, checked);
        var selectedCategories = updateCategories(team, checked);
        var selectedUsers = updateUsers(team, checked);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedTeams: selectedTeams, selectedCategories: selectedCategories, selectedUsers: selectedUsers })); });
        updatePeriod(selectedTeams);
    };
    var onChangeCategory = function (team, category, checked) {
        var selectedCategories = updateCategory(team, category, checked);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedCategories: selectedCategories })); });
        var hasTeamCategories = some(selectedCategories, function (c) { return c.team.id === team.id; });
        var hasTeamUsers = some(state.selectedUsers, function (u) { return u.team.id === team.id; });
        var selectedTeams = updateTeam(team, !hasTeamCategories);
        if (hasTeamCategories && !hasTeamUsers) {
            // Select first category to team
            var selectedUsers_1 = updateUsers(team, false);
            setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedTeams: selectedTeams, selectedUsers: selectedUsers_1 })); });
        }
        if (!hasTeamCategories && hasTeamUsers) {
            // Deselect last category from team
            var selectedUsers_2 = updateUsers(team, true);
            setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedTeams: selectedTeams, selectedUsers: selectedUsers_2 })); });
        }
        else {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedTeams: selectedTeams })); });
        }
        updatePeriod(selectedTeams);
    };
    var onChangeUsersTeam = function (team, checked) {
        var selectedUsers = updateUsers(team, checked);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedUsers: selectedUsers })); });
    };
    var onChangeUser = function (team, user, checked) {
        var selectedUsers = updateUser(team, user, checked);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedUsers: selectedUsers })); });
    };
    var onSelectDate = function (selected) {
        if (selected.eventType !== EVENT_TYPE.END) {
            return;
        }
        var startDate = moment(selected.start).format('YYYY-MM-DD');
        var endDate = moment(selected.end).format('YYYY-MM-DD');
        setState(function (prevState) { return (__assign(__assign({}, prevState), { startDate: startDate, endDate: endDate, isOpenCalendar: !state.isOpenCalendar })); });
    };
    var onClickThisPeriod = function (period) {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { startDate: moment().startOf(convert(period)).format('YYYY-MM-DD'), endDate: moment().endOf(convert(period)).format('YYYY-MM-DD') })); });
    };
    var onClickLastPeriod = function (period) {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { startDate: moment()
                .subtract(1, "".concat(period, "s"))
                .startOf(convert(period))
                .format('YYYY-MM-DD'), endDate: moment()
                .subtract(1, "".concat(period, "s"))
                .endOf(convert(period))
                .format('YYYY-MM-DD') })); });
    };
    var onClickGenerate = function (dataType, timeFormat, charset) {
        var selectedCategories = state.selectedCategories, selectedUsers = state.selectedUsers, startDate = state.startDate, endDate = state.endDate;
        var filteredCategories = isShowArchived
            ? selectedCategories
            : ignoreArchivedCategories(selectedCategories);
        var teamIds = uniq(selectedUsers.map(function (user) { return user.team.id; }));
        var teams = teamIds.map(function (id) {
            return {
                id: id,
                categories: filteredCategories
                    .filter(function (category) { return category.team.id === id; })
                    .map(function (category) {
                    return {
                        id: category.id,
                    };
                }),
                users: selectedUsers
                    .filter(function (user) { return user.team.id === id; })
                    .map(function (user) {
                    return {
                        id: user.id,
                    };
                }),
            };
        });
        var params = {
            data_type: dataType,
            time_format: timeFormat,
            charset: charset,
            file_type: 'csv',
            teams: teams,
            from: startDate,
            to: endDate,
            with_archived: isShowArchived,
        };
        createAsset(params).then(function (asset) {
            if (!asset.error) {
                toggleExportForm();
                closeExportFormatModal();
                setTimeout(function () { return fetchAsset(asset.id); }, FIRST_CHECK_WAIT);
            }
        });
    };
    var disabledSubmit = function () {
        return state.selectedTeams.length < 1 || state.selectedUsers.length < 1;
    };
    var toggleCalendar = function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { isOpenCalendar: !state.isOpenCalendar })); });
    };
    var onOpenExportPaymentAlert = function (team) {
        var shownPaymentAlertTeamIds = state.shownPaymentAlertTeamIds;
        if (shownPaymentAlertTeamIds.includes(team.id)) {
            return;
        }
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedTeam: team, isOpenPaymentAlert: true })); });
    };
    var onCloseExportPaymentAlert = function (team, isDisabled) {
        if (isDisabled) {
            var shownPaymentAlertTeamIds = state.shownPaymentAlertTeamIds;
            var teamIds_1 = uniq(shownPaymentAlertTeamIds.concat([team.id]));
            variants.saveStorage('shownPaymentAlertTeamIds', teamIds_1);
            setState(function (prevState) { return (__assign(__assign({}, prevState), { shownPaymentAlertTeamIds: teamIds_1, isOpenPaymentAlert: false })); });
        }
        else {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { isOpenPaymentAlert: false })); });
        }
    };
    // @ts-ignore
    var onClickPayAction = function () {
        var selectedTeam = state.selectedTeam;
        if (!selectedTeam) {
            return false;
        }
        navigate("/app/teams/".concat(selectedTeam.id, "/payment"));
    };
    var openExportFormatModal = function () {
        setIsOpenExportFormatModal(true);
    };
    var closeExportFormatModal = function () {
        dispatch(removeDangerNotificationMessage());
        setIsOpenExportFormatModal(false);
    };
    var startDate = state.startDate, endDate = state.endDate, selectedTeams = state.selectedTeams, selectedCategories = state.selectedCategories, selectedUsers = state.selectedUsers, selectedTeam = state.selectedTeam, isOpenPaymentAlert = state.isOpenPaymentAlert;
    var teams = sortTeamsByWorkspace(exportableTeams, workspaces.items);
    if (isLoading) {
        return <LoadingPage />;
    }
    var hasInProgress = !!assets.items.find(function (asset) { return asset.status === 'initial' || asset.status === 'in_progress'; });
    var isEmptySelectedTeams = selectedTeams.length === 0;
    return (<div className="content-wrap">
      <ExportPaymentAlert isOpen={isOpenPaymentAlert} openURL={openURL} team={selectedTeam} onClose={onCloseExportPaymentAlert} onPay={onClickPayAction}/>
      <MainMenuContainer />
      <div className="content-main export">
        <div className="toolbar">
          <div className="row no-gutters">
            {!openModal && (<div className="col-8">
                <button className="text-left btn-new-task btn btn-secondary btn-lg btn-block" disabled={hasInProgress} onClick={toggleExportForm}>
                  <Icon className="mr-1" color="primary" name="plus" size="lg"/>
                  {I18n.t('export.generateNewData')}
                </button>
              </div>)}
            {openModal && (<div className="col-12 modal-wrapper">
                <div className="composer data-export-form">
                  <div className="composer-header p-1 border-bottom-1">
                    <div className="pl-2 d-flex modal-title font-weight-medium">
                      <OmitLabel maxWidth="10rem">
                        <TeamLabel teams={selectedTeams}/>
                      </OmitLabel>
                      <span className="mx-1">/</span>
                      <OmitLabel maxWidth="10rem">
                        <UserLabel users={uniqBy(selectedUsers, 'id')}/>
                      </OmitLabel>
                      <span className="mx-1">/</span>
                      {moment(startDate).format(DATE_FORMAT)} -{' '}
                      {moment(endDate).format(DATE_FORMAT)}
                    </div>
                    <div className="d-flex ml-auto">
                      <button className="btn-major-action ml-2 btn btn-primary" disabled={disabledSubmit()} onClick={openExportFormatModal}>
                        {I18n.t('export.generate')}
                      </button>
                      <ExportFormatModalContainer actionLabel={I18n.t('report.download')} isOpen={isOpenExportFormatModal} titleLabel={I18n.t('report.download')} toggle={closeExportFormatModal} onSubmit={onClickGenerate}/>
                    </div>
                  </div>
                  <div className="composer-body">
                    <div className="row no-gutters h-100">
                      <div className="col-md-4 col-12 data-export-form-block">
                        <FormSectionHeader scope="teams">
                          <div className="ml-auto">
                            <a className="mr-2" href="#" onClick={onClickSelectAllTeams}>
                              {I18n.t('shared.selectAll')}
                            </a>
                            <a href="#" onClick={onClickDeselectAllTeams}>
                              {I18n.t('shared.clear')}
                            </a>
                          </div>
                        </FormSectionHeader>
                        <CustomCheck checked={isShowArchived} className="ml-2 mb-2" onChange={function () { return setIsShowArchived(!isShowArchived); }}>
                          {I18n.t('shared.includeArchived')}
                        </CustomCheck>
                        <div className="data-export-form-list">
                          {teams.length > 0 ? (<TeamCategoryCheckList shouldDisableUnpaidTeam showsBadge showsWorkspaceAvatar selectedCategories={isShowArchived
                    ? selectedCategories
                    : ignoreArchivedCategories(selectedCategories)} teams={teams.map(function (team) {
                    return __assign(__assign({}, team), { categories: isShowArchived
                            ? team.categories
                            : ignoreArchivedCategories(team.categories) });
                })} onChangeCategory={onChangeCategory} onChangeTeam={onChangeCategoriesTeam}/>) : (<div className="text-center">
                              <LoadingSpinner size="md"/>
                            </div>)}
                        </div>
                      </div>
                      <div className="col-md-4 col-12 data-export-form-block">
                        <FormSectionHeader scope="users">
                          {!isEmptySelectedTeams && (<div className="ml-auto">
                              <a className="mr-2" href="#" onClick={onClickSelectAllUsers}>
                                {I18n.t('shared.selectAll')}
                              </a>
                              <a href="#" onClick={onClickDeselectAllUsers}>
                                {I18n.t('shared.clear')}
                              </a>
                            </div>)}
                        </FormSectionHeader>
                        <div className="data-export-form-list">
                          {teams.map(function (team) {
                var selectedTeam = selectedTeams.find(function (t) { return t.id === team.id; });
                var selectedTeamUsers = selectedUsers.filter(function (user) { return user.team.id === team.id; });
                var checked = team.users.length === selectedTeamUsers.length;
                var indeterminated = isIndeterminate(team.users, selectedTeamUsers);
                return (selectedTeam && (<ExpansionPanel key={"team-".concat(team.id, "-users")} expand className="list-group-item-expansion">
                                  {function (expand, toggle) { return (<>
                                      <ExpansionPanelHeader expand={expand} toggle={toggle} onClick={function () {
                            return onChangeUsersTeam(team, checked);
                        }}>
                                        <TeamCheckbox shouldDisableUnpaidTeam showsBadge checked={checked} indeterminate={indeterminated} team={team} onChange={onChangeUsersTeam}/>
                                      </ExpansionPanelHeader>
                                      <ExpansionPanelBody expand={expand}>
                                        <ListUsers selectedTeamUsers={selectedTeamUsers} team={team} onChange={onChangeUser}/>
                                      </ExpansionPanelBody>
                                    </>); }}
                                </ExpansionPanel>));
            })}
                        </div>
                      </div>
                      <div className="col-md-4 col-12 data-export-form-block">
                        <FormSectionHeader scope="date"/>
                        <div className="p-2">
                          <div className="form-inline">
                            <ButtonDropdown className="date-dropdown pb-2" isOpen={state.isOpenCalendar} toggle={toggleCalendar}>
                              <DropdownToggle caret disabled={isCheckAllPeriod || isEmptySelectedTeams}>
                                {startDate} - {endDate}
                              </DropdownToggle>
                              <DropdownMenu right className="p-0" style={{ overflowY: 'hidden' }}>
                                <InfiniteCalendar autoFocus={false} className="calendar-sm" Component={withRange(Calendar)} displayOptions={{
                shouldHeaderAnimate: false,
                hideYearsOnSelect: false,
            }} locale={I18n.t('infiniteCalendar')} selected={{
                start: new Date(startDate),
                end: new Date(endDate),
            }} tabIndex={7} theme={{
                floatingNav: {
                    chevron: '#fff',
                },
            }} width="100%" onSelect={onSelectDate}/>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </div>
                          {PERIODS.map(function (period) {
                return (<div key={"period-".concat(period)}>
                                <button className="btn btn-link" disabled={isCheckAllPeriod || isEmptySelectedTeams} onClick={function () { return onClickThisPeriod(period); }}>
                                  {I18n.t("export.".concat(THIS_LABEL[period]))}
                                </button>
                                <button className="btn btn-link" disabled={isCheckAllPeriod || isEmptySelectedTeams} onClick={function () { return onClickLastPeriod(period); }}>
                                  {I18n.t("export.".concat(LAST_LABEL[period]))}
                                </button>
                              </div>);
            })}
                          <hr />
                          <div className="ml-2">
                            <CustomCheck checked={isCheckAllPeriod} disabled={isEmptySelectedTeams} onChange={onChangeAllPeriod}>
                              {I18n.t('export.allPeriods')}
                            </CustomCheck>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
            {openModal && <Backdrop onClick={toggleExportForm}/>}
          </div>
        </div>
        <div className="row no-gutters with-toolbar">
          <div className="col-12">
            <AssetTableContainer />
          </div>
        </div>
      </div>
      <DeleteAssetModalContainer />
    </div>);
};
export default DataExportContainer;
