import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeDangerNotificationMessage } from '@/modules/notification';
import CategoryFormModal from '@/components/categories/category_form_modal';
var CategoryFormModalContainer = function (_a) {
    var categories = _a.categories, category = _a.category, categoryTaskTree = _a.categoryTaskTree, copySourceCategory = _a.copySourceCategory, customFieldsMasters = _a.customFieldsMasters, customFieldsValues = _a.customFieldsValues, fetchCustomFieldsValues = _a.fetchCustomFieldsValues, isOpen = _a.isOpen, onChangeTitle = _a.onChangeTitle, onCreate = _a.onCreate, onSelectCategory = _a.onSelectCategory, onSelectColor = _a.onSelectColor, onUpdate = _a.onUpdate, onToggleModal = _a.onToggleModal, parentCategory = _a.parentCategory, setNotificationMessage = _a.setNotificationMessage, team = _a.team;
    var teams = useSelector(function (state) { return state.teams; });
    var dispatch = useDispatch();
    React.useEffect(function () {
        dispatch(removeDangerNotificationMessage());
    }, [dispatch, isOpen]);
    return (<CategoryFormModal categories={categories} category={category} categoryTaskTree={categoryTaskTree} copySourceCategory={copySourceCategory} customFieldsMasters={customFieldsMasters.filter(function (_a) {
            var team_id = _a.team_id;
            return team_id === team.id;
        })} customFieldsValues={customFieldsValues} fetchCustomFieldsValues={fetchCustomFieldsValues} isOpen={isOpen} parentCategory={parentCategory} setNotificationMessage={setNotificationMessage} team={team} teams={teams} toggle={onToggleModal} onChangeTitle={onChangeTitle} onCreate={onCreate} onSelectCategory={onSelectCategory} onSelectColor={onSelectColor} onUpdate={onUpdate}/>);
};
export default CategoryFormModalContainer;
