import React from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
export var formatSuggestedTitle = function (title, keyword) {
    if (keyword === undefined) {
        return;
    }
    var pos = 0;
    var klen = keyword.length;
    return title
        .split(new RegExp(escapeRegExp(keyword), 'gi'))
        .map(function (word, index) {
        pos = pos + word.length + klen;
        return [
            word,
            <strong key={'suggested-' + index.toString()}>
          {title.substring(pos - klen, pos)}
        </strong>,
        ];
    });
};
