import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
export var FETCH_WORKSPACES_REQUEST = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_REQUEST';
export var FETCH_WORKSPACES_SUCCESS = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_SUCCESS';
export var FETCH_WORKSPACES_FAILURE = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_FAILURE';
export var UPDATE_WORKSPACE_REQUEST = 'timecrowd/WORKSPACES/UPDATE_WORKSPACE_REQUEST';
export var UPDATE_WORKSPACE_SUCCESS = 'timecrowd/WORKSPACES/UPDATE_WORKSPACE_SUCCESS';
export var UPDATE_WORKSPACE_FAILURE = 'timecrowd/WORKSPACES/UPDATE_WORKSPACE_FAILURE';
export var DELETE_WORKSPACE_REQUEST = 'timecrowd/WORKSPACES/DELETE_WORKSPACE_REQUEST';
export var DELETE_WORKSPACE_SUCCESS = 'timecrowd/WORKSPACES/DELETE_WORKSPACE_SUCCESS';
export var DELETE_WORKSPACE_FAILURE = 'timecrowd/WORKSPACES/DELETE_WORKSPACE_FAILURE';
export var SET_WORKSPACES_CURRENT = 'timecrowd/WORKSPACES/SET_WORKSPACES_CURRENT';
export var initialState = {
    isLoading: false,
    isLoaded: false,
    items: [],
    current: null,
};
export default function workspaces(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_WORKSPACES_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isLoaded: false });
        case UPDATE_WORKSPACE_REQUEST:
        case DELETE_WORKSPACE_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_WORKSPACES_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, isLoading: false, isLoaded: true });
        case UPDATE_WORKSPACE_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.map(function (item) {
                    return item.id === action.payload.id ? action.payload : item;
                }), isLoading: false });
        case DELETE_WORKSPACE_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.filter(function (item) { return item.id !== action.payload.id; }), isLoading: false });
        case FETCH_WORKSPACES_FAILURE:
        case UPDATE_WORKSPACE_FAILURE:
        case DELETE_WORKSPACE_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case SET_WORKSPACES_CURRENT:
            return __assign(__assign({}, state), { current: action.workspace });
        default:
            return state;
    }
}
export var fetchWorkspacesRequest = function () { return ({
    type: FETCH_WORKSPACES_REQUEST,
}); };
export var fetchWorkspacesSuccess = function (items) { return ({
    type: FETCH_WORKSPACES_SUCCESS,
    payload: items,
}); };
export var fetchWorkspacesFailure = function () { return ({
    type: FETCH_WORKSPACES_FAILURE,
}); };
export var fetchWorkspaces = function () {
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesRequest());
        return api2(dispatch, getState, '/api/v2/workspaces/workspaces', 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchWorkspacesSuccess(items));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchWorkspacesFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateWorkspaceRequest = function () { return ({
    type: UPDATE_WORKSPACE_REQUEST,
}); };
export var updateWorkspaceSuccess = function (item) { return ({
    type: UPDATE_WORKSPACE_SUCCESS,
    payload: item,
}); };
export var updateWorkspaceFailure = function () { return ({
    type: UPDATE_WORKSPACE_FAILURE,
}); };
export var updateWorkspace = function (id, params) {
    return function (dispatch, getState) {
        dispatch(updateWorkspaceRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/workspaces/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(updateWorkspaceSuccess(item));
                        return [2 /*return*/, { error: false, item: item }];
                    case 2:
                        dispatch(updateWorkspaceFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var deleteWorkspaceRequest = function () { return ({
    type: DELETE_WORKSPACE_REQUEST,
}); };
export var deleteWorkspaceSuccess = function (item) { return ({
    type: DELETE_WORKSPACE_SUCCESS,
    payload: item,
}); };
export var deleteWorkspaceFailure = function () { return ({
    type: DELETE_WORKSPACE_FAILURE,
}); };
export var deleteWorkspace = function (id) {
    return function (dispatch, getState) {
        dispatch(deleteWorkspaceRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/workspaces/".concat(id), 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(deleteWorkspaceSuccess({ id: id }));
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(deleteWorkspaceFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
export var setCurrentWorkspace = function (workspace) { return ({
    type: SET_WORKSPACES_CURRENT,
    workspace: workspace,
}); };
export var stateWithoutWorkspaces = function (getState) {
    return function () { return (__assign(__assign({}, getState()), { workspaces: null })); };
};
