import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import CategoryListContainer from '@/containers/category_list_container';
import { NewCategoryFormModal } from '@/components/categories/new_category_form_modal';
import { fetchCustomFieldsMasters } from '@/modules/custom_fields_masters';
import { fetchCustomFieldsValues } from '@/modules/custom_fields_values';
import { setNotificationMessage } from '@/modules/notification';
import { TeamManagementLayoutContainer } from '@/containers/team_management_layout_container';
import { workspaceTeams as filterWorkspaceTeams } from '@/utils/workspace_helper';
import NotFound from '@/components/pages/not_found';
var CategorySettingContainer = function (props) {
    var copyTeamCategory = props.copyTeamCategory, createTeamCategory = props.createTeamCategory, updateTeamCategory = props.updateTeamCategory, moveLowerCategoryPosition = props.moveLowerCategoryPosition, moveHigherCategoryPosition = props.moveHigherCategoryPosition, categoryTaskTree = props.categoryTaskTree, categories = props.categories, fetchCategories = props.fetchCategories;
    var customFieldsMasters = useSelector(function (state) { return state.customFieldsMasters.items; });
    var customFieldsValues = useSelector(function (state) { return state.customFieldsValues.items; });
    var teams = useSelector(function (state) { return state.teams; });
    var workspaceTeams = React.useMemo(function () { return filterWorkspaceTeams(teams.items); }, [teams.items]);
    var _a = React.useState(false), isNewCategoryModalOpened = _a[0], setIsNewCategoryModalOpened = _a[1];
    var team_id = useParams().team_id;
    var dispatch = useDispatch();
    var handleFetchCustomFieldsMasters = React.useCallback(function (teamId) {
        dispatch(fetchCustomFieldsMasters(teamId));
    }, [dispatch]);
    var handleFetchCustomFieldsValues = React.useCallback(function (teamId) {
        dispatch(fetchCustomFieldsValues(teamId));
    }, [dispatch]);
    var handleSetNotificationMessage = React.useCallback(function (type, message) {
        dispatch(setNotificationMessage(type, message, { isHtml: true }));
    }, [dispatch]);
    React.useEffect(function () {
        fetchCategories({ team_id: team_id });
    }, [fetchCategories, team_id]);
    React.useEffect(function () {
        if (!teams.isFetched) {
            return;
        }
        var team = workspaceTeams.find(function (_a) {
            var id = _a.id;
            return id === Number(team_id);
        });
        if (team === null || team === void 0 ? void 0 : team.is_custom_fields_allowed) {
            handleFetchCustomFieldsMasters(team.id);
            handleFetchCustomFieldsValues(team.id);
        }
    }, [
        handleFetchCustomFieldsMasters,
        handleFetchCustomFieldsValues,
        team_id,
        teams.isFetched,
        workspaceTeams,
    ]);
    var onClickNewCategory = function () {
        setIsNewCategoryModalOpened(true);
    };
    var team = workspaceTeams.find(function (team) { return team.id === Number(team_id); });
    if (!team) {
        if (teams.isFetched) {
            return <NotFound />;
        }
        return null;
    }
    return (<TeamManagementLayoutContainer currentPage="categories" saveButtonForPC={team.can_manage && (<button className="btn-major-action btn btn-primary" onClick={onClickNewCategory}>
            {I18n.t('teamManagement.categoryPage.add')}
          </button>)} saveButtonForSP={(team === null || team === void 0 ? void 0 : team.can_manage) && (<button className="btn-major-action btn btn-link" onClick={onClickNewCategory}>
            <Icon className="mr-1" color="primary" name="plus" size="lg"/>
          </button>)} team={team} title={I18n.t('teamManagement.categoryPage.title')}>
      <CategoryListContainer categories={categories} copyTeamCategory={copyTeamCategory} createTeamCategory={createTeamCategory} fetchCustomFieldsValues={handleFetchCustomFieldsValues} moveHigherCategoryPosition={moveHigherCategoryPosition} moveLowerCategoryPosition={moveLowerCategoryPosition} setNotificationMessage={handleSetNotificationMessage} team={team} updateTeamCategory={updateTeamCategory}/>

      <NewCategoryFormModal categories={categories} categoryTaskTree={categoryTaskTree} createTeamCategory={createTeamCategory} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues} fetchCustomFieldsValues={handleFetchCustomFieldsValues} isOpen={isNewCategoryModalOpened} setIsOpen={function (isNewCategoryModalOpened) {
            return setIsNewCategoryModalOpened(isNewCategoryModalOpened);
        }} setNotificationMessage={handleSetNotificationMessage} team={team}/>
    </TeamManagementLayoutContainer>);
};
export default CategorySettingContainer;
