import { __assign, __spreadArray } from "tslib";
import React from 'react';
import { arrangeCustomFieldsValues } from '@/components/custom_fields_value_list/helpers';
import I18n from '@/constants/i18n';
import { fullToHalf } from '@/utils/number_helper';
import { SyntaxHighlighting } from '@/components/syntax_highlighting';
import { isLeaf } from '@/utils/category_helper';
export var CustomFieldsValuesForm = function (props) {
    var customFieldsMasters = props.customFieldsMasters, customFieldsValues = props.customFieldsValues, customFieldsErrors = props.customFieldsErrors, categories = props.categories, target = props.target, onChangeCustomFieldsValues = props.onChangeCustomFieldsValues;
    var customFieldsValuesByMasterId = arrangeCustomFieldsValues(customFieldsMasters, customFieldsValues, categories, target).customFieldsValuesByMasterId;
    if (customFieldsMasters.length < 1) {
        return null;
    }
    var onChangeValueText = function (masterId, value, newValueText) {
        var newCustomFieldsValues;
        if (value.custom_fields_master_id) {
            newCustomFieldsValues = customFieldsValues.map(function (v) {
                if (v.custom_fields_master_id === masterId) {
                    return __assign(__assign({}, v), { value_text: newValueText });
                }
                else {
                    return v;
                }
            });
        }
        else {
            newCustomFieldsValues = __spreadArray(__spreadArray([], customFieldsValues, true), [
                {
                    custom_fields_master_id: masterId,
                    value_text: newValueText,
                    target_id: target.id,
                },
            ], false);
        }
        onChangeCustomFieldsValues(newCustomFieldsValues);
    };
    var onChangeDuration = function (id, value, hours, minutes) {
        var text = !hours && !minutes ? '' : "".concat(hours, ":").concat(minutes);
        onChangeValueText(id, value, text);
    };
    return (<>
      <p className="small font-weight-bold mt-3 mb-1">
        {I18n.t('shared.customFields')}
      </p>
      <table className="w-100">
        <tbody>
          {customFieldsMasters.map(function (_a) {
            var _b, _c, _d;
            var id = _a.id, name = _a.name, requirement = _a.requirement, field_type = _a.field_type, unit = _a.unit, expression_text = _a.expression_text;
            var value = (_b = customFieldsValuesByMasterId[id]) !== null && _b !== void 0 ? _b : {};
            var isLeafRequirement = requirement === 'leaf' && isLeaf(categories, target);
            return (<tr key={id}>
                  <td className="align-top pt-2 w-25">
                    <div className="form-control-static text-break">
                      {name}
                      {(requirement === 'all' || isLeafRequirement) && (<span className="text-danger">*</span>)}
                    </div>
                  </td>
                  <td className="align-top pt-2 pl-3">
                    <div className="d-flex align-items-center text-break">
                      {field_type === 'number' && (<>
                          <input className="form-control form-control-sm w-50" value={value.value_text} onChange={function (e) {
                        return onChangeValueText(id, value, fullToHalf(e.target.value));
                    }}/>
                          <span className="ml-1 text-break">{unit}</span>
                        </>)}
                      {field_type === 'text' && (<textarea className="form-control form-control-sm" rows={3} value={value.value_text} onChange={function (e) {
                        return onChangeValueText(id, value, e.target.value);
                    }}/>)}
                      {field_type === 'duration' &&
                    (function () {
                        var _a = (value.value_text || ':').split(':'), hours = _a[0], minutes = _a[1];
                        return (<>
                              <input className="form-control form-control-sm w-25" value={hours} onChange={function (e) {
                                return onChangeDuration(id, value, fullToHalf(e.target.value).replace(/^0+/, ''), minutes);
                            }}/>
                              <span className="text-nowrap ml-1">
                                {I18n.t('shared.hours')}
                              </span>
                              <input className="form-control form-control-sm w-25 ml-2" value={minutes} onBlur={function (_a) {
                                var value = _a.target.value;
                                if (value) {
                                    onChangeDuration(id, value, hours, value.padStart(2, '0'));
                                }
                            }} onChange={function (e) {
                                return onChangeDuration(id, value, hours, fullToHalf(e.target.value));
                            }}/>
                              <span className="text-nowrap ml-1">
                                {I18n.t('shared.minutes')}
                              </span>
                            </>);
                    })()}
                      {field_type === 'expression' && (<SyntaxHighlighting className="form-control-static" expressionText={expression_text}/>)}
                    </div>
                    <div className="text-danger">
                      <div dangerouslySetInnerHTML={{
                    __html: (_d = (_c = customFieldsErrors.find(function (e) { return e.custom_fields_master_id === id; })) === null || _c === void 0 ? void 0 : _c.error) !== null && _d !== void 0 ? _d : '',
                }}/>
                    </div>
                  </td>
                </tr>);
        })}
        </tbody>
      </table>
    </>);
};
