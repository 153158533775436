import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import api2 from '@/api2';
import queryString from 'query-string';
export var LIST_WORKSPACES_LONG_TERM_REPORTS_REQUEST = 'timecrowd/WORKSPACES/LIST_WORKSPACES_LONG_TERM_REPORTS_REQUEST';
export var LIST_WORKSPACES_LONG_TERM_REPORTS_SUCCESS = 'timecrowd/WORKSPACES/LIST_WORKSPACES_LONG_TERM_REPORTS_SUCCESS';
export var LIST_WORKSPACES_LONG_TERM_REPORTS_FAILURE = 'timecrowd/WORKSPACES/LIST_WORKSPACES_LONG_TERM_REPORTS_FAILURE';
export var FETCH_WORKSPACES_LONG_TERM_REPORT_REQUEST = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_LONG_TERM_REPORT_REQUEST';
export var FETCH_WORKSPACES_LONG_TERM_REPORT_SUCCESS = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_LONG_TERM_REPORT_SUCCESS';
export var FETCH_WORKSPACES_LONG_TERM_REPORT_FAILURE = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_LONG_TERM_REPORT_FAILURE';
export var CREATE_WORKSPACES_LONG_TERM_REPORT_REQUEST = 'timecrowd/WORKSPACES/CREATE_WORKSPACES_LONG_TERM_REPORT_REQUEST';
export var CREATE_WORKSPACES_LONG_TERM_REPORT_SUCCESS = 'timecrowd/WORKSPACES/CREATE_WORKSPACES_LONG_TERM_REPORT_SUCCESS';
export var CREATE_WORKSPACES_LONG_TERM_REPORT_FAILURE = 'timecrowd/WORKSPACES/CREATE_WORKSPACES_LONG_TERM_REPORT_FAILURE';
export var CANCEL_WORKSPACES_LONG_TERM_REPORT_REQUEST = 'timecrowd/WORKSPACES/CANCEL_WORKSPACES_LONG_TERM_REPORT_REQUEST';
export var CANCEL_WORKSPACES_LONG_TERM_REPORT_SUCCESS = 'timecrowd/WORKSPACES/CANCEL_WORKSPACES_LONG_TERM_REPORT_SUCCESS';
export var CANCEL_WORKSPACES_LONG_TERM_REPORT_FAILURE = 'timecrowd/WORKSPACES/CANCEL_WORKSPACES_LONG_TERM_REPORT_FAILURE';
export var REMOVE_WORKSPACES_COMPLETED_LONG_TERM_REPORT = 'timecrowd/WORKSPACES/REMOVE_WORKSPACES_COMPLETED_LONG_TERM_REPORT';
export var SET_CURRENT_WORKSPACES_LONG_TERM_REPORT = 'timecrowd/WORKSPACES/SET_CURRENT_WORKSPACES_LONG_TERM_REPORT';
export var initialState = {
    status: 'pending',
    items: [],
    completedItems: [],
    current: null,
};
var version = 1;
export default function workspacesLongTermReports(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LIST_WORKSPACES_LONG_TERM_REPORTS_REQUEST:
        case FETCH_WORKSPACES_LONG_TERM_REPORT_REQUEST:
        case CREATE_WORKSPACES_LONG_TERM_REPORT_REQUEST:
        case CANCEL_WORKSPACES_LONG_TERM_REPORT_REQUEST:
            return __assign(__assign({}, state), { status: 'loading' });
        case LIST_WORKSPACES_LONG_TERM_REPORTS_SUCCESS:
            return __assign(__assign({}, state), { status: 'success', items: action.payload.workspaceId
                    ? state.items
                        .filter(function (item) {
                        return item.workspaces_workspace_id !== action.payload.workspaceId;
                    })
                        .concat(action.payload.items)
                    : action.payload.items });
        case CREATE_WORKSPACES_LONG_TERM_REPORT_SUCCESS:
            return __assign(__assign({}, state), { status: 'success', items: __spreadArray(__spreadArray([], state.items, true), [action.payload], false) });
        case FETCH_WORKSPACES_LONG_TERM_REPORT_SUCCESS: {
            var isCompleted = state.items.some(function (item) {
                return item.id === action.payload.id &&
                    item.status !== 'completed' &&
                    action.payload.status === 'completed';
            });
            return __assign(__assign({}, state), { status: 'success', items: state.items.map(function (item) {
                    return item.id === action.payload.id ? action.payload : item;
                }), completedItems: isCompleted
                    ? __spreadArray(__spreadArray([], state.completedItems, true), [action.payload], false) : state.completedItems });
        }
        case CANCEL_WORKSPACES_LONG_TERM_REPORT_SUCCESS:
            return __assign(__assign({}, state), { status: 'success', items: state.items.filter(function (item) { return item.id !== action.payload.id; }) });
        case LIST_WORKSPACES_LONG_TERM_REPORTS_FAILURE:
        case FETCH_WORKSPACES_LONG_TERM_REPORT_FAILURE:
        case CREATE_WORKSPACES_LONG_TERM_REPORT_FAILURE:
        case CANCEL_WORKSPACES_LONG_TERM_REPORT_FAILURE:
            return __assign(__assign({}, state), { status: 'failure' });
        case REMOVE_WORKSPACES_COMPLETED_LONG_TERM_REPORT:
            return __assign(__assign({}, state), { completedItems: state.completedItems.filter(function (item) { return item.id !== action.payload; }) });
        case SET_CURRENT_WORKSPACES_LONG_TERM_REPORT:
            return __assign(__assign({}, state), { current: action.payload });
        default:
            return state;
    }
}
export var listWorkspacesLongTermReportsRequest = function () { return ({
    type: LIST_WORKSPACES_LONG_TERM_REPORTS_REQUEST,
}); };
export var listWorkspacesLongTermReportsSuccess = function (workspaceId, items) { return ({
    type: LIST_WORKSPACES_LONG_TERM_REPORTS_SUCCESS,
    payload: {
        workspaceId: workspaceId,
        items: items,
    },
}); };
export var listWorkspacesLongTermReportsFailure = function () { return ({
    type: LIST_WORKSPACES_LONG_TERM_REPORTS_FAILURE,
}); };
export var listWorkspacesLongTermReports = function (workspaceId, params) {
    return function (dispatch, getState) {
        dispatch(listWorkspacesLongTermReportsRequest());
        return api2(dispatch, function () { return (__assign(__assign({}, getState()), { workspaces: {
                current: { id: workspaceId },
            } })); }, "/api/v2/workspaces/long_term_reports?".concat(queryString.stringify(__assign(__assign({}, params), { version: version }))), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(listWorkspacesLongTermReportsSuccess(workspaceId, item));
                        return [2 /*return*/, { error: false, data: item }];
                    case 2:
                        dispatch(listWorkspacesLongTermReportsFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var fetchWorkspacesLongTermReportRequest = function () { return ({
    type: FETCH_WORKSPACES_LONG_TERM_REPORT_REQUEST,
}); };
export var fetchWorkspacesLongTermReportSuccess = function (item) { return ({
    type: FETCH_WORKSPACES_LONG_TERM_REPORT_SUCCESS,
    payload: item,
}); };
export var fetchWorkspacesLongTermReportFailure = function () { return ({
    type: FETCH_WORKSPACES_LONG_TERM_REPORT_FAILURE,
}); };
export var fetchWorkspacesLongTermReport = function (workspaceId, id) {
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesLongTermReportRequest());
        return api2(dispatch, function () { return (__assign(__assign({}, getState()), { workspaces: {
                current: { id: workspaceId },
            } })); }, "/api/v2/workspaces/long_term_reports/".concat(id, "?version=").concat(version), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(fetchWorkspacesLongTermReportSuccess(item));
                        return [2 /*return*/, { error: false, data: item }];
                    case 2:
                        dispatch(fetchWorkspacesLongTermReportFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var createWorkspacesLongTermReportRequest = function () { return ({
    type: CREATE_WORKSPACES_LONG_TERM_REPORT_REQUEST,
}); };
export var createWorkspacesLongTermReportSuccess = function (item) { return ({
    type: CREATE_WORKSPACES_LONG_TERM_REPORT_SUCCESS,
    payload: item,
}); };
export var createWorkspacesLongTermReportFailure = function () { return ({
    type: CREATE_WORKSPACES_LONG_TERM_REPORT_FAILURE,
}); };
export var createWorkspacesLongTermReport = function (params) {
    return function (dispatch, getState) {
        dispatch(createWorkspacesLongTermReportRequest());
        return api2(dispatch, getState, '/api/v2/workspaces/long_term_reports', 'POST', __assign(__assign({}, params), { version: version })).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(createWorkspacesLongTermReportSuccess(item));
                        return [2 /*return*/, { error: false, data: item }];
                    case 2:
                        dispatch(createWorkspacesLongTermReportFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var cancelWorkspacesLongTermReportRequest = function () { return ({
    type: CANCEL_WORKSPACES_LONG_TERM_REPORT_REQUEST,
}); };
export var cancelWorkspacesLongTermReportSuccess = function (item) { return ({
    type: CANCEL_WORKSPACES_LONG_TERM_REPORT_SUCCESS,
    payload: item,
}); };
export var cancelWorkspacesLongTermReportFailure = function () { return ({
    type: CANCEL_WORKSPACES_LONG_TERM_REPORT_FAILURE,
}); };
export var cancelWorkspacesLongTermReport = function (id) {
    return function (dispatch, getState) {
        dispatch(cancelWorkspacesLongTermReportRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/long_term_reports/".concat(id, "/cancel?version=").concat(version), 'PATCH').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(cancelWorkspacesLongTermReportSuccess(item));
                        return [2 /*return*/, { error: false, data: item }];
                    case 2:
                        dispatch(cancelWorkspacesLongTermReportFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var removeWorkspacesCompletedLongTermReport = function (id) { return ({
    type: REMOVE_WORKSPACES_COMPLETED_LONG_TERM_REPORT,
    payload: id,
}); };
export var setCurrentWorkspacesLongTermReport = function (item) { return ({
    type: SET_CURRENT_WORKSPACES_LONG_TERM_REPORT,
    payload: item,
}); };
