import React from 'react';
import AssetListItem from './asset_list_item';
export default function AssetList(_a) {
    var assets = _a.assets;
    return (<tbody>
      {assets.map(function (asset) {
            return (
            // @ts-ignore
            <AssetListItem key={asset.id} asset={asset}/>);
        })}
    </tbody>);
}
