import React from 'react';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
import { CrossTeamReportPeriodSelectorContainer } from '@/containers/cross_team_report_period_selector_container';
import { MultiTeamsSelectDropdown } from '@/components/multi_teams_select_dropdown';
import { MultiCategoriesSelectDropdown } from '@/components/multi_categories_select_dropdown';
import { MultiUsersSelectDropdown } from '@/components/multi_users_select_dropdown';
import { CrossTeamReportBookmarkCreateModalContainer } from '@/containers/cross_team_report_bookmark_create_modal_container';
import { buildUsableUsers, buildLeavedUserIds } from './helpers';
import { CustomSwitch } from '@/components/custom_switch';
import variants from '@/constants/variants';
export var CrossTeamReportHeader = function (props) {
    var allCategories = props.allCategories, allTeams = props.allTeams, allUsers = props.allUsers, defaultPeriod = props.defaultPeriod, defaultStartMonth = props.defaultStartMonth, memberships = props.memberships, _a = props.openURL, openURL = _a === void 0 ? variants.openURL : _a, selectedCategories = props.selectedCategories, selectedTeams = props.selectedTeams, selectedUsers = props.selectedUsers, withArchived = props.withArchived, workedCategoryIdSet = props.workedCategoryIdSet, workedTeamIdSet = props.workedTeamIdSet, workedUserIdSet = props.workedUserIdSet, workspacesMemberships = props.workspacesMemberships, onChangeWithArchived = props.onChangeWithArchived, onOpenCSV = props.onOpenCSV, onSelectCategories = props.onSelectCategories, onSelectTeams = props.onSelectTeams, onSelectUsers = props.onSelectUsers;
    var _b = React.useState(false), isShowShortLoading = _b[0], setIsShowShortLoading = _b[1];
    var _c = React.useMemo(function () {
        if (selectedTeams.length < 1) {
            return {
                usableAllCategories: allCategories,
                usableSelectedCategories: selectedCategories,
            };
        }
        var teamsIdSet = new Set(selectedTeams.map(function (team) { return team.id; }));
        return {
            usableAllCategories: allCategories.filter(function (category) {
                return teamsIdSet.has(category.team_id);
            }),
            usableSelectedCategories: selectedCategories.filter(function (category) {
                return teamsIdSet.has(category.team_id);
            }),
        };
    }, [allCategories, selectedCategories, selectedTeams]), usableAllCategories = _c.usableAllCategories, usableSelectedCategories = _c.usableSelectedCategories;
    var categoryTeams = React.useMemo(function () {
        var teamById = allTeams.reduce(function (acc, team) {
            acc[team.id] = team;
            return acc;
        }, {});
        return Array.from(new Set(usableSelectedCategories.map(function (category) { return teamById[category.team_id]; })));
    }, [allTeams, usableSelectedCategories]);
    var _d = React.useMemo(function () {
        var _a = buildUsableUsers(selectedTeams, memberships, allUsers, selectedUsers), usableAllUsers = _a.usableAllUsers, usableSelectedUsers = _a.usableSelectedUsers;
        var leavedUserIds = buildLeavedUserIds(selectedTeams, memberships, workspacesMemberships);
        return {
            usableAllUsers: usableAllUsers,
            usableSelectedUsers: usableSelectedUsers,
            leavedUserIds: leavedUserIds,
        };
    }, [
        selectedTeams,
        allUsers,
        selectedUsers,
        memberships,
        workspacesMemberships,
    ]), usableAllUsers = _d.usableAllUsers, usableSelectedUsers = _d.usableSelectedUsers, leavedUserIds = _d.leavedUserIds;
    var withShortLoading = React.useCallback(function (callback) {
        setIsShowShortLoading(true);
        setTimeout(function () {
            callback();
            setIsShowShortLoading(false);
        }, 0);
    }, []);
    var handleSelectCategories = React.useCallback(function (categories) {
        withShortLoading(function () { return onSelectCategories(categories); });
    }, [onSelectCategories, withShortLoading]);
    var handleSelectTeams = React.useCallback(function (teams) {
        withShortLoading(function () { return onSelectTeams(teams); });
    }, [onSelectTeams, withShortLoading]);
    var handleSelectUsers = React.useCallback(function (users) {
        withShortLoading(function () { return onSelectUsers(users); });
    }, [onSelectUsers, withShortLoading]);
    var handleClickLink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    return (<div className="p-2 border-bottom-1 h-44 position-fixed top-0 left-0 right-0 z-1 content-main min-h-auto bg-white shadow">
      <div className="d-flex">
        <CrossTeamReportPeriodSelectorContainer defaultPeriod={defaultPeriod} defaultStartMonth={defaultStartMonth}/>
        <a className="btn btn-link ml-auto" href="https://support.timecrowd.net/cross-team-report-overview" onClick={handleClickLink}>
          <Icon className="mr-1 align-baseline" name="question"/>
          <span className="hidden-md-down">{I18n.t('shared.help')}</span>
        </a>

        <button className="btn btn-link" onClick={onOpenCSV}>
          <Icon className="mr-1 align-baseline" name="download"/>
          <span className="hidden-md-down">
            {I18n.t('report.download_csv')}
          </span>
        </button>
        <CrossTeamReportBookmarkCreateModalContainer conditions={{
            term: defaultPeriod.term,
            period_type: defaultPeriod.period_type,
            from: defaultPeriod.from,
            to: defaultPeriod.to,
            start_month: defaultStartMonth,
            selected_teams: selectedTeams,
            selected_users: selectedUsers,
            selected_categories: usableSelectedCategories,
            category_teams: categoryTeams,
        }}/>
      </div>
      <div className="d-flex align-items-center mt-2">
        <Icon className="text-primary mr-1" name="scale"/>
        <div className="d-flex align-items-center text-muted mr-3">
          <Icon className="mr-0.5" name="user-group"/>
          <span className="mr-1">
            <span className="hidden-md-down">{I18n.t('shared.team')}</span>
          </span>
          <MultiTeamsSelectDropdown allTeams={allTeams} disabled={allTeams.length < 1} selectedTeams={selectedTeams} workedTeamIdSet={workedTeamIdSet} onApply={handleSelectTeams}/>
        </div>
        <div className="d-flex align-items-center text-muted mr-3">
          <Icon className="mr-0.5" name="user"/>
          <span className="mr-1">
            <span className="hidden-md-down">{I18n.t('shared.user')}</span>
          </span>
          <MultiUsersSelectDropdown allUsers={usableAllUsers} disabled={usableAllUsers.length < 1} leavedUserIds={leavedUserIds} selectedUsers={usableSelectedUsers} workedUserIdSet={workedUserIdSet} onApply={handleSelectUsers}/>
        </div>
        <div className="d-flex align-items-center text-muted">
          <Icon className="mr-0.5" name="tags"/>
          <span className="mr-1">
            <span className="hidden-md-down">{I18n.t('shared.category')}</span>
          </span>
          <MultiCategoriesSelectDropdown allCategories={usableAllCategories} disabled={usableAllCategories.length < 1 || allTeams.length < 1} selectedCategories={usableSelectedCategories} teams={selectedTeams.length ? selectedTeams : allTeams} workedCategoryIdSet={workedCategoryIdSet} onApply={handleSelectCategories}/>
        </div>
        <CustomSwitch checked={withArchived} className="ml-3 text-muted" onChange={function () { return onChangeWithArchived(!withArchived); }}>
          <Icon className="mr-0.5 align-baseline" name="archive" size="sm"/>
          <span className="hidden-md-down">
            {I18n.t('shared.includeArchived')}
          </span>
        </CustomSwitch>
        {isShowShortLoading && (<div className="text-primary ml-3">
            <Icon className="mr-1" name="clockwise"/>
            {I18n.t('shared.refreshing')}…
          </div>)}
      </div>
    </div>);
};
