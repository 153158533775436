import { __awaiter, __generator } from "tslib";
import { setNotificationMessage } from '@/modules/notification';
import I18n from '@/constants/i18n';
import { NETWORK_ERROR_MESSAGES } from '@/constants/network_error_messages';
import { parseUnprocessableEntityMessages, request as requestV2 } from '@/api2';
var request = function (getState, path, method, body) {
    return requestV2(getState, "/api/v1/".concat(path), method, body, false);
};
var api = function (dispatch, getState, path, method, body) {
    if (body === void 0) { body = null; }
    return request(getState, path, method, body)
        .then(function (response) {
        return response;
    })
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = response.status;
                    switch (_a) {
                        case 200: return [3 /*break*/, 1];
                        case 204: return [3 /*break*/, 2];
                        case 401: return [3 /*break*/, 3];
                        case 403: return [3 /*break*/, 4];
                        case 404: return [3 /*break*/, 5];
                        case 413: return [3 /*break*/, 6];
                        case 500: return [3 /*break*/, 7];
                        case 422: return [3 /*break*/, 8];
                        case 503: return [3 /*break*/, 8];
                    }
                    return [3 /*break*/, 10];
                case 1: return [2 /*return*/, response.json()];
                case 2: return [2 /*return*/, {}];
                case 3: return [2 /*return*/, { error: true }];
                case 4:
                    dispatch(setNotificationMessage('danger', I18n.t('errors.forbidden')));
                    return [2 /*return*/, { error: true }];
                case 5:
                    dispatch(setNotificationMessage('danger', I18n.t('errors.notFound')));
                    return [2 /*return*/, { error: true }];
                case 6:
                    dispatch(setNotificationMessage('danger', I18n.t('errors.tooLarge')));
                    return [2 /*return*/, { error: true }];
                case 7:
                    dispatch(setNotificationMessage('danger', I18n.t('errors.internalServerError'), { isHtml: true }));
                    return [2 /*return*/, { error: true }];
                case 8: return [4 /*yield*/, response.json().then(function (result) {
                        if (result.messages) {
                            dispatch(setNotificationMessage('danger', parseUnprocessableEntityMessages(result.messages), { isHtml: true }));
                        }
                    })];
                case 9:
                    _b.sent();
                    return [2 /*return*/, { error: true }];
                case 10: return [2 /*return*/, response.json()];
            }
        });
    }); })
        .catch(function (e) {
        if (NETWORK_ERROR_MESSAGES.test(e.message)) {
            dispatch(setNotificationMessage('danger', I18n.t('errors.network')));
        }
        else {
            throw e;
        }
        return { error: true };
    });
};
export default api;
