import moment from 'moment'; // eslint-disable-line no-restricted-imports
export var filterCalendarItems = function (items, view, selectedDay) {
    if (view === 'daily') {
        return items.filter(function (_a) {
            var date = _a.date;
            return date === moment(selectedDay).format('YYYY-MM-DD');
        });
    }
    else if (view === 'weekly') {
        var startOfWeek_1 = moment(selectedDay).startOf('isoWeek');
        var endOfWeek_1 = moment(selectedDay).endOf('isoWeek');
        return items.filter(function (_a) {
            var date = _a.date;
            return moment(date).isBetween(startOfWeek_1, endOfWeek_1, 'day', '[]');
        });
    }
    else {
        return items;
    }
};
