import React from 'react';
import isEmpty from 'lodash/isEmpty';
import I18n from '@/constants/i18n';
var NewChildTeamForm = function (props) {
    var team = props.team, onCreate = props.onCreate;
    var _a = React.useState(''), name = _a[0], setName = _a[1];
    var _b = React.useState(false), disabled = _b[0], setDisabled = _b[1];
    var onChangeName = function (e) {
        setName(e.target.value);
    };
    var handleClickCreate = function () {
        setDisabled(true);
        onCreate({ team: { name: name, parent_id: team.id } }).then(function (team) {
            setDisabled(false);
            if (team.error) {
                return;
            }
            setName('');
        });
    };
    return (<div>
      <h3 className="mb-2">{I18n.t('shared.createChildTeam')}</h3>
      <div className="form-group">
        <div className="form-inline">
          <input className="form-control mr-2" placeholder={I18n.t('teamManagement.newTeam.defaultName')} type="text" value={name} onChange={onChangeName}/>
          <button className="btn-major-action btn btn-primary" disabled={isEmpty(name) || disabled} onClick={handleClickCreate}>
            {I18n.t('shared.create')}
          </button>
        </div>
      </div>
    </div>);
};
export default NewChildTeamForm;
