import { __assign } from "tslib";
import api2 from '@/api2';
export var FETCH_TWO_FACTOR_AUTH_REQUEST = 'timecrowd/two_factor_auth/FETCH_TWO_FACTOR_AUTH_REQUEST';
export var FETCH_TWO_FACTOR_AUTH_SUCCESS = 'timecrowd/two_factor_auth/FETCH_TWO_FACTOR_AUTH_SUCCESS';
export var FETCH_TWO_FACTOR_AUTH_FAILURE = 'timecrowd/two_factor_auth/FETCH_TWO_FACTOR_AUTH_FAILURE';
export var DISABLE_TWO_FACTOR_AUTH_REQUEST = 'timecrowd/two_factor_auth/DISABLE_TWO_FACTOR_AUTH_REQUEST';
export var DISABLE_TWO_FACTOR_AUTH_SUCCESS = 'timecrowd/two_factor_auth/DISABLE_TWO_FACTOR_AUTH_SUCCESS';
export var DISABLE_TWO_FACTOR_AUTH_FAILURE = 'timecrowd/two_factor_auth/DISABLE_TWO_FACTOR_AUTH_FAILURE';
export var PREPARE_TWO_FACTOR_AUTH_REQUEST = 'timecrowd/two_factor_auth/PREPARE_TWO_FACTOR_AUTH_REQUEST';
export var PREPARE_TWO_FACTOR_AUTH_SUCCESS = 'timecrowd/two_factor_auth/PREPARE_TWO_FACTOR_AUTH_SUCCESS';
export var PREPARE_TWO_FACTOR_AUTH_FAILURE = 'timecrowd/two_factor_auth/PREPARE_TWO_FACTOR_AUTH_FAILURE';
export var ENABLE_TWO_FACTOR_AUTH_REQUEST = 'timecrowd/two_factor_auth/ENABLE_TWO_FACTOR_AUTH_REQUEST';
export var ENABLE_TWO_FACTOR_AUTH_SUCCESS = 'timecrowd/two_factor_auth/ENABLE_TWO_FACTOR_AUTH_SUCCESS';
export var ENABLE_TWO_FACTOR_AUTH_FAILURE = 'timecrowd/two_factor_auth/ENABLE_TWO_FACTOR_AUTH_FAILURE';
export var BACKUP_TWO_FACTOR_AUTH_REQUEST = 'timecrowd/two_factor_auth/BACKUP_TWO_FACTOR_AUTH_REQUEST';
export var BACKUP_TWO_FACTOR_AUTH_SUCCESS = 'timecrowd/two_factor_auth/BACKUP_TWO_FACTOR_AUTH_SUCCESS';
export var BACKUP_TWO_FACTOR_AUTH_FAILURE = 'timecrowd/two_factor_auth/BACKUP_TWO_FACTOR_AUTH_FAILURE';
var initialState = {
    isLoading: false,
    isFetched: false,
    required: false,
    backedUp: false,
    otpQrCode: '',
};
export default function twoFactorAuth(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_TWO_FACTOR_AUTH_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_TWO_FACTOR_AUTH_SUCCESS:
            return __assign(__assign(__assign({}, state), { isLoading: false, isFetched: true }), action.payload);
        case FETCH_TWO_FACTOR_AUTH_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case DISABLE_TWO_FACTOR_AUTH_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case DISABLE_TWO_FACTOR_AUTH_SUCCESS:
            return __assign(__assign({}, initialState), { isFetched: true, isLoading: false });
        case DISABLE_TWO_FACTOR_AUTH_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case PREPARE_TWO_FACTOR_AUTH_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case PREPARE_TWO_FACTOR_AUTH_SUCCESS:
            return __assign(__assign(__assign({}, state), { isLoading: false }), action.payload);
        case PREPARE_TWO_FACTOR_AUTH_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case ENABLE_TWO_FACTOR_AUTH_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case ENABLE_TWO_FACTOR_AUTH_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, required: true, otpQrCode: '' });
        case ENABLE_TWO_FACTOR_AUTH_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case BACKUP_TWO_FACTOR_AUTH_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case BACKUP_TWO_FACTOR_AUTH_SUCCESS:
            return __assign(__assign(__assign({}, state), { isLoading: false, backedUp: true }), action.payload);
        case BACKUP_TWO_FACTOR_AUTH_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
export var fetchTwoFactorAuthRequest = function () { return ({
    type: FETCH_TWO_FACTOR_AUTH_REQUEST,
}); };
export var fetchTwoFactorAuthSuccess = function (payload) { return ({
    type: FETCH_TWO_FACTOR_AUTH_SUCCESS,
    payload: payload,
}); };
export var fetchTwoFactorAuthFailure = function () { return ({
    type: FETCH_TWO_FACTOR_AUTH_FAILURE,
}); };
export var fetchTwoFactorAuth = function () {
    return function (dispatch, getState) {
        dispatch(fetchTwoFactorAuthRequest());
        return api2(dispatch, getState, '/api/v2/users/two_factor_auth', 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (json) {
                    var payload = { required: json.required, backedUp: json.backed_up };
                    dispatch(fetchTwoFactorAuthSuccess(payload));
                    return payload;
                });
            }
            else {
                dispatch(fetchTwoFactorAuthFailure());
                return { error: true };
            }
        });
    };
};
export var disableTwoFactorAuthRequest = function () { return ({
    type: DISABLE_TWO_FACTOR_AUTH_REQUEST,
}); };
export var disableTwoFactorAuthSuccess = function () { return ({
    type: DISABLE_TWO_FACTOR_AUTH_SUCCESS,
}); };
export var disableTwoFactorAuthFailure = function () { return ({
    type: DISABLE_TWO_FACTOR_AUTH_FAILURE,
}); };
export var disableTwoFactorAuth = function () {
    return function (dispatch, getState) {
        dispatch(disableTwoFactorAuthRequest());
        return api2(dispatch, getState, '/api/v2/users/two_factor_auth', 'DELETE').then(function (response) {
            if (response.ok) {
                dispatch(disableTwoFactorAuthSuccess());
                return {};
            }
            else {
                dispatch(disableTwoFactorAuthFailure());
                return { error: true };
            }
        });
    };
};
export var prepareTwoFactorAuthRequest = function () { return ({
    type: PREPARE_TWO_FACTOR_AUTH_REQUEST,
}); };
export var prepareTwoFactorAuthSuccess = function (payload) { return ({
    type: PREPARE_TWO_FACTOR_AUTH_SUCCESS,
    payload: payload,
}); };
export var prepareTwoFactorAuthFailure = function () { return ({
    type: PREPARE_TWO_FACTOR_AUTH_FAILURE,
}); };
export var prepareTwoFactorAuth = function () {
    return function (dispatch, getState) {
        dispatch(prepareTwoFactorAuthRequest());
        return api2(dispatch, getState, '/api/v2/users/two_factor_auth/prepare', 'POST').then(function (response) {
            if (response.ok) {
                return response.json().then(function (json) {
                    var payload = { otpQrCode: json.otp_qr_code };
                    dispatch(prepareTwoFactorAuthSuccess(payload));
                    return payload;
                });
            }
            else {
                dispatch(prepareTwoFactorAuthFailure());
                return { error: true };
            }
        });
    };
};
export var enableTwoFactorAuthRequest = function () { return ({
    type: ENABLE_TWO_FACTOR_AUTH_REQUEST,
}); };
export var enableTwoFactorAuthSuccess = function () { return ({
    type: ENABLE_TWO_FACTOR_AUTH_SUCCESS,
}); };
export var enableTwoFactorAuthFailure = function () { return ({
    type: ENABLE_TWO_FACTOR_AUTH_FAILURE,
}); };
export var enableTwoFactorAuth = function (params) {
    return function (dispatch, getState) {
        dispatch(enableTwoFactorAuthRequest());
        return api2(dispatch, getState, '/api/v2/users/two_factor_auth/enable', 'PATCH', params).then(function (response) {
            if (response.ok) {
                dispatch(enableTwoFactorAuthSuccess());
                return {};
            }
            else {
                dispatch(enableTwoFactorAuthFailure());
                return { error: true };
            }
        });
    };
};
export var backupTwoFactorAuthRequest = function () { return ({
    type: BACKUP_TWO_FACTOR_AUTH_REQUEST,
}); };
export var backupTwoFactorAuthSuccess = function (payload) { return ({
    type: BACKUP_TWO_FACTOR_AUTH_SUCCESS,
    payload: payload,
}); };
export var backupTwoFactorAuthFailure = function () { return ({
    type: BACKUP_TWO_FACTOR_AUTH_FAILURE,
}); };
export var backupTwoFactorAuth = function () {
    return function (dispatch, getState) {
        dispatch(backupTwoFactorAuthRequest());
        return api2(dispatch, getState, '/api/v2/users/two_factor_auth/backup', 'POST').then(function (response) {
            if (response.ok) {
                return response.json().then(function (json) {
                    var payload = { otpBackupCodes: json.otp_backup_codes };
                    dispatch(backupTwoFactorAuthSuccess(payload));
                    return payload;
                });
            }
            else {
                dispatch(backupTwoFactorAuthFailure());
                return { error: true };
            }
        });
    };
};
