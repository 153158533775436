import { __assign } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import Avatar from '@/components/atoms/avatar';
import { PersonalSettingsLayout } from '@/components/personal_settings_layout';
export var Profile = function (_a) {
    var defaultAvatarUrl = _a.avatarUrl, isOpenSubpane = _a.isOpenSubpane, isPC = _a.isPC, isShowAvatar = _a.isShowAvatar, defaultNickname = _a.nickname, signOut = _a.signOut, user = _a.user, onToggleSubpane = _a.onToggleSubpane, onUpdate = _a.onUpdate;
    var _b = React.useState({
        avatarUrl: defaultAvatarUrl,
        avatarFilename: '',
        avatarContentType: '',
    }), state = _b[0], setState = _b[1];
    var _c = React.useState(defaultNickname), nickname = _c[0], setNickname = _c[1];
    var _d = React.useState('save'), saveStatus = _d[0], setSaveStatus = _d[1];
    var handleClickSave = function () {
        var avatarUrl = state.avatarUrl, avatarFilename = state.avatarFilename, avatarContentType = state.avatarContentType;
        var params = {
            user: {
                avatar: avatarUrl.split(',')[1],
                avatar_filename: avatarFilename,
                avatar_content_type: avatarContentType,
                nickname: nickname,
            },
        };
        setSaveStatus('saving');
        onUpdate(params).then(function (user) {
            if (user.error) {
                setSaveStatus('save');
                return;
            }
            setSaveStatus('saved');
            setTimeout(function () {
                setSaveStatus('save');
            }, 2000);
        });
    };
    var handleChangeAvatar = function (e) {
        var file = e.target.files[0];
        if (file !== void 0) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                if (file.type.match(/^image\/(png|jpeg|gif)$/)) {
                    setState(function (prevState) { return (__assign(__assign({}, prevState), { avatarUrl: reader_1.result, avatarFilename: file.name, avatarContentType: file.type })); });
                }
            };
            reader_1.readAsDataURL(file);
        }
    };
    var handleClickAvatar = function () {
        // @ts-ignore
        document.querySelector('#avatarFileInput').click();
    };
    var handleChangeNickname = function (e) {
        setNickname(e.target.value);
    };
    var avatarUrl = state.avatarUrl;
    var isDisabled = saveStatus === 'saving' || saveStatus === 'saved';
    return (<PersonalSettingsLayout active="profile" isOpenSubpane={isOpenSubpane} isPC={isPC} saveButtonForPC={<button className="btn-major-action btn btn-primary" disabled={isDisabled} onClick={handleClickSave}>
          {I18n.t("shared.".concat(saveStatus))}
        </button>} saveButtonForSP={<button className="btn-mobile-header-action btn btn-link" disabled={isDisabled} onClick={handleClickSave}>
          {I18n.t("shared.".concat(saveStatus))}
        </button>} setIsOpenSubpane={onToggleSubpane} signOut={signOut} title={I18n.t('shared.profile')} user={user}>
      <div className="text-center">
        <Avatar className="mb-2" image={avatarUrl} name={nickname} size="lg"/>
        {isShowAvatar && (<div className="text-center mb-3">
            <input accept="image/gif,image/jpeg,image/png" className="hidden-file-input" id="avatarFileInput" type="file" onChange={handleChangeAvatar}/>
            <button className="btn btn-secondary" onClick={handleClickAvatar}>
              <Icon className="mr-1" name="camera" size="md"/>
              {I18n.t('shared.change')}
            </button>
            <p className="text-muted mt-1">{I18n.t('shared.avatarHelp')}</p>
          </div>)}
      </div>

      <div className="col-12">
        <div className="form-group row">
          <label className="col-sm-3 col-12 col-form-label" htmlFor="profileUsername">
            {I18n.t('shared.name')}
          </label>
          <div className="col-xl-7 col-sm-9 col-12">
            <input className="form-control" id="profileUsername" type="text" value={nickname} onChange={handleChangeNickname}/>
            <p className="text-muted small mt-2 mb-0">
              {I18n.t('shared.userId')} {user.id}
            </p>
          </div>
        </div>
      </div>
    </PersonalSettingsLayout>);
};
