import user from './user';
import token from './token';
import recentTasks from '@/modules/recent_tasks';
import workingUsersCount from './working_users_count';
import currentEntry from '@/modules/current_entry';
import apiBase from './api_base';
import clearUser from './clear_user';
import memberSummaries from './member_summaries';
import timeZones from './time_zones';
import teams from '@/modules/teams';
import exportableTeams from '@/modules/exportable_teams';
import taskSearch from '@/modules/task_search';
import myTask from '@/modules/my_task';
import teamManagement from '@/modules/team_management';
import notification from '@/modules/notification';
import webHooks from '@/modules/web_hooks';
import taskManagement from '@/modules/task_management';
import report from '@/modules/report';
import categories from '@/modules/categories';
import quickTask from '@/modules/quick_task';
import calendar from '@/modules/calendar';
import dailyActivities from '@/modules/daily_activites';
import fromPageTitle from '@/modules/from_page_title';
import reportRecipients from '@/modules/report_recipients';
import filters from '@/modules/filters';
import timeEntries from '@/modules/time_entries';
import memberships from '@/modules/memberships';
import payment from '@/modules/payment';
import modal from '@/modules/modal';
import display from '@/modules/display';
import employs from '@/modules/employs';
import assets from '@/modules/assets';
import exports from '@/modules/exports';
import tasks from '@/modules/tasks';
import lastTimeEntries from '@/modules/last_time_entries';
import personalSettings from '@/modules/personal_settings';
import identities from '@/modules/identities';
import currentUser from '@/modules/current_user';
import reportableUsers from '@/modules/reportable_users';
import twoFactorAuth from '@/modules/two_factor_auth';
import systemNotifications from '@/modules/system_notifications';
import categoryTaskTrees from '@/modules/category_task_trees';
import bulkCreateTasks from '@/modules/bulk_create_tasks';
import calendarIntegrationSettings from '@/modules/calendar_integration_settings';
import calendarIntegrationFilters from '@/modules/calendar_integration_filters';
import calendarIntegrationLists from '@/modules/calendar_integration_lists';
import calendarIntegrationEvents from '@/modules/calendar_integration_events';
import categoryTaskStructuresExports from '@/modules/category_task_structures_exports';
import categoryTaskStructuresImports from '@/modules/category_task_structures_imports';
import reminderEmailsSettings from '@/modules/reminder_emails_settings';
import customFieldsMasters from '@/modules/custom_fields_masters';
import customFieldsValues from '@/modules/custom_fields_values';
import timeEntrySuggestions from '@/modules/time_entry_suggestions';
import workspaces from '@/modules/workspaces';
import workspacesInvitations from '@/modules/workspaces_invitations';
import workspacesMemberships from '@/modules/workspaces_memberships';
import workspacesEmploys from '@/modules/workspaces_employs';
import workspacesCharges from '@/modules/workspaces_charges';
import workspacesRealtimeReports from '@/modules/workspaces_realtime_reports';
import workspacesLongTermReports from '@/modules/workspaces_long_term_reports';
import workspacesReportBookmarks from '@/modules/workspaces_report_bookmarks';
import undo from '@/modules/undo';
import overlapTimeEntries from '@/modules/overlap_time_entries';
import recentCategories from '@/modules/recent_categories';
import workspacesCalendarIntegrationFilters from '@/modules/workspaces_calendar_integration_filters';
import workspacesCalendarIntegrationSettings from '@/modules/workspaces_calendar_integration_settings';
export default {
    user: user,
    token: token,
    recentTasks: recentTasks,
    workingUsersCount: workingUsersCount,
    currentEntry: currentEntry,
    apiBase: apiBase,
    clearUser: clearUser,
    memberSummaries: memberSummaries,
    timeZones: timeZones,
    teams: teams,
    exportableTeams: exportableTeams,
    taskSearch: taskSearch,
    myTask: myTask,
    teamManagement: teamManagement,
    notification: notification,
    webHooks: webHooks,
    taskManagement: taskManagement,
    report: report,
    categories: categories,
    quickTask: quickTask,
    calendar: calendar,
    dailyActivities: dailyActivities,
    fromPageTitle: fromPageTitle,
    filters: filters,
    isMobile: function (state) {
        if (state === void 0) { state = false; }
        return state;
    },
    isChromeExtension: function (state) {
        if (state === void 0) { state = false; }
        return state;
    },
    isApp: function (state) {
        if (state === void 0) { state = false; }
        return state;
    },
    isPC: function (state) {
        if (state === void 0) { state = false; }
        return state;
    },
    isDesktop: function (state) {
        if (state === void 0) { state = false; }
        return state;
    },
    openURL: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    getPage: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    saveFromPageTitle: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    loadFromPageTitle: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    saveReportHistory: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    loadReportHistory: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    saveReportSelectedUsers: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    loadReportSelectedUsers: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    saveMyTaskViewMode: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    loadMyTaskViewMode: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    reportRecipients: reportRecipients,
    timeEntries: timeEntries,
    memberships: memberships,
    payment: payment,
    saveShownPaymentAlertTeamIds: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    loadShownPaymentAlertTeamIds: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    modal: modal,
    display: display,
    saveTeamId: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    loadTeamId: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    saveExportTimeFormat: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    loadExportTimeFormat: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    saveExportCharset: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    loadExportCharset: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    saveExportDataType: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    loadExportDataType: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    employs: employs,
    assets: assets,
    exports: exports,
    tasks: tasks,
    lastTimeEntries: lastTimeEntries,
    personalSettings: personalSettings,
    identities: identities,
    currentUser: currentUser,
    reportableUsers: reportableUsers,
    appVersion: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    twoFactorAuth: twoFactorAuth,
    systemNotifications: systemNotifications,
    reloadPage: function (state) {
        if (state === void 0) { state = null; }
        return state;
    },
    categoryTaskTrees: categoryTaskTrees,
    bulkCreateTasks: bulkCreateTasks,
    calendarIntegrationSettings: calendarIntegrationSettings,
    calendarIntegrationFilters: calendarIntegrationFilters,
    calendarIntegrationLists: calendarIntegrationLists,
    calendarIntegrationEvents: calendarIntegrationEvents,
    categoryTaskStructuresExports: categoryTaskStructuresExports,
    categoryTaskStructuresImports: categoryTaskStructuresImports,
    reminderEmailsSettings: reminderEmailsSettings,
    customFieldsMasters: customFieldsMasters,
    customFieldsValues: customFieldsValues,
    timeEntrySuggestions: timeEntrySuggestions,
    workspaces: workspaces,
    workspacesInvitations: workspacesInvitations,
    workspacesMemberships: workspacesMemberships,
    workspacesEmploys: workspacesEmploys,
    workspacesCharges: workspacesCharges,
    workspacesRealtimeReports: workspacesRealtimeReports,
    workspacesLongTermReports: workspacesLongTermReports,
    workspacesReportBookmarks: workspacesReportBookmarks,
    undo: undo,
    overlapTimeEntries: overlapTimeEntries,
    recentCategories: recentCategories,
    workspacesCalendarIntegrationFilters: workspacesCalendarIntegrationFilters,
    workspacesCalendarIntegrationSettings: workspacesCalendarIntegrationSettings,
};
