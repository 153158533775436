import React from 'react';
import Timeline from '@/components/timeline';
import { CalendarDateHourCol } from '@/components/calendar_date_hour_col';
import { CALENDAR_UNIT_MINUTES } from '@/modules/calendar';
import { filterCalendarItems } from '@/utils/calendar_helper';
var DIVISION_HEIGHT_XS = 36;
var DIVISION_HEIGHT_BASE = 24;
var divisionHeight = function (isMobile, isApp, divisionHeightRate, calendarUnitMinutes) {
    var adjustRate = calendarUnitMinutes / CALENDAR_UNIT_MINUTES[0];
    var heightForCurrentDevice = isMobile || isApp
        ? DIVISION_HEIGHT_XS
        : divisionHeightRate * DIVISION_HEIGHT_BASE;
    return heightForCurrentDevice * adjustRate;
};
export var Schedule = function (_a) {
    var isMobile = _a.isMobile, isApp = _a.isApp, isChromeExtension = _a.isChromeExtension, divisionHeightRate = _a.divisionHeightRate, initialScrollTo = _a.initialScrollTo, currentCategory = _a.currentCategory, items = _a.items, selectedDay = _a.selectedDay, toggleTaskForm = _a.toggleTaskForm, activeTaskForm = _a.activeTaskForm, user = _a.user, _b = _a.readOnly, readOnly = _b === void 0 ? false : _b, _c = _a.showsCalendarEvents, showsCalendarEvents = _c === void 0 ? false : _c, calendarIntegrationEvents = _a.calendarIntegrationEvents, teams = _a.teams, taskSearch = _a.taskSearch, isShowCategoryOnWeeklyCalendar = _a.isShowCategoryOnWeeklyCalendar, calendarUnitMinutes = _a.calendarUnitMinutes, view = _a.view, overlaps = _a.overlaps;
    var filteredCalendarItems = filterCalendarItems(items, view, selectedDay);
    // FIXME: 画面のちらつきを抑えるために、表示するデータが適切ではない場合は描画しない
    var canRender = view === 'daily'
        ? filteredCalendarItems.length > 0
        : filteredCalendarItems.length > 6;
    return (<div key={divisionHeightRate} className={"d-flex ".concat(view)}>
      <CalendarDateHourCol calendarUnitMinutes={calendarUnitMinutes} divisionHeight={divisionHeight(isMobile, isApp, divisionHeightRate, calendarUnitMinutes)}/>
      <div className="d-flex flex-grow-1 rnd-bounds overflow-hidden">
        {canRender &&
            filteredCalendarItems.map(function (_a) {
                var date = _a.date, time_entries = _a.time_entries;
                return (<Timeline key={date} activeTaskForm={activeTaskForm} calendarIntegrationEvents={calendarIntegrationEvents} calendarUnitMinutes={calendarUnitMinutes} currentCategory={currentCategory} currentUser={user} date={date} divisionHeight={divisionHeight(isMobile, isApp, divisionHeightRate, calendarUnitMinutes)} entries={time_entries} initialScrollTo={initialScrollTo} isApp={isApp} isChromeExtension={isChromeExtension} isMobile={isMobile} isShowCategoryOnWeeklyCalendar={isShowCategoryOnWeeklyCalendar} overlaps={overlaps} readOnly={readOnly} selectedDay={selectedDay} showsCalendarEvents={showsCalendarEvents} subdivisions={60 / calendarUnitMinutes} taskSearch={taskSearch} teams={teams} toggleTaskForm={toggleTaskForm} view={view}/>);
            })}
      </div>
    </div>);
};
