import React from 'react';
import I18n from '@/constants/i18n';
import { receivers } from '@/types';
import CustomRadio from '@/components/atoms/custom_radio';
export var ReminderReceiverField = function (_a) {
    var index = _a.index, reminderEmailsSetting = _a.reminderEmailsSetting, onChangeReminderEmailsSetting = _a.onChangeReminderEmailsSetting;
    return (<div className="row mt-3">
      <div className="col-3">{I18n.t('shared.reminderReceiver')}</div>
      <div className="col d-flex">
        {receivers.map(function (receiver) { return (<div key={receiver} className="mr-2">
            <CustomRadio checked={receiver === reminderEmailsSetting.receivers} value={receiver} onChange={function () {
                return onChangeReminderEmailsSetting(index, { receivers: receiver });
            }}>
              {I18n.t("shared.reminderReceivers.".concat(receiver))}
            </CustomRadio>
          </div>); })}
      </div>
    </div>);
};
