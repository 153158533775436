import React from 'react';
import Avatar from '@/components/atoms/avatar';
import UserEmployListContainer from '@/containers/members/user_employ_list_container';
import MembershipRoleDropdownContainer from '@/containers/members/membership_role_dropdown_container';
var Member = function (props) {
    var membership = props.membership, team = props.team;
    var user = membership.user;
    return (<tr>
      <td>
        <Avatar className="mr-2" image={user.avatar_url} name={user.nickname} size="md"/>
        {user.nickname}
      </td>
      {team.can_manage_employ && (<td>
          <UserEmployListContainer membership={membership} team={team}/>
        </td>)}
      {team.can_manage_employ && (<td>
          <MembershipRoleDropdownContainer membership={membership} team={team}/>
        </td>)}
    </tr>);
};
export default Member;
