import React from 'react';
import { SortableCategoryList } from '.';
import { ClearableSearchField } from '../clearable_search_field';
import I18n from '@/constants/i18n';
import { ignoreArchivedCategories } from '@/modules/categories';
import { buildCategoriesWithAncestorAndDescendantTitles } from '@/modules/categories/helpers';
import LoadingSpinner from '@/components/atoms/loading_spinner';
export var SortableCategoryListWithSearch = function (props) {
    var categories = props.categories, isShowArchived = props.isShowArchived, setNotificationMessage = props.setNotificationMessage, cancelDrag = props.cancelDrag;
    var _a = React.useState(''), searchText = _a[0], setSearchText = _a[1];
    var filteredCategories = React.useMemo(function () { return (isShowArchived ? categories : ignoreArchivedCategories(categories)); }, [categories, isShowArchived]);
    var categoriesWithAncestorAndDescendantTitles = React.useMemo(function () { return buildCategoriesWithAncestorAndDescendantTitles(filteredCategories); }, [filteredCategories]).categoriesWithAncestorAndDescendantTitles;
    var rootCategories = React.useMemo(function () {
        return categoriesWithAncestorAndDescendantTitles.filter(function (category) { return !category.parent_id; });
    }, [categoriesWithAncestorAndDescendantTitles]);
    var showAlertForSortWithSearch = function () {
        if (!searchText) {
            return;
        }
        setNotificationMessage('danger', I18n.t('shared.cannotReorderWhileSearching'));
        return true;
    };
    return (<>
      <ClearableSearchField className="mb-2" placeholderKey="categoryListSearchPlaceholder" searchText={searchText} size="md" onChangeSearchText={setSearchText}/>
      {rootCategories.length > 0 ? (<SortableCategoryList 
        // NOTE: SortableCategoryListをラップするコンポーネントのためspreadで全て渡す
        //       SortableCategoryListPropsを考慮した型定義になっているので漏れには繋がらない
        {...props} // eslint-disable-line react/jsx-props-no-spreading
         categories={categoriesWithAncestorAndDescendantTitles} handleDragEnd={function (event) {
                if (showAlertForSortWithSearch()) {
                    cancelDrag();
                    return;
                }
                props.handleDragEnd(event);
            }} originCategories={rootCategories} searchText={searchText} onMoveHigherCategoryPosition={function (id) {
                if (showAlertForSortWithSearch()) {
                    return;
                }
                props.onMoveHigherCategoryPosition(id);
            }} onMoveLowerCategoryPosition={function (id) {
                if (showAlertForSortWithSearch()) {
                    return;
                }
                props.onMoveLowerCategoryPosition(id);
            }}/>) : (<div className="text-center mt-3">
          <LoadingSpinner size="md"/>
        </div>)}
    </>);
};
