import { __assign, __awaiter, __generator } from "tslib";
import React from 'react';
import { useLocation } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import WorkspacesEmployList from '@/components/workspaces_employ_list';
import WorkspacesEmployListModal from '@/components/workspaces_employ_list/workspaces_employ_list_modal';
import DeleteMembershipModal from '@/components/members/delete_membership_modal';
import { TeamEmploysModal } from '@/components/workspaces_employ_list/team_employs_modal';
import { TeamMembershipsModal } from '@/components/workspaces_employ_list/team_memberships_modal';
import { imageSource } from '@/utils/image_helper';
import { CustomSwitch } from '@/components/custom_switch';
export var WorkspacesMemberListItem = function (_a) {
    var membership = _a.membership, workspacesEmploys = _a.workspacesEmploys, workspacesMemberships = _a.workspacesMemberships, teams = _a.teams, employsByTeamId = _a.employsByTeamId, membershipsByTeamId = _a.membershipsByTeamId, createWorkspacesEmploy = _a.createWorkspacesEmploy, updateWorkspacesEmploy = _a.updateWorkspacesEmploy, deleteWorkspacesEmploy = _a.deleteWorkspacesEmploy, updateWorkspacesMembership = _a.updateWorkspacesMembership, deleteWorkspacesMembership = _a.deleteWorkspacesMembership, toggleTeamMenu = _a.toggleTeamMenu, onRemoveDangerNotificationMessage = _a.onRemoveDangerNotificationMessage;
    var queryUserId = Number(new URLSearchParams(useLocation().search).get('user_id'));
    var _b = React.useState(membership.user_id === queryUserId), isOpenEmployModal = _b[0], setIsOpenEmployModal = _b[1];
    var _c = React.useState(false), isOpenDeleteModal = _c[0], setIsOpenDeleteModal = _c[1];
    var _d = React.useState(false), isDeleting = _d[0], setIsDeleting = _d[1];
    var _e = React.useState(membership.role), role = _e[0], setRole = _e[1];
    var deleteButtonRef = React.useRef(null);
    var onCreateEmploy = function (_employ, params) { return __awaiter(void 0, void 0, void 0, function () {
        var item;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createWorkspacesEmploy(params)];
                case 1:
                    item = (_a.sent()).item;
                    return [2 /*return*/, __assign(__assign({}, item), { user: { id: item.user_id } })];
            }
        });
    }); };
    var onUpdateEmploy = function (employ, params) { return __awaiter(void 0, void 0, void 0, function () {
        var item;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateWorkspacesEmploy(employ.id, params)];
                case 1:
                    item = (_a.sent()).item;
                    return [2 /*return*/, __assign(__assign({}, item), { user: { id: item.user_id } })];
            }
        });
    }); };
    var onDeleteEmploy = function (employ) {
        return deleteWorkspacesEmploy(employ.id);
    };
    var canUpdateRole = membership.role !== 'owner' ||
        workspacesMemberships.filter(function (_a) {
            var role = _a.role;
            return role === 'owner';
        }).length > 1;
    var sortedEmploys = React.useMemo(function () {
        return workspacesEmploys.sort(function (a, b) {
            return a.started_at.localeCompare(b.started_at);
        });
    }, [workspacesEmploys]);
    var handleCloseDeleteMembershipModal = React.useCallback(function () {
        onRemoveDangerNotificationMessage();
        setIsOpenDeleteModal(false);
    }, [onRemoveDangerNotificationMessage]);
    var handleRemoveMembership = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsDeleting(true);
                    return [4 /*yield*/, deleteWorkspacesMembership(membership.id)];
                case 1:
                    response = _a.sent();
                    setIsDeleting(false);
                    if (response.error) {
                        return [2 /*return*/];
                    }
                    setIsOpenDeleteModal(false);
                    return [2 /*return*/];
            }
        });
    }); }, [deleteWorkspacesMembership, membership.id]);
    var user = {
        id: membership.user_id,
        nickname: membership.user_nickname,
        avatar_url: membership.user_avatar_url,
    };
    return (<tr>
      <td>
        <div className="d-flex flex-column align-items-between">
          <div>
            <Avatar className="mr-2 border-0" image={user.avatar_url}/>
            {user.nickname}
          </div>
          <TeamMembershipsModal membershipByTeamId={membershipsByTeamId} teams={teams} toggleTeamMenu={toggleTeamMenu} user={user}/>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <WorkspacesEmployList employs={sortedEmploys}/>
            <TeamEmploysModal employsByTeamId={employsByTeamId} membershipByTeamId={membershipsByTeamId} teams={teams} toggleTeamMenu={toggleTeamMenu} user={user}/>
          </div>
          <button className="btn btn-secondary btn-sm" onClick={function () { return setIsOpenEmployModal(true); }}>
            {I18n.t('shared.change')}
          </button>
        </div>
        <WorkspacesEmployListModal disabled={false} employs={sortedEmploys.map(function (employ) { return (__assign(__assign({}, employ), { user: { id: employ.user_id } })); })} isOpen={isOpenEmployModal} membership={__assign(__assign({}, membership), { user: user, team: {} })} toggle={function () { return setIsOpenEmployModal(false); }} onCreate={onCreateEmploy} onDelete={onDeleteEmploy} onUpdate={onUpdateEmploy}/>
      </td>
      <td>
        <div className="d-flex">
          <CustomSwitch checked={role === 'owner'} disabled={!canUpdateRole} onChange={function () {
            var newRole = role === 'owner' ? 'member' : 'owner';
            setRole(newRole);
            updateWorkspacesMembership(membership.id, { role: newRole });
        }}/>
          <span className={role === 'owner' ? '' : 'text-muted'}>
            {I18n.t('shared.roles.owner')}
          </span>
        </div>
      </td>
      <td className="p-0 text-center">
        {canUpdateRole && (<>
            <button ref={deleteButtonRef} className="btn btn-link p-0" onClick={function () { return setIsOpenDeleteModal(true); }}>
              <img src={imageSource('remove.svg')}/>
            </button>
            <UncontrolledTooltip fade={false} target={deleteButtonRef}>
              {I18n.t('shared.leaveWorkspace')}
            </UncontrolledTooltip>
          </>)}
        <DeleteMembershipModal isDeleting={isDeleting} isOpen={isOpenDeleteModal} membership={__assign(__assign({}, membership), { user: user })} target={I18n.t('shared.workspace')} toggle={handleCloseDeleteMembershipModal} onDelete={handleRemoveMembership}/>
      </td>
    </tr>);
};
