import React from 'react';
import { useDispatch } from 'react-redux';
import { WorkspacesSettingsLayout } from '@/components/workspaces_settings_layout';
import I18n from '@/constants/i18n';
import { WorkspacesTeamListItem } from '@/components/workspaces_team_list_item';
import { CreateTeamFormModal } from '@/components/create_team_form_modal';
import variants from '@/constants/variants';
import { removeDangerNotificationMessage } from '@/modules/notification';
export var WorkspacesTeamsForm = function (props) {
    var workspace = props.workspace, membershipsByTeamId = props.membershipsByTeamId, teams = props.teams, createTeam = props.createTeam, setNotificationMessage = props.setNotificationMessage, toggleTeamMenu = props.toggleTeamMenu, _a = props.openURL, openURL = _a === void 0 ? variants.openURL : _a;
    var _b = React.useState(false), isOpenNewTeamModal = _b[0], setIsOpenNewTeamModal = _b[1];
    var onClickNewTeam = function () {
        setIsOpenNewTeamModal(true);
    };
    var handleClickLink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    var dispatch = useDispatch();
    var handleCloseModal = function () {
        dispatch(removeDangerNotificationMessage());
        setIsOpenNewTeamModal(false);
    };
    var isPersonal = workspace.plan_type === 'personal';
    var canAdd = !isPersonal && teams.length < workspace.max_team_count;
    return (<WorkspacesSettingsLayout active="teams" saveButtonForPC={<>
          {canAdd && (<button className="btn-major-action btn btn-primary" onClick={onClickNewTeam}>
              {I18n.t('shared.newTeam')}
            </button>)}
        </>} saveButtonForSP={<>
          {canAdd && (<button className="btn-mobile-header-action btn btn-link" onClick={onClickNewTeam}>
              {I18n.t('shared.newTeam')}
            </button>)}
        </>} title={"".concat(I18n.t('shared.workspace'), " ").concat(I18n.t('shared.teamManagement'))}>
      {isPersonal && (<>
          <div>{I18n.t('shared.workspacePersonalNote1')}</div>
          <div className="mb-3">
            {I18n.t('shared.workspacePersonalNote2')}
            <a className="mx-1" href="https://timecrowd.net/contacts/new" onClick={handleClickLink}>
              {I18n.t('shared.contactSupport')}
            </a>
            {I18n.t('shared.workspacePersonalNote3')}
          </div>
        </>)}
      {teams.length < 1 ? (I18n.t('shared.noTeamFound')) : (<>
          {!isPersonal && (<p>
              <span className="mr-3">
                {I18n.t('shared.numberOfTeams')}: {teams.length} /{' '}
                {workspace.max_team_count}
              </span>
              {!canAdd && (<>
                  {I18n.t('shared.maxTeamCountNotice.prefix')}
                  <a className="mx-1" href="https://timecrowd.net/contacts/new" onClick={handleClickLink}>
                    {I18n.t('shared.maxTeamCountNotice.link')}
                  </a>
                  {I18n.t('shared.maxTeamCountNotice.suffix')}
                </>)}
            </p>)}
          {teams.map(function (team) {
                var _a;
                return (<WorkspacesTeamListItem key={team.id} isPersonal={isPersonal} memberships={(_a = membershipsByTeamId[team.id]) !== null && _a !== void 0 ? _a : []} team={team} toggleTeamMenu={toggleTeamMenu}/>);
            })}
        </>)}
      <CreateTeamFormModal createTeam={createTeam} isOpen={isOpenNewTeamModal} setNotificationMessage={setNotificationMessage} onClose={handleCloseModal}/>
    </WorkspacesSettingsLayout>);
};
