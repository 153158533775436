import React from 'react';
import { useNavigate } from 'react-router-dom';
import I18n from '@/constants/i18n';
import variants from '@/constants/variants';
import { workspacePath } from '@/utils/workspace_helper';
function UnpaidBadge(_a) {
    var team = _a.team, _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.style, style = _c === void 0 ? {} : _c, _d = _a.isPC, isPC = _d === void 0 ? variants.isPC : _d, _e = _a.withLink, withLink = _e === void 0 ? true : _e;
    var color = 'danger';
    var navigate = useNavigate();
    var onClickBadge = function (e) {
        e.preventDefault();
        navigate(e.target.pathname);
    };
    if (!team.payment_required) {
        return null;
    }
    if (withLink && team.can_manage && isPC) {
        return (<a className={"badge badge-".concat(color, " ").concat(className)} href={workspacePath("/teams/".concat(team.id, "/payment"))} style={style} onClick={onClickBadge}>
        {I18n.t('shared.unpaid')}
      </a>);
    }
    else {
        return (<span className={"badge badge-".concat(color, " ").concat(className)} style={style}>
        {I18n.t('shared.unpaid')}
      </span>);
    }
}
export default UnpaidBadge;
