import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
import variants from '@/constants/variants';
var PersonalSettingsSubpane = function (_a) {
    var active = _a.active, otpAllowed = _a.otpAllowed, hasPassword = _a.hasPassword, setIsOpenSubpane = _a.setIsOpenSubpane, signOut = _a.signOut, _b = _a.openURL, openURL = _b === void 0 ? variants.openURL : _b, _c = _a.apiBase, apiBase = _c === void 0 ? variants.apiBase : _c;
    var navigate = useNavigate();
    var onClickNavLink = function (e) {
        e.preventDefault();
        setIsOpenSubpane(false);
        navigate(e.target.pathname);
    };
    return (<ul className="flex-column nav">
      <li className="nav-item">
        <a className={"".concat(active === 'profile' ? 'active ' : '', "nav-link")} href="/app/personal_settings/profile" onClick={onClickNavLink}>
          <Icon className="mr-2" color="info" name="user"/>
          {I18n.t('shared.profile')}
        </a>
      </li>
      <li className="nav-item">
        <a className={"".concat(active === 'account' ? 'active ' : '', "nav-link")} href="/app/personal_settings/account" onClick={onClickNavLink}>
          <Icon className="mr-2" color="info" name="cloud"/>
          {I18n.t('shared.account')}
        </a>
      </li>
      <li className="nav-item">
        <a className={"".concat(active === 'report_mail' ? 'active ' : '', "nav-link")} href="/app/personal_settings/report_mail" onClick={onClickNavLink}>
          <Icon className="mr-2" color="info" name="mail"/>
          {I18n.t('personalSetting.reportMail')}
        </a>
      </li>
      {otpAllowed && (<li className="nav-item">
          <a className={"".concat(active === 'two_factor_auth' ? 'active ' : '', "nav-link")} href="/app/personal_settings/two_factor_auth" onClick={onClickNavLink}>
            <Icon className="mr-2" color="info" name="lock"/>
            {I18n.t('personalSetting.twoFactorAuth')}
          </a>
        </li>)}
      {!otpAllowed && (<li className="nav-item">
          <a className={"".concat(active === 'calendar' ? 'active ' : '', "nav-link")} href="/app/personal_settings/calendar_integration" onClick={onClickNavLink}>
            <Icon className="mr-2" color="info" name="network-3"/>
            {I18n.t('shared.calendarIntegrations')}
          </a>
        </li>)}
      {hasPassword && (<li className="nav-item">
          <a className="nav-link" href={"".concat(apiBase, "/users/edit")} onClick={function (e) {
                e.preventDefault();
                openURL("".concat(apiBase, "/users/edit"));
            }}>
            <Icon className="mr-2" name="gear"/>
            {I18n.t('other.changePassword')}
          </a>
        </li>)}
      <li className="nav-item">
        <span className="nav-link" onClick={signOut}>
          <Icon className="mr-2" name="power"/>
          {I18n.t('other.signOut')}
        </span>
      </li>
    </ul>);
};
export default PersonalSettingsSubpane;
