import React from 'react';
import MediaQuery from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@/hooks/use_query';
import { BREAKPOINT_MD } from '@/constants/styles';
import I18n from '@/constants/i18n';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import Icon from '@/components/atoms/icon';
import MobileHeader from '@/components/atoms/mobile_header';
import MobilePage from '@/components/molecules/mobile_page';
import Subpane from '@/components/molecules/subpane';
import MainMenuContainer from '@/containers/main_menu_container';
import TaskEditor from './task_editor';
import TaskTimeEntryListContainer from '@/containers/task_edit/task_time_entry_list_container';
import { DeleteTaskModal } from './delete_task_modal';
import UserList from './user_list';
import EditTaskFormContainer from '@/containers/task_edit/edit_task_form_container';
import OmitLabel from '@/components/atoms/omit_label';
import LoadingPage from '@/components/pages/loading_page';
import NotFound from '@/components/pages/not_found';
import { workspacePath } from '@/utils/workspace_helper';
import useCustomFields from '@/hooks/use_custom_fields';
import { workspaceTeams as filterWorkspaceTeams } from '@/utils/workspace_helper';
var TaskEdit = function (props) {
    var tasks = props.tasks, onRefresh = props.onRefresh, fetchCategories = props.fetchCategories, from = props.from, user = props.user, isLoading = props.isLoading, isFetched = props.isFetched, categories = props.categories, teams = props.teams;
    var _a = React.useState(false), isOpenEdit = _a[0], setIsOpenEdit = _a[1];
    var _b = React.useState(false), isOpenSubpane = _b[0], setIsOpenSubpane = _b[1];
    var params = useParams();
    var id = params.id;
    var navigate = useNavigate();
    var query = useQuery();
    var user_id = query.user_id;
    React.useEffect(function () {
        if (!teams.isFetched) {
            return;
        }
        fetchCategories();
    }, [fetchCategories, teams.isFetched]);
    React.useEffect(function () {
        onRefresh(id, query);
    }, [id, query, onRefresh]);
    React.useEffect(function () {
        if (tasks.length &&
            user_id &&
            !tasks[0].users.some(function (u) { return u.id === Number(user_id); })) {
            navigate(workspacePath("/".concat(from, "/").concat(id, "/edit")));
        }
    }, [from, id, navigate, tasks, user_id]);
    var workspaceTeams = React.useMemo(function () { return filterWorkspaceTeams(teams.items); }, [teams.items]);
    useCustomFields(workspaceTeams);
    var onClickBack = function () {
        if (window.history.length > 1) {
            navigate(-1);
        }
        else {
            navigate('/app');
        }
    };
    var onClickUser = function (user) {
        toggleSubpane(false);
        if (user) {
            navigate(workspacePath("/".concat(from, "/").concat(id, "/edit?user_id=").concat(user.id)));
        }
        else {
            navigate(workspacePath("/".concat(from, "/").concat(id, "/edit")));
        }
    };
    var toggleEdit = function () {
        setIsOpenEdit(!isOpenEdit);
    };
    var toggleSubpane = function (isOpen) {
        if (isOpen === void 0) {
            setIsOpenSubpane(!isOpenSubpane);
        }
        else {
            setIsOpenSubpane(isOpen);
        }
    };
    if (isLoading && !isFetched) {
        return <LoadingPage />;
    }
    if (!tasks.length) {
        return <NotFound />;
    }
    var task = tasks[0];
    return (<div className="content-wrap">
      <MediaQuery minWidth={BREAKPOINT_MD}>
        <MainMenuContainer />
      </MediaQuery>
      <div className="content-main">
        <MediaQuery className="h-100" minWidth={BREAKPOINT_MD}>
          <div className="toolbar" style={{ height: '8.4rem' }}>
            <div className="row no-gutters">
              <div className="col-12">
                <TaskEditor from={from} task={task} onClickBack={onClickBack}/>
              </div>
            </div>
          </div>
          <div className="row no-gutters with-toolbar animated" style={{ paddingTop: '8.4rem' }}>
            <div className="col-md-9 col-12">
              <TaskTimeEntryListContainer currentUser={user} params={params} query={query} task={task}/>
            </div>
            <Subpane className="col-md-3 col-12">
              <UserList query={query} users={task.users} onClick={onClickUser}/>
            </Subpane>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={BREAKPOINT_MD}>
          <MobilePage style={{
            position: 'relative',
            top: 0,
            minHeight: '100vh',
            paddingTop: 0,
            marginTop: '-6rem',
        }}>
            <MobileHeader className="task-edit-header">
              <button className="btn-mobile-header-action btn-mobile-header-action-left btn btn-link" onClick={onClickBack}>
                <Icon name="cross"/>
              </button>
              <span className="mobile-header-title task-title">
                <CategoryCircleIcon color={task.category.color}/>
                <OmitLabel maxWidth="100%">{task.title}</OmitLabel>
              </span>
              <button className="btn-mobile-header-action btn-mobile-header-action-text btn-mobile-header-action-primary btn btn-link" onClick={toggleEdit}>
                {I18n.t('shared.edit')}
              </button>
            </MobileHeader>
            <div className="row no-gutters animated h-100" style={{ marginTop: '6rem' }}>
              <div className="col-md-9 col-12">
                <button className="btn btn-toggler subpane-toggler hidden-sm-up" style={{
            top: '7.5rem',
            zIndex: 2,
        }} onClick={function () { return toggleSubpane(true); }}>
                  <Icon name="chevron-down" size="lg"/>
                </button>
                <TaskTimeEntryListContainer currentUser={user} params={params} query={query} task={task}/>
              </div>
              <Subpane className="col-md-3 col-12" isOpen={isOpenSubpane} style={{ zIndex: 1000 }} toggle={toggleSubpane}>
                <UserList query={query} users={task.users} onClick={onClickUser}/>
              </Subpane>
            </div>
            {isOpenEdit && (<EditTaskFormContainer category={task.category} from={from} task={task} team={task.team} toggle={toggleEdit}/>)}
          </MobilePage>
        </MediaQuery>
      </div>
      <DeleteTaskModal categories={categories} from={from} task={task} team={task.team}/>
    </div>);
};
export default TaskEdit;
