import React from 'react';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import Icon from '@/components/atoms/icon';
export default function StatusBadge(_a) {
    var status = _a.status;
    switch (status) {
        case 'success':
            return (<span className="badge-status zoomIn animated badge badge-success d-flex justify-content-center">
          <Icon name="checkmark"/>
        </span>);
        case 'initial':
        case 'in_progress':
            return <LoadingSpinner size="md"/>;
        case 'failure':
            return (<span className="badge-status zoomIn animated badge badge-danger d-flex justify-content-center">
          <Icon name="cross"/>
        </span>);
        case 'cancel':
            return (<span className="badge-status zoomIn animated badge badge-info d-flex justify-content-center">
          <Icon name="minus"/>
        </span>);
        default:
            return null;
    }
}
