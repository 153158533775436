import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import TaskFormCalendarEventContainer from '@/containers/task_form_calendar_event_container';
import Backdrop from '@/components/atoms/backdrop';
export var CalendarEventItem = function (_a) {
    var calendarIntegrationEvent = _a.calendarIntegrationEvent, top = _a.top, height = _a.height, width = _a.width, left = _a.left, index = _a.index, toggleTaskForm = _a.toggleTaskForm;
    var _b = React.useState(false), isOpenModal = _b[0], setIsOpenModal = _b[1];
    var onClickItem = React.useCallback(function () {
        toggleTaskForm(index);
        setIsOpenModal(true);
    }, [index, toggleTaskForm]);
    var handleCloseModal = React.useCallback(function () {
        toggleTaskForm(-1);
        setIsOpenModal(false);
    }, [toggleTaskForm]);
    React.useEffect(function () {
        // NOTE: reduxとの相性でReactstrapのModalがエラーになるため自前で管理
        if (isOpenModal) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
    }, [isOpenModal]);
    return (<>
      <div className="timeline-item timeline-entry calendar-event-item" style={{ top: top, height: height, width: width, left: left }} onClick={onClickItem}>
        <div className="h-100">
          <div className="task task-inline timeline-task-inline">
            <div className="task-block">
              <span className="task-title">
                {calendarIntegrationEvent.title}
              </span>
            </div>
          </div>
        </div>
      </div>
      {isOpenModal && (<>
          <Backdrop />
          <div className="modal fade show d-block" onClick={function (e) {
                e.stopPropagation();
                handleCloseModal();
            }}>
            <div className="modal-dialog modal-lg" onClick={function (e) { return e.stopPropagation(); }}>
              <TaskFormCalendarEventContainer hidesFromPageToggle calendarIntegrationEvent={calendarIntegrationEvent} className="bg-white rounded" date={moment(calendarIntegrationEvent.starts_at).format('YYYY-MM-DD')} fromPageTitle={false} isFocus={false} linkUrl={calendarIntegrationEvent.personalized_html_link} mode="calendar_event" tab="schedule" task={{
                title: calendarIntegrationEvent.title,
            }} timeEntry={{
                started_at: moment(calendarIntegrationEvent.starts_at),
                stopped_at: moment(calendarIntegrationEvent.ends_at),
            }} onClose={handleCloseModal}/>
            </div>
          </div>
        </>)}
    </>);
};
