import { __assign } from "tslib";
import omit from 'lodash/omit';
import variants from './constants/variants';
import { initialState as CalendarInitialState } from '@/modules/calendar';
var isChromeExtension = variants.isChromeExtension, isApp = variants.isApp, isMobile = variants.isMobile;
var view = isChromeExtension || isApp || isMobile ? 'daily' : 'weekly';
var initialState = __assign(__assign({}, omit(variants, [
    'saveHistory',
    'middleware',
    'useHashRouter',
    'loadTaskManagementHistory',
    'saveTaskManagementHistory',
    'loadStorage',
    'saveStorage',
    'clearStorage',
])), { calendar: __assign(__assign({}, CalendarInitialState), { view: view }) });
export default initialState;
