import { __assign } from "tslib";
import { connect } from 'react-redux';
import TaskTimeEntryList from '@/components/task_edit/task_time_entry_list';
import { openModal } from '@/modules/modal';
import { fetchTeamTimeEntries, expandTimeEntries, destroyTimeEntry, } from '@/modules/time_entries';
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { timeEntries: state.timeEntries.items, next: state.timeEntries.next, isFetched: state.timeEntries.isFetched, isLoading: state.timeEntries.isLoading })); };
var mapDispatchToProps = function (dispatch) { return ({
    onFetch: function (teamId, params) {
        dispatch(fetchTeamTimeEntries(teamId, params));
    },
    onExpand: function (next) {
        dispatch(expandTimeEntries(next));
    },
    openDeleteModal: function () {
        dispatch(openModal('DELETE_TASK_MODAL'));
    },
    destroyTimeEntry: function (id) {
        return dispatch(destroyTimeEntry(id));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TaskTimeEntryList);
