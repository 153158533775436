import React from 'react';
import { zenToHanTime, addColon, removeNonTimeCharacters, } from '@/utils/time_helper';
var zeroPadding = function (num) {
    if (num) {
        return num < 10 ? "0".concat(num) : num.toString();
    }
    else {
        return '00';
    }
};
function Duration(_a) {
    var seconds = _a.seconds, onChange = _a.onChange;
    var _b = React.useState(false), isEditing = _b[0], setIsEditing = _b[1];
    var defaultTimeString = React.useMemo(function () {
        var sec = Math.abs(seconds);
        var h = Math.floor(sec / 3600);
        var m = Math.floor((sec - h * 3600) / 60);
        var s = sec - h * 3600 - m * 60;
        return "".concat(zeroPadding(h), ":").concat(zeroPadding(m), ":").concat(zeroPadding(s));
    }, [seconds]);
    var _c = React.useState(defaultTimeString), timeString = _c[0], setTimeString = _c[1];
    React.useEffect(function () {
        if (!isEditing) {
            setTimeString(defaultTimeString);
        }
    }, [defaultTimeString, isEditing]);
    var canChange = onChange && seconds >= 0;
    var Tag = canChange ? 'button' : 'span';
    if (isEditing) {
        return (<input autoFocus className="border-0 h-100 w-28 text-center py-1" type="text" value={timeString} onBlur={function () {
                setIsEditing(false);
            }} onChange={function (e) {
                var value = removeNonTimeCharacters(addColon(zenToHanTime(e.target.value).slice(0, 8)));
                setTimeString(value);
                onChange === null || onChange === void 0 ? void 0 : onChange(value);
            }}/>);
    }
    else {
        return (<Tag className={"".concat(canChange
                ? "btn btn-link btn-sm align-baseline ".concat(seconds === 0 ? 'duration' : 'text-muted')
                : 'duration')} onClick={canChange
                ? function () {
                    setIsEditing(true);
                }
                : undefined}>
        {seconds < 0 && '-'}
        {timeString}
      </Tag>);
    }
}
export default Duration;
