import React from 'react';
import I18n from '@/constants/i18n';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
export var CustomFieldsExpressionEditor = function (_a) {
    var customFieldsMasters = _a.customFieldsMasters, _b = _a.className, className = _b === void 0 ? '' : _b, expression = _a.expression, onChangeExpression = _a.onChangeExpression;
    var textareaRef = React.useRef(null);
    var insertText = function (insertValue) {
        var textarea = textareaRef.current;
        if (!textarea) {
            return;
        }
        var startPos = textarea.selectionStart;
        var endPos = textarea.selectionEnd;
        var currentValue = textarea.value;
        var newValue = currentValue.slice(0, startPos) +
            insertValue +
            currentValue.slice(endPos);
        textarea.value = newValue;
        textarea.selectionStart = textarea.selectionEnd =
            startPos + insertValue.length;
        textarea.focus();
        onChangeExpression(newValue);
    };
    return (<div className={"rounded border-1 ".concat(className)}>
      <div className="bg-faded border-bottom-1 p-2 d-flex">
        <UncontrolledDropdown>
          <DropdownToggle caret>{I18n.t('shared.customFields')}</DropdownToggle>
          <DropdownMenu>
            {customFieldsMasters
            .filter(function (_a) {
            var field_type = _a.field_type;
            return ['number', 'duration'].includes(field_type);
        })
            .map(function (master) {
            return (<DropdownItem key={master.id} className="d-flex justify-content-between align-items-center" value={master.name} onClick={function () {
                    return insertText("".concat(I18n.t('shared.customFieldsExpressionLabels.customField'), "(\"").concat(master.name, "\") "));
                }}>
                    {master.name}
                    <small className="text-muted">
                      {I18n.t("shared.customFieldsTypes.".concat(master.field_type))}
                      {master.field_type === 'duration' && (<span className="ml-1">
                          ({I18n.t('shared.seconds')})
                        </span>)}
                    </small>
                  </DropdownItem>);
        })}
          </DropdownMenu>
        </UncontrolledDropdown>
        {['totalAmount', 'totalDuration']
            .map(function (label) {
            return I18n.t("shared.customFieldsExpressionLabels.".concat(label));
        })
            .map(function (label) { return (<button key={label} className="btn btn-secondary ml-2" onClick={function () { return insertText("".concat(label, " ")); }}>
              {label}
            </button>); })}
      </div>
      <div>
        <textarea ref={textareaRef} className="m-0 form-control border-0 resize-none px-2" placeholder={I18n.t('shared.customFieldsExpressionPlaceholder')} rows={5} value={expression} onChange={function (e) { return onChangeExpression(e.target.value); }}/>
      </div>
    </div>);
};
