import React from 'react';
import I18n from '@/constants/i18n';
import CustomRadio from '@/components/atoms/custom_radio';
export var dataTypes = ['task', 'time_entry'];
export var DataTypeField = function (_a) {
    var dataType = _a.dataType, onChangeDataType = _a.onChangeDataType;
    return (<div className="form-group row">
      <label className="col-form-label col-sm-3 col-12">
        {I18n.t("export.dataType")}
      </label>
      <div className="col-xl-7 col-sm-9 col-12">
        {dataTypes.map(function (dt) { return (<div key={dt}>
            <CustomRadio key={dt} checked={dataType === dt} value={dt} onChange={function () { return onChangeDataType(dt); }}>
              {I18n.t("export.by.".concat(dt))}
            </CustomRadio>
          </div>); })}
      </div>
    </div>);
};
