import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
import { RealtimeReportTab } from './realtime_report_tab';
import { LongTermReportTabContainer } from '@/containers/cross_team_report_header/long_term_report_tab_container';
import { buildDefaultPeriod, periodLabel } from './helpers';
export var CrossTeamReportPeriodSelector = function (props) {
    var defaultPeriod = props.defaultPeriod, defaultStartMonth = props.defaultStartMonth, isLoadingRealtime = props.isLoadingRealtime, onRefreshRealtime = props.onRefreshRealtime;
    var _a = React.useState(false), isOpenDropdown = _a[0], setIsOpenDropdown = _a[1];
    var _b = React.useState('realtime'), currentTab = _b[0], setCurrentTab = _b[1];
    var _c = React.useState(defaultStartMonth), startMonth = _c[0], setStartMonth = _c[1];
    React.useEffect(function () {
        setStartMonth(defaultStartMonth);
        setCurrentTab('realtime');
    }, [defaultStartMonth, isOpenDropdown]);
    var handleToggle = function () {
        setIsOpenDropdown(!isOpenDropdown);
    };
    var handleChangeTab = function (e, tab) {
        e.preventDefault();
        setCurrentTab(tab);
    };
    return (<div className="d-flex align-items-center">
      <Icon className="text-primary mr-1" name="calendar"/>
      <Dropdown isOpen={isOpenDropdown} toggle={handleToggle}>
        <DropdownToggle caret className="btn-flat">
          {defaultPeriod.period_type !== 'custom' && (<span className="mr-1">
              {I18n.t("shared.".concat(defaultPeriod.period_type))}
            </span>)}
          {periodLabel(defaultPeriod)}
        </DropdownToggle>
        <DropdownMenu className="bg-white">
          <ul className="nav nav-tabs nav-justified">
            <li className="nav-item">
              <a className={"nav-link ".concat(currentTab === 'realtime' && 'active')} href="#" onClick={function (e) { return handleChangeTab(e, 'realtime'); }}>
                {I18n.t('shared.realtimeReport')}
              </a>
            </li>
            <li className="nav-item">
              <a className={"nav-link ".concat(currentTab === 'long_term' && 'active')} href="#" onClick={function (e) { return handleChangeTab(e, 'long_term'); }}>
                {I18n.t('shared.longTermReport')}
              </a>
            </li>
          </ul>
          <div className="px-2 py-1">
            {currentTab === 'realtime' && (<RealtimeReportTab defaultPeriod={defaultPeriod.term === 'realtime'
                ? defaultPeriod
                : buildDefaultPeriod(defaultStartMonth)} isLoading={isLoadingRealtime} isOpen={isOpenDropdown} startMonth={startMonth} onChangeStartMonth={setStartMonth} onClose={handleToggle} onRefresh={onRefreshRealtime}/>)}
            {currentTab === 'long_term' && (<LongTermReportTabContainer isOpen={isOpenDropdown} startMonth={startMonth} onChangeStartMonth={setStartMonth} onClose={handleToggle}/>)}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>);
};
