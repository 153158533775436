import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CrossTeamReportBookmarkList } from '@/components/cross_team_report_bookmark_list';
import { fetchWorkspacesReportBookmarks, updateWorkspacesReportBookmark, } from '@/modules/workspaces_report_bookmarks';
import { setNotificationMessage } from '@/modules/notification';
import I18n from '@/constants/i18n';
export var CrossTeamReportBookmarkListContainer = function (props) {
    var onSelect = props.onSelect;
    var dispatch = useDispatch();
    var workspacesReportBookmarks = useSelector(function (state) { return state.workspacesReportBookmarks; });
    React.useEffect(function () {
        dispatch(fetchWorkspacesReportBookmarks({}));
    }, [dispatch]);
    var sortedBookmarks = React.useMemo(function () {
        return workspacesReportBookmarks.items
            .sort(function (a, b) { return b.id - a.id; })
            .sort(function (a, b) {
            return a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1;
        });
    }, [workspacesReportBookmarks.items]);
    var handleUpdate = React.useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(updateWorkspacesReportBookmark(params))];
                case 1:
                    res = _a.sent();
                    if (!res.error) {
                        dispatch(setNotificationMessage('success', I18n.t("shared.reportBookmark".concat(params.pinned ? 'Favorited' : 'Unfavorited'))));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch]);
    return (<CrossTeamReportBookmarkList bookmarks={sortedBookmarks} isLoaded={workspacesReportBookmarks.status === 'success'} onSelect={onSelect} onUpdate={handleUpdate}/>);
};
