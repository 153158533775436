import { __assign, __spreadArray } from "tslib";
export var incrementCategoryPosition = function (targetCategory, baseCategory, beforePosition) {
    if (targetCategory.ancestry === baseCategory.ancestry &&
        targetCategory.position >= baseCategory.position &&
        targetCategory.position < beforePosition) {
        return __assign(__assign({}, targetCategory), { position: targetCategory.position + 1 });
    }
    else {
        return targetCategory;
    }
};
export var decrementCategoryPosition = function (targetCategory, baseCategory, beforePosition) {
    if (targetCategory.ancestry === baseCategory.ancestry &&
        targetCategory.position <= baseCategory.position &&
        targetCategory.position > beforePosition) {
        return __assign(__assign({}, targetCategory), { position: targetCategory.position - 1 });
    }
    else {
        return targetCategory;
    }
};
export var buildCategoriesWithAncestorTitles = function (categories) {
    var categoryById = {};
    var categoriesWithAncestorTitles = [];
    categories.forEach(function (category) {
        categoryById[category.id] = category;
    });
    categories.forEach(function (category) {
        var builtCategory = __assign(__assign({}, category), { ancestor_titles: buildAncestorTitles(category, categoryById) });
        categoryById[category.id] = builtCategory;
        categoriesWithAncestorTitles.push(builtCategory);
    });
    return {
        categoriesWithAncestorTitles: categoriesWithAncestorTitles,
        categoryById: categoryById,
    };
};
export var buildCategoriesWithAncestorAndDescendantTitles = function (categories) {
    var categoryById = {};
    var childrenByParentId = {};
    categories.forEach(function (category) {
        categoryById[category.id] = category;
        var parent_id = category.parent_id;
        if (!parent_id) {
            return;
        }
        if (!childrenByParentId[parent_id]) {
            childrenByParentId[parent_id] = [];
        }
        childrenByParentId[parent_id].push(category);
    });
    var categoriesWithAncestorAndDescendantTitles = categories.map(function (category) { return (__assign(__assign({}, category), { ancestor_titles: buildAncestorTitles(category, categoryById), descendant_titles: buildDescendantTitles(category, childrenByParentId).slice(1) })); });
    return {
        categoriesWithAncestorAndDescendantTitles: categoriesWithAncestorAndDescendantTitles,
    };
};
export var buildAncestorTitles = function (category, categoryById) {
    var _a, _b;
    return ((_b = (_a = category.ancestry) === null || _a === void 0 ? void 0 : _a.split('/').filter(function (id) { return id !== '' && categoryById[Number(id)]; }).map(function (id) { return categoryById[Number(id)].title; })) !== null && _b !== void 0 ? _b : []);
};
var buildDescendantTitles = function (category, childrenByParentId) {
    var _a;
    return __spreadArray([
        category.title
    ], ((_a = childrenByParentId[category.id]) !== null && _a !== void 0 ? _a : []).flatMap(function (c) {
        return buildDescendantTitles(c, childrenByParentId);
    }), true);
};
