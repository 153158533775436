import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
var DateBar = function (_a) {
    var date = _a.date, hours = _a.hours, minutes = _a.minutes;
    return (<div className="datebar">
    <span className="datebar-day">
      {moment(date)
            .locale(I18n.locale)
            .calendar(null, {
            sameDay: "[".concat(I18n.t('shared.today'), "] YYYY-MM-DD(ddd)"),
            nextDay: "[".concat(I18n.t('shared.nextDay'), "] YYYY-MM-DD(ddd)"),
            nextWeek: 'YYYY-MM-DD(ddd)',
            lastDay: "[".concat(I18n.t('shared.yesterday'), "] YYYY-MM-DD(ddd)"),
            lastWeek: 'YYYY-MM-DD(ddd)',
            sameElse: 'YYYY-MM-DD(ddd)',
        })}
    </span>
    <div className="datebar-times">
      <span className="time-value">{hours}</span>
      <span className="time-unit">{I18n.t('shared.hours')}</span>
      <span className="time-value">{minutes}</span>
      <span className="time-unit">{I18n.t('shared.minutes')}</span>
    </div>
  </div>);
};
export default DateBar;
