import { __assign } from "tslib";
import React from 'react';
import I18n from '@/constants/i18n';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import Icon from '@/components/atoms/icon';
import { categoryWithAncestors } from '@/utils/category_helper';
import { CategoryInfoContainer } from '@/containers/category_info_container';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
import { formatSuggestedTitle } from './helpers';
export var TaskSuggestionListItem = function (props) {
    var task = props.task, keyword = props.keyword, icon = props.icon, hideTeam = props.hideTeam, handleClickSuggestion = props.handleClickSuggestion;
    return (<div className="list-group-item list-group-item-action task-suggestion py-1" onClick={function () { return handleClickSuggestion(task); }}>
      <div className="task-inline w-100">
        <div className="task-block row no-gutters">
          <div className="task-title col-12 col-sm-8">
            {icon.length > 0 && <Icon className="mr-2" name={icon}/>}
            {formatSuggestedTitle(task.title, keyword)}
          </div>
          <div className="task-meta col-12 col-sm-4">
            <div className="task-category">
              <div className="d-inline-flex align-items-center align-middle">
                <WorkspaceAvatarContainer className="mr-1 grayscale" workspaceId={task.team.workspaces_workspace_id}/>
                <CategoryCircleIcon color={task.category.color}/>
              </div>
              <span className="task-category-title">
                {categoryWithAncestors(task.category)}
                {!hideTeam && " - ".concat(task.team.name)}
                <CategoryInfoContainer requiresCustomFields category={__assign(__assign({}, task.category), { team: task.team })} className="mx-1"/>
              </span>
            </div>
            {task.state === 'closed' && (<div className="task-status">
                <Icon className="mr-1" name="checkmark" size="sm"/>
                {I18n.t('shared.completed')}
              </div>)}
          </div>
        </div>
      </div>
    </div>);
};
