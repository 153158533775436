import React from 'react';
import I18n from '@/constants/i18n';
export var SupportAccountLabel = function (_a) {
    var isShow = _a.isShow;
    if (!isShow) {
        return null;
    }
    return (<div style={{
            position: 'fixed',
            background: '#3498db',
            left: '50%',
            transform: 'translate(-50%, 0)',
            top: 0,
            zIndex: 9999,
            padding: '0 1rem',
            color: 'white',
        }}>
      {I18n.t('shared.loggedInAsSupportAccount')}
    </div>);
};
