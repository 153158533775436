import React from 'react';
import ExpansionPanel from '@/components/atoms/expansion_panel';
import ExpansionPanelBody from '@/components/atoms/expansion_panel_body';
import Avatar from '@/components/atoms/avatar';
import I18n from '@/constants/i18n';
export default function UserList(_a) {
    var users = _a.users, query = _a.query, onClick = _a.onClick;
    return (<ExpansionPanel expand className="mw-100">
      {function (expand) { return (<ExpansionPanelBody expand={expand}>
          <div className="list-group list-group-flush">
            <div key={"user-list-all"} className={"list-group-item list-group-item-action ".concat(!query.user_id ? 'active' : '')} onClick={function () { return onClick(undefined); }}>
              <span>{I18n.t('shared.allUsers')}</span>
            </div>
            {(users !== null && users !== void 0 ? users : []).map(function (user) {
                return (<div key={"user-list-".concat(user.id)} className={"list-group-item list-group-item-action ".concat(query.user_id && Number(query.user_id) === user.id
                        ? 'active'
                        : '')} onClick={function () { return onClick(user); }}>
                  <Avatar className="mr-2" image={user.avatar_url} size="md"/>
                  <span>{user.nickname}</span>
                </div>);
            })}
          </div>
        </ExpansionPanelBody>); }}
    </ExpansionPanel>);
}
