import React from 'react';
import I18n from '@/constants/i18n';
import { workspacePath } from '@/utils/workspace_helper';
export default function ChromeExtension(_a) {
    var teamId = _a.teamId, openURL = _a.openURL;
    return (<>
      <p className="mb-3">{I18n.t('payment.chromeExtension.description')}</p>
      <p className="mb-3">
        <a href={workspacePath("/teams/".concat(teamId, "/payment"))} onClick={function (e) {
            e.preventDefault();
            openURL(e.target.href);
        }}>
          {I18n.t('payment.chromeExtension.goToPC')}
        </a>
      </p>
    </>);
}
