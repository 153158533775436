import React from 'react';
import I18n from '@/constants/i18n';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import Icon from '@/components/atoms/icon';
import CustomCheck from '@/components/atoms/custom_check';
import { ignoreArchivedCategories } from '@/modules/categories';
export var SubpaneCategoryList = function (_a) {
    var reportableTeams = _a.reportableTeams, reportedTeam = _a.reportedTeam, reportedCategory = _a.reportedCategory, isShowArchived = _a.isShowArchived, isSelectedMyReport = _a.isSelectedMyReport, onSelectCategory = _a.onSelectCategory, onToggleOpenSubpane = _a.onToggleOpenSubpane, onToggleIsShowArchived = _a.onToggleIsShowArchived;
    var allCategories = isSelectedMyReport
        ? reportableTeams.reduce(function (acc, team) { return acc.concat(team.categories); }, [])
        : reportedTeam.categories;
    var filteredCategories = isShowArchived
        ? allCategories
        : ignoreArchivedCategories(allCategories);
    var AllCategory = function () { return (<a className={"list-group-item ".concat(reportedCategory === null ? 'active' : '')} href="#" style={{
            paddingLeft: '1.2rem',
        }} onClick={function (e) {
            e.preventDefault();
            onSelectCategory(null);
            onToggleOpenSubpane();
        }}>
      <CategoryCircleIcon />
      <span className="list-group-contents">
        {I18n.t('report.allCategory')}
      </span>
    </a>); };
    var parseCategories = function (cat) {
        var children = filteredCategories.filter(function (category) {
            return (category.parent_id === cat.id &&
                category.team_id === cat.team_id &&
                category.ancestry_depth === cat.ancestry_depth + 1);
        });
        return [
            <a key={cat.id} className={"list-group-item ".concat(reportedCategory && cat.id === reportedCategory.id ? 'active' : '')} href="#" style={{
                    paddingLeft: "".concat(cat.ancestry_depth + 1 * 1.2, "rem"),
                }} onClick={function (e) {
                    e.preventDefault();
                    onSelectCategory(cat);
                    onToggleOpenSubpane();
                }}>
        <CategoryCircleIcon color={cat.color}/>
        <span className="list-group-contents">{cat.title}</span>
        {cat.archived && (<span className="badge badge-info ml-1">
            <Icon className="mr-1" name="archive" size="sm"/>
            {I18n.t('shared.archived')}
          </span>)}
      </a>,
            children.map(parseCategories),
        ];
    };
    return (<>
      <CustomCheck checked={isShowArchived} className="m-2" onChange={onToggleIsShowArchived}>
        {I18n.t('shared.includeArchived')}
      </CustomCheck>
      <AllCategory key={'report.allCategory'}/>
      {filteredCategories
            .filter(function (category) { return category.ancestry_depth === 0; })
            .map(parseCategories)}
    </>);
};
