import api2 from '@/api2';
import { changeCategory } from '@/modules/categories';
export var updateCategory = function (id, params) {
    if (params === void 0) { params = {}; }
    return function (dispatch, getState) {
        return api2(dispatch, getState, "/api/v2/categories/".concat(id), 'PATCH', params).then(function (response) {
            if (response.ok) {
                return response.json().then(function (category) {
                    dispatch(changeCategory(category));
                    return category;
                });
            }
            else {
                return { error: true };
            }
        });
    };
};
