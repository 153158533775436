import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspacesMembersForm } from '@/components/workspaces_members_form';
import { fetchWorkspacesInvitations, renewWorkspacesInvitation, } from '@/modules/workspaces_invitations';
import { fetchWorkspacesMemberships, updateWorkspacesMembership, deleteWorkspacesMembership, } from '@/modules/workspaces_memberships';
import { fetchWorkspacesEmploys, createWorkspacesEmploy, updateWorkspacesEmploy, deleteWorkspacesEmploy, } from '@/modules/workspaces_employs';
import LoadingPage from '@/components/pages/loading_page';
import NotFound from '@/components/pages/not_found';
import I18n from '@/constants/i18n';
import { setNotificationMessage, removeDangerNotificationMessage, } from '@/modules/notification';
import { workspaceTeams } from '@/utils/workspace_helper';
import { fetchTeamEmploys } from '@/modules/employs';
import { fetchTeamMemberships } from '@/modules/memberships';
import { toggleTeamMenu } from '@/modules/team_management';
export var WorkspacesMembersContainer = function () {
    var teams = useSelector(function (state) { return state.teams.items; });
    var workspaces = useSelector(function (state) { return state.workspaces; });
    var workspacesEmploys = useSelector(function (state) { return state.workspacesEmploys; });
    var workspacesInvitations = useSelector(function (state) { return state.workspacesInvitations; });
    var workspacesMemberships = useSelector(function (state) { return state.workspacesMemberships; });
    var employsByTeamId = useSelector(function (state) { return state.employs.itemsByTeamId; });
    var membershipsByTeamId = useSelector(function (state) { return state.memberships.itemsByTeamId; });
    var dispatch = useDispatch();
    var handleUpdateWorkspacesMembership = function (id, params) { return __awaiter(void 0, void 0, void 0, function () {
        var res, prefix, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(updateWorkspacesMembership(id, params))];
                case 1:
                    res = _a.sent();
                    if (!res.error) {
                        prefix = res.item.role === 'owner' ? '' : 'Not';
                        message = I18n.t("shared.make".concat(prefix, "WorkspaceOwner"), {
                            name: res.item.user_nickname,
                        });
                        dispatch(setNotificationMessage('success', message));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteWorkspacesMembership = function (id) {
        return dispatch(deleteWorkspacesMembership(id));
    };
    var handleCreateWorkspacesEmploy = function (params) {
        return dispatch(createWorkspacesEmploy(params));
    };
    var handleUpdateWorkspacesEmploy = function (id, params) {
        return dispatch(updateWorkspacesEmploy(id, params));
    };
    var handleDeleteWorkspacesEmploy = function (id) {
        return dispatch(deleteWorkspacesEmploy(id));
    };
    var handleRenewWorkspacesInvitation = function (id) {
        return dispatch(renewWorkspacesInvitation(id));
    };
    var handleToggleTeamMenu = function (isOpen) {
        dispatch(toggleTeamMenu(isOpen));
    };
    var handleRemoveDangerNotificationMessage = function () {
        dispatch(removeDangerNotificationMessage());
    };
    React.useEffect(function () {
        var _a;
        if (((_a = workspaces.current) === null || _a === void 0 ? void 0 : _a.role) === 'owner') {
            dispatch(fetchWorkspacesInvitations());
            dispatch(fetchWorkspacesMemberships());
            dispatch(fetchWorkspacesEmploys());
        }
    }, [dispatch, workspaces]);
    var filteredTeams = React.useMemo(function () { return workspaceTeams(teams); }, [teams]);
    React.useEffect(function () {
        filteredTeams.forEach(function (team) {
            dispatch(fetchTeamEmploys(team.id));
            dispatch(fetchTeamMemberships(team.id));
        });
    }, [dispatch, filteredTeams]);
    if (!workspaces.isLoaded) {
        return <LoadingPage />;
    }
    var workspace = workspaces.current;
    if (!workspace || workspace.role !== 'owner') {
        return <NotFound />;
    }
    if (!workspacesInvitations.isLoaded ||
        !workspacesMemberships.isLoaded ||
        !workspacesEmploys.isLoaded) {
        return <LoadingPage />;
    }
    return (<WorkspacesMembersForm createWorkspacesEmploy={handleCreateWorkspacesEmploy} deleteWorkspacesEmploy={handleDeleteWorkspacesEmploy} deleteWorkspacesMembership={handleDeleteWorkspacesMembership} employsByTeamId={employsByTeamId} membershipsByTeamId={membershipsByTeamId} renewWorkspacesInvitation={handleRenewWorkspacesInvitation} teams={filteredTeams} toggleTeamMenu={handleToggleTeamMenu} updateWorkspacesEmploy={handleUpdateWorkspacesEmploy} updateWorkspacesMembership={handleUpdateWorkspacesMembership} workspace={workspace} workspacesEmploys={workspacesEmploys.items} workspacesInvitation={workspacesInvitations.items[0]} workspacesMemberships={workspacesMemberships.items} onRemoveDangerNotificationMessage={handleRemoveDangerNotificationMessage}/>);
};
export default WorkspacesMembersContainer;
