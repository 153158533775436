import { __assign } from "tslib";
import React from 'react';
import Avatar from '@/components/atoms/avatar';
import I18n from '@/constants/i18n';
import OmitLabel from '@/components/atoms/omit_label';
var BASE_LIMIT = 5;
var PER_PAGE = 20;
export var TeamReportBarChartLegend = function (_a) {
    var categories = _a.categories, teams = _a.teams;
    var _b = React.useState({}), pageByTeamId = _b[0], setPageByTeamId = _b[1];
    var categoriesByTeamId = React.useMemo(function () {
        var categoriesByTeamId = {};
        categories.forEach(function (category) {
            if (!categoriesByTeamId[category.team_id]) {
                categoriesByTeamId[category.team_id] = [];
            }
            categoriesByTeamId[category.team_id].unshift(category);
        });
        return categoriesByTeamId;
    }, [categories]);
    var handlePrevPage = function (team) {
        var _a;
        var _b;
        setPageByTeamId(__assign(__assign({}, pageByTeamId), (_a = {}, _a[team.id] = ((_b = pageByTeamId[team.id]) !== null && _b !== void 0 ? _b : 0) - 1, _a)));
    };
    var handleNextPage = function (team) {
        var _a;
        var _b;
        setPageByTeamId(__assign(__assign({}, pageByTeamId), (_a = {}, _a[team.id] = ((_b = pageByTeamId[team.id]) !== null && _b !== void 0 ? _b : 0) + 1, _a)));
    };
    return (<div className="ml-2 small transition-none">
      {teams.toReversed().map(function (team, i) {
            var _a, _b;
            return (<div key={team.id} className={"d-flex align-items-center py-2 ".concat(i > 0 ? 'border-top-1' : '')}>
          <Avatar image={team.avatar_url}/>
          <div className="flex-grow-1">
            <div className="d-flex flex-wrap gap-y-[0.8rem]">
              {categoriesByTeamId[team.id]
                    .slice(0, BASE_LIMIT + ((_a = pageByTeamId[team.id]) !== null && _a !== void 0 ? _a : 0) * PER_PAGE)
                    .map(function (category) { return (<div key={category.id} className="d-flex align-items-center ml-2 pl-1">
                    <div className={"w-[1.8rem] h-[0.8rem] min-h-auto top-0 rounded-0 category-circle category-".concat(category.color)}/>
                    <OmitLabel withTooltip maxWidth="10rem">
                      {category.title}
                    </OmitLabel>
                  </div>); })}
            </div>
            {categoriesByTeamId[team.id].length > BASE_LIMIT && (<div className="text-center mt-1">
                {pageByTeamId[team.id] > 0 && (<button className="btn btn-link btn-sm p-0" onClick={function () { return handlePrevPage(team); }}>
                    {"- ".concat(I18n.t('shared.showLess'))}
                  </button>)}
                {categoriesByTeamId[team.id].length >
                        BASE_LIMIT + ((_b = pageByTeamId[team.id]) !== null && _b !== void 0 ? _b : 0) * PER_PAGE && (<button className="btn btn-link btn-sm p-0" onClick={function () { return handleNextPage(team); }}>
                    {"+ ".concat(I18n.t('shared.showMore'))}
                  </button>)}
              </div>)}
          </div>
        </div>);
        })}
    </div>);
};
