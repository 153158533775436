import { __assign } from "tslib";
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import Icon from '@/components/atoms/icon';
import { CrossTeamReportConditionsInfo } from '@/components/cross_team_report_conditions_info';
import { buildBookmarkTitle } from './helpers';
import { CrossTeamReportBookmarkUpdateModalContainer } from '@/containers/cross_team_report_bookmark_update_modal_container';
import { CrossTeamReportBookmarkDeleteModalContainer } from '@/containers/cross_team_report_bookmark_delete_modal_container';
import I18n from '@/constants/i18n';
import { formatDate } from '@/utils/time_helper';
import { calcFromAndToForType } from '../cross_team_report_header/helpers';
export var CrossTeamReportBookmarkItem = function (props) {
    var bookmark = props.bookmark, pinned = props.bookmark.pinned, onFavorite = props.onFavorite, onUnfavorite = props.onUnfavorite, onSelect = props.onSelect;
    var nameRef = React.useRef(null);
    var favoriteRef = React.useRef(null);
    var handleClickFavorite = function (e) {
        e.stopPropagation();
        if (pinned) {
            onUnfavorite(bookmark.id);
        }
        else {
            onFavorite(bookmark.id);
        }
    };
    var conditions = __assign(__assign({}, bookmark.conditions), (bookmark.conditions.term === 'realtime' &&
        bookmark.conditions.period_type !== 'custom' &&
        calcFromAndToForType(bookmark.conditions.period_type, bookmark.conditions.start_month)));
    var handleSelect = function () {
        onSelect(__assign(__assign({}, bookmark), { conditions: conditions }));
    };
    var name = buildBookmarkTitle(bookmark);
    return (<div className="border-1 rounded mt-2 py-1 px-2 !tw-cursor-pointer hover-container" onClick={handleSelect}>
      <div className="d-flex align-items-center">
        <div ref={nameRef} className="font-weight-bold leading-8 w-100 text-truncate">
          {name}
        </div>
        <UncontrolledTooltip fade={false} target={nameRef}>
          {name}
        </UncontrolledTooltip>
        <button ref={favoriteRef} className="btn btn-link p-0 d-flex align-items-center" onClick={handleClickFavorite}>
          <span className={"h2 ".concat(pinned ? 'text-warning' : 'text-muted')}>
            {pinned ? '★' : '☆'}
          </span>
        </button>
        <UncontrolledTooltip fade={false} target={favoriteRef}>
          {I18n.t("shared.favorite")}
        </UncontrolledTooltip>
      </div>
      <div className="text-muted position-relative min-h-20">
        <CrossTeamReportConditionsInfo withDate conditions={conditions}/>
        <div className="btn-group btn-group-sm position-absolute right-0 bottom-0 hover-item">
          <CrossTeamReportBookmarkUpdateModalContainer bookmark={bookmark}/>
          <CrossTeamReportBookmarkDeleteModalContainer bookmark={bookmark}/>
        </div>
        {bookmark.last_generated_at !== null && (<div className="mt-1 small">
            <Icon className="mr-1" name="clock"/>
            {"".concat(I18n.t('shared.lastUpdated'), ": ")}
            {formatDate(bookmark.last_generated_at)}
          </div>)}
      </div>
    </div>);
};
