import React from 'react';
import I18n from '../../constants/i18n';
export default function DeleteAssetButton(_a) {
    var asset = _a.asset, onDelete = _a.onDelete;
    if (['initial', 'in_progress'].includes(asset.status)) {
        return null;
    }
    return (<button className="text-danger btn btn-link" onClick={function () { return onDelete(asset); }}>
      {I18n.t('shared.remove')}
    </button>);
}
