import React from 'react';
var HIGHLIGHTING_PATTERN = new RegExp([
    'カスタムフィールド\\(".+?"\\)',
    'custom_field\\(".+?"\\)',
    '打刻時間（秒）',
    'total_duration',
    '稼働金額',
    'total_amount',
].join('|'), 'g');
export var SyntaxHighlighting = function (_a) {
    var expressionText = _a.expressionText, className = _a.className;
    var highlighted = expressionText.replace(HIGHLIGHTING_PATTERN, function (target) {
        return "<span class=\"bg-body-secondary p-0.5 mx-0.5\" style=\"\">".concat(target, "</span>");
    });
    return (<div className={className} dangerouslySetInnerHTML={{ __html: highlighted }}/>);
};
