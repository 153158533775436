import { __spreadArray } from "tslib";
import Encoding from 'encoding-japanese';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
import { calcCategoryAndTaskTotals } from '@/components/report/helpers';
export var BOM = [0xef, 0xbb, 0xbf];
var HEADERS = {
    task: [
        'teamId',
        'teamName',
        'categoryId',
        'categoryTitle',
        'taskId',
        'taskTitle',
        'taskUrl',
        'taskState',
        'taskTotalTime',
        'taskAmount',
    ],
    time_entry: [
        'teamId',
        'teamName',
        'categoryId',
        'categoryTitle',
        'taskId',
        'taskTitle',
        'taskUrl',
        'taskState',
        'userId',
        'userNickname',
        'timeEntryId',
        'timeEntryStartedAt',
        'timeEntryStoppedAt',
        'timeEntryDuration',
        'timeEntryAmount',
        'timeEntryComment',
    ],
};
export var generateCSV = function (timeEntries, tasks, teams, categories, users, dataType, timeFormat, charset) {
    var teamById = teams.reduce(function (acc, team) {
        acc[team.id] = team;
        return acc;
    }, {});
    var categoryById = categories.reduce(function (acc, category) {
        acc[category.id] = category;
        return acc;
    }, {});
    var taskById = tasks.reduce(function (acc, task) {
        acc[task.id] = task;
        return acc;
    }, {});
    var csv = [
        generateCSVLine(HEADERS[dataType].map(function (header) { return I18n.t("csv.".concat(header)); })),
    ];
    // NOTE: メモリ節約のため、配列を直接書き換える
    if (dataType === 'task') {
        generateCSVTasks(csv, timeEntries, timeFormat, teamById, categoryById, taskById);
    }
    else {
        generateCSVTimeEntries(csv, timeEntries, users, timeFormat, teamById, categoryById, taskById);
    }
    var encoding = charset === 'utf8_bom' ? 'UTF8' : charset.toUpperCase();
    var converted = Encoding.convert(Encoding.stringToCode(csv.join('\r\n')), {
        to: encoding,
        bom: true,
    });
    var bom = charset === 'utf8_bom' ? BOM : [];
    var blob = new Blob([new Uint8Array(__spreadArray(__spreadArray([], bom, true), converted, true))], {
        type: "text/csv;charset=".concat(encoding),
    });
    return blob;
};
export var generateCSVLine = function (row) {
    return row.map(function (cell) { return "\"".concat(cell.replace(/"/g, '""'), "\""); }).join(',');
};
export var formatDuration = function (duration, timeFormat) {
    switch (timeFormat) {
        case 'seconds': {
            return String(duration);
        }
        case 'hms': {
            var hms = calcHMS(duration);
            return I18n.t('csv.hms', hms);
        }
        case 'hms_colon': {
            var hms = calcHMS(duration);
            return Object.values(hms).join(':');
        }
        default: {
            var _a = calcHMS(duration), h = _a.h, m = _a.m;
            var hours = "".concat(h, ".").concat(Math.ceil(Number(m) / 6));
            if (hours.match(/\.10$/)) {
                hours = "".concat(Number(h) + 1, ".0");
            }
            if (hours === '0.0' && duration > 0) {
                hours = '0.1';
            }
            return hours;
        }
    }
};
var formatDateTime = function (timestamp) {
    return moment(timestamp).format(I18n.t('csv.dateTimeFormat'));
};
var calcHMS = function (duration) {
    var h = Math.floor(duration / 3600);
    var m = Math.floor((duration - h * 3600) / 60);
    var s = duration - h * 3600 - m * 60;
    return {
        h: String(h),
        m: String(m).padStart(2, '0'),
        s: String(s).padStart(2, '0'),
    };
};
export var generateCSVTasks = function (csv, timeEntries, timeFormat, teamById, categoryById, taskById) {
    var tasks = Array.from(new Set(timeEntries.map(function (timeEntry) { return taskById[timeEntry.time_trackable_id]; })));
    var taskTotals = calcCategoryAndTaskTotals(timeEntries, tasks).taskTotals;
    tasks.forEach(function (task) {
        var team = teamById[task.team_id];
        csv.push(generateCSVLine(__spreadArray(__spreadArray([
            team.id,
            team.name
        ], generateCategoryCells(task, categoryById), true), [
            task.id,
            task.title,
            task.url,
            task.state_text,
            formatDuration(taskTotals[task.id].duration, timeFormat),
            taskTotals[task.id].amount,
        ], false).map(function (col) { return String(col); })));
    });
};
export var generateCSVTimeEntries = function (csv, timeEntries, users, timeFormat, teamById, categoryById, taskById) {
    var userById = users.reduce(function (acc, user) {
        acc[user.id] = user;
        return acc;
    }, {});
    timeEntries.forEach(function (timeEntry) {
        var _a, _b;
        var task = taskById[timeEntry.time_trackable_id];
        var team = teamById[timeEntry.team_id];
        var user = userById[timeEntry.time_tracker_id];
        csv.push(generateCSVLine(__spreadArray(__spreadArray([
            team.id,
            team.name
        ], generateCategoryCells(task, categoryById), true), [
            task.id,
            task.title,
            task.url,
            task.state_text,
            user.id,
            user.nickname,
            timeEntry.id,
            formatDateTime(timeEntry.started_at),
            timeEntry.stopped_at ? formatDateTime(timeEntry.stopped_at) : '',
            formatDuration(timeEntry.duration, timeFormat),
            timeEntry.amount,
            (_b = (_a = timeEntry.comment) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : '',
        ], false).map(function (col) { return String(col); })));
    });
};
var generateCategoryCells = function (task, categoryById) {
    var categoryIds = [];
    var categoryTitles = [];
    task.ancestry.split('/').forEach(function (id) {
        categoryIds.push(id);
        categoryTitles.push(categoryById[Number(id)].title);
    });
    return [categoryIds.join('>'), categoryTitles.join('>')];
};
