import React from 'react';
import { connect } from 'react-redux';
import { ExportFormatModal } from '@/components/organisms/export_format_modal';
var ExportFormatModalContainer = function (props) {
    var loadExportDataType = props.loadExportDataType, loadExportTimeFormat = props.loadExportTimeFormat, loadExportCharset = props.loadExportCharset, saveExportDataType = props.saveExportDataType, saveExportTimeFormat = props.saveExportTimeFormat, saveExportCharset = props.saveExportCharset, isOpen = props.isOpen, titleLabel = props.titleLabel, actionLabel = props.actionLabel, toggle = props.toggle, onSubmit = props.onSubmit, disableDataType = props.disableDataType;
    var _a = React.useState('task'), dataType = _a[0], setDataType = _a[1];
    var _b = React.useState('seconds'), timeFormat = _b[0], setTimeFormat = _b[1];
    var _c = React.useState('sjis'), charset = _c[0], setCharset = _c[1];
    var handleChangeDataType = React.useCallback(function (newDataType) {
        if (newDataType) {
            if (dataType !== newDataType) {
                saveExportDataType(newDataType);
            }
            setDataType(newDataType);
        }
    }, [dataType, saveExportDataType]);
    var handleChangeTimeFormat = React.useCallback(function (newTimeFormat) {
        if (newTimeFormat) {
            if (timeFormat !== newTimeFormat) {
                saveExportTimeFormat(newTimeFormat);
            }
            setTimeFormat(newTimeFormat);
        }
    }, [timeFormat, saveExportTimeFormat]);
    var handleChangeCharset = React.useCallback(function (newCharset) {
        if (newCharset) {
            if (charset !== newCharset) {
                saveExportCharset(newCharset);
            }
            setCharset(newCharset);
        }
    }, [charset, saveExportCharset]);
    React.useEffect(function () {
        loadExportDataType(function (dataType) { return handleChangeDataType(dataType); });
        loadExportTimeFormat(function (timeFormat) { return handleChangeTimeFormat(timeFormat); });
        loadExportCharset(function (charset) { return handleChangeCharset(charset); });
    }, [
        loadExportDataType,
        loadExportTimeFormat,
        loadExportCharset,
        handleChangeDataType,
        handleChangeTimeFormat,
        handleChangeCharset,
    ]);
    return (<ExportFormatModal actionLabel={actionLabel} charset={charset} dataType={dataType} disableDataType={disableDataType} isOpen={isOpen} timeFormat={timeFormat} titleLabel={titleLabel} toggle={toggle} onChangeCharset={handleChangeCharset} onChangeDataType={handleChangeDataType} onChangeTimeFormat={handleChangeTimeFormat} onSubmit={function () { return onSubmit(dataType, timeFormat, charset); }}/>);
};
var mapStateToProps = function (state, props) { return ({
    isOpen: props.isOpen,
    titleLabel: props.titleLabel,
    actionLabel: props.actionLabel,
    toggle: props.toggle,
    onSubmit: props.onSubmit,
    loadExportDataType: state.loadExportDataType,
    loadExportTimeFormat: state.loadExportTimeFormat,
    loadExportCharset: state.loadExportCharset,
    saveExportDataType: state.saveExportDataType,
    saveExportTimeFormat: state.saveExportTimeFormat,
    saveExportCharset: state.saveExportCharset,
}); };
var mapDispatchToProps = function () { return ({}); };
export default connect(mapStateToProps, mapDispatchToProps)(ExportFormatModalContainer);
