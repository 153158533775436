import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomFieldsMasters } from '@/modules/custom_fields_masters';
import { fetchCustomFieldsValues } from '@/modules/custom_fields_values';
export default function useCustomFields(teams) {
    var dispatch = useDispatch();
    var customFieldsMasters = useSelector(function (state) { return state.customFieldsMasters; });
    var customFieldsValues = useSelector(function (state) { return state.customFieldsValues; });
    useEffect(function () {
        teams.forEach(function (team) {
            if (team.is_custom_fields_allowed) {
                if (!['loading', 'loaded'].includes(customFieldsMasters.statusByTeamId[team.id])) {
                    dispatch(fetchCustomFieldsMasters(team.id));
                }
                if (!['loading', 'loaded'].includes(customFieldsValues.statusByTeamId[team.id])) {
                    dispatch(fetchCustomFieldsValues(team.id));
                }
            }
        });
    }, [customFieldsMasters, customFieldsValues, dispatch, teams]);
}
