import { __assign } from "tslib";
import api2 from '@/api2';
export var BULK_CREATE_TASKS_REQUEST = 'timecrowd/BULK_CREATE_TASKS/BULK_CREATE_TASKS_REQUEST';
export var BULK_CREATE_TASKS_SUCCESS = 'timecrowd/BULK_CREATE_TASKS/BULK_CREATE_TASKS_SUCCESS';
export var BULK_CREATE_TASKS_FAILURE = 'timecrowd/BULK_CREATE_TASKS/BULK_CREATE_TASKS_FAILURE';
var initialState = {
    isLoading: false,
};
export default function BulkCreateTasks(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case BULK_CREATE_TASKS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case BULK_CREATE_TASKS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false });
        case BULK_CREATE_TASKS_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
export var bulkCreateTasksRequest = function () { return ({
    type: BULK_CREATE_TASKS_REQUEST,
}); };
export var bulkCreateTasksSuccess = function () { return ({
    type: BULK_CREATE_TASKS_SUCCESS,
}); };
export var bulkCreateTasksFailure = function () { return ({
    type: BULK_CREATE_TASKS_FAILURE,
}); };
export var bulkCreateTasks = function (params) {
    return function (dispatch, getState) {
        dispatch(bulkCreateTasksRequest());
        return api2(dispatch, getState, '/api/v2/tasks/bulk_create', 'POST', params).then(function (response) {
            if (response.ok) {
                dispatch(bulkCreateTasksSuccess());
                return { error: false };
            }
            else {
                dispatch(bulkCreateTasksFailure());
                return { error: true };
            }
        });
    };
};
