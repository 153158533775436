import { __assign, __spreadArray } from "tslib";
import queryString from 'query-string';
import orderBy from 'lodash/orderBy';
import api2, { getNextLink } from '@/api2';
import { clearTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
export var REMOVE_TIME_ENTRY = 'timecrowd/time_entries/REMOVE_TIME_ENTRY';
export var FETCH_TIME_ENTRIES_REQUEST = 'timecrowd/time_entries/FETCH_TIME_ENTRIES_REQUEST';
export var FETCH_TIME_ENTRIES_SUCCESS = 'timecrowd/time_entries/FETCH_TIME_ENTRIES_SUCCESS';
export var FETCH_TIME_ENTRIES_FAILED = 'timecrowd/time_entries/FETCH_TIME_ENTRIES_FAILED';
export var FETCH_TEAM_TIME_ENTRIES_REQUEST = 'timecrowd/time_entries/FETCH_TEAM_TIME_ENTRIES_REQUEST';
export var FETCH_TEAM_TIME_ENTRIES_SUCCESS = 'timecrowd/time_entries/FETCH_TEAM_TIME_ENTRIES_SUCCESS';
export var FETCH_TEAM_TIME_ENTRIES_FAILED = 'timecrowd/time_entries/FETCH_TEAM_TIME_ENTRIES_FAILED';
export var EXPAND_TIME_ENTRIES_REQUEST = 'timecrowd/time_entries/EXPAND_TIME_ENTRIES_REQUEST';
export var EXPAND_TIME_ENTRIES_SUCCESS = 'timecrowd/time_entries/EXPAND_TIME_ENTRIES_SUCCESS';
export var EXPAND_TIME_ENTRIES_FAILED = 'timecrowd/time_entries/EXPAND_TIME_ENTRIES_FAILED';
export var ADD_TIME_ENTRY_SUCCESS = 'timecrowd/time_entries/ADD_TIME_ENTRY_SUCCESS';
export var ADD_TIME_ENTRY_FAILED = 'timecrowd/time_entries/ADD_TIME_ENTRY_FAILED';
export var CHANGE_TIME_ENTRY_SUCCESS = 'timecrowd/time_entries/CHANGE_TIME_ENTRY_SUCCESS';
export var CHANGE_TIME_ENTRY_FAILED = 'timecrowd/time_entries/CHANGE_TIME_ENTRY_FAILED';
export var DELETE_TIME_ENTRY_SUCCESS = 'timecrowd/time_entries/DELETE_TIME_ENTRY_SUCCESS';
export var DELETE_TIME_ENTRY_FAILED = 'timecrowd/time_entries/DELETE_TIME_ENTRY_FAILED';
export var FETCH_DAILY_TIME_ENTRIES_REQUEST = 'timecrowd/time_entries/FETCH_DAILY_TIME_ENTRIES_REQUEST';
export var FETCH_DAILY_TIME_ENTRIES_SUCCESS = 'timecrowd/time_entries/FETCH_DAILY_TIME_ENTRIES_SUCCESS';
export var FETCH_DAILY_TIME_ENTRIES_FAILED = 'timecrowd/time_entries/FETCH_DAILY_TIME_ENTRIES_FAILED';
export var initialState = {
    isLoading: false,
    isFetched: false,
    items: [],
    next: null,
};
function addTimeEntry(state, action) {
    var timeEntries = state.items.concat(action.timeEntry);
    return __assign(__assign({}, state), { items: orderBy(timeEntries, [function (timeEntry) { return new Date(timeEntry.started_at); }], ['desc']) });
}
function changeTimeEntry(state, action) {
    var timeEntries = state.items.map(function (timeEntry) {
        if (timeEntry.id === action.timeEntry.id) {
            return action.timeEntry;
        }
        else {
            return timeEntry;
        }
    });
    return __assign(__assign({}, state), { items: orderBy(timeEntries, [function (timeEntry) { return new Date(timeEntry.started_at); }], ['desc']) });
}
function deleteTimeEntry(state, action) {
    var timeEntries = state.items.filter(function (timeEntry) {
        return timeEntry.id !== action.id;
    });
    return __assign(__assign({}, state), { items: timeEntries });
}
export default function timeEntries(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_TIME_ENTRIES_REQUEST:
        case FETCH_TEAM_TIME_ENTRIES_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isFetched: false, items: [], next: null });
        case FETCH_TIME_ENTRIES_SUCCESS:
        case FETCH_TEAM_TIME_ENTRIES_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, isFetched: true, items: action.timeEntries, next: action.next });
        case FETCH_TIME_ENTRIES_FAILED:
        case FETCH_TEAM_TIME_ENTRIES_FAILED:
            return initialState;
        case EXPAND_TIME_ENTRIES_REQUEST:
        case FETCH_DAILY_TIME_ENTRIES_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case EXPAND_TIME_ENTRIES_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, items: state.items.concat(action.timeEntries), next: action.next });
        case FETCH_DAILY_TIME_ENTRIES_SUCCESS: {
            var fetchedIds_1 = action.timeEntries.map(function (_a) {
                var id = _a.id;
                return id;
            });
            return __assign(__assign({}, state), { isLoading: false, items: __spreadArray(__spreadArray([], state.items.filter(function (_a) {
                    var id = _a.id;
                    return !fetchedIds_1.includes(id);
                }), true), action.timeEntries, true) });
        }
        case EXPAND_TIME_ENTRIES_FAILED:
        case FETCH_DAILY_TIME_ENTRIES_FAILED:
            return __assign(__assign({}, state), { isLoading: false });
        case ADD_TIME_ENTRY_SUCCESS:
            return addTimeEntry(state, action);
        case CHANGE_TIME_ENTRY_SUCCESS:
            return changeTimeEntry(state, action);
        case DELETE_TIME_ENTRY_SUCCESS:
            return deleteTimeEntry(state, action);
        case ADD_TIME_ENTRY_FAILED:
        case CHANGE_TIME_ENTRY_FAILED:
        case DELETE_TIME_ENTRY_FAILED:
            return state;
        default:
            return state;
    }
}
export var fetchTimeEntriesRequest = function () { return ({
    type: FETCH_TIME_ENTRIES_REQUEST,
}); };
export var fetchTimeEntriesSuccess = function (timeEntries, next) { return ({
    type: FETCH_TIME_ENTRIES_SUCCESS,
    timeEntries: timeEntries,
    next: next,
}); };
export var fetchTimeEntriesFailed = function () { return ({
    type: FETCH_TIME_ENTRIES_FAILED,
}); };
export var fetchTeamTimeEntriesRequest = function () { return ({
    type: FETCH_TEAM_TIME_ENTRIES_REQUEST,
}); };
export var fetchTeamTimeEntriesSuccess = function (timeEntries, next) { return ({
    type: FETCH_TEAM_TIME_ENTRIES_SUCCESS,
    timeEntries: timeEntries,
    next: next,
}); };
export var fetchTeamTimeEntriesFailed = function () { return ({
    type: FETCH_TEAM_TIME_ENTRIES_FAILED,
}); };
export var expandTimeEntriesRequest = function () { return ({
    type: EXPAND_TIME_ENTRIES_REQUEST,
}); };
export var expandTimeEntriesSuccess = function (timeEntries, next) { return ({
    type: EXPAND_TIME_ENTRIES_SUCCESS,
    timeEntries: timeEntries,
    next: next,
}); };
export var addTimeEntrySuccess = function (timeEntry) { return ({
    type: ADD_TIME_ENTRY_SUCCESS,
    timeEntry: timeEntry,
}); };
export var addTimeEntryFailed = function () { return ({
    type: ADD_TIME_ENTRY_FAILED,
}); };
export var changeTimeEntrySuccess = function (timeEntry) { return ({
    type: CHANGE_TIME_ENTRY_SUCCESS,
    timeEntry: timeEntry,
}); };
export var changeTimeEntryFailed = function () { return ({
    type: CHANGE_TIME_ENTRY_FAILED,
}); };
export var deleteTimeEntrySuccess = function (id) { return ({
    type: DELETE_TIME_ENTRY_SUCCESS,
    id: id,
}); };
export var deleteTimeEntryFailed = function () { return ({
    type: DELETE_TIME_ENTRY_FAILED,
}); };
export var expandTimeEntriesFailed = function () { return ({
    type: EXPAND_TIME_ENTRIES_FAILED,
}); };
export var fetchDailyTimeEntriesRequest = function () { return ({
    type: FETCH_DAILY_TIME_ENTRIES_REQUEST,
}); };
export var fetchDailyTimeEntriesSuccess = function (timeEntries) { return ({
    type: FETCH_DAILY_TIME_ENTRIES_SUCCESS,
    timeEntries: timeEntries,
}); };
export var fetchDailyTimeEntriesFailed = function () { return ({
    type: FETCH_DAILY_TIME_ENTRIES_FAILED,
}); };
export var fetchTimeEntries = function (params) {
    if (params === void 0) { params = {}; }
    var _query = queryString.stringify(params);
    var query = _query ? "?".concat(_query) : '';
    return function (dispatch, getState) {
        dispatch(fetchTimeEntriesRequest());
        return api2(dispatch, getState, "/api/v2/time_entries".concat(query), 'GET').then(function (response) {
            if (response.ok) {
                var next_1 = getNextLink(response);
                response.json().then(function (timeEntries) {
                    dispatch(fetchTimeEntriesSuccess(timeEntries, next_1));
                });
            }
            else {
                dispatch(fetchTimeEntriesFailed());
            }
        });
    };
};
export var expandTimeEntries = function (next) {
    return function (dispatch, getState) {
        dispatch(expandTimeEntriesRequest());
        return api2(dispatch, getState, next, 'GET').then(function (response) {
            if (response.ok) {
                var next_2 = getNextLink(response);
                response.json().then(function (timeEntries) {
                    dispatch(expandTimeEntriesSuccess(timeEntries, next_2));
                });
            }
            else {
                dispatch(expandTimeEntriesFailed());
            }
        });
    };
};
export var fetchTeamTimeEntries = function (teamId, params) {
    if (params === void 0) { params = {}; }
    var _query = queryString.stringify(params);
    var query = _query ? "?".concat(_query) : '';
    return function (dispatch, getState) {
        dispatch(fetchTeamTimeEntriesRequest());
        return api2(dispatch, getState, "/api/v2/teams/".concat(teamId, "/time_entries").concat(query), 'GET').then(function (response) {
            if (response.ok) {
                var next_3 = getNextLink(response);
                response.json().then(function (timeEntries) {
                    dispatch(fetchTeamTimeEntriesSuccess(timeEntries, next_3));
                });
            }
            else {
                dispatch(fetchTeamTimeEntriesFailed());
            }
        });
    };
};
export var createTimeEntry = function (body) {
    return function (dispatch, getState) {
        return api2(dispatch, getState, "/api/v2/time_entries", 'POST', body).then(function (response) {
            if (response.ok) {
                return response.json().then(function (timeEntry) {
                    dispatch(addTimeEntrySuccess(timeEntry));
                    return timeEntry;
                });
            }
            else {
                dispatch(addTimeEntryFailed());
                return { error: true };
            }
        });
    };
};
export var createUserTimeEntry = function (userId, body) {
    return function (dispatch, getState) {
        return api2(dispatch, getState, "/api/v2/users/".concat(userId, "/time_entries"), 'POST', body).then(function (response) {
            if (response.ok) {
                return response.json().then(function (timeEntry) {
                    dispatch(addTimeEntrySuccess(timeEntry));
                    return timeEntry;
                });
            }
            else {
                dispatch(addTimeEntryFailed());
                return { error: true };
            }
        });
    };
};
export var updateTimeEntry = function (id, body) {
    return function (dispatch, getState) {
        return api2(dispatch, getState, "/api/v2/time_entries/".concat(id), 'PATCH', body).then(function (response) {
            if (response.ok) {
                return response.json().then(function (timeEntry) {
                    dispatch(changeTimeEntrySuccess(timeEntry));
                    dispatch(clearTimeEntrySuggestion(id));
                    return timeEntry;
                });
            }
            else {
                dispatch(changeTimeEntryFailed());
                return { error: true };
            }
        });
    };
};
export var upsertTimeEntryComment = function (id, body) {
    return function (dispatch, getState) {
        return api2(dispatch, getState, "/api/v2/time_entries/".concat(id, "/comment"), 'POST', body).then(function (response) {
            if (response.ok) {
                return response.json().then(function (timeEntry) {
                    dispatch(changeTimeEntrySuccess(timeEntry));
                    return timeEntry;
                });
            }
            else {
                dispatch(changeTimeEntryFailed());
                return { error: true };
            }
        });
    };
};
export var destroyTimeEntry = function (id) {
    return function (dispatch, getState) {
        return api2(dispatch, getState, "/api/v2/time_entries/".concat(id), 'DELETE').then(function (response) {
            if (response.ok) {
                dispatch(deleteTimeEntrySuccess(id));
                dispatch(clearTimeEntrySuggestion(id));
                return { error: false };
            }
            else {
                dispatch(deleteTimeEntryFailed());
                return { error: true };
            }
        });
    };
};
export var fetchDailyTimeEntries = function (teamId, taskId, startedSince, startedUntil) {
    return function (dispatch, getState) {
        dispatch(fetchDailyTimeEntriesRequest());
        var user = getState().user;
        return api2(dispatch, getState, "/api/v2/teams/".concat(teamId, "/time_entries?user_id=").concat(user.id, "&task_id=").concat(taskId, "&started_since=").concat(startedSince.getTime() / 1000, "&started_until=").concat(startedUntil.getTime() / 1000, "&per_page=100"), 'GET').then(function (response) {
            if (response.ok) {
                response.json().then(function (timeEntries) {
                    dispatch(fetchDailyTimeEntriesSuccess(timeEntries));
                });
            }
            else {
                dispatch(fetchDailyTimeEntriesFailed());
            }
        });
    };
};
