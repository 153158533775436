export var setUser = function (user) { return ({
    type: 'SET_USER',
    user: user,
}); };
export var setWorkingUsersCount = function (workingUsersCount) { return ({
    type: 'SET_WORKING_USERS_COUNT',
    workingUsersCount: workingUsersCount,
}); };
export var setMemberSummaries = function (memberSummaries) { return ({
    type: 'SET_MEMBER_SUMMARIES',
    memberSummaries: memberSummaries,
}); };
export var setTimeZones = function (timeZones) { return ({
    type: 'SET_TIME_ZONES',
    timeZones: timeZones,
}); };
export var setCalendarZoomRate = function (zoomRate) { return ({
    type: 'SET_CALENDAR_ZOOM_RATE',
    zoomRate: zoomRate,
}); };
