import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import I18n from '@/constants/i18n';
import OmitLabel from '@/components/atoms/omit_label';
import { MultiUsersSelectList } from './multi_users_select_list';
export var MultiUsersSelectDropdown = function (props) {
    var allUsers = props.allUsers, _a = props.disabled, disabled = _a === void 0 ? false : _a, leavedUserIds = props.leavedUserIds, selectedUsers = props.selectedUsers, workedUserIdSet = props.workedUserIdSet, onApply = props.onApply;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var handleToggle = function () {
        setIsOpen(!isOpen);
    };
    var handleApply = function (users) {
        onApply(users);
        setIsOpen(false);
    };
    return (<Dropdown isOpen={isOpen} toggle={handleToggle}>
      <DropdownToggle caret className={disabled ? '' : 'text-secondary'} color="dark" disabled={disabled}>
        {selectedUsers.length < 1 ? (I18n.t('shared.all')) : (<>
            <OmitLabel maxWidth="13rem">{selectedUsers[0].nickname}</OmitLabel>
            {selectedUsers.length > 1 && " + ".concat(selectedUsers.length - 1)}
          </>)}
      </DropdownToggle>
      <DropdownMenu className="bg-white max-w-lg overflow-hidden p-0">
        <div className="d-flex flex-column max-h-[80vh]">
          <MultiUsersSelectList allUsers={allUsers} leavedUserIds={leavedUserIds} selectedUsers={selectedUsers} workedUserIdSet={workedUserIdSet} onApply={handleApply}/>
        </div>
      </DropdownMenu>
    </Dropdown>);
};
