import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { Link, useLocation } from 'react-router-dom';
import I18n from '@/constants/i18n';
import classNames from 'classnames';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import Duration from '@/components/atoms/duration';
import Icon from '@/components/atoms/icon';
import { CategoryInfoContainer } from '@/containers/category_info_container';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
export var TaskFormTabMenu = function (props) {
    var mode = props.mode, category = props.category, timeEntry = props.timeEntry, readOnly = props.readOnly, archived = props.archived, canEditTimeEntry = props.canEditTimeEntry, isOpenTaskSuggestion = props.isOpenTaskSuggestion, isTimeEntrySpecified = props.isTimeEntrySpecified, taskId = props.taskId, taskTitle = props.taskTitle, tab = props.tab, toggleTab = props.toggleTab;
    var location = useLocation();
    var isShowTaskLink = mode === 'update' && !/tasks\/\d+\/edit/.test(location.pathname);
    return (<div className="composer-options ml-1 mb-1">
      {!readOnly && !archived && (<div className={classNames('composer-option-toggle category-composer-option-toggle mr-1', {
                active: tab === 'category',
            })} onClick={function () {
                toggleTab('category');
            }}>
          <WorkspaceAvatarContainer className="mr-1 ml-n1 grayscale" workspaceId={category.team.workspaces_workspace_id}/>
          {category.id ? (<>
              <CategoryCircleIcon color={category.color}/>
              <span className="text-overflow">
                {category.title} - {category.team.name}
              </span>
              <CategoryInfoContainer category={category} className="mx-1"/>
            </>) : (I18n.t('shared.pleaseSelectCategory'))}
          <Icon className="ml-auto" color="secondary" name={tab === 'category' && !isOpenTaskSuggestion
                ? 'chevron-up'
                : 'chevron-down'} size="md"/>
          {tab === 'category' && <i className="arrow-block-up"/>}
        </div>)}
      {canEditTimeEntry && (<div className={classNames('composer-option-toggle schedule-composer-option-toggle', {
                active: tab === 'schedule',
            })} onClick={function () {
                toggleTab('schedule');
            }}>
          <Icon className="mr-1" name="clock"/>
          {isTimeEntrySpecified && (<Duration seconds={Math.floor(moment(timeEntry.stopped_at).diff(moment(timeEntry.started_at), 'seconds'))}/>)}
          {!isTimeEntrySpecified && (<span className="text-muted">
              {I18n.t('quicktask.withoutTimeEntry')}
            </span>)}
          <Icon className={classNames('ml-auto', {
                'd-none': mode === 'update',
                'd-block': mode !== 'update',
            })} color="secondary" name={tab === 'schedule' && !isOpenTaskSuggestion
                ? 'chevron-up'
                : 'chevron-down'} size="md"/>
          {tab === 'schedule' && <i className="arrow-block-up"/>}
        </div>)}
      {isShowTaskLink && (<UncontrolledDropdown className="ml-auto mr-1">
          <DropdownToggle className="btn-link text-reset">
            <Icon name="dots-3"/>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <Link className="text-reset d-block" to={"/app/mytasks/".concat(taskId, "/edit")}>
                {I18n.t('shared.editNamedTask', {
                name: taskTitle,
            })}
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>)}
    </div>);
};
