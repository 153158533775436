import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';
import I18n from '@/constants/i18n';
import LoadingSpinner from '@/components/atoms/loading_spinner';
export var PeriodSelectDropdown = function (_a) {
    var isOpen = _a.isOpen, hasError = _a.hasError, onToggleDropdown = _a.onToggleDropdown, onSelectPeriod = _a.onSelectPeriod, onSelectDate = _a.onSelectDate, selectedPeriod = _a.selectedPeriod, periods = _a.periods, locale = _a.locale;
    return (<ButtonDropdown className="d-flex" isOpen={isOpen} size="lg" toggle={onToggleDropdown}>
      <DropdownToggle caret className="">
        {selectedPeriod ? (selectedPeriod.label) : hasError ? ('') : (<LoadingSpinner />)}
      </DropdownToggle>
      <DropdownMenu right>
        {periods.map(function (period) { return (<DropdownItem key={period.label} onClick={function () { return onSelectPeriod(period); }}>
            {period.label}{' '}
            <small>
              {moment(period.from).locale(locale).format('l')} -{' '}
              {moment(period.to).locale(locale).format('l')}
            </small>
          </DropdownItem>); })}
        <InfiniteCalendar autoFocus={false} Component={withRange(Calendar)} displayOptions={{
            shouldHeaderAnimate: false,
            hideYearsOnSelect: false,
        }} locale={I18n.t('infiniteCalendar')} selected={{
            start: selectedPeriod ? new Date(selectedPeriod.from) : new Date(),
            end: selectedPeriod ? new Date(selectedPeriod.to) : new Date(),
        }} tabIndex={7} theme={{
            floatingNav: {
                chevron: '#fff',
            },
        }} width="100%" onSelect={onSelectDate}/>
      </DropdownMenu>
    </ButtonDropdown>);
};
