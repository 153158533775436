import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var CustomFieldsMasterDeleteModal = function (_a) {
    var customFieldsMaster = _a.customFieldsMaster, isOpen = _a.isOpen, isDeleting = _a.isDeleting, onCancel = _a.onCancel, onDelete = _a.onDelete;
    var _b = React.useState(false), isConfirmed = _b[0], setIsConfirmed = _b[1];
    var onConfirm = React.useCallback(function () {
        setIsConfirmed(!isConfirmed);
    }, [isConfirmed]);
    var handleClose = React.useCallback(function () {
        setIsConfirmed(false);
        onCancel();
    }, [onCancel]);
    return (<Modal isOpen={isOpen} toggle={handleClose}>
      <ModalHeaderMod toggle={handleClose}>
        {I18n.t('shared.deleteCustomField')}
      </ModalHeaderMod>
      <ModalBody className="text-break">
        {I18n.t('shared.deleteCustomFieldDescription')}
        <p className="mt-3 h3">{customFieldsMaster.name}</p>
        <CustomCheck checked={isConfirmed} className="mt-3" onChange={onConfirm}>
          <span className="text-danger font-weight-bold">
            {I18n.t('shared.deleteCustomFieldConfirm')}
          </span>
        </CustomCheck>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <button className="btn btn-danger" disabled={!isConfirmed || isDeleting} onClick={onDelete}>
          {I18n.t('shared.delete')}
        </button>

        <button className="btn btn-secondary" disabled={isDeleting} onClick={handleClose}>
          {I18n.t('shared.cancel')}
        </button>
      </ModalFooter>
    </Modal>);
};
