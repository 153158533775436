import React from 'react';
import Icon from '@/components/atoms/icon';
export var SortButton = function (_a) {
    var sortBy = _a.sortBy, currentSortBy = _a.currentSortBy, currentSortOrder = _a.currentSortOrder, setOrder = _a.setOrder, setTarget = _a.setTarget;
    var iconName = currentSortBy === sortBy && currentSortOrder === 'asc'
        ? 'chevron-up'
        : 'chevron-down';
    return (<button className="btn btn-sm btn-light btn-circle ml-1" type="button" onClick={function () {
            setTarget(sortBy);
            setOrder(currentSortBy === sortBy && currentSortOrder === 'asc'
                ? 'desc'
                : 'asc');
        }}>
      <Icon name={iconName} size="sm"/>
    </button>);
};
