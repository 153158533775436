import { __assign, __awaiter, __generator } from "tslib";
import moment from 'moment'; // eslint-disable-line
import uniqBy from 'lodash/uniqBy';
import api from '@/api';
import api2 from '@/api2';
import queryString from 'query-string';
import { setCurrentEntry } from '@/modules/current_entry';
import { setCalendarZoomRate } from '@/actions/setter';
import variants from '@/constants/variants';
import { updateStateIfChanged } from '@/modules/helpers';
export var FETCH_CALENDAR_REQUEST = 'timecrowd/calendar/FETCH_CALENDAR_REQUEST';
export var FETCH_CALENDAR_SUCCESS = 'timecrowd/calendar/FETCH_CALENDAR_SUCCESS';
export var FETCH_CALENDAR_FAILED = 'timecrowd/calendar/FETCH_CALENDAR_FAILED';
export var RESET_CALENDAR = 'timecrowd/calendar/RESET_CALENDAR';
export var UPDATE_CALENDAR_ZOOM_RATE_REQUEST = 'timecrowd/calendar/UPDATE_CALENDAR_ZOOM_RATE_REQUEST';
export var UPDATE_CALENDAR_ZOOM_RATE_SUCCESS = 'timecrowd/calendar/UPDATE_CALENDAR_ZOOM_RATE_SUCCESS';
export var UPDATE_CALENDAR_ZOOM_RATE_FAILED = 'timecrowd/calendar/UPDATE_CALENDAR_ZOOM_RATE_FAILED';
export var SET_CALENDAR_VIEW = 'timecrowd/calendar/SET_CALENDAR_VIEW';
export var SET_IS_SHOW_CATEGORY_ON_WEEKLY_CALENDAR = 'timecrowd/calendar/SET_IS_SHOW_CATEGORY_ON_WEEKLY_CALENDAR';
export var IS_SHOW_CATEGORY_ON_WEEKLY_CALENDAR_KEY = 'IS_SHOW_CATEGORY_ON_WEEKLY_CALENDAR_KEY';
export var SET_CALENDAR_UNIT_MINUTES = 'timecrowd/calendar/SET_CALENDAR_UNIT_MINUTES';
export var CALENDAR_UNIT_MINUTES = [15, 5];
export var CALENDAR_ZOOM_RATES = Array.from({ length: 10 }, function (_, i) { return i + 1; });
export var fetchCalendar = function (params) {
    if (params === void 0) { params = {}; }
    return function (dispatch, getState) {
        var _a;
        var _b = getState(), view = _b.calendar.view, user = _b.user;
        var query = queryString.stringify(__assign({ view: view }, params));
        dispatch(fetchCalendarRequest(parseInt((_a = params.user_id) !== null && _a !== void 0 ? _a : user.id)));
        return api2(dispatch, getState, "/api/v2/calendar?".concat(query), 'GET').then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    dispatch(fetchCalendarSuccess(json));
                });
            }
            else {
                dispatch(fetchCalendarFailed());
            }
        });
    };
};
export var updateCalendarZoomRate = function (zoomRate) {
    return function (dispatch, getState) {
        return api2(dispatch, getState, '/api/v2/users/calendar_zoom_rate', 'PUT', {
            zoom_rate: zoomRate,
        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        _a = dispatch;
                        _b = setCalendarZoomRate;
                        return [4 /*yield*/, response.json()];
                    case 1:
                        _a.apply(void 0, [_b.apply(void 0, [(_c.sent()).calendar_zoom_rate])]);
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
};
export var destroyTimelineTimeEntry = function (id) {
    return function (dispatch, getState) {
        var currentEntry = getState().currentEntry;
        return api(dispatch, getState, "time_entries/".concat(id), 'DELETE').then(function (_a) {
            var error = _a.error;
            if (error) {
                return { error: error };
            }
            if (currentEntry.current && id === currentEntry.current.id) {
                dispatch(setCurrentEntry(null));
            }
            return { error: false };
        });
    };
};
// NOTE: スイープラインアルゴリズムを使って重複を検出する
export var collectOverlaps = function (timeEntries) {
    // NOTE: 日跨いだデータを保持している関係上、同じIDのデータが複数存在する場合があるため、IDでユニークにする
    //       分単位で開始時間と停止時間が同一になる場合に重複ではないと判定されないよう降順で並べる
    var sortedTimeEntries = uniqBy(timeEntries, 'id').sort(function (a, b) {
        var _a;
        if (a.started_at === b.started_at) {
            return (_a = b.stopped_at) === null || _a === void 0 ? void 0 : _a.localeCompare(a.stopped_at);
        }
        return a.started_at.localeCompare(b.started_at);
    });
    var START = 'start';
    var STOP = 'stop';
    var events = sortedTimeEntries.reduce(function (acc, timeEntry) {
        var startedAt = moment(timeEntry.started_at).startOf('minute');
        var stoppedAt = moment(timeEntry.stopped_at).startOf('minute');
        acc.push({
            id: timeEntry.id,
            time: startedAt,
            type: START,
        });
        if (timeEntry.stopped_at) {
            acc.push({
                id: timeEntry.id,
                time: stoppedAt,
                type: STOP,
            });
        }
        return acc;
    }, []);
    events.sort(function (a, b) {
        return a.time.unix() - b.time.unix();
    });
    var activeRanges = new Set();
    var overlaps = {};
    // スイープライン処理
    events.forEach(function (event) {
        if (event.type === START) {
            // 現在アクティブな範囲と重複を確認
            activeRanges.forEach(function (activeId) {
                overlaps[activeId] = true;
                overlaps[event.id] = true;
            });
            // 現在の範囲をアクティブに追加
            activeRanges.add(event.id);
        }
        else if (event.type === STOP) {
            // アクティブリストから削除
            activeRanges.delete(event.id);
        }
    });
    return overlaps;
};
var createOverlaps = function (items) {
    var timeEntries = items.reduce(function (acc, _a) {
        var time_entries = _a.time_entries;
        return acc.concat(time_entries);
    }, []);
    return collectOverlaps(timeEntries);
};
export var initialState = {
    view: 'daily',
    items: [],
    loading: false,
    userId: null,
    isShowCategoryOnWeeklyCalendar: false,
    unitMinutes: CALENDAR_UNIT_MINUTES[0],
    overlaps: {},
    user: null,
};
export default function calendar(state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CALENDAR_REQUEST:
            return __assign(__assign({}, state), { loading: true, userId: action.userId });
        case FETCH_CALENDAR_SUCCESS: {
            var items = action.items.filter(function (_a) {
                var user = _a.user;
                return user.id === state.userId;
            });
            return __assign(__assign({}, state), { items: items, loading: false, overlaps: createOverlaps(items), user: (_b = (_a = action.items.find(function (_a) {
                    var user = _a.user;
                    return user.id === state.userId;
                })) === null || _a === void 0 ? void 0 : _a.user) !== null && _b !== void 0 ? _b : null });
        }
        case SET_CALENDAR_VIEW:
            return __assign(__assign({}, state), { view: action.view });
        case SET_IS_SHOW_CATEGORY_ON_WEEKLY_CALENDAR:
            return updateStateIfChanged(state, {
                isShowCategoryOnWeeklyCalendar: action.isShowCategoryOnWeeklyCalendar,
            });
        case SET_CALENDAR_UNIT_MINUTES:
            return updateStateIfChanged(state, { unitMinutes: action.unitMinutes });
        case FETCH_CALENDAR_FAILED:
        case RESET_CALENDAR:
            return __assign(__assign({}, initialState), { view: state.view });
        default:
            return state;
    }
}
export var fetchCalendarRequest = function (userId) { return ({
    type: FETCH_CALENDAR_REQUEST,
    userId: userId,
}); };
export var fetchCalendarSuccess = function (items) { return ({
    type: FETCH_CALENDAR_SUCCESS,
    items: items,
}); };
export var fetchCalendarFailed = function () { return ({
    type: FETCH_CALENDAR_FAILED,
}); };
export var resetCalendar = function () { return ({
    type: RESET_CALENDAR,
}); };
export var setCalendarView = function (view) { return ({
    type: SET_CALENDAR_VIEW,
    view: view,
}); };
export var setIsShowCategoryOnWeeklyCalendar = function (isShowCategoryOnWeeklyCalendar) { return ({
    type: SET_IS_SHOW_CATEGORY_ON_WEEKLY_CALENDAR,
    isShowCategoryOnWeeklyCalendar: isShowCategoryOnWeeklyCalendar,
}); };
export var loadIsShowCategoryOnWeeklyCalendar = function (loadStorage) {
    if (loadStorage === void 0) { loadStorage = variants.loadStorage; }
    return function (dispatch) {
        loadStorage(IS_SHOW_CATEGORY_ON_WEEKLY_CALENDAR_KEY, function (isShowCategoryOnWeeklyCalendar) {
            dispatch(setIsShowCategoryOnWeeklyCalendar(isShowCategoryOnWeeklyCalendar));
        });
    };
};
export var saveIsShowCategoryOnWeeklyCalendar = function (isShowCategoryOnWeeklyCalendar, saveStorage) {
    if (saveStorage === void 0) { saveStorage = variants.saveStorage; }
    return function (dispatch) {
        saveStorage(IS_SHOW_CATEGORY_ON_WEEKLY_CALENDAR_KEY, isShowCategoryOnWeeklyCalendar);
        dispatch(setIsShowCategoryOnWeeklyCalendar(isShowCategoryOnWeeklyCalendar));
    };
};
export var setCalendarUnitMinutes = function (unitMinutes) { return ({
    type: SET_CALENDAR_UNIT_MINUTES,
    unitMinutes: unitMinutes,
}); };
export var loadCalendarUnitMinutes = function (loadStorage) {
    if (loadStorage === void 0) { loadStorage = variants.loadStorage; }
    return function (dispatch) {
        loadStorage('CALENDAR_UNIT_MINUTES', function (unitMinutes) {
            if (!unitMinutes) {
                return;
            }
            dispatch(setCalendarUnitMinutes(unitMinutes));
        });
    };
};
export var saveCalendarUnitMinutes = function (unitMinutes, saveStorage) {
    if (saveStorage === void 0) { saveStorage = variants.saveStorage; }
    return function (dispatch) {
        saveStorage('CALENDAR_UNIT_MINUTES', unitMinutes);
        dispatch(setCalendarUnitMinutes(unitMinutes));
    };
};
export var minimumZoomRate = function (isShowCategoryOnWeeklyCalendar, unitMinutes) {
    return ((CALENDAR_UNIT_MINUTES[0] / unitMinutes) *
        (isShowCategoryOnWeeklyCalendar ? 2 : 1));
};
