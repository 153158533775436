import React from 'react';
import QuickTask from '@/components/organisms/quick_task';
import TaskListContainer from '@/containers/task_list_container';
import Blank from '@/components/molecules/blank';
import I18n from '@/constants/i18n';
var MyTask = function (_a) {
    var fetchCurrentUser = _a.fetchCurrentUser, fetchDailyActivity = _a.fetchDailyActivity, resetDailyActivities = _a.resetDailyActivities, _b = _a.dailyActivities, tasks = _b.tasks, activities = _b.activities, currentEntry = _a.currentEntry, stopTimeEntry = _a.stopTimeEntry, setCurrentEntry = _a.setCurrentEntry, fetchWorkingUsersCount = _a.fetchWorkingUsersCount, updateDailyActivity = _a.updateDailyActivity, switchViewMode = _a.switchViewMode, isPC = _a.isPC, isChromeExtension = _a.isChromeExtension, myTask = _a.myTask, startTask = _a.startTask, setCurrentCategory = _a.setCurrentCategory, arrangeRecentCategories = _a.arrangeRecentCategories, updateTask = _a.updateTask, restoreTask = _a.restoreTask, isMobile = _a.isMobile, isApp = _a.isApp, user = _a.user, expandDailyActivity = _a.expandDailyActivity, dailyActivities = _a.dailyActivities, setActiveTaskForm = _a.setActiveTaskForm;
    React.useEffect(function () {
        // NOTE: 打刻中にタイトル変更されたケースに対応するために取得
        //       再度取得するのではなく、reducerを更新して対応したい
        //       https://trello.com/c/rUe3PdhP
        fetchCurrentUser();
        fetchDailyActivity();
        window.scrollTo(0, 0);
        return function () {
            resetDailyActivities();
        };
    }, [fetchCurrentUser, fetchDailyActivity, resetDailyActivities]);
    var isNothing = tasks.length === 0 &&
        !!activities.length &&
        activities[activities.length - 1].next_date === null;
    return (<div className="content-main">
      <QuickTask currentEntry={currentEntry} fetchWorkingUsersCount={fetchWorkingUsersCount} isChromeExtension={isChromeExtension} isPC={isPC} myTask={myTask} setActiveTaskForm={setActiveTaskForm} stopTimeEntry={stopTimeEntry} switchViewMode={switchViewMode} updateDailyActivity={updateDailyActivity}/>
      <div className="row no-gutters with-toolbar with-bottom-bar-only-mobile-app">
        {isNothing && (<Blank description={I18n.t('mytask.blankDescriptipn')} title={I18n.t('mytask.blankTitle')}/>)}
        {!isNothing && (<TaskListContainer activities={activities} arrangeRecentCategories={arrangeRecentCategories} currentEntry={currentEntry} dailyActivities={dailyActivities} expandDailyActivity={expandDailyActivity} fetchWorkingUsersCount={fetchWorkingUsersCount} isApp={isApp} isMobile={isMobile} restoreTask={restoreTask} setCurrentCategory={setCurrentCategory} setCurrentEntry={setCurrentEntry} startTask={startTask} stopTimeEntry={stopTimeEntry} updateDailyActivity={updateDailyActivity} updateTask={updateTask} user={user}/>)}
      </div>
    </div>);
};
export default MyTask;
