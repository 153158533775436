import React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from '@/components/pages/loading_page';
import NotFound from '@/components/pages/not_found';
import { workspaceTeams } from '@/utils/workspace_helper';
var Payment = function (props) {
    var teams = props.teams, isFetched = props.isFetched;
    var _a = React.useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var team_id = useParams().team_id;
    var navigate = useNavigate();
    React.useEffect(function () {
        if (!isFetched) {
            return;
        }
        var teamId = Number(team_id);
        var team = teams.find(function (team) { return team.id === teamId; });
        if (!team) {
            setIsLoading(false);
            return;
        }
        navigate("/app/workspaces/".concat(team.workspaces_workspace_id, "/plan"));
    }, [team_id, navigate, isFetched, teams]);
    if (isLoading) {
        return <LoadingPage />;
    }
    var teamId = Number(team_id);
    var team = teams.find(function (team) { return team.id === teamId; });
    if (!team || (team && (!team.can_manage || team.personal || team.ancestry))) {
        return <NotFound />;
    }
    return null;
};
var mapStateToProps = function (state) { return ({
    teams: workspaceTeams(state.teams.items),
    isFetched: state.teams.isFetched,
}); };
export default connect(mapStateToProps)(Payment);
