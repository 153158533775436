import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import api2 from '@/api2';
export var FETCH_REMINDER_EMAILS_SETTINGS_REQUEST = 'timecrowd/REMINDER_EMAILS_SETTINGS/FETCH_REMINDER_EMAILS_SETTINGS_REQUEST';
export var FETCH_REMINDER_EMAILS_SETTINGS_SUCCESS = 'timecrowd/REMINDER_EMAILS_SETTINGS/FETCH_REMINDER_EMAILS_SETTINGS_SUCCESS';
export var FETCH_REMINDER_EMAILS_SETTINGS_FAILURE = 'timecrowd/REMINDER_EMAILS_SETTINGS/FETCH_REMINDER_EMAILS_SETTINGS_FAILURE';
export var CREATE_REMINDER_EMAILS_SETTING_REQUEST = 'timecrowd/REMINDER_EMAILS_SETTINGS/CREATE_REMINDER_EMAILS_SETTING_REQUEST';
export var CREATE_REMINDER_EMAILS_SETTING_SUCCESS = 'timecrowd/REMINDER_EMAILS_SETTINGS/CREATE_REMINDER_EMAILS_SETTING_SUCCESS';
export var CREATE_REMINDER_EMAILS_SETTING_FAILURE = 'timecrowd/REMINDER_EMAILS_SETTINGS/CREATE_REMINDER_EMAILS_SETTING_FAILURE';
export var UPDATE_REMINDER_EMAILS_SETTING_REQUEST = 'timecrowd/REMINDER_EMAILS_SETTINGS/UPDATE_REMINDER_EMAILS_SETTING_REQUEST';
export var UPDATE_REMINDER_EMAILS_SETTING_SUCCESS = 'timecrowd/REMINDER_EMAILS_SETTINGS/UPDATE_REMINDER_EMAILS_SETTING_SUCCESS';
export var UPDATE_REMINDER_EMAILS_SETTING_FAILURE = 'timecrowd/REMINDER_EMAILS_SETTINGS/UPDATE_REMINDER_EMAILS_SETTING_FAILURE';
export var DELETE_REMINDER_EMAILS_SETTING_REQUEST = 'timecrowd/REMINDER_EMAILS_SETTINGS/DELETE_REMINDER_EMAILS_SETTING_REQUEST';
export var DELETE_REMINDER_EMAILS_SETTING_SUCCESS = 'timecrowd/REMINDER_EMAILS_SETTINGS/DELETE_REMINDER_EMAILS_SETTING_SUCCESS';
export var DELETE_REMINDER_EMAILS_SETTING_FAILURE = 'timecrowd/REMINDER_EMAILS_SETTINGS/DELETE_REMINDER_EMAILS_SETTING_FAILURE';
export var initialState = {
    isLoading: false,
    items: [],
};
export default function reminderEmailsSettings(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_REMINDER_EMAILS_SETTINGS_REQUEST:
        case CREATE_REMINDER_EMAILS_SETTING_REQUEST:
        case UPDATE_REMINDER_EMAILS_SETTING_REQUEST:
        case DELETE_REMINDER_EMAILS_SETTING_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_REMINDER_EMAILS_SETTINGS_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, isLoading: false });
        case FETCH_REMINDER_EMAILS_SETTINGS_FAILURE:
        case CREATE_REMINDER_EMAILS_SETTING_FAILURE:
        case UPDATE_REMINDER_EMAILS_SETTING_FAILURE:
        case DELETE_REMINDER_EMAILS_SETTING_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case CREATE_REMINDER_EMAILS_SETTING_SUCCESS:
            return __assign(__assign({}, state), { items: __spreadArray(__spreadArray([], state.items, true), [action.payload], false), isLoading: false });
        case UPDATE_REMINDER_EMAILS_SETTING_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.map(function (item) {
                    return item.id === action.payload.id ? action.payload : item;
                }), isLoading: false });
        case DELETE_REMINDER_EMAILS_SETTING_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.filter(function (item) { return item.id !== action.payload.id; }), isLoading: false });
        default:
            return state;
    }
}
export var fetchReminderEmailsSettingsRequest = function () { return ({
    type: FETCH_REMINDER_EMAILS_SETTINGS_REQUEST,
}); };
export var fetchReminderEmailsSettingsSuccess = function (items) { return ({
    type: FETCH_REMINDER_EMAILS_SETTINGS_SUCCESS,
    payload: items,
}); };
export var fetchReminderEmailsSettingsFailure = function () { return ({
    type: FETCH_REMINDER_EMAILS_SETTINGS_FAILURE,
}); };
export var fetchReminderEmailsSettings = function (teamId) {
    return function (dispatch, getState) {
        dispatch(fetchReminderEmailsSettingsRequest());
        return api2(dispatch, getState, "/api/v2/reminder_emails/settings?team_id=".concat(teamId), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchReminderEmailsSettingsSuccess(items));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchReminderEmailsSettingsFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var createReminderEmailsSettingRequest = function () { return ({
    type: CREATE_REMINDER_EMAILS_SETTING_REQUEST,
}); };
export var createReminderEmailsSettingSuccess = function (item) { return ({
    type: CREATE_REMINDER_EMAILS_SETTING_SUCCESS,
    payload: item,
}); };
export var createReminderEmailsSettingFailure = function () { return ({
    type: CREATE_REMINDER_EMAILS_SETTING_FAILURE,
}); };
export var createReminderEmailsSetting = function (params) {
    return function (dispatch, getState) {
        dispatch(createReminderEmailsSettingRequest());
        return api2(dispatch, getState, '/api/v2/reminder_emails/settings', 'POST', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(createReminderEmailsSettingSuccess(item));
                        return [2 /*return*/, { error: false, item: item }];
                    case 2:
                        dispatch(createReminderEmailsSettingFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateReminderEmailsSettingRequest = function () { return ({
    type: UPDATE_REMINDER_EMAILS_SETTING_REQUEST,
}); };
export var updateReminderEmailsSettingSuccess = function (item) { return ({
    type: UPDATE_REMINDER_EMAILS_SETTING_SUCCESS,
    payload: item,
}); };
export var updateReminderEmailsSettingFailure = function () { return ({
    type: UPDATE_REMINDER_EMAILS_SETTING_FAILURE,
}); };
export var updateReminderEmailsSetting = function (id, params) {
    return function (dispatch, getState) {
        dispatch(updateReminderEmailsSettingRequest());
        return api2(dispatch, getState, "/api/v2/reminder_emails/settings/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(updateReminderEmailsSettingSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(updateReminderEmailsSettingFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var deleteReminderEmailsSettingRequest = function () { return ({
    type: DELETE_REMINDER_EMAILS_SETTING_REQUEST,
}); };
export var deleteReminderEmailsSettingSuccess = function (item) { return ({
    type: DELETE_REMINDER_EMAILS_SETTING_SUCCESS,
    payload: item,
}); };
export var deleteReminderEmailsSettingFailure = function () { return ({
    type: DELETE_REMINDER_EMAILS_SETTING_FAILURE,
}); };
export var deleteReminderEmailsSetting = function (id, teamId) {
    return function (dispatch, getState) {
        dispatch(deleteReminderEmailsSettingRequest());
        return api2(dispatch, getState, "/api/v2/reminder_emails/settings/".concat(id, "?team_id=").concat(teamId), 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(deleteReminderEmailsSettingSuccess({ id: id }));
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(deleteReminderEmailsSettingFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
