export var reminderTypes = [
    'general',
    'forgot_stop',
    'forgot_record',
];
export var conditionTypes = ['none', 'not_working', 'working'];
export var receivers = ['member', 'owner', 'all'];
export var days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];
