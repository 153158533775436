import React from 'react';
import classNames from 'classnames';
import { zenToHanTime, addColon, zeroPadTime, removeNonTimeCharacters, } from '@/utils/time_helper';
var TimeInput = function (props) {
    var autoFocus = props.autoFocus, onChange = props.onChange, onFocus = props.onFocus, onBlur = props.onBlur, isPC = props.isPC, isChromeExtension = props.isChromeExtension, className = props.className, disabled = props.disabled;
    var _a = React.useState(props.initTime), value = _a[0], setValue = _a[1];
    var isOpenIME = React.useRef(false);
    var inputRef = React.useRef(null);
    React.useEffect(function () {
        setValue(props.initTime);
    }, [props.initTime]);
    React.useEffect(function () {
        if (autoFocus) {
            setTimeout(function () {
                var _a;
                // @ts-ignore
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }, 0);
        }
    }, [autoFocus]);
    var handleChangeTime = function (e) {
        if (isOpenIME.current) {
            setValue(e.target.value);
            return;
        }
        var value = removeNonTimeCharacters(addColon(zenToHanTime(e.target.value).slice(0, 5)));
        setValue(value);
        onChange(value);
    };
    var handleCompositionStart = function () {
        isOpenIME.current = true;
    };
    var handleCompositionEnd = function (e) {
        isOpenIME.current = false;
        handleChangeTime(e);
    };
    var handleFocusTime = function () {
        onFocus();
    };
    var handleBlurTime = function (e) {
        var value = e.target.value;
        var paddedValue = zeroPadTime(value);
        setValue(paddedValue);
        onBlur(paddedValue);
    };
    var type = isPC || isChromeExtension ? 'text' : 'time';
    return (<input ref={inputRef} className={classNames('time-input form-control', className)} disabled={disabled} type={type} value={value} onBlur={handleBlurTime} onChange={handleChangeTime} onCompositionEnd={handleCompositionEnd} onCompositionStart={handleCompositionStart} onFocus={handleFocusTime}/>);
};
export default TimeInput;
