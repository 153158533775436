import { __assign } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import TaskForm from '@/components/task_form';
import { fetchCalendar } from '@/modules/calendar';
import { createAndStartTask, createTaskWithTimeEntry, fetchWorkingUsersCount, } from '@/actions/task';
import { arrangeRecentCategories } from '@/modules/recent_categories';
import { setCurrentCategory } from '@/modules/my_task';
import { setFromPageTitle } from '@/modules/from_page_title';
import { freezeScroll } from '@/modules/display';
import { fetchSuggestTasks } from '@/actions/task';
import { fetchTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
import { matchedCategory as findCategoryByKeyword } from '@/components/calendar_event_item/helpers';
var dateFormat = 'YYYY-MM-DD';
var TaskFormCalendarEventContainer = function (_a) {
    var hidesFromPageToggle = _a.hidesFromPageToggle, calendarIntegrationEvent = _a.calendarIntegrationEvent, className = _a.className, date = _a.date, fromPageTitle = _a.fromPageTitle, isFocus = _a.isFocus, linkUrl = _a.linkUrl, mode = _a.mode, tab = _a.tab, task = _a.task, timeEntry = _a.timeEntry, onClose = _a.onClose;
    var currentCategory = useSelector(function (state) { return state.myTask.currentCategory; });
    var isMobile = useSelector(function (state) { return state.isMobile; });
    var isApp = useSelector(function (state) { return state.isApp; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var isChromeExtension = useSelector(function (state) { return state.isChromeExtension; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var timeEntrySuggestions = useSelector(function (state) { return state.timeEntrySuggestions.items; });
    var calendarIntegrationSetting = useSelector(function (state) { return state.calendarIntegrationSettings.item; });
    var calendarIntegrationFilters = useSelector(function (state) { return state.calendarIntegrationFilters.items; });
    var categories = useSelector(function (state) { return state.categories; });
    var workspacesCalendarIntegrationFilters = useSelector(function (state) { return state.workspacesCalendarIntegrationFilters.items; });
    var _b = React.useState(true), isUseMatchedCategory = _b[0], setIsUseMatchedCategory = _b[1];
    var filters = calendarIntegrationSetting.base_type === 'workspace'
        ? workspacesCalendarIntegrationFilters
        : calendarIntegrationFilters;
    var matchedCategory = isUseMatchedCategory
        ? findCategoryByKeyword(calendarIntegrationEvent.title, calendarIntegrationSetting, filters, categories)
        : null;
    var isShowCancelButton = !(isMobile || isApp);
    var handleToggleTaskForm = function () {
        onClose();
        setIsUseMatchedCategory(true);
    };
    var dispatch = useDispatch();
    var handleStart = function (task, comment) {
        var promise = dispatch(createAndStartTask(task, comment));
        promise.then(function () {
            dispatch(fetchWorkingUsersCount());
            dispatch(arrangeRecentCategories(task.category));
            var currentDate = moment().format(dateFormat);
            dispatch(fetchCalendar({ date: currentDate }));
            handleToggleTaskForm();
        });
        return promise;
    };
    var handleCreate = function (teamId, task, timeEntry, comment) {
        var promise = dispatch(createTaskWithTimeEntry(teamId, task, timeEntry, comment));
        promise.then(function (task) {
            if (task.error) {
                return;
            }
            if (timeEntry) {
                var startedDate = moment(timeEntry.started_at).format(dateFormat);
                if (startedDate === date) {
                    dispatch(fetchCalendar({ date: startedDate }));
                }
            }
            handleToggleTaskForm();
            var category = __assign(__assign({}, task.category), { team: task.team });
            dispatch(arrangeRecentCategories(category));
        });
        return promise;
    };
    var handleCancel = function () {
        onClose();
    };
    var handleSelectCategory = function (category) {
        dispatch(setCurrentCategory(category));
        setIsUseMatchedCategory(false);
    };
    var handleSetFromPageTitle = function (fromPageTitle) {
        dispatch(setFromPageTitle(fromPageTitle));
    };
    var handleSetFreezeScroll = React.useCallback(function (isFreeze) {
        dispatch(freezeScroll(isFreeze));
    }, [dispatch]);
    var handleSuggestTasks = function (category, title) {
        var params = {
            title: title,
            category_id: category.id,
            team_id: category.team_id,
        };
        dispatch(fetchSuggestTasks(params));
    };
    var handleFetchTimeEntrySuggestion = React.useCallback(function (time) {
        dispatch(fetchTimeEntrySuggestion(time));
    }, [dispatch]);
    return (<TaskForm isTimeEntrySpecified isTimespanChanged category={matchedCategory || currentCategory} className={className} fetchTimeEntrySuggestion={handleFetchTimeEntrySuggestion} fromPageTitle={fromPageTitle} hidesFromPageToggle={hidesFromPageToggle} isChromeExtension={isChromeExtension} isFocus={isFocus} isMine={false} isPC={isPC} isShowCancelButton={isShowCancelButton} linkUrl={linkUrl} mode={mode} openURL={openURL} readOnly={false} setFreezeScroll={handleSetFreezeScroll} tab={tab} task={task} timeEntry={timeEntry} timeEntrySuggestions={timeEntrySuggestions} useDefaultDuration={false} onCancel={handleCancel} onCreate={handleCreate} onSelectCategory={handleSelectCategory} onSetFromPageTitle={handleSetFromPageTitle} onStart={handleStart} onSuggestTasks={handleSuggestTasks}/>);
};
export default TaskFormCalendarEventContainer;
