import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CrossTeamReportLongTermLoading } from '@/components/cross_team_report_long_term_loading';
import { useWorkspaceId } from '@/hooks/use_workspace_id';
import { cancelWorkspacesLongTermReport } from '@/modules/workspaces_long_term_reports';
export var CrossTeamReportLongTermLoadingContainer = function () {
    var workspaceId = useWorkspaceId();
    var dispatch = useDispatch();
    var report = useSelector(function (state) {
        return state.workspacesLongTermReports.items.find(function (report) {
            return ['pending', 'processing'].includes(report.status) &&
                report.workspaces_workspace_id === workspaceId;
        });
    });
    var handleCancel = React.useCallback(function (report) {
        dispatch(cancelWorkspacesLongTermReport(report.id));
    }, [dispatch]);
    if (!report) {
        return null;
    }
    return (<CrossTeamReportLongTermLoading report={report} onCancel={handleCancel}/>);
};
