import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import I18n from '@/constants/i18n';
export var CustomFieldsErrorBadge = function (_a) {
    var tooltipId = _a.tooltipId, _b = _a.className, className = _b === void 0 ? '' : _b, onClick = _a.onClick, errors = _a.errors;
    return (<span className={"".concat(className, " ").concat(onClick ? '!tw-cursor-pointer' : '')} onClick={onClick}>
      <span className="bg-danger text-white rounded-circle d-inline-flex justify-content-center align-items-center font-weight-bold" id={tooltipId} style={{ width: '2rem', height: '2rem' }}>
        !
      </span>
      <UncontrolledTooltip fade={false} target={tooltipId}>
        {errors.map(function (error) { return (<div key={error}>
            {error === 'requirement'
                ? I18n.t('shared.thereAreBlankRequiredFields')
                : I18n.t("shared.customFieldsExpressionErrors.".concat(error))}
          </div>); })}
      </UncontrolledTooltip>
    </span>);
};
