import React from 'react';
import I18n from '@/constants/i18n';
import { periodLabel } from '@/components/cross_team_report_header/helpers';
export var CrossTeamReportLongTermLoading = function (props) {
    var report = props.report, onCancel = props.onCancel;
    var handleCancel = React.useCallback(function () {
        onCancel(report);
    }, [report, onCancel]);
    return (<div className="position-fixed bottom-0 left-0 right-0 bg-white border-top-1 p-2 d-flex align-items-center content-main min-h-auto z-1 shadow">
      <div>
        <div className="d-flex align-items-center">
          <div className="h4 mr-2">
            {I18n.t('shared.longTermReportLoadingTitle')}
          </div>
          {report.period_type !== 'custom' && (<span className="mr-1">
              {I18n.t("shared.".concat(report.period_type))}
            </span>)}
          {periodLabel(report)}
        </div>
        <div className="text-muted text-nowrap mt-1">
          {I18n.t('shared.longTermReportLoadingDescription')}
        </div>
      </div>
      <div className="w-100 ml-3 mr-2 text-muted">
        <div>
          {I18n.t("shared.longTermReportLoadingStatus.".concat(report.status))}
        </div>
        <div className="position-relative">
          <div className="bg-body-secondary h-2 rounded position-absolute w-100"/>
          <div className="bg-primary h-2 rounded position-absolute" style={{
            width: "".concat(report.status === 'pending' ? 33 : 66, "%"),
        }}/>
        </div>
      </div>
      <button className="btn btn-link" onClick={handleCancel}>
        {I18n.t('shared.cancel')}
      </button>
    </div>);
};
