import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import React from 'react';
import { useParams } from 'react-router-dom';
import I18n from '@/constants/i18n';
import { TeamManagementLayoutContainer } from '@/containers/team_management_layout_container';
import Icon from '@/components/atoms/icon';
import { ReminderReceiverField } from './reminder_receiver_field';
import { ReminderMessageField } from './reminder_message_field';
import { ReminderSendTimeField } from './reminder_send_time_field';
import { ReminderTypeField } from './reminder_type_field';
import { ReminderConditionField } from './reminder_condition_field';
import { ReminderDeleteModal } from './reminder_delete_modal';
export var ReminderEmailsForm = function (_a) {
    var teams = _a.teams, defaultReminderEmailsSettings = _a.reminderEmailsSettings, createReminderEmailsSetting = _a.createReminderEmailsSetting, updateReminderEmailsSetting = _a.updateReminderEmailsSetting, deleteReminderEmailsSetting = _a.deleteReminderEmailsSetting;
    var _b = React.useState(''), activeHolidayCalendarIndex = _b[0], setActiveHolidayCalendarIndex = _b[1];
    var _c = React.useState(defaultReminderEmailsSettings), reminderEmailsSettings = _c[0], setReminderEmailsSettings = _c[1];
    var _d = React.useState(-1), deletingIndex = _d[0], setDeletingIndex = _d[1];
    var _e = React.useState(false), isDeleting = _e[0], setIsDeleting = _e[1];
    var _f = React.useState(-1), savingIndex = _f[0], setSavingIndex = _f[1];
    var _g = React.useState(-1), savedIndex = _g[0], setSavedIndex = _g[1];
    var _h = React.useState(false), isChanged = _h[0], setIsChanged = _h[1];
    var params = useParams();
    var team = teams.find(function (team) { return team.id === Number(params.team_id); });
    var canAdd = reminderEmailsSettings.length < 10;
    React.useEffect(function () {
        if (!isChanged) {
            setReminderEmailsSettings(defaultReminderEmailsSettings);
        }
    }, [isChanged, defaultReminderEmailsSettings]);
    var onAdd = React.useCallback(function () {
        setIsChanged(true);
        setReminderEmailsSettings(__spreadArray(__spreadArray([], reminderEmailsSettings, true), [
            {
                reminder_type: 'general',
                condition_type: 'none',
                receivers: 'member',
                days_of_week: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
                ignored_periods: [],
                send_time_text: '09:00',
                condition_minutes: 0,
            },
        ], false));
    }, [reminderEmailsSettings]);
    var onDelete = React.useCallback(function (index) {
        setDeletingIndex(index);
    }, []);
    var onCancelDelete = React.useCallback(function () {
        setDeletingIndex(-1);
    }, []);
    var onConfirmDelete = React.useCallback(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var setting;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsChanged(true);
                        setting = reminderEmailsSettings[deletingIndex];
                        setIsDeleting(true);
                        if (!setting.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, deleteReminderEmailsSetting(setting.id, team === null || team === void 0 ? void 0 : team.id)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        setReminderEmailsSettings(reminderEmailsSettings.filter(function (_, i) { return i !== deletingIndex; }));
                        setDeletingIndex(-1);
                        setIsDeleting(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [
        reminderEmailsSettings,
        deletingIndex,
        team === null || team === void 0 ? void 0 : team.id,
        deleteReminderEmailsSetting,
    ]);
    var onSave = React.useCallback(function (index) {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var setting, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSavingIndex(index);
                        setting = reminderEmailsSettings[index];
                        if (!setting.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, updateReminderEmailsSetting(setting.id, setting)];
                    case 1:
                        res = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, createReminderEmailsSetting(__assign({ team_id: team === null || team === void 0 ? void 0 : team.id }, setting))];
                    case 3:
                        res = _a.sent();
                        if (!res.error) {
                            setReminderEmailsSettings(reminderEmailsSettings.map(function (reminderEmailsSetting, i) {
                                return i === index ? res.item : reminderEmailsSetting;
                            }));
                        }
                        _a.label = 4;
                    case 4:
                        setSavingIndex(-1);
                        if (!!res.error) return [3 /*break*/, 6];
                        setSavedIndex(index);
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 2000); })];
                    case 5:
                        _a.sent();
                        setSavedIndex(-1);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); })();
    }, [
        reminderEmailsSettings,
        createReminderEmailsSetting,
        updateReminderEmailsSetting,
        team === null || team === void 0 ? void 0 : team.id,
    ]);
    var onChangeReminderEmailsSetting = React.useCallback(function (index, values) {
        setIsChanged(true);
        setReminderEmailsSettings(reminderEmailsSettings.map(function (reminderEmailsSetting, i) {
            return i === index
                ? __assign(__assign({}, reminderEmailsSetting), values) : reminderEmailsSetting;
        }));
    }, [reminderEmailsSettings]);
    return (<TeamManagementLayoutContainer currentPage="reminder_emails" team={team} title={I18n.t('shared.reminderEmails')}>
      <div className="w-fit">
        <p>{I18n.t('shared.reminderEmailsDescription1')}</p>
        <p className="mt-3">{I18n.t('shared.reminderEmailsDescription2')}</p>
        <ul className="pl-3">
          <li>{I18n.t('shared.reminderEmailsDescription3')}</li>
          <li>{I18n.t('shared.reminderEmailsDescription4')}</li>
          <li>{I18n.t('shared.reminderEmailsDescription5')}</li>
        </ul>

        <div className="mt-3">
          {reminderEmailsSettings.map(function (reminderEmailsSetting, i) { return (<div key={i} className="border-1 border-bottom-0 px-3 py-2">
              <ReminderTypeField index={i} reminderEmailsSetting={reminderEmailsSetting} onChangeReminderEmailsSetting={onChangeReminderEmailsSetting}/>

              <ReminderConditionField index={i} reminderEmailsSetting={reminderEmailsSetting} onChangeReminderEmailsSetting={onChangeReminderEmailsSetting}/>

              {['general', 'forgot_record'].includes(reminderEmailsSetting.reminder_type) && (<ReminderSendTimeField activeHolidayCalendarIndex={activeHolidayCalendarIndex} index={i} reminderEmailsSetting={reminderEmailsSetting} setActiveHolidayCalendarIndex={setActiveHolidayCalendarIndex} onChangeReminderEmailsSetting={onChangeReminderEmailsSetting}/>)}

              <ReminderMessageField index={i} reminderEmailsSetting={reminderEmailsSetting} onChangeReminderEmailsSetting={onChangeReminderEmailsSetting}/>

              <ReminderReceiverField index={i} reminderEmailsSetting={reminderEmailsSetting} onChangeReminderEmailsSetting={onChangeReminderEmailsSetting}/>

              <div className="mt-4 mb-2 d-flex justify-content-between align-items-center">
                <button className="btn btn-link text-danger p-0" onClick={function () { return onDelete(i); }}>
                  {I18n.t('shared.delete')}
                </button>
                <button className="btn btn-primary" disabled={savingIndex === i ||
                savedIndex === i ||
                (reminderEmailsSetting.reminder_type === 'general' &&
                    !reminderEmailsSetting.message)} onClick={function () { return onSave(i); }}>
                  {I18n.t("shared.".concat(savingIndex === i
                ? 'saving'
                : savedIndex === i
                    ? 'saved'
                    : 'save'))}
                </button>
              </div>
            </div>); })}
        </div>

        <div className="border-1">
          {canAdd && (<button className="d-flex py-2 btn btn-link btn-block" onClick={onAdd}>
              <span className="bg-primary text-white p-1 rounded-circle d-flex justify-content-center align-items-center mr-1">
                <Icon name="plus"/>
              </span>
              <span>{I18n.t('shared.addReminder')}</span>
            </button>)}
        </div>
      </div>

      <ReminderDeleteModal isDeleting={isDeleting} isOpen={deletingIndex > -1} onCancel={onCancelDelete} onConfirm={onConfirmDelete}/>
    </TeamManagementLayoutContainer>);
};
