import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export default function DeleteAssetModal(_a) {
    var isOpen = _a.isOpen, asset = _a.asset, toggle = _a.toggle, onDelete = _a.onDelete;
    return (<Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeaderMod toggle={toggle}>
        {I18n.t('export.deleteCsv.header')}
      </ModalHeaderMod>
      <ModalBody>
        <p>{I18n.t('export.deleteCsv.attention')}</p>
      </ModalBody>
      <ModalFooter className="justify-content-start">
        <button className="btn-major-action btn btn-danger" onClick={function () { return onDelete(asset); }}>
          {I18n.t('shared.remove')}
        </button>
        <button className="btn btn-secondary" onClick={toggle}>
          {I18n.t('shared.cancel')}
        </button>
      </ModalFooter>
    </Modal>);
}
