import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import I18n from '@/constants/i18n';
export var ReminderDeleteModal = function (_a) {
    var isOpen = _a.isOpen, isDeleting = _a.isDeleting, onCancel = _a.onCancel, onConfirm = _a.onConfirm;
    return (<Modal isOpen={isOpen} toggle={onCancel}>
      <ModalHeaderMod toggle={onCancel}>
        {I18n.t('shared.deleteReminderEmailsSetting')}
      </ModalHeaderMod>
      <ModalBody>{I18n.t('shared.deleteReminderDescription')}</ModalBody>
      <ModalFooter className="justify-content-between">
        <button className="btn btn-danger" disabled={isDeleting} onClick={onConfirm}>
          {I18n.t('shared.delete')}
        </button>

        <button className="btn btn-secondary" disabled={isDeleting} onClick={onCancel}>
          {I18n.t('shared.cancel')}
        </button>
      </ModalFooter>
    </Modal>);
};
