import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateTeam } from '@/modules/teams';
import { EditTeamForm } from '@/components/edit_team_form';
import { workspaceTeams } from '@/utils/workspace_helper';
var TeamEditContainer = function () {
    var openURL = useSelector(function (state) { return state.openURL; });
    var saveTeamId = useSelector(function (state) { return state.saveTeamId; });
    var teams = workspaceTeams(useSelector(function (state) { return state.teams.items; }));
    var teamsIsFetched = useSelector(function (state) { return state.teams.isFetched; });
    var id = useParams().id;
    var team = teams.find(function (team) { return team.id === Number(id); });
    var dispatch = useDispatch();
    var handleUpdateTeam = function (id, params) {
        return dispatch(updateTeam(id, params));
    };
    React.useEffect(function () {
        if (!teamsIsFetched) {
            return;
        }
        if (id && team) {
            saveTeamId(team.id);
        }
    }, [id, saveTeamId, team, teamsIsFetched]);
    return (<EditTeamForm openURL={openURL} team={team} teams={teams} onUpdate={handleUpdateTeam}/>);
};
export default TeamEditContainer;
