import React from 'react';
import I18n from '@/constants/i18n';
import { visibilities } from '@/types';
import CustomRadio from '@/components/atoms/custom_radio';
export var CustomFieldsVisibilityField = function (_a) {
    var customFieldsMaster = _a.customFieldsMaster, onChangeCustomFieldsMaster = _a.onChangeCustomFieldsMaster, className = _a.className;
    return (<div className={className}>
      {I18n.t('shared.customFieldsVisibility')}
      <div className="d-flex mt-2">
        {visibilities.map(function (v) { return (<div key={v} className="mr-2">
            <CustomRadio checked={v === customFieldsMaster.visibility} value={v} onChange={function () { return onChangeCustomFieldsMaster('visibility', v); }}>
              {I18n.t("shared.customFieldsVisibilities.".concat(v))}
            </CustomRadio>
          </div>); })}
      </div>
    </div>);
};
