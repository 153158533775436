import React from 'react';
import Icon from '@/components/atoms/icon';
export var InlineAlert = function (_a) {
    var className = _a.className, _b = _a.errorMessages, errorMessages = _b === void 0 ? [] : _b;
    if (errorMessages.length === 0) {
        return null;
    }
    return (<div className={className}>
      <div className="alert-danger border-1 border-danger rounded p-2">
        <div className="d-flex">
          <div className="mr-1">
            <Icon className="text-danger" name="warning"/>
          </div>
          <ul className="list-none mb-0 pl-0">
            {errorMessages.map(function (message, index) { return (<li key={index} dangerouslySetInnerHTML={{ __html: message }}/>); })}
          </ul>
        </div>
      </div>
    </div>);
};
