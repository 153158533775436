import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch } from 'react-redux';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { CustomFieldsMasterDeleteModal } from '@/components/custom_fields_master_delete_modal';
import { CustomFieldsMasterFormModal } from '@/components/custom_fields_master_form_modal';
import { SyntaxHighlighting } from '@/components/syntax_highlighting';
import { removeDangerNotificationMessage } from '@/modules/notification';
export var CustomFieldsMasterListItem = function (_a) {
    var customFieldsMaster = _a.customFieldsMaster, customFieldsMasters = _a.customFieldsMasters, updateCustomFieldsMaster = _a.updateCustomFieldsMaster, deleteCustomFieldsMaster = _a.deleteCustomFieldsMaster, setNotificationMessage = _a.setNotificationMessage, onNavigateToCategories = _a.onNavigateToCategories;
    var _b = React.useState(false), isShowDeleteModal = _b[0], setIsShowDeleteModal = _b[1];
    var _c = React.useState(false), isShowEditModal = _c[0], setIsShowEditModal = _c[1];
    var _d = React.useState(false), isDeleting = _d[0], setIsDeleting = _d[1];
    var _e = React.useState(false), isEditing = _e[0], setIsEditing = _e[1];
    var _f = React.useState(false), isOpenDetails = _f[0], setIsOpenDetails = _f[1];
    var _g = React.useState([]), duplicatedCategories = _g[0], setDuplicatedCategories = _g[1];
    var onStartEdit = React.useCallback(function () {
        setIsShowEditModal(true);
    }, []);
    var dispatch = useDispatch();
    var handleCloseEditModal = React.useCallback(function () {
        dispatch(removeDangerNotificationMessage());
        setIsShowEditModal(false);
    }, [dispatch]);
    var onConfirmEdit = React.useCallback(function (params) {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var res, duplicated;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsEditing(true);
                        setDuplicatedCategories([]);
                        return [4 /*yield*/, updateCustomFieldsMaster(customFieldsMaster.id, params)];
                    case 1:
                        res = _a.sent();
                        setIsEditing(false);
                        if (res.error) {
                            duplicated = res.errors.find(function (e) { return e.attribute === 'unique' && e.type === 'values_duplicated'; });
                            if (duplicated) {
                                setNotificationMessage('danger', I18n.t('shared.customFieldsValuesDuplicated'));
                                setDuplicatedCategories(duplicated.message.split(','));
                            }
                            return [2 /*return*/];
                        }
                        handleCloseEditModal();
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [
        customFieldsMaster.id,
        updateCustomFieldsMaster,
        setNotificationMessage,
        handleCloseEditModal,
    ]);
    var onCancelEdit = React.useCallback(function () {
        handleCloseEditModal();
        setDuplicatedCategories([]);
    }, [handleCloseEditModal]);
    var onStartDelete = React.useCallback(function () {
        setIsShowDeleteModal(true);
    }, []);
    var handleCloseModal = React.useCallback(function () {
        dispatch(removeDangerNotificationMessage());
        setIsShowDeleteModal(false);
    }, [dispatch]);
    var onConfirmDelete = React.useCallback(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsDeleting(true);
                        return [4 /*yield*/, deleteCustomFieldsMaster(customFieldsMaster.id)];
                    case 1:
                        response = _a.sent();
                        setIsDeleting(false);
                        if (response.error) {
                            return [2 /*return*/];
                        }
                        handleCloseModal();
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [customFieldsMaster.id, deleteCustomFieldsMaster, handleCloseModal]);
    return (<div className="category-list-item__container d-block">
      <div className="d-flex justify-content-between align-items-center">
        <div className="text-break">
          {customFieldsMaster.name}
          <button className="btn btn-link text-muted ml-1 px-1 rounded-circle" onClick={function () { return setIsOpenDetails(!isOpenDetails); }}>
            <Icon name={"chevron-".concat(isOpenDetails ? 'up' : 'down')} size="lg"/>
          </button>
        </div>
        <div className="btn-group">
          <button className="btn btn-secondary btn-sm rounded-right-0" onClick={onStartEdit}>
            <Icon name="pencil" size="sm"/>
          </button>
          <UncontrolledButtonDropdown size="sm">
            <DropdownToggle>
              <span className="btn-icon">
                <Icon name="dots-3" size="sm"/>
              </span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className="text-danger" onClick={onStartDelete}>
                {I18n.t('shared.remove')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </div>

      {isOpenDetails && (<table>
          <tbody>
            <tr>
              <td className="text-nowrap pr-1">
                {I18n.t('shared.customFieldsType')}
              </td>
              <td className="align-top">
                {I18n.t("shared.customFieldsTypes.".concat(customFieldsMaster.field_type))}
              </td>
            </tr>
            {customFieldsMaster.field_type !== 'expression' && (<>
                <tr>
                  <td className="text-nowrap pr-1">
                    {I18n.t('shared.customFieldsRequirementAll')}
                  </td>
                  <td className="align-top">
                    {customFieldsMaster.requirement !== 'none' ? '✔' : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="text-nowrap pr-1">
                    {I18n.t('shared.customFieldsRequirementLeaf')}
                  </td>
                  <td className="align-top">
                    {customFieldsMaster.requirement === 'leaf' ? '✔' : '-'}
                  </td>
                </tr>
              </>)}
            {customFieldsMaster.field_type !== 'expression' && (<tr>
                <td className="text-nowrap pr-1">
                  {I18n.t('shared.customFieldsUnique')}
                </td>
                <td className="align-top">
                  {customFieldsMaster.unique ? '✔' : '-'}
                </td>
              </tr>)}
            {customFieldsMaster.field_type === 'expression' && (<tr>
                <td className="text-nowrap pr-1">
                  {I18n.t('shared.customFieldsExpression')}
                </td>
                <td>
                  <SyntaxHighlighting expressionText={customFieldsMaster.expression_text}/>
                </td>
              </tr>)}
            {['number', 'expression'].includes(customFieldsMaster.field_type) && (<tr>
                <td className="align-top text-nowrap pr-1">
                  {I18n.t('shared.customFieldsUnit')}
                </td>
                <td className="text-break">{customFieldsMaster.unit || '-'}</td>
              </tr>)}
            <tr>
              <td className="text-nowrap pr-1">
                {I18n.t('shared.customFieldsVisibility')}
              </td>
              <td className="align-top">
                {I18n.t("shared.customFieldsVisibilities.".concat(customFieldsMaster.visibility))}
              </td>
            </tr>
            <tr></tr>
          </tbody>
        </table>)}

      <CustomFieldsMasterDeleteModal customFieldsMaster={customFieldsMaster} isDeleting={isDeleting} isOpen={isShowDeleteModal} onCancel={handleCloseModal} onDelete={onConfirmDelete}/>
      <CustomFieldsMasterFormModal customFieldsMaster={customFieldsMaster} customFieldsMasters={customFieldsMasters} duplicatedCategories={duplicatedCategories} isOpen={isShowEditModal} isSaving={isEditing} onCancel={onCancelEdit} onNavigateToCategories={onNavigateToCategories} onSave={onConfirmEdit}/>
    </div>);
};
