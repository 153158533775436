import React, { useEffect } from 'react';
import { Alert as ReactstrapAlert } from 'reactstrap';
import Icon from '@/components/atoms/icon';
import classNames from 'classnames';
var Alert = function (props) {
    var color = props.color, _a = props.position, position = _a === void 0 ? 'top' : _a, _b = props.classes, classes = _b === void 0 ? '' : _b, style = props.style, _c = props.isOpen, isOpen = _c === void 0 ? false : _c, _d = props.isHtml, isHtml = _d === void 0 ? false : _d, _e = props.autoClose, autoClose = _e === void 0 ? false : _e, message = props.message, _f = props.withoutCloseButton, withoutCloseButton = _f === void 0 ? false : _f, onClose = props.onClose, children = props.children;
    var timer = React.useRef(null);
    useEffect(function () {
        clearTimeout(timer.current);
        if (autoClose && isOpen) {
            timer.current = setTimeout(function () {
                onClose();
            }, 5000);
        }
        return function () {
            clearTimeout(timer.current);
        };
    }, [autoClose, isOpen, onClose]);
    if (!isOpen) {
        return null;
    }
    var className = classNames('alert-' + position, 'animated', 'fadeInUpSmall', 'd-flex', 'w-fit', classes);
    return (<div className="alert-bar">
      <ReactstrapAlert className={className} color={color} isOpen={isOpen} style={style}>
        <div className="alert-body text-break">
          <div dangerouslySetInnerHTML={isHtml && message ? { __html: message } : undefined}>
            {isHtml ? null : message}
          </div>
          {children}
        </div>
        {!withoutCloseButton && (<button aria-label="Close" className="close" onClick={onClose}>
            <span aria-hidden="true">
              <Icon name="cross" size="lg"/>
            </span>
          </button>)}
      </ReactstrapAlert>
    </div>);
};
export default Alert;
