import moment from 'moment'; // eslint-disable-line no-restricted-imports
import orderBy from 'lodash/orderBy';
export function groupByTime(calendarIntegrationEvents) {
    var groups = {};
    var index = 0;
    var prevGroup = { id: -1, count: 0 };
    var prevEvent = null;
    calendarIntegrationEvents.forEach(function (currentEvent) {
        var currentGroup = { id: prevGroup.id + 1, count: 0 };
        if (prevEvent) {
            var prevStart = moment(prevEvent.starts_at);
            var prevEnd = moment(prevEvent.ends_at);
            var currentStart = moment(currentEvent.starts_at);
            if (currentStart.isSameOrAfter(prevStart) &&
                currentStart.isBefore(prevEnd)) {
                currentGroup = prevGroup;
            }
        }
        if (prevGroup !== currentGroup) {
            index = 0;
        }
        currentGroup.count++;
        groups[currentEvent.id] = {
            group: currentGroup,
            index: index,
        };
        prevEvent = currentEvent;
        prevGroup = currentGroup;
        index++;
    });
    return groups;
}
export var filterByDate = function (calendarIntegrationEvents, date) {
    return orderBy(calendarIntegrationEvents.filter(function (_a) {
        var starts_at = _a.starts_at, ends_at = _a.ends_at;
        return moment(date).isSame(starts_at, 'day') ||
            moment(date).isSame(ends_at, 'day');
    }), ['starts_at'], ['asc']);
};
