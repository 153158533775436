import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '@/modules/modal';
import { ConfirmModal } from '@/components/confirm_modal';
var ConfirmModalContainer = function () {
    var isOpen = useSelector(function (state) { return state.modal.modalType === 'CONFIRM'; });
    var header = useSelector(function (state) { return state.modal.modalProps.header; });
    var body = useSelector(function (state) { return state.modal.modalProps.body; });
    var dispatch = useDispatch();
    var handleClose = function () {
        dispatch(closeModal());
    };
    return (<ConfirmModal body={body} header={header} isOpen={isOpen} onClose={handleClose}/>);
};
export default ConfirmModalContainer;
