import React from 'react';
import classNames from 'classnames';
var ExpansionPanel = function (props) {
    var _a = props.expand, expand = _a === void 0 ? false : _a, className = props.className, children = props.children, _b = props.tag, Tag = _b === void 0 ? 'div' : _b, toggle = props.toggle;
    var _c = React.useState(expand), expandState = _c[0], setExpandState = _c[1];
    React.useEffect(function () {
        setExpandState(expand);
    }, [expand]);
    var handleToggle = function () {
        if (toggle) {
            return toggle();
        }
        setExpandState(!expandState);
    };
    var classes = classNames('panel expansion-panel', className, {
        expand: expandState,
        shrink: !expandState,
    });
    return <Tag className={classes}>{children(expandState, handleToggle)}</Tag>;
};
export default ExpansionPanel;
