import React from 'react';
import I18n from '../../constants/i18n';
export default function ExportActions(_a) {
    var asset = _a.asset, openURL = _a.openURL, onCancel = _a.onCancel;
    switch (asset.status) {
        case 'success':
            return (<a className="btn btn-link" href={asset.download_url} onClick={function (e) {
                    e.preventDefault();
                    openURL(e.target.href);
                }}>
          {I18n.t('shared.download')}
        </a>);
        case 'initial':
        case 'in_progress':
            return (<button className="text-danger btn btn-link" onClick={function () { return onCancel(asset); }}>
          {I18n.t('shared.cancel')}
        </button>);
        case 'failure':
            return (<span className="text-info px-2 py-1 d-inline-block" color="link">
          {I18n.t('shared.failure')}
        </span>);
        case 'cancel':
            return (<span className="text-info px-2 py-1 d-inline-block">
          {I18n.t('shared.canceled')}
        </span>);
        default:
            return null;
    }
}
