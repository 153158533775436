import { __assign, __spreadArray } from "tslib";
import React from 'react';
import CategoryFormModalContainer from '@/containers/category_form_modal_container';
import { unsetCategory, unsetParentCategory } from './helpers';
export var NewCategoryFormModal = function (_a) {
    var team = _a.team, categories = _a.categories, categoryTaskTree = _a.categoryTaskTree, customFieldsMasters = _a.customFieldsMasters, customFieldsValues = _a.customFieldsValues, isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, createTeamCategory = _a.createTeamCategory, fetchCustomFieldsValues = _a.fetchCustomFieldsValues, setNotificationMessage = _a.setNotificationMessage;
    var selectableCategories = __spreadArray([unsetParentCategory(team)], categories, true);
    var _b = React.useState(unsetParentCategory(team)), parentCategory = _b[0], setParentCategory = _b[1];
    var _c = React.useState(unsetCategory()), selectedCategory = _c[0], setSelectedCategory = _c[1];
    var toggleCategoryModal = function () {
        setParentCategory(unsetParentCategory(team));
        setSelectedCategory(unsetCategory());
        setIsOpen(!isOpen);
    };
    var handleTitle = function (e) {
        var category = __assign(__assign({}, selectedCategory), { title: e.target.value });
        setSelectedCategory(category);
    };
    var onSelectParentCategory = function (parentCategory) {
        var category = __assign(__assign({}, selectedCategory), { parent_id: parentCategory.id });
        setParentCategory(parentCategory);
        setSelectedCategory(category);
    };
    var selectColor = function (colorIndex) {
        var category = __assign(__assign({}, selectedCategory), { color: colorIndex });
        setSelectedCategory(category);
    };
    var createCategory = function (team, customFieldsValues) {
        var params = {
            category: {
                title: selectedCategory.title,
                color: selectedCategory.color,
                parent_id: parentCategory.id,
            },
            custom_fields_values: customFieldsValues,
        };
        return createTeamCategory(team.id, params).then(function (res) {
            if (!res.error) {
                toggleCategoryModal();
                if (team.is_custom_fields_allowed) {
                    fetchCustomFieldsValues(team.id);
                }
            }
            return res;
        });
    };
    return (<CategoryFormModalContainer categories={selectableCategories} category={selectedCategory} categoryTaskTree={categoryTaskTree} copySourceCategory={null} customFieldsMasters={customFieldsMasters.filter(function (_a) {
            var team_id = _a.team_id;
            return team_id === team.id;
        })} customFieldsValues={customFieldsValues} fetchCustomFieldsValues={fetchCustomFieldsValues} isOpen={isOpen} parentCategory={parentCategory} setNotificationMessage={setNotificationMessage} team={team} onChangeTitle={handleTitle} onCreate={function (customFieldsValues) {
            return createCategory(team, customFieldsValues);
        }} onSelectCategory={onSelectParentCategory} onSelectColor={selectColor} onToggleModal={toggleCategoryModal} onUpdate={function () { }}/>);
};
