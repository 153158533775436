document.querySelectorAll('[data-sign-in-method]').forEach((element) => {
  const method = element.dataset.signInMethod;

  element.addEventListener('click', () => {
    localStorage.currentSignInMethod = method;
  });
});

if (localStorage.lastSignInMethod) {
  const method = localStorage.lastSignInMethod;
  const container = document.querySelector(`.js-last-sign-in-method-container-${method}`);
  if (container) {
    container.querySelector('.js-last-sign-in-method-button')?.classList?.add('border-primary');
    container.querySelector('.js-last-sign-in-method-label')?.classList?.remove('d-none', 'invisible');

    if (method === 'email') {
      const social = document.querySelector('.js-social');
      const divider = document.querySelector('.js-divider');
      if (social && divider) {
        const parent = social.parentElement;
        parent.insertBefore(container, social);
        parent.insertBefore(divider, social);
      }
    }
  }
}

if (/^\/app/.test(location.pathname)) {
  if (localStorage.currentSignInMethod) {
    localStorage.lastSignInMethod = localStorage.currentSignInMethod;
    localStorage.removeItem('currentSignInMethod');
  }
}
