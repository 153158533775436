import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import React from 'react';
import { Modal, ModalBody, ModalFooter, Dropdown, DropdownMenu, DropdownToggle, } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import { CategoryTaskTreeList } from '@/components/organisms/category_task_tree_list';
import { CustomFieldsValuesForm } from '@/components/custom_fields_values_form';
import { buildErrorList } from '@/api2';
import { useThrottle } from '@/hooks/use_throttle';
import { CategorySelector } from '@/components/molecules/category_selector';
var CategoryFormModal = function (props) {
    var isOpen = props.isOpen, customFieldsValues = props.customFieldsValues, parentCategory = props.parentCategory, copySourceCategory = props.copySourceCategory, categories = props.categories, category = props.category, toggle = props.toggle, onChangeTitle = props.onChangeTitle, onSelectCategory = props.onSelectCategory, onSelectColor = props.onSelectColor, categoryTaskTree = props.categoryTaskTree, customFieldsMasters = props.customFieldsMasters, onCreate = props.onCreate, onUpdate = props.onUpdate, setNotificationMessage = props.setNotificationMessage, fetchCustomFieldsValues = props.fetchCustomFieldsValues, team = props.team, teams = props.teams;
    var _a = React.useState(false), isSaving = _a[0], setIsSaving = _a[1];
    var _b = React.useState([]), customFieldsValuesState = _b[0], setCustomFieldsValuesState = _b[1];
    var _c = React.useState([]), customFieldsErrors = _c[0], setCustomFieldsErrors = _c[1];
    var _d = React.useState(false), isParentCategorySelectorOpened = _d[0], setIsParentCategorySelectorOpened = _d[1];
    var setCustomFieldsValues = React.useCallback(function () {
        if (category.id) {
            setCustomFieldsValuesState(customFieldsValues.filter(function (_a) {
                var target_id = _a.target_id;
                return target_id === category.id;
            }));
        }
        else {
            setCustomFieldsValuesState([]);
        }
    }, [category.id, customFieldsValues]);
    React.useEffect(function () {
        setCustomFieldsValues();
    }, [setCustomFieldsValues]);
    React.useEffect(function () {
        setCustomFieldsValues();
        if (!isOpen) {
            setCustomFieldsErrors([]);
        }
    }, [isOpen, customFieldsValues, setCustomFieldsValues]);
    var handleSave = useThrottle(function () { return __awaiter(void 0, void 0, void 0, function () {
        var customFieldsValues, res, customFieldsError, customFieldsErrors_1, names;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    customFieldsValues = __spreadArray([], customFieldsValuesState, true);
                    customFieldsMasters.forEach(function (master) {
                        if (!customFieldsValues.find(function (value) { return value.custom_fields_master_id === master.id; })) {
                            customFieldsValues.push({
                                custom_fields_master_id: master.id,
                                value_text: '',
                            });
                        }
                    });
                    setIsSaving(true);
                    setCustomFieldsErrors([]);
                    if (!category.id) return [3 /*break*/, 2];
                    return [4 /*yield*/, onUpdate(customFieldsValues)];
                case 1:
                    res = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, onCreate(customFieldsValues)];
                case 3:
                    res = _b.sent();
                    _b.label = 4;
                case 4:
                    setIsSaving(false);
                    customFieldsError = (_a = res.errors) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
                        var attribute = _a.attribute;
                        return attribute === 'custom_fields_values';
                    });
                    if (!customFieldsError && team.is_custom_fields_allowed) {
                        fetchCustomFieldsValues(team.id);
                    }
                    if (customFieldsError) {
                        customFieldsErrors_1 = JSON.parse(customFieldsError.message);
                        names = customFieldsMasters
                            .filter(function (_a) {
                            var id = _a.id;
                            return customFieldsErrors_1.find(function (error) { return error.custom_fields_master_id === id; });
                        })
                            .map(function (_a) {
                            var name = _a.name;
                            return name;
                        });
                        setNotificationMessage('danger', buildErrorList(I18n.t('shared.customFieldsValuesInvalid'), names));
                        setCustomFieldsErrors(customFieldsErrors_1);
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    var handleToggleParentCategorySelector = function () {
        setIsParentCategorySelectorOpened(!isParentCategorySelectorOpened);
    };
    var handleSelectCategory = function (category) {
        onSelectCategory(category);
        setIsParentCategorySelectorOpened(false);
    };
    return (<Modal isOpen={isOpen} toggle={toggle} zIndex={1050}>
      <ModalHeaderMod toggle={toggle}>
        {I18n.t('teamManagement.categoryPage.info')}
      </ModalHeaderMod>

      <ModalBody className="text-break">
        <div>
          {!!copySourceCategory && (<p dangerouslySetInnerHTML={{
                __html: I18n.t('teamManagement.categoryPage.noticeForCopy', {
                    category: copySourceCategory.title,
                }),
            }}></p>)}

          <div className="form-group">
            <label>{I18n.t('teamManagement.categoryPage.categoryTitle')}</label>
            <input autoFocus={isOpen} className="form-control" type="text" value={category.title} onChange={onChangeTitle}/>
          </div>

          <div className="form-group">
            <label>
              {I18n.t('teamManagement.categoryPage.parentCategory')}
            </label>
            <Dropdown isOpen={isParentCategorySelectorOpened} toggle={handleToggleParentCategorySelector}>
              <DropdownToggle caret className="text-break whitespace-normal">
                {parentCategory.title}
              </DropdownToggle>
              <DropdownMenu>
                <CategorySelector categories={categories} category={parentCategory} isExpandAll={true} isOpened={isParentCategorySelectorOpened} setCategory={function (category) {
            handleSelectCategory(category);
        }} teams={teams.items} withoutTeam={true}/>
              </DropdownMenu>
            </Dropdown>
          </div>

          <div className="form-group">
            <label>{I18n.t('teamManagement.categoryPage.selectColor')}</label>
            <div className="category-color-selector">
              {[
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20,
        ].map(function (colorIndex) {
            return (<div key={'color' + colorIndex} className="category-color" onClick={function () { return onSelectColor(colorIndex); }}>
                    <i className={[
                    'category-color-chip',
                    'category-' + colorIndex,
                    category.color === colorIndex
                        ? 'active tc-checkmark'
                        : '',
                ].join(' ')}/>
                  </div>);
        })}
            </div>
          </div>

          {!!copySourceCategory && (<div className="form-group">
              <label>{I18n.t('teamManagement.categoryPage.toBeCopied')}</label>
              <div>
                <CategoryTaskTreeList tree={categoryTaskTree.map(function (item) {
                if (item.id === copySourceCategory.id) {
                    return __assign(__assign({}, item), { title: category.title, color: category.color });
                }
                else {
                    return item;
                }
            })}/>
              </div>
            </div>)}
        </div>
        <CustomFieldsValuesForm categories={categories} customFieldsErrors={customFieldsErrors} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValuesState} target={category} onChangeCustomFieldsValues={function (customFieldsValues) {
            // @ts-ignore
            setCustomFieldsValuesState(customFieldsValues);
        }}/>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-secondary" onClick={toggle}>
          {I18n.t('shared.cancel')}
        </button>
        <button className="btn btn-primary" disabled={isSaving} onClick={handleSave}>
          {I18n.t(isSaving ? 'shared.saving' : 'shared.save')}
        </button>
      </ModalFooter>
    </Modal>);
};
export default CategoryFormModal;
