import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '../../constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export default function DeleteMembershipModal(_a) {
    var membership = _a.membership, isOpen = _a.isOpen, toggle = _a.toggle, onDelete = _a.onDelete, isDeleting = _a.isDeleting, target = _a.target;
    if (!membership) {
        return null;
    }
    var user = membership.user;
    return (<Modal isOpen={isOpen} toggle={toggle} zIndex={1050}>
      <ModalHeaderMod toggle={toggle}>
        {I18n.t('teamManagement.removeUserFromTarget', { target: target })}
      </ModalHeaderMod>

      <ModalBody>
        {I18n.t('teamManagement.removeUserFromTargetConfirm', { target: target })}
        <ul>
          <li>{user.nickname}</li>
        </ul>
      </ModalBody>

      <ModalFooter className="justify-content-start">
        <button className="btn btn-danger" disabled={isDeleting} onClick={function () { return onDelete(membership); }}>
          {I18n.t('teamManagement.deleteMembership')}
        </button>
        <button className="btn btn-secondary" onClick={toggle}>
          {I18n.t('shared.cancel')}
        </button>
      </ModalFooter>
    </Modal>);
}
