import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n from '@/constants/i18n';
import { createWorkspacePayment } from '@/actions/workspace_payment';
import { updateWorkspaceSuccess } from '@/modules/workspaces';
import { NewPaymentForm } from '@/components/payment/new_payment_form';
var NewPaymentFormContainer = function (_a) {
    var openModal = _a.openModal;
    var openURL = useSelector(function (state) { return state.openURL; });
    var team = useSelector(function (state) { return state.payment.item.team; });
    var workspace = useSelector(function (state) { return state.payment.item.workspace; });
    var payment = useSelector(function (state) { return state.payment.item; });
    var email = useSelector(function (state) { return state.user.email; });
    var dispatch = useDispatch();
    var handleCreate = function (_teamId, token, capacity) {
        var params = { 'payjp-token': token, capacity: capacity };
        dispatch(createWorkspacePayment(params)).then(function (payment) {
            if (payment.error) {
                return;
            }
            dispatch(updateWorkspaceSuccess(payment.workspace));
            openModal(I18n.t('teamManagement.billingForm.accept'), I18n.t('teamManagement.billingForm.thankYou'));
        });
    };
    return (<NewPaymentForm email={email} openURL={openURL} payment={payment} team={team} workspace={workspace} onCreate={handleCreate}/>);
};
export default NewPaymentFormContainer;
