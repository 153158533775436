import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
var CategoryMenu = function (props) {
    var category = props.category, onNewChildCategory = props.handleNewChildCategory, onCopyCategory = props.handleCopyCategory, onArchiveCategory = props.handleArchiveCategory, onMoveLowerCategoryPosition = props.onMoveLowerCategoryPosition, onMoveHigherCategoryPosition = props.onMoveHigherCategoryPosition, canUnarchive = props.canUnarchive, toggleRemoveModal = props.toggleRemoveModal;
    var _a = React.useState(false), isDropdownOpened = _a[0], setIsDropdownOpened = _a[1];
    var toggleDropdown = function () {
        setIsDropdownOpened(!isDropdownOpened);
    };
    var handleNewChildCategory = function (e) {
        e.stopPropagation();
        onNewChildCategory(category);
    };
    var handleCopyCategory = function (e) {
        e.stopPropagation();
        onCopyCategory(category);
    };
    var handleArchiveCategory = function (e) {
        e.stopPropagation();
        onArchiveCategory(category);
    };
    var handleMoveLowerCategoryPosition = function (e) {
        e.stopPropagation();
        onMoveLowerCategoryPosition(category.id);
    };
    var handleMoveHigherCategoryPosition = function (e) {
        e.stopPropagation();
        onMoveHigherCategoryPosition(category.id);
    };
    return (<ButtonDropdown isOpen={isDropdownOpened} size="sm" toggle={toggleDropdown}>
      <DropdownToggle onClick={function (e) { return e.stopPropagation(); }}>
        <span className="btn-icon">
          <Icon name="dots-3" size="sm"/>
        </span>
      </DropdownToggle>
      <DropdownMenu right>
        {!category.archived && (<>
            <DropdownItem onClick={handleNewChildCategory}>
              {I18n.t('teamManagement.categoryMenu.createChildCategory')}
            </DropdownItem>
            <DropdownItem onClick={handleCopyCategory}>
              {I18n.t('teamManagement.categoryMenu.copyCategory')}
            </DropdownItem>
            <DropdownItem onClick={handleMoveHigherCategoryPosition}>
              {I18n.t('teamManagement.categoryMenu.moveHigherCategoryPosition')}
            </DropdownItem>
            <DropdownItem onClick={handleMoveLowerCategoryPosition}>
              {I18n.t('teamManagement.categoryMenu.moveLowerCategoryPosition')}
            </DropdownItem>
          </>)}
        {!category.archived && (<>
            <DropdownItem onClick={handleArchiveCategory}>
              {I18n.t('teamManagement.categoryMenu.archiveCategory')}
            </DropdownItem>
            <DropdownItem divider/>
          </>)}
        {category.archived && canUnarchive && (<>
            <DropdownItem onClick={handleArchiveCategory}>
              {I18n.t('teamManagement.categoryMenu.unarchiveCategory')}
            </DropdownItem>
            <DropdownItem divider/>
          </>)}
        <DropdownItem className="text-danger" onClick={function (e) {
            e.stopPropagation();
            toggleRemoveModal(category);
        }}>
          {I18n.t('shared.remove')}
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>);
};
export default CategoryMenu;
