import React from 'react';
import { isChild } from '@/modules/categories';
export var ArchiveCategoryModalCategoryItem = function (_a) {
    var parent = _a.parent, categories = _a.categories;
    if (!parent) {
        return null;
    }
    var children = categories.filter(function (category) { return isChild(parent, category); });
    return (<>
      <i className={"category-circle category-".concat(parent.color)}/>
      {parent.title}
      {children.length > 0 && (<ul className="list-unstyled ml-2">
          {children.map(function (child) { return (<li key={"category-".concat(parent.id, "-").concat(child.id)}>
              <ArchiveCategoryModalCategoryItem categories={categories} parent={child}/>
            </li>); })}
        </ul>)}
    </>);
};
