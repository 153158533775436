import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var CreatePaymentModal = function (_a) {
    var isOpen = _a.isOpen, disabled = _a.disabled, onCancel = _a.onCancel, onSubmit = _a.onSubmit;
    return (<Modal isOpen={isOpen} toggle={onCancel} zIndex={1050}>
      <ModalHeaderMod toggle={onCancel}>
        {I18n.t('shared.createPayment')}
      </ModalHeaderMod>

      <ModalBody>
        <p className="text-danger">
          {I18n.t('shared.createPaymentDescription')}
        </p>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-secondary" onClick={onCancel}>
          {I18n.t('shared.cancel')}
        </button>
        <button className="btn btn-primary" disabled={disabled} onClick={onSubmit}>
          {I18n.t('teamManagement.billingForm.apply')}
        </button>
      </ModalFooter>
    </Modal>);
};
