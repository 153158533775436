import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
export var FETCH_CALENDAR_INTEGRATION_SETTING_REQUEST = 'timecrowd/CALENDAR_INTEGRATIONS/FETCH_CALENDAR_INTEGRATION_SETTING_REQUEST';
export var FETCH_CALENDAR_INTEGRATION_SETTING_SUCCESS = 'timecrowd/CALENDAR_INTEGRATIONS/FETCH_CALENDAR_INTEGRATION_SETTING_SUCCESS';
export var FETCH_CALENDAR_INTEGRATION_SETTING_FAILURE = 'timecrowd/CALENDAR_INTEGRATIONS/FETCH_CALENDAR_INTEGRATION_SETTING_FAILURE';
export var UPDATE_CALENDAR_INTEGRATION_SETTING_REQUEST = 'timecrowd/CALENDAR_INTEGRATIONS/UPDATE_CALENDAR_INTEGRATION_SETTING_REQUEST';
export var UPDATE_CALENDAR_INTEGRATION_SETTING_SUCCESS = 'timecrowd/CALENDAR_INTEGRATIONS/UPDATE_CALENDAR_INTEGRATION_SETTING_SUCCESS';
export var UPDATE_CALENDAR_INTEGRATION_SETTING_FAILURE = 'timecrowd/CALENDAR_INTEGRATIONS/UPDATE_CALENDAR_INTEGRATION_SETTING_FAILURE';
export var DELETE_CALENDAR_INTEGRATION_SETTING_REQUEST = 'timecrowd/CALENDAR_INTEGRATIONS/DELETE_CALENDAR_INTEGRATION_SETTING_REQUEST';
export var DELETE_CALENDAR_INTEGRATION_SETTING_SUCCESS = 'timecrowd/CALENDAR_INTEGRATIONS/DELETE_CALENDAR_INTEGRATION_SETTING_SUCCESS';
export var DELETE_CALENDAR_INTEGRATION_SETTING_FAILURE = 'timecrowd/CALENDAR_INTEGRATIONS/DELETE_CALENDAR_INTEGRATION_SETTING_FAILURE';
var initialState = {
    isLoading: false,
    item: null,
};
export default function calendarIntegrations(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CALENDAR_INTEGRATION_SETTING_REQUEST:
        case UPDATE_CALENDAR_INTEGRATION_SETTING_REQUEST:
        case DELETE_CALENDAR_INTEGRATION_SETTING_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_CALENDAR_INTEGRATION_SETTING_SUCCESS:
        case UPDATE_CALENDAR_INTEGRATION_SETTING_SUCCESS:
            return __assign(__assign({}, state), { item: action.payload, isLoading: false });
        case FETCH_CALENDAR_INTEGRATION_SETTING_FAILURE:
        case UPDATE_CALENDAR_INTEGRATION_SETTING_FAILURE:
        case DELETE_CALENDAR_INTEGRATION_SETTING_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case DELETE_CALENDAR_INTEGRATION_SETTING_SUCCESS:
            return __assign(__assign({}, state), { item: null, isLoading: false });
        default:
            return state;
    }
}
export var fetchCalendarIntegrationSettingRequest = function () { return ({
    type: FETCH_CALENDAR_INTEGRATION_SETTING_REQUEST,
}); };
export var fetchCalendarIntegrationSettingSuccess = function (item) { return ({
    type: FETCH_CALENDAR_INTEGRATION_SETTING_SUCCESS,
    payload: item,
}); };
export var fetchCalendarIntegrationSettingFailure = function () { return ({
    type: FETCH_CALENDAR_INTEGRATION_SETTING_FAILURE,
}); };
export var fetchCalendarIntegrationSetting = function () {
    return function (dispatch, getState) {
        dispatch(fetchCalendarIntegrationSettingRequest());
        return api2(dispatch, getState, '/api/v2/calendar_integration/setting', 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(response.status === 200)) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(fetchCalendarIntegrationSettingSuccess(item));
                        return [2 /*return*/, { error: false, item: item }];
                    case 2:
                        dispatch(fetchCalendarIntegrationSettingFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateCalendarIntegrationSettingRequest = function () { return ({
    type: UPDATE_CALENDAR_INTEGRATION_SETTING_REQUEST,
}); };
export var updateCalendarIntegrationSettingSuccess = function (item) { return ({
    type: UPDATE_CALENDAR_INTEGRATION_SETTING_SUCCESS,
    payload: item,
}); };
export var updateCalendarIntegrationSettingFailure = function () { return ({
    type: UPDATE_CALENDAR_INTEGRATION_SETTING_FAILURE,
}); };
export var updateCalendarIntegrationSetting = function (params) {
    return function (dispatch, getState) {
        dispatch(updateCalendarIntegrationSettingRequest());
        return api2(dispatch, getState, '/api/v2/calendar_integration/setting', 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(updateCalendarIntegrationSettingSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(updateCalendarIntegrationSettingFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var deleteCalendarIntegrationSettingRequest = function () { return ({
    type: DELETE_CALENDAR_INTEGRATION_SETTING_REQUEST,
}); };
export var deleteCalendarIntegrationSettingSuccess = function () { return ({
    type: DELETE_CALENDAR_INTEGRATION_SETTING_SUCCESS,
}); };
export var deleteCalendarIntegrationSettingFailure = function () { return ({
    type: DELETE_CALENDAR_INTEGRATION_SETTING_FAILURE,
}); };
export var deleteCalendarIntegrationSetting = function () {
    return function (dispatch, getState) {
        dispatch(deleteCalendarIntegrationSettingRequest());
        return api2(dispatch, getState, '/api/v2/calendar_integration/setting', 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(deleteCalendarIntegrationSettingSuccess());
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(deleteCalendarIntegrationSettingFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
