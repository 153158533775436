import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import I18n from '@/constants/i18n';
import { TeamManagementLayoutContainer } from '@/containers/team_management_layout_container';
import { CustomFieldsMasterListItem } from '@/components/custom_fields_master_list_item';
import { CustomFieldsMasterFormModal } from '../custom_fields_master_form_modal';
import Alert from '@/components/alert';
import variants from '@/constants/variants';
import { workspacePath } from '@/utils/workspace_helper';
import { removeDangerNotificationMessage } from '@/modules/notification';
export var CustomFieldsMasterList = function (_a) {
    var teams = _a.teams, defaultCustomFieldsMasters = _a.customFieldsMasters, createCustomFieldsMaster = _a.createCustomFieldsMaster, updateCustomFieldsMaster = _a.updateCustomFieldsMaster, deleteCustomFieldsMaster = _a.deleteCustomFieldsMaster, setNotificationMessage = _a.setNotificationMessage, _b = _a.openURL, openURL = _b === void 0 ? variants.openURL : _b;
    var navigate = useNavigate();
    var params = useParams();
    var _c = React.useState(defaultCustomFieldsMasters), customFieldsMasters = _c[0], setCustomFieldsMasters = _c[1];
    var _d = React.useState(false), isCreating = _d[0], setIsCreating = _d[1];
    var _e = React.useState(false), isShowLimitAlert = _e[0], setIsShowLimitAlert = _e[1];
    var _f = React.useState(null), creatingMaster = _f[0], setCreatingMaster = _f[1];
    var team = teams.find(function (team) { return team.id === Number(params.team_id); });
    var canAdd = false;
    React.useEffect(function () {
        setCustomFieldsMasters(defaultCustomFieldsMasters);
    }, [defaultCustomFieldsMasters]);
    var onAdd = React.useCallback(function () {
        if (team && customFieldsMasters.length >= (team === null || team === void 0 ? void 0 : team.custom_fields_limit)) {
            setIsShowLimitAlert(true);
            return;
        }
        setCreatingMaster({
            team_id: team === null || team === void 0 ? void 0 : team.id,
            name: '',
            field_type: 'text',
            requirement: 'none',
            unique: false,
            unit: '',
            visibility: 'owner',
        });
    }, [team, customFieldsMasters]);
    var dispatch = useDispatch();
    var handleCloseCreateModal = React.useCallback(function () {
        dispatch(removeDangerNotificationMessage());
        setCreatingMaster(null);
    }, [dispatch]);
    var onConfirmCreate = React.useCallback(function (params) {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsCreating(true);
                        return [4 /*yield*/, createCustomFieldsMaster(params)];
                    case 1:
                        res = _a.sent();
                        setIsCreating(false);
                        if (!res.error) {
                            handleCloseCreateModal();
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [createCustomFieldsMaster, handleCloseCreateModal]);
    var onClickLink = React.useCallback(function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    }, [openURL]);
    var limitExceededMessage = React.useMemo(function () {
        return "\n        ".concat(I18n.t('shared.customFieldsReachedLimit1'), "\n        <br />\n        ").concat(I18n.t('shared.customFieldsReachedLimit2'), "\n    ");
    }, []);
    return (<TeamManagementLayoutContainer currentPage="custom_fields" saveButtonForPC={team &&
            team.is_custom_fields_allowed && (<button className="btn-major-action btn btn-primary" disabled={canAdd} onClick={onAdd}>
            {I18n.t('shared.newCustomField')}
          </button>)} saveButtonForSP={team &&
            team.is_custom_fields_allowed && (<button className="btn-mobile-header-action btn btn-link" disabled={canAdd} onClick={onAdd}>
            {I18n.t('shared.newCustomField')}
          </button>)} team={team} title={I18n.t('shared.customFields')}>
      <p>
        {I18n.t('shared.customFieldsDescription1')}
        <br />
        {I18n.t('shared.customFieldsDescription2')}
      </p>

      {team && team.is_custom_fields_allowed ? (<>
          <p className="h3 font-weight-normal mt-5">
            {I18n.t('shared.customFieldList')}
            <span className="ml-2">
              {"".concat(customFieldsMasters.length, " / ").concat(team === null || team === void 0 ? void 0 : team.custom_fields_limit)}
            </span>
          </p>

          {customFieldsMasters.length < 1 ? (<p className="mt-3">{I18n.t('shared.noCustomFieldFound')}</p>) : (<div className="mt-3">
              {customFieldsMasters.map(function (customFieldsMaster) { return (<div key={customFieldsMaster.id} className="mt-1">
                  <CustomFieldsMasterListItem customFieldsMaster={customFieldsMaster} customFieldsMasters={customFieldsMasters} deleteCustomFieldsMaster={deleteCustomFieldsMaster} setNotificationMessage={setNotificationMessage} updateCustomFieldsMaster={updateCustomFieldsMaster} onNavigateToCategories={function () {
                        return navigate(workspacePath("/teams/".concat(team === null || team === void 0 ? void 0 : team.id, "/categories")));
                    }}/>
                </div>); })}
            </div>)}
          {creatingMaster !== null && (<CustomFieldsMasterFormModal isOpen customFieldsMaster={creatingMaster} customFieldsMasters={customFieldsMasters} isSaving={isCreating} onCancel={handleCloseCreateModal} onSave={onConfirmCreate}/>)}
          <Alert color="warning" isHtml={true} isOpen={isShowLimitAlert} message={limitExceededMessage} onClose={function () { return setIsShowLimitAlert(false); }}>
            <div className="mt-2">
              <button className="btn btn-secondary" onClick={function () { return setIsShowLimitAlert(false); }}>
                {I18n.t('shared.close')}
              </button>
              <button className="btn btn-primary" onClick={function () { return openURL('https://timecrowd.net/contacts/new'); }}>
                {I18n.t('shared.contactSupport')}
              </button>
            </div>
          </Alert>
        </>) : (<div className="mt-5">
          <p>{I18n.t('shared.customFieldsNotAllowed1')}</p>
          <ol className="">
            <li>{I18n.t('shared.customFieldsNotAllowed2')}</li>
            <li>{I18n.t('shared.customFieldsNotAllowed3')}</li>
          </ol>
          <p>{I18n.t('shared.customFieldsNotAllowed4')}</p>

          <p className="mt-4">
            <a className="btn btn-primary" href="https://timecrowd.net/contacts/new" onClick={onClickLink}>
              {I18n.t('shared.contactSupport')}
            </a>
          </p>
        </div>)}
    </TeamManagementLayoutContainer>);
};
