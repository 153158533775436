import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n from '@/constants/i18n';
import { fetchUserWorkspacesCalendarIntegrationFilters } from '@/modules/workspaces_calendar_integration_filters';
import { WorkspaceSelect } from '@/components/workspace_select';
var WorkspacesCalendarIntegrationWorkspaceSelectContainer = function (_a) {
    var workspaceId = _a.workspaceId, onSelect = _a.onSelect;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var workspaces = useSelector(function (state) { return state.workspaces; });
    var workspacesCalendarIntegrationSettings = useSelector(function (state) { return state.workspacesCalendarIntegrationSettings; });
    var dispatch = useDispatch();
    var handleSelect = function (workspace) {
        onSelect(workspace.id);
    };
    React.useEffect(function () {
        var setting = workspacesCalendarIntegrationSettings.items.find(function (setting) {
            return setting.workspaces_workspace_id === workspaceId;
        });
        if (!setting) {
            return;
        }
        dispatch(fetchUserWorkspacesCalendarIntegrationFilters(setting.id));
    }, [dispatch, workspaceId, workspacesCalendarIntegrationSettings.items]);
    if (!workspaces.isLoaded ||
        workspacesCalendarIntegrationSettings.status !== 'loaded') {
        return null;
    }
    var workspaceIds = workspacesCalendarIntegrationSettings.items.map(function (setting) { return setting.workspaces_workspace_id; });
    var filteredWorkspaces = workspaces.items.filter(function (workspace) {
        return workspaceIds.includes(workspace.id);
    });
    var workspace = filteredWorkspaces.find(function (workspace) { return workspace.id === workspaceId; });
    return (<>
      <WorkspaceSelect defaultWorkspace={workspace} isOpen={isOpen} workspaces={filteredWorkspaces} onSelect={handleSelect} onToggle={function () { return setIsOpen(!isOpen); }}/>
      {!workspace && (<p className="mt-1 text-danger">
          {I18n.t('shared.pleaseSelectCalendarIntegrationSettingWorkspace')}
        </p>)}
    </>);
};
export default WorkspacesCalendarIntegrationWorkspaceSelectContainer;
