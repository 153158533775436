import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
export var FETCH_WORKSPACES_INVITATIONS_REQUEST = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_INVITATIONS_REQUEST';
export var FETCH_WORKSPACES_INVITATIONS_SUCCESS = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_INVITATIONS_SUCCESS';
export var FETCH_WORKSPACES_INVITATIONS_FAILURE = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_INVITATIONS_FAILURE';
export var RENEW_WORKSPACES_INVITATION_REQUEST = 'timecrowd/WORKSPACES/RENEW_WORKSPACES_INVITATION_REQUEST';
export var RENEW_WORKSPACES_INVITATION_SUCCESS = 'timecrowd/WORKSPACES/RENEW_WORKSPACES_INVITATION_SUCCESS';
export var RENEW_WORKSPACES_INVITATION_FAILURE = 'timecrowd/WORKSPACES/RENEW_WORKSPACES_INVITATION_FAILURE';
export var RESET_WORKSPACES_INVITATIONS = 'timecrowd/WORKSPACES/RESET_WORKSPACES_INVITATIONS';
export var initialState = {
    isLoading: false,
    isLoaded: false,
    items: [],
};
export default function workspaces(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_WORKSPACES_INVITATIONS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isLoaded: false });
        case RENEW_WORKSPACES_INVITATION_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_WORKSPACES_INVITATIONS_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, isLoading: false, isLoaded: true });
        case RENEW_WORKSPACES_INVITATION_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.map(function (item) {
                    return item.id === action.payload.id ? action.payload : item;
                }), isLoading: false });
        case FETCH_WORKSPACES_INVITATIONS_FAILURE:
        case RENEW_WORKSPACES_INVITATION_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case RESET_WORKSPACES_INVITATIONS:
            return initialState;
        default:
            return state;
    }
}
export var fetchWorkspacesInvitationsRequest = function () { return ({
    type: FETCH_WORKSPACES_INVITATIONS_REQUEST,
}); };
export var fetchWorkspacesInvitationsSuccess = function (items) { return ({
    type: FETCH_WORKSPACES_INVITATIONS_SUCCESS,
    payload: items,
}); };
export var fetchWorkspacesInvitationsFailure = function () { return ({
    type: FETCH_WORKSPACES_INVITATIONS_FAILURE,
}); };
export var fetchWorkspacesInvitations = function (teamId) {
    if (teamId === void 0) { teamId = ''; }
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesInvitationsRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/invitations?team_id=".concat(teamId), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchWorkspacesInvitationsSuccess(items));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchWorkspacesInvitationsFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var renewWorkspacesInvitationRequest = function () { return ({
    type: RENEW_WORKSPACES_INVITATION_REQUEST,
}); };
export var renewWorkspacesInvitationSuccess = function (item) { return ({
    type: RENEW_WORKSPACES_INVITATION_SUCCESS,
    payload: item,
}); };
export var renewWorkspacesInvitationFailure = function () { return ({
    type: RENEW_WORKSPACES_INVITATION_FAILURE,
}); };
export var renewWorkspacesInvitation = function (id, teamId) {
    if (teamId === void 0) { teamId = ''; }
    return function (dispatch, getState) {
        dispatch(renewWorkspacesInvitationRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/invitations/".concat(id, "/renew"), 'PATCH', { team_id: teamId }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item, item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(renewWorkspacesInvitationSuccess(item));
                        return [2 /*return*/, { error: false }];
                    case 2:
                        item = void 0;
                        if (!(response.status === 422)) return [3 /*break*/, 4];
                        return [4 /*yield*/, response.json()];
                    case 3:
                        item = _a.sent();
                        _a.label = 4;
                    case 4:
                        dispatch(renewWorkspacesInvitationFailure());
                        return [2 /*return*/, { error: true, errors: item === null || item === void 0 ? void 0 : item.errors }];
                }
            });
        }); });
    };
};
export var resetWorkspacesInvitations = function () { return ({
    type: RESET_WORKSPACES_INVITATIONS,
}); };
