import React from 'react';
import range from 'lodash/range';
export var CalendarDateHourCol = function (_a) {
    var divisionHeight = _a.divisionHeight, calendarUnitMinutes = _a.calendarUnitMinutes;
    var hours = range(24).map(function (hour) {
        return hour < 10 ? '0' + hour : hour.toString();
    });
    return (<div className="timeline">
      {hours.map(function (hour, index) {
            return (<div key={"timeline-hours-".concat(hour)} className={['timeline-hour', 'timeline-hour-' + index].join(' ')} style={{ height: divisionHeight * (60 / calendarUnitMinutes) }}>
            <div className="timeline-hour-header">
              <span className="timeline-hour-label">{hour}</span>
            </div>
          </div>);
        })}
    </div>);
};
