import { __assign, __awaiter, __generator } from "tslib";
import queryString from 'query-string';
import api from '@/api';
import api2 from '@/api2';
import { setCategories, moveCategoryLowerSuccess, moveCategoryHigherSuccess, } from '@/modules/categories';
import { stateWithoutWorkspaces } from '@/modules/workspaces';
import { workspaceTeams } from '@/utils/workspace_helper';
var fetchCategoriesWithTeams = function (dispatch, getState, query, teams) { return __awaiter(void 0, void 0, void 0, function () {
    var response, categories, merged;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, api2(dispatch, getState, "/api/v2/categories".concat(query), 'GET')];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 5];
                return [4 /*yield*/, response.json()];
            case 2:
                categories = _a.sent();
                if (!(teams.length < 1)) return [3 /*break*/, 4];
                return [4 /*yield*/, fetchTeams(dispatch, getState)];
            case 3:
                teams = _a.sent();
                if (teams.error) {
                    return [2 /*return*/, teams];
                }
                _a.label = 4;
            case 4:
                merged = mergeCategoriesWithTeams(categories, workspaceTeams(teams));
                dispatch(setCategories(merged));
                return [2 /*return*/, merged];
            case 5: return [2 /*return*/, { error: true }];
        }
    });
}); };
var fetchTeams = function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, api2(dispatch, getState, '/api/v2/teams', 'GET')];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2: return [2 /*return*/, _a.sent()];
            case 3: return [2 /*return*/, { error: true }];
        }
    });
}); };
var mergeCategoriesWithTeams = function (categories, teams) {
    var teamById = teams.reduce(function (acc, team) {
        acc[team.id] = team;
        return acc;
    }, {});
    var merged = categories.map(function (category) { return (__assign(__assign({}, category), { team: teamById[category.team_id] })); });
    // NOTE: タイミングによってチーム取得のスコープと一致していないケースが発生するため、マッチしないカテゴリーは除外する
    var filtered = merged.filter(function (category) { return category.team; });
    return filtered;
};
export var fetchCategories = function (params) {
    if (params === void 0) { params = {}; }
    var query = params ? "?".concat(queryString.stringify(params)) : '';
    return function (dispatch, getState) {
        return fetchCategoriesWithTeams(dispatch, getState, query, getState().teams.items);
    };
};
export var fetchAllWorkspacesCategories = function () {
    return function (dispatch, getState) {
        return fetchCategoriesWithTeams(dispatch, stateWithoutWorkspaces(getState), '', []);
    };
};
export var createTeamCategory = function (teamId, params) {
    return function (dispatch, getState) {
        return api2(dispatch, getState, "/api/v2/categories", 'POST', __assign(__assign({}, params), { category: __assign(__assign({}, params.category), { team_id: teamId }) })).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        if (response.ok) {
                            dispatch(fetchCategories({ team_id: teamId }));
                            return [2 /*return*/, { error: false }];
                        }
                        return [2 /*return*/, { error: true, errors: item.errors }];
                }
            });
        }); });
    };
};
export var copyTeamCategory = function (teamId, params) {
    return function (dispatch, getState) {
        return api2(dispatch, getState, '/api/v2/categories/copy', 'POST', __assign(__assign({}, params), { category: __assign({ team_id: teamId }, params.category) })).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        if (response.ok) {
                            dispatch(fetchCategories({ team_id: teamId }));
                            return [2 /*return*/, { error: false }];
                        }
                        return [2 /*return*/, { error: true, errors: item.errors }];
                }
            });
        }); });
    };
};
export var updateTeamCategory = function (id, params) {
    return function (dispatch, getState) {
        return api2(dispatch, getState, "/api/v2/categories/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        if (response.ok) {
                            dispatch(fetchCategories({ team_id: item.team_id }));
                            return [2 /*return*/, { error: false }];
                        }
                        return [2 /*return*/, { error: true, errors: item.errors }];
                }
            });
        }); });
    };
};
export var destroyTeamCategory = function (teamId, id) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "teams/".concat(teamId, "/categories/").concat(id), 'DELETE').then(function (response) {
            if (!response.error) {
                dispatch(fetchCategories({ team_id: teamId }));
            }
            return response;
        });
    };
};
export var moveLowerCategoryPosition = function (id, withArchived) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "categories/".concat(id, "/move_lower?with_archived=").concat(withArchived), 'PATCH').then(function (category) {
            if (!category.error) {
                dispatch(moveCategoryLowerSuccess(category));
            }
            return category;
        });
    };
};
export var moveHigherCategoryPosition = function (id, withArchived) {
    return function (dispatch, getState) {
        return api(dispatch, getState, "categories/".concat(id, "/move_higher?with_archived=").concat(withArchived), 'PATCH').then(function (category) {
            if (!category.error) {
                dispatch(moveCategoryHigherSuccess(category));
            }
            return category;
        });
    };
};
export var fetchUserCategories = function (user_id) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var teams, response, categories, merged;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    teams = getState().teams.items;
                    return [4 /*yield*/, api2(dispatch, getState, "/api/v2/users/".concat(user_id, "/categories"), 'GET')];
                case 1:
                    response = _a.sent();
                    if (!response.ok) return [3 /*break*/, 5];
                    return [4 /*yield*/, response.json()];
                case 2:
                    categories = _a.sent();
                    if (!(teams.length < 1)) return [3 /*break*/, 4];
                    return [4 /*yield*/, fetchTeams(dispatch, getState)];
                case 3:
                    teams = _a.sent();
                    if (teams.error) {
                        return [2 /*return*/, teams];
                    }
                    _a.label = 4;
                case 4:
                    merged = mergeCategoriesWithTeams(categories, workspaceTeams(teams));
                    dispatch(setCategories(merged));
                    return [2 /*return*/, { categories: merged, error: false }];
                case 5: return [2 /*return*/, { error: true }];
            }
        });
    }); };
};
