import { __assign } from "tslib";
import React from 'react';
import { TabContent, TabPane, Dropdown, DropdownMenu, DropdownToggle, } from 'reactstrap';
import MediaQuery from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import I18n from '@/constants/i18n';
import { BREAKPOINT_MD } from '@/constants/styles';
import Icon from '@/components/atoms/icon';
import MobileHeader from '@/components/atoms/mobile_header';
import MobilePage from '@/components/molecules/mobile_page';
import { CategorySelector } from '@/components/molecules/category_selector';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import MergeModals from './merge_modals';
import OmitLabel from '@/components/atoms/omit_label';
import { EditTaskConfirmModal } from './edit_task_confirm_modal';
import { CategoryInfoContainer } from '@/containers/category_info_container';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
var EditTaskForm = function (props) {
    var _a = props.isFocus, isFocus = _a === void 0 ? true : _a, isPC = props.isPC, isChromeExtension = props.isChromeExtension, onCheck = props.onCheck, onSubmit = props.onSubmit, toggle = props.toggle, onOpenModal = props.onOpenModal, onOpenDeleteModal = props.onOpenDeleteModal, _b = props.tag, Tag = _b === void 0 ? 'div' : _b, teams = props.teams, _c = props.categories, categories = _c === void 0 ? [] : _c, from = props.from;
    var _d = React.useState(undefined), tab = _d[0], setTab = _d[1];
    var _e = React.useState(props.task), task = _e[0], setTask = _e[1];
    var _f = React.useState(props.team), team = _f[0], setTeam = _f[1];
    var _g = React.useState(props.category), category = _g[0], setCategory = _g[1];
    var _h = React.useState(false), isOpenConfirmModal = _h[0], setIsOpenConfirmModal = _h[1];
    var taskFormTitle = React.useRef(null);
    React.useEffect(function () {
        if (isFocus || isPC || isChromeExtension) {
            onFocusTaskTitle();
        }
    }, [isFocus, isPC, isChromeExtension]);
    var onClickConfirm = function () {
        setIsOpenConfirmModal(true);
    };
    var onCancelConfirm = function () {
        setIsOpenConfirmModal(false);
    };
    var onClickSave = function () {
        var params = {
            task: {
                title: task.title,
                url: task.url,
                team_id: team.id,
                parent_id: category.id,
            },
        };
        return onCheck(task.id, params.task).then(function (sameKeyTask) {
            if (isEmpty(sameKeyTask)) {
                return onSubmit(task.id, params).then(function (newTask) {
                    if (!newTask.error) {
                        toggle();
                    }
                });
            }
            else {
                var modalTypeName = sameKeyTask.ancestry_changed
                    ? 'TIME_ENTRY_MERGE_MODAL'
                    : 'TASK_MERGE_MODAL';
                onOpenModal(modalTypeName);
            }
        });
    };
    var onFocusTaskTitle = function () {
        // @ts-ignore
        taskFormTitle.current.focus();
    };
    var toggleTab = function (toggleTo) {
        if (tab !== toggleTo) {
            setTab(toggleTo);
        }
        else {
            setTab(undefined);
        }
    };
    var onChangeTitle = function (e) {
        var newTask = __assign(__assign({}, task), { title: e.target.value });
        setTask(newTask);
    };
    var onKeyPressTitle = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            onClickSave();
        }
    };
    var onChangeCategory = function (category) {
        setTab(undefined);
        setTeam(category.team);
        setCategory(category);
        onFocusTaskTitle();
    };
    var onClickDelete = function () {
        onOpenDeleteModal();
    };
    return (<div>
      <MediaQuery minWidth={BREAKPOINT_MD}>
        <Tag className="task-form">
          <div className="task-form-body">
            <textarea ref={taskFormTitle} className="form-control task-form-title" disabled={!!task.url} maxLength={700} placeholder={I18n.t('quicktask.insertTaskTitle')} rows={1} value={task.title} onChange={onChangeTitle} onKeyPress={onKeyPressTitle}/>
            <div className="ml-auto task-form-controls">
              <button className="btn-return btn btn-link" onClick={toggle}>
                <span>{I18n.t('shared.cancel')}</span>
              </button>
              <button className="btn-major-action btn btn-primary" disabled={task.title === ''} onClick={onClickConfirm}>
                <span>{I18n.t('shared.update')}</span>
              </button>
            </div>
          </div>
          <ul className="task-form-attrs nav nav-tabs">
            <a className={"".concat(tab === 'category' ? 'active ' : '', "nav-link")} onClick={function () {
            toggleTab('category');
        }}>
              <WorkspaceAvatarContainer className="mr-1 ml-n1" workspaceId={team.workspaces_workspace_id}/>
              <span className="mr-1 text-overflow" style={{ width: '180px' }}>
                <CategoryCircleIcon color={category.color}/>
                <span>
                  {category.title} - {team.name}
                </span>
              </span>
              <CategoryInfoContainer category={category} className="mx-1"/>
              <Icon className="ml-auto" name={tab === 'category' ? 'chevron-up' : 'chevron-down'}/>
              {tab === 'category' && <i className="arrow-block-up"/>}
            </a>
            <a className="nav-link disabled">
              <OmitLabel maxWidth="18rem">
                <Icon className="mr-1" name="link"/>
                <span>{task.url}</span>
              </OmitLabel>
            </a>
          </ul>
          <TabContent activeTab={tab}>
            <TabPane tabId="category">
              <CategorySelector categories={categories} category={category} isOpened={tab === 'category'} setCategory={onChangeCategory} teams={teams}/>
            </TabPane>
          </TabContent>
          <MergeModals category={category} from={from} task={task} team={team} toggle={toggle}/>
        </Tag>
      </MediaQuery>
      <MediaQuery maxWidth={BREAKPOINT_MD}>
        <MobilePage>
          <MobileHeader>
            <button className="btn-mobile-header-action btn-mobile-header-action-left btn btn-link" onClick={toggle}>
              <Icon name="chevron-left"/>
            </button>
            <span className="mobile-header-title">
              {I18n.t('taskEdit.editTask')}
            </span>
            <button className="btn-mobile-header-action btn-mobile-header-action-text btn-mobile-header-action-primary btn btn-link" onClick={onClickConfirm}>
              {I18n.t('shared.change')}
            </button>
          </MobileHeader>
          <div className="container p-2 h-100">
            <div className="form-group">
              <label className="col-form-label">
                {I18n.t('taskEdit.taskName')}
              </label>
              <input ref={taskFormTitle} className="form-control" disabled={!!task.url} placeholder={I18n.t('quicktask.insertTaskTitle')} value={task.title} onChange={onChangeTitle} onKeyPress={onKeyPressTitle}/>
            </div>
            <div className="form-group">
              <label className="col-form-label">
                {I18n.t('shared.category')}
              </label>
              <Dropdown isOpen={tab === 'category'} toggle={function () { return toggleTab('category'); }}>
                <DropdownToggle caret className="w-100">
                  <CategoryCircleIcon color={category.color}/>
                  <span>
                    {category.title} - {team.name}
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <CategorySelector categories={categories} category={category} isOpened={tab === 'category'} setCategory={onChangeCategory} teams={teams}/>
                </DropdownMenu>
              </Dropdown>
            </div>
            {!!task.url && (<div className="form-group">
                <label className="col-form-label">{I18n.t('shared.url')}</label>
                <div className="text-muted">{task.url}</div>
              </div>)}
            <hr className="my-3"/>
            <button className="text-danger btn btn-link" onClick={onClickDelete}>
              {I18n.t('shared.delete')}
            </button>
          </div>
        </MobilePage>
        <MergeModals category={category} from={from} task={task} team={team} toggle={toggle}/>
      </MediaQuery>
      <EditTaskConfirmModal currentCategory={props.category} currentTask={props.task} isOpen={isOpenConfirmModal} newCategory={category} newTask={task} onClose={onCancelConfirm} onSave={onClickSave}/>
    </div>);
};
export default EditTaskForm;
