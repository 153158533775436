import React from 'react';
import CategoryFormModalContainer from '@/containers/category_form_modal_container';
import { unsetParentCategory } from '@/components/categories//helpers';
export var EditCategoryFormModal = function (_a) {
    var isOpen = _a.isOpen, team = _a.team, parentCategory = _a.parentCategory, selectedCategory = _a.selectedCategory, copySourceCategory = _a.copySourceCategory, categories = _a.categories, customFieldsMasters = _a.customFieldsMasters, customFieldsValues = _a.customFieldsValues, categoryTaskTree = _a.categoryTaskTree, toggleCategoryModal = _a.toggleCategoryModal, handleTitle = _a.handleTitle, onSelectParentCategory = _a.onSelectParentCategory, setNotificationMessage = _a.setNotificationMessage, fetchCustomFieldsValues = _a.fetchCustomFieldsValues, selectColor = _a.selectColor, copyTeamCategory = _a.copyTeamCategory, createTeamCategory = _a.createTeamCategory, updateTeamCategory = _a.updateTeamCategory;
    var selectableCategories = React.useMemo(function () {
        var filteredCategories = categories.filter(function (category) {
            var tree = String(category.ancestry)
                .split('/')
                .map(function (id) {
                return parseInt(id, 10);
            });
            return (category.id !== selectedCategory.id &&
                category.id !== (copySourceCategory === null || copySourceCategory === void 0 ? void 0 : copySourceCategory.id) &&
                !tree.includes(selectedCategory.id));
        });
        return [unsetParentCategory(team)].concat(filteredCategories);
    }, [selectedCategory, categories, copySourceCategory, team]);
    var createCategory = function (customFieldsValues) {
        var _a;
        var params = {
            category: {
                title: selectedCategory.title,
                color: selectedCategory.color,
                parent_id: parentCategory.id,
            },
            source_id: (_a = copySourceCategory === null || copySourceCategory === void 0 ? void 0 : copySourceCategory.id) !== null && _a !== void 0 ? _a : '',
            custom_fields_values: customFieldsValues,
        };
        var action = copySourceCategory ? copyTeamCategory : createTeamCategory;
        return action(team.id, params).then(function (res) {
            if (!res.error) {
                toggleCategoryModal();
            }
            return res;
        });
    };
    var updateCategory = function (customFieldsValues) {
        var params = {
            category: {
                title: selectedCategory.title,
                color: selectedCategory.color,
                parent_id: selectedCategory.parent_id,
            },
            custom_fields_values: customFieldsValues,
        };
        return updateTeamCategory(selectedCategory.id, params).then(function (res) {
            if (!res.error) {
                toggleCategoryModal();
            }
            return res;
        });
    };
    return (<CategoryFormModalContainer categories={selectableCategories} category={selectedCategory} categoryTaskTree={categoryTaskTree} copySourceCategory={copySourceCategory} customFieldsMasters={customFieldsMasters} customFieldsValues={customFieldsValues} fetchCustomFieldsValues={fetchCustomFieldsValues} isOpen={isOpen} parentCategory={parentCategory} setNotificationMessage={setNotificationMessage} team={team} onChangeTitle={handleTitle} onCreate={createCategory} onSelectCategory={onSelectParentCategory} onSelectColor={selectColor} onToggleModal={function () { return toggleCategoryModal(); }} onUpdate={updateCategory}/>);
};
