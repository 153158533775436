import { __assign } from "tslib";
import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
import Alert from '@/components/alert';
import CustomCheck from '@/components/atoms/custom_check';
export var ReportAlerts = function (_a) {
    var onNavigateToPayment = _a.onNavigateToPayment, onCloseAlert = _a.onCloseAlert, teams = _a.teams, hiddenTeamIds = _a.hiddenTeamIds, isOpenAlerts = _a.isOpenAlerts, openURL = _a.openURL;
    var paiableTeams = teams.filter(function (team) { return team.payable; });
    var _b = React.useState(teams.reduce(function (ids, _a) {
        var _b;
        var id = _a.id;
        return (__assign(__assign({}, ids), (_b = {}, _b[id] = false, _b)));
    }, {})), permanentIds = _b[0], setPermanentIds = _b[1];
    var paymentMessage = function (_a) {
        var team = _a.team;
        if (team.payment_required) {
            if (team.can_manage) {
                return I18n.t('report.trialEndedToOwner', { name: team.name });
            }
            else {
                return I18n.t('report.trialEndedToMember', { name: team.name });
            }
        }
        else {
            var trialEndDays = moment(team.trial_end_at).diff(moment(), 'd');
            return I18n.t('report.trialDuring', {
                period: trialEndDays,
                name: team.name,
            });
        }
    };
    return (<>
      {paiableTeams
            .filter(function (team) { return !hiddenTeamIds.includes(team.id); })
            .map(function (team, index) {
            return (<Alert key={"team-".concat(team.id, "-payment-alertbar")} isHtml autoClose={false} color="warning" isOpen={isOpenAlerts[index]} message={paymentMessage({ team: team })} style={{
                    top: 84 + 18 * index,
                }} onClose={function () { return onCloseAlert(team, index, permanentIds[team.id]); }}>
              <div className="mt-2">
                <CustomCheck checked={permanentIds[team.id]} className="mt-2" onChange={function (_a) {
                    var _b;
                    var checked = _a.target.checked;
                    return setPermanentIds(__assign(__assign({}, permanentIds), (_b = {}, _b[team.id] = checked, _b)));
                }}>
                  {I18n.t('shared.dontShowThisMessage')}
                </CustomCheck>
                <div className="mt-2">
                  {team.root && team.can_manage && (<>
                      <button className="mr-1 btn btn-primary" onClick={function () {
                        onNavigateToPayment(team.id);
                    }}>
                        {I18n.t('shared.subscribePaidPlan')}
                      </button>
                      <button className="mr-1 btn btn-secondary" onClick={function () {
                        openURL('https://timecrowd.net/contacts/new');
                    }}>
                        {I18n.t('shared.contactForExtendTrial')}
                      </button>
                    </>)}
                  <button className="btn btn-secondary" onClick={function () {
                    return onCloseAlert(team, index, permanentIds[team.id]);
                }}>
                    {I18n.t('shared.close')}
                  </button>
                </div>
              </div>
            </Alert>);
        })}
    </>);
};
