import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ClassNames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
import { useWorkspaceId } from '@/hooks/use_workspace_id';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
import { WorkspacesAccordionSettings } from './workspaces_accordion_settings';
import groupBy from 'lodash/groupBy';
import { sortWorkspaces } from '@/utils/workspace_helper';
import { saveLastOpenedWorkspaceId, loadLastOpenedWorkspaceId, } from './helpers';
import { CrossTeamReportLongTermProgressContainer } from '@/containers/cross_team_report_long_term_progress_container';
export var WorkspacesAccordion = function (props) {
    var _a;
    var currentWorkspaceId = useWorkspaceId();
    var navigate = useNavigate();
    var workspaces = props.workspaces, workingUsersCount = props.workingUsersCount, currentRoute = props.currentRoute, teams = props.teams, user = props.user;
    var _b = React.useState(currentWorkspaceId !== null && currentWorkspaceId !== void 0 ? currentWorkspaceId : (_a = workspaces[0]) === null || _a === void 0 ? void 0 : _a.id), openedWorkspaceId = _b[0], setOpenedWorkspaceId = _b[1];
    var teamsByWorkspaceId = groupBy(teams, 'workspaces_workspace_id');
    var sortedWorkspaces = sortWorkspaces(workspaces);
    var isEnabledCrossTeamReport = user.enabled_features.includes('cross_team_report_feature');
    React.useEffect(function () {
        if (currentWorkspaceId) {
            setOpenedWorkspaceId(currentWorkspaceId);
        }
        else {
            loadLastOpenedWorkspaceId().then(function (lastOpenedWorkspaceId) {
                var _a;
                setOpenedWorkspaceId(lastOpenedWorkspaceId !== null && lastOpenedWorkspaceId !== void 0 ? lastOpenedWorkspaceId : (_a = workspaces[0]) === null || _a === void 0 ? void 0 : _a.id);
            });
        }
    }, [currentWorkspaceId, workspaces]);
    var handleChangeOpenedWorkspaceId = function (workspaceId) {
        setOpenedWorkspaceId(workspaceId);
        saveLastOpenedWorkspaceId(workspaceId);
    };
    return (<>
      <div className="hidden-sm-down">
        <div className="navbar-minor-group p-1 navbar-nav"/>
        <ul className="navbar-nav">
          <li className="nav-item text-white small my-1">
            {I18n.t('shared.workspace')}
          </li>
          {sortedWorkspaces.map(function (workspace) {
            var _a, _b;
            var id = workspace.id, name = workspace.name, role = workspace.role;
            var isOpened = id === openedWorkspaceId;
            var isCurrent = id === currentWorkspaceId;
            var pathPrefix = "/app/workspaces/".concat(id);
            var hasOwnerTeam = (_a = teamsByWorkspaceId[id]) === null || _a === void 0 ? void 0 : _a.some(function (_a) {
                var can_manage = _a.can_manage;
                return can_manage;
            });
            var workspaceWorkingUsersCount = (_b = workingUsersCount[id]) !== null && _b !== void 0 ? _b : 0;
            var nameTargetId = "workspace-".concat(id, "-name");
            var settingsTargetId = "workspace-".concat(id, "-settings");
            return (<li key={id} className="nav-item">
                <div className={"nav-link text-white d-flex align-items-center pr-1 !tw-cursor-pointer ".concat(isCurrent && !isOpened ? 'active' : '')} onClick={function () {
                    return handleChangeOpenedWorkspaceId(isOpened ? 0 : id);
                }}>
                  <WorkspaceAvatarContainer force className="mr-2" workspaceId={id}/>
                  <span className="w-100 text-truncate" id={nameTargetId}>
                    {name}
                  </span>
                  <UncontrolledTooltip autohide delay={{
                    show: 850,
                    hide: 0,
                }} fade={false} target={nameTargetId}>
                    {name}
                  </UncontrolledTooltip>
                  <Icon className="mr-0" name={"chevron-".concat(isOpened ? 'up' : 'down', " mr-1")}/>
                  {role === 'owner' && (<>
                      <a className="nav-link text-overflow-unset px-1 mb-0 py-0" id={settingsTargetId} onClick={function (e) {
                        e.stopPropagation();
                        e.preventDefault();
                        navigate("".concat(pathPrefix, "/members"));
                    }}>
                        <Icon className="mr-0" name="gear" size="md"/>
                      </a>
                      <UncontrolledTooltip autohide delay={{
                        show: 850,
                        hide: 0,
                    }} fade={false} target={settingsTargetId}>
                        {I18n.t('mainmenu.workspaceSettings')}
                      </UncontrolledTooltip>
                    </>)}
                </div>
                {isOpened && (<ul className="navbar-major-group navbar-nav ml-2 small mb-2">
                    <li className="members nav-item">
                      <Link className={ClassNames({
                        'nav-link': true,
                        active: currentRoute.indexOf("".concat(pathPrefix, "/member/")) > -1,
                    })} to={"".concat(pathPrefix, "/member/realtime")}>
                        <Icon name="user"/>
                        {workspaceWorkingUsersCount > 0 && (<span className={ClassNames({
                            'badge badge-pill badge-default': true,
                        })}>
                            {workspaceWorkingUsersCount}
                          </span>)}
                        {I18n.t('mainmenu.members')}
                      </Link>
                    </li>
                    {(workspaces.length > 1 || hasOwnerTeam) && (<li className="nav-item">
                        <Link className={ClassNames({
                            reports: true,
                            'nav-link': true,
                            active: currentRoute.indexOf("".concat(pathPrefix, "/report")) > -1,
                        })} to={"".concat(pathPrefix, "/report")}>
                          <Icon name="graph-pie"/>
                          {I18n.t('mainmenu.report')}
                        </Link>
                      </li>)}
                    {isEnabledCrossTeamReport && hasOwnerTeam && (<li className="nav-item">
                        <Link className={ClassNames({
                            reports: true,
                            'nav-link': true,
                            active: currentRoute.indexOf("".concat(pathPrefix, "/cross_team_report")) > -1,
                        })} to={"".concat(pathPrefix, "/cross_team_report")}>
                          <Icon name="graph-pie"/>
                          {I18n.t('shared.crossTeamReport')}
                          <CrossTeamReportLongTermProgressContainer workspace={workspace}/>
                        </Link>
                      </li>)}
                    <WorkspacesAccordionSettings currentRoute={currentRoute} teamsByWorkspaceId={teamsByWorkspaceId} workspace={workspace}/>
                  </ul>)}
              </li>);
        })}
        </ul>
      </div>

      {/* モバイルメニュー表示 */}
      <div className="hidden-md-up">
        <ul className="flex-column nav">
          {sortedWorkspaces.map(function (workspace) {
            var id = workspace.id, name = workspace.name, role = workspace.role;
            var isOpened = id === openedWorkspaceId;
            return (<li key={id} className="nav-item">
                <div className="nav-link d-flex align-items-center pr-1 !tw-cursor-pointer" onClick={function () { return setOpenedWorkspaceId(isOpened ? 0 : id); }}>
                  <WorkspaceAvatarContainer force className="mr-2" workspaceId={id}/>
                  <span className="w-100 text-truncate">{name}</span>
                  <Icon className="mr-0" name={"chevron-".concat(isOpened ? 'up' : 'down')}/>
                  {role === 'owner' && (<a className="nav-link text-overflow-unset px-2 mb-0 py-0 ml-4" onClick={function (e) {
                        e.stopPropagation();
                        e.preventDefault();
                        navigate("/app/workspaces/".concat(id, "/members"));
                    }}>
                      <Icon className="mr-0" name="gear" size="md"/>
                    </a>)}
                </div>
                {isOpened && (<ul className="flex-column nav ml-2 mb-2">
                    <WorkspacesAccordionSettings forMobile currentRoute={currentRoute} teamsByWorkspaceId={teamsByWorkspaceId} workspace={workspace}/>
                  </ul>)}
              </li>);
        })}
        </ul>
      </div>
    </>);
};
