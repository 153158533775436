import React from 'react';
import I18n from '@/constants/i18n';
import Alert from '@/components/alert';
export var TimeZoneAlert = function (_a) {
    var isOpen = _a.isOpen, utcOffset = _a.utcOffset, osUTCOffset = _a.osUTCOffset, onClickChangeTimeZone = _a.onClickChangeTimeZone, onClose = _a.onClose;
    return (<Alert isHtml color="danger" isOpen={isOpen} position="bottom" onClose={onClose}>
      <p>{I18n.t('calendar.timeZoneDifference')}</p>
      <ul>
        <li>
          {I18n.t('calendar.timeCrowdTimeZone')}: GMT {utcOffset}
        </li>
        <li>
          {I18n.t('calendar.osTimeZone')}: GMT {osUTCOffset}
        </li>
      </ul>
      <button className="ml-2 mb-2 btn btn-primary" type="button" onClick={onClickChangeTimeZone}>
        {I18n.t('actions.change')}
      </button>
    </Alert>);
};
