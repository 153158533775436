import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var ConfirmModal = function (_a) {
    var body = _a.body, header = _a.header, isOpen = _a.isOpen, onClose = _a.onClose;
    return (<Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeaderMod toggle={onClose}>{header}</ModalHeaderMod>
      <ModalBody>
        <div dangerouslySetInnerHTML={{ __html: body }}/>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={onClose}>
          {I18n.t('shared.confirm')}
        </button>
      </ModalFooter>
    </Modal>);
};
