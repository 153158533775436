import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import api2 from '@/api2';
export var FETCH_WORKSPACES_EMPLOYS_REQUEST = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_EMPLOYS_REQUEST';
export var FETCH_WORKSPACES_EMPLOYS_SUCCESS = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_EMPLOYS_SUCCESS';
export var FETCH_WORKSPACES_EMPLOYS_FAILURE = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_EMPLOYS_FAILURE';
export var CREATE_WORKSPACES_EMPLOY_REQUEST = 'timecrowd/WORKSPACES/CREATE_WORKSPACES_EMPLOY_REQUEST';
export var CREATE_WORKSPACES_EMPLOY_SUCCESS = 'timecrowd/WORKSPACES/CREATE_WORKSPACES_EMPLOY_SUCCESS';
export var CREATE_WORKSPACES_EMPLOY_FAILURE = 'timecrowd/WORKSPACES/CREATE_WORKSPACES_EMPLOY_FAILURE';
export var UPDATE_WORKSPACES_EMPLOY_REQUEST = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_EMPLOY_REQUEST';
export var UPDATE_WORKSPACES_EMPLOY_SUCCESS = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_EMPLOY_SUCCESS';
export var UPDATE_WORKSPACES_EMPLOY_FAILURE = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_EMPLOY_FAILURE';
export var DELETE_WORKSPACES_EMPLOY_REQUEST = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_EMPLOY_REQUEST';
export var DELETE_WORKSPACES_EMPLOY_SUCCESS = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_EMPLOY_SUCCESS';
export var DELETE_WORKSPACES_EMPLOY_FAILURE = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_EMPLOY_FAILURE';
export var RESET_WORKSPACES_EMPLOYS = 'timecrowd/WORKSPACES/RESET_WORKSPACES_EMPLOYS';
export var initialState = {
    isLoading: false,
    isLoaded: false,
    items: [],
};
export default function workspaces(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_WORKSPACES_EMPLOYS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isLoaded: false });
        case CREATE_WORKSPACES_EMPLOY_REQUEST:
        case UPDATE_WORKSPACES_EMPLOY_REQUEST:
        case DELETE_WORKSPACES_EMPLOY_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_WORKSPACES_EMPLOYS_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, isLoading: false, isLoaded: true });
        case CREATE_WORKSPACES_EMPLOY_SUCCESS:
            return __assign(__assign({}, state), { items: __spreadArray(__spreadArray([], state.items, true), [action.payload], false), isLoading: false });
        case UPDATE_WORKSPACES_EMPLOY_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.map(function (item) {
                    return item.id === action.payload.id ? action.payload : item;
                }), isLoading: false });
        case DELETE_WORKSPACES_EMPLOY_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.filter(function (item) { return item.id !== action.payload.id; }), isLoading: false });
        case FETCH_WORKSPACES_EMPLOYS_FAILURE:
        case CREATE_WORKSPACES_EMPLOY_FAILURE:
        case UPDATE_WORKSPACES_EMPLOY_FAILURE:
        case DELETE_WORKSPACES_EMPLOY_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case RESET_WORKSPACES_EMPLOYS:
            return initialState;
        default:
            return state;
    }
}
export var fetchWorkspacesEmploysRequest = function () { return ({
    type: FETCH_WORKSPACES_EMPLOYS_REQUEST,
}); };
export var fetchWorkspacesEmploysSuccess = function (items) { return ({
    type: FETCH_WORKSPACES_EMPLOYS_SUCCESS,
    payload: items,
}); };
export var fetchWorkspacesEmploysFailure = function () { return ({
    type: FETCH_WORKSPACES_EMPLOYS_FAILURE,
}); };
export var fetchWorkspacesEmploys = function (teamId) {
    if (teamId === void 0) { teamId = ''; }
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesEmploysRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/employs?team_id=".concat(teamId), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchWorkspacesEmploysSuccess(items));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchWorkspacesEmploysFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var createWorkspacesEmployRequest = function () { return ({
    type: CREATE_WORKSPACES_EMPLOY_REQUEST,
}); };
export var createWorkspacesEmploySuccess = function (item) { return ({
    type: CREATE_WORKSPACES_EMPLOY_SUCCESS,
    payload: item,
}); };
export var createWorkspacesEmployFailure = function () { return ({
    type: CREATE_WORKSPACES_EMPLOY_FAILURE,
}); };
export var createWorkspacesEmploy = function (params) {
    return function (dispatch, getState) {
        dispatch(createWorkspacesEmployRequest());
        return api2(dispatch, getState, '/api/v2/workspaces/employs', 'POST', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        if (response.ok) {
                            dispatch(createWorkspacesEmploySuccess(item));
                            return [2 /*return*/, { error: false, item: item }];
                        }
                        else {
                            dispatch(createWorkspacesEmployFailure());
                            return [2 /*return*/, { error: true, errors: item.errors }];
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
};
export var updateWorkspacesEmployRequest = function () { return ({
    type: UPDATE_WORKSPACES_EMPLOY_REQUEST,
}); };
export var updateWorkspacesEmploySuccess = function (item) { return ({
    type: UPDATE_WORKSPACES_EMPLOY_SUCCESS,
    payload: item,
}); };
export var updateWorkspacesEmployFailure = function () { return ({
    type: UPDATE_WORKSPACES_EMPLOY_FAILURE,
}); };
export var updateWorkspacesEmploy = function (id, params) {
    return function (dispatch, getState) {
        dispatch(updateWorkspacesEmployRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/employs/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        if (response.ok) {
                            dispatch(updateWorkspacesEmploySuccess(item));
                            return [2 /*return*/, { error: false, item: item }];
                        }
                        else {
                            dispatch(updateWorkspacesEmployFailure());
                            return [2 /*return*/, { error: true, errors: item.errors }];
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
};
export var deleteWorkspacesEmployRequest = function () { return ({
    type: DELETE_WORKSPACES_EMPLOY_REQUEST,
}); };
export var deleteWorkspacesEmploySuccess = function (id) { return ({
    type: DELETE_WORKSPACES_EMPLOY_SUCCESS,
    payload: { id: id },
}); };
export var deleteWorkspacesEmployFailure = function () { return ({
    type: DELETE_WORKSPACES_EMPLOY_FAILURE,
}); };
export var deleteWorkspacesEmploy = function (id) {
    return function (dispatch, getState) {
        dispatch(deleteWorkspacesEmployRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/employs/".concat(id), 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(deleteWorkspacesEmploySuccess(id));
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(deleteWorkspacesEmployFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
export var resetWorkspacesEmploys = function () { return ({
    type: RESET_WORKSPACES_EMPLOYS,
}); };
