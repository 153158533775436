import React from 'react';
import { useDispatch } from 'react-redux';
import I18n from '@/constants/i18n';
import { destroyIdentity } from '@/api2/identities';
import { fetchCurrentUser } from '@/modules/current_user';
import { setNotificationMessage } from '@/modules/notification';
import IdentityField from '@/components/identity_field';
var IdentityFieldContainer = function (_a) {
    var identity = _a.identity;
    var dispatch = useDispatch();
    var handleClickDelete = React.useCallback(function () {
        dispatch(destroyIdentity(identity.id)).then(function (response) {
            if (response.error) {
                return;
            }
            dispatch(setNotificationMessage('success', I18n.t('shared.disconnectedIdentity')));
            dispatch(fetchCurrentUser());
        });
    }, [dispatch, identity.id]);
    return (<IdentityField info_email={identity.info_email} provider={identity.provider} onDelete={handleClickDelete}/>);
};
export default IdentityFieldContainer;
