import React from 'react';
import I18n from '@/constants/i18n';
import { imageSource } from '@/utils/image_helper';
export var NewForm = function (_a) {
    var user = _a.user, canIntegrate = _a.canIntegrate, openURL = _a.openURL, setNotificationMessage = _a.setNotificationMessage;
    var onClickLink = React.useCallback(function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    }, [openURL]);
    return (<div>
      <p className="text-muted">
        {I18n.t('shared.integrateWithMyTask')}
        <br />
        {I18n.t('shared.selectAccountAndIntegrateWithOutlook')}
      </p>
      {canIntegrate ? (<div className="mt-4">
          <div className="form-group">
            <label className="font-weight-bold">
              {I18n.t('shared.integrateWithGoogleCalendar')}
            </label>
            <p>
              <a className="btn btn-secondary btn-google" href="/oauth_scopes/new?provider=google&scope_name=calendar" onClick={function (e) {
                var _a;
                if (((_a = user.allowed_sign_in_methods) === null || _a === void 0 ? void 0 : _a.length) &&
                    !user.allowed_sign_in_methods.includes('google')) {
                    setNotificationMessage('danger', I18n.t('shared.operationRestrictedByAdministrator'));
                    e.preventDefault();
                }
            }}>
                <img className="blank-image" src={imageSource('sign_in_with_google_logo.png')}/>
                {I18n.t('shared.signInWithGoogle')}
              </a>
            </p>
          </div>
          <div className="form-group mt-3">
            <label className="font-weight-bold">
              {I18n.t('shared.integrateWithOutlookCalendar')}
            </label>
            <p>
              <a className="btn btn-secondary btn-google" href="/oauth_scopes/new?provider=outlook&scope_name=calendar" onClick={function (e) {
                var _a;
                if (((_a = user.allowed_sign_in_methods) === null || _a === void 0 ? void 0 : _a.length) &&
                    !user.allowed_sign_in_methods.includes('outlook')) {
                    setNotificationMessage('danger', I18n.t('shared.operationRestrictedByAdministrator'));
                    e.preventDefault();
                }
            }}>
                <img className="blank-image" src={imageSource('sign_in_with_outlook_logo.png')}/>
                {I18n.t('shared.signInWithMicrosoft')}
              </a>
            </p>
          </div>
        </div>) : (<p>
          {I18n.t('shared.pleaseIntegrateWithBrowser')}
          <br />
          <a href="https://timecrowd.net/app/personal_settings/calendar_integration" onClick={onClickLink}>
            {I18n.t('shared.openTimeCrowdOnBrowser')}
          </a>
        </p>)}
    </div>);
};
