import React from 'react';
import I18n from '@/constants/i18n';
import { imageSource } from '@/utils/image_helper';
import DeactivationContainer from '@/containers/pages/account/deactivation_container';
export var Welcome = function (_a) {
    var email = _a.email, onClickLink = _a.onClickLink, onSignOut = _a.onSignOut;
    var _b = React.useState(false), isOpenDeactivation = _b[0], setIsOpenDeactivation = _b[1];
    var handleToggleDeactivation = function () {
        setIsOpenDeactivation(!isOpenDeactivation);
    };
    return (<div className="cotainer walkthrough text-center">
      <div className="row justify-content-center">
        <main className="col-10 col-sm-6 col-xl-4">
          <p className="mt-5">
            <a href="/" onClick={onClickLink}>
              <img className="walkthrough-logo" src={imageSource('tc-logo-horizontal.svg')}/>
            </a>
          </p>
          <h1 className="mt-5">{I18n.t('shared.walkthroughPlanTitle')}</h1>
          <div className="mt-2">
            <a className="btn btn-primary btn-lg btn-block mt-3" href="https://timecrowd.net/inquiry/new/trial" onClick={onClickLink}>
              <span className="h3">
                <span className="font-weight-bold">
                  {I18n.t('shared.walkthroughPlanButtonLabel')}
                </span>
                <br />
                <span className="small">
                  {I18n.t('shared.walkthroughPlanButtonDescription')}
                </span>
              </span>
            </a>

            <p className="mt-4 text-center pb-4">
              <a className="btn btn-link p-0" href="https://timecrowd.net/inquiry/new/1" onClick={onClickLink}>
                <span className="h3">
                  {I18n.t('shared.walkthroughPlanLinkLabel1')}
                </span>
              </a>
            </p>

            <div className="mt-3 text-center">
              <p className="h1">{I18n.t('shared.walkthroughWelcomeNotice1')}</p>
              <p className="h3 font-weight-normal mt-3 leading-9">
                {I18n.t('shared.walkthroughWelcomeNotice2')}
              </p>
            </div>

            <hr className="mt-5"/>

            <div className="my-5">
              <div className="mb-3">
                <p className="h1">{I18n.t('welcome.logoutOrDeactivate')}</p>
              </div>
              <div className="mb-3">
                <p className="h3 font-weight-normal leading-9" dangerouslySetInnerHTML={{
            __html: I18n.t('welcome.logoutOrDeactivateDescriptionHtml'),
        }}/>
              </div>
              <p dangerouslySetInnerHTML={{
            __html: I18n.t('welcome.currentLoginHtml', { email: email }),
        }}/>
              <div className="mb-1">
                <button className="btn btn-link p-0" onClick={onSignOut}>
                  {I18n.t('other.signOut')}
                </button>
              </div>
              <p className="mb-0">{I18n.t('shared.or')}</p>
              <div className="mt-1">
                <button className="btn btn-link text-danger p-0" type="button" onClick={handleToggleDeactivation}>
                  {I18n.t('personalSetting.deactivate')}
                </button>
                <DeactivationContainer email={email} isOpen={isOpenDeactivation} onClose={handleToggleDeactivation}/>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>);
};
