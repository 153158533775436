import React from 'react';
import I18n from '@/constants/i18n';
export var CustomFieldsNameField = function (_a) {
    var customFieldsMaster = _a.customFieldsMaster, onChangeCustomFieldsMaster = _a.onChangeCustomFieldsMaster, className = _a.className;
    return (<div className={className}>
      {I18n.t('shared.customFieldsName')}
      <div className="mt-2">
        <input autoFocus className="form-control" type="text" value={customFieldsMaster.name} onChange={function (e) { return onChangeCustomFieldsMaster('name', e.target.value); }}/>
      </div>
    </div>);
};
