import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import CustomCheck from '@/components/atoms/custom_check';
import CustomRadio from '@/components/atoms/custom_radio';
import { CALENDAR_UNIT_MINUTES } from '@/modules/calendar';
import { CalendarZoomRateControlContainer } from '@/containers/calendar_zoom_rate_control_container';
export var CalendarOptionsDropdown = function (props) {
    var isShowCalendarIntegrationMenu = props.isShowCalendarIntegrationMenu, isShowCategoryOnWeeklyCalendarMenu = props.isShowCategoryOnWeeklyCalendarMenu, isShowZoomRateMenu = props.isShowZoomRateMenu, isShowCategoryOnWeeklyCalendar = props.isShowCategoryOnWeeklyCalendar, calendarUnitMinutes = props.calendarUnitMinutes, setZoomRateToMinimum = props.setZoomRateToMinimum, loadIsShowCategoryOnWeeklyCalendar = props.loadIsShowCategoryOnWeeklyCalendar, saveIsShowCategoryOnWeeklyCalendar = props.saveIsShowCategoryOnWeeklyCalendar, loadCalendarUnitMinutes = props.loadCalendarUnitMinutes, saveCalendarUnitMinutes = props.saveCalendarUnitMinutes;
    React.useEffect(function () {
        loadIsShowCategoryOnWeeklyCalendar();
    }, [loadIsShowCategoryOnWeeklyCalendar]);
    React.useEffect(function () {
        loadCalendarUnitMinutes();
    }, [loadCalendarUnitMinutes]);
    var handleClickIsShowCategoryOnWeeklyCalendar = function (flag) {
        saveIsShowCategoryOnWeeklyCalendar(flag);
        if (flag) {
            setZoomRateToMinimum(flag, calendarUnitMinutes);
        }
    };
    var handleChangeUnitMinutes = function (unitMinutes) {
        saveCalendarUnitMinutes(unitMinutes);
        if (unitMinutes < CALENDAR_UNIT_MINUTES[0]) {
            setZoomRateToMinimum(isShowCategoryOnWeeklyCalendar, unitMinutes);
        }
    };
    return (<div className="date-header-link">
      <UncontrolledDropdown>
        <DropdownToggle className="btn btn-link text-muted" tag="button">
          <Icon name="gear" size="lg"/>
        </DropdownToggle>
        <DropdownMenu right className="p-2 pb-3">
          <h4 className="py-1">{I18n.t('shared.calendarSettings')}</h4>
          {isShowCalendarIntegrationMenu && (<Link className="d-block mt-3" to="/app/personal_settings/calendar_integration">
              <Icon className="mr-2" name="network-3"/>
              {I18n.t('shared.goToCalendarIntegration')}
            </Link>)}
          {isShowCategoryOnWeeklyCalendarMenu && (<>
              <CustomCheck checked={isShowCategoryOnWeeklyCalendar} className="mt-3" onChange={function () {
                return handleClickIsShowCategoryOnWeeklyCalendar(!isShowCategoryOnWeeklyCalendar);
            }}>
                {I18n.t('shared.showCategoryOnWeeklyCalendar')}
              </CustomCheck>
              <p className="mt-1 text-muted small">
                {I18n.t('shared.showCategoryOnWeeklyCalendarDescription')}
              </p>
            </>)}
          <h5 className="mt-3">{I18n.t('shared.unitMinutes')}</h5>
          <p className="mt-1 text-muted small">
            {I18n.t('shared.unitMinutesDescription')}
          </p>
          <div className="d-flex justify-content-center">
            {CALENDAR_UNIT_MINUTES.map(function (minutes, i) { return (<CustomRadio key={minutes} checked={minutes === calendarUnitMinutes} className={i > 0 ? 'ml-4' : ''} onChange={function () { return handleChangeUnitMinutes(minutes); }}>
                {minutes}
                {I18n.t('shared.minutes')}
              </CustomRadio>); })}
          </div>

          {isShowZoomRateMenu && (<>
              <h5 className="mt-3 mb-2">{I18n.t('shared.calendarZoomRate')}</h5>
              <CalendarZoomRateControlContainer isHorizontal/>
            </>)}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>);
};
