import { __awaiter, __generator } from "tslib";
import api2 from '@/api2';
import { createPaymentRequest, createPaymentSuccess, createPaymentFailed, } from '@/modules/payment';
export var updateWorkspaceCard = function (params) {
    return function (dispatch, getState) {
        dispatch(createPaymentRequest());
        return api2(dispatch, getState, '/api/v2/workspaces/card', 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(createPaymentSuccess(item));
                        return [2 /*return*/, item];
                    case 2:
                        dispatch(createPaymentFailed());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
