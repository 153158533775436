import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import uniqBy from 'lodash/uniqBy';
import I18n from '../../constants/i18n';
import { DATETIME_FORMAT } from '@/constants/format';
import StatusBadge from './status_badge';
import OmitLabel from '@/components/atoms/omit_label';
import TeamLabel from './team_label';
import UserLabel from './user_label';
import ExportActionsContainer from '@/containers/export/export_actions_container';
import DeleteAssetButtonContainer from '@/containers/export/delete_asset_button_container';
function days(asset) {
    if (asset.start_date && asset.end_date) {
        var days_1 = moment(asset.end_date).diff(moment(asset.start_date), 'days');
        return days_1 + 1;
    }
    else {
        return '';
    }
}
function uniqUsers(teams) {
    var allUsers = teams.reduce(function (acc, team) { return acc.concat(team.users); }, []);
    return uniqBy(allUsers, 'id');
}
export default function AssetListItem(_a) {
    var _b;
    var asset = _a.asset;
    return (<tr>
      <th className="table-status">
        <StatusBadge status={asset.status}/>
      </th>
      <td>
        <div className="d-flex">
          <OmitLabel maxWidth="10rem">
            <TeamLabel teams={asset.meta.teams}/>
          </OmitLabel>
          /
          <OmitLabel maxWidth="10rem">
            <UserLabel users={uniqUsers(asset.meta.teams)}/>
          </OmitLabel>
        </div>
      </td>
      <td>
        {asset.start_date} {I18n.t('export.to')} {asset.end_date} ({days(asset)}{' '}
        {I18n.t('export.days')})
      </td>
      <td>
        {I18n.t("shared.".concat(asset.meta.file_type))} /{' '}
        {I18n.t("export.charset.".concat((_b = asset.meta.charset) !== null && _b !== void 0 ? _b : 'sjis'))}
      </td>
      <td>{I18n.t("shared.".concat(asset.meta.data_type))}</td>
      <td>{I18n.t("export.format.".concat(asset.meta.time_format || 'seconds'))}</td>
      <td>{moment(asset.created_at).format(DATETIME_FORMAT)}</td>
      <td>
        <ExportActionsContainer asset={asset}/>
      </td>
      <td>
        {/* @ts-ignore */}
        <DeleteAssetButtonContainer asset={asset}/>
      </td>
    </tr>);
}
