import React from 'react';
import range from 'lodash/range';
import { CalendarEventItem } from '@/components/calendar_event_item';
import { filterByDate, groupByTime } from './helpers';
import { fitTimesWithinDate } from '@/components/timeline/helpers';
export var TimelineCalendarEvents = function (props) {
    var isShown = props.isShown, hours = props.hours, subdivisions = props.subdivisions, divisionHeight = props.divisionHeight, calendarIntegrationEvents = props.calendarIntegrationEvents, date = props.date, calendarUnitMinutes = props.calendarUnitMinutes, toggleTaskForm = props.toggleTaskForm;
    if (!isShown) {
        return null;
    }
    var currentDateEvents = filterByDate(calendarIntegrationEvents, date);
    var eventGroups = groupByTime(currentDateEvents);
    return (<div className="position-relative timeline-event">
      {hours.map(function (hour, index) {
            return (<div key={"timeline-hours-".concat(hour)} className={['timeline-hour', 'timeline-hour-' + index].join(' ')}>
            <div className="timeline-hour-body">
              {range(subdivisions).map(function (item, subindex) { return (<div key={"timeline-blank-".concat(hour, "-").concat(subindex)} className="timeline-hour-body-item" style={{ height: divisionHeight }}/>); })}
            </div>
          </div>);
        })}
      {currentDateEvents.map(function (event, i) {
            var _a = fitTimesWithinDate(date, {
                started_at: event.starts_at,
                stopped_at: event.ends_at,
            }), startedAt = _a.startedAt, stoppedAt = _a.stoppedAt;
            var duration = stoppedAt.diff(startedAt, 'seconds');
            var heightPerMinutes = divisionHeight / calendarUnitMinutes;
            var top = startedAt.hours() * subdivisions * divisionHeight +
                startedAt.minutes() * heightPerMinutes;
            var height = Math.ceil(duration / 60) * heightPerMinutes;
            var index = "".concat(startedAt.hours(), "-").concat(i, "-calendar-event-").concat(date);
            var width = "".concat(100 / eventGroups[event.id].group.count, "%");
            return (<CalendarEventItem key={event.id} calendarIntegrationEvent={event} height={height > divisionHeight ? height : divisionHeight} index={index} left={"calc(".concat(width, " * ").concat(eventGroups[event.id].index, ")")} toggleTaskForm={toggleTaskForm} top={top} width={width}/>);
        })}
    </div>);
};
