import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenPersonalSettingsSubpane } from '@/modules/personal_settings';
import { setNotificationMessage } from '@/modules/notification';
import { fetchIdentities } from '@/api2/identities';
import { fetchAllWorkspacesCategories } from '@/actions/category';
import { fetchCalendarIntegrationSetting, updateCalendarIntegrationSetting, deleteCalendarIntegrationSetting, } from '@/modules/calendar_integration_settings';
import { fetchCalendarIntegrationFilters, createCalendarIntegrationFilter, updateCalendarIntegrationFilter, deleteCalendarIntegrationFilter, } from '@/modules/calendar_integration_filters';
import { fetchCalendarIntegrationLists, updateCalendarIntegrationList, } from '@/modules/calendar_integration_lists';
import { fetchUserWorkspacesCalendarIntegrationSettings } from '@/modules/workspaces_calendar_integration_settings';
import { CalendarIntegrationForm } from '@/components/calendar_integration_form';
import { signOut } from '@/actions/user';
import useCustomFields from '@/hooks/use_custom_fields';
var CalendarIntegrationContainer = function () {
    var _a;
    var calendarIntegrationFilters = useSelector(function (state) { return state.calendarIntegrationFilters; });
    var calendarIntegrationLists = useSelector(function (state) { return state.calendarIntegrationLists; });
    var calendarIntegrationSettings = useSelector(function (state) { return state.calendarIntegrationSettings; });
    var categories = useSelector(function (state) { return state.categories; });
    var identities = useSelector(function (state) { return state.identities; });
    var isApp = useSelector(function (state) { return state.isApp; });
    var isChromeExtension = useSelector(function (state) { return state.isChromeExtension; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var isOpenSubpane = useSelector(function (state) { return state.personalSettings.isOpenSubpane; });
    var user = useSelector(function (state) { return state.user; });
    var teams = useSelector(function (state) { return state.teams; });
    var dispatch = useDispatch();
    var handleFetchCalendarIntegrationFilters = React.useCallback(function () {
        dispatch(fetchCalendarIntegrationFilters());
    }, [dispatch]);
    var handleUpdateCalendarIntegrationSetting = function (params) {
        return dispatch(updateCalendarIntegrationSetting(params));
    };
    var handleDeleteCalendarIntegrationSetting = function () {
        return dispatch(deleteCalendarIntegrationSetting());
    };
    var handleCreateCalendarIntegrationFilter = function (params) {
        return dispatch(createCalendarIntegrationFilter(params));
    };
    var handleUpdateCalendarIntegrationFilter = function (id, params) {
        return dispatch(updateCalendarIntegrationFilter(id, params));
    };
    var handleDeleteCalendarIntegrationFilter = function (id) {
        return dispatch(deleteCalendarIntegrationFilter(id));
    };
    var handleUpdateCalendarIntegrationList = React.useCallback(function (id, params) {
        return dispatch(updateCalendarIntegrationList(id, params));
    }, [dispatch]);
    var handleSetNotificationMessage = function (type, message) {
        dispatch(setNotificationMessage(type, message));
    };
    var handleSignOut = function () {
        dispatch(signOut());
    };
    var handleSetIsOpenPersonalSettingsSubpane = React.useCallback(function (isOpen) {
        dispatch(setIsOpenPersonalSettingsSubpane(isOpen));
    }, [dispatch]);
    React.useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var integration;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        handleSetIsOpenPersonalSettingsSubpane(false);
                        return [4 /*yield*/, dispatch(fetchCalendarIntegrationSetting())];
                    case 1:
                        integration = _a.sent();
                        if (integration.item) {
                            handleFetchCalendarIntegrationFilters();
                            dispatch(fetchCalendarIntegrationLists());
                            dispatch(fetchIdentities());
                            dispatch(fetchAllWorkspacesCategories());
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [
        dispatch,
        handleFetchCalendarIntegrationFilters,
        handleSetIsOpenPersonalSettingsSubpane,
    ]);
    React.useEffect(function () {
        var _a;
        if (!((_a = calendarIntegrationSettings.item) === null || _a === void 0 ? void 0 : _a.id)) {
            return;
        }
        dispatch(fetchUserWorkspacesCalendarIntegrationSettings());
    }, [(_a = calendarIntegrationSettings.item) === null || _a === void 0 ? void 0 : _a.id, dispatch]);
    useCustomFields(teams.items);
    if ((calendarIntegrationSettings.isLoading &&
        !calendarIntegrationSettings.item) ||
        teams.isLoading) {
        return null;
    }
    return (<CalendarIntegrationForm calendarIntegrationFilters={calendarIntegrationFilters.items} calendarIntegrationLists={calendarIntegrationLists.items} calendarIntegrationSetting={calendarIntegrationSettings.item} categories={categories} createCalendarIntegrationFilter={handleCreateCalendarIntegrationFilter} deleteCalendarIntegrationFilter={handleDeleteCalendarIntegrationFilter} fetchCalendarIntegrationFilters={handleFetchCalendarIntegrationFilters} identities={identities.items} isApp={isApp} isCalendarIntegrationListsIsLoading={calendarIntegrationLists.isLoading} isChromeExtension={isChromeExtension} isOpenSubpane={isOpenSubpane} isPC={isPC} isUnrecoverable={calendarIntegrationLists.isUnrecoverable} openURL={openURL} setIsOpenSubpane={handleSetIsOpenPersonalSettingsSubpane} setNotificationMessage={handleSetNotificationMessage} signOut={handleSignOut} updateCalendarIntegrationFilter={handleUpdateCalendarIntegrationFilter} updateCalendarIntegrationList={handleUpdateCalendarIntegrationList} updateCalendarIntegrationSetting={handleUpdateCalendarIntegrationSetting} user={user} onDeleteIntegration={handleDeleteCalendarIntegrationSetting}/>);
};
export default CalendarIntegrationContainer;
