import React from 'react';
export var TimeSelectionPopup = function (_a) {
    var selectedDateTime = _a.selectedDateTime, isShown = _a.isShown, _b = _a.className, className = _b === void 0 ? '' : _b, onSelectDateTime = _a.onSelectDateTime;
    var containerRef = React.useRef(null);
    var baseDateTime = selectedDateTime.clone().subtract(1, 'hour');
    var selectSize = 121;
    var centerPositionIndex = Math.floor(selectSize / 2) + 4;
    var handleSelectDateTime = function (e, datetime) {
        e.preventDefault();
        onSelectDateTime(datetime);
    };
    React.useEffect(function () {
        if (!containerRef.current || !isShown) {
            return;
        }
        var targetElement = containerRef.current.children[centerPositionIndex];
        targetElement.scrollIntoView({ block: 'end' });
    }, [centerPositionIndex, isShown]);
    if (!isShown) {
        return null;
    }
    return (<div ref={containerRef} className={"position-absolute z-1 bg-white border-1 h-80 overflow-scroll-hover scrollbar-dark scrollbar-thin ".concat(className)}>
      {Array.from({ length: selectSize }, function (_, i) { return i; }).map(function (num) {
            var datetime = baseDateTime.clone().add(num, 'minutes');
            return (<button key={num} className={"btn btn-link btn-block pl-2 pr-5 py-0 m-0 text-reset ".concat(datetime.isSame(selectedDateTime) ? 'bg-faded rounded-0' : '')} onMouseDown={function (e) { return handleSelectDateTime(e, datetime); }}>
            {datetime.format('HH:mm')}
          </button>);
        })}
    </div>);
};
