import React from 'react';
import { Modal, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { CrossTeamReportConditionsInfo } from '../cross_team_report_conditions_info';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var CrossTeamReportBookmarkUpdateModal = function (props) {
    var bookmark = props.bookmark, isLoading = props.isLoading, isOpen = props.isOpen, onOpen = props.onOpen, onClose = props.onClose, onUpdate = props.onUpdate;
    var _a = React.useState(bookmark.name), name = _a[0], setName = _a[1];
    var tooltipRef = React.useRef(null);
    React.useEffect(function () {
        setName(bookmark.name);
    }, [isOpen, bookmark]);
    var handleUpdate = React.useCallback(function () {
        onUpdate({ id: bookmark.id, name: name });
    }, [name, bookmark, onUpdate]);
    var handleOpen = React.useCallback(function (e) {
        e.stopPropagation();
        onOpen();
    }, [onOpen]);
    return (<>
      <button ref={tooltipRef} className="btn btn-secondary" onClick={handleOpen}>
        <Icon name="information"/>
      </button>
      <UncontrolledTooltip fade={false} target={tooltipRef}>
        {I18n.t("shared.details")}
      </UncontrolledTooltip>

      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeaderMod toggle={onClose}>
          {I18n.t('shared.updateReportBookmarkHeader')}
        </ModalHeaderMod>

        <ModalBody>
          <div className="bg-gray-lighter rounded px-2 pt-2 pb-1 mb-2 text-secondary">
            <CrossTeamReportConditionsInfo withDetails conditions={bookmark.conditions} withDate={bookmark.conditions.period_type === 'custom' ||
            bookmark.conditions.term === 'long_term'}/>
          </div>
          <p className="mb-1">
            {I18n.t('shared.updateReportBookmarkTitleLabel')}
          </p>
          <input className="form-control" maxLength={30} placeholder={I18n.t('shared.createReportBookmarkTitlePlaceholder')} type="text" value={name} onChange={function (e) { return setName(e.target.value); }}/>
          <p className="text-right">{"".concat(name.length, " / 30")}</p>
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-secondary" disabled={isLoading} onClick={onClose}>
            {I18n.t('shared.cancel')}
          </button>
          <button className="btn btn-primary" disabled={isLoading} onClick={handleUpdate}>
            {I18n.t('shared.save')}
          </button>
        </ModalFooter>
      </Modal>
    </>);
};
