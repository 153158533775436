import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
var FETCH_RECENT_CATEGORIES_REQUEST = 'timecrowd/recent_categories/FETCH_RECENT_CATEGORIES_REQUEST';
var FETCH_RECENT_CATEGORIES_SUCCESS = 'timecrowd/recent_categories/FETCH_RECENT_CATEGORIES_SUCCESS';
var FETCH_RECENT_CATEGORIES_FAILURE = 'timecrowd/recent_categories/FETCH_RECENT_CATEGORIES_FAILURE';
var ARRANGE_RECENT_CATEGORIES = 'timecrowd/recent_categories/ARRANGE_RECENT_CATEGORIES';
var REJECT_RECENT_CATEGORIES = 'timecrowd/recent_categories/REJECT_RECENT_CATEGORIES';
var RESET_RECENT_CATEGORIES = 'timecrowd/recent_categories/RESET_RECENT_CATEGORIES';
var initialState = {
    items: [],
    status: 'pending',
};
export function unmatchedRecentCategories(recentCategories, category) {
    return recentCategories.filter(function (recentCategory) {
        return !(recentCategory.id === category.id &&
            recentCategory.team_id === category.team_id);
    });
}
export default function recentCategories(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_RECENT_CATEGORIES_REQUEST:
            return __assign(__assign({}, state), { items: [], status: 'loading' });
        case FETCH_RECENT_CATEGORIES_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, status: 'loaded' });
        case FETCH_RECENT_CATEGORIES_FAILURE:
            return __assign(__assign({}, state), { status: 'pending' });
        case ARRANGE_RECENT_CATEGORIES: {
            var items = [action.category].concat(unmatchedRecentCategories(state.items, action.category).slice(0, 9));
            return __assign(__assign({}, state), { items: items });
        }
        case REJECT_RECENT_CATEGORIES: {
            var items = unmatchedRecentCategories(state.items, action.category).slice(0, 9);
            return __assign(__assign({}, state), { items: items });
        }
        case RESET_RECENT_CATEGORIES:
            return initialState;
        default:
            return state;
    }
}
export var fetchRecentCategoriesRequest = function () { return ({
    type: FETCH_RECENT_CATEGORIES_REQUEST,
}); };
export var fetchRecentCategoriesSuccess = function (categories) { return ({
    type: FETCH_RECENT_CATEGORIES_SUCCESS,
    payload: categories,
}); };
export var fetchRecentCategoriesFailure = function () { return ({
    type: FETCH_RECENT_CATEGORIES_FAILURE,
}); };
export var arrangeRecentCategories = function (category) { return ({
    type: ARRANGE_RECENT_CATEGORIES,
    category: category,
}); };
export var rejectRecentCategories = function (category) { return ({
    type: REJECT_RECENT_CATEGORIES,
    category: category,
}); };
export var resetRecentCategories = function () { return ({
    type: RESET_RECENT_CATEGORIES,
}); };
export var fetchRecentCategories = function () {
    return function (dispatch, getState) {
        dispatch(fetchRecentCategoriesRequest());
        return api2(dispatch, getState, '/api/v1/user/recent_categories', 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var recentCategories_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        recentCategories_1 = _a.sent();
                        dispatch(fetchRecentCategoriesSuccess(recentCategories_1));
                        return [2 /*return*/, { recentCategories: recentCategories_1, error: false }];
                    case 2:
                        dispatch(fetchRecentCategoriesFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var fetchUserRecentCategories = function (user_id) {
    return function (dispatch, getState) {
        dispatch(fetchRecentCategoriesRequest());
        return api2(dispatch, getState, "/api/v2/users/".concat(user_id, "/recent_categories"), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var recentCategories_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        recentCategories_2 = _a.sent();
                        dispatch(fetchRecentCategoriesSuccess(recentCategories_2));
                        return [2 /*return*/, { recentCategories: recentCategories_2, error: false }];
                    case 2:
                        dispatch(fetchRecentCategoriesFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
