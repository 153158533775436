import { __assign, __awaiter, __generator } from "tslib";
import api2 from '@/api2';
export var FETCH_WORKSPACES_MEMBERSHIPS_REQUEST = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_MEMBERSHIPS_REQUEST';
export var FETCH_WORKSPACES_MEMBERSHIPS_SUCCESS = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_MEMBERSHIPS_SUCCESS';
export var FETCH_WORKSPACES_MEMBERSHIPS_FAILURE = 'timecrowd/WORKSPACES/FETCH_WORKSPACES_MEMBERSHIPS_FAILURE';
export var UPDATE_WORKSPACES_MEMBERSHIP_REQUEST = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_MEMBERSHIP_REQUEST';
export var UPDATE_WORKSPACES_MEMBERSHIP_SUCCESS = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_MEMBERSHIP_SUCCESS';
export var UPDATE_WORKSPACES_MEMBERSHIP_FAILURE = 'timecrowd/WORKSPACES/UPDATE_WORKSPACES_MEMBERSHIP_FAILURE';
export var DELETE_WORKSPACES_MEMBERSHIP_REQUEST = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_MEMBERSHIP_REQUEST';
export var DELETE_WORKSPACES_MEMBERSHIP_SUCCESS = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_MEMBERSHIP_SUCCESS';
export var DELETE_WORKSPACES_MEMBERSHIP_FAILURE = 'timecrowd/WORKSPACES/DELETE_WORKSPACES_MEMBERSHIP_FAILURE';
export var RESET_WORKSPACES_MEMBERSHIPS = 'timecrowd/WORKSPACES/RESET_WORKSPACES_MEMBERSHIPS';
export var initialState = {
    isLoading: false,
    isLoaded: false,
    items: [],
};
export default function workspacesMembership(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_WORKSPACES_MEMBERSHIPS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true, isLoaded: false });
        case UPDATE_WORKSPACES_MEMBERSHIP_REQUEST:
        case DELETE_WORKSPACES_MEMBERSHIP_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_WORKSPACES_MEMBERSHIPS_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, isLoading: false, isLoaded: true });
        case DELETE_WORKSPACES_MEMBERSHIP_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.filter(function (item) { return item.id !== action.payload.id; }), isLoading: false });
        case UPDATE_WORKSPACES_MEMBERSHIP_SUCCESS:
            return __assign(__assign({}, state), { items: state.items.map(function (item) {
                    return item.id === action.payload.id ? action.payload : item;
                }), isLoading: false });
        case FETCH_WORKSPACES_MEMBERSHIPS_FAILURE:
        case UPDATE_WORKSPACES_MEMBERSHIP_FAILURE:
        case DELETE_WORKSPACES_MEMBERSHIP_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case RESET_WORKSPACES_MEMBERSHIPS:
            return initialState;
        default:
            return state;
    }
}
export var fetchWorkspacesMembershipsRequest = function () { return ({
    type: FETCH_WORKSPACES_MEMBERSHIPS_REQUEST,
}); };
export var fetchWorkspacesMembershipsSuccess = function (items) { return ({
    type: FETCH_WORKSPACES_MEMBERSHIPS_SUCCESS,
    payload: items,
}); };
export var fetchWorkspacesMembershipsFailure = function () { return ({
    type: FETCH_WORKSPACES_MEMBERSHIPS_FAILURE,
}); };
export var fetchWorkspacesMemberships = function () {
    return function (dispatch, getState) {
        dispatch(fetchWorkspacesMembershipsRequest());
        return api2(dispatch, getState, '/api/v2/workspaces/memberships', 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        items = _a.sent();
                        dispatch(fetchWorkspacesMembershipsSuccess(items));
                        return [2 /*return*/, { error: false, items: items }];
                    case 2:
                        dispatch(fetchWorkspacesMembershipsFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var updateWorkspacesMembershipRequest = function () { return ({
    type: UPDATE_WORKSPACES_MEMBERSHIP_REQUEST,
}); };
export var updateWorkspacesMembershipSuccess = function (item) { return ({
    type: UPDATE_WORKSPACES_MEMBERSHIP_SUCCESS,
    payload: item,
}); };
export var updateWorkspacesMembershipFailure = function () { return ({
    type: UPDATE_WORKSPACES_MEMBERSHIP_FAILURE,
}); };
export var updateWorkspacesMembership = function (id, params) {
    return function (dispatch, getState) {
        dispatch(updateWorkspacesMembershipRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/memberships/".concat(id), 'PATCH', params).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        if (response.ok) {
                            dispatch(updateWorkspacesMembershipSuccess(item));
                            return [2 /*return*/, { error: false, item: item }];
                        }
                        else {
                            dispatch(updateWorkspacesMembershipFailure());
                            return [2 /*return*/, { error: true, errors: item.errors }];
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
};
export var deleteWorkspacesMembershipRequest = function () { return ({
    type: DELETE_WORKSPACES_MEMBERSHIP_REQUEST,
}); };
export var deleteWorkspacesMembershipSuccess = function (id) { return ({
    type: DELETE_WORKSPACES_MEMBERSHIP_SUCCESS,
    payload: { id: id },
}); };
export var deleteWorkspacesMembershipFailure = function () { return ({
    type: DELETE_WORKSPACES_MEMBERSHIP_FAILURE,
}); };
export var deleteWorkspacesMembership = function (id) {
    return function (dispatch, getState) {
        dispatch(deleteWorkspacesMembershipRequest());
        return api2(dispatch, getState, "/api/v2/workspaces/memberships/".concat(id), 'DELETE').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.ok) {
                    dispatch(deleteWorkspacesMembershipSuccess(id));
                    return [2 /*return*/, { error: false }];
                }
                else {
                    dispatch(deleteWorkspacesMembershipFailure());
                    return [2 /*return*/, { error: true }];
                }
                return [2 /*return*/];
            });
        }); });
    };
};
export var resetWorkspacesMemberships = function () { return ({
    type: RESET_WORKSPACES_MEMBERSHIPS,
}); };
