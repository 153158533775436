import { __spreadArray } from "tslib";
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import sum from 'lodash/sum';
import { formatDate, isSameOrBefore, weekOfMonth, quarterOfYear, } from '@/utils/time_helper';
import { colors } from '@/components/report/helpers';
import I18n from '@/constants/i18n';
import { startOfFiscalYear } from '../cross_team_report_header/helpers';
export var workingTimeRatioToText = function (totalTime, currentTime) {
    var ratio = Math.round((currentTime / totalTime) * 100) || 0;
    return "".concat(ratio, "%");
};
export var amountToText = function (amount) {
    return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY',
    }).format(amount);
};
export var sortByTotalTime = function (categories) {
    return categories.sort(function (a, b) {
        if (a.total_time > b.total_time) {
            return -1;
        }
        else if (a.total_time < b.total_time) {
            return 1;
        }
        return 0;
    });
};
export var calcCategoryTotalsForInterval = function (fromString, toString, tasks, timeEntries, interval, startMonth, withoutNoDataPeriods) {
    var _a = calcBarChartPeriods(fromString, toString, timeEntries, withoutNoDataPeriods), from = _a[0], to = _a[1];
    var labelsSet = new Set();
    var labelByDate = {};
    var lastMonth = -1;
    while (isSameOrBefore(from, to)) {
        var month = from.month();
        var date = formatDate(from);
        var formatString = '';
        switch (interval) {
            case 'daily':
                formatString = month === lastMonth ? 'D ddd' : 'M-D ddd';
                break;
            case 'weekly':
                formatString = I18n.t('shared.weekOfMonthFormat', {
                    week: weekOfMonth(from),
                });
                break;
            case 'monthly':
                formatString = 'MMM';
                break;
            case 'quarterly': {
                var _b = quarterOfYear(from, startMonth), year = _b.year, quarter = _b.quarter;
                formatString = "".concat(year, "-[Q]").concat(quarter);
                break;
            }
            case 'fiscalYearly': {
                var start = startOfFiscalYear(from, startMonth);
                formatString = start.format('YYYY');
                break;
            }
        }
        var label = from.format(formatString);
        labelsSet.add(label);
        from.add(1, 'days');
        labelByDate[date] = label;
        lastMonth = month;
    }
    var labels = Array.from(labelsSet);
    var categoryIdByTaskId = tasks.reduce(function (acc, task) {
        acc[task.id] = task.ancestry.split('/').pop();
        return acc;
    }, {});
    var categoryTotalsForInterval = {};
    var teamTotalsForInterval = {};
    var defaultTotals = new Array(labels.length).fill(0);
    var allTotalDurations = __spreadArray([], defaultTotals, true);
    timeEntries.forEach(function (timeEntry) {
        var date = formatDate(timeEntry.started_at);
        var categoryId = categoryIdByTaskId[timeEntry.time_trackable_id];
        if (!categoryTotalsForInterval[categoryId]) {
            categoryTotalsForInterval[categoryId] = {
                duration: __spreadArray([], defaultTotals, true),
                amount: __spreadArray([], defaultTotals, true),
            };
        }
        if (!teamTotalsForInterval[timeEntry.team_id]) {
            teamTotalsForInterval[timeEntry.team_id] = {
                duration: __spreadArray([], defaultTotals, true),
                amount: __spreadArray([], defaultTotals, true),
            };
        }
        var label = labelByDate[date];
        var index = labels.indexOf(label);
        categoryTotalsForInterval[categoryId].duration[index] += timeEntry.duration;
        categoryTotalsForInterval[categoryId].amount[index] += timeEntry.amount;
        teamTotalsForInterval[timeEntry.team_id].duration[index] +=
            timeEntry.duration;
        teamTotalsForInterval[timeEntry.team_id].amount[index] += timeEntry.amount;
        allTotalDurations[index] += timeEntry.duration;
    });
    return {
        labels: labels,
        categoryTotalsForInterval: categoryTotalsForInterval,
        teamTotalsForInterval: teamTotalsForInterval,
        allTotalDurations: allTotalDurations,
    };
};
export var buildBarChartDatasetForCategory = function (categories, categoryTotalsForInterval) {
    return categories.map(function (category) {
        var categoryTotals = categoryTotalsForInterval[category.id];
        var label = category.title;
        var data = categoryTotals.duration.map(function (time) { return time / 3600.0; });
        var backgroundColor = colors[category.color - 1];
        return {
            label: label,
            data: data,
            totals: categoryTotals,
            backgroundColor: backgroundColor,
        };
    });
};
export var buildBarChartDatasetForTeam = function (teams, teamTotalsForInterval) {
    return teams.map(function (team) {
        var teamTotals = teamTotalsForInterval[team.id];
        var label = team.name;
        var data = teamTotals.duration.map(function (time) { return time / 3600.0; });
        var backgroundColor = colors[team.id % colors.length];
        return {
            label: label,
            data: data,
            totals: teamTotals,
            backgroundColor: backgroundColor,
        };
    });
};
export var buildBarChartTooltipLabel = function (_a, allTotalDurations) {
    var _b = _a.dataset, totals = _b.totals, label = _b.label, dataIndex = _a.dataIndex;
    var time = totals.duration[dataIndex];
    // NOTE: データがない場合に不要なTooltipが出ないように
    if (time === 0) {
        return null;
    }
    var h = Math.floor(time / 3600);
    var m = Math.floor((time - h * 3600) / 60);
    var totalTime = allTotalDurations[dataIndex];
    var workingRatio = workingTimeRatioToText(totalTime, time);
    var amount = amountToText(totals.amount[dataIndex]);
    return "".concat(label, " ").concat(h).concat(I18n.t('shared.hours')).concat(m).concat(I18n.t('shared.minutes'), " / ").concat(amount, " / ").concat(workingRatio);
};
export var buildPieChartDataForCategory = function (topCategories, otherCategories) {
    var hasOthers = otherCategories.length > 0;
    var chartColors = __spreadArray(__spreadArray([], topCategories.map(function (c) { return colors[c.color - 1]; }), true), (hasOthers ? ['#bbbbbb'] : []), true);
    return {
        labels: __spreadArray(__spreadArray([], topCategories.map(function (c) { return c.title; }), true), (hasOthers ? [I18n.t('shared.others')] : []), true),
        datasets: [
            {
                data: __spreadArray(__spreadArray([], topCategories.map(function (c) { return c.total_time; }), true), (hasOthers
                    ? [otherCategories.reduce(function (sum, c) { return sum + c.total_time; }, 0)]
                    : []), true),
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
                hoverBorderColor: '#fff',
            },
        ],
    };
};
export var buildPieChartDataForTeam = function (topTeams, otherTeams) {
    var hasOthers = otherTeams.length > 0;
    var chartColors = __spreadArray(__spreadArray([], topTeams.map(function (t) { return colors[t.id % colors.length]; }), true), (hasOthers ? ['#bbbbbb'] : []), true);
    return {
        labels: __spreadArray(__spreadArray([], topTeams.map(function (t) { return t.name; }), true), (hasOthers ? [I18n.t('shared.others')] : []), true),
        datasets: [
            {
                data: __spreadArray(__spreadArray([], topTeams.map(function (t) { return t.total_time; }), true), (hasOthers
                    ? [otherTeams.reduce(function (sum, t) { return sum + t.total_time; }, 0)]
                    : []), true),
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
                hoverBorderColor: '#fff',
            },
        ],
    };
};
export var buildPieChartTooltipLabel = function (_a, otherCategories, categoriesLimit) {
    var label = _a.label, dataset = _a.dataset, dataIndex = _a.dataIndex, time = _a.raw;
    var totalTime = sum(dataset.data);
    var h = Math.floor(time / 3600);
    var m = Math.floor((time - h * 3600) / 60);
    var workingRatio = workingTimeRatioToText(totalTime, time);
    return __spreadArray([
        "".concat(label, " ").concat(h).concat(I18n.t('shared.hours')).concat(m).concat(I18n.t('shared.minutes'), " / ").concat(workingRatio)
    ], (dataIndex === categoriesLimit
        ? otherCategories.map(function (c) { return c.title; })
        : []), true);
};
export var buildIntervalsForPeriods = function (from, to) {
    var days = moment(to).diff(from, 'days') + 1;
    // NOTE: 例外条件付きで月数を判定
    //       2025/01/01-2025/01/31: 0
    //       2025/01/01-2025/02/01: 1
    //       2025/01/01-2025/02/28: 2
    //       2025/01/01-2025/04/01: 3
    //       2025/01/01-2025/04/30: 4
    //       2025/01/01-2025/12/01: 11
    //       2025/01/01-2025/12/31: 11
    //       2025/01/01-2026/12/01: 23
    //       2025/01/01-2026/12/31: 23
    var isEndOfMonth = moment(to).endOf('month').date() === moment(to).date();
    var rawMonths = moment(to).diff(from, 'months');
    var months = rawMonths > 0 && rawMonths < 11 && isEndOfMonth ? rawMonths + 1 : rawMonths;
    var defaultInterval;
    var intervals;
    if (days < 15) {
        defaultInterval = 'daily';
        intervals = ['daily'];
    }
    else if (months < 1) {
        defaultInterval = 'daily';
        intervals = ['daily', 'weekly'];
    }
    else if (months < 2) {
        defaultInterval = 'weekly';
        intervals = ['daily', 'weekly', 'monthly'];
    }
    else if (months < 4) {
        defaultInterval = 'weekly';
        intervals = ['weekly', 'monthly'];
    }
    else if (months < 12) {
        defaultInterval = 'monthly';
        intervals = ['weekly', 'monthly', 'quarterly'];
    }
    else if (months < 24) {
        defaultInterval = 'monthly';
        intervals = ['monthly', 'quarterly', 'fiscalYearly'];
    }
    else {
        defaultInterval = 'fiscalYearly';
        intervals = ['quarterly', 'fiscalYearly'];
    }
    return {
        defaultInterval: defaultInterval,
        intervals: intervals,
    };
};
var calcBarChartPeriods = function (fromString, toString, timeEntries, withoutNoDataPeriods) {
    if (!withoutNoDataPeriods) {
        return [moment(fromString), moment(toString)];
    }
    var minStartedAt = Number.MAX_SAFE_INTEGER;
    var maxStartedAt = 0;
    timeEntries.forEach(function (timeEntry) {
        var startedAt = new Date(timeEntry.started_at).getTime();
        minStartedAt = Math.min(minStartedAt, startedAt);
        maxStartedAt = Math.max(maxStartedAt, startedAt);
    });
    var from = minStartedAt !== Number.MAX_SAFE_INTEGER ? moment(minStartedAt) : moment();
    var to = maxStartedAt !== 0 ? moment(maxStartedAt) : moment();
    return [from, to];
};
