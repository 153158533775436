import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspacesEditForm } from '@/components/workspaces_edit_form';
import { updateWorkspace, deleteWorkspace, setCurrentWorkspace, } from '@/modules/workspaces';
import LoadingPage from '@/components/pages/loading_page';
import NotFound from '@/components/pages/not_found';
import { setNotificationMessage } from '@/modules/notification';
import { fetchWorkspacePayment } from '@/actions/workspace_payment';
import { workspaceTeams } from '@/utils/workspace_helper';
export var WorkspacesEditContainer = function () {
    var payment = useSelector(function (state) { return state.payment; });
    var teams = useSelector(function (state) { return state.teams; });
    var workspaces = useSelector(function (state) { return state.workspaces; });
    var workspace = workspaces.current;
    var dispatch = useDispatch();
    var handleUpdateWorkspace = function (id, params) {
        return dispatch(updateWorkspace(id, params));
    };
    var handleDeleteWorkspace = function (id) {
        return dispatch(deleteWorkspace(id));
    };
    var handleSetCurrentWorkspace = function (workspace) {
        dispatch(setCurrentWorkspace(workspace));
    };
    var handleSetNotificationMessage = function (messageType, message) {
        dispatch(setNotificationMessage(messageType, message));
    };
    React.useEffect(function () {
        if (workspace) {
            dispatch(fetchWorkspacePayment());
        }
    }, [dispatch, workspace]);
    if (workspaces.isLoaded) {
        if (!workspace || workspace.role !== 'owner') {
            return <NotFound />;
        }
        if (!teams.isFetched || !payment.isLoaded) {
            return <LoadingPage />;
        }
    }
    else {
        return <LoadingPage />;
    }
    return (<WorkspacesEditForm deleteWorkspace={handleDeleteWorkspace} payment={payment.item} setCurrentWorkspace={handleSetCurrentWorkspace} setNotificationMessage={handleSetNotificationMessage} teams={workspaceTeams(teams.items)} updateWorkspace={handleUpdateWorkspace} workspace={workspace}/>);
};
export default WorkspacesEditContainer;
