import { __spreadArray } from "tslib";
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import I18n from '@/constants/i18n';
import { PersonalSettingsLayout } from '@/components/personal_settings_layout';
import ChangeUserEmailContainer from '@/containers/change_user_email_container';
import Icon from '@/components/atoms/icon';
import IdentityFieldContainer from '@/containers/identity_field_container';
import { TimeZoneSelect } from '@/components/account/time_zone_select';
import CustomRadio from '@/components/atoms/custom_radio';
import DeactivationContainer from '@/containers/pages/account/deactivation_container';
var locales = [
    {
        label: '日本語',
        value: 'ja',
    },
    {
        label: 'English',
        value: 'en',
    },
];
export var AccountSetting = function (_a) {
    var apiBase = _a.apiBase, identities = _a.identities, isApp = _a.isApp, isChromeExtension = _a.isChromeExtension, isMobile = _a.isMobile, isOpenDeactivation = _a.isOpenDeactivation, isOpenSubpane = _a.isOpenSubpane, isPC = _a.isPC, locale = _a.locale, openURL = _a.openURL, saved = _a.saved, saving = _a.saving, setIsOpenSubpane = _a.setIsOpenSubpane, signOut = _a.signOut, timeZone = _a.timeZone, timeZones = _a.timeZones, user = _a.user, onChangeLocale = _a.onChangeLocale, onClickDeactivation = _a.onClickDeactivation, onClickProvider = _a.onClickProvider, onClickSave = _a.onClickSave, onClickTimeZone = _a.onClickTimeZone, onCloseDeactivation = _a.onCloseDeactivation;
    var saveLabel = saving ? 'saving' : saved ? 'saved' : 'save';
    var providers = __spreadArray([
        'google',
        'outlook',
        'apple'
    ], (user.saml_allowed ? ['saml'] : []), true);
    return (<PersonalSettingsLayout active="account" isOpenSubpane={isOpenSubpane} isPC={isPC} saveButtonForPC={<button className="btn-major-action btn btn-primary" disabled={saving || saved} onClick={onClickSave}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>} saveButtonForSP={<button className="btn-mobile-header-action btn btn-link" disabled={saving || saved} onClick={onClickSave}>
          {I18n.t("shared.".concat(saveLabel))}
        </button>} setIsOpenSubpane={setIsOpenSubpane} signOut={signOut} title={I18n.t('shared.account')} user={user}>
      <Fragment>
        <div className="col-12">
          <div className="form-group row mb-2">
            <label className="col-form-label col-sm-3 col-12" htmlFor="email">
              {I18n.t('shared.email')}
            </label>
            <div className="col-xl-7 col-sm-9 col-12">
              <ChangeUserEmailContainer />
              <p className="help-block mt-1">
                {I18n.t('shared.reportRecipientsSettings')}
                <br />
                <Link className="btn btn-link px-1" to="/app/personal_settings/report_mail">
                  <Icon className="mr-1" name="mail" size="md"/>
                  {I18n.t('shared.reportMail')}
                </Link>
              </p>
              {user.bounced && (<p className="mt-1 text-danger">
                  {I18n.t('shared.bouncedRecipient')}
                </p>)}
            </div>
          </div>
        </div>

        {!user.otp_allowed && (<div className="col-12">
            <div className="form-group row mb-2">
              <label className="col-form-label col-sm-3 col-12" htmlFor="googleAccout">
                {I18n.t('shared.loginAccount')}
              </label>
              <div className="col-xl-7 col-sm-9 col-12">
                {(isPC || isMobile) && (<div>
                    {identities.map(function (identity) {
                    return (<IdentityFieldContainer key={"identity-".concat(identity.id)} identity={identity}/>);
                })}
                    <div className="form-group mt-1">
                      {providers.map(function (provider) { return (<div key={provider}>
                          <a className="btn btn-link px-1" href={"".concat(apiBase, "/identities/new?provider=").concat(provider)} onClick={onClickProvider}>
                            {I18n.t("personalSetting.connect".concat(provider[0].toUpperCase()).concat(provider.slice(1), "Accounts"))}
                          </a>
                        </div>); })}
                    </div>
                  </div>)}
                {isApp && (<p>
                    {I18n.t('personalSetting.appConnectGoogleAccountNotice')}
                  </p>)}
                {isChromeExtension && (<div>
                    <p>
                      {I18n.t('personalSetting.crxConnectGoogleAccountNotice')}
                    </p>
                    <a href={"".concat(apiBase, "/app/personal_settings/account")} onClick={function (e) {
                    e.preventDefault();
                    openURL(e.currentTarget.href);
                }}>
                      {I18n.t('personalSetting.goToPC')}
                    </a>
                  </div>)}
              </div>
            </div>
          </div>)}
      </Fragment>

      <div className="col-12">
        <div className="form-group row">
          <label className="col-form-label col-sm-3 col-12">
            {I18n.t('shared.timeZoneAndLocale')}
          </label>
          <div className="col-xl-7 col-sm-9 col-12">
            <div className="mb-2">
              <TimeZoneSelect selected={timeZone} timeZones={timeZones} onClick={onClickTimeZone}/>
            </div>
            {locales.map(function (l) { return (<CustomRadio key={l.value} checked={locale === l.value} value={l.value} onChange={onChangeLocale}>
                {l.label}
              </CustomRadio>); })}
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="form-group row mt-3">
          <div className="col-12">
            <hr />
            <button className="btn btn-link text-danger pr-0 pl-0 mt-1" type="button" onClick={onClickDeactivation}>
              {I18n.t('personalSetting.deactivate')}
            </button>
          </div>
          <DeactivationContainer email={user.email} isOpen={isOpenDeactivation} onClose={onCloseDeactivation}/>
        </div>
      </div>
    </PersonalSettingsLayout>);
};
