import React from 'react';
import I18n from '@/constants/i18n';
import { imageSource } from '@/utils/image_helper';
var IdentityField = function (_a) {
    var info_email = _a.info_email, provider = _a.provider, onDelete = _a.onDelete;
    return (<div className="row mb-2">
      <div className="col-12 d-flex">
        <div className="border-1 border-right-0 p-1 rounded-left">
          <img className="avatar avatar-md rounded-0" src={imageSource("sign_in_with_".concat(provider, "_logo.png"))}/>
        </div>
        <div className="input-group">
          <input readOnly className="rounded-0 form-control" type="email" value={info_email}/>
          <div className="input-group-btn">
            <button className="btn btn-danger" onClick={onDelete}>
              {I18n.t('shared.disconnect')}
            </button>
          </div>
        </div>
      </div>
    </div>);
};
export default IdentityField;
