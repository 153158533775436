export var FREEZE_SCROLL = 'timecrowd/display/FREEZE_SCROLL';
var initialState = {
    freezeScroll: false,
};
export default function display(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FREEZE_SCROLL:
            return { freezeScroll: action.isFreeze };
        default:
            return state;
    }
}
export var freezeScroll = function (isFreeze) {
    if (isFreeze === void 0) { isFreeze = false; }
    return ({
        type: FREEZE_SCROLL,
        isFreeze: isFreeze,
    });
};
