import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, signOut } from '@/actions/user';
import { setIsOpenPersonalSettingsSubpane } from '@/modules/personal_settings';
import { fetchTimeZones } from '@/actions/time_zone';
import { fetchIdentities } from '@/api2/identities';
import { setNotificationMessage } from '@/modules/notification';
import I18n from '@/constants/i18n';
import { AccountSetting } from '@/components/account_setting';
var AccountSettingContainer = function () {
    var defaultTimeZone = useSelector(function (state) { return state.user.time_zone; });
    var timeZones = useSelector(function (state) { return state.timeZones; });
    var identities = useSelector(function (state) { return state.identities.items; });
    var defaultLocale = useSelector(function (state) { return state.user.locale; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var isMobile = useSelector(function (state) { return state.isMobile; });
    var isApp = useSelector(function (state) { return state.isApp; });
    var isChromeExtension = useSelector(function (state) { return state.isChromeExtension; });
    var apiBase = useSelector(function (state) { return state.apiBase; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var user = useSelector(function (state) { return state.user; });
    var isOpenSubpane = useSelector(function (state) { return state.personalSettings.isOpenSubpane; });
    var _a = React.useState(defaultTimeZone), timeZone = _a[0], setTimeZone = _a[1];
    var _b = React.useState(defaultLocale), locale = _b[0], setLocale = _b[1];
    var _c = React.useState(false), saving = _c[0], setSaving = _c[1];
    var _d = React.useState(false), saved = _d[0], setSaved = _d[1];
    var _e = React.useState(false), isOpenDeactivation = _e[0], setIsOpenDeactivation = _e[1];
    var dispatch = useDispatch();
    React.useEffect(function () {
        dispatch(fetchTimeZones());
        dispatch(fetchIdentities());
    }, [dispatch]);
    var handleClickSave = function () {
        var params = {
            user: {
                time_zone: timeZone,
                locale: locale,
            },
        };
        setSaving(true);
        dispatch(updateUser(params)).then(function (user) {
            if (user.error) {
                setSaving(false);
                return;
            }
            dispatch(fetchTimeZones());
            setSaving(false);
            setSaved(true);
            setTimeout(function () {
                setSaved(false);
            }, 2000);
        });
    };
    var handleClickProvider = function (e) {
        var _a;
        if (((_a = user.allowed_sign_in_methods) === null || _a === void 0 ? void 0 : _a.length) &&
            !user.allowed_sign_in_methods.includes(user.provider)) {
            handleSetNotificationMessage('danger', I18n.t('shared.operationRestrictedByAdministrator'));
            e.preventDefault();
        }
    };
    var handleClickTimeZone = function (e) {
        setTimeZone(e.target.value);
    };
    var handleChangeLocale = function (e) {
        setLocale(e.target.value);
    };
    var handleClickDeactivation = function () {
        setIsOpenDeactivation(true);
    };
    var handleCloseDeactivation = function () {
        setIsOpenDeactivation(false);
    };
    var handleSignOut = function () {
        dispatch(signOut());
    };
    var setIsOpenSubpane = function (isOpen) {
        dispatch(setIsOpenPersonalSettingsSubpane(isOpen));
    };
    var handleSetNotificationMessage = function (type, message) {
        dispatch(setNotificationMessage(type, message));
    };
    return (<AccountSetting apiBase={apiBase} identities={identities} isApp={isApp} isChromeExtension={isChromeExtension} isMobile={isMobile} isOpenDeactivation={isOpenDeactivation} isOpenSubpane={isOpenSubpane} isPC={isPC} locale={locale} openURL={openURL} saved={saved} saving={saving} setIsOpenSubpane={setIsOpenSubpane} signOut={handleSignOut} timeZone={timeZone} timeZones={timeZones} user={user} onChangeLocale={handleChangeLocale} onClickDeactivation={handleClickDeactivation} onClickProvider={handleClickProvider} onClickSave={function () { return handleClickSave(); }} onClickTimeZone={handleClickTimeZone} onCloseDeactivation={handleCloseDeactivation}/>);
};
export default AccountSettingContainer;
