import { __awaiter, __generator } from "tslib";
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import Avatar from '@/components/atoms/avatar';
import { CharsetSelect } from '../charset_select';
import variants from '@/constants/variants';
var saveExportCharset = variants.saveExportCharset;
export var CategoryTaskStructuresExportModal = function (_a) {
    var team = _a.team, defaultCharset = _a.defaultCharset, onExport = _a.onExport;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = React.useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = React.useState(defaultCharset), charset = _d[0], setCharset = _d[1];
    React.useEffect(function () {
        setCharset(defaultCharset);
    }, [defaultCharset]);
    var toggle = React.useCallback(function () {
        setIsOpen(!isOpen);
    }, [isOpen]);
    var onClickExport = React.useCallback(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, onExport(charset)];
                    case 1:
                        _a.sent();
                        setIsLoading(false);
                        setIsOpen(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [onExport, charset]);
    var onChangeCharset = React.useCallback(function (charset) {
        setCharset(charset);
        saveExportCharset(charset);
    }, []);
    return (<>
      <button className="btn btn-primary" onClick={toggle}>
        {I18n.t('shared.exportCSVFile')}
      </button>

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeaderMod toggle={toggle}>
          {I18n.t('shared.exportCSVFile')}
        </ModalHeaderMod>

        <ModalBody>
          <p>{I18n.t('shared.exportFromTeam')}</p>
          <div>
            <Avatar className="mr-1" image={team.avatar_url} size="sm"/>
            {team.name}
          </div>
          <h5 className="mt-4">{I18n.t("export.charset.label")}</h5>
          <div className="mt-2">
            <CharsetSelect charset={charset} onChangeCharset={onChangeCharset}/>
          </div>
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-secondary" disabled={isLoading} onClick={toggle}>
            {I18n.t('shared.cancel')}
          </button>
          <button className="btn btn-primary" disabled={isLoading} onClick={onClickExport}>
            {I18n.t('shared.export')}
          </button>
        </ModalFooter>
      </Modal>
    </>);
};
