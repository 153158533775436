import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Helplink from '@/components/molecules/helplink';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
var DeactivationModal = function (_a) {
    var email = _a.email, isOpen = _a.isOpen, isConfirm = _a.isConfirm, isLoading = _a.isLoading, onSubmit = _a.onSubmit, onConfirm = _a.onConfirm, onClose = _a.onClose, openURL = _a.openURL;
    return (<Modal isOpen={isOpen} toggle={onClose}>
    <ModalHeaderMod toggle={onClose}>
      {I18n.t('personalSetting.deactivate')}
    </ModalHeaderMod>

    <ModalBody>
      <p dangerouslySetInnerHTML={{
            __html: I18n.t('personalSetting.deactivation.yourEmailHtml', {
                email: email,
            }),
        }}/>
      <p>{I18n.t('personalSetting.deactivation.attention')}</p>
      <ul>
        <li>{I18n.t('personalSetting.deactivation.attentionDetail1')}</li>
        <li>{I18n.t('personalSetting.deactivation.attentionDetail2')}</li>
        <li>{I18n.t('personalSetting.deactivation.attentionDetail3')}</li>
      </ul>
      <p>
        <Helplink className="mx-0" href="https://help.timecrowd.net/unsubscribe" onClick={function (e) {
            e.preventDefault();
            openURL(e.currentTarget.href);
        }}>
          {I18n.t('personalSetting.deactivation.checkHelpPage')}
        </Helplink>
      </p>
      <div className="form-group">
        <CustomCheck checked={isConfirm} onChange={onConfirm}>
          <span className="text-danger">
            {I18n.t('personalSetting.deactivation.confirmation')}
          </span>
        </CustomCheck>
      </div>
    </ModalBody>

    <ModalFooter>
      <button className="btn btn-danger" disabled={!isConfirm || isLoading} onClick={onSubmit}>
        {I18n.t('personalSetting.deactivate')}
      </button>
      <button className="btn btn-secondary" disabled={isLoading} onClick={onClose}>
        {I18n.t('shared.close')}
      </button>
    </ModalFooter>
  </Modal>);
};
export default DeactivationModal;
