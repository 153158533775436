import React from 'react';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
export var CrossTeamReportLongTermProgress = function (props) {
    var isCompleted = props.isCompleted, isProgress = props.isProgress;
    return (<>
      {isCompleted && (<div className="position-absolute right-0 top-0 bottom-0 d-flex align-items-center">
          {I18n.t('shared.done')}
          <Icon className="text-primary" name="checkmark"/>
        </div>)}
      {isProgress && (<LoadingSpinner className="position-absolute right-0 top-0 bottom-0 mr-2" size="sm"/>)}
    </>);
};
