import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import InfiniteScroll from 'react-infinite-scroller';
import last from 'lodash/last';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import DateBar from '@/components/molecules/date_bar';
import TaskListItem from './task_list_item';
export var TaskList = function (_a) {
    var activities = _a.activities, arrangeRecentCategories = _a.arrangeRecentCategories, currentEntry = _a.currentEntry, isLoading = _a.dailyActivities.isLoading, destroyTimeEntry = _a.destroyTimeEntry, expandDailyActivity = _a.expandDailyActivity, fetchDailyTimeEntries = _a.fetchDailyTimeEntries, fetchWorkingUsersCount = _a.fetchWorkingUsersCount, isApp = _a.isApp, isMobile = _a.isMobile, restoreTask = _a.restoreTask, setCurrentCategory = _a.setCurrentCategory, setCurrentEntry = _a.setCurrentEntry, startTask = _a.startTask, stopTimeEntry = _a.stopTimeEntry, timeEntries = _a.timeEntries, updateDailyActivity = _a.updateDailyActivity, updateTask = _a.updateTask, user = _a.user;
    var lastActivity = last(activities);
    var handleLoadMore = function () {
        if (!activities.length) {
            return;
        }
        var lastActivity = last(activities);
        if (lastActivity === null || lastActivity === void 0 ? void 0 : lastActivity.next_date) {
            var nextDate = moment(lastActivity.next_date);
            expandDailyActivity(nextDate.format('YYYY-MM-DD'));
        }
    };
    var navigate = useNavigate();
    var toggleTaskEdit = function (task) {
        navigate("/app/mytasks/".concat(task.id, "/edit"));
    };
    return (<div className="tasklist">
      {!lastActivity && (<div className="py-4 text-center">
          <LoadingSpinner size="md"/>
        </div>)}
      {lastActivity && lastActivity.tasks && (<InfiniteScroll hasMore={!isLoading && !!lastActivity.next_date} loader={<div key={0} className="py-4 text-center">
              <LoadingSpinner size="md"/>
            </div>} loadMore={handleLoadMore}>
          {activities.map(function (activity) {
                if (!activity.tasks || activity.tasks.length === 0) {
                    return;
                }
                return [
                    <DateBar key={activity.date} date={activity.date} hours={activity.total.hours} minutes={activity.total.minutes}/>,
                    activity.tasks.map(function (task) { return (<TaskListItem key={task.id} arrangeRecentCategories={arrangeRecentCategories} currentEntry={currentEntry} date={activity.date} destroyTimeEntry={destroyTimeEntry} fetchDailyTimeEntries={fetchDailyTimeEntries} fetchWorkingUsersCount={fetchWorkingUsersCount} isApp={isApp} isMobile={isMobile} restoreTask={restoreTask} setCurrentCategory={setCurrentCategory} setCurrentEntry={setCurrentEntry} startTask={startTask} stopTimeEntry={stopTimeEntry} task={task} timeEntries={timeEntries} toggleTaskEdit={toggleTaskEdit} updateDailyActivity={updateDailyActivity} updateTask={updateTask} user={user}/>); }),
                ];
            })}
        </InfiniteScroll>)}
    </div>);
};
