import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
import { CategoryTaskStructuresImportModal } from '@/components/category_task_structures_import_modal';
import { formatDateTime } from '@/utils/time_helper';
import variants from '@/constants/variants';
import { workspacePath } from '@/utils/workspace_helper';
var DROPZONE_CONTAINER_CLASSNAMES = 'd-flex justify-content-center align-items-center rounded py-3';
export var CategoryTaskStructuresImportForm = function (_a) {
    var _b = _a.openURL, openURL = _b === void 0 ? variants.openURL : _b, imports = _a.imports, canImport = _a.canImport, team = _a.team, isLoading = _a.isLoading, hasNext = _a.hasNext, createCategoryTaskStructuresImport = _a.createCategoryTaskStructuresImport, fetchCategoryTaskStructuresImports = _a.fetchCategoryTaskStructuresImports;
    var _c = React.useState([]), acceptedFiles = _c[0], setAcceptedFiles = _c[1];
    var _d = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        accept: { 'text/csv': ['.csv'] },
        onDrop: function (files) { return setAcceptedFiles(files); },
    }), getRootProps = _d.getRootProps, getInputProps = _d.getInputProps, open = _d.open, isDragAccept = _d.isDragAccept;
    var navigate = useNavigate();
    var file = acceptedFiles[0];
    var onClickLink = React.useCallback(function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    }, [openURL]);
    var onClickPay = React.useCallback(function () {
        navigate(workspacePath("/teams/".concat(team.id, "/payment")));
    }, [team, navigate]);
    var onImport = React.useCallback(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                params = new FormData();
                params.append('import[team_id]', team.id);
                params.append('import[file]', file);
                createCategoryTaskStructuresImport(params);
                return [2 /*return*/];
            });
        }); })();
    }, [createCategoryTaskStructuresImport, file, team.id]);
    var onClear = React.useCallback(function () {
        setAcceptedFiles([]);
    }, [setAcceptedFiles]);
    var loadMore = React.useCallback(function () {
        fetchCategoryTaskStructuresImports(team.id);
    }, [fetchCategoryTaskStructuresImports, team.id]);
    var processingImport = imports.find(function (_a) {
        var status = _a.status;
        return status === 'processing';
    });
    var completedImports = imports.filter(function (_a) {
        var status = _a.status;
        return status !== 'processing';
    });
    return (<div>
      <p>
        {I18n.t('shared.categoryTaskStructureImportNote1')}
        <br />
        {I18n.t('shared.categoryTaskStructureImportNote2')}
        <a href="https://help.timecrowd.net/category_task_structures" onClick={onClickLink}>
          {I18n.t('shared.here')}
        </a>
      </p>
      {canImport ? (<>
          {processingImport ? (<div className={"".concat(DROPZONE_CONTAINER_CLASSNAMES, " bg-faded")}>
              <p className="text-center">
                <Icon color="secondary" name="mail" size="xl"/>
                <br />
                {I18n.t('shared.importingCSVFile')}
                <br />
                {I18n.t('shared.notifyWhenComplete', {
                    email: processingImport.user_email,
                })}
              </p>
            </div>) : file ? (<div className={"".concat(DROPZONE_CONTAINER_CLASSNAMES, " border-2 border-dashed")}>
              <CategoryTaskStructuresImportModal file={file} team={team} onClear={onClear} onImport={onImport}/>
            </div>) : (<div 
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getRootProps({
                className: "dropzone ".concat(DROPZONE_CONTAINER_CLASSNAMES, " border-2 border-dashed ").concat(isDragAccept ? 'bg-faded' : ''),
            })}>
              <input {...getInputProps()} // eslint-disable-line react/jsx-props-no-spreading
            />
              <Icon color="secondary" name="upload" size="xl"/>
              <div className="ml-2">
                <p className="mb-0">{I18n.t('shared.dragAndDropFile')}</p>
                <p className="mb-0 d-flex align-items-center">
                  {I18n.t('shared.or')}
                  <button className="btn btn-link p-0" onClick={open}>
                    {I18n.t('shared.selectFile')}
                  </button>
                  {I18n.t('shared.selectFileSuffix')}
                </p>
              </div>
            </div>)}

          <h4 className="mt-4">
            {I18n.t('shared.categoryTaskStructureImportHistory')}
          </h4>
          <div className="mt-2">
            {completedImports.length < 1 ? (<p className="mt-2">{I18n.t('shared.noHistoryFound')}</p>) : (<div>
                <table>
                  <thead>
                    <tr>
                      <th className="pr-1">{I18n.t('shared.datetime')}</th>
                      <th className="pr-1">{I18n.t('shared.filename')}</th>
                      <th className="pr-1">
                        {I18n.t('shared.processingResult')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {completedImports.map(function (_a) {
                    var id = _a.id, created_at = _a.created_at, filename = _a.filename, status = _a.status, error = _a.error;
                    return (<tr key={id}>
                          <td className="pr-1 text-nowrap align-top">
                            {formatDateTime(new Date(created_at))}
                          </td>
                          <td className="pr-1 text-break align-top">
                            {filename}
                          </td>
                          <td className="pr-1 text-nowrap align-top">
                            {status === 'failed' ? (<span>
                                <Icon color="danger" name="warning"/>
                                <span className="ml-1">
                                  {I18n.t("shared.failed_".concat(error))}
                                </span>
                              </span>) : (<span>
                                <span className="bg-primary text-white rounded-circle d-inline-flex justify-content-center align-items-center" style={{ width: '1.5rem', height: '1.5rem' }}>
                                  <Icon name="checkmark" size="xs"/>
                                </span>
                                <span className="ml-1">
                                  {I18n.t('shared.importComplete')}
                                </span>
                              </span>)}
                          </td>
                        </tr>);
                })}
                  </tbody>
                </table>
                {hasNext && (<p className="mt-1">
                    <button className="btn btn-secondary" disabled={isLoading} onClick={loadMore}>
                      {I18n.t('shared.showMore')}
                    </button>
                  </p>)}
              </div>)}
          </div>
        </>) : (<div className="mt-4">
          <p>{I18n.t('shared.thisIsForPaidTeams')}</p>
          <p>
            <button className="btn btn-primary" onClick={onClickPay}>
              {I18n.t('shared.clickHereToPay')}
            </button>
          </p>
        </div>)}
    </div>);
};
