import I18n from '@/constants/i18n';
export var buildBookmarkTitle = function (bookmark) {
    if (bookmark.name) {
        return bookmark.name;
    }
    var conditions = bookmark.conditions;
    return [
        [
            conditions.period_type === 'custom'
                ? "".concat(conditions.from, " - ").concat(conditions.to)
                : [I18n.t("shared.".concat(conditions.period_type))],
        ],
        [
            conditions.selected_teams.length
                ? conditions.selected_teams[0].name
                : null,
            conditions.selected_teams.length > 1
                ? I18n.t('shared.othersShort')
                : null,
        ],
        [
            conditions.selected_users.length
                ? conditions.selected_users[0].nickname
                : null,
            conditions.selected_users.length > 1
                ? I18n.t('shared.othersShort')
                : null,
        ],
        [
            conditions.selected_categories.length
                ? conditions.selected_categories[0].title
                : null,
            conditions.selected_categories.length > 1
                ? I18n.t('shared.others')
                : null,
        ],
    ]
        .map(function (s) { return s.join(' '); })
        .filter(function (s) { return s.trim(); })
        .join(', ');
};
