import { __assign } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import throttle from 'lodash/throttle';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorBoundary from '@/components/error_boundary';
import Placeholder from '@/components/pages/placeholder';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../actions';
import classNames from 'classnames';
import { CrossTeamReportLongTermNotificationContainer } from '@/containers/cross_team_report_long_term_notification_container';
import { SupportAccountLabel } from '@/components/support_account_label';
import { MinAppVersionAlertContainer } from '@/containers/min_app_version_alert_container';
import '../../../iconfonts/iconfonts.css';
import '../../../bootstrap/dist/css/bootstrap.css';
import '../../../../app/assets/builds/tailwind.css';
import { setNotificationMessage, removeOutdatedNotificationMessage, removeDangerNotificationMessage, } from '@/modules/notification';
import { clearUndo } from '@/modules/undo';
import I18n from '@/constants/i18n';
import { EnvMark } from '@/components/molecules/env_mark';
import ErrorScreen from '@/components/error_boundary/error_screen';
import variants from '@/constants/variants';
import { fetchWorkspaces, setCurrentWorkspace } from '@/modules/workspaces';
import { parseWorkspaceId } from '@/utils/workspace_helper';
import { resetWorkspaceResources } from '@/actions/workspace_reset';
import NotificationContainer from '@/containers/notification_container';
import { UndoAlertContainer } from '@/containers/undo_alert_container';
import { fetchTeams } from '@/modules/teams';
var Layout = function (props) {
    var _a;
    var children = props.children, currentUser = props.currentUser, freezeScroll = props.display.freezeScroll, fetchCurrentUser = props.fetchCurrentUser, fetchWorkingUsersCount = props.fetchWorkingUsersCount, fetchWorkspaces = props.fetchWorkspaces, isApp = props.isApp, isChromeExtension = props.isChromeExtension, isMobile = props.isMobile, isPC = props.isPC, isShowNotification = props.isShowNotification, openURL = props.openURL, reloadPage = props.reloadPage, resetWorkspaceResources = props.resetWorkspaceResources, setCurrentWorkspace = props.setCurrentWorkspace, setNotificationMessage = props.setNotificationMessage, updateDailyActivity = props.updateDailyActivity, workspaces = props.workspaces;
    var intervalRef = React.useRef(null);
    var location = useLocation();
    var navigate = useNavigate();
    // NOTE: ページの寿命を2週間に設定
    var ttl = React.useRef(1000 * 60 * 60 * 24 * 14);
    var baseTime = React.useRef(Date.now());
    var onWalkthrough = /^\/app\/walkthrough\//.test(location.pathname);
    var locationWorkspaceId = parseWorkspaceId(location);
    var currentWorkspaceId = ((_a = workspaces.current) === null || _a === void 0 ? void 0 : _a.id) || null;
    var isReadyWorkspaces = locationWorkspaceId === currentWorkspaceId;
    var activeTaskForm = useSelector(function (state) { return state.myTask.activeTaskForm; });
    var dispatch = useDispatch();
    React.useEffect(function () {
        dispatch(fetchTeams());
        // NOTE: チームの変更を反映するトリガーとしてワークスペースの変更を利用する
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, workspaces.current]);
    // NOTE: ページが古くなった場合に通知してリロードを促す
    React.useEffect(function () {
        if (isApp || isShowNotification) {
            return;
        }
        if (Date.now() - baseTime.current > ttl.current) {
            setNotificationMessage(I18n.t('shared.pleaseReloadPage'));
        }
    }, [isApp, isShowNotification, location.pathname, setNotificationMessage]);
    // NOTE: ページが古くなった場合にアプリの場合はリソースを取得し直してリロードする
    React.useEffect(function () {
        if (!isApp) {
            return;
        }
        if (Date.now() - baseTime.current > ttl.current) {
            reloadPage();
        }
    }, [isApp, location.pathname, reloadPage]);
    var handleFetchWorkingUsersCount = React.useCallback(function () {
        fetchWorkingUsersCount().then(function () {
            // @ts-ignore jsxの状態ではuseRef関連の型チェックエラーを回避できないため、一時的に無効化
            intervalRef.current = setInterval(function () {
                fetchWorkingUsersCount().then(function (json) {
                    if (json.error && intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }
                });
            }, 60 * 15000);
        });
    }, [fetchWorkingUsersCount]);
    React.useEffect(function () {
        fetchCurrentUser().then(function (user) {
            if (!user.error) {
                variants.loadStorage('lastSignedInUserId', function (value) {
                    if (value && value !== user.id) {
                        variants.clearStorage();
                    }
                    variants.saveStorage('lastSignedInUserId', user.id);
                });
            }
        });
        handleFetchWorkingUsersCount();
        return function () {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [fetchCurrentUser, handleFetchWorkingUsersCount]);
    var refreshUser = React.useCallback(function () {
        fetchCurrentUser();
        if (location.pathname === '/app') {
            updateDailyActivity(moment().format('YYYY-MM-DD'));
        }
    }, [fetchCurrentUser, location.pathname, updateDailyActivity]);
    React.useEffect(function () {
        var throttledRefreshUser = throttle(refreshUser, 3000, {
            trailing: false,
        });
        var onFocus = function () {
            if (!isReadyWorkspaces) {
                return;
            }
            throttledRefreshUser();
        };
        window.addEventListener('focus', onFocus);
        return function () { return window.removeEventListener('focus', onFocus); };
    }, [isReadyWorkspaces, refreshUser]);
    React.useEffect(function () {
        variants.saveHistory(location.pathname);
    }, [location.pathname]);
    React.useEffect(function () {
        if (workspaces.isLoading || workspaces.isLoaded) {
            return;
        }
        fetchWorkspaces();
    }, [
        currentUser.loaded,
        fetchWorkspaces,
        workspaces.isLoaded,
        workspaces.isLoading,
    ]);
    React.useEffect(function () {
        var _a, _b;
        if (!workspaces.isLoaded) {
            return;
        }
        var locationWorkspace = (_a = workspaces.items.find(function (_a) {
            var id = _a.id;
            return id === locationWorkspaceId;
        })) !== null && _a !== void 0 ? _a : null;
        if (((_b = locationWorkspace === null || locationWorkspace === void 0 ? void 0 : locationWorkspace.id) !== null && _b !== void 0 ? _b : null) !== currentWorkspaceId) {
            resetWorkspaceResources();
            setCurrentWorkspace(locationWorkspace);
        }
    }, [
        currentUser.loaded,
        setCurrentWorkspace,
        resetWorkspaceResources,
        locationWorkspaceId,
        workspaces.isLoaded,
        workspaces.items,
        currentWorkspaceId,
    ]);
    var redirectToWalkthrough = React.useCallback(function () {
        navigate('/app/walkthrough/welcome');
    }, [navigate]);
    var redirectToHome = React.useCallback(function () {
        navigate('/app');
    }, [navigate]);
    React.useEffect(function () {
        if (!currentUser.loaded) {
            return;
        }
        if (!workspaces.isLoaded) {
            return;
        }
        var isBelongsToWorkspace = workspaces.items.length > 0;
        if (!isBelongsToWorkspace && !onWalkthrough) {
            redirectToWalkthrough();
            return;
        }
        if (isBelongsToWorkspace && onWalkthrough) {
            redirectToHome();
        }
    }, [
        currentUser.loaded,
        onWalkthrough,
        redirectToHome,
        redirectToWalkthrough,
        workspaces.isLoaded,
        workspaces.items,
    ]);
    var onClickLink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    var classes = classNames('content', {
        'freeze-scroll': freezeScroll,
        'is-crx': isChromeExtension,
        'is-mobile': isMobile,
        'is-app': isApp,
        'is-pc': isPC,
    });
    React.useEffect(function () {
        dispatch(removeOutdatedNotificationMessage());
        dispatch(clearUndo());
    }, [dispatch, location.pathname]);
    React.useEffect(function () {
        dispatch(removeDangerNotificationMessage());
    }, [activeTaskForm, dispatch]);
    if (currentUser.failed && !currentUser.loaded) {
        return (<>
        <ErrorScreen status={500} onClickLink={onClickLink} onReload={reloadPage}/>
        <EnvMark />
      </>);
    }
    return (<ErrorBoundary user={currentUser.item}>
      <main className={classes} id="content">
        <NotificationContainer />
        <UndoAlertContainer />
        <MinAppVersionAlertContainer />
        {isPC && <CrossTeamReportLongTermNotificationContainer />}
        {currentUser.loaded && (isReadyWorkspaces || onWalkthrough) ? (children) : !onWalkthrough ? (<Placeholder />) : null}
      </main>
      <SupportAccountLabel isShow={currentUser.item.role === 'bot'}/>
      <EnvMark />
    </ErrorBoundary>);
};
var mapStateToProps = function (state) { return ({
    currentUser: state.currentUser,
    display: state.display,
    isApp: state.isApp,
    isChromeExtension: state.isChromeExtension,
    isMobile: state.isMobile,
    isPC: state.isPC,
    openURL: state.openURL,
    reloadPage: state.reloadPage,
    workspaces: state.workspaces,
    isShowNotification: state.notification.isShown,
}); };
var mapDispatchToProps = function (dispatch) { return (__assign(__assign({ dispatch: dispatch }, bindActionCreators(actions, dispatch)), { fetchWorkspaces: function () {
        return dispatch(fetchWorkspaces());
    }, setCurrentWorkspace: function (workspace) {
        dispatch(setCurrentWorkspace(workspace));
    }, resetWorkspaceResources: function () {
        dispatch(resetWorkspaceResources());
    }, setNotificationMessage: function (message) {
        dispatch(setNotificationMessage('warning', message, { isPermanent: true }));
    } })); };
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
