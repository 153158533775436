import { __assign } from "tslib";
import { connect } from 'react-redux';
import TeamList from '@/components/team_children/team_list';
import { openModal } from '@/modules/modal';
import { workspaceTeams } from '@/utils/workspace_helper';
import { parentId } from '@/utils/ancestry_helper';
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { teams: workspaceTeams(state.teams.items).filter(function (t) { return parentId(t) === ownProps.team.id; }) })); };
var mapDispatchToProps = function (dispatch) { return ({
    onOpenModal: function (modalType, modalProps) {
        dispatch(openModal(modalType, modalProps));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TeamList);
