import React from 'react';
import I18n from '@/constants/i18n';
import TeamListItem from './team_list_item';
var TeamList = function (props) {
    var teams = props.teams, onOpenModal = props.onOpenModal;
    var handleClickLeave = function (modalProps) {
        onOpenModal('DELETE_CHILD_TEAM_MODAL', modalProps);
    };
    if (teams.length) {
        return (<tbody>
        {teams.map(function (team) {
                return (<TeamListItem key={team.id} team={team} onLeave={handleClickLeave}/>);
            })}
      </tbody>);
    }
    else {
        return (<tbody>
        <tr>
          <td colSpan={2}>{I18n.t('teamChildren.notExistChildren')}</td>
        </tr>
      </tbody>);
    }
};
export default TeamList;
