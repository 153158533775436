import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import I18n from '@/constants/i18n';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
import { isChild } from '@/modules/categories';
import Icon from '@/components/atoms/icon';
import CustomCheck from '@/components/atoms/custom_check';
var DeleteCategoryModal = function (props) {
    var toggle = props.toggle, category = props.category, categories = props.categories, currentCategory = props.currentCategory, isOpen = props.isOpen, onDelete = props.onDelete, onRemoveCategoryFromRecentCategories = props.onRemoveCategoryFromRecentCategories, onRemoveCurrentCategory = props.onRemoveCurrentCategory;
    var _a = React.useState(false), isConfirm = _a[0], setIsConfirm = _a[1];
    var _b = React.useState(false), saving = _b[0], setSaving = _b[1];
    var toggleModal = function () {
        setIsConfirm(false);
        toggle();
    };
    var onChangeConfirm = function (e) {
        setIsConfirm(e.target.checked);
    };
    var onClickRemove = function () {
        setSaving(true);
        onDelete(category).then(function (response) {
            if (response.error) {
                setSaving(false);
                return;
            }
            onRemoveCategoryFromRecentCategories(category);
            if (currentCategory && currentCategory.id === category.id) {
                onRemoveCurrentCategory();
            }
            setIsConfirm(false);
            setSaving(false);
            toggle();
        });
    };
    var renderCategory = function (parent) {
        if (!parent) {
            return <></>;
        }
        var children = categories.filter(function (category) { return isChild(parent, category); });
        return (<>
        <i className={"category-circle category-".concat(parent.color)}/>
        {parent.title}
        {parent.archived && (<span className="badge badge-info ml-1">
            <Icon className="mr-1" name="archive" size="sm"/>
            {I18n.t('shared.archived')}
          </span>)}
        {children.length > 0 && (<ul className="list-unstyled ml-2">
            {children.map(function (child) { return (<li key={"category-".concat(parent.id, "-").concat(child.id)}>
                {renderCategory(child)}
              </li>); })}
          </ul>)}
      </>);
    };
    return (<Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeaderMod toggle={toggleModal}>
        {I18n.t('teamManagement.removeCategoryModal.title')}
      </ModalHeaderMod>

      <ModalBody className="text-break">
        <p>{I18n.t('teamManagement.removeCategoryModal.attention')}</p>
        <div className="mb-2">
          {renderCategory(categories.find(function (c) { return c.id === category.id; }))}
        </div>
        <div className="form-group">
          <CustomCheck checked={isConfirm} onClick={onChangeConfirm}>
            <span className="text-danger">
              {I18n.t('teamManagement.removeCategoryModal.confirmation')}
            </span>
          </CustomCheck>
        </div>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-secondary" onClick={toggleModal}>
          {I18n.t('shared.cancel')}
        </button>
        <button className="btn btn-danger" disabled={!isConfirm || saving} onClick={onClickRemove}>
          {I18n.t('shared.remove')}
        </button>
      </ModalFooter>
    </Modal>);
};
export default DeleteCategoryModal;
