import { __assign, __spreadArray } from "tslib";
import api from '@/api';
import api2 from '@/api2';
export var FETCH_TEAM_EMPLOYS_REQUEST = 'timecrowd/employs/FETCH_TEAM_EMPLOYS_REQUEST';
export var FETCH_TEAM_EMPLOYS_SUCCESS = 'timecrowd/employs/FETCH_TEAM_EMPLOYS_SUCCESS';
export var FETCH_TEAM_EMPLOYS_FAILURE = 'timecrowd/employs/FETCH_TEAM_EMPLOYS_FAILURE';
export var CREATE_EMPLOY_REQUEST = 'timecrowd/employs/CREATE_EMPLOY_REQUEST';
export var CREATE_EMPLOY_SUCCESS = 'timecrowd/employs/CREATE_EMPLOY_SUCCESS';
export var CREATE_EMPLOY_FAILURE = 'timecrowd/employs/CREATE_EMPLOY_FAILURE';
export var UPDATE_EMPLOY_REQUEST = 'timecrowd/employs/UPDATE_EMPLOY_REQUEST';
export var UPDATE_EMPLOY_SUCCESS = 'timecrowd/employs/UPDATE_EMPLOY_SUCCESS';
export var UPDATE_EMPLOY_FAILURE = 'timecrowd/employs/UPDATE_EMPLOY_FAILURE';
export var DELETE_EMPLOY_REQUEST = 'timecrowd/employs/DELETE_EMPLOY_REQUEST';
export var DELETE_EMPLOY_SUCCESS = 'timecrowd/employs/DELETE_EMPLOY_SUCCESS';
export var DELETE_EMPLOY_FAILURE = 'timecrowd/employs/DELETE_EMPLOY_FAILURE';
export var initialState = {
    isLoading: false,
    isSubmitting: false,
    itemsByTeamId: {},
};
function changeEmploy(employs, newEmploy) {
    return employs.map(function (employ) {
        if (employ.id === newEmploy.id) {
            return newEmploy;
        }
        else {
            return employ;
        }
    });
}
export default function employs(state, action) {
    var _a, _b, _c, _d;
    var _e, _f, _g;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_TEAM_EMPLOYS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_TEAM_EMPLOYS_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, itemsByTeamId: __assign(__assign({}, state.itemsByTeamId), (_a = {}, _a[action.teamId] = action.employs, _a)) });
        case CREATE_EMPLOY_REQUEST:
        case UPDATE_EMPLOY_REQUEST:
        case DELETE_EMPLOY_REQUEST:
            return __assign(__assign({}, state), { isSubmitting: true });
        case CREATE_EMPLOY_SUCCESS:
            return __assign(__assign({}, state), { isSubmitting: false, itemsByTeamId: __assign(__assign({}, state.itemsByTeamId), (_b = {}, _b[action.teamId] = __spreadArray(__spreadArray([], ((_e = state.itemsByTeamId[action.teamId]) !== null && _e !== void 0 ? _e : []), true), [
                    action.employ,
                ], false), _b)) });
        case UPDATE_EMPLOY_SUCCESS:
            return __assign(__assign({}, state), { isSubmitting: false, itemsByTeamId: __assign(__assign({}, state.itemsByTeamId), (_c = {}, _c[action.teamId] = changeEmploy((_f = state.itemsByTeamId[action.teamId]) !== null && _f !== void 0 ? _f : [], action.employ), _c)) });
        case DELETE_EMPLOY_SUCCESS:
            return {
                isSubmitting: false,
                itemsByTeamId: __assign(__assign({}, state.itemsByTeamId), (_d = {}, _d[action.teamId] = ((_g = state.itemsByTeamId[action.teamId]) !== null && _g !== void 0 ? _g : []).filter(function (employ) { return employ.id !== action.id; }), _d)),
            };
        case FETCH_TEAM_EMPLOYS_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        case CREATE_EMPLOY_FAILURE:
        case UPDATE_EMPLOY_FAILURE:
        case DELETE_EMPLOY_FAILURE:
            return __assign(__assign({}, state), { isSubmitting: false });
        default:
            return state;
    }
}
export var fetchTeamEmploysRequest = function () { return ({
    type: FETCH_TEAM_EMPLOYS_REQUEST,
}); };
export var fetchTeamEmploysSuccess = function (teamId, employs) { return ({
    type: FETCH_TEAM_EMPLOYS_SUCCESS,
    teamId: teamId,
    employs: employs,
}); };
export var fetchTeamEmploysFailure = function () { return ({
    type: FETCH_TEAM_EMPLOYS_FAILURE,
}); };
export var createEmployRequest = function () { return ({
    type: CREATE_EMPLOY_REQUEST,
}); };
export var createEmploySuccess = function (teamId, employ) { return ({
    type: CREATE_EMPLOY_SUCCESS,
    teamId: teamId,
    employ: employ,
}); };
export var createEmployFailure = function () { return ({
    type: CREATE_EMPLOY_FAILURE,
}); };
export var updateEmployRequest = function () { return ({
    type: UPDATE_EMPLOY_REQUEST,
}); };
export var updateEmploySuccess = function (teamId, employ) { return ({
    type: UPDATE_EMPLOY_SUCCESS,
    teamId: teamId,
    employ: employ,
}); };
export var updateEmployFailure = function () { return ({
    type: UPDATE_EMPLOY_FAILURE,
}); };
export var deleteEmployRequest = function () { return ({
    type: DELETE_EMPLOY_REQUEST,
}); };
export var deleteEmploySuccess = function (teamId, id) { return ({
    type: DELETE_EMPLOY_SUCCESS,
    teamId: teamId,
    id: id,
}); };
export var deleteEmployFailure = function () { return ({
    type: DELETE_EMPLOY_FAILURE,
}); };
export var fetchTeamEmploys = function (teamId) {
    return function (dispatch, getState) {
        dispatch(fetchTeamEmploysRequest());
        return api2(dispatch, getState, "/api/v2/teams/".concat(teamId, "/employs"), 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (employs) {
                    dispatch(fetchTeamEmploysSuccess(teamId, employs));
                    return employs;
                });
            }
            else {
                dispatch(fetchTeamEmploysFailure());
                return { error: true };
            }
        });
    };
};
export var createEmploy = function (teamId, params) {
    return function (dispatch, getState) {
        dispatch(createEmployRequest());
        return api(dispatch, getState, "teams/".concat(teamId, "/employs"), 'POST', params).then(function (employ) {
            if (employ.error) {
                dispatch(createEmployFailure());
            }
            else {
                dispatch(createEmploySuccess(teamId, employ));
            }
            return employ;
        });
    };
};
export var updateEmploy = function (teamId, id, params) {
    return function (dispatch, getState) {
        dispatch(updateEmployRequest());
        return api(dispatch, getState, "teams/".concat(teamId, "/employs/").concat(id), 'PATCH', params).then(function (employ) {
            if (employ.error) {
                dispatch(updateEmployFailure());
            }
            else {
                dispatch(updateEmploySuccess(teamId, employ));
            }
            return employ;
        });
    };
};
export var deleteEmploy = function (teamId, id) {
    return function (dispatch, getState) {
        dispatch(deleteEmployRequest());
        return api(dispatch, getState, "teams/".concat(teamId, "/employs/").concat(id), 'DELETE').then(function (response) {
            if (response.error) {
                dispatch(deleteEmployFailure());
            }
            else {
                dispatch(deleteEmploySuccess(teamId, id));
            }
            return response;
        });
    };
};
