import { __assign } from "tslib";
import variants from '@/constants/variants';
import { updateStateIfChanged } from '../helpers';
// Actions
export var SET_CURRENT_CATEGORY = 'timecrowd/my_task/SET_CURRENT_CATEGORY';
export var CHANGE_TASK = 'timecrowd/my_task/CHANGE_TASK';
export var REMOVE_TASK = 'timecrowd/my_task/REMOVE_TASK';
export var RESET_MY_TASK = 'timecrowd/my_task/RESET_MY_TASK';
export var SET_ACTIVE_TASK_FORM = 'timecrowd/my_task/SET_ACTIVE_TASK_FORM';
export var initialState = {
    activeTaskForm: -1,
    currentCategory: undefined,
    timeEntries: [],
    activities: [],
    loadingMoreActivities: false,
};
var isChromeExtension = function () { return variants.isChromeExtension; };
function saveCurrentCategory(currentCategory) {
    window.chrome.storage.local.set({ currentCategory: currentCategory });
}
export default function myTask(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_ACTIVE_TASK_FORM:
            return updateStateIfChanged(state, {
                activeTaskForm: action.activeTaskForm,
            });
        case CHANGE_TASK:
            return __assign(__assign({}, state), { activities: state.activities.map(function (activity) {
                    return __assign(__assign({}, activity), { tasks: activity.tasks.map(function (task) {
                            if (task.id === action.task.id) {
                                return action.task;
                            }
                            else {
                                return task;
                            }
                        }) });
                }) });
        case REMOVE_TASK:
            return __assign(__assign({}, state), { activities: state.activities.map(function (activity) {
                    return __assign(__assign({}, activity), { tasks: activity.tasks.filter(function (task) {
                            return task.id !== action.id;
                        }) });
                }) });
        case SET_CURRENT_CATEGORY:
            if (isChromeExtension()) {
                saveCurrentCategory(action.currentCategory);
            }
            return __assign(__assign({}, state), { currentCategory: action.currentCategory });
        case RESET_MY_TASK:
            return initialState;
        default:
            return state;
    }
}
export var setCurrentCategory = function (currentCategory) { return ({
    type: SET_CURRENT_CATEGORY,
    currentCategory: currentCategory,
}); };
export var changeTask = function (task) { return ({
    type: CHANGE_TASK,
    task: task,
}); };
export var removeTask = function (id) { return ({
    type: REMOVE_TASK,
    id: id,
}); };
export var resetMyTask = function () { return ({
    type: RESET_MY_TASK,
}); };
export var setActiveTaskForm = function (activeTaskForm) { return ({
    type: SET_ACTIVE_TASK_FORM,
    activeTaskForm: activeTaskForm,
}); };
