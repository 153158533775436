import { __awaiter, __generator } from "tslib";
import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
import { TimeEntrySuggestionButton } from '@/components/time_entry_suggestion_button';
import { TimeSelectionPopup } from '@/components/time_selection_popup';
import { isValidTimeFormat, isValidTimeRange } from '@/utils/time_helper';
import { zenToHanTime, addColon, removeNonTimeCharacters, } from '@/utils/time_helper';
import variants from '@/constants/variants';
export function TimeEntryInlineTimeInput(_a) {
    var _this = this;
    var id = _a.id, defaultDatetime = _a.datetime, updateTimeEntry = _a.updateTimeEntry, isEditing = _a.isEditing, setIsEditing = _a.setIsEditing, attribute = _a.attribute, prevTimeEntry = _a.prevTimeEntry, nextTimeEntry = _a.nextTimeEntry, suggestionString = _a.suggestionString, focusNextField = _a.focusNextField, isMoreThan24hour = _a.isMoreThan24hour, baseTime = _a.baseTime, _b = _a.isPC, isPC = _b === void 0 ? variants.isPC : _b, _c = _a.isChromeExtension, isChromeExtension = _c === void 0 ? variants.isChromeExtension : _c;
    var _d = React.useState(false), isInvalid = _d[0], setIsInvalid = _d[1];
    var inputRef = React.useRef(null);
    var format = 'HH:mm';
    var _e = React.useState(defaultDatetime), datetime = _e[0], setDatetime = _e[1];
    var defaultTimeString = defaultDatetime.format(format);
    var _f = React.useState(defaultTimeString), timeString = _f[0], setTimeString = _f[1];
    React.useEffect(function () {
        setTimeString(defaultTimeString);
    }, [defaultTimeString]);
    var handleUpdate = function (value) { return __awaiter(_this, void 0, void 0, function () {
        var _a, hours, minutes, updated, res;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = value.split(':').map(function (s) { return parseInt(s); }), hours = _a[0], minutes = _a[1];
                    updated = isMoreThan24hour
                        ? baseTime.clone()
                        : defaultDatetime.clone();
                    updated.set('hour', hours);
                    updated.set('minute', minutes);
                    updated.set('second', 0);
                    return [4 /*yield*/, updateTimeEntry(id, {
                            time_entry: (_b = {},
                                _b[attribute] = updated,
                                _b),
                        })];
                case 1:
                    res = _c.sent();
                    if (!res.error) {
                        setIsEditing(false);
                    }
                    return [2 /*return*/, res];
            }
        });
    }); };
    React.useEffect(function () {
        setDatetime(defaultDatetime);
    }, [defaultDatetime]);
    React.useEffect(function () {
        var _a;
        if (isEditing) {
            if (!defaultDatetime.isValid()) {
                setIsEditing(false);
                return;
            }
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
            setIsInvalid(false);
        }
    }, [defaultDatetime, isEditing, setIsEditing]);
    var handleSelectDateTime = function (datetime) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setTimeString(datetime.format(format));
                    return [4 /*yield*/, updateTimeEntry(id, {
                            time_entry: (_a = {},
                                _a[attribute] = datetime,
                                _a),
                        })];
                case 1:
                    res = _b.sent();
                    if (!res.error) {
                        setIsEditing(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (<div className="d-inline-flex flex-column w-28">
      <input ref={inputRef} className={"form-control text-center font-weight-bold ".concat(isEditing ? 'd-inline' : 'd-none', " ").concat(isInvalid ? 'border-danger shadow-none' : '')} type="text" value={timeString} onBlur={function () {
            setTimeString(defaultTimeString);
            setDatetime(defaultDatetime);
            setIsEditing(false);
        }} onChange={function (e) {
            var value = removeNonTimeCharacters(addColon(zenToHanTime(e.target.value).slice(0, 5)));
            setTimeString(value);
            var isValid = isValidTimeFormat(value) && isValidTimeRange(value);
            if (isValid) {
                setDatetime(moment("".concat(defaultDatetime.format('YYYY-MM-DD'), " ").concat(value)));
            }
        }} onFocus={function () { }} onKeyDown={function (e) { return __awaiter(_this, void 0, void 0, function () {
            var key, value, isValid, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        key = e.key;
                        if (!['Enter', 'Tab'].includes(key)) return [3 /*break*/, 2];
                        e.preventDefault();
                        value = e.target.value;
                        isValid = isValidTimeFormat(value) && isValidTimeRange(value);
                        setIsInvalid(!isValid);
                        if (!isValid) return [3 /*break*/, 2];
                        return [4 /*yield*/, handleUpdate(value)];
                    case 1:
                        res = _a.sent();
                        if (key === 'Tab' && !res.error) {
                            focusNextField === null || focusNextField === void 0 ? void 0 : focusNextField();
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); }}/>
      <div className={"form-control-static w-auto p-1 rounded text-center ".concat(!isEditing || !datetime.isValid() ? '' : 'd-none')} onClick={function () {
            setIsEditing(isPC || isChromeExtension);
        }}>
        {datetime.isValid() && datetime.format(format)}
        {!datetime.isValid() && I18n.t('shared.now')}
      </div>
      <TimeSelectionPopup className="mt-4" isShown={isEditing} selectedDateTime={datetime} onSelectDateTime={handleSelectDateTime}/>
      {(isPC || isChromeExtension) && (<TimeEntrySuggestionButton isInline datetime={datetime} nextTimeEntry={nextTimeEntry} prevTimeEntry={prevTimeEntry} onClick={function () {
                setTimeout(function () {
                    setTimeString(suggestionString);
                    handleUpdate(suggestionString);
                }, 0);
            }}/>)}
    </div>);
}
