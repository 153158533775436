import React from 'react';
import I18n from '@/constants/i18n';
import { CustomFieldsExpressionEditor } from './custom_fields_expression_editor';
import variants from '@/constants/variants';
import Helplink from '@/components/molecules/helplink';
export var CustomFieldsExpressionField = function (_a) {
    var customFieldsMaster = _a.customFieldsMaster, onChangeCustomFieldsMaster = _a.onChangeCustomFieldsMaster, customFieldsMasters = _a.customFieldsMasters, className = _a.className, _b = _a.openURL, openURL = _b === void 0 ? variants.openURL : _b;
    if (customFieldsMaster.field_type !== 'expression') {
        return null;
    }
    return (<div className={className}>
      {I18n.t('shared.customFieldsExpression')}
      <Helplink className="ml-1" href="https://help.timecrowd.net/customfield-calc" onClick={function (e) {
            e.preventDefault();
            openURL(e.currentTarget.href);
        }}/>
      <CustomFieldsExpressionEditor className="mt-2" customFieldsMasters={customFieldsMasters} expression={customFieldsMaster.expression_text} onChangeExpression={function (expression) {
            return onChangeCustomFieldsMaster('expression_text', expression);
        }}/>
    </div>);
};
