import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import variants from '@/constants/variants';
import Icon from '@/components/atoms/icon';
import I18n from '@/constants/i18n';
export var MemberRateHelp = function (_a) {
    var messageKey = _a.messageKey, _b = _a.openURL, openURL = _b === void 0 ? variants.openURL : _b;
    var targetRef = React.useRef(null);
    return (<>
      <span ref={targetRef} className="helplink">
        <Icon name="question"/>
      </span>
      <UncontrolledTooltip autohide={false} fade={false} innerClassName="max-w-lg" target={targetRef}>
        <p className="text-left leading-8 mt-1" dangerouslySetInnerHTML={{
            __html: I18n.t("shared.".concat(messageKey)),
        }}/>
        <p className="text-right mb-1">
          <a className="text-reset" href="https://support.timecrowd.net/1428ec2d2afa809c8742c586f8cc8b96" onClick={function (e) {
            e.preventDefault();
            openURL(e.currentTarget.href);
        }}>
            <u>{I18n.t('shared.clickHereForDetails')}</u>
          </a>
        </p>
      </UncontrolledTooltip>
    </>);
};
