import React from 'react';
import LoadingSpinner from '@/components/atoms/loading_spinner';
import I18n from '@/constants/i18n';
import CustomCheck from '@/components/atoms/custom_check';
import Helplink from '@/components/molecules/helplink';
export var IntegrationForm = function (_a) {
    var filter = _a.filter, isFetched = _a.isFetched, isLoading = _a.isLoading, onChangeActive = _a.onChangeActive, onClickHelplink = _a.onClickHelplink;
    if (!isLoading && !isFetched) {
        return null;
    }
    if (isLoading) {
        return (<div className="col-12">
        <LoadingSpinner position="absolute-center" size="md"/>
      </div>);
    }
    if (isFetched && !filter) {
        return (<div className="col-12">
        <p>{I18n.t('integration.notFoundIntegrations')}</p>
      </div>);
    }
    return (<div className="col-12">
      <p className="h3 mb-3">{I18n.t('shared.chatwork')}</p>
      <CustomCheck checked={filter.active} onChange={onChangeActive}>
        {I18n.t("integration.chatwork")}
        <Helplink href="https://support.timecrowd.net/ChatWork-1908ec2d2afa80beabb2c07e776a483d" onClick={onClickHelplink}/>
      </CustomCheck>
    </div>);
};
