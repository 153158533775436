import React from 'react';
import I18n from '@/constants/i18n';
export var PayjpCheckoutButton = function (props) {
    var publicKey = props.publicKey, onCreateToken = props.onCreateToken, dataText = props.dataText, dataSubmitText = props.dataSubmitText, email = props.email;
    var containerRef = React.useRef(null);
    React.useEffect(function () {
        // @ts-ignore
        window.reactPayjpCheckoutOnCreated = function (_a) {
            var id = _a.id;
            onCreateToken(id);
        };
        if (containerRef.current && !containerRef.current.innerHTML) {
            var script = document.createElement('script');
            script.setAttribute('src', 'https://checkout.pay.jp/');
            script.setAttribute('class', 'payjp-button');
            script.setAttribute('data-payjp-key', publicKey);
            // @ts-ignore
            script.setAttribute('data-payjp-partial', true);
            script.setAttribute('data-payjp-on-created', 'reactPayjpCheckoutOnCreated');
            script.setAttribute('data-payjp-lang', I18n.locale);
            if (dataText) {
                script.setAttribute('data-payjp-text', dataText);
            }
            if (dataSubmitText) {
                script.setAttribute('data-payjp-submit-text', dataSubmitText);
            }
            script.setAttribute('data-payjp-three-d-secure', 'true');
            script.setAttribute('data-payjp-three-d-secure-workflow', 'subwindow');
            script.setAttribute('data-payjp-extra-attribute-email', email);
            // @ts-ignore
            containerRef.current.appendChild(script);
        }
        return function () {
            // @ts-ignore
            window.reactPayjpCheckoutOnCreated = null;
            // @ts-ignore
            window.PayjpCheckout = null;
        };
    }, [publicKey, onCreateToken, dataText, dataSubmitText, email]);
    return <div ref={containerRef}/>;
};
