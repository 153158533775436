import { __assign } from "tslib";
import queryString from 'query-string';
import api2 from '@/api2';
export var FETCH_OVERWRAP_TIME_ENTRIES_REQUEST = 'timecrowd/overlap_time_entries/FETCH_OVERWRAP_TIME_ENTRIES_REQUEST';
export var FETCH_OVERWRAP_TIME_ENTRIES_SUCCESS = 'timecrowd/overlap_time_entries/FETCH_OVERWRAP_TIME_ENTRIES_SUCCESS';
export var FETCH_OVERWRAP_TIME_ENTRIES_FAILURE = 'timecrowd/overlap_time_entries/FETCH_OVERWRAP_TIME_ENTRIES_FAILURE';
export var RESET_OVERWRAP_TIME_ENTRIES = 'timecrowd/overlap_time_entries/RESET_OVERWRAP_TIME_ENTRIES';
export var initialState = {
    items: [],
    status: 'pending',
};
export default function overlapTimeEntries(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_OVERWRAP_TIME_ENTRIES_REQUEST:
            return __assign(__assign({}, initialState), { status: 'loading' });
        case FETCH_OVERWRAP_TIME_ENTRIES_SUCCESS:
            return __assign(__assign({}, state), { items: action.payload, status: 'loaded' });
        case FETCH_OVERWRAP_TIME_ENTRIES_FAILURE:
            return __assign(__assign({}, state), { status: 'pending' });
        case RESET_OVERWRAP_TIME_ENTRIES:
            return initialState;
        default:
            return state;
    }
}
export var fetchOverlapTimeEntriesRequest = function () { return ({
    type: FETCH_OVERWRAP_TIME_ENTRIES_REQUEST,
}); };
export var fetchOverlapTimeEntriesSuccess = function (timeEntries) { return ({
    type: FETCH_OVERWRAP_TIME_ENTRIES_SUCCESS,
    payload: timeEntries,
}); };
export var fetchOverlapTimeEntriesFailure = function () { return ({
    type: FETCH_OVERWRAP_TIME_ENTRIES_FAILURE,
}); };
export var resetOverlapTimeEntries = function () { return ({
    type: RESET_OVERWRAP_TIME_ENTRIES,
}); };
export var fetchOverlapTimeEntries = function (params) {
    var query = queryString.stringify(params);
    return function (dispatch, getState) {
        dispatch(fetchOverlapTimeEntriesRequest());
        return api2(dispatch, getState, "/api/v2/overlap_time_entries?".concat(query), 'GET').then(function (response) {
            if (response.ok) {
                return response.json().then(function (timeEntries) {
                    dispatch(fetchOverlapTimeEntriesSuccess(timeEntries));
                    return { timeEntries: timeEntries, error: false };
                });
            }
            else {
                dispatch(fetchOverlapTimeEntriesFailure());
                return { error: true };
            }
        });
    };
};
