import React, { Fragment } from 'react';
import I18n from '@/constants/i18n';
import queryString from 'query-string';
import Avatar from '@/components/atoms/avatar';
import CategoryCircleIcon from '@/components/atoms/category_circle_icon';
import ExpansionPanel from '@/components/atoms/expansion_panel';
import ExpansionPanelBody from '@/components/atoms/expansion_panel_body';
import Icon from '@/components/atoms/icon';
import ExpansionPanelHeader from '@/components/molecules/expansion_panel_header';
import ExportFormatModalContainer from '@/containers/export_format_modal_container';
import { ReportMemberActivities } from '@/components/organisms/report_member_activities';
import { TeamReportDetails } from './team_report_details';
import { TeamReportBarChart } from '@/components/report_chart/team_report_bar_chart';
import { TeamReportPieChart } from '@/components/report_chart/team_report_pie_chart';
import { TeamReportTasks } from './team_report_tasks';
import { calcCategoryAndTaskTotals, filterWorkedTasks, sortSelectedUsers, } from './helpers';
import { Chart, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
import { TeamReportMyReportNotice } from './team_report_myreport_notice';
import useCustomFields from '@/hooks/use_custom_fields';
import { formatDuration } from '@/utils/time_helper';
import { numberWithDelimiter } from '@/utils/number_helper';
window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
Chart.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
var TeamReport = function (props) {
    var teamReport = props.teamReport, apiBase = props.apiBase, isSelectedMyReport = props.isSelectedMyReport, myReportTeamIds = props.myReportTeamIds, myReportUserIds = props.myReportUserIds, isPC = props.isPC, openURL = props.openURL, category = props.category, selectedUsers = props.selectedUsers, reportableUsers = props.reportableUsers, onSelectUser = props.onSelectUser, withAllUsers = props.withAllUsers, isShowArchived = props.isShowArchived, isShowOnlyMyData = props.isShowOnlyMyData, team = props.team, setReportTasksSortOrder = props.setReportTasksSortOrder, setReportTasksSortTarget = props.setReportTasksSortTarget, timeEntries = props.timeEntries, _a = props.report, sortOrder = _a.sortOrder, sortBy = _a.sortBy, workedMemberIds = props.workedMemberIds;
    var _b = React.useState(false), openCSV = _b[0], setOpenCSV = _b[1];
    var teams = React.useMemo(function () { return (team ? [team] : []); }, [team]);
    useCustomFields(teams);
    var tasks = React.useMemo(function () { return filterWorkedTasks(teamReport.tasks, timeEntries); }, [teamReport.tasks, timeEntries]);
    var _c = React.useMemo(function () {
        return calcCategoryAndTaskTotals(timeEntries, tasks);
    }, [timeEntries, tasks]), taskTotals = _c.taskTotals, categoryTotals = _c.categoryTotals, teamTotals = _c.teamTotals, totalDuration = _c.totalDuration, totalAmount = _c.totalAmount;
    var onToggleCSV = function () {
        setOpenCSV(!openCSV);
    };
    var csvURL = function (dataType, timeFormat, charset) {
        var model = {
            time_entry: 'time_entries',
            task: 'tasks',
        }[dataType];
        var from = teamReport.from, to = teamReport.to, type = teamReport.type, teams = teamReport.teams;
        var query = queryString.stringify({
            from: from,
            to: to,
            type: type,
            'team_ids[]': isSelectedMyReport ? myReportTeamIds : [teams[0].id],
            'user_ids[]': isShowOnlyMyData
                ? myReportUserIds
                : selectedUsers.map(function (u) { return u.id; }),
            category_id: (category === null || category === void 0 ? void 0 : category.id) || '',
            with_archived: isShowArchived,
            with_all_users: withAllUsers,
            time_format: timeFormat,
            charset: charset,
        });
        return "".concat(apiBase, "/report/").concat(model, ".csv?").concat(query);
    };
    var isShowMemberActivities = !isShowOnlyMyData &&
        reportableUsers.length > 1 &&
        (withAllUsers || selectedUsers.length > 1);
    var sortedSelectedUsers = sortSelectedUsers(selectedUsers, workedMemberIds);
    return (<div className="report-container">
      <div className="d-flex" style={{ padding: '2.1rem 2.4rem 0rem 2.4rem' }}>
        <div>
          <WorkspaceAvatarContainer withName size="md" workspaceId={teamReport.teams[0].workspaces_workspace_id}/>
          <div className="d-flex">
            {!isSelectedMyReport && (<span className="mt-1">
                {category ? (<CategoryCircleIcon color={category.color} style={{ top: '-1px', marginRight: '1.8rem' }}/>) : (<Avatar className="mr-1" image={teamReport.teams[0].avatar_url} size="md"/>)}
              </span>)}
            <div>
              <p className="lead mb-0">
                {category && "".concat(category.title, " - ")}
                {isSelectedMyReport
            ? I18n.t('shared.myReport')
            : teamReport.teams[0].name}
              </p>
              <TeamReportMyReportNotice isSelectedMyReport={isSelectedMyReport} isShowOnlyMyData={isShowOnlyMyData} team={team}/>
              {category && category.archived && (<span className="badge badge-info mb-1">
                  <Icon className="mr-1" name="archive" size="sm"/>
                  {I18n.t('shared.archived')}
                </span>)}
              {!isShowOnlyMyData && (<p className="text-muted mb-0">
                  {selectedUsers.length === reportableUsers.length ||
                withAllUsers ? (<span className="mr-1 font-weight-bold">
                      {I18n.t('shared.allUsers')}
                    </span>) : (<>
                      <span className="mr-1 font-weight-bold">
                        {I18n.t('shared.nPeople', {
                    num: sortedSelectedUsers.length,
                })}
                      </span>
                      <span>
                        {sortedSelectedUsers
                    .map(function (_a) {
                    var nickname = _a.nickname, member = _a.member;
                    return "".concat(nickname).concat(member ? '' : " (".concat(I18n.t('shared.leaved'), ")"));
                })
                    .join(', ')}
                      </span>
                    </>)}
                </p>)}
              <p className="text-muted">
                {teamReport.label && (<span className="mr-1 font-weight-bold">
                    {teamReport.label}
                  </span>)}
                <span>
                  {teamReport.from} {I18n.t('shared.between')} {teamReport.to}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="ml-auto">
          {isPC && (<Fragment>
              <button className="btn btn-secondary" onClick={onToggleCSV}>
                <Icon className="mr-1" name="download"/>
                {I18n.t('report.download')}
              </button>
              <ExportFormatModalContainer actionLabel={I18n.t('report.download')} isOpen={openCSV} titleLabel={I18n.t('report.download')} toggle={onToggleCSV} onSubmit={function (dataType, timeFormat, charset) {
                openURL(csvURL(dataType, timeFormat, charset));
                onToggleCSV();
            }}/>
            </Fragment>)}
        </div>
      </div>
      <ExpansionPanel expand>
        {function (expand, toggle) { return (<>
            <ExpansionPanelHeader className="pb-0 p-[0.8rem]" expand={expand} toggle={toggle}>
              <label className="expansion-panel-label">
                {I18n.t('shared.summary')}
              </label>
            </ExpansionPanelHeader>
            <ExpansionPanelBody className="px-3" expand={expand}>
              <div className="mb-3" style={{ fontSize: '16px' }}>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <Icon className="text-muted mr-1" name="clock"/>
                    {formatDuration(totalDuration)}
                    <span className="ml-2 mr-1 text-muted">¥</span>
                    {numberWithDelimiter(totalAmount)}
                  </div>
                  <div className="col-12 col-sm-6">
                    {!category && (<TeamReportPieChart categories={teamReport.categories} categoryTotals={categoryTotals} teams={teamReport.teams} teamTotals={teamTotals}/>)}
                  </div>
                </div>
              </div>
            </ExpansionPanelBody>
          </>); }}
      </ExpansionPanel>
      <TeamReportBarChart categories={teamReport.categories} from={teamReport.from} isSelectedMyReport={isSelectedMyReport} tasks={teamReport.tasks} teams={teamReport.teams} timeEntries={timeEntries} to={teamReport.to}/>
      <TeamReportDetails categories={teamReport.categories} categoryTotals={categoryTotals} showsTooltip={isPC} tasks={tasks} taskTotals={taskTotals} teams={teamReport.teams} teamTotals={teamTotals} timeEntries={timeEntries} users={reportableUsers}/>
      <hr className="my-4"/>
      {isShowMemberActivities && (<>
          <ReportMemberActivities categories={teamReport.categories} defaultVisibleRows={5} showsTooltip={isPC && !teamReport.category_id} tasks={tasks} timeEntries={timeEntries} users={reportableUsers} onSelectUser={onSelectUser}/>
          <hr className="my-4"/>
        </>)}
      <TeamReportTasks categories={teamReport.categories} currentSortBy={sortBy} currentSortOrder={sortOrder} setOrder={setReportTasksSortOrder} setTarget={setReportTasksSortTarget} tasks={tasks} taskTotals={taskTotals}/>
    </div>);
};
export default TeamReport;
