import React from 'react';
import { categoryWithAncestors } from '@/utils/category_helper';
import { CategoryInfoContainer } from '@/containers/category_info_container';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
export var RecentCategories = function (_a) {
    var label = _a.label, setCategory = _a.setCategory, recentCategories = _a.recentCategories;
    return (<div className="list-group list-group-flush pb-2">
    <div className="mt-2 mb-1 ml-2 text-muted font-weight-bold small">
      {label}
    </div>
    {recentCategories.map(function (recentCategory) {
            return (<div key={"recent-categories-".concat(recentCategory.team_id, "-").concat(recentCategory.id)} className="list-group-item list-group-item-action text-break whitespace-normal ml-n1" onClick={function () {
                    setCategory(recentCategory);
                }}>
          <WorkspaceAvatarContainer className="mr-1 grayscale" workspaceId={recentCategory.team.workspaces_workspace_id}/>
          <i className={[
                    'category-circle',
                    'category-' + recentCategory.color,
                    'ml-0',
                ].join(' ')}/>
          {categoryWithAncestors(recentCategory)} - {recentCategory.team.name}
          <CategoryInfoContainer requiresCustomFields category={recentCategory} className="mx-1"/>
        </div>);
        })}
  </div>);
};
