import React from 'react';
import classNames from 'classnames';
import Icon from '@/components/atoms/icon';
var ExpansionPanelHeader = function (props) {
    var _a = props.disabled, disabled = _a === void 0 ? false : _a, expand = props.expand, className = props.className, children = props.children, _b = props.tag, Tag = _b === void 0 ? 'div' : _b, _c = props.action, action = _c === void 0 ? false : _c, onClick = props.onClick, _d = props.style, style = _d === void 0 ? {} : _d;
    var toggle = function () {
        props.toggle();
    };
    var handleClickHeader = function (e) {
        e.preventDefault();
        if (typeof onClick === 'function') {
            onClick();
        }
        else {
            if (disabled) {
                return false;
            }
            toggle();
        }
    };
    var handleClickToggle = function (e) {
        if (disabled) {
            return false;
        }
        e.stopPropagation();
        e.preventDefault();
        toggle();
    };
    var classes = classNames('expansion-panel-header', className, {
        'expansion-panel-header-action': action,
        expand: expand,
        shrink: !expand,
    });
    var iconName = expand ? 'chevron-up' : 'chevron-down';
    return (<Tag className={classes} style={style} onClick={handleClickHeader}>
      <div className="expansion-panel-header-body">{children}</div>
      <button className="btn-toggler btn-sm ml-auto" type="button" onClick={handleClickToggle}>
        <Icon name={iconName} size="md"/>
      </button>
    </Tag>);
};
export default ExpansionPanelHeader;
