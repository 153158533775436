import variants from '@/constants/variants';
export var buildTeamMenuItems = function (team, user, isPC) {
    if (isPC === void 0) { isPC = variants.isPC; }
    var items = [
        {
            name: 'members',
            icon: 'user',
            path: "/teams/".concat(team.id, "/members"),
            label: 'teamManagement.member',
        },
        {
            name: 'categories',
            icon: 'tags',
            path: "/teams/".concat(team.id, "/categories"),
            label: 'teamManagement.category',
        },
    ];
    if (team.can_manage) {
        items.push({
            name: 'team',
            icon: 'box',
            path: "/teams/".concat(team.id, "/edit"),
            label: 'shared.generalSettings',
        });
        if (!user.has_password) {
            items.push({
                name: 'integrations',
                icon: 'network-3',
                path: "/teams/".concat(team.id, "/integrations"),
                label: 'teamManagement.integration',
            });
        }
        if (isPC && !team.personal) {
            items.push({
                name: 'category_task_structures',
                icon: 'upload',
                path: "/teams/".concat(team.id, "/category_task_structures"),
                label: 'shared.bulkCreateCategoriesAndTasks',
                isBeta: true,
            });
        }
        items.push.apply(items, [
            {
                name: 'reminder_emails',
                icon: 'bell',
                path: "/teams/".concat(team.id, "/reminder_emails"),
                label: 'shared.reminderEmails',
            },
            {
                name: 'settings',
                icon: 'gear',
                path: "/teams/".concat(team.id, "/settings"),
                label: 'teamManagement.addvancedSettings',
            },
        ]);
        if (team.hierarchized && team.ancestry === null) {
            items.push({
                name: 'descendants',
                icon: 'network-1',
                path: "/teams/".concat(team.id, "/children"),
                label: 'shared.manageChildTeams',
            });
        }
        items.push({
            name: 'custom_fields',
            icon: 'pencil',
            path: "/teams/".concat(team.id, "/custom_fields"),
            label: 'shared.customFields',
        });
        if (user.enabled_features.includes('team_calendar_integration')) {
            items.push({
                name: 'team_calendar_integration',
                icon: 'network-3',
                path: "/teams/".concat(team.id, "/calendar_integration"),
                label: 'shared.calendarIntegrations',
            });
        }
    }
    return items;
};
