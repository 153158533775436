import { __awaiter, __generator } from "tslib";
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import I18n from '@/constants/i18n';
import { removeDangerNotificationMessage } from '@/modules/notification';
import ModalHeaderMod from '@/components/molecules/modal_header_mod';
export var DeleteModal = function (_a) {
    var onDelete = _a.onDelete;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = React.useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var dispatch = useDispatch();
    var toggle = React.useCallback(function () {
        dispatch(removeDangerNotificationMessage());
        setIsOpen(!isOpen);
    }, [dispatch, isOpen]);
    var onClickDelete = React.useCallback(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, onDelete()];
                    case 1:
                        _a.sent();
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [onDelete]);
    return (<div>
      <button className="btn btn-link text-danger pr-0 pl-0" onClick={toggle}>
        {I18n.t('shared.cancelIntegration')}
      </button>

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeaderMod toggle={toggle}>
          {I18n.t('shared.disconnectTitle')}
        </ModalHeaderMod>

        <ModalBody>
          <p>{I18n.t('shared.disconnectNote')}</p>
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-danger" disabled={isLoading} onClick={onClickDelete}>
            {I18n.t('shared.disconnect')}
          </button>
          <button className="btn btn-secondary" disabled={isLoading} onClick={toggle}>
            {I18n.t('shared.cancel')}
          </button>
        </ModalFooter>
      </Modal>
    </div>);
};
