import { __assign } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n from '@/constants/i18n';
import { workspaceTeams as filterWorkspaceTeams } from '@/utils/workspace_helper';
import { fetchCategories } from '@/actions/category';
import { fetchWorkspacesCalendarIntegrationSetting, updateWorkspacesCalendarIntegrationSetting, } from '@/modules/workspaces_calendar_integration_settings';
import { createWorkspacesCalendarIntegrationFilter, deleteWorkspacesCalendarIntegrationFilter, fetchWorkspacesCalendarIntegrationFilters, updateWorkspacesCalendarIntegrationFilter, updateWorkspacesCalendarIntegrationFilterPriorityUp, updateWorkspacesCalendarIntegrationFilterPriorityDown, } from '@/modules/workspaces_calendar_integration_filters';
import { ignoreArchivedCategories, ignoreReadonlyTeamCategories, } from '@/modules/categories';
import { setNotificationMessage, removeDangerNotificationMessage, } from '@/modules/notification';
import { WorkspacesCalendarIntegrationSetting } from '@/components/workspaces_calendar_integration_setting';
import Placeholder from '@/components/pages/placeholder';
var WorkspacesCalendarIntegrationSettingContainer = function () {
    var openURL = useSelector(function (state) { return state.openURL; });
    var categories = useSelector(function (state) { return state.categories; });
    var currentUser = useSelector(function (state) { return state.currentUser.item; });
    var filteredCategories = ignoreArchivedCategories(ignoreReadonlyTeamCategories(categories));
    var filters = useSelector(function (state) { return state.workspacesCalendarIntegrationFilters; });
    var settings = useSelector(function (state) { return state.workspacesCalendarIntegrationSettings; });
    var teams = useSelector(function (state) { return state.teams; });
    var workspaceTeams = React.useMemo(function () {
        return filterWorkspaceTeams(teams.items);
    }, [teams.items]);
    var handleClickLink = React.useCallback(function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    }, [openURL]);
    var dispatch = useDispatch();
    var handleCloseModal = React.useCallback(function () {
        dispatch(removeDangerNotificationMessage());
    }, [dispatch]);
    var handleCreateFilter = React.useCallback(function (params) {
        var newParams = __assign(__assign({}, params), { priority: filters.items.length + 1 });
        return dispatch(createWorkspacesCalendarIntegrationFilter(newParams)).then(function (_a) {
            var error = _a.error;
            if (error) {
                return { error: error };
            }
            dispatch(setNotificationMessage('success', I18n.t('shared.createCalendarIntegrationFilterSuccess')));
            return { error: error };
        });
    }, [dispatch, filters.items]);
    var handleSubmitSetting = React.useCallback(function (params) {
        return dispatch(updateWorkspacesCalendarIntegrationSetting(params)).then(function (_a) {
            var error = _a.error;
            if (error) {
                return { error: error };
            }
            dispatch(setNotificationMessage('success', I18n.t('shared.updateCalendarIntegrationSettingSuccess')));
            return { error: error };
        });
    }, [dispatch]);
    var handleDeleteFilter = React.useCallback(function (id) {
        return dispatch(deleteWorkspacesCalendarIntegrationFilter(id)).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            dispatch(setNotificationMessage('success', I18n.t('shared.deleteCalendarIntegrationFilterSuccess')));
            return { error: error };
        });
    }, [dispatch]);
    var handleUpdateFilter = React.useCallback(function (id, params) {
        return dispatch(updateWorkspacesCalendarIntegrationFilter(id, params)).then(function (_a) {
            var error = _a.error;
            if (error) {
                return { error: error };
            }
            dispatch(setNotificationMessage('success', I18n.t('shared.updateCalendarIntegrationFilterSuccess')));
            return { error: error };
        });
    }, [dispatch]);
    var handleUpdateFilterPriorityUp = React.useCallback(function (id, params) {
        return dispatch(updateWorkspacesCalendarIntegrationFilterPriorityUp(id, params)).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            dispatch(setNotificationMessage('success', I18n.t('shared.updateCalendarIntegrationFilterSuccess')));
            return { error: error };
        });
    }, [dispatch]);
    var handleUpdateFilterPriorityDown = React.useCallback(function (id, params) {
        return dispatch(updateWorkspacesCalendarIntegrationFilterPriorityDown(id, params)).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            dispatch(setNotificationMessage('success', I18n.t('shared.updateCalendarIntegrationFilterSuccess')));
            return { error: error };
        });
    }, [dispatch]);
    React.useEffect(function () {
        dispatch(fetchWorkspacesCalendarIntegrationSetting());
    }, [dispatch]);
    React.useEffect(function () {
        if (settings.status !== 'loaded') {
            return;
        }
        if (!settings.item.can_update) {
            return;
        }
        dispatch(fetchCategories());
        dispatch(fetchWorkspacesCalendarIntegrationFilters());
    }, [dispatch, settings.status, settings.item]);
    if (settings.status !== 'loaded') {
        return <Placeholder />;
    }
    return (<WorkspacesCalendarIntegrationSetting categories={filteredCategories} currentUser={currentUser} filters={filters.items} setting={settings.item} teams={workspaceTeams} onClickLink={handleClickLink} onCloseModal={handleCloseModal} onCreateFilter={handleCreateFilter} onDeleteFilter={handleDeleteFilter} onDownFilterPriority={handleUpdateFilterPriorityDown} onSubmitSetting={handleSubmitSetting} onUpdateFilter={handleUpdateFilter} onUpFilterPriority={handleUpdateFilterPriorityUp}/>);
};
export default WorkspacesCalendarIntegrationSettingContainer;
