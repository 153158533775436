import { __assign, __awaiter, __generator } from "tslib";
import api from '../api';
import { setMemberSummaries } from '@/actions/setter';
import { setReportTeams } from '@/modules/report';
import { workspaceTeams } from '@/utils/workspace_helper';
export var fetchMemberSummaries = function () {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var teams, summarizedTeams, _i, _a, team, summaries, total_time, hours, minutes, amount;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    teams = getState().teams;
                    summarizedTeams = [];
                    _i = 0, _a = workspaceTeams(teams.items);
                    _b.label = 1;
                case 1:
                    if (!(_i < _a.length)) return [3 /*break*/, 4];
                    team = _a[_i];
                    return [4 /*yield*/, fetchTeamMemberSummaries(dispatch, getState, team)];
                case 2:
                    summaries = _b.sent();
                    total_time = summaries.reduce(function (sum, summary) {
                        return sum + summary.total_time;
                    }, 0);
                    hours = Math.floor(total_time / 3600);
                    minutes = Math.floor((total_time - hours * 3600) / 60);
                    amount = summaries.reduce(function (sum, summary) {
                        return sum + summary.total_amount;
                    }, 0);
                    summarizedTeams.push(__assign(__assign({}, team), { hours: hours, minutes: minutes, amount: amount, summaries: summaries }));
                    _b.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4:
                    dispatch(setMemberSummaries(summarizedTeams));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var fetchReportTeams = function () {
    return function (dispatch, getState) {
        return api(dispatch, getState, 'user/reportable_teams?without_categories=true', 'GET').then(function (teams) {
            if (!teams.error) {
                dispatch(setReportTeams(teams));
            }
        });
    };
};
var fetchTeamMemberSummaries = function (dispatch, getState, team) { return __awaiter(void 0, void 0, void 0, function () {
    var summaries;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, api(dispatch, getState, "teams/".concat(team.id, "/member_summaries"), 'GET')];
            case 1:
                summaries = _a.sent();
                return [2 /*return*/, Array.isArray(summaries) ? summaries : []];
        }
    });
}); };
