import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import { useDispatch } from 'react-redux';
import I18n from '@/constants/i18n';
import DeleteTimeEntryModal from '@/components/organisms/delete_time_entry_modal';
import variants from '@/constants/variants';
import { removeDangerNotificationMessage } from '@/modules/notification';
export var TaskFormDeleteTimeEntryButton = function (_a) {
    var mode = _a.mode, readOnly = _a.readOnly, archived = _a.archived, disabled = _a.disabled, timeEntry = _a.timeEntry, onDestroyTimeEntry = _a.onDestroyTimeEntry, _b = _a.openURL, openURL = _b === void 0 ? variants.openURL : _b;
    var _c = React.useState(false), isOpenDeleteTimeEntryModal = _c[0], setIsOpenDeleteTimeEntryModal = _c[1];
    var tooltipRef = React.useRef(null);
    var dispatch = useDispatch();
    if (mode !== 'update') {
        return null;
    }
    var handleClickDestroyTimeEntry = function () {
        onDestroyTimeEntry === null || onDestroyTimeEntry === void 0 ? void 0 : onDestroyTimeEntry(timeEntry.id);
    };
    var toggleDeleteTimeEntryModal = function () {
        dispatch(removeDangerNotificationMessage());
        setIsOpenDeleteTimeEntryModal(!isOpenDeleteTimeEntryModal);
    };
    return (<>
      {onDestroyTimeEntry && !readOnly && (<button className={"p-0 ".concat(archived ? 'text-muted' : 'text-danger', " btn btn-link")} disabled={disabled || archived} type="button" onClick={toggleDeleteTimeEntryModal}>
          <span ref={tooltipRef}>{I18n.t('shared.delete')}</span>
        </button>)}
      {archived && tooltipRef.current !== null && (<UncontrolledTooltip fade={false} target={tooltipRef.current}>
          {I18n.t('shared.archivedTaskCannotBeEdited')}
        </UncontrolledTooltip>)}
      <DeleteTimeEntryModal isOpen={isOpenDeleteTimeEntryModal} openURL={openURL} startedAt={moment(timeEntry.started_at)} stoppedAt={moment(timeEntry.stopped_at)} toggle={toggleDeleteTimeEntryModal} user={timeEntry.user} onDelete={handleClickDestroyTimeEntry}/>
    </>);
};
