import { __assign, __awaiter, __generator } from "tslib";
import queryString from 'query-string';
import api2 from '@/api2';
import { setNotificationMessage } from '@/modules/notification';
import I18n from '@/constants/i18n';
export var FETCH_CALENDAR_INTEGRATION_EVENTS_REQUEST = 'timecrowd/CALENDAR_INTEGRATION_EVENTS/FETCH_CALENDAR_INTEGRATION_EVENTS_REQUEST';
export var FETCH_CALENDAR_INTEGRATION_EVENTS_SUCCESS = 'timecrowd/CALENDAR_INTEGRATION_EVENTS/FETCH_CALENDAR_INTEGRATION_EVENTS_SUCCESS';
export var FETCH_CALENDAR_INTEGRATION_EVENTS_FAILURE = 'timecrowd/CALENDAR_INTEGRATION_EVENTS/FETCH_CALENDAR_INTEGRATION_EVENTS_FAILURE';
var initialState = {
    isLoading: false,
    items: [],
    isIntegrated: false,
};
export default function calendarIntegrationEvents(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_CALENDAR_INTEGRATION_EVENTS_REQUEST:
            return __assign(__assign({}, state), { isLoading: true });
        case FETCH_CALENDAR_INTEGRATION_EVENTS_SUCCESS:
            return __assign(__assign(__assign({}, state), action.payload), { isLoading: false });
        case FETCH_CALENDAR_INTEGRATION_EVENTS_FAILURE:
            return __assign(__assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
export var fetchCalendarIntegrationEventsRequest = function () { return ({
    type: FETCH_CALENDAR_INTEGRATION_EVENTS_REQUEST,
}); };
export var fetchCalendarIntegrationEventsSuccess = function (_a) {
    var isIntegrated = _a.is_integrated, items = _a.items;
    return ({
        type: FETCH_CALENDAR_INTEGRATION_EVENTS_SUCCESS,
        payload: {
            items: items,
            isIntegrated: isIntegrated,
        },
    });
};
export var fetchCalendarIntegrationEventsFailure = function () { return ({
    type: FETCH_CALENDAR_INTEGRATION_EVENTS_FAILURE,
}); };
export var fetchCalendarIntegrationEvents = function (params) {
    return function (dispatch, getState) {
        var view = getState().calendar.view;
        var query = queryString.stringify(__assign({ view: view }, params));
        dispatch(fetchCalendarIntegrationEventsRequest());
        return api2(dispatch, getState, "/api/v2/calendar_integration/events?".concat(query), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        json = _a.sent();
                        dispatch(fetchCalendarIntegrationEventsSuccess(json));
                        if (json.is_integrated && !json.is_first_fetched) {
                            dispatch(setNotificationMessage('warning', I18n.t('shared.firstFetchIsNotFinished')));
                        }
                        return [2 /*return*/, { error: false }];
                    case 2:
                        dispatch(fetchCalendarIntegrationEventsFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
