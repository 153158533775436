import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
import Avatar from '@/components/atoms/avatar';
import Icon from '@/components/atoms/icon';
import MobileHeader from '@/components/atoms/mobile_header';
import MobilePage from '@/components/molecules/mobile_page';
import Timespan from '@/components/timespan';
import { BREAKPOINT_MD } from '../../constants/styles';
import MediaQuery from 'react-responsive';
import { isValidTimeFormat } from '@/utils/time_helper';
import throttle from 'lodash/throttle';
import { findTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
function newTimeEntry(duration) {
    var dateTimeString = moment().format('YYYY-MM-DD HH:mm');
    var startedAt = moment(dateTimeString);
    var stoppedAt = moment(moment(dateTimeString).add(duration, 's').clone().format('YYYY-MM-DD HH:mm'));
    return {
        startedAt: startedAt,
        stoppedAt: stoppedAt,
    };
}
var NewTimeEntryForm = function (props) {
    var timeEntrySuggestions = props.timeEntrySuggestions, fetchTimeEntrySuggestion = props.fetchTimeEntrySuggestion, task = props.task, query = props.query, onCreate = props.onCreate, onFetchTask = props.onFetchTask, onCancel = props.onCancel, user = props.user;
    var defaultTimeEntry = newTimeEntry(props.task.default_duration);
    var _a = React.useState(defaultTimeEntry.startedAt), startedAt = _a[0], setStartedAt = _a[1];
    var _b = React.useState(defaultTimeEntry.stoppedAt), stoppedAt = _b[0], setStoppedAt = _b[1];
    var _c = React.useState(''), comment = _c[0], setComment = _c[1];
    var _d = React.useState(true), isValidTimeEntry = _d[0], setIsValidTimeEntry = _d[1];
    var handleFetchTimeEntrySuggestion = React.useCallback(function () {
        var date = startedAt.toDate();
        fetchTimeEntrySuggestion(date);
    }, [fetchTimeEntrySuggestion, startedAt]);
    React.useEffect(function () {
        handleFetchTimeEntrySuggestion();
    }, [handleFetchTimeEntrySuggestion]);
    var onChangeComment = function (e) {
        setComment(e.target.value);
    };
    var onClickSave = throttle(function () {
        var params = {
            time_entry: {
                started_at: startedAt,
                stopped_at: stoppedAt,
                time_trackable_id: task.id,
                input_type: 'manual',
            },
            comment: {
                content: comment,
            },
        };
        onCreate(params).then(function (timeEntry) {
            if (!timeEntry.error) {
                onFetchTask(task.id, query);
                onCancel();
            }
        });
    }, 1000, { trailing: false });
    var onChangeTimespan = function (start, stop) {
        var _a = start._i.split(' '), startTimeString = _a[1];
        var _b = stop._i.split(' '), stopTimeString = _b[1];
        if (start.isValid() &&
            stop.isValid() &&
            isValidTimeFormat(startTimeString) &&
            isValidTimeFormat(stopTimeString)) {
            setStartedAt(start);
            setStoppedAt(stop);
            setIsValidTimeEntry(start.isBefore(stop));
        }
        else {
            setIsValidTimeEntry(false);
        }
    };
    var timeEntrySuggestion = findTimeEntrySuggestion(timeEntrySuggestions, startedAt.toDate());
    return (<div>
      <MediaQuery minWidth={BREAKPOINT_MD}>
        <div className="inline-edit">
          <div className="inline-edit-body">
            <div className="d-flex align-items-center mb-2">
              <Avatar className="mr-1" image={user.avatar_url} size="sm"/>
              <span>{user.nickname}</span>
            </div>
            <div className="mb-2">
              <Timespan autoFocus nextTimeEntry={timeEntrySuggestion === null || timeEntrySuggestion === void 0 ? void 0 : timeEntrySuggestion.nextTimeEntry} prevTimeEntry={timeEntrySuggestion === null || timeEntrySuggestion === void 0 ? void 0 : timeEntrySuggestion.prevTimeEntry} start={startedAt} stop={stoppedAt} onChange={onChangeTimespan}/>
            </div>
            <textarea className="form-control col-lg-8" placeholder={I18n.t('shared.enterComments')} value={comment} onChange={onChangeComment}/>
          </div>
          <div className="inline-edit-footer">
            <div className="ml-auto">
              <button className="btn btn-secondary btn-md" onClick={onCancel}>
                {I18n.t('shared.cancel')}
              </button>
              <button className="btn-major-action btn btn-primary btn-md" disabled={!isValidTimeEntry} onClick={onClickSave}>
                {I18n.t('shared.add')}
              </button>
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={BREAKPOINT_MD}>
        <MobilePage>
          <MobileHeader>
            <button className="btn-mobile-header-action btn-mobile-header-action-left btn btn-link" onClick={onCancel}>
              <Icon name="chevron-left"/>
            </button>
            <span className="mobile-header-title">
              {I18n.t('taskEdit.addTimeEntry')}
            </span>
            <button className="btn-mobile-header-action btn-mobile-header-action-text btn-mobile-header-action-primary btn btn-link" disabled={!isValidTimeEntry} onClick={onClickSave}>
              {I18n.t('shared.add')}
            </button>
          </MobileHeader>
          <div className="inline-edit-body">
            <div className="d-flex align-items-center mb-2">
              <Avatar className="mr-1" image={user.avatar_url} size="sm"/>
              <span>{user.nickname}</span>
            </div>
            <div className="mb-2">
              <Timespan autoFocus nextTimeEntry={timeEntrySuggestion === null || timeEntrySuggestion === void 0 ? void 0 : timeEntrySuggestion.nextTimeEntry} prevTimeEntry={timeEntrySuggestion === null || timeEntrySuggestion === void 0 ? void 0 : timeEntrySuggestion.prevTimeEntry} start={startedAt} stop={stoppedAt} onChange={onChangeTimespan}/>
            </div>
            <textarea className="form-control col-lg-8" placeholder={I18n.t('shared.enterComments')} value={comment} onChange={onChangeComment}/>
          </div>
        </MobilePage>
      </MediaQuery>
    </div>);
};
export default NewTimeEntryForm;
