import { __assign } from "tslib";
import { connect } from 'react-redux';
import UserEmployList from '@/components/members/user_employ_list';
import { createEmploy, updateEmploy, deleteEmploy, } from '../../modules/employs';
var mapStateToProps = function (state, ownProps) {
    var _a;
    return (__assign(__assign({}, ownProps), { employs: ((_a = state.employs.itemsByTeamId[ownProps.team.id]) !== null && _a !== void 0 ? _a : []).filter(function (employ) { return employ.user_id === ownProps.membership.user.id; }), isLoading: state.employs.isLoading, user: state.user, workspacesEmploys: state.workspacesEmploys.items.filter(function (_a) {
            var user_id = _a.user_id;
            return user_id === ownProps.membership.user.id;
        }), currentWorkspace: state.workspaces.current }));
};
var mapDispatchToState = function (dispatch) { return ({
    onCreate: function (employ, params) {
        return dispatch(createEmploy(employ.team_id, params));
    },
    onUpdate: function (employ, params) {
        return dispatch(updateEmploy(employ.team_id, employ.id, params));
    },
    onDelete: function (employ) {
        return dispatch(deleteEmploy(employ.team_id, employ.id));
    },
}); };
export default connect(mapStateToProps, mapDispatchToState)(UserEmployList);
