import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import api2 from '@/api2';
export var FETCH_TIME_ENTRY_SUGGESTION_REQUEST = 'timecrowd/TIME_ENTRY_SUGGESTIONS/FETCH_TIME_ENTRY_SUGGESTION_REQUEST';
export var FETCH_TIME_ENTRY_SUGGESTION_SUCCESS = 'timecrowd/TIME_ENTRY_SUGGESTIONS/FETCH_TIME_ENTRY_SUGGESTION_SUCCESS';
export var FETCH_TIME_ENTRY_SUGGESTION_FAILURE = 'timecrowd/TIME_ENTRY_SUGGESTIONS/FETCH_TIME_ENTRY_SUGGESTION_FAILURE';
export var CLEAR_TIME_ENTRY_SUGGESTION = 'timecrowd/TIME_ENTRY_SUGGESTIONS/CLEAR_TIME_ENTRY_SUGGESTION';
export var RESET_TIME_ENTRY_SUGGESTION = 'timecrowd/TIME_ENTRY_SUGGESTIONS/RESET_TIME_ENTRY_SUGGESTION';
export var initialState = {
    items: [],
    status: 'pending',
};
export default function timeEntrySuggestion(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_TIME_ENTRY_SUGGESTION_REQUEST:
            return __assign(__assign({}, state), { status: 'loading' });
        case FETCH_TIME_ENTRY_SUGGESTION_SUCCESS:
            return __assign(__assign({}, state), { items: __spreadArray(__spreadArray([], state.items.filter(function (item) {
                    return item.time.getTime() !== action.item.time.getTime();
                }), true), [
                    action.item,
                ], false), status: 'loaded' });
        case FETCH_TIME_ENTRY_SUGGESTION_FAILURE:
            return __assign(__assign({}, state), { status: 'pending' });
        case CLEAR_TIME_ENTRY_SUGGESTION:
            return __assign(__assign({}, state), { items: state.items.filter(function (item) {
                    var _a, _b;
                    return (((_a = item.prevTimeEntry) === null || _a === void 0 ? void 0 : _a.id) !== action.id &&
                        ((_b = item.nextTimeEntry) === null || _b === void 0 ? void 0 : _b.id) !== action.id);
                }) });
        case RESET_TIME_ENTRY_SUGGESTION:
            return initialState;
        default:
            return state;
    }
}
export var fetchTimeEntrySuggestionRequest = function () { return ({
    type: FETCH_TIME_ENTRY_SUGGESTION_REQUEST,
}); };
export var fetchTimeEntrySuggestionSuccess = function (item) { return ({
    type: FETCH_TIME_ENTRY_SUGGESTION_SUCCESS,
    item: item,
}); };
export var fetchTimeEntrySuggestionFailure = function () { return ({
    type: FETCH_TIME_ENTRY_SUGGESTION_FAILURE,
}); };
export var fetchTimeEntrySuggestion = function (time, ignoreId) {
    if (ignoreId === void 0) { ignoreId = ''; }
    return function (dispatch, getState) {
        dispatch(fetchTimeEntrySuggestionRequest());
        return api2(dispatch, getState, "/api/v2/time_entry_suggestion?time=".concat(time.toISOString(), "&ignore_id=").concat(ignoreId), 'GET').then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(response.status === 200)) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        item = _a.sent();
                        dispatch(fetchTimeEntrySuggestionSuccess({
                            time: time,
                            prevTimeEntry: item.prev_time_entry,
                            nextTimeEntry: item.next_time_entry,
                        }));
                        return [2 /*return*/, { error: false, item: item }];
                    case 2:
                        dispatch(fetchTimeEntrySuggestionFailure());
                        return [2 /*return*/, { error: true }];
                }
            });
        }); });
    };
};
export var findTimeEntrySuggestion = function (suggestions, time) {
    if (!suggestions || suggestions.length < 1 || !time) {
        return;
    }
    return suggestions.find(function (suggestion) {
        return suggestion.time.getTime() === time.getTime();
    });
};
export var clearTimeEntrySuggestion = function (id) { return ({
    type: CLEAR_TIME_ENTRY_SUGGESTION,
    id: id,
}); };
export var resetTimeEntrySuggestion = function () { return ({
    type: RESET_TIME_ENTRY_SUGGESTION,
}); };
