import React from 'react';
import { useSelector } from 'react-redux';
import { workspaceTeams } from '@/utils/workspace_helper';
import { CategorySelector } from '@/components/molecules/category_selector';
var CategorySelectorContainer = function (_a) {
    var category = _a.category, isOpened = _a.isOpened, setCategory = _a.setCategory;
    var categories = useSelector(function (state) { return state.categories; });
    var recentCategories = useSelector(function (state) { return state.recentCategories.items; });
    var teams = useSelector(function (state) { return state.teams; });
    return (<CategorySelector categories={categories} category={category} isOpened={isOpened} recentCategories={recentCategories} setCategory={setCategory} teams={workspaceTeams(teams.items)}/>);
};
export default CategorySelectorContainer;
