import React from 'react';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
import QuickTaskCurrentContainer from '@/containers/quick_task_current_container';
import Icon from '@/components/atoms/icon';
import TaskFormMyTaskQuickTaskContainer from '@/containers/task_form_my_task_quick_task_container';
import Backdrop from '@/components/atoms/backdrop';
import ModeSwitch from '@/components/molecules/mode_switch';
var QuickTask = function (props) {
    var currentEntry = props.currentEntry, stopTimeEntry = props.stopTimeEntry, fetchWorkingUsersCount = props.fetchWorkingUsersCount, updateDailyActivity = props.updateDailyActivity, switchViewMode = props.switchViewMode, isPC = props.isPC, isChromeExtension = props.isChromeExtension, activeTaskForm = props.myTask.activeTaskForm, setActiveTaskForm = props.setActiveTaskForm;
    var isOpen = activeTaskForm === 'quicktask';
    var handleStop = function (e) {
        e.stopPropagation();
        var startedOn = moment(currentEntry.started_at).format('YYYY-MM-DD');
        stopTimeEntry(currentEntry).then(function (_a) {
            var error = _a.error;
            if (error) {
                return;
            }
            fetchWorkingUsersCount();
            updateDailyActivity(startedOn);
        });
    };
    var handleOpenQuickTask = function () { return setActiveTaskForm('quicktask'); };
    var handleCloseQuickTask = function () { return setActiveTaskForm(-1); };
    return (<div className={['toolbar', currentEntry ? 'started' : 'stopped'].join(' ')}>
      <div className="row no-gutters">
        <div className="col-xl-8 col-md-10 col-12">
          {!isOpen && (<div className="quicktask" onClick={handleOpenQuickTask}>
              <button className={"text-left btn-new-task btn btn-secondary btn-lg ".concat(currentEntry ? '' : 'btn-block')}>
                <Icon className={currentEntry ? '' : 'mr-1'} color={currentEntry ? 'normal' : 'primary'} name="plus" size="lg"/>
                {!currentEntry && <span>{I18n.t('quicktask.newtask')}</span>}
              </button>
              <QuickTaskCurrentContainer onStop={handleStop}/>
            </div>)}
          {isOpen && (<TaskFormMyTaskQuickTaskContainer className="composer task-edit" isShowRemoveTimeEntry={true} mode="create" toggleQuickTask={handleCloseQuickTask}/>)}
          {isOpen && <Backdrop onClick={handleCloseQuickTask}/>}
        </div>
        <ModeSwitch isChromeExtension={isChromeExtension} isPC={isPC} mode="myTask" switchViewMode={switchViewMode}/>
      </div>
    </div>);
};
export default QuickTask;
