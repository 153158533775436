import React from 'react';
import { useSelector } from 'react-redux';
import { ChargeList } from '@/components/payment/charge_list';
export var filterCharges = function (charges, isShowUnpaid) {
    var firstUnpaidCharge = charges.find(function (charge) { return !charge.paid; }) || {};
    return charges.filter(function (charge) {
        return (isShowUnpaid && charge.id === firstUnpaidCharge.id) || charge.paid;
    });
};
var ChargeListContainer = function (_a) {
    var _b = _a.isShowUnpaid, isShowUnpaid = _b === void 0 ? false : _b;
    var apiBase = useSelector(function (state) { return state.apiBase; });
    var charges = useSelector(function (state) { return state.workspacesCharges.items; });
    var openURL = useSelector(function (state) { return state.openURL; });
    var handleClickLink = function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    };
    return (<ChargeList apiBase={apiBase} charges={filterCharges(charges, isShowUnpaid)} onClickLink={handleClickLink}/>);
};
export default ChargeListContainer;
