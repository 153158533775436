import { __spreadArray } from "tslib";
import React, { useState } from 'react';
import uniq from 'lodash/uniq';
import { FilterableInput, } from '@/components/molecules/filterable_input';
import { RecentCategoriesContainer } from '@/containers/recent_categories_container';
import TeamCategoriesListContainer from '@/containers/team_categories_list_container';
import { ignoreArchivedCategories } from '@/modules/categories';
import { buildCategoriesWithAncestorTitles } from '@/modules/categories/helpers';
import I18n from '@/constants/i18n';
import variants from '@/constants/variants';
import LoadingSpinner from '@/components/atoms/loading_spinner';
function fuzzyMatch(str, word) {
    var trimedWord = word.trim();
    if (!trimedWord) {
        return true;
    }
    var words = trimedWord.toLowerCase().split(/[ 　]+/); // eslint-disable-line no-irregular-whitespace
    var downcasedStr = str.toLowerCase();
    return words.every(function (w) { return downcasedStr.includes(w); });
}
function filterCategories(categories, word, categoryById) {
    if (!word) {
        return categories;
    }
    var filteredIds = uniq(categories.reduce(function (ids, category) {
        var _a;
        var ancestorIds = (((_a = category.ancestry) === null || _a === void 0 ? void 0 : _a.split('/')) || []).map(function (id) {
            return parseInt(id);
        });
        var targets = __spreadArray(__spreadArray([], ancestorIds.map(function (id) { return categoryById[id].title; }), true), [
            category.title,
            category.team.name,
        ], false);
        if (fuzzyMatch(targets.join(' '), word)) {
            ids.push.apply(ids, __spreadArray([category.id], ancestorIds, true));
        }
        return ids;
    }, []));
    return categories.filter(function (category) { return filteredIds.includes(category.id); });
}
export var CategorySelector = function (_a) {
    var className = _a.className, _b = _a.tag, tag = _b === void 0 ? 'div' : _b, category = _a.category, teams = _a.teams, _c = _a.recentCategories, recentCategories = _c === void 0 ? [] : _c, setCategory = _a.setCategory, categories = _a.categories, isOpened = _a.isOpened, _d = _a.isPC, isPC = _d === void 0 ? variants.isPC : _d, _e = _a.allowsUnselect, allowsUnselect = _e === void 0 ? false : _e, _f = _a.isExpandAll, isExpandAll = _f === void 0 ? false : _f, _g = _a.withoutTeam, withoutTeam = _g === void 0 ? false : _g;
    var placeholder = withoutTeam
        ? I18n.t('shared.searchByCategoryName')
        : I18n.t('shared.searchByCategoryOrTeamName');
    var _h = useState(''), filteringWord = _h[0], setFilteringWord = _h[1];
    var Tag = tag;
    var _j = React.useMemo(function () {
        return buildCategoriesWithAncestorTitles(categories);
    }, [categories]), categoryById = _j.categoryById, categoriesWithAncestorTitles = _j.categoriesWithAncestorTitles;
    var filteredRecentCategories = React.useMemo(function () {
        return filterCategories(recentCategories, filteringWord, categoryById);
    }, [recentCategories, filteringWord, categoryById]);
    var filteredCategores = React.useMemo(function () {
        return ignoreArchivedCategories(filterCategories(categoriesWithAncestorTitles, filteringWord, categoryById));
    }, [categoriesWithAncestorTitles, filteringWord, categoryById]);
    var handleChangeFilter = function (word) { return setFilteringWord(word); };
    return (<Tag className={className}>
      <FilterableInput isOpened={isOpened} isPC={isPC} placeholder={placeholder} onChange={handleChangeFilter}/>
      <div className="category-selector__category-list">
        {allowsUnselect && (<div className="list-group list-group-flush">
            <div className="list-group-item list-group-item-action" onClick={function () { return setCategory(null); }}>
              {I18n.t('shared.notSelected')}
            </div>
          </div>)}

        <RecentCategoriesContainer recentCategories={filteredRecentCategories} setCategory={setCategory}/>
        {teams.length > 0 && categories.length > 0 ? (<TeamCategoriesListContainer categories={filteredCategores} category={category} isExpandAll={isExpandAll || !!filteringWord} setCategory={setCategory} teams={teams} withoutTeam={withoutTeam}/>) : (<div className="py-2 text-center">
            <LoadingSpinner size="md"/>
          </div>)}
      </div>
    </Tag>);
};
