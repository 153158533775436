import { __assign, __awaiter, __generator } from "tslib";
import React from 'react';
import InfiniteCalendar, { withRange, Calendar } from 'react-infinite-calendar';
import I18n from '@/constants/i18n';
import { CrossTeamReportPeriodButton } from './cross_team_report_period_button';
import { calcFromAndToForType } from './helpers';
import { formatDate } from '@/utils/time_helper';
import { StartMonthDropdown } from '@/components/start_month_dropdown';
import LoadingSpinner from '@/components/atoms/loading_spinner';
var END_EVENT_TYPE = 3;
var RangedCalendar = withRange(Calendar);
export var RealtimeReportTab = function (props) {
    var defaultPeriod = props.defaultPeriod, isLoading = props.isLoading, isOpen = props.isOpen, startMonth = props.startMonth, onChangeStartMonth = props.onChangeStartMonth, onClose = props.onClose, onRefresh = props.onRefresh;
    var _a = React.useState(defaultPeriod), selectedPeriod = _a[0], setSelectedPeriod = _a[1];
    // NOTE: typeを変更したときにカレンダーを再描画させて選択位置に移動させるため
    var _b = React.useState(0), calendarKey = _b[0], setCalendarKey = _b[1];
    React.useEffect(function () {
        setSelectedPeriod(defaultPeriod);
    }, [defaultPeriod, isOpen]);
    var handleChangeType = function (period_type) {
        setSelectedPeriod(function (prev) { return (__assign(__assign(__assign({}, prev), { period_type: period_type }), calcFromAndToForType(period_type, startMonth))); });
        setCalendarKey(function (prev) { return prev + 1; });
    };
    var handleChangeStartMonth = function (startMonth) {
        onChangeStartMonth(startMonth);
        if (selectedPeriod.period_type) {
            setSelectedPeriod(__assign(__assign({}, selectedPeriod), calcFromAndToForType(selectedPeriod.period_type, startMonth)));
            setCalendarKey(function (prev) { return prev + 1; });
        }
    };
    var handleClickRefresh = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onRefresh({
                        period_type: selectedPeriod.period_type,
                        from: selectedPeriod.from,
                        to: selectedPeriod.to,
                        start_month: startMonth,
                    })];
                case 1:
                    res = _a.sent();
                    if (!res.error) {
                        onClose();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSelectCalendar = function (selected) {
        if (selected.eventType !== END_EVENT_TYPE) {
            return;
        }
        setSelectedPeriod(function (prev) { return (__assign(__assign({}, prev), { period_type: 'custom', from: formatDate(selected.start), to: formatDate(selected.end) })); });
    };
    return (<>
      <p className="text-muted mt-2">
        {I18n.t('shared.realtimeReportDescription')}
      </p>
      <div className="d-flex position-relative">
        <div className="w-50">
          {[
            ['today', 'yesterday'],
            ['thisWeek', 'lastWeek', 'recent2Weeks'],
            ['thisMonth', 'lastMonth', 'monthBeforeLast'],
            ['recent2Months'],
        ].map(function (types) { return (<div key={String(types)} className="mt-2 text-nowrap">
              {types.map(function (type) { return (<CrossTeamReportPeriodButton key={type} isSelected={selectedPeriod.period_type === type} startMonth={startMonth} type={type} onClick={function () { return handleChangeType(type); }}/>); })}
            </div>); })}
          <hr className="my-3"/>
          <StartMonthDropdown helpMessage={I18n.t('shared.startMonthHelpForRealtime')} startMonth={startMonth} onChange={handleChangeStartMonth}/>
          {['currentQuarter', 'lastQuarter'].map(function (type) { return (<div key={type} className="mt-2">
              <CrossTeamReportPeriodButton isSelected={selectedPeriod.period_type === type} startMonth={startMonth} type={type} onClick={function () { return handleChangeType(type); }}/>
            </div>); })}
        </div>
        <InfiniteCalendar key={calendarKey} className="calendar-sm" Component={RangedCalendar} height={360} locale={I18n.t('infiniteCalendar')} rowHeight={40} selected={{
            start: new Date(selectedPeriod.from),
            end: new Date(selectedPeriod.to),
        }} theme={{
            floatingNav: {
                chevron: '#4abaa4',
            },
        }} onSelect={handleSelectCalendar}/>
        {isLoading && (<div className="position-absolute top-0 bottom-0 left-0 right-0 d-flex justify-content-center align-items-center z-1 bg-white">
            <div className="text-center">
              <LoadingSpinner size="lg"/>
              <h2 className="opacity-80 mt-3">
                {I18n.t('shared.crossTeamReportLoadingTitleForRealtime')}
              </h2>
              <p className="text-secondary mt-1">
                {I18n.t('shared.crossTeamReportLoadingDescription')}
              </p>
            </div>
          </div>)}
      </div>
      <div className="mt-3 text-right">
        <button className="btn btn-dark text-secondary border-1" onClick={onClose}>
          {I18n.t('shared.cancel')}
        </button>
        <button className="btn btn-primary ml-2" disabled={isLoading} onClick={handleClickRefresh}>
          {I18n.t('shared.update')}
        </button>
      </div>
    </>);
};
