import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { destroyUser, signOut } from '@/actions/user';
import DeactivationModal from '@/components/organisms/deactivation_modal';
import { removeDangerNotificationMessage } from '@/modules/notification';
var DeactivationContainer = function (props) {
    var email = props.email, isOpen = props.isOpen, onClose = props.onClose;
    var openURL = useSelector(function (state) { return state.openURL; });
    var _a = React.useState(false), isConfirm = _a[0], setIsConfirm = _a[1];
    var _b = React.useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var dispatch = useDispatch();
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsLoading(true);
            dispatch(destroyUser()).then(function (resp) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (!resp.error) {
                        dispatch(signOut());
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
                });
            }); });
            return [2 /*return*/];
        });
    }); };
    var handleConfirm = function () {
        setIsConfirm(!isConfirm);
    };
    var handleClose = function () {
        dispatch(removeDangerNotificationMessage());
        setIsConfirm(false);
        onClose();
    };
    return (<DeactivationModal email={email} isConfirm={isConfirm} isLoading={isLoading} isOpen={isOpen} openURL={openURL} onClose={handleClose} onConfirm={handleConfirm} onSubmit={handleSubmit}/>);
};
export default DeactivationContainer;
