var initialState = {};
export default function workingUsersCount(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case 'SET_WORKING_USERS_COUNT':
            return action.workingUsersCount;
        default:
            return state;
    }
}
