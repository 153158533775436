import { __spreadArray } from "tslib";
export var categoryWithAncestors = function (category) {
    var _a;
    var ancestor_titles = __spreadArray(__spreadArray([], ((_a = category.ancestor_titles) !== null && _a !== void 0 ? _a : []), true), [category.title], false);
    return ancestor_titles.join(':');
};
export var isLeaf = function (categories, targetCategory) {
    if (targetCategory.archived) {
        return false;
    }
    if (!targetCategory.id) {
        return true;
    }
    return !categories.some(function (c) { return !c.archived && c.parent_id === targetCategory.id; });
};
