export var isRequiredUpdate = function (currentVersion, minVersion) {
    var currentVersions = currentVersion.split('.').map(Number);
    var minVersions = minVersion.split('.').map(Number);
    for (var i = 0; i < currentVersions.length; i++) {
        if (currentVersions[i] > minVersions[i]) {
            return false;
        }
        else if (currentVersions[i] < minVersions[i]) {
            return true;
        }
    }
    return false;
};
