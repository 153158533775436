import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTeamMenu } from '@/modules/team_management';
import { setNotificationMessage } from '@/modules/notification';
import { workspaceTeams } from '@/utils/workspace_helper';
import { TeamManagementLayout } from '@/components/team_management_layout';
export var TeamManagementLayoutContainer = function (props) {
    var currentPage = props.currentPage, team = props.team, title = props.title, saveButtonForPC = props.saveButtonForPC, saveButtonForSP = props.saveButtonForSP, children = props.children;
    var dispatch = useDispatch();
    var teams = workspaceTeams(useSelector(function (state) { return state.teams.items; }));
    var isLoading = useSelector(function (state) { return state.teams.isLoading; });
    var isTeamMenuOpened = useSelector(function (state) { return state.teamManagement.openTeamMenu; });
    var user = useSelector(function (state) { return state.user; });
    var handleSetNotificationMessage = React.useCallback(function (type, message) {
        dispatch(setNotificationMessage(type, message));
    }, [dispatch]);
    var handleToggleTeamMenu = React.useCallback(function (isOpen) {
        dispatch(toggleTeamMenu(isOpen));
    }, [dispatch]);
    return (<TeamManagementLayout currentPage={currentPage} isLoading={isLoading} isTeamMenuOpened={isTeamMenuOpened} saveButtonForPC={saveButtonForPC} saveButtonForSP={saveButtonForSP} setNotificationMessage={handleSetNotificationMessage} team={team} teams={teams} title={title} toggleTeamMenu={handleToggleTeamMenu} user={user}>
      {children}
    </TeamManagementLayout>);
};
