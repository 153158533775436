import { __assign } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import TaskForm from '@/components/task_form';
import { createAndStartTask, createTaskWithTimeEntry, fetchWorkingUsersCount, } from '@/actions/task';
import { setCurrentCategory } from '@/modules/my_task';
import { arrangeRecentCategories } from '@/modules/recent_categories';
import { updateDailyActivity } from '@/modules/daily_activites';
import { setFromPageTitle } from '@/modules/from_page_title';
import { freezeScroll } from '@/modules/display';
import { fetchSuggestTasks } from '@/actions/task';
import { fetchTimeEntrySuggestion } from '@/modules/time_entry_suggestions';
var today = function () { return moment().format('YYYY-MM-DD'); };
var TaskFormMyTaskQuickTaskContainer = function (_a) {
    var className = _a.className, isShowRemoveTimeEntry = _a.isShowRemoveTimeEntry, mode = _a.mode, toggleQuickTask = _a.toggleQuickTask;
    var category = useSelector(function (state) { return state.myTask.currentCategory; });
    var fromPageTitle = useSelector(function (state) { return state.fromPageTitle; });
    var isChromeExtension = useSelector(function (state) { return state.isChromeExtension; });
    var isPC = useSelector(function (state) { return state.isPC; });
    var timeEntrySuggestions = useSelector(function (state) { return state.timeEntrySuggestions.items; });
    var dispatch = useDispatch();
    var handleStart = function (task, comment) {
        var promise = dispatch(createAndStartTask(task, comment));
        promise.then(function () {
            dispatch(fetchWorkingUsersCount());
            dispatch(arrangeRecentCategories(task.category));
            dispatch(updateDailyActivity(today()));
            toggleQuickTask();
        });
        return promise;
    };
    var handleCreate = function (teamId, task, timeEntry, comment) {
        var promise = dispatch(createTaskWithTimeEntry(teamId, task, timeEntry, comment));
        promise.then(function (task) {
            if (task.error) {
                return;
            }
            var category = __assign(__assign({}, task.category), { team: task.team });
            var date = moment(timeEntry.started_at).format('YYYY-MM-DD');
            dispatch(arrangeRecentCategories(category));
            dispatch(updateDailyActivity(date));
            toggleQuickTask();
        });
        return promise;
    };
    var handleSelectCategory = function (category) {
        dispatch(setCurrentCategory(category));
    };
    var handleSetFromPageTitle = function (fromPageTitle) {
        dispatch(setFromPageTitle(fromPageTitle));
    };
    var handleSetFreezeScroll = React.useCallback(function (isFreeze) {
        dispatch(freezeScroll(isFreeze));
    }, [dispatch]);
    var handleSuggestTasks = function (category, title) {
        var params = {
            title: title,
            category_id: category.id,
            team_id: category.team_id,
        };
        dispatch(fetchSuggestTasks(params));
    };
    var handleFetchTimeEntrySuggestion = React.useCallback(function (time) {
        dispatch(fetchTimeEntrySuggestion(time));
    }, [dispatch]);
    return (<TaskForm category={category} className={className} fetchTimeEntrySuggestion={handleFetchTimeEntrySuggestion} fromPageTitle={fromPageTitle} isChromeExtension={isChromeExtension} isPC={isPC} isShowCancelButton={false} isShowRemoveTimeEntry={isShowRemoveTimeEntry} isTimeEntrySpecified={false} mode={mode} setFreezeScroll={handleSetFreezeScroll} tab="unselect" timeEntrySuggestions={timeEntrySuggestions} onCreate={handleCreate} onSelectCategory={handleSelectCategory} onSetFromPageTitle={handleSetFromPageTitle} onStart={handleStart} onSuggestTasks={handleSuggestTasks}/>);
};
export default TaskFormMyTaskQuickTaskContainer;
