import { __awaiter, __generator } from "tslib";
import React from 'react';
export var useThrottle = function (func, wait) {
    if (wait === void 0) { wait = 1000; }
    var isProcessing = React.useRef(false);
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isProcessing.current) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        isProcessing.current = true;
                        return [4 /*yield*/, func.apply(void 0, args)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        setTimeout(function () {
                            isProcessing.current = false;
                        }, wait);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
};
