import { __assign } from "tslib";
import { connect } from 'react-redux';
import NewChildTeamForm from '@/components/team_children/new_child_team_form';
import { createTeam } from '@/modules/teams';
var mapStateToProps = function (state, ownProps) { return (__assign({}, ownProps)); };
var mapDispatchToProps = function (dispatch) { return ({
    onCreate: function (params) {
        return dispatch(createTeam(params));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(NewChildTeamForm);
