import React from 'react';
import Avatar from '@/components/atoms/avatar';
import CustomCheck from '@/components/atoms/custom_check';
import OmitLabel from '@/components/atoms/omit_label';
import { PersonalBadge, UnpaidBadge } from '@/components/badges';
import { WorkspaceAvatarContainer } from '@/containers/workspace_avatar_container';
export default function TeamCheckbox(_a) {
    var team = _a.team, checked = _a.checked, _b = _a.disabled, defaultDisabled = _b === void 0 ? false : _b, indeterminate = _a.indeterminate, onChange = _a.onChange, shouldDisableUnpaidTeam = _a.shouldDisableUnpaidTeam, showsBadge = _a.showsBadge, _c = _a.showsWorkspaceAvatar, showsWorkspaceAvatar = _c === void 0 ? false : _c;
    var maxWidth = team.personal || team.payment_required ? '9.25rem' : '13.5rem';
    var disabled = (shouldDisableUnpaidTeam && team.payment_required) || defaultDisabled;
    return (<div className="d-flex align-items-center">
      <CustomCheck checked={checked} className="d-block mr-1" disabled={disabled} indeterminate={indeterminate} onChange={function () { return onChange(team, checked); }}/>
      <div className="w-100">
        {showsWorkspaceAvatar && (<WorkspaceAvatarContainer withName className="mb-1 small" inline={false} size="md" workspaceId={team.workspaces_workspace_id}/>)}
        <div className="d-flex">
          <Avatar className="mr-1" image={team.avatar_url}/>
          <OmitLabel maxWidth={maxWidth}>{team.name}</OmitLabel>
          {showsBadge && (<>
              <PersonalBadge className="ml-auto" isPersonal={team.personal} style={{ marginTop: '2px' }}/>
              <UnpaidBadge className="ml-auto" style={{ marginTop: '2px' }} team={team}/>
            </>)}
        </div>
      </div>
    </div>);
}
