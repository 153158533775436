import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '@/actions/user';
import { Welcome } from '@/components/welcome';
export var WelcomeContainer = function () {
    var openURL = useSelector(function (state) { return state.openURL; });
    var user = useSelector(function (state) { return state.user; });
    var handleClickLink = React.useCallback(function (e) {
        e.preventDefault();
        openURL(e.currentTarget.href);
    }, [openURL]);
    var dispatch = useDispatch();
    var handleSignOut = React.useCallback(function () {
        dispatch(signOut());
    }, [dispatch]);
    return (<Welcome email={user.email} onClickLink={handleClickLink} onSignOut={handleSignOut}/>);
};
