import React from 'react';
import I18n from '@/constants/i18n';
import { TeamManagementLayoutContainer } from '@/containers/team_management_layout_container';
import { WebHookForm } from '@/components/organisms/webhook_form';
export var WebHookSetting = function (_a) {
    var team = _a.team, webHooks = _a.webHooks, onCreate = _a.onCreate, onDelete = _a.onDelete;
    return (<TeamManagementLayoutContainer currentPage="settings" team={team} title={I18n.t('webHook.create.title')}>
      <WebHookForm team={team} webHooks={webHooks} onCreate={onCreate} onDelete={onDelete}/>
    </TeamManagementLayoutContainer>);
};
