import { __assign } from "tslib";
import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import InfiniteCalendar from 'react-infinite-calendar';
import moment from 'moment'; // eslint-disable-line no-restricted-imports
import I18n from '@/constants/i18n';
import { formatDateWithLocale, beforeDay } from '@/utils/time_helper';
var WorkspacesEmployForm = function (props) {
    var employ = props.employ, minDate = props.minDate, maxDate = props.maxDate, onChangeEmploy = props.onChangeEmploy, onDelete = props.onDelete, index = props.index, nextEmploy = props.nextEmploy, isCurrent = props.isCurrent;
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var toggleCalendar = function () {
        setIsOpen(!isOpen);
    };
    var onChangeRate = function (e) {
        if (Number(e.target.value) < 0 || 9999999 < Number(e.target.value)) {
            return;
        }
        var newEmploy = __assign(__assign({}, employ), { rate: e.target.value });
        onChangeEmploy(newEmploy);
    };
    var onSelectDate = function (date) {
        var started_at = date.toString();
        var newEmploy = __assign(__assign({}, employ), { started_at: started_at });
        onChangeEmploy(newEmploy);
        toggleCalendar();
    };
    var onClickDelete = function () {
        onDelete(employ);
    };
    return (<div className="form-group mb-0 w-100">
      <div className="form-inline">
        <div className="d-flex w-40">
          <label className="mr-1 font-weight-bold">
            {I18n.t('shared.currencyUnit')}
          </label>
          <input className="form-control form-control-sm" min="0" type="number" value={employ.rate} onChange={onChangeRate}/>
        </div>
        <span className="mr-1 ml-2 text-muted">/ {I18n.t('shared.hours')}</span>
        <div className="ml-2">
          {index < 1 &&
            nextEmploy &&
            I18n.t('shared.toDate', {
                date: formatDateWithLocale(beforeDay(nextEmploy.started_at, 1)),
            })}
          {index < 1 && !nextEmploy && I18n.t('shared.allPeriods')}
          {index > 0 && (<ButtonDropdown isOpen={isOpen} toggle={toggleCalendar}>
              <DropdownToggle caret>
                {I18n.t('shared.fromDate', {
                date: moment(employ.started_at).format('YYYY-MM-DD'),
            })}
              </DropdownToggle>
              <DropdownMenu>
                <InfiniteCalendar className="calendar-sm" displayOptions={{
                showHeader: true,
                showTodayHelper: false,
                showWeekdays: false,
                shouldHeaderAnimate: true,
            }} height={276} locale={I18n.t('infiniteCalendar')} maxDate={maxDate} minDate={minDate} rowHeight={48} selected={new Date(employ.started_at)} theme={{
                floatingNav: {
                    chevron: '#4abaa4',
                },
            }} width="100%" onSelect={onSelectDate}/>
              </DropdownMenu>
            </ButtonDropdown>)}
        </div>
        {isCurrent && (<span className="badge badge-success opacity-50 ml-2 rounded-0">
            {I18n.t('shared.currentRate')}
          </span>)}
        <div className={"ml-auto ".concat(index < 1 && nextEmploy ? 'invisible' : '')}>
          <button className="btn btn-link text-danger" onClick={onClickDelete}>
            {I18n.t('shared.remove')}
          </button>
        </div>
      </div>
    </div>);
};
export default WorkspacesEmployForm;
