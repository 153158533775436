import React from 'react';
import I18n from '@/constants/i18n';
import Icon from '@/components/atoms/icon';
var ModeSwitch = function (props) {
    var mode = props.mode, switchViewMode = props.switchViewMode, isPC = props.isPC, isChromeExtension = props.isChromeExtension;
    return (<div className="col-xl-4 col-md-2 d-flex">
      <ul className={"floating-nav ml-auto nav nav-pills ".concat(!isPC && !isChromeExtension ? 'nav-justified' : '')}>
        <li className="nav-item">
          <a className={"nav-link ".concat(mode === 'myTask' ? 'active' : '')} href="#" onClick={function (e) {
            e.preventDefault();
            if (mode === 'myTask') {
                return;
            }
            switchViewMode('myTask');
        }}>
            <Icon name="align-justify" size="md"/>
            {!isPC && !isChromeExtension && (<span> {I18n.t('shared.list')}</span>)}
          </a>
        </li>
        <li className="nav-item">
          <a className={"nav-link ".concat(mode === 'calendar' ? 'active' : '')} href="#" onClick={function (e) {
            e.preventDefault();
            if (mode === 'calendar') {
                return;
            }
            switchViewMode('calendar');
        }}>
            <Icon name="calendar" size="md"/>
            {!isPC && !isChromeExtension && (<span> {I18n.t('shared.calendar')}</span>)}
          </a>
        </li>
      </ul>
    </div>);
};
export default ModeSwitch;
